<template>
  <div>
    <section-header />
    <v-content
      id="main_content"
      tabindex="-1"
      class="pb-5 mb-5"
    >
      <router-view />
    </v-content>
    <mobile-nav v-if="$vuetify.breakpoint.xsOnly" />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';
import SectionHeader from '../components/SectionHeader.vue';
import SecureHeader from '../components/SecureHeader.vue';
import MobileNav from '../components/MobileNav.vue';

export default {
  name: 'MainLayout',
  components: {
    SectionHeader,
    SecureHeader,
    MobileNav,
    Footer,
  },
};
</script>
<style lang="stylus" scoped>
.language-dropdown {
  padding-right: 113px;
}
.sing-in {
  padding-right: 85px;
}
</style>
