export const returnUndefined = () => undefined;

export function renderNoRender(el, show, featureName, vnode) {
  if (show) {
    el.style.display = 'block';
    return;
  }
  const comment = document.createComment(` Feature not loaded: ${featureName} `);
  Object.defineProperty(comment, 'setAttribute', {
    value: returnUndefined,
  });
  vnode.elm = comment;
  vnode.text = ' ';
  vnode.isComment = true;
  if (el.parentNode !== null) {
    el.parentNode.replaceChild(comment, el);
  }
}
export function checkFlag(el, binding, vnode) {
  el.style.display = 'none';
  const featureName = binding.arg.toUpperCase().slice(4);
  const show = vnode.context.$store.getters.containsFeature(featureName);

  renderNoRender(el, show, featureName, vnode);
}

export const featureFlagDirective = {
  bind: (checkFlag),
};

export default {
  install(Vue) {
    Vue.directive('feature', featureFlagDirective);
  },
};
