import { render, staticRenderFns } from "./NoLeftSpaceLayout.vue?vue&type=template&id=6f33345d&scoped=true"
import script from "./NoLeftSpaceLayout.vue?vue&type=script&lang=js"
export * from "./NoLeftSpaceLayout.vue?vue&type=script&lang=js"
import style0 from "./NoLeftSpaceLayout.vue?vue&type=style&index=0&id=6f33345d&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f33345d",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Flayouts%2FNoLeftSpaceLayout.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports