import { render, staticRenderFns } from "./PolicyDetailsPanel.vue?vue&type=template&id=78f8d7e8&scoped=true"
import script from "./PolicyDetailsPanel.vue?vue&type=script&lang=js"
export * from "./PolicyDetailsPanel.vue?vue&type=script&lang=js"
import style0 from "./PolicyDetailsPanel.vue?vue&type=style&index=0&id=78f8d7e8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f8d7e8",
  null
  
)

/* custom blocks */
import block0 from "../../../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FPolicyOverview%2Fcomponents%2FPolicyDetailsPanel%2FPolicyDetailsPanel.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports