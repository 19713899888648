<i18n src="./locales.json"></i18n>
<template>
  <v-flex
    md8
    sm10
    xs12
    pl-4
    pr-4
  >
    <v-card-title
      class="pb-0 justify-center"
    >
      <v-layout row wrap class="text-xs-center">
        <v-flex xs12 mb-3>
          <v-icon
            color="primary"
            size="50"
          >
            check_circle_outline
          </v-icon>
        </v-flex>
        <v-flex xs12>
          <h2
            class="title font-weight-regular mb-0"
          >
            {{ $t('L_payment_has_been_submitted') }}
          </h2>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class="text-xs-center">
      <v-layout
        justify-center
        row
        wrap
        mt-3
      >
        <v-flex xs12>
          {{ $t('L_your_kubra_payment_being_processed') }}
        </v-flex>
        <v-flex xs12>
          {{ $t('L_payment_may_appear_as') }}
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-flex>
</template>

<script>

export default {
  name: 'KubraPayment',
  methods: {
    closeAndFocus() {
      // const viewPaymentButton = document.querySelector('div.card-slide-item:not(.policy-active) #view_payment_details_container');
      this.$store.commit('policy/setPanelValue', -1);
      // Ada.focusIt(viewPaymentButton);
    },
  },
  mounted() {
    /*
      * Emit a custom action when a user successfully submits a Pay Now payment
      * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
    */
    const i = this.$dynatrace.enterAction('pay_now_succesful_submission');
    this.$dynatrace.leaveAction(i);
  },
};
</script>

