import { render, staticRenderFns } from "./SectionHeader.vue?vue&type=template&id=5030ad42&scoped=true"
import script from "./SectionHeader.vue?vue&type=script&lang=js"
export * from "./SectionHeader.vue?vue&type=script&lang=js"
import style0 from "./SectionHeader.vue?vue&type=style&index=0&id=5030ad42&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5030ad42",
  null
  
)

export default component.exports