<i18n src='./locales.json'></i18n>
<template>
  <div>
    <v-btn
      href="javascript:void(0)"
      class="skip-main policy-details"
      dark
      @keydown.native.space="$event.target.click()"
      @click="focusMain"
    >
      {{ $t('L_skip_to_main') }}
    </v-btn>
  </div>
</template>


<script>
export default {
  name: 'SkipToMain',
  methods: {
    focusMain() {
      document.getElementById('main_content').focus();
    },
  },
};
</script>


<style scoped>
a.skip-main {
  position: absolute;
  top: 0;
  left: -500px;
  overflow: hidden;
  z-index: 99999 !important;
  display: inline-block !important;
  transition: none;
}
a.skip-main:focus, a.skip-main:active {
  font-size: 14px;
  top: 80px;
  left: 250px;
  background: #FFFFFE !important;
  text-decoration: none;
  color: #073562 !important;
  padding: 12px 16px;
  transition: none;
}
a.skip-main::before {
  color: #063562 !important;
}
</style>
