<template>
  <div class="video">
    <iframe
      :src="url"
      frameborder="0"
      title="Video"
      allowfullscreen
      allow="accelerometer; encrypted-media; gyroscope"
      v-if="render"
    />
  </div>
</template>

<script>
export default {
  name: 'Video',
  props: {
    url: {
      type: String,
      default: null,
    },
    render: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="stylus">
.video
  position: relative;
  padding-top: 56.25%;  /* 16:9 Aspect Ratio */
  iframe
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
</style>
