import ada from '@/shared/ada/ada';

export function focusTrigger(trigger) {
  return () => {
    if (trigger) {
      trigger.focus();
    }
  };
}

export function focusClose(trigger, modal, closeSelectors) {
  if (modal) {
    modal.focus();
  }
  closeSelectors.forEach((eachCloseButton) => {
    eachCloseButton.addEventListener('click', focusTrigger(trigger));
  });
}
export function createsCloseSelector(baseSelector, trigger) {
  return () => {
    const modal = document.querySelector(`.v-dialog--active .${baseSelector}modal`);
    const closeSelectors = Array.from(document.querySelectorAll(`.${baseSelector}close`));
    if (modal) {
      modal.setAttribute('tabindex', '-1');
    }
    focusClose(trigger, modal, closeSelectors);
    if (modal) {
      ada.createTabLoopSelectors(modal);
    }
  };
}

export function addListeners(el, baseSelector) {
  return () => {
    const trigger = el;
    trigger.addEventListener('click', () => {
      setTimeout(createsCloseSelector(baseSelector, trigger), 250);
    });
  };
}

export function onUnbind(el) {
  const baseSelector = el.id.replace('trigger', '');
  setTimeout(addListeners(el, baseSelector), 250);
}

export const modalDirective = {
  bind: (onUnbind),
};

export default {
  install(Vue) {
    Vue.directive('ada-modal', modalDirective);
  },
};
