export const filters = {
  filters: {
    hideNums(value) {
      if (value.length > 5) {
        return value.substring(0, value.length - 4).replace(/\d/g, '*') + value.substring(value.length - 4);
      } else if (value.length === 2) {
        return value.substring(0, value.length - 1).replace(/\d/g, '*') + value.substring(value.length - 1);
      }
      return value.substring(0, value.length - 2).replace(/\d/g, '*') + value.substring(value.length - 2);
    },
  },
  methods: {
    currency(value) {
      return this.$n(value, 'currency', 'en_US');
    },
  },
};

