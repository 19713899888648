<i18n src='./locales.json'></i18n>

<template>
  <div
    :id="id"
  >
    <v-alert
      aria-live="assertive"
      :aria-label="type"
      :value="showAlert"
      :type="type"
      transition="slide-y-transition"
      :icon="alertIcon"
      role="alertdialog"
      class="elevation-1"
      :class="{
        alert: newAlertType
      }"
    >
      <v-icon
        v-if="dismissable"
        class="close"
        @click="dismiss"
      >
        cancel
      </v-icon>
      <template v-if="!hasSlot('message')">
        <span
          v-html="$t(message)"
          class="max-width-85"
        />
      </template>
      <slot name="message" />
    </v-alert>
  </div>
</template>
<script>
import moment from 'moment';

export const getDomTextRecursive = (node) => {
  if (typeof (node.text) !== 'undefined') {
    return node.text;
  }
  return ((typeof (node.children) !== 'undefined')
    ? node.children.reduce((agg, childNode) => `${agg} ${getDomTextRecursive(childNode)}`, '')
    : '').replace(/\s{2,}/g, ' ');
};

export default {
  props: {
    showAlert: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: 'L_generic_error_message',
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: [String, undefined],
      default: 'error',
    },
    alertIcon: {
      type: String,
      default: 'error',
    },
    autoDismiss: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoDismissTimeout: {
      type: Number,
      required: false,
      default: 5000,
    },
    id: {
      type: String,
      required: false,
      default: 'alert',
    },
    newAlertType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    gtmMessage() {
      if (this.hasSlot('message')) {
        return getDomTextRecursive(this.$slots.message[0]).trim();
      }
      if (this.hasSlot('default')) {
        return getDomTextRecursive(this.$slots.default[0]).trim();
      }
      return this.message;
    },
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    dismiss() {
      this.$emit('close-alert');
    },
  },
  watch: {
    showAlert: {
      immediate: true,
      handler(current, previous) {
        if (current === true && previous === false && typeof (this.$eventBus) !== 'undefined') {
          this.$eventBus.$emit('gtm-data-layer', {
            event: 'event',
            eventCategory: 'alert',
            eventLabel: this.type,
            eventValue: this.gtmMessage,
            timestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          });
          if (this.autoDismiss) {
            window.setTimeout(this.dismiss, this.autoDismissTimeout);
          }
        }
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
span.max-width-85 {
  display: inline-block;
  max-width: 85%;
}
.alert{
  background-color : #fff !important;
  color:#808080;
}
>>>.alert.success{
    border-top-color: var(--v-success-base) !important;
    i.v-alert__icon.v-icon{
      color: var(--v-success-base) !important;
    }
}
>>>.alert.warning{
    border-top-color: #fb8c00 !important;
    i.v-alert__icon.v-icon{
      color: #fb8c00 !important;
    }
}
>>>.alert.error{
    border-top-color: #ff5252 !important;
    i.v-alert__icon.v-icon{
      color: #ff5252 !important;
    }
}
>>>.alert.info {
    border-top-color: #2196f3 !important;
    i.v-alert__icon.v-icon{
      color: #2196f3 !important;
    }
  }
>>> div.v-alert a {
  color:#02579e !important;
}
</style>
