<i18n src='./locales.json'></i18n>

<template>
  <v-layout row wrap>
    <v-flex xs12 md7 class="content">
      <div class="font-size--20 font-weight-bold text-xs-center ">
        {{ $t('L_finish_creating_your_debt_stacking_plan') }}
      </div>
      <v-layout
        row
        wrap
        class="header text-xs-center font-size--12"
      >
        <v-flex xs12 class="pl-4 pr-4 ml-4 mr-4">
          {{ $t('L_you_have_taken_the_first_step_finish') }}
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 md5 class="learn-more">
      <v-btn
        block
        outline
        color="white"
        @click="goToMyDebtAndOpenAddADebt"
        :aria-label="$t('L_enter_your_debts')"
        class="setBlueBackground"
        style="background-color: #063562 !important;"
      >
        {{ $t('L_enter_your_debts') }}
      </v-btn>
    </v-flex>

    <v-spacer class="mb-2" />
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Config from '@/shared/util/config';
import { openInNewWindow } from '@/mixins/openNewWindow';

export default {
  name: 'DebtSolutionsV03TopSingle',
  data() {
    return {
      document: {
        title: '',
        content: '',
        type: '',
      },
    };
  },
  mixins: [openInNewWindow],
  computed: {
    ...mapGetters({
      planWOStacking: 'debtsolutions/planWOStacking',
      planWStacking: 'debtsolutions/planWStacking',
    }),
    debtFreeAgeWStacking() {
      return this.planWStacking?.planTotals?.primaryDebtPayoffAge || '';
    },
    WOStackingMonth() {
      return (12 * this.planWOStacking?.planTotals?.debtPayoffYears) + this.planWOStacking?.planTotals?.debtPayoffMonths;
    },
    WStackingMonth() {
      return (12 * this.planWStacking?.planTotals?.debtPayoffYears) + this.planWStacking?.planTotals?.debtPayoffMonths;
    },
    yearsSooner() {
      const yearsSoonerVal = Math.ceil((this.WOStackingMonth - this.WStackingMonth) / 12);
      // eslint-disable-next-line no-restricted-globals
      return (isNaN(yearsSoonerVal)) ? '' : yearsSoonerVal;
    },
    interestAvoided() {
      const diff = this.planWOStacking?.planTotals?.projectedInterestPaid - this.planWStacking?.planTotals?.projectedInterestPaid;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(diff)) {
        return '';
      }
      return this.$n(diff, 'currencyNoCents', 'en-US');
    },
  },
  methods: {
    ...mapActions({
      setAddADebtOpen: 'debtsolutions/setAddADebtOpen',
    }),
    goToMyDebtAndOpenAddADebt() {
      this.setAddADebtOpen(true).then(() => {
        if (this.$route.path !== '/debtsolutions/mydebt') {
          this.$router.push({ name: 'MyDebt' });
        }
      });
    },
    async openImportantDisclosures() {
      await this.openInNewWindow({ url: `${Config.get('content_stack')}/blt676ecb292039a611/MISC-006.pdf`, target: '_blank' });
    },
  },
};
</script>

<style lang="stylus" scoped>
  .v-btn
    border: 2px solid #063562;
    background-color: #063562;
    padding-right:15px !important;
  .v-btn__content
    color: #063562;
  .textBlue
    color: #063562;
  .learn-more
    padding: 0 15px;
  .v-btn
    background-color: #063562 !important
    color: white;
    border-color: white;
  .font-weight-medium
    font-weight: 500;
  .font-size--30
    font-size: 30px;
  .toolTipBox
    height: 320px;
    width: 432px;
    position: relative;
  .smallWidthTooltip
    max-width: 300px;
  .largeWidthTooltip
    max-width: 452px;
  .moveiconup
    position: absolute;
    margin-top: -3px;
  .importantDisclosure
    color: #02579e;
    font-size: 16px;
    font-weight: 600;
  .helpXSBox
    max-width:330px !important;
  .helpLargerBox
    max-width:432px !important;
  .cursorPointer
    cursor: pointer;
  .font-weight-bold
    font-weight: bold;
  .setBlueBackground
    background-color: #063562 !important;
    background: #063562 !important;
    color: white !important;
    max-width: 288px !important;
</style>
