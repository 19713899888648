<template>
  <v-card-text
    class="background-light-grey"
  >
    <v-layout>
      <v-flex
        xs12
      >
        <v-card-title
          class="pb-0
                justify-center"
        >
          <span
            role="heading"
            aria-level="3"
            class="font-weight-regular font-size--18 mb-0"
          >
            <slot name="paymentType" />
          </span>
        </v-card-title>
        <v-card-text
          row
          wrap
        >
          <v-layout
            row
            wrap
            justify-center
            :class="$vuetify.breakpoint.mdAndUp ? 'pl-4': 'pl-2'"
          >
            <v-flex
              xs12
              sm11
              md8
            >
              <slot name="premiumAmountPanel" />
              <v-layout
                row
                wrap
                justify-space-between
                class="mx-4"
              >
                <v-flex
                  xs12
                  sm4
                >
                  <v-layout
                    class="justify-center row wrap"
                    :class="this.$vuetify.breakpoint.xs ? 'text-xs-center' : ''"
                  >
                    <v-flex
                      class="font-weight-600 xs12"
                    >
                      <slot name="paymentAmountLabel" />
                    </v-flex>
                  </v-layout>
                  <v-layout
                    class="justify-center mt-2"
                    :class="this.$vuetify.breakpoint.xs ? 'text-xs-center' : ''"
                  >
                    <v-flex
                      xs12
                    >
                      <slot name="paymentAmount" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  :class="this.$vuetify.breakpoint.xs ? 'mt-4' : ''"
                >
                  <v-layout
                    class="justify-center row wrap"
                    :class="this.$vuetify.breakpoint.xs ? 'text-xs-center' : ''"
                  >
                    <v-flex
                      class="font-weight-600 xs12"
                    >
                      <slot name="nextPaymentDateLabel" />
                    </v-flex>
                  </v-layout>
                  <v-layout
                    class="justify-center mt-2"
                    :class="this.$vuetify.breakpoint.xs ? 'text-xs-center' : ''"
                  >
                    <v-flex
                      class="xs12"
                    >
                      <slot name="nextPaymentDate" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex
        xs12
        class="text-xs-center"
      >
        <v-card-title
          class="pb-0
                justify-center"
        >
          <span
            class="font-weight-regular font-size--18 mb-0"
          >
            <slot name="billingInformationLabel" />
          </span>
        </v-card-title>
        <v-card-text
          class="font-size--14"
        >
          <slot name="description" />
        </v-card-text>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex
        xs12
        class="text-xs-center"
      >
        <v-card-title
          class="pb-0
                justify-center"
        >
          <span
            class="font-weight-600 mb-0"
          >
            <slot name="mailingAddressLabel" />
          </span>
        </v-card-title>
        <v-card-text>
          <slot name="address" />
        </v-card-text>
      </v-flex>
    </v-layout>
  </v-card-text>
</template>
<script>
export default {
  name: 'DirectPaymentPlaceHolder',
};
</script>
