import { render, staticRenderFns } from "./OtherCoverage.vue?vue&type=template&id=547cf63e&scoped=true"
import script from "./OtherCoverage.vue?vue&type=script&lang=js"
export * from "./OtherCoverage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "547cf63e",
  null
  
)

export default component.exports