<i18n src="./locales.json"></i18n>
<template>
  <div>
    <v-text-field
      v-model="formInput.$model"
      :id="bankAccountid"
      class="verify-bank-acct-number"
      type="tel"
      pattern="\d*"
      :label="label || $t('L_verify_account_number') || 'Verify Account Number'"
      :autocomplete="disableAutocomplete ? 'off' : null"
      required
      :color="color"
      :error-messages="errorMessages"
      :maxlength="maxLengthInput"
      @focus="onFocus"
      @blur="onBlur"
      @paste="prevent($event)"
      @input="onInput(), maxLengthCheck($event)"
      @keypress="isNumeric($event)"
      @keydown="noMax($event)"
      :aria-describedby="describers"
    >
      <FieldIcon
        :form-field="formInput"
        icon="lock"
      />
    </v-text-field>
  </div>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import { bankingNumbers } from '@/mixins/bankingNumbers';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'VerifyBankAccountNumber',
  components: { FieldIcon },
  extends: BaseTextField,
  mixins: [bankingNumbers],
  props: {
    bankAccountid: {
      type: String,
      required: false,
      default: 'verify_bank_acct',
    },
    maxLengthInput: {
      type: Number,
      required: false,
      default: 9,
    },
  },
  data: () => ({
    modelName: 'verifyBankAccountNumber',
    verifyBankAccountNumber: '',
  }),
  methods: {
    prevent(e) {
      e.preventDefault();
    },
  },
};


</script>
