<template>
  <v-btn-toggle
    v-model="formInput.$model"
    style="width: 100%; box-shadow: none;"
    mandatory
    @change="onInput"
  >
    <v-btn
      v-for="(option, index) in options"
      :value="option.value"
      :key="index"
      :color="(option.value === formInput.$model) ? 'primary' : 'secondary'"
      :class="(option.value !== formInput.$model) ? 'v-btn--secondary' : ''"
      :aria-selected="option.value === formInput.$model ? 'true' : 'false'"
      role="tab"
      :style="{
        opacity: '1 !important',
        'text-transform': 'none',
        width: `${100 / options.length}%`
      }"
    >
      {{ option.label }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'ButtonGroup',
  extends: BaseTextField,
  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    modelName: 'selectedOption',
    selectedOption: '',
  }),
  mounted() {
    BaseTextField.mounted.bind(this)();
    this.selectedOption = this.value;
  },
};
</script>
