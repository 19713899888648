<i18n src='../../../locales.json'></i18n>

<template>
  <v-layout class="retirement-tracker align-center" row wrap>
    <v-flex xs4 md2 class="icon">
      <img
        class="icon"
        role="presentation"
        :alt="$t('L_pay_yourself_first')"
        src="@/assets/investments/calculator/calreturn_icon.svg"
      >
    </v-flex>
    <v-spacer />
    <v-flex xs7 md5>
      <h4
        class="heading_four pa-0 font-weight-medium"
        :aria-label="retirementGoalPercentage >= 100 ? $t('L_congratulations') : $t('L_retirement_goal_tracker')"
      >
        {{ retirementGoalPercentage >= 100 ? $t('L_congratulations') : $t('L_retirement_goal_tracker') }}
      </h4>
      <div
        class="text"
        :aria-label="retirementGoalPercentage >= 100 ? $t('L_appear_to_be_on_track') : $t('L_are_you_on_track')"
      >
        {{ retirementGoalPercentage >= 100 ? $t('L_appear_to_be_on_track') : $t('L_are_you_on_track') }}
      </div>
    </v-flex>
    <v-flex xs12 md5>
      <v-layout row fill-height align-center justify-center>
        <v-btn
          block
          outline
          class="btn_revisit"
          color="white"
          @click="goToCalculator"
          :aria-label="$t('L_revisit_goals')"
        >
          {{ $t('L_revisit_goals') }}
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Achievement from '../../../../../../components/Achievement/Achievement.vue';

export default {
  name: 'RetirementTracker',
  components: {
    Achievement,
  },
  computed: {
    ...mapGetters('customer', ['investments']),
    retirementGoalPercentage() {
      return this.investments.calculator?.projection?.percentRetirementIncomeGoal || 0;
    },
  },
  methods: {
    goToCalculator() {
      this.$router.push({
        path: '/investments/calculator',
      });
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'


.retirement-tracker
  .btn_revisit
    width: 100%;
    max-width: 288px;
    margin-right: 0px;
    margin-left: 0px;
  .icon
    text-align: center;
    width: 65px;
    height: 65px;
    object-fit: contain;
  .chart
    .heading_three, .heading_four
      display: none !important;
    .heading_four
      margin: 5px 0;
      font-weight: 600;

    .progress-circle
      background-color: #FFFFFF;
      &:after
        background-color: #d8d8d8;

      .left-half-clipper
        .value-bar
          border: 0.5em solid #0B8D1F;

        .first50-bar
          background-color: #0B8D1F;

        &:not(.over50)
          .first50-bar
            background-color: #FFFFFF !important;

      >.display
        color: #333333;
        background-image: none;
</style>
