import { minLength, maxLength } from 'vuelidate/lib/validators';

const validOntarioValues = ['K', 'L', 'M', 'N', 'P'];
const validQuebecValues = ['G', 'H', 'J'];

export default {
  minLength: minLength(6),
  maxLength: maxLength(6),
  correctFormat(value) {
    return !value || !!value.match(/[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\d[ABCEGHJ-NPRSTV-Z]\d/);
  },
};

export const validForTerritory = (territory, postalCode) => () => {
  const stringVal = postalCode || '';
  switch (territory) {
    case 'NL':
      return stringVal.slice(0, 1).toUpperCase() === 'A';
    case 'NS':
      return stringVal.slice(0, 1).toUpperCase() === 'B';
    case 'PE':
      return stringVal.slice(0, 1).toUpperCase() === 'C';
    case 'NB':
      return stringVal.slice(0, 1).toUpperCase() === 'E';
    case 'QC':
    case 'PQ':
      return validQuebecValues.indexOf(stringVal.slice(0, 1).toUpperCase()) !== -1;
    case 'ON':
      return validOntarioValues.indexOf(stringVal.slice(0, 1).toUpperCase()) !== -1;
    case 'MB':
      return stringVal.slice(0, 1).toUpperCase() === 'R';
    case 'SK':
      return stringVal.slice(0, 1).toUpperCase() === 'S';
    case 'AB':
      return stringVal.slice(0, 1).toUpperCase() === 'T';
    case 'BC':
      return stringVal.slice(0, 1).toUpperCase() === 'V';
    case 'NU':
    case 'NT':
      return stringVal.slice(0, 1).toUpperCase() === 'X';
    case 'YT':
      return stringVal.slice(0, 1).toUpperCase() === 'Y';
    default:
      return true;
  }
};
