<i18n src='./locales.json'></i18n>
<template>
  <v-dialog
    :value="show"
    :max-width="maxWidth"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :persistent="persistent"
  >
    <v-card
      :class="modalClass"
    >
      <v-card-text mt-4>
        <h2
          class="heading_two text-xs-center pb-2 mb-4 topSpacingForHeader font-weight-bold"
          aria-live="assertive"
          v-html="header"
        />
        <div class="fixed-top-alert">
          <Alerts context="myaccount" />
        </div>
      </v-card-text>
      <v-layout mt2 class="pb-4">
        <v-flex
          offset-xs1
          xs10
        >
          <slot name="content" />
          <v-layout row wrap class="mb-4">
            <v-flex class="text-xs-center" v-if="!showSendGrid">
              <v-btn
                v-if="showSaveButton"
                block
                color="primary"
                class="btnWidth marginAuto mb-3 "
                :id="saveChangesBtnId"
                @click="removeAlerts('myaccount'), $emit('save')"
                :loading="submitLoading"
                :disabled="!enableSaveButton"
              >
                {{ $t(submitBtnText) }}
              </v-btn>
              <v-btn
                v-if="showCloseButton"
                block
                :color="closeIsActive ? 'primary': 'secondary'"
                class="btnWidth marginAuto mb-3"
                :id="closeBtnId"
                :class="closeBtnClass"
                @click="removeAlerts('myaccount'), $emit('close')"
              >
                {{ $t('L_close') }}
              </v-btn>
            </v-flex>
            <v-flex class="text-xs-center" v-else>
              <v-btn
                block
                color="primary"
                class="btnWidth marginAuto mb-3"
                :id="saveChangesBtnId"
                @click="removeAlerts('myaccount'), $emit('save')"
                :loading="submitLoading"
                :disabled="!enableSaveButton"
              >
                {{ $t('L_send_my_code') }}
              </v-btn>
            </v-flex>
            <v-flex xs12 class="pt-4 pb-4 mt-4 mb-4">
              &nbsp;
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex />
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
import Alerts from '@/components/Alerts.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'MyAccountDialog',
  props: {
    showSendGrid: {
      type: Boolean,
      required: false,
      default: false,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    header: {
      type: String,
      required: true,
    },
    enableSaveButton: {
      type: Boolean,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    modalClass: {
      type: String,
      required: false,
      default: '',
    },
    saveChangesBtnId: {
      type: String,
      required: false,
      default: 'saveId',
    },
    closeBtnId: {
      type: String,
      required: false,
      default: 'closeBtnId',
    },
    closeBtnClass: {
      type: String,
      required: false,
      default: 'mydialog_close',
    },
    closeIsActive: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 446,
    },
    submitBtnText: {
      type: String,
      required: false,
      default: 'L_save_changes',
    },
  },
  components: {
    Alerts,
  },
  computed: {
    ...mapState({ submitLoading: 'submitLoading' }),
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
  },
};
</script>

<style>
.topSpacingForHeader {
  margin-top: 40px;
}
.marginAuto {
  margin: auto !important;
}
.fixed-top-alert {
  position: absolute;
  width: 100%;
  left: 0;
}
.btnWidth {
  max-width: 288px;
  margin: auto;
}

</style>
