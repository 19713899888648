<i18n src='../../../../locales.json'></i18n>

<template>
  <v-flex
    class="restricted-policy-code-box"
    xs12
  >
    <v-card-text>
      <v-layout
        row
        wrap
        class=""
      >
        <v-flex
          xs10
          offset-xs2
        >
          <v-layout align-center>
            <span
              class="policy-number-text"
              v-if="policyList && policyList.length === 1"
            >
              {{ __t('L_policy_number') }}:
              <span
                class="font-weight-regular"
              >
                {{ policyList && policyList[0].contractId }}
              </span>
            </span>
            <template v-else>
              <span class="policy-number-text">{{ __t('L_policy_number') }}</span>
              <v-menu
                ref="content"
                v-if="policyList && policyList.length > 1"
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <span
                  ref="respolicies"
                  class="policy-items-dropdown"
                  slot="activator"
                  role="button"
                  tabindex="0"
                  aria-label="Select a Policy"
                  aria-haspopup="true"
                >
                  {{ policyList[selectedPolicyIndex].contractId }}
                  <v-icon>expand_more</v-icon>
                </span>
                <v-list ref="resitems">
                  <v-list-tile
                    v-for="(item, i) in policyList"
                    :key="item.contractId"
                  >
                    <v-list-tile-title
                      tabindex="-1"
                      @click="setPolicyIndex(i)"
                    >
                      {{ item.contractId }}
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </template>
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          class="zrestricted-policy-message-box mt-3 pb-5 pt-5"
        >
          <h3
            class="heading_three text-xs-center mb-3"
          >
            {{ $t('L_restricted_policy_code_message') }}
          </h3>
          <h3
            v-html="custServNum"
            class="heading_three text-xs-center"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import Ada from '@/shared/ada/ada';
import __t from '../../../../translate';

export default {
  name: 'RestrictedPolicy',
  computed: {
    ...mapGetters({
      policies: 'policy/policies',
      selectedPolicyIndex: 'policy/selectedPolicyIndex',
    }),
    ...mapGetters('customer', {
      getCustServNumWDash: 'getCustServNumWDash',
      getCustServNumWODash: 'getCustServNumWODash',
    }),
    ...mapGetters({
      locale: 'getLocale',
    }),
    policyList() {
      return this.policies?.map(p => ({ contractId: p.contractId }));
    },
    custServNum() {
      if (this.locale?.length > 0) {
        const withDash = this.getCustServNumWDash(this.locale);
        const withoutDash = this.getCustServNumWODash(this.locale);
        const num = this.$t('L_restricted_policy_code_number', {
          phoneWODash: withoutDash,
          phoneWDash: withDash,
        });
        return num;
      }
      return '';
    },
  },
  methods: {
    setPolicyIndex(index) {
      this.$store.dispatch('policy/changePolicy', { selectedIndex: index });
    },
    __t,
  },
  mounted() {
    if (this.$refs.resitems !== undefined) {
      Ada.popupMenu(this.$refs.respolicies, this.$refs.resitems.$el);
    }
  },
};
</script>

<style lang="stylus" scoped>
.policy-items-dropdown
  position: relative;
  border-radius: 13.5px;
  width: 135px;
  height: 27px;
  background: #ebebeb;
  margin: 0;
  padding: 0px 35px 0 10px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #000;
  span
    font-weight 600
  .v-icon
    position: absolute;
    right: 10px;
    top: 2px;
    color: #2196f3;
.policy-number-text
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 0 0;
.restricted-policy-message-box
  background-color: rgba(216, 216, 216, 0.95);
  box-shadow: 0px 0px 1px rgba(0,0,0,0.5) inset;
</style>
