import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=f5e84244&scoped=true"
import script from "./Alert.vue?vue&type=script&lang=js"
export * from "./Alert.vue?vue&type=script&lang=js"
import style0 from "./Alert.vue?vue&type=style&index=0&id=f5e84244&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5e84244",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FAlert%2FAlert.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports