<i18n src='./locales.json'></i18n>

<template>
  <v-dialog id="contact_your_rep_modal" :value="value" :max-width="submitted === true ? 560 : 914" :fullscreen="$vuetify.breakpoint.xsOnly" @input="v => v || $emit('close')">
    <v-card :class="modalClass" v-if="submitted">
      <v-card-text class="pb-0">
        <p class="text-xs-right mb-0">
          <a :class="closeSelector" href="javascript:void(0)" role="button" @click="close()" aria-label="close">
            <v-icon>clear</v-icon>
          </a>
        </p>
      </v-card-text>
      <v-card-text class="pt-0">
        <h2 class="heading_two text-xs-center" aria-live="assertive">
          {{ $t('L_your_message_has_been_sent') }}
        </h2>
      </v-card-text>
      <v-card-text class="pt-0">
        <div class="heading_two text-xs-center font-size--16">
          {{ $t('L_your_representative_will_respond_to_your_message_using') }}
        </div>
      </v-card-text>
      <v-layout justify-start row wrap text-xs-center>
        <v-flex xs12 mb-5 pb-2 text-xs-center class="blueBackgroundForButton">
          <v-btn role="link" color="primary" class="maxSizeForCloseButton OkButton" @click="close()" aria-label="close">
            {{ $t('L_ok') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card v-if="!submitted" :class="modalClass">
      <Alert v-if="err" :id="'errorAlert'" :message="$t('L_contact_send_error')" :auto-dismiss="true" :dismissable="true" @close-alert="err = false" />
      <Alert v-if="success" :auto-dismiss="true" :dismissable="false" type="success" alert-icon="check_circle" :message="$t('L_contact_send_success')" @close-alert="close" />
      <v-card-text class="pb-0">
        <p class="text-xs-right mb-0">
          <a :class="closeSelector" href="javascript:void(0)" role="button" @click="close()" aria-label="close">
            <v-icon>clear</v-icon>
          </a>
        </p>
      </v-card-text>
      <v-card-text class="pt-0">
        <h2 class="heading_two text-xs-center" aria-live="assertive">
          {{ $t('L_contact_contact_your_rep') }}
        </h2>
      </v-card-text>

      <!-- Agent selection -->
      <v-layout v-if="agents.length > 1">
        <v-flex offset-xs1 xs10 offset-md2 offset-lg3 md8 lg6>
          <v-layout row wrap>
            <v-flex xs12 mt-5>
              <div class="font-weight-medium">
                {{ $t('L_contact_select_agent') }}
              </div>
              <div>
                <v-select :items="agents" item-text="displayName" item-value="agentId" v-model="currentAgent" color="black" hide-details />
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!-- Agent selection -->

      <v-layout justify-center style="border: 1px solid blue" v-if="1 == 2">
        <v-flex xs10 md8 lg6>
          <v-layout row wrap>
            <v-flex xs11 sm2 pb-4 mr-2 style="padding-top: 8px">
              <img
                class="rep-image mr-4"
                width="80px"
                height="60px"
                :src="repImage"
                v-img-fallback="repFallbackImage"
                alt="rep image"
              >
            </v-flex>
            <v-spacer />
            <v-flex v-if="agentHasContactInfo" xs12 sm9>
              <v-layout v-if="agents.length === 1" justify-start row wrap class="font-size--16 contact-me" text-xs-center text-sm-left>
                <v-flex :class="this.$vuetify.breakpoint.smAndUp ? 'shrink mr-1' : 'xs12'">
                  <span class="font-weight-medium">
                    {{ agentName }}
                  </span>
                </v-flex>
              </v-layout>
              <v-layout v-if="agentPhone.length > 0" justify-start row wrap class="font-size--16 contact-me" text-xs-center text-sm-left>
                <v-flex :class="this.$vuetify.breakpoint.smAndUp ? 'shrink mr-1' : 'xs12'">
                  <span>
                    {{ $t('L_contact_your_rep_phone_text') }}
                  </span>
                </v-flex>
                <v-flex xs12 sm4 md5>
                  <span
                    v-html="
                      $t('L_contact_your_rep_phone_link', {
                        phone: agentPhone.replace(/(\d{3})-(\d{3})-(\d{4})/, '($1) $2-$3'),
                      })
                    "
                  />
                </v-flex>
              </v-layout>
              <v-layout v-if="agentEmail.length > 0" justify-start row wrap class="font-size--16 contact-me" text-xs-center text-sm-left>
                <v-flex :class="this.$vuetify.breakpoint.smAndUp ? 'shrink mr-1' : 'xs12'" mr-1>
                  <span>
                    {{ $t('L_contact_your_rep_email_text') }}
                  </span>
                </v-flex>
                <v-flex xs12 sm3 md3 pb-2>
                  <span v-html="$t('L_contact_your_rep_email_link', { email: agentEmail })" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-divider />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex offset-xs1 xs10 offset-md2 offset-lg3 md8 lg6>
          <v-layout row wrap>
            <v-flex xs12 mt-5>
              <ReasonInput :form-input="$v.reason" :items="options" v-model="$v.reason.$model" id-prop="contact_your_rep_options" />
            </v-flex>
            <v-flex v-if="$v.reason.$model === 'other'" xs12>
              <SubjectInput v-model="$v.subject.$model" :form-input="$v.subject" />
            </v-flex>
            <v-flex xs12>
              <MessageInput v-model="$v.message.$model" :form-input="$v.message" />
            </v-flex>

            <v-flex xs12 pb-4 text-xs-center>
              <div class="preferLabel text-xs-left">
                {{ $t('L_how_would_you_prefer_to_be_contacted') }}
              </div>
              <div class="buttonCommonCss" :class="contactOption === 1 ? 'blueButtonWhiteText' : 'whiteButtonBlueText'" @click="contactOption = 1">
                {{ $t('L_contact_email') }}
              </div>
              <div class="buttonCommonCss" :class="contactOption === 2 ? 'blueButtonWhiteText' : 'whiteButtonBlueText'" @click="contactOption = 2">
                {{ $t('L_contact_telephone') }}
              </div>
            </v-flex>

            <v-flex xs12 pt-4 v-if="contactOption === 1">
              <EmailInput v-model="$v.email.$model" :form-input="$v.email" :label="$t('L_contact_email')" />
            </v-flex>
            <v-flex xs12 pt-4 v-if="contactOption === 2">
              <PhoneNumberInput v-model="$v.telephone.$model" :form-input="$v.telephone" :label="$t('L_contact_telephone')" />
            </v-flex>
            <v-flex offset-lg1 lg10 xs12 mb-5>
              <v-btn block color="primary" :class="sendButtonClass" :disabled="sendButtonDisable" @click="send()">
                {{ $t('L_contact_send') }}
              </v-btn>
              <v-btn block outline primary class="mt-3" @click="close()" :class="closeSelector" aria-label="close">
                {{ $t('L_contact_clear') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { ReasonInput, SubjectInput, PhoneNumberInput, PhoneNumberValidation, EmailInput, EmailValidation, MessageInput } from '../../components/Inputs';
import Alert from '../Alert/Alert.vue';
import { titleCase, phoneNumber } from '../../shared/util/formatters';

export const requiredIfOther = vm => vm.$v.reason.$model === 'other';

export function closeAfterSuccess() {
  if (this.value) {
    this.success = true;
    // window.setTimeout(this.close.bind(this), 10000);
  }
}

export function afterError() {
  if (this.value) {
    this.err = true;
    this.submitted = false;
    window.setTimeout(() => {
      document.getElementById('errorAlert').scrollIntoView();
    }, 0);
    window.setTimeout(() => {
      this.err = false;
    }, 10000);
  }
}

export default {
  name: 'ContactRep',
  components: {
    Alert,
    ReasonInput,
    SubjectInput,
    MessageInput,
    EmailInput,
    PhoneNumberInput,
  },
  data: () => ({
    submitted: false,
    telephone: '',
    email: '',
    message: '',
    subject: '',
    messageMaxLength: 500,
    reason: '',
    err: false,
    success: false,
    agent: {},
    currentAgent: '',
    contactOption: '',
  }),
  validations: {
    reason: {
      required,
    },
    message: {},
    contactOption: {
      required,
    },
    email: {
      required,
      ...EmailValidation,
    },
    telephone: {
      ...PhoneNumberValidation,
    },
    subject: {
      required: requiredIf(requiredIfOther),
      maxlength: maxLength(60),
    },
  },
  props: {
    agents: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    closeSelector: {
      type: String,
      required: false,
      default: 'modal_close',
    },
    modalClass: {
      type: String,
      required: false,
      default: 'modal_close',
    },
    sender: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    sendButtonDisable() {
      let disable = false;
      if (this.reason === null || this.reason.length === 0) {
        disable = true;
      }
      if (this.contactOption === '') {
        disable = true;
      }
      if (this.$v.email.$invalid && this.contactOption === 1) {
        disable = true;
      }
      if (this.contactOption === 2 && (this.reason.length === 0 || this.telephone.length < 10)) {
        disable = true;
      }
      return disable;
    },
    agentName() {
      return this.agent?.name ? titleCase(this.agent.name) : '';
    },
    agentEmail() {
      return this.agent?.email?.length > 0 ? this.agent.email.toLowerCase() : '';
    },
    agentPhone() {
      return phoneNumber(this.agent?.office?.phone);
    },
    agentHasContactInfo() {
      return this.agent?.email?.length > 0 || this.agentPhone?.length > 0;
    },
    repImage() {
      return this.agent?.displayPic;
    },
    repFallbackImage() {
      // eslint-disable-next-line
      return require('./assets/rep-thumb.svg');
    },
    sendButtonClass() {
      if (this.$v.reason.$model === 'question') {
        return 'email_rep_about_policy';
      }
      if (this.$v.reason.$model) {
        return `email_rep_about_${this.$v.reason.$model}`;
      }
      return 'email_rep_about_option';
    },
    options() {
      let investments = [];
      let lifeInsurance = [];
      if (this.agent?.licensedProducts) {
        lifeInsurance = this.agent?.licensedProducts.find(product => product === 'Term Life Insurance');
        investments = this.agent?.licensedProducts.find(product => product.indexOf('Annuities') > -1 || product === 'Securities');
      }
      const options = [];
      if (lifeInsurance) {
        options.push(this.$t('L_contact_select_question'));
        options.push(this.$t('L_contact_select_coverage'));
      }
      if (investments) {
        options.push(this.$t('L_contact_select_investing'));
      }
      options.push(this.$t('L_contact_select_other'));
      return options;
    },
  },
  methods: {
    close() {
      this.success = false;
      this.err = false;
      this.$emit('close');
      this.$v.message.$model = '';
      this.$v.subject.$model = '';
      this.$v.reason.$model = '';
      this.contactOption = '';
      this.$v.$reset();
      this.submitted = false;
    },
    send() {
      this.submitted = true;
      const subject = this.$v.reason.$model;
      if (this.contactOption === 1) {
        this.$emit('send-message', {
          agentId: this.agent?.agentId,
          sender: {
            emailAddress: this.$v.email.$model,
            firstName: this.sender.firstName,
            lastName: this.sender.lastName,
            phoneNumber: this.sender.phone,
            preferredContact: 'EMAIL',
          },
          reason: subject,
          message: this.$v.message.$model,
        });
      } else if (this.contactOption === 2) {
        this.$emit('send-message', {
          agentId: this.agent?.agentId,
          sender: {
            emailAddress: this.$v.email.$model,
            firstName: this.sender.firstName,
            lastName: this.sender.lastName,
            phoneNumber: this.sender.phone,
            preferredContact: 'PHONE',
          },
          reason: subject,
          message: this.$v.message.$model,
        });
      }
    },
  },
  mounted() {
    this.submitted = false;
    if (this.agents) {
      [this.agent] = this.agents;
      this.currentAgent = this.agent?.agentId;
    }
    this.$eventBus.$on('show-contact-rep-modal-success', closeAfterSuccess.bind(this));
    this.$eventBus.$on('show-contact-rep-modal-error', afterError.bind(this));
    if (this.sender) {
      this.telephone = this.sender.phone;
      this.email = this.sender.email;
    }
    this.contactOption = '';
  },
  created() {
    this.submitted = false;
  },
  watch: {
    currentAgent(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.agent = this.agents?.find(a => a.agentId === newVal);
      }
    },
    agents(newVal) {
      if (newVal) {
        [this.agent] = newVal;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.rep-name {
  font-size: 16px !important;
}

.blueButtonWhiteText {
  background-color: #063562;
  color: #ffffff;
}

.whiteButtonBlueText {
  background-color: #ffffff;
  color: #063562;
}

.buttonCommonCss {
  text-align: center;
  font-size: 16px;
  width: 135px;
  height: 40px;
  line-height: 24px;
  border: 2px solid #063562;
  padding-top: 8px;
  display: inline-block;
  font-weight: 600;
}

.maxSizeForCloseButton {
  max-width: 360px !important;
}

.OkButton {
  margin: auto !important;
  background-color: #063562 !important;
  color: white;
  font-weight: 600;
  width: 280px;
}

.contact-me {
  line-height: 2em;
}

.preferLabel {
  font-weight: 500;
  padding-bottom: 22px;
}

/deep/ label.v-label.theme--light.v-label--active {
  font-weight: 500 !important;
  color: #000 !important;
  margin-top: -12px;
}

/deep/ .v-input {
  &.v-input--is-disabled {
    .v-input__slot:before {
      border-image: none;
    }
  }

  input {
    color: #767575;
  }
}

/deep/ .v-btn__content {
  font-weight: 500 !important;
}

test {
  background-color: red;
}
</style>

