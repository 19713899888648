/* eslint-disable import/first, no-restricted-globals */

// Transpiler for older browsers
import 'core-js/stable';

// IFrame Loading Mitigation
if (self !== top) {
  throw new Error('App may not be loaded in an iframe!');
}

import 'babel-polyfill';
import Vue from 'vue';
import axios from 'axios';
import { FeatureDirective, ImageFallbackDirective, AdaModalDirective, ClickOutside } from '@/directives';
import { DynatracePlugin } from './plugins/dynatracePlugin';
import EventBus from './plugins/eventBus';
import CapacitorPlugin from './plugins/capacitorPlugin';
import AxiosPlugin from './plugins/axiosPlugin';
import AuthPlugin, { getAuthClient } from './plugins/auth/index';
import setupCollection from '@/shared/deviceDna';
import Config from '@/shared/util/config';
import './plugins/vuetify';
import './plugins/vuelidate';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import i18n from './plugins/i18n';
import { VAULT_ERROR_CODES } from './plugins/auth/utils/definitions';

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  try {
    DynatracePlugin.getInstance().reportCustomError('PC2_VUE_ERR', `message: ${err?.message}\n pageTitle: ${vm.$route?.meta?.pageTitle} \n route: ${vm.$route?.name}\n`, `${info}`, false);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

Vue.use(CapacitorPlugin);
Vue.use(DynatracePlugin);
Vue.use(AuthPlugin);
Vue.use(FeatureDirective);
Vue.use(AxiosPlugin(axios));
Vue.use(ImageFallbackDirective);
Vue.use(AdaModalDirective);
Vue.use(EventBus);
Vue.use(ClickOutside);

(() => {
  if (typeof Element.prototype.append === 'undefined') {
    Element.prototype.append = Element.prototype.appendChild;
  }
  if (typeof Element.prototype.prepend === 'undefined') {
    Element.prototype.prepend = Element.prototype.appendChild;
  }
})();

const startApp = async () => {
  await AuthPlugin.initialize(); // ? initialize auth plugin

  await store.dispatch('buildUserSessionMetaInfo'); // ? build session/device meta info

  // TODO: refactor and move the vaultErrorHandler in a separate file
  AuthPlugin.config.setVaultErrorHandler(async (err) => {
    try {
      // send DT customError and send log to activity_log
      DynatracePlugin.getInstance().reportCustomError('PC2_MOBILE_ERR', `[Vault] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
      await store.dispatch('postAuditLog', {
        actionId: 'MobileApp_Vault_LoginFailureAction', // optional
        details: `[${Date.now()}] ERROR CODE ${err?.code}`, // Additional message stored - optional
        message: err?.message, // Error type - optional
      });
      if (Config.get('env') !== 'prod') {
        const em = {
          error: {
            key: `vaultError ${err?.code}: ${err?.message}`,
            message: `[Vault] ${err?.code}: ${err?.message || JSON.stringify(err)}`,
          },
        };
        store.commit('addError', { ...{ context: 'app' }, ...em });
        store.commit('addError', { ...{ context: 'vault' }, ...em });
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    /*
    TODO: implement to use store and implement pub/sub
    so subscribers can implement error handling in their lexical scopes
    */
    const currentRouteIsLoginLandingPage = router?.currentRoute?.name === 'LoginLandingPage';
    switch (err?.code) {
      case VAULT_ERROR_CODES.UserCanceledInteraction:
        if (!currentRouteIsLoginLandingPage) await router.push({ name: 'LoginLandingPage' });
        break;
      // handle error code 0: see https://ionic.io/docs/identity-vault/troubleshooting#not-handling-error-code-0
      case VAULT_ERROR_CODES.Unknown:
        // handle the Unknown Vault Error by clearing the Vault and routing the app to a login page
        getAuthClient().clearLocalAuthSession(); // clear the vault
        if (!currentRouteIsLoginLandingPage) await router.push({ name: 'LoginLandingPage' });
        break;
      default:
        if (!currentRouteIsLoginLandingPage) await router.push({ name: 'LoginLandingPage' });
        break;
    }
  });
  const deviceDna = await setupCollection();
  store.commit('deviceDNA', deviceDna);

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
};

startApp();
