<template>
  <div>
    <v-text-field
      v-model="displayValue"
      :mask="mask"
      pattern="[0-9]*"
      inputmode="numeric"
      class="text-xs-center font-size--24 pb-0"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      :aria-live="notify ? 'assertive' : 'off'"
      :aria-labelledby="labelId"
      :aria-describedby="toolTipId"
      :hide-details="hideDetails"
      :aria-label="labelId"
    />
  </div>
</template>

<script>

export default {
  name: 'CurrencyDisplay',
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    maxLength: {
      type: Number,
      default: 9,
    },
    notify: {
      type: Boolean,
      default: false,
    },
    labelId: {
      type: String,
      required: false,
      default: () => '',
    },
    toolTipId: {
      type: String,
      required: false,
      default: () => '',
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    displayValue: undefined,
  }),
  computed: {
    mask() {
      if (typeof (this.displayValue) === 'undefined') {
        return '$###';
      }
      const numbers = this.displayValue.split('').map((char, i) => {
        const pos = (this.displayValue.length - (i + 1));
        return `#${pos > 0 && pos % 3 === 0 ? ',' : ''}`;
      }).join('');
      return `$${numbers}${numbers.length < (this.maxLength + 1) ? '#' : ''}`;
    },
    describerId() {
      return `sr_span_${this.labelId}`;
    },
  },
  watch: {
    value(value) {
      if (value.length === 0 || (value.length > 0 && +value === 0)) {
        return;
      }
      this.displayValue = `${+value}`;
    },
  },
  mounted() {
    this.displayValue = `${this.value}`;
  },
};
</script>

<style>
.screen-reader-span{
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
</style>

