<i18n src='./locales.json'></i18n>

<template>
  <div>
    <v-flex
      xs12
      pl-5
      pr-4
    >
      <PayOffMortgage
        :form-input="$v.mortgage"
        v-model="mortgage"
        @input="onInput"
      />
      <PayOffDebt
        :form-input="$v.debt"
        v-model="debt"
        @input="onInput"
      />
      <FundEducation
        :form-input="$v.education"
        v-model="education"
        @input="onInput"
      />
      <CoverFuneralExpenses
        :form-input="$v.expenses"
        v-model="expenses"
        @input="onInput"
      />
    </v-flex>
    <v-flex
      xs12
      pl-4
      mt-1
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs7
          sm7
          mt-1
          pl-4
          pb-4
        >
          <div
            id="immediate_cash_needs_ada"
            class="heading_four font-weight-medium"
          >
            {{ $t('L_add_spouse') }}
          </div>
        </v-flex>
        <v-flex
          xs5
          sm3
          offset-sm1
          offset-md2
        >
          <v-switch
            aria-labelledby="immediate_cash_needs_ada"
            v-model="spouseIncluded"
            class="mt-0 pb-0"
            style="width: 156px !important"
            @change="$emit('include-spouse', $event)"
          >
            <span
              slot="prepend"
              class="font-size--16 font-weight-medium mt-1 mr-2"
            >{{ $t('L_no') }}</span>
            <span
              slot="append"
              class="font-size--16 font-weight-medium mt-1 ml-2"
              style="margin-left: -4.6em;"
            >{{ $t('L_yes') }}</span>
          </v-switch>
        </v-flex>
      </v-layout>
    </v-flex>
    <!-- <v-flex
      v-if="!includeSpouse"
      xs12
    >
      <v-layout row>
        <v-flex
          mt-3
          pt-3
          mb-5
          xs12
          sm8
          offset-sm2
          md6
          offset-md3
          pl-4
          pr-4
        >
          <v-btn
            block
            color="primary"
            @click="$emit('next')"
            id="guidance_calculator_btn"
          >
            {{ $t('L_next') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex> -->
    <v-flex
      xs12
      v-if="includeSpouse"
      class="spouse"
    >
      <div
        slot="header"
        class="heading_two pa-4 ml-2 expansion-panel-header"
      >
        <img
          class="heading-icon"
          src="../../assets/immediate.svg"
          alt="immediate cash need"
        >
        <span>
          {{ $t('L_spouse_immediate_needs') }}
        </span>
      </div>
      <v-flex
        xs12
        pl-5
        pr-4
      >
        <PayOffMortgage
          :form-input="$v.spouseMortgage"
          v-model="spouseMortgage"
          @input="onInput"
        />
        <PayOffDebt
          :form-input="$v.spouseDebt"
          v-model="spouseDebt"
          @input="onInput"
        />
        <FundEducation
          :form-input="$v.spouseEducation"
          v-model="spouseEducation"
          @input="onInput"
        />
        <CoverFuneralExpenses
          :form-input="$v.spouseExpenses"
          v-model="spouseExpenses"
          @input="onInput"
        />
      </v-flex>
      <!-- <v-flex xs12>
        <v-layout row>
          <v-flex
            mt-3
            pt-3
            mb-5
            xs12
            sm8
            offset-sm2
            md6
            offset-md3
            pl-4
            pr-4
          >
            <v-btn
              block
              color="primary"
              @click="$emit('next')"
            >
              {{ $t('L_next') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex> -->
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PayOffMortgage from './components/PayOffMortgage.vue';
import PayOffDebt from './components/PayOffDebt.vue';
import CoverFuneralExpenses from './components/CoverFuneralExpenses.vue';
import FundEducation from './components/FundEducation.vue';

export default {
  name: 'ImmediateCashNeeds',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dimeData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => ({
    spouse: false,
    mortgage: 0,
    debt: 0,
    expenses: 0,
    education: 0,
    spouseMortgage: 0,
    spouseDebt: 0,
    spouseExpenses: 0,
    spouseEducation: 0,
    spouseIncluded: false,
    panels: [
      true,
      true,
    ],
  }),
  components: {
    FundEducation,
    CoverFuneralExpenses,
    PayOffDebt,
    PayOffMortgage,
  },
  validations: {
    mortgage: {},
    debt: {},
    expenses: {},
    education: {},
    spouseMortgage: {},
    spouseDebt: {},
    spouseExpenses: {},
    spouseEducation: {},
  },
  computed: {
    ...mapGetters('dime', {
      includeSpouse: 'includeSpouse',
    }),
  },
  methods: {
    onInput() {
      this.$emit('updated', {
        you: {
          mortgage: +this.mortgage,
          debt: +this.debt,
          education: +this.education,
          funeral: +this.expenses,
        },
        spouse: {
          mortgage: +this.spouseMortgage,
          debt: +this.spouseDebt,
          education: +this.spouseEducation,
          funeral: +this.spouseExpenses,
        },
      });
    },
  },
  mounted() {
    this.mortgage = this.$v.mortgage.$model = this.dimeData.you.mortgage;
    this.debt = this.$v.debt.$model = this.dimeData.you.debt;
    this.education = this.$v.education.$model = this.dimeData.you.education;
    this.expenses = this.$v.expenses.$model = this.dimeData.you.funeral;
    this.spouseMortgage = this.$v.spouseMortgage.$model = this.dimeData.spouse.mortgage;
    this.spouseDebt = this.$v.spouseDebt.$model = this.dimeData.spouse.debt;
    this.spouseEducation = this.$v.spouseEducation.$model = this.dimeData.spouse.education;
    this.spouseExpenses = this.$v.spouseExpenses.$model = this.dimeData.spouse.funeral;
    this.spouseIncluded = !!this.includeSpouse;
  },
  watch: {
    dimeData(value) {
      this.spouseMortgage = this.$v.spouseMortgage.$model = `${value?.spouse?.mortgage}`;
      this.spouseDebt = this.$v.spouseDebt.$model = `${value?.spouse?.debt}`;
      this.spouseEducation = this.$v.spouseEducation.$model = `${value?.spouse?.education}`;
      this.spouseExpenses = this.$v.spouseExpenses.$model = `${value?.spouse?.funeral}`;
    },
  },
};
</script>

<style lang="stylus" scoped>
$superSmall = 'only screen and (max-width: 399px)';

@media $superSmall {
  .pl-5.pr-4 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.spouse {
  background-color: #e0e0e0 !important;
  border-top: none !important;

  .v-expansion-panel__header {
    border-top: none !important;
  }
}

.expansion-panel-header {
  @media $superSmall {
    padding: 16px !important;
    font-size: 18px !important;

    img.header-icon {
      height: 2em;
    }
  }
}
</style>
