<i18n src='./locales.json'></i18n>

<template>
  <v-content
    id="policy_details"
    class="policy-overview-content pt-0"
    role="presentation"
    style="max-width:1024px !important; margin: auto;"
  >
    <div v-if="beneficiaryStep === -1 && paymentStep === -1 && withDrawalDateStep === -1">
      <v-container
        class="maxContainerWidth"
        v-if="showRestrict && accessNotNone"
      >
        <RestrictedPolicy />
      </v-container>
      <v-container
        class="maxContainerWidth"
      >
        <!-- My Primerica Header -->
        <v-layout
          row
          wrap
        >
          <v-flex
            d-flex
            align-center
            xs12
          >
            <v-layout
              row
              wrap
              class="my-primerica-header-layout white--text column-at-xs"
            >
              <v-flex
                d-flex
              >
                <v-layout
                  mb-3
                >
                  <!-- My Primerica Header -->
                  <v-flex
                    shrink
                    mr-3
                    d-flex
                    align-center
                  >
                    <svg width="55px" height="40px" viewBox="15 10 42 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Life Insurance</title>
                      <defs>
                        <radialGradient cx="51.4182051%" cy="82.466338%" fx="51.4182051%" fy="82.466338%" r="70.4153415%"
                                        gradientTransform="translate(0.514182,0.824663),scale(0.698177,1.000000),rotate(77.265059),scale(1.000000,1.064158),translate(-0.514182,-0.824663)"
                                        id="radialGradient-511"
                        >
                          <stop stop-color="#36A5DD" offset="0%" />
                          <stop stop-color="#286EB6" offset="100%" />
                        </radialGradient>
                        <radialGradient cx="51.4182051%" cy="82.466338%" fx="51.4182051%" fy="82.466338%" r="303.039601%"
                                        gradientTransform="translate(0.514182,0.824663),scale(0.234861,1.000000),rotate(45.568421),scale(1.000000,1.064158),translate(-0.514182,-0.824663)"
                                        id="radialGradient-611"
                        >
                          <stop stop-color="#36A5DD" offset="0%" />
                          <stop stop-color="#286EB6" offset="100%" />
                        </radialGradient>
                      </defs>
                      <g id="Nav-House" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Blue-Level-Icon2" transform="translate(12.000000, 11.000000)">
                        <g>
                          <polygon id="Rectangle" fill="#18344E" points="6.39435792 8.78385542 19.6767558 1.41036145 32.9435943 8.78385542 32.9591537 25.7838554 6.39435792 25.7838554" />
                          <path
                            d="M0.0481927711,10.1108701 L4.76705398,10.1181643 L4.76705398,27.2296386
                            L34.468165,27.2296386 L34.468165,10.1181643 L39.0481928,10.1181643 L31.4197199,6.14855624 L31.4191647,0.143222694 L26.1423416,0.143222694
                            L26.1423416,3.4009463 L19.6152197,0.000722891566 L0.0481927711,10.1108701 Z M6.40023796,9.21092306 L19.5343354,2.41036145 L32.939759,9.35099862
                            L32.939759,19.759759 L6.39156627,19.759759 L6.40023796,9.21092306 Z"
                            id="Blue-Level-Icon" fill="url(#radialGradient-511)"
                          />
                          <path
                            d="M6.89402838,19.259759 L6.89057366,24.499759 L32.4563424,24.499759 L32.4589087,19.259759 L6.89402838,19.259759 Z"
                            id="Rectangle" stroke="#18344E" fill="url(#radialGradient-611)"
                          />
                        </g>
                      </g>
                      </g>
                    </svg>
                  </v-flex>
                  <v-flex
                    d-flex
                    align-center
                  >
                    <h2
                      role="heading"
                      aria-level="1"
                      class="heading_two"
                    >
                      {{ __t(titleLocaleKey) }}
                    </h2>
                  </v-flex>
                </v-layout>
              </v-flex>
              <div
                v-if="havePolicies && !loading"
              >
                <!-- View Policy Dropdown -->
                <v-flex
                  class="view-policy-section"
                  d-flex
                  v-if="policiesForSelect.length > 1"
                >
                  <v-subheader class="white--text">
                    <div>
                      {{ $t('L_policy_view_policy') }}
                    </div>
                  </v-subheader>
                  <v-select
                    :items="policiesForSelect"
                    :value="selectedPolicyIndex"
                    solo
                    single-line
                    @input="changePolicy"
                  />
                </v-flex>

                <!-- Policy Number -->
                <v-flex
                  class="view-policy-section policy-number"
                  d-flex
                  v-else
                >
                  <v-subheader class="white--text">
                    <div>
                      {{ $t('L_policy_policy_number') }}:
                      <span class="font-weight-regular">{{ policiesForCardStack[policiesForCardStack.length - 1].policyNumber }}</span>
                    </div>
                  </v-subheader>
                </v-flex>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- Keeping it just in case if we need in future What Do I Have -->
        <!-- <WhatDoIHave
          v-if="coverageAmount"
          :coverage-amount="coverageAmount"
          :is-pending="isPending"
        /> -->

        <PaginationDots
          :num-items="policies.length"
          :current-index="selectedPolicyIndex"
          @clicked-dot="changePolicy"
          v-if="policiesForCardStack.length > 1"
        />

        <v-layout row wrap>
          <!-- Policy Overview -->
          <v-flex xs12>
            <v-tabs
              id="policy_tabs_main"
              v-model="activeTab"
              grow
              color="white"
              class="pri-tabs"
              slider-color="transparent"
              aria-orientation="horizontal"
              fixed-tabs
            >
              <v-tab
                v-show="havePolicies"
                ref="mypolicytab"
                @click="$router.replace({name: 'PolicyDetails', params: {contractId: selectedPolicyIndex}})"
                color="black"
                id="my_policy_tab"
              >
                <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ __t('L_policy_my_policy') }}</span>
              </v-tab>
              <v-tab
                v-show="showGuidanceCalculatorTab"
                :ref="'guidancecalc'"
                @click="$router.replace({name: 'GuidanceCalculator'})"
                :id="'guidance_calculator_tab'"
              >
                <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_policy_guidance_calculator') }}</span>
              </v-tab>
              <v-tab
                ref="termbenefits"
                @click="$router.replace({name: 'TermLifeBenefits'})"
                id="termlife_benefits_tab"
              >
                <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_policy_learn_more') }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              class="pri-tab-items"
            >
              <v-tab-item
                v-touch="{
                  left: () => swipeLeft(),
                  right: () => swipeRight(),
                }"
              >
                <LoadingIndicator
                  :loading="isLoading"
                />
                <transition
                  :name="routerTransitionName"
                  mode="out-in"
                >
                  <router-view />
                </transition>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div v-if="beneficiaryStep > -1">
      <v-container
        class="maxContainerWidth"
      >
        <UpdateBeneficiaryLayout />
      </v-container>
    </div>
    <div v-if="paymentStep > -1">
      <v-container
        class="maxContainerWidth"
      >
        <!-- My Primerica Header -->
        <v-layout
          row
          wrap
        >
          <v-flex
            d-flex
            align-center
            xs12
          >
            <v-layout
              row
              wrap
              class="my-primerica-header-layout white--text column-at-xs"
            >
              <v-flex
                d-flex
              >
                <v-layout>
                  <v-flex shrink mr-3>
                    <v-icon color="#d2dee9" :class="$vuetify.breakpoint.xsOnly?'dollarSignSmall': 'dollarSignBig'">
                      monetization_on
                    </v-icon>
                  </v-flex>
                  <v-flex shrink class="">
                    <span
                      aria-level="1"
                      :class="$vuetify.breakpoint.xsOnly?'makeAPaymentFontSmall': 'makeAPaymentFontBig'"
                    >
                      {{ $t('L_make_a_payment') }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <div
                v-if="havePolicies"
              >
                <!-- View Policy Dropdown -->
                <!-- <v-flex
                  class="view-policy-section"
                  d-flex
                  v-if="policiesForSelect.length > 1"
                >
                  <v-subheader class="white--text">
                    <div>
                      {{ $t('L_policy_view_policy') }}
                    </div>
                  </v-subheader>
                  <v-select
                    :items="policiesForSelect"
                    :value="selectedPolicyIndex"
                    solo
                    single-line
                    @input="changePolicy"
                  />
                </v-flex> -->

                <!-- Policy Number -->
                <v-flex
                  class="view-policy-section policy-number"
                  d-flex
                >
                  <v-subheader class="white--text">
                    <div>
                      {{ $t('L_policy_policy_number') }}:
                      <span class="font-weight-regular">{{ policiesForCardStack[policiesForCardStack.length - 1].policyNumber }}</span>
                    </div>
                  </v-subheader>
                </v-flex>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
        <PaymentMethodLayout />
      </v-container>
    </div>
    <div v-if="withDrawalDateStep > -1">
      <v-container
        class="maxContainerWidth"
      >
        <!-- My Primerica Header -->
        <v-layout
          row
          wrap
        >
          <v-flex
            d-flex
            align-center
            xs12
          >
            <v-layout
              row
              wrap
              class="my-primerica-header-layout white--text column-at-xs"
            >
              <v-flex
                d-flex
              >
                <v-layout>
                  <v-flex shrink mr-3 class="withDrawalIcon">
                    <v-icon color="#d2dee9" :class="$vuetify.breakpoint.xsOnly?'dollarSignSmall': 'dollarSignBig'">
                      date_range
                    </v-icon>
                  </v-flex>
                  <v-flex shrink class="withDrawalTitle">
                    <span
                      aria-level="1"
                      :class="$vuetify.breakpoint.xsOnly?'font-size--20': 'font-size--20'"
                    >
                      {{ $t('L_update_monthly_withdrawal_day') }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <div
                v-if="havePolicies"
              >
                <!-- View Policy Dropdown -->
                <!-- <v-flex
                  class="view-policy-section"
                  d-flex
                  v-if="policiesForSelect.length > 1"
                >
                  <v-subheader class="white--text">
                    <div>
                      {{ $t('L_policy_view_policy') }}
                    </div>
                  </v-subheader>
                  <v-select
                    :items="policiesForSelect"
                    :value="selectedPolicyIndex"
                    solo
                    single-line
                    @input="changePolicy"
                  />
                </v-flex> -->

                <!-- Policy Number -->
                <v-flex
                  class="view-policy-section policy-number"
                  d-flex
                >
                  <v-subheader class="white--text">
                    <div>
                      {{ $t('L_policy_policy_number') }}:
                      <span class="font-weight-regular">{{ policiesForCardStack[policiesForCardStack.length - 1].policyNumber }}</span>
                    </div>
                  </v-subheader>
                </v-flex>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
        <WithDrawalDateLayout />
      </v-container>
    </div>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaginationDots from '@/components/PaginationDots/PaginationDots.vue';
import Ada from '@/shared/ada/ada';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import WhatDoIHave from './components/WhatDoIHave/WhatDoIHave.vue';
import PolicyDetailsCards from './components/PolicyDetailsCards/PolicyDetailsCards.vue';
import __t from './translate';
import RestrictedPolicy from './components/Restricted/Restricted.vue';
import UpdateBeneficiaryLayout from './components/Beneficiaries/components/UpdateBeneficiaries/UpdateBeneficiaryLayout.vue';
import PaymentMethodLayout from '../PaymentMethod/PaymentMethodLayout.vue';
import WithDrawalDateLayout from '../WithDrawalDateLayout/WithDrawalDateLayout.vue';


export function onResize() {
  this.showNavButtons = document.documentElement.clientWidth > 1339;
}

export default {
  name: 'PolicyDetails',
  data: () => ({
    showNavButtons: document.documentElement.clientWidth > 1339,
    routerTransitionName: null,
  }),
  computed: {
    ...mapGetters('policy', {
      currentPolicy: 'currentPolicy',
      isPending: 'isPending',
      policies: 'policies',
      selectedPolicyIndex: 'selectedPolicyIndex',
      restrictCodeMessage: 'restrictCodeMessage',
      clientCommunicationsPhone: 'clientCommunicationsPhone',
      beneficiaryStep: 'beneficiaryStep',
      paymentStep: 'paymentStep',
      withDrawalDateStep: 'withDrawalDateStep',
    }),
    ...mapGetters({
      dimeLoading: 'dime/loading',
      paymentInfoLoading: 'policy/paymentInfoLoading',
      loading: 'policy/policyInfoLoading',
    }),
    ...mapGetters({
      isMobileApp: 'isMobileApp',
    }),
    accessNotNone() {
      return this.currentPolicy?.data?.access !== 'NONE';
    },
    showRestrict() {
      if (this.policiesForSelect.length === 0) {
        if (this.$route.name === 'TermLifeBenefits' || this.$route.name === 'GuidanceCalculator') {
          return false;
        }
        return true;
      }
      return '';
    },
    activeTab: {
      get() {
        switch (this.$route.name) {
          case 'GuidanceCalculator':
            return 1;
          case 'TermLifeBenefits':
            return 2;
          default:
        }
        return 0;
      },
      set() {},
    },
    havePolicies() {
      return !!(this.policiesForSelect.length > 0);
    },
    showGuidanceCalculatorTab() {
      return !this.isMobileApp;
    },
    nextButtonDisabled() {
      return this.selectedPolicyIndex >= this.policiesForSelect.length - 1;
    },
    prevButtonDisabled() {
      return this.selectedPolicyIndex < 1;
    },
    policiesForSelect() {
      return this.policies
        .filter(policy => !policy.restricted && policy.contractId !== 0)
        .map((policy, index) => ({
          text: this.formatSelectText(policy),
          value: index,
        }));
    },
    policiesForCardStack() {
      const allPolicies = this?.policies?.slice(this.selectedPolicyIndex, this.policies.length)?.reverse();
      return allPolicies.filter(policy => !policy.restricted && policy.contractId !== 0);
    },
    selectedPolicy() {
      return this.policies[this.selectedPolicyIndex];
    },
    titleLocaleKey() {
      const company = this.selectedPolicy?.contractId && this.selectedPolicy?.contractId?.slice(0, 2);
      switch (company) {
        case '05':
        case '10':
          return 'L_policy_my_canada_life';
        case '34':
          return 'L_policy_my_nb_life';
        default:
          return 'L_policy_my_primerica_life';
      }
    },
    coverageAmount() {
      return this.selectedPolicy?.totalCoverage;
    },
    isLoading() {
      return this.loading || this.dimeLoading || this.paymentInfoLoading;
    },
  },
  methods: {
    ...mapActions('childrider', {
      pullPackages: 'pullPackages',
    }),
    __t,
    async changePolicy(index) {
      this.$store.dispatch('policy/changePolicy', { selectedIndex: index });
      await this.$router.push({ name: 'GuidanceCalculator' });
      this.$router.push({ name: 'PolicyDetails', params: { contractId: this.indexPolicy } });
    },
    nextPolicy() {
      this.$store.dispatch('policy/nextPolicy');
    },
    prevPolicy() {
      this.$store.dispatch('policy/prevPolicy');
    },
    isFirstPolicy(index) {
      return index === this.policiesForCardStack.length - 1;
    },
    cardClass(index) {
      const reverseIndex = this.policiesForCardStack.length - (index + 1);
      if (reverseIndex >= 3) {
        return 'last-cards';
      }
      return `card-${reverseIndex}${index === this.selectedPolicyIndex ? ' policy-active' : ''}`;
    },
    formatSelectText(policy) {
      if (!policy) {
        return '';
      }
      return `${policy?.insuredName?.slice(0, 6)} ${policy.contractId || ''}`;
    },
    swipeLeft() {
      if (this.$route.name === 'PolicyDetails') {
        this.nextPolicy();
      }
    },
    swipeRight() {
      if (this.$route.name === 'PolicyDetails') {
        this.prevPolicy();
      }
    },
    adaActions(tabs) {
      Ada.makeTabs(tabs);
    },
  },
  async mounted() {
    if (!this.accessNotNone) {
      this.$router.push({ path: '/policy/guidance' });
    }
    // await this.$store.dispatch('policy/getPolicyOverview');
    this.pullPackages();
    window.addEventListener('resize', onResize.bind(this));
    const tabs = document.getElementById('policy_tabs_main');
    this.adaActions(tabs);
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      throw e;
    }
  },
  watch: {
    $route(to, from) {
      const fromPath = from.meta.indexOfRoute;
      const toPath = to.meta.indexOfRoute;
      this.routerTransitionName = fromPath < toPath ? 'card-slide-right' : 'card-slide-left';
    },
  },
  components: {
    RestrictedPolicy,
    UpdateBeneficiaryLayout,
    PolicyDetailsCards,
    WhatDoIHave,
    PaginationDots,
    LoadingIndicator,
    PaymentMethodLayout,
    WithDrawalDateLayout,
  },
};
</script>


<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
@media $display-breakpoints.lg-and-up, $display-breakpoints.sm-and-down
  .v-tabs__items{
    overflow: visible;
  }
/* Transition for router-view*/
/* Slide from left */
.card-slide-left-enter-active{
  transition: transform 0.4s ease-out;
}
.card-slide-left-leave-active {
  transition: transform 0.4s ease-in;
}
.card-slide-left-enter {
  transform: translateX(-100%);
  opacity:1;
}
.card-slide-left-leave-to {
  transform: translateX(100%);
  opacity:0.5;
}
/* Slide from right */
.card-slide-right-enter-active{
  transition: transform 0.4s ease-out;
}
.card-slide-right-leave-active {
  transition: transform 0.4s ease-in;
}
.card-slide-right-enter {
  transform: translateX(100%);
  opacity:1;
}
.card-slide-right-leave-to {
  transform: translateX(-100%);
  opacity:0.5;
}

.zmaxContainerWidth {
  max-width: 960px !important;
}
.navigate_next_css {
  margin-top: 12px;
  margin-left: -18px;

}
.navigate_prev_css {
  margin-left: -22px;
  margin-top: 12px;
  padding-right: 42px;
}
.button_navigation_margin_left {
  margin-left: -5px;
}
/* Transition for buttons */
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.next-prev-buttons {
  padding: 10px;
  position: fixed;
  left: 50%;
  top: 536px;
  margin-left: 575px;
  width: 97px;
}
.withDrawalIcon {
  margin-top: -11px !important;
  margin-bottom: 20px !important;
}
.withDrawalTitle {
  margin-top: 5px !important;
}
.pagination-dots
  margin-top: $spacers.four.y
  margin-bottom: 45px;
  @media $display-breakpoints.md-and-up
    display: none;

// Fonts Based on breakpoint
.my-primerica-header-layout
  justify-content: center;
  padding-left: $spacers.three.x;
  padding-right: $spacers.three.x;
  padding-top: $spacers.four.y;
  @media $display-breakpoints.sm-and-up
    padding-left: $spacers.zero.x;
    padding-right: $spacers.zero.x;
    flex-wrap: wrap;
    justify-content: flex-end;
  @media $display-breakpoints.md-and-up
    padding-top: $spacers.three.y;
  .heading_two
    padding-right: 16px;
    @media $display-breakpoints.xs-only
      padding-right: 0;
      line-height: 24px;

// View Policy Dropdown
.view-policy-section
  @media $display-breakpoints.sm-only
    justify-content: flex-end;
    flex-basis: 200px;
    margin-bottom: 0;
  @media $display-breakpoints.sm-and-down
    flex-direction: column;

  &.policy-number
    align-items: flex-end;
    max-width: 100%;
    .v-subheader
      align-items: flex-end;
      padding-right: $spacers.zero.x;
      padding-bottom: $spacers.three.y;
      width: auto;
    @media $display-breakpoints.xs-only
      align-items: flex-start;

  .v-subheader
    font-weight: $font-weights.medium;
    @media $display-breakpoints.sm-and-down
      padding-left: $spacers.zero.x;
      padding-right: $spacers.zero.x;
      margin-top: $spacers.four.y;
      height: 30px;
    @media $display-breakpoints.sm-only
      margin-top: $spacers.zero.y;
    @media $display-breakpoints.md-and-up
      padding-right: $spacers.three.x;
      > div {
        text-align: right;
        width: 100%;
      }

  .v-select
    width: 100%;
    @media $display-breakpoints.sm-and-up
      padding-left: $spacers.zero.x;
      padding-right: $spacers.zero.x;
    @media $display-breakpoints.md-and-up
      max-width: 247px;


// Padding based on breakpoint
.container
  @media $display-breakpoints.xs-only
    padding-left: $spacers.zero.x !important
    padding-right: $spacers.zero.x !important
    padding-bottom: $spacers.zero.y !important

/deep/.policy-overview-content {
  .payment-details .info-title {
    text-align: left;
  }
  .payment-details .info-content {
    text-align: left;
    font-size: $headings.subheading.size;
  }

  .contact-details {
    .theme--light.v-input--is-disabled label.v-label.v-label--active {
      color: #424242;
      font-weight: $font-weights.medium;
    }
    .theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
      border-image: none;
    }
  }
  .name-header {
    min-width: 100px;
  }
}

// Tabs z-index
.pri-tabs {
  z-index: 2;
}
.pri-tab-items {
  z-index: 1;
}
.dollarSignBig {
  font-size: 56px;
}
.dollarSignSmall {
  font-size: 38px;
}
.makeAPaymentFontBig {
  font-size: 44px;
  display: block;
  margin-top: -5px;
  padding-bottom: 20px;
}

.makeAPaymentFontSmall {
  font-size: 24px;
  display: block;
  margin-top: -2px;
  padding-bottom: 5px;
}

.updateMonthlyWDFontBig {
  font-size: 36px;
  display: block;
  margin-top: -5px;
  padding-bottom: 20px;
}

.updateMonthlyWDFontSmall {
  font-size: 18px;
  display: block;
  margin-top: -2px;
  padding-bottom: 5px;
}
</style>
