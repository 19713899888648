export const canadaRegex = /^(19|20)([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/;
export const quebecRegex = /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])(19|20)\d\d$/;
export const usRegex = /^(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(19|20)([0-9]{2})$/;

export default {
  date(isCanadian, isQuebec) {
    return (value) => {
      if (value === '') {
        return true;
      }
      let regex = usRegex;
      if (isCanadian && !isQuebec) {
        regex = canadaRegex;
      }
      if (isQuebec) {
        regex = quebecRegex;
      }
      const match = value && value.match(regex);
      return !!(match);
    };
  },
};
