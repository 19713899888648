<i18n src='../locales.json'></i18n>

<template>
  <v-content
    id="policy_details"
    class="policy-overview-content pt-0"
    role="presentation"
  >
    <v-container>
      <!-- My Primerica Header -->
      <v-layout
        row
        wrap
      >
        <v-flex
          d-flex
          align-center
          xs12
        >
          <v-layout
            row
            wrap
            class="my-primerica-header-layout white--text column-at-xs"
          >
            <v-flex
              d-flex
            >
              <v-layout
                mb-3
              >
                <!-- My Primerica Header -->
                <v-flex
                  shrink
                  mr-3
                  d-flex
                  align-center
                >
                  <svg width="55px" height="40px" viewBox="15 10 42 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Life Insurance</title>
                    <defs>
                      <radialGradient cx="51.4182051%" cy="82.466338%" fx="51.4182051%" fy="82.466338%" r="70.4153415%"
                                      gradientTransform="translate(0.514182,0.824663),scale(0.698177,1.000000),rotate(77.265059),scale(1.000000,1.064158),translate(-0.514182,-0.824663)"
                                      id="radialGradient-511"
                      >
                        <stop stop-color="#36A5DD" offset="0%" />
                        <stop stop-color="#286EB6" offset="100%" />
                      </radialGradient>
                      <radialGradient cx="51.4182051%" cy="82.466338%" fx="51.4182051%" fy="82.466338%" r="303.039601%"
                                      gradientTransform="translate(0.514182,0.824663),scale(0.234861,1.000000),rotate(45.568421),scale(1.000000,1.064158),translate(-0.514182,-0.824663)"
                                      id="radialGradient-611"
                      >
                        <stop stop-color="#36A5DD" offset="0%" />
                        <stop stop-color="#286EB6" offset="100%" />
                      </radialGradient>
                    </defs>
                    <g id="Nav-House" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Blue-Level-Icon2" transform="translate(12.000000, 11.000000)">
                      <g>
                        <polygon id="Rectangle" fill="#18344E" points="6.39435792 8.78385542 19.6767558 1.41036145 32.9435943 8.78385542 32.9591537 25.7838554 6.39435792 25.7838554" />
                        <path
                          d="M0.0481927711,10.1108701 L4.76705398,10.1181643 L4.76705398,27.2296386
                          L34.468165,27.2296386 L34.468165,10.1181643 L39.0481928,10.1181643 L31.4197199,6.14855624 L31.4191647,0.143222694 L26.1423416,0.143222694
                          L26.1423416,3.4009463 L19.6152197,0.000722891566 L0.0481927711,10.1108701 Z M6.40023796,9.21092306 L19.5343354,2.41036145 L32.939759,9.35099862
                          L32.939759,19.759759 L6.39156627,19.759759 L6.40023796,9.21092306 Z"
                          id="Blue-Level-Icon" fill="url(#radialGradient-511)"
                        />
                        <path
                          d="M6.89402838,19.259759 L6.89057366,24.499759 L32.4563424,24.499759 L32.4589087,19.259759 L6.89402838,19.259759 Z"
                          id="Rectangle" stroke="#18344E" fill="url(#radialGradient-611)"
                        />
                      </g>
                    </g>
                    </g>
                  </svg>
                </v-flex>
                <v-flex
                  d-flex
                  align-center
                >
                  <h2
                    role="heading"
                    aria-level="1"
                    class="heading_two"
                  >
                    {{ $t('L_life_insurance') }}
                  </h2>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- Policy Number -->
            <v-flex
              class="view-policy-section policy-number"
              d-flex
            >
              <v-subheader class="white--text">
                <div>
                  {{ $t('L_policy_number') }}:
                  <span class="font-weight-regular">{{ policyNo }}</span>
                </div>
              </v-subheader>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
        >
          <ChildRiderSteps />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapGetters } from 'vuex';
import ChildRiderSteps from '../components/ChildRiderSteps/ChildRiderSteps.vue';

export default {
  name: 'ChildRiderLayout',
  components: {
    ChildRiderSteps,
  },
  computed: {
    ...mapGetters({
      currentPolicy: 'policy/currentPolicy',
    }),
    policyNo() {
      return this.currentPolicy?._contractId;
    },
  },
};
</script>


<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'


// Fonts Based on breakpoint
.my-primerica-header-layout
  justify-content: center;
  padding-left: $spacers.three.x;
  padding-right: $spacers.three.x;
  padding-top: $spacers.four.y;
  @media $display-breakpoints.sm-and-up
    padding-left: $spacers.zero.x;
    padding-right: $spacers.zero.x;
    flex-wrap: wrap;
    justify-content: flex-end;
  @media $display-breakpoints.md-and-up
    padding-top: $spacers.three.y;
  .heading_two
    padding-right: 16px;
    @media $display-breakpoints.xs-only
      padding-right: 0;
      line-height: 24px;

  .v-subheader
    font-weight: $font-weights.medium;
    @media $display-breakpoints.sm-and-down
      padding-left: $spacers.zero.x;
      padding-right: $spacers.zero.x;
      margin-top: $spacers.four.y;
      height: 30px;
    @media $display-breakpoints.sm-only
      margin-top: $spacers.zero.y;
    @media $display-breakpoints.md-and-up
      padding-right: $spacers.three.x;
      > div {
        text-align: right;
        width: 100%;
      }
</style>

