import { render, staticRenderFns } from "./MinorAccountLinkProcess.vue?vue&type=template&id=cf448a24&scoped=true"
import script from "./MinorAccountLinkProcess.vue?vue&type=script&lang=js"
export * from "./MinorAccountLinkProcess.vue?vue&type=script&lang=js"
import style0 from "./MinorAccountLinkProcess.vue?vue&type=style&index=0&id=cf448a24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf448a24",
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2FminorAccountLinkProcess%2Flayouts%2FMinorAccountLinkProcess.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports