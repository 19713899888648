

export class Agent {
  _id = '';

  _cellPhone = '';

  _firstName = '';

  _lastName = '';

  _email = '';

  _title = '';

  _titleDesc = '';

  _office = '';

  _isRvp = false;

  _homeOfficeAgent = false;

  _lifeLicensed = 'N';

  _displayPic = '';

  _securitiesLicensed = 'N';

  _licensedProducts = [];

  constructor({
    agentId,
    cellPhone,
    firstName,
    lastName,
    email,
    title,
    titleDesc,
    office,
    rvpAndUp,
    homeOfficeAgent,
    lifeLicensed,
    displayPic,
    securitiesLicensed,
    licensedProducts,
  }) {
    this._id = agentId;
    this._cellPhone = cellPhone;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._title = title;
    this._titleDesc = titleDesc;
    this._office = office;
    this._isRvp = rvpAndUp;
    this._homeOfficeAgent = homeOfficeAgent;
    this._lifeLicensed = lifeLicensed;
    this._displayPic = displayPic;
    this._securitiesLicensed = securitiesLicensed;
    this._licensedProducts = licensedProducts;
  }

  get id() {
    return this._id || '';
  }

  get cellPhone() {
    return this._cellPhone || '';
  }

  get firstName() {
    return this._firstName || '';
  }

  get lastName() {
    return this._lastName || '';
  }

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  get title() {
    return this._title || '';
  }

  get titleDescription() {
    return this._titleDesc || '';
  }

  get office() {
    return this._office || '';
  }

  get phone() {
    return this._office?.phone || '';
  }

  get email() {
    return this._email || '';
  }

  get homeOffice() {
    return this._homeOfficeAgent === true || this._homeOfficeAgent === 'Y';
  }

  get lifeLicensed() {
    return this._lifeLicensed === true || this._lifeLicensed === 'Y';
  }

  get displayPic() {
    return this._displayPic || '';
  }

  get securitiesLicensed() {
    return this._securitiesLicensed === true || this._securitiesLicensed === 'Y';
  }

  get isRvp() {
    return this._isRvp;
  }

  get licensedProducts() {
    return this._licensedProducts;
  }
}
