import { render, staticRenderFns } from "./UpdateContactMobile.vue?vue&type=template&id=489650b4&scoped=true"
import script from "./UpdateContactMobile.vue?vue&type=script&lang=js"
export * from "./UpdateContactMobile.vue?vue&type=script&lang=js"
import style0 from "./UpdateContactMobile.vue?vue&type=style&index=0&id=489650b4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489650b4",
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fhome%2Fmyaccount%2Fcomponents%2FupdateContactMobile%2FUpdateContactMobile.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports