import bff from '@/shared/api/bff';

// TODO: add unit test
export const postPolicyReinstatement = async (context, payload) => {
  try {
    const response = await bff.postPolicyReinstatement(payload);
    return response.redirectUrl;
  } catch (error) {
    throw error;
  }
};
