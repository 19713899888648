<i18n src="../locales.json"></i18n>

<template>
  <div class="statements flex xs12">
    <v-card elevation="0">
      <v-card-title>
        <h3>{{ $t('L_statements') }}</h3>
      </v-card-title>
      <v-card-text>
        <a @click.stop="navigateToDocumentsPage">{{ $t('L_view_investment_statements') }}</a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';

export default {
  name: 'Statements',
  components: {
    EmbeddedViewer,
  },
  data() {
    return {
      statementTitle: null,
      viewStatements: [],
    };
  },
  computed: {
    ...mapGetters('customer', ['loading', 'investments', 'binaryInvestmentStatement']),
    statements() {
      return this.investments.statements;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    ...mapActions('customer', ['openStatement']),
    // TODO: remove if unused
    viewStatement(statement) {
      this.statementTitle = this.translatedStatementTitle(statement);
      this.openStatement(statement.documentMetadata);
    },
    translatedStatementTitle(statement) {
      const month = this.$t(`L_${statement.title.month.toLowerCase()}`);
      return `${month} ${statement.title.year}`;
    },
    translatedStatementPeriod(statement) {
      const startMonth = this.$t(`L_${statement.period.start.month.toLowerCase()}`);
      const endMonth = this.$t(`L_${statement.period.end.month.toLowerCase()}`);
      return `${startMonth} ${statement.period.start.day} - ${endMonth} ${statement.period.end.day}, ${statement.period.year}`;
    },
    navigateToDocumentsPage() {
      try {
        const i = this.$dynatrace.enterAction('link_investmentStatements_investmentDetailsPage');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$router.push({ name: '' });
      if (this.isMobile) {
        this.$router.push({ name: 'DocumentListMobile', params: { routeName: 'InvestmentStatements' } });
      } else {
        this.$router.push({ name: 'InvestmentStatements' }); // default
      }
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'
.statements
    margin: 5px 0 0;
    box-shadow: none;
    .v-expansion-panel
        box-shadow: none;
    .v-expansion-panel__header
        color: #063562;
        width: fit-content;
        margin: 0 auto;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        .v-icon
            color: #063562 !important;
            font-size: 32px;
    .v-expansion-panel__body
        padding: 20px;
        background: #f9f9f9;
        .v-card__title, .v-card__text
            border-bottom: 1px solid #cccccc;
        .v-card__title
            color: #333333;
            font-size: 24px;
        .v-card__text
            font-size: 14px;
            text-align: center;
            @media $display-breakpoints.md-and-up
              padding: 20px;
            .layout
              padding: 10px 5px;
              @media $display-breakpoints.md-and-up
                padding: 10px 20px;
            .alternate
                background-color: #fafcfd;
            .header
                &.layout
                    border: none;
                    padding: 10px 20px 20px;
                .period
                    font-weight: 600;
                .statement
                    padding: 0 0 0 15px;
                    text-decoration: none;
            .period
                text-align: left;
            .statement
                text-align: left;
                font-weight: 600;
                text-decoration: underline;
</style>
