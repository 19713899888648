<template>
  <v-layout
    row
    wrap
    mt-3
  >
    <v-flex
      xs12
      md7
    >
      <h4 class="heading_four mt-2 font-weight-medium">
        How much monthly income would your survivors need if you passed away?
      </h4>
    </v-flex>
    <v-flex
      xs12
      offset-md2
      md3
    >
      <CurrencyDisplay
        v-model="amount"
        @input="$emit('input', $event)"
        :label-id="'Survivor monthly income'"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Slider from '@/components/Inputs/Slider/Slider.vue';
import CurrencyDisplay from '../../CurrencyDisplay.vue';

export default {
  name: 'SurvivorMonthlyIncome',
  data: () => ({
    amount: 0,
  }),
  props: {
    value: {
      type: Number,
      required: true,
    },
    formInput: {
      type: Object,
      required: true,
    },
  },
  components: {
    Slider,
    CurrencyDisplay,
  },
  mounted() {
    this.amount = this.value;
  },
};
</script>
