<i18n src="./locales.json"></i18n>

<template>
  <v-content role="presentation" class="white_background">
    <v-container text-xs-center>
      <div v-if="isProcessing" class="centerProgress">
        <v-progress-circular indeterminate :size="size" :width="width" :color="color" />
      </div>
      <v-layout class="widthForContent">
        <v-flex text-xs-center mt-4 mb-4>
          <v-layout row wrap>
            <v-flex xs12 text-xs-center mt-4 mb-4 pb-4>
              <img src="@/assets/primerica-logo.svg" height="70" alt="Primerica Beta">
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <img v-if="isTouchId"
                   height="82"
                   width="82"
                   src="@/assets/TouchID.svg"
                   class="mr-2"
                   alt="Touch Id"
              >
              <img v-else-if="isFaceId"
                   height="82"
                   width="82"
                   src="@/assets/FaceID.svg"
                   class="mr-2"
                   alt="face id"
              >
            </v-flex>
          </v-layout>
          <div class="mx-auto mb-xs-12" :class="$vuetify.breakpoint.xsOnly ? 'overall-div-card-smaller' : 'overall-div-card-larger'">
            <v-layout row wrap>
              <v-flex xs12 text-xs-center font-weight-bold mt-4 mb-4 ml-4 mr-4 class="regular_body_text">
                {{ $t('L_want_a_faster_way_to_log_in') }}
              </v-flex>
              <v-flex v-if="isTouchId" xs12 text-xs-left mb-4 ml-4 mr-4 pb-4 class="regular_body_text">
                {{ $t('L_turn_on_touch_or_fingerprint_id_so_you_can_log_in_quickly') }}
              </v-flex>
              <v-flex v-else-if="isFaceId" xs12 text-xs-left mb-4 ml-4 mr-4 pb-4 class="regular_body_text">
                {{ $t('L_turn_on_face_id_so_you_can_log_in_with_a_simple') }}
              </v-flex>
            </v-layout>
            <v-layout row wrap ml-4 mr-4>
              <v-flex xs12 text-xs-left font-weight-regular class="regular_body_text">
                <span v-if="isTouchId">
                  {{ $t('L_sharing_your_device_with_anyone_touch_id') }}
                </span>
                <span v-else-if="isFaceId">
                  {{ $t('L_sharing_your_device_with_anyone_face_id') }}
                </span>
              </v-flex>
            </v-layout>
          </div>
          <v-layout row wrap text-xs-center class="outerlayout">
            <div class="innerLayout">
              <v-flex xs12 mt-4 mb-4>
                <v-btn block ml-4 mr-4 pl-4 pr-4 color="primary" class="btn_default" :class="currentLocale==='en_US'?'font-size--14':'font-size--12'">
                  <span v-if="isTouchId" @click="startTouchID">{{ $t('L_use_fingerprint_id') }}</span>
                  <span v-else-if="isFaceId" @click="startFaceID">{{ $t('L_use_face_id') }}</span>
                </v-btn>
              </v-flex>
              <v-flex xs12 mt-4 mb-4>
                <v-btn block ml-4 mr-4 pl-4 pr-4 class="btn_default btn_white" @click="skip"
                       :class="currentLocale==='en_US'?'font-size--14':'font-size--12'"
                >
                  {{ $t('L_skip') }}
                </v-btn>
              </v-flex>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COUNTRIES } from '@/shared/constants';
import Config from '@/shared/util/config';
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';
import { hasFaceId, hasTouchId, setUnlockModeToBiometrics, setUnlockModeToSecureStorage } from '@/plugins/auth/utils/sessionManager';
import MessageDialog from '@/components/MessageDialog.vue';

export default {
  name: 'Biometrics',
  components: {
    LocaleToggle,
    MessageDialog,
  },
  props: {
    color: {
      type: String,
      default: '#063562',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 70,
    },
    width: {
      type: Number,
      default: 7,
    },
  },
  data: () => ({
    deviceType: null,
    languagesPrefs: Config.get('languagePreferences'),
    languages: [],
    isProcessing: true,
    isFaceId: false,
    isTouchId: false,
    canUseFaceId: false,
    canUseTouchId: false,
    biometricsStrengthStrong: false,
  }),
  computed: {
    ...mapGetters({
      currentLocale: 'getLocale',
      hasTermsOfUseTask: 'hasTermsOfUseTask',
      hasPolicyAckTask: 'hasPolicyAckTask',
    }),
    ...mapGetters('customer', ['customerProfile']),
  },
  methods: {
    ...mapActions(['changeLocale']),
    async startFaceID() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        window.setTimeout(() => {
          this.isProcessing = false;
        }, 5000);
        try {
          await setUnlockModeToBiometrics().catch(async (err) => {
            try {
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[BiometricsSplash] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
              await this.$store.dispatch('postAuditLog', {
                actionId: 'MobileApp_Device_FailureAction', // optional
                details: `[${Date.now()}] ERROR CODE ${err?.code}`, // Additional message stored - optional
                message: err?.message, // Error type - optional
              });
            } catch (error) { /*  */ }
            throw (err); // ! rethrow error and have page handle the error; see [showErrorDuringBiometricsMessage]
          });
          this.isProcessing = false;
          await this.$router.push({ name: 'SuccessBiometrics' });
          // await this.goToNextFlow();
        } catch (e) {
          this.isProcessing = false;
          // this.showErrorDuringBiometricsMessage = true;
          await this.$router.push({ name: 'ErrorBiometrics' });
        }
      }
    },
    async startTouchID() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        window.setTimeout(() => {
          this.isProcessing = false;
        }, 5000);
        try {
          await setUnlockModeToBiometrics().catch(async (err) => {
            try {
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[BiometricsSplash] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
              await this.$store.dispatch('postAuditLog', {
                actionId: 'MobileApp_Device_FailureAction', // optional
                details: `[${Date.now()}] ERROR CODE ${err?.code}`, // Additional message stored - optional
                message: err?.message, // Error type - optional
              });
            } catch (error) { /*  */ }
            throw (err); // ! rethrow error and have page handle the error; see [showErrorDuringBiometricsMessage]
          });
          this.isProcessing = false;
          await this.$router.push({ name: 'SuccessBiometrics' });
          // await this.goToNextFlow();
        } catch (e) {
          this.isProcessing = false;
          // this.showErrorDuringBiometricsMessage = true;
          await this.$router.push({ name: 'ErrorBiometrics' });
        }
      }
    },
    async skip() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        window.setTimeout(() => {
          this.isProcessing = false;
        }, 5000);
        try {
          await setUnlockModeToSecureStorage().catch(async (err) => {
            try {
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[BiometricsSplash] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
              await this.$store.dispatch('postAuditLog', {
                actionId: 'MobileApp_Device_FailureAction', // optional
                details: `[${Date.now()}] ERROR CODE ${err?.code}`, // Additional message stored - optional
                message: err?.message, // Error type - optional
              });
            } catch (error) { /*  */ }
            throw (err); // ! rethrow error and have page handle the error; see [showErrorDuringBiometricsMessage]
          });
          await this.$router.push({ name: 'ErrorBiometrics' });
        } catch (e) {
          // this.showErrorDuringBiometricsMessage = true;
          await this.$router.push({ name: 'ErrorBiometrics' });
        }
        this.isProcessing = false;
      }
    },
    async goToNextFlow() {
      const deeplinkroute = window.localStorage.getItem('deeplinkroute'); // ? this is a path, NOT name of a route
      let nextRoute = '';
      if (this.hasTermsOfUseTask) nextRoute = 'TermsOfUse';
      else if (this.hasPolicyAckTask) nextRoute = 'PolicyAcknowledgement';
      else {
        window.localStorage.removeItem('deeplinkroute'); // remove the deeplink route
        nextRoute = deeplinkroute || 'Dashboard'; // there are no post login tasks
      }
      this.$router.push((deeplinkroute ? { path: nextRoute } : { name: nextRoute }));
    },
    updateLocale(language) {
      this.changeLocale(language);
    },
    validateLanguagePreference() {
      if (!this.languagesPrefs || this.languagesPrefs.length === 0) {
        this.languagePrefs = ['en_US', 'en_CA'];
      }

      if (this.customerProfile.country === COUNTRIES.CANADA) {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_CA') > 0);
        if (this.currentLocale?.indexOf('_CA') === -1) {
          this.changeLocale('en_CA');
        }
      } else {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_US') > 0);
        if (this.currentLocale?.indexOf('_US') === -1) {
          this.changeLocale('en_US');
        }
      }
    },
  },
  async mounted() {
    this.validateLanguagePreference();
    await Promise.all([hasFaceId(), hasTouchId()])
      .then(([_hasFaceId, _hasTouchId]) => {
        this.isFaceId = _hasFaceId;
        this.isTouchId = _hasTouchId;
      });
    this.isProcessing = false;
  },
};
</script>

<style lang="stylus" scoped>
.background {
  min-height: 100%;
  background-image: linear-gradient(to top, #607c97, #063562);
}
.white_background {
  min-height: 100%;
  background-color: white;
}
.css_locale {
  background-color: white;
}
.css_app_update {
  font-size: 20px;
  font-weight: bold;
}
.regular_body_text {
  font-size: 20px;
}
.language-icon {
  color: white !imporant;
}
.container {
  max-width: 960px !important;
}
.widthForContent {
  max-width: 600px !important;
  margin:auto;
}
.btn_default {
  min-width: 250px;
  max-width: 350px;
  margin: auto;
  height: 48px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.outerlayout {
  padding-bottom: 50px;
}
.innerLayout {
  zwidth: 50% !important;
  margin: auto !important;
}
.btn_white {
  background-color: white !important;
}
.centerProgress {
  left: calc(50% + -38px);
  top: 50vh;
  position: absolute;
}
</style>
