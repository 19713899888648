import PolicyDocuments from './components/PolicyDocuments.vue';
import NoticesAndConfirmations from './components/NoticesAndConfirmations.vue';
import InvestmentStatements from './components/InvestmentStatements.vue';
import DocumentList from './DocumentList.vue';
import DashboardLayout from '../../layouts/DashboardLayout.vue';
import Documents from './Documents.vue';

export default {
  path: '/documents',
  component: DashboardLayout,
  children: [
    {
      name: 'DocumentListMobile',
      path: 'document-list/:routeName',
      component: DocumentList,
      props: true,
    },
    {
      name: 'Documents',
      path: '',
      component: Documents,
      children: [
        {
          name: 'PolicyDocuments',
          path: 'policy-documents',
          component: PolicyDocuments,
        },
        {
          name: 'NoticesAndConfirmations',
          path: 'notices-and-confirmations',
          component: NoticesAndConfirmations,
        },
        {
          name: 'InvestmentStatements',
          path: 'investment-statements',
          component: InvestmentStatements,
        },
      ],
    },
  ],
};
