<i18n src='../locales.json'></i18n>
<template>
  <v-content class="pt-0 text-left">
    <v-layout row wrap>
      <v-flex xs12 mb-4>
        <h2>
          {{ $t('L_welcome_back') }}
        </h2>
      </v-flex>
      <v-flex xs12 my-2>
        <p>{{ $t('L_payment_in_case_you_need') }}</p>
      </v-flex>
      <v-flex xs12 sm6 mb-4>
        <div>{{ $t('L_payment_zip_postal_code') }}</div>
        <p class="headline font-weight-bold">
          {{ zipCode }}
        </p>
      </v-flex>
      <v-flex xs12 sm6 mb-4>
        <div>{{ $t('L_payment_policy_number') }}</div>
        <p class="headline font-weight-bold" @click="copyPolicyToClipboard()">
          {{ contractId }}
          <span class="clickable font-size--16 font-weight-bold blue_label">
            <v-icon color="#02579e">content_copy</v-icon> {{ $t('L_copy') }}
          </span>
        </p>
        <div>
          <b>{{ $t('L_enter_in_account_number_field_when_prompted') }}</b>
        </div>
      </v-flex>
      <v-flex xs12 mt-2>
        <p>{{ $t('L_payment_if_you_made') }}</p>
        <p>{{ $t('L_payment_you_should_receive') }}</p>
        <p>{{ $t('L_payment_payments_made_before') }}</p>
        <p>{{ $t('L_payment_payments_made_after') }}</p>
      </v-flex>
      <v-flex xs12 offset-md8 offset-lg8 mt-4>
        <v-btn id="one_tp_kubra_done" block class="white--text float-right" color="primary" @click="refreshAndClose">
          {{ $t('L_done') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'PayStepThreeKubra',
  components: {},
  props: {
    // show: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },
    // alertContext: {
    //   type: String,
    //   required: false,
    //   default: 'go_paperless',
    // },
    // policynumber: {
    //   type: String,
    //   required: false,
    //   default: '',
    // },
  },
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['policies', 'currentPolicy', 'routeNameToNavigateBack', 'selectedPolicyIndex']),
    ...mapGetters({
      locale: 'getLocale',
    }),
    contractId() {
      return this.currentPolicy?.contractId;
    },
    zipCode() {
      return this.currentPolicy?.owner?.address._zip;
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument', 'getPolicyOverview', 'setPaymentInfoShowPaymentAmountDateBillingMethod', 'setPaymentStep']),
    async refreshAndClose() {
      this.$emit('close-external-kubra');
      await this.getPolicyOverview(true); // refresh policy data
      this.setPaymentInfoShowPaymentAmountDateBillingMethod();
      this.setPaymentStep(-1);
      this.$router.push({ name: 'PolicyDetails', params: { contractId: this.selectedPolicyIndex } });
    },
    copyPolicyToClipboard() {
      navigator.clipboard.writeText(this.contractId);
    },
  },
  mounted() {
    // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
    try {
      const i = this.$dynatrace.enterAction('OneTimePayment_CreditCard_WelcomeBackMsg_PageDisplayed');
      this.$dynatrace.leaveAction(i);
    } catch (error) { /*  */ }
  },
};
</script>

<style>
.bg-light-grey {
  background-color: #f5f5f5;
}
.clickable {
  cursor: pointer;
}
.blue_label{
  color: #02579e;
}
</style>
