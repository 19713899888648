const moment = require('moment');

export const currency = (value, numDecimals) => (typeof value === 'undefined' ?
  '' : (new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: numDecimals,
  })).format(value));

export const currencyTwoDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const currencyNoDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export function timestampToMDY(timestamp, isCanada) {
  if (typeof timestamp === 'undefined') {
    return '';
  }
  const date = new Date(timestamp);
  if (isCanada) {
    return `${date.getFullYear()}/${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(-2)}`;
  }
  return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(-2)}/${date.getFullYear()}`;
}

export function titleCase(str) {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export const phoneNumber = number => (!number || number.length === 0 ? '' : number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'));

export const formatDateToDMY = date => (typeof date === 'string' ? moment(date, 'YYYY-MM-DD').format('ll') : '');

export const formatDate = (date, format) => (date ? moment(date).format(format || 'MM/DD/YYYY') : '');

export async function sha256(input) {
  return window.crypto.subtle
    .digest('SHA-256', new TextEncoder('utf-8').encode(input))
    .then((h) => {
      const hexes = [];
      const view = new DataView(h);
      for (let i = 0; i < view.byteLength; i += 4) {
        hexes.push((`00000000${view.getUint32(i).toString(16)}`).slice(-8));
      }
      return hexes.join('');
    });
}

export const number = (input, fmt) => {
  let reg = '';
  let replace = '';
  let format = fmt;
  let pos = 0;
  const groups = format.split(/[^#]+/);
  groups.forEach((group, index) => {
    if (group.length === 0) return;
    pos = format.indexOf(group);
    reg += `(\\d{${group.length}})`;
    replace += `${format.slice(0, pos)}$${index}`;
    format = format.slice(pos + group.length);
  });
  const regExp = new RegExp(reg);
  return input.replace(regExp, replace);
};
