<i18n src='./locales.json'></i18n>
<template>
  <v-text-field
    v-model="formInput.$model"
    :max-length="9"
    type="tel"
    :id="idProp"
    :label="label || $t('L_zip_code') || 'Zip Code'"
    :autocomplete="disableAutocomplete ? 'off' : null"
    :color="color"
    :hint="hint"
    :disabled="disabled"
    :mask="maskFormat"
    :error-messages="errorMessages"
    @input="onInput"
    @blur="onBlur"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  data: () => ({
    modelName: 'zipCode',
    zipCode: '',
  }),
  components: { FieldIcon },
  name: 'ZipCodeInput',
  extends: BaseTextField,
  props: {
    canadianPolicy: {
      type: Boolean,
      required: false,
      default: false,
    },
    territory: {
      type: String,
      required: false,
      default: undefined,
    },
    idProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    maskFormat() {
      return (this.formInput.$model.length > 5) ? '#####-####' : '######';
    },
  },
};
</script>
