<i18n src="./locales.json"></i18n>
<template>
  <v-dialog
    transition="scale-transition"
    origin="center center"
    id="edit_contact_info"
    :max-width="554"
    :value="show"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
  >
    <v-card class="text-xs-left">
      <Alert
        v-if="showAddressError"
        type="error"
        :dismissible="false"
        icon="error"
        color="error"
        :expandable="false"
        :colored-border="true"
      >
        <v-layout
          slot="message"
          :class="$vuetify.breakpoint.xsOnly ? 'row wrap font-size--13' : ''"
        >
          <v-flex xs12 class="font-size--16">
            {{ $t('L_the_addess_you_provided_cannot_be_verified_with_the_US_Postal') }}.
          </v-flex>
        </v-layout>
      </Alert>
      <MessageDialog
        :show-dialog="showMsgDialog"
        :has-button-two="false"
        :type="updateDialogType"
        :title="updateDialogTitle"
        button-one-label="OK"
        :is-persistent="true"
        @buttonOneAction="closeEditContact"
      >
        <span slot="message">
          <v-layout mt-4 mb-4 row wrap justify-center>
            <v-flex xs10>
              {{ updateDialogMessage }}
              <v-tooltip top max-width="200" v-if="!isSuccessEdit">
                <template v-slot:activator="{ on }">
                  <v-icon color="blue" v-on="on" @blur="status = !status" @focus="status = !status">
                    info
                  </v-icon>
                </template>
                <div> {{ errorNavigateDirectlyToEachPolicy }} </div>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <v-layout mb-2 row wrap justify-center v-for="(policy, index) in updateDialogPolicies" :key="index">
            <v-flex xs11>
              <div class="divHoldingPolicyForSuccessAndError">
                {{ policy }}
                <v-icon color="green darken-2" class="moveToRight" v-if="isSuccessEdit">
                  check_circle
                </v-icon>
                <v-icon color="red darken-2" class="moveToRight" v-if="!isSuccessEdit">
                  error
                </v-icon>
              </div>
            </v-flex>
          </v-layout>
        </span>
      </MessageDialog>

      <v-card-text v-if="currentState === 'edit'">
        <!-- Start Edit Email -->
        <v-layout row wrap justify-center v-if="editingCategory == 'email'">
          <v-flex xs9 sm10 md9>
            <div class="heading_two mt-4 mb-2 font-size--20 text-xs-center">
              {{ $t('L_update_policy_email_address') }}
            </div>
          </v-flex>
          <v-flex xs9 sm10 md9>
            <div class="heading_two mt-4 mb-4 font-size--16">
              {{
                $t(
                  'L_We_may_use_this_email_to_contact_you_regarding_your_life_policy'
                )
              }}
            </div>
          </v-flex>
          <v-flex xs9 sm10 md9>
            <div class="heading_two mt-4 mb-4 font-size--16 font-weight-bold">
              {{
                $t(
                  'L_note_this_update_will_only_apply_to_your_life_policy'
                )
              }}
            </div>
          </v-flex>
          <v-flex xs9 sm10 md9>
            <EmailInput
              id="new_email"
              id-prop="new_email"
              v-model="emailAddress"
              :form-input="$v.emailAddress"
              :label="$t('L_Enter_New_Email_Address')"
            />
          </v-flex>
          <v-flex xs9 sm10 md9>
            <ConfirmEmailInput
              id="confirm-new_email"
              v-model="confirmEmailAddress"
              :form-input="$v.confirmEmailAddress"
              :label="$t('L_Confirm_New_Email_Address')"
              :validation-messages="{
                notMatchingEmail: $t('L_confirm_email_match'),
              }"
            />
          </v-flex>
        </v-layout>
        <!-- End Edit Email -->
        <!-- Start Edit Phone -->
        <v-layout row wrap justify-center v-if="editingCategory == 'phone'">
          <v-flex xs9 sm10 md9>
            <h2 class="heading_two mt-4 mb-2 text-xs-center">
              {{ $t('L_update_policy_phone_number') }}
            </h2>
          </v-flex>
          <v-flex xs9 sm10 md9>
            <div class="heading_two mt-4 mb-4 font-size--16">
              {{
                $t(
                  'L_We_may_use_this_phone_number_to_contact_you_regarding_your_life_policy'
                )
              }}
            </div>
          </v-flex>

          <v-flex xs9 sm10 md9>
            <div class="heading_two mt-4 mb-4 font-size--16 font-weight-bold">
              {{
                $t(
                  'L_note_this_update_will_only_apply_to_your_life_policy'
                )
              }}
            </div>
          </v-flex>
          <v-flex xs9 sm10 md9>
            <PhoneNumberInput
              id="mobile_phone"
              id-prop="mobile_phone"
              v-model="phoneNumber"
              :form-input="$v.phoneNumber"
              :label="$t('L_Enter_New_Phone_Number')"
              :validation-messages="{
                required: $t('L_mobile_number_required'),
              }"
            />
            <!-- TODO L_mobile_number_must_be_same -->
            <PhoneNumberInput
              id="confirm_mobile_phone"
              id-prop="confirm_mobile_phone"
              v-model="confirmPhoneNumber"
              :form-input="$v.confirmPhoneNumber"
              :label="$t('L_Confirm_New_Phone_Number')"
              :validation-messages="{
                notSamePhoneNumber: $t('L_phone_number_must_match'),
                required: $t('L_mobile_number_required'),
              }"
            />
          </v-flex>
        </v-layout>
        <!-- End Edit Phone -->
        <!-- Start Address -->
        <v-layout row wrap justify-center v-if="editingCategory == 'address'">
          <v-flex xs9 sm10 md9>
            <h2 class="heading_two mt-4 mb-2 text-xs-center">
              {{ $t('L_update_policy_home_address') }}
            </h2>
          </v-flex>
          <v-flex xs9 sm10 md9>
            <div class="heading_two mt-4 mb-4 font-size--16">
              {{ addressHeaderLabel }}
            </div>
          </v-flex>
          <v-flex xs9 sm10 md9>
            <div class="heading_two mt-4 mb-4 font-size--16 font-weight-bold">
              {{
                $t(
                  'L_note_this_update_will_only_apply_to_your_life_policy'
                )
              }}
            </div>
          </v-flex>
          <!-- Start user Address entry -->
          <v-flex v-if="isVerify" xs11 sm11 md10>
            <AddressInput
              id-prop="update_contact_details_address_one"
              v-model="address1Data"
              :form-input="$v.address1Data"
              :maxlen="35"
              :label="$t('L_Street_Address_-_Line_1')"
              :auto-capitalize="true"
              :validation-messages="{
                required: $t('L_address_line_1_required'),
              }"
            />
            <AddressInput
              id-prop="update_contact_details_address_two"
              v-model="address2Data"
              :form-input="$v.address2Data"
              :label="$t('L_Street_Address_-_Line_2_optional')"
              :auto-capitalize="true"
              :maxlen="35"
            />
            <CityInput
              id-prop="update_contact_details_city"
              v-model="cityData"
              :form-input="$v.cityData"
              :label="$t('L_City')"
            />
            <StateInput
              v-if="!currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_state"
              v-model="stateData"
              :form-input="$v.usStateData"
              :label="$t('L_State')"
            />
            <TerritoryInput
              v-if="currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_province"
              v-model="stateData"
              :form-input="$v.caTerritoryData"
              :label="$t('L_policy_province')"
            />

            <ZipCodeInput
              v-if="!currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_zip_code"
              v-model="zipData"
              :form-input="$v.zipData"
              :label="$t('L_Zip_Code')"
            />
            <PostalCodeInput
              v-if="currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_zip_code"
              v-model="zipData"
              :territory="stateData"
              :form-input="$v.zipData"
              :label="$t('L_policy_postal_code')"
            />
          </v-flex>
          <!-- End of user Address entry -->
          <!-- Start of selecting user Entered Address Or USPS Suggested Address -->
          <div v-if="isVerify === false" xs11 sm11 md10>
            <!--Start of User Typed Address and USPS verified address -->
            <v-flex xs12 md12 class="pad-right-md pb-4">
              <h5 class="heading_five mb-2 font-weight-bold">
                {{ $t('L_address_you_entered') }}
              </h5>
              <v-flex
                xs12
                pa-2
                pr-0
                :class="
                  addressSelected === 'userEntered'
                    ? 'blue-border'
                    : 'gray-border'
                "
                @click="addressSelected = 'userEntered'"
              >
                <v-layout row>
                  <v-flex>
                    <span class="font-size--14">
                      {{ address1Data }} {{ address2Data }}
                    </span>
                    <br>
                    <span class="font-size--14">
                      {{ cityData }}, {{ stateData }}
                      {{ zipData.replace(/(\d{5})(\d{4})/, '$1-$2') }}
                    </span>
                  </v-flex>
                  <v-flex align-self-center>
                    <v-layout justify-end>
                      <span v-if="addressSelected !== 'userEntered'" class="select-address pr-0 font-weight-medium">
                        {{ $t('L_select') }}
                      </span>
                      <span v-if="addressSelected === 'userEntered'" class="select-address pr-0 font-weight-medium">
                        <v-icon color="#2196f3">check_circle</v-icon>
                      </span>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
            <!--End of User Typed Address and USPS verified address -->
            <!-- Start "The U.S. Postal Service recommended format"-->
            <v-flex
              xs12
              md12
              pt-4
              pb-4
              class="pad-left-md"
              @click="addressSelected = 'useUsps'"
            >
              <h5 class="heading_five mb-2 font-weight-bold">
                {{ $t('L_The_US_Postal_Service_recommended_format') }}
              </h5>
              <v-flex
                xs12
                pa-2
                pr-0
                :class="
                  addressSelected === 'useUsps' ? 'blue-border' : 'gray-border'
                "
              >
                <v-layout row>
                  <v-flex>
                    <span class="font-size--14">
                      {{ localUSPSFormattedAddress1 }}
                    </span>
                    <br>
                    <span class="font-size--14">
                      {{ localUSPSFormattedAddress2 }}
                    </span>
                  </v-flex>
                  <v-flex align-self-center>
                    <v-layout justify-end>
                      <span v-if="addressSelected !== 'useUsps'" class="select-address pr-0 font-weight-medium">
                        {{ $t('L_select') }}
                      </span>
                      <span v-if="addressSelected === 'useUsps'" class="select-address pr-0 font-weight-medium">
                        <v-icon color="#2196f3">check_circle</v-icon>
                      </span>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
            <!-- End "The U.S. Postal Service recommended format:"-->
          </div>
          <!-- End of selecting user Entered Address Or USPS Suggested Address -->
        </v-layout>
        <!-- End Address -->

        <!--Start Common -->
        <v-layout row wrap justify-center v-if="isMultiPolicy">
          <v-flex xs9 sm10 md9>
            <v-checkbox
              v-model="selectedUpdateAll"
              :label="checkBoxLabel"
              :value="false"
              class="no-padding pa-0 ma-0 justify-center"
              color="#063562"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm8 md8 mb-2>
            <v-btn
              id="btn_save_changes"
              block
              color="primary"
              @click="clickSaveChanges()"
              :disabled="!enableSave()"
              class="mb-2 btnFormat maxButtonWidth centerBtnHorizontally"
            >
              {{ $t('L_Save_Changes') }}
            </v-btn>
          </v-flex>
          <v-flex xs12 sm8 md8>
            <v-btn
              id="btn_close"
              block
              class="whitebutton btnFormat maxButtonWidth centerBtnHorizontally"
              @click="closeEditContact"
            >
              {{ $t('L_Cancel') }}
            </v-btn>
          </v-flex>
        </v-layout>
        <!--End Common-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, sameAs, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';
import {
  EmailInput,
  EmailValidation,
  ConfirmEmailInput,
  ConfirmEmailValidation,
  PhoneNumberInput,
  PhoneNumberValidation,
  AddressInput,
  AddressValidation,
  CityInput,
  CityValidation,
  StateInput,
  StateValidation,
  TerritoryInput,
  TerritoryValidation,
  ValidForTerritory,
  ZipCodeInput,
  ZipCodeValidation,
  PostalCodeInput,
  PostalCodeValidation,
} from '@/components/Inputs';
import Alert from '@/components/Alert/Alert.vue';
import MessageDialog from '@/components/MessageDialog.vue';

export const notSameAsEmail = (value, vm) => value.toLowerCase() === vm.emailAddress.toLowerCase();

export default {
  name: 'EditContactInfo',
  components: {
    EmailInput,
    EmailValidation,
    ConfirmEmailInput,
    ConfirmEmailValidation,
    PhoneNumberInput,
    PhoneNumberValidation,
    AddressInput,
    AddressValidation,
    CityInput,
    CityValidation,
    StateInput,
    StateValidation,
    TerritoryInput,
    TerritoryValidation,
    ValidForTerritory,
    ZipCodeInput,
    ZipCodeValidation,
    PostalCodeInput,
    PostalCodeValidation,
    Alert,
    MessageDialog,
  },
  props: {
    email: {
      type: String,
      required: false,
      default: '',
    },
    phone: {
      type: String,
      required: false,
      default: '',
    },
    address1: {
      type: String,
      required: false,
      default: '',
    },
    address2: {
      type: String,
      required: false,
      default: '',
    },
    city: {
      type: String,
      required: false,
      default: '',
    },
    state: {
      type: String,
      required: false,
      default: '',
    },
    zip: {
      type: String,
      required: false,
      default: '',
    },
    isCanada: {
      type: Boolean,
      required: true,
    },
    editingCategory: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    status: false,
    currentState: 'edit',
    show: true,
    allowSave: true,
    // Email
    emailAddress: '',
    confirmEmailAddress: '',
    selectedUpdateEmailAllPolicies: false,
    // Phone
    phoneNumber: '',
    confirmPhoneNumber: '',
    selectedUpdatePhoneAllPolicies: false,
    // Address
    isVerify: true,
    address1Data: '',
    address2Data: '',
    cityData: '',
    stateData: '',
    zipData: '',
    selectedUpdateAddressAllPolicies: false,
    // This is used to determine if save button to show after address verify screen
    addressSelected: '',
    localUSPSFormattedAddress1: '',
    localUSPSFormattedAddress2: '',
    selectedUpdateAll: false,
    showAddressError: false,
    showMsgDialog: false,
  }),
  validations() {
    return {
      emailAddress: {
        ...EmailValidation,
        required,
      },
      confirmEmailAddress: {
        ...ConfirmEmailValidation,
        required,
        notMatchingEmail: notSameAsEmail,
      },
      phoneNumber: {
        ...PhoneNumberValidation,
      },
      confirmPhoneNumber: {
        ...PhoneNumberValidation,
        notSamePhoneNumber: sameAs('phoneNumber'),
      },
      address1Data: {
        ...AddressValidation,
        maxlength: maxLength(35),
      },
      address2Data: {
        ...AddressValidation,
        maxlength: maxLength(35),
      },
      cityData: CityValidation,
      caTerritoryData: TerritoryValidation,
      usStateData: StateValidation,
      zipData: this?.currentPolicy?.isCanadaPolicy
        ? {
          ...PostalCodeValidation,
          validForTerritory: ValidForTerritory(this.stateData, this.zipData),
        }
        : ZipCodeValidation,
    };
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', {
      policies: 'policies',
      addressVerification: 'addressVerification',
      addressUpdateSuccess: 'addressUpdateSuccess',
      policyOwner: 'policyOwner',
      currentPolicy: 'currentPolicy',
      contactDetailsPanel: 'contactDetailsPanel',
      editContactInfo: 'editContactInfo',
      successResponseUpdateContactInfo: 'successResponseUpdateContactInfo',
      errorResponseUpdateContactInfo: 'errorResponseUpdateContactInfo',
    }),
    errorNavigateDirectlyToEachPolicy() {
      if (this.editingCategory === 'email') { // email
        return this.$t('L_Navigate_directly_to_each_policy_displayed_below_email');
      } else if (this.editingCategory === 'phone') { // mobile
        return this.$t('L_Navigate_directly_to_each_policy_displayed_below_phone');
      } else if (this.editingCategory === 'address') { // address
        return this.$t('L_Navigate_directly_to_each_policy_displayed_below_homeaddress');
      } return '';
    },
    isMultiPolicy() {
      if (this.policies?.length > 1) {
        return true;
      }
      return false;
    },
    isSuccessEdit() {
      if (this.successResponseUpdateContactInfo && this.successResponseUpdateContactInfo.length > 0) {
        return true;
      } else if (this.errorResponseUpdateContactInfo && this.errorResponseUpdateContactInfo.length > 0) {
        return false;
      }
      return true;
    },
    updateDialogType() {
      if (this.isSuccessEdit) {
        return 'success';
      }
      return 'error';
    },
    updateDialogMessage() {
      if (this.isSuccessEdit) {
        if (this.editingCategory === 'email') { // email
          return this.$t('L_your_email_address_has_been_updated_successfully_for_the_following_policies');
        } else if (this.editingCategory === 'phone') { // mobile
          return this.$t('L_your_phone_number_has_been_updated_successfully_for_the_following_policies');
        } else if (this.editingCategory === 'address') { // address
          return this.$t('L_your_home_address_has_been_updated_successfully_for_the_following_policies');
        }
      }
      if (this.editingCategory === 'email') { // email
        return this.$t('L_there_was_error_updating_your_new_email_address_for_the_following_policies');
      } else if (this.editingCategory === 'phone') { // mobile
        return this.$t('L_there_was_error_updating_your_new_phone_number_for_the_following_policies');
      } else if (this.editingCategory === 'address') { // address
        return this.$t('L_there_was_error_updating_your_new_home_address_for_the_following_policies');
      }
      return '';
    },
    updateDialogTitle() {
      if (this.isSuccessEdit) {
        if (this.editingCategory === 'email') { // email
          return this.$t('L_Email_address_has_been_updated_successfully');
        } else if (this.editingCategory === 'phone') { // mobile
          return this.$t('L_Phone_number_has_been_updated_successfully');
        } else if (this.editingCategory === 'address') { // address
          return this.$t('L_address_has_been_updated_successfully');
        }
      }
      if (this.editingCategory === 'email') { // email
        return this.$t('L_there_was_error_updating_your_new_email_address_for_the_following_policies');
      } else if (this.editingCategory === 'phone') { // mobile
        return this.$t('L_there_was_error_updating_your_new_phone_number_for_the_following_policies');
      } else if (this.editingCategory === 'address') { // address
        return this.$t('L_there_was_error_updating_your_new_home_address_for_the_following_policies');
      }
      return '';
    },
    updateDialogPolicies() {
      if (this.isSuccessEdit) {
        return this.successResponseUpdateContactInfo;
      }
      return this.errorResponseUpdateContactInfo;
    },
    checkBoxLabel() {
      if (this.editingCategory === 'email') { // email
        return this.$t('L_Update_email_address_for_all_my_policies');
      } else if (this.editingCategory === 'phone') { // mobile
        return this.$t('L_Update_phone_number_for_all_my_policies');
      } else if (this.editingCategory === 'address') { // address
        return this.$t('L_Update_home_address_for_all_my_policies');
      } return '';
    },
    addressHeaderLabel() {
      if (this.isVerify) {
        return this.$t('L_We_may_use_this_address_to_contact_you_regarding_your_life_policy');
      }
      return this.$t('L_select_which_address_you_want_to_use_from_the_following');
    },
    formattedZip() {
      const { zip } = this.addressVerification;
      if (zip && zip.length > 5) {
        return `${zip.substr(0, 5)}-${zip.substr(5)}`;
      }
      return zip;
    },
  },
  methods: {
    ...mapMutations('policy', {
      setAddressUpdateSuccess: 'setAddressUpdateSuccess',
      setAddressVerification: 'setAddressVerification',
      setContactInfoEdit: 'setContactInfoEdit',
    }),
    enableSave() {
      let enableBtn = true;
      if (this.editingCategory === 'email') {
        if (
          this.$v.emailAddress.$anyError ||
          this.$v.confirmEmailAddress.$anyError ||
          this.emailAddress.length === 0 ||
          this.confirmEmailAddress.length === 0
        ) {
          enableBtn = false;
        }
      } else if (this.editingCategory === 'phone') {
        if (
          this.$v.phoneNumber.$anyError ||
          this.$v.confirmPhoneNumber.$anyError ||
          this.phoneNumber.length === 0 ||
          this.confirmPhoneNumber.length === 0
        ) {
          enableBtn = false;
        }
      } else if (this.editingCategory === 'address') {
        if (
          this.isVerify &&
          (this.$v.address1Data.$anyError ||
            this.$v.address2Data.$anyError ||
            this.$v.cityData.$anyError ||
            (!this.currentPolicy.isCanadaPolicy && this.$v.usStateData.$anyError) ||
            (this.currentPolicy.isCanadaPolicy && this.$v.caTerritoryData.$anyError) ||
            this.$v.zipData.$anyError ||
            this.address1Data.length === 0 ||
            this.cityData.length === 0 ||
            this.stateData.length === 0 ||
            this.zipData.length === 0)
        ) {
          enableBtn = false;
        } else if (this.isVerify === false && this.addressSelected === '') {
          enableBtn = false;
        }
      }
      return enableBtn && this.allowSave;
    },
    async clickSaveChanges() {
      this.allowSave = false;
      // Existing data
      const reqObj = {
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.state,
        zip: this.zip,
        phone: this.phone,
        email: this.email,
        updateAll: this.selectedUpdateAll,
      };
      // Update the portion that was modified
      if (this.editingCategory === 'email') {
        reqObj.email = this.emailAddress;
      } else if (this.editingCategory === 'phone') {
        reqObj.phone = this.phoneNumber;
      } else if (this.editingCategory === 'address') {
        if (this.addressSelected === 'useUsps') {
          reqObj.address1 = this.addressVerification.address1;
          reqObj.address2 = this.addressVerification.address2;
          reqObj.city = this.addressVerification.city;
          reqObj.state = this.addressVerification.state;
          reqObj.zip = this.addressVerification.zip;
        } else {
          //  (this.addressSelected === 'userEntered') && when isVerify === true i.e. during user data entry
          reqObj.address1 = this.address1Data;
          reqObj.address2 = this.address2Data;
          reqObj.city = this.cityData;
          reqObj.state = this.stateData;
          reqObj.zip = this.zipData;
        }
      }
      // Following section is to handle empty data for email or phone on the save.
      if (reqObj.email === '') {
        reqObj.email = this.currentPolicy?.data?.clientEmailaddress || this.policyOwner.email || this.customerProfile.email;
      }
      if (reqObj.phone === '') {
        reqObj.phone = this.currentPolicy?.data?.phoneNumber || this.policyOwner.address.phone || this.customerProfile.mobilePhone;
      }

      // For email and phone update the data
      if (
        this.editingCategory === 'email' ||
        this.editingCategory === 'phone'
      ) {
        // This call is used for verification (addressMatched: true | false)  and update .
        await this.$store.dispatch('policy/updateContactInfo', reqObj);
        if (this.addressVerification === 'error') { // TODO add way to show error in front end
          this.allowSave = true;
          this.currentState = 'edit';
        } else {
          this.currentState = 'save';
        }
      } else if (this.editingCategory === 'address') {
        if (this.currentPolicy.isCanadaPolicy) {
          await this.$store.dispatch('policy/updateContactInfo', reqObj);
          if (this.addressVerification === 'error') { // TODO add way to show in front end
            this.allowSave = true;
            this.currentState = 'edit';
          } else {
            this.currentState = 'save';
          }
        } else if (this.isVerify === true) {
          await this.$store.dispatch('policy/verifyContactInfo', reqObj);
          // Let user retry putting address again
          if (this.addressVerification === 'error') {
            this.showAddressError = true;
            this.allowSave = true;
            this.isVerify = true;
          } else {
            this.showAddressError = false;
            // Success backend call for address API
            this.allowSave = true;
            this.isVerify = false;
            this.localUSPSFormattedAddress1 = `${this.addressVerification.address1} ${this.addressVerification.address2}`;
            this.localUSPSFormattedAddress2 = `${this.addressVerification.city},${this.addressVerification.state} ${this.formattedZip}`;
          }
          // This is edit because user will have to select USPS or self entered address
          this.currentState = 'edit';
        } else { // US users after verify
          await this.$store.dispatch('policy/updateContactInfo', reqObj, false);
          if (this.addressVerification === 'error') {
            this.allowSave = true;
            this.currentState = 'edit';
          } else {
            this.currentState = 'save';
          }
        }
      }
      if (this.currentState === 'save') {
        this.showMsgDialog = true;
      }
      const successPolicyArray = this.successResponseUpdateContactInfo;
      const currentPolicyNum = this.currentPolicy.contractId;
      // policyNumber: this.currentPolicy.contractId,
      if (this.currentState === 'save' && successPolicyArray.length > 0 && successPolicyArray.indexOf(currentPolicyNum) > -1) {
        await this.$store.dispatch('policy/getPolicies');
      }
    },
    async closeEditContact() {
      // await this.$store.dispatch('policy/setAddressVerification', null);
      // await this.$store.dispatch('policy/setAddressUpdateSuccess', false);
      this.setAddressVerification(null);
      this.setAddressUpdateSuccess(false);
      this.showMsgDialog = false;
      try {
        await this.$store.dispatch('policy/getPolicies');
        await this.$store.dispatch('policy/getPolicyOverview');
      } catch (error) {
        // issue with fetching data
      } finally {
        this.$emit('closeeditcontact');
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
.pinkBkgrndWithRedTop{
  background-color: #ffecec;
  border-top: 4px solid red;
  padding: 10px 10px 10px 15px;
  border-bottom: 1px solid gray;
}
.ltgreenBkgrndWithGreenTop{
  background-color: #d8fcdc;
  border-top: 4px solid green;
  padding: 10px 2px 10px 15px;
  border-bottom: 1px solid gray;
}
.moveToRight {
  float: right !important;
}
.graybutton {
  color: black !important;
  background-color: #b3b3b3 !important;
}
.bluebutton {
  color: #FFFFFF !important;
  background-color: #063662 !important;
  border: 2px solid #b3b3b3 !important;
}
.whitebutton {
  color: #063662 !important;
  background-color: #FFFFFF !important;
}
.btnFormat {
  width: 260px;
}
.btnOKSize{
  width: 40%;
  max-width: 288px;
}
.centerBtnHorizontally {
  margin: auto !important;
}
.divHoldingPolicyForSuccessAndError{
  background-color: #eeeeee;
  padding: 10px 25px 10px 25px;
}
.maxButtonWidth {
  max-width: 275px;
  width: 275px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.primarybutton {
  color: #FFFFFF !important;
  background-color: #063562 !important;
}
.blue-border {
  border: solid 2px #2196f3;
  cursor pointer;
}
.gray-border {
  border: solid 2px #eeeeee;
  cursor pointer;
}
.heading_two {
  line-height: 22px;
}
.fullHorizontalStretch {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
</style>
