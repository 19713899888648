<i18n src='../locales.json'></i18n>

<template>
  <v-container fluid>
    <v-layout column>
      <!-- TODO: AC changes needed -->
      <p v-if="!minorAccountsNotLinked.length">
        {{ $t('mal_the_minor_account_have_been_linked_successfully' ) }}
      </p>
      <p v-else>
        {{ $t('mal_some_minor_accounts_below_linked_successfully' ) }}
      </p>
      <p>{{ $t('mal_you_will_now_see_the_balance') }}</p>
      <div v-for="account in minorAccountsLinked" :key="account.accountId">
        <v-icon
          class="left-icon"
          color="success"
        >
          done
        </v-icon>
        {{ account.accountNumber }}&nbsp;&nbsp;&nbsp;&nbsp;{{ account.firstName }}&nbsp;{{ account.lastName }}<br>
      </div>
      <div v-if="minorAccountsNotLinked.length" class="py-4">
        <p>
          {{ $t('mal_however_the_following_accounts_were_not_linked') }}
        </p>
        <div v-for="account in minorAccountsNotLinked" :key="account.accountId">
          <v-icon
            class="left-icon"
            color="error"
          >
            priority_high
          </v-icon>
          {{ account.accountNumber }}&nbsp;&nbsp;&nbsp;&nbsp;{{ account.firstName }}&nbsp;{{ account.lastName }}<br>
        </div>
      </div>
      <v-layout mt-4 row wrap :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
        <v-flex xs12 offset-md8 sm4 mb-3 ml-3>
          <v-btn :disabled="disableActions" block color="primary" @click="continueAction">
            OK
          </v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'MinorAccountLinkProcessLinkConfirmation',
  props: {
    minorAccountsLinked: {
      type: Array,
      required: true,
    },
    minorAccountsNotLinked: {
      type: Array,
      required: true,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
    continueAction() {
      this.$emit('continueClicked', { });
    },
    cancelAction() {
      this.$emit('cancelClicked');
    },
  },
};
</script>

<style>
</style>
