<i18n src='./locales.json'></i18n>

<template>
  <v-flex
    mt-2
    xs12
  >
    <BeneficiaryCard />
    <div v-if="displayFooter">
      <USBeneficiaryCard
        v-if="isUsPolicyCode && !isRestrictedPolicy"
      />
      <NYBeneficiaryCard
        v-else-if="isNyPolicyCode && !isRestrictedPolicy"
      />
      <CABeneficiaryCard
        v-else-if="isCanadaPolicyCode && !isRestrictedPolicy"
        :is-quebec="isCanadaQuebec"
      />
      <BeneficiaryDefault v-else />
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '@/shared/constants';
import USBeneficiaryCard from './components/USBeneficiaryCard/USBeneficiaryCard.vue';
import NYBeneficiaryCard from './components/NYBeneficiaryCard/NYBeneficiaryCard.vue';
import CABeneficiaryCard from './components/CABeneficiaryCard/CABeneficiaryCard.vue';
import BeneficiaryDefault from './components/BeneficiaryDefault/BeneficiaryDefault.vue';
import BeneficiaryCard from './components/BeneficiaryCard/BeneficiaryCard.vue';

export default {
  name: 'Beneficiaries',
  components: {
    USBeneficiaryCard,
    NYBeneficiaryCard,
    CABeneficiaryCard,
    BeneficiaryDefault,
    BeneficiaryCard,
  },
  props: {
    isRestrictedPolicy: {
      type: Boolean,
      default: false,
    },
    isUsPolicyCode: {
      type: Boolean,
      default: true,
    },
    isNyPolicyCode: {
      type: Boolean,
      default: false,
    },
    isCanadaPolicyCode: {
      type: Boolean,
      default: false,
    },
    isCanadaQuebec: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('policy', {
      isPending: 'isPending',
      policy: 'currentPolicy',
    }),
    ...mapGetters(['containsFeature', 'features']),
    displayFooter() {
      return this.isPending || !this.containsFeature(FEATURE_FLAGS.BENEFICIARIES_EDIT)
        || !this.policy.allowEditBeneficiary;
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'

.beneficiaries-subtitle {
  margin-top: $spacers.three.y !important;
  font-size: $headings.h6.size !important;
}

.beneficiaries-phone {
  margin-top: $spacers.three.y !important;
  font-size: $headings.h6.size !important;
}

.beneficiaries-subtext
  padding-left: $spacers.four.X;
  padding-right: $spacers.four.x;
  @media $display-breakpoints.sm-and-up
    padding-left: 100px;
    padding-right: 100px;

.beneficiaries-for-more-information
  display: block;
  @media $display-breakpoints.sm-and-up
    display: inline;
    margin-left: $spacers.two.x;
</style>
