<i18n src='./locales.json'></i18n>
<template>
  <v-container :class="$vuetify.breakpoint.xs ? 'dtMainContainerBackgroundMob' : 'dtMainContainerBackground'">
    <LoadingIndicator class="setupLeft"
                      :loading="updatingDebtData"
    />
    <v-layout row wrap>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'myCurrentDebtDetailsMob' : 'myCurrentDebtDetails'">
        {{ $t('L_my_current_debt_details') }}
      </v-flex>
    </v-layout>
    <v-divider />
    <!-- Show the Create Your Plan button -->
    <v-layout row wrap v-if="isDebtInputHidden">
      <!-- Create Your Plan-->
      <v-layout row wrap v-if="hasNoPlan">
        <v-flex xs12 text-xs-center :class="$vuetify.breakpoint.xs ? 'enterEachOfYourDebtMob' : 'enterEachOfYourDebt'">
          {{ $t('L_enter_each_of_your_debts') }}
        </v-flex>
        <v-flex text-xs-center pt-3 pb-3>
          <v-btn
            role="link"
            color="primary"
            class="max-btn-width"
            @click="openDebtInput"
            :disabled="disablebutton"
          >
            {{ $t('L_create_your_plan') }}
          </v-btn>
        </v-flex>
      </v-layout>
      <!-- This is if theres debt data -->
      <div v-if="!hasNoPlan" class="divfullwidth">
        <!-- This is your paid off debts section -->
        <v-layout row wrap>
          <v-flex xs12 class="yourpaidoffdebts">
            {{ $t('L_your_paid_off_debts') }}
          </v-flex>
        </v-layout>
        <!-- If no debt paid off default message-->
        <v-layout row wrap v-if="paidOffDebts.length===0">
          <v-flex xs12 text-xs-center class="pl-2 pr-2 pt-3 pb-3" :class="$vuetify.breakpoint.xs ? 'font-size--16' : 'font-size--18'">
            {{ $t('L_as_you_progress_toward_your_goal') }}
          </v-flex>
        </v-layout>
        <!-- If paid off debts then -->
        <div v-if="paidOffDebts.length > 0">
          <v-layout row wrap v-for="(paidOffDebtObject, index) in paidOffDebts" :key="index" class="paidoffdebt">
            <v-flex xs3 text-xs-center>
              <span><img alt="trophy" src="../../../assets/trophy.svg"></span>
              <span class="greenText">&nbsp;{{ $t('L_paid_off') }}</span>
            </v-flex>
            <v-flex xs8 class="font-weight-regular linkText" @click="openDebtInput(paidOffDebtObject.debtID)">
              {{ paidOffDebtObject.debtName }}
            </v-flex>
            <v-flex xs2 text-xs-right pr-3 class="greenText">
              {{ formattedMoney(paidOffDebtObject.minPayment) }}
            </v-flex>
          </v-layout>
          <v-layout row wrap pt-4 pb-4>
            <v-flex xs10 pl-4 pr-4>
              {{ $t('L_congratulations_now_that_you_have') }}
            </v-flex>
            <v-flex xs2>
              <div class="grayRoundedButtonWBlueText">
                +${{ getTotalMinPaymentUsinggPaidOffDebts }}
              </div>
            </v-flex>
          </v-layout>
        </div>
        <!-- This is the Current Debts section -->
        <v-layout row wrap>
          <v-flex class="currentdebts">
            {{ $t('L_current_debts') }}
          </v-flex>
        </v-layout>
        <!-- Current Debt for the Desktop view -->
        <div v-if="!$vuetify.breakpoint.xs">
          <v-layout row wrap font-weight-bold pt-2 pb-2 class="borderOnBottom">
            <v-flex xs3 font-size--16 text-xs-left>
              {{ $t('L_debt_name') }}
            </v-flex>
            <v-flex xs3 font-size--16 text-xs-right>
              {{ $t('L_pay_off_date') }}
            </v-flex>
            <v-flex xs3 font-size--16 text-xs-right>
              {{ $t('L_debt_balance') }}
            </v-flex>
            <v-flex xs3 font-size--16 text-xs-right>
              {{ $t('L_monthly_payment') }}
            </v-flex>
          </v-layout>
          <v-layout row wrap pt-4 pb-4 font-weight-normal v-for="(item, i) in filteredCurrentDebt" :key="i" class="borderOnBottom">
            <v-flex xs3 font-size--16 text-xs-left class="linkText" @click="openDebtInput(item.debtId)">
              {{ item.debtName }}
            </v-flex>
            <v-flex xs3 font-size--16 text-xs-right>
              <!--  {{ getMonthAndYear(projectedPayoffDateGivenDebtId(debtObject.debtID)) }} -->
              {{ getMonthAndYear(item.projectedPayoffDate) }}
            </v-flex>
            <v-flex xs3 font-size--16 class="textalignright">
              {{ getDollarAmount(debtBalanceForDebtId(item.debtId)) }}
            </v-flex>
            <v-flex xs3 font-size--16 class="textalignright">
              {{ getDollarAmount(debtMonthlyPaymentForDebtId(item.debtId)) }}
            </v-flex>
          </v-layout>
        </div>
        <!-- Current Debt for the Mobile view -->
        <div v-if="$vuetify.breakpoint.xs">
          <v-layout row wrap pt-2 pb-2 pl-4 pr-4 font-weight-normal v-for="(item, i) in filteredCurrentDebt" :key="i"
                    class="borderOnBottom"
          >
            <v-flex xs12 pt-2 pb-2 font-size--16 text-xs-left class="linkText font-weight-bold" @click="openDebtInput(item.debtId)">
              {{ item.debtName }}
            </v-flex>

            <v-flex xs6 pt-2 pb-2 font-size--14 text-xs-left class="font-weight-bold">
              {{ $t('L_pay_off_date') }}
            </v-flex>
            <v-flex xs6 pt-2 pb-2 font-size--14 text-xs-right>
              <!--  {{ getMonthAndYear(projectedPayoffDateGivenDebtId(debtObject.debtID)) }} -->
              {{ getMonthAndYear(item.projectedPayoffDate) }}
            </v-flex>

            <v-flex xs6 pt-2 pb-2 font-size--14 text-xs-left class="font-weight-bold">
              {{ $t('L_debt_balance') }}
            </v-flex>
            <v-flex xs6 pt-2 pb-2 font-size--14 text-xs-right class="textalignright">
              {{ getDollarAmount(debtBalanceForDebtId(item.debtId)) }}
            </v-flex>

            <v-flex xs6 pt-2 pb-2 font-size--14 text-xs-left class="font-weight-bold">
              {{ $t('L_monthly_payment') }}
            </v-flex>
            <v-flex xs6 pt-2 pb-2 font-size--14 text-xs-right class="textalignright">
              {{ getDollarAmount(debtMonthlyPaymentForDebtId(item.debtId)) }}
            </v-flex>
          </v-layout>
        </div>


        <v-layout row wrap pt-4 pb-4>
          <v-flex xs3 pt-2 pl-2>
            <v-divider color="#cccccc" />
          </v-flex>
          <v-flex xs6 font-weight-bold @click="openDebtInput" class="addadebtlink">
            <span class="circleplus">+</span> {{ $t("L_add_a_debt") }}
          </v-flex>
          <v-flex xs3 pt-2 pr-2>
            <v-divider color="#cccccc" />
          </v-flex>
        </v-layout>
      </div>
    </v-layout>

    <!-- Show the input screen -->
    <v-layout v-if="!isDebtInputHidden">
      <DebtInput @close-debt-input="onCloseDebtInput" :temp-debt-data="tempObject" />
    </v-layout>
    <v-divider />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DebtInput from './DebtInput.vue';
import LoadingIndicator from '../../../../../components/LoadingIndicator.vue';

export default {
  name: 'DebtDetails',
  components: {
    DebtInput, LoadingIndicator,
  },
  props: {
    disablebutton: {
      type: Boolean,
      default: false,
    },
    tpersondob: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isDebtInputHidden: true,
    valTotPaidOffMonthlyAmount: 0,
    tempObject: null,
    isDisabledCloseTheDebtInput: false,
    updatingDebtData: false,
  }),
  computed: {
    ...mapGetters({
      hasNoPlan: 'debtsolutions/hasNoPlan',
      debts: 'debtsolutions/debts',
      unpaidDebts: 'debtsolutions/unpaidDebts',
      paidOffDebts: 'debtsolutions/paidOffDebts',
      getTotalMinPaymentUsinggPaidOffDebts: 'debtsolutions/getTotalMinPaymentUsinggPaidOffDebts',
      debtGivenDebtId: 'debtsolutions/debtGivenDebtId',
      projectedPayoffDateGivenDebtId: 'debtsolutions/projectedPayoffDateGivenDebtId',
      debtBalanceForDebtId: 'debtsolutions/debtBalanceForDebtId',
      debtMonthlyPaymentForDebtId: 'debtsolutions/debtMonthlyPaymentForDebtId',
      step: 'debtsolutions/step',
      planWOStacking: 'debtsolutions/planWOStacking',
      planWStacking: 'debtsolutions/planWStacking',
      addADebtOpen: 'debtsolutions/addADebtOpen',
    }),
    filteredCurrentDebt() {
      const tempListToFilter = this.planWOStacking.planItemCollection;
      const paidOffDebtList = this.paidOffDebts;
      if (tempListToFilter !== undefined && paidOffDebtList !== undefined && tempListToFilter.length > 0 && paidOffDebtList.length > 0) {
        return tempListToFilter.filter(x => !paidOffDebtList.filter(y => y.debtID === x.debtId).length);
      }
      return tempListToFilter;
    },
  },
  mounted() {
    if (this.addADebtOpen === true) {
      this.tempObject = null;
      this.isDebtInputHidden = false;
    }
  },
  methods: {
    ...mapActions({
      createNewDebt: 'debtsolutions/createDebtItem',
      updateDebt: 'debtsolutions/updateDebtItem',
      deleteDebt: 'debtsolutions/deleteDebtItem',
      debtInit: 'debtsolutions/debtInit',
      setAddADebtOpen: 'debtsolutions/setAddADebtOpen',
    }),
    getDollarAmount(balance) {
      // eslint-disable-next-line no-restricted-globals
      if (balance == null || isNaN(balance)) {
        return '';
      }
      return this.$n(balance, 'currencyNoCents', 'en-US');
    },
    getMonthAndYear(tObj) {
      let rDate = null;
      const tDate = moment(tObj, 'YYYY-MM-DDTHH:mm:ss-nn:00');
      rDate = moment(tDate).format('MMM YYYY');
      return rDate;
    },
    getPayOffDate(debtID) {
      const val = this.debtGivenDebtId(debtID);
      return val?.projectedPayoffDate;
    },
    // eslint-disable-next-line no-unused-vars
    async onCloseDebtInput(debtData) {
      try {
        // Logic for adding / updating done via the
        // this.updateDebtItem(this.debtData); in the DebtInput during submit.
        if (this.isDisabledCloseTheDebtInput) {
          return;
        }
        // Show the Loading spinner
        this.updatingDebtData = true;
        this.isDisabledCloseTheDebtInput = true;
        let response;
        if (debtData && debtData.debtName) {
          if (debtData.remove) {
            response = await this.deleteDebt(debtData, this.tpersondob);
          } else if (debtData.debtID !== undefined && debtData.debtID > -1) {
            response = await this.updateDebt(debtData, this.tpersondob);
          } else {
            response = await this.createNewDebt(debtData, this.tpersondob);
          }
          // this.debtInit();
          if (response) {
            this.updatingDebtData = false;
          }
        } else {
          this.updatingDebtData = false;
        }
        setTimeout(() => {
          this.isDisabledCloseTheDebtInput = false;
        }, 500);
        this.isDebtInputHidden = true;
        this.setAddADebtOpen(false);
        this.$emit('is-debt-input-hidden', true);
      } catch (e) {
        this.$store.commit('addError', { error: e, context: 'DebtDetails onCloseDebtInput' });
        this.isDisabledCloseTheDebtInput = false; // This allows to close Debt Input
        this.updatingDebtData = false; // Hide the modal spinner
        this.isDebtInputHidden = true; // Hide the Debt Input
        this.$emit('is-debt-input-hidden', true);
      }
    },
    openDebtInput(debtId) {
      if (typeof (debtId) === 'string' || typeof (debtId) === 'number') {
        this.tempObject = JSON.parse(JSON.stringify(this.debtGivenDebtId(debtId)));
      } else {
        this.tempObject = null;
      }
      this.isDebtInputHidden = false;
      this.$emit('is-debt-input-hidden', false);
    },
    getDebtBalance(debtObject) {
      return (debtObject !== undefined)
        ? this.$n(debtObject?.balance, 'currencyNoCents', 'en-US')
        : '';
    },
    getMontlyPayment(debtObject) {
      return (debtObject !== undefined)
        ? this.$n(debtObject?.minPayment, 'currencyNoCents', 'en-US')
        : '';
    },
    formattedMoney(strMoney) {
      return (strMoney !== undefined)
        ? this.$n(strMoney, 'currencyNoCents', 'en-US')
        : '';
    },
  },
};
</script>
<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
.dtMainContainerBackground
  background-color: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
.dtMainContainerBackgroundMob
  background-color: #ffffff;
  padding-left: 0px;
  padding-right: 0px;
.max-btn-width
  max-width: 260px;
  width: 260px;
.addadebtlink
  color: #02579e;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
.circleplus
  border: solid 2px #02579e;
  border-radius: 18px;
  padding: 0 3px 0 3px;
  margin-right: 8px;
.divfullwidth
  width: 100%;
.borderOnBottom
  border-bottom: 1px solid rgba(0,0,0,0.12) !important;
.currentdebts, .yourpaidoffdebts
  border-top:1px solid #cccccc;
  border-bottom:1px solid #cccccc;
  background-color: #f5f5f5;
  font-size:20px;
  line-height: 2.9;
  text-align: center;
.asyouprogress
  background-color: #ffffff;
  font-size: 14px;
  height: 76px;
  line-height:76px;
  text-align: center;
.textalignright
  text-align:right;
.paidoffdebt
  height:50px;
  line-height:50px;
  background-color: #eafbec;
  font-weight: 600;
.linkText
  color: #02579e;
  cursor: pointer;
.greenText
  color: #098d1f;
.grayRoundedButtonWBlueText
  background-color: #eeeeee;
  color: #063562;
  border-radius: 16px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-top:20px;
  font-weight: 600;
.enterEachOfYourDebt
  font-size: 20px;
  color: #333333;
  padding-top:20px;
.enterEachOfYourDebtMob
  font-size: 20px;
  color: #333333;
  padding-top:20px;
.setupLeft
  margin-left: -24px;
.loading-indicator
  z-index: 10 !important;
.disabledBtn
  background-color: #eee !important;
.size22font
  font-size: 22px;
.myCurrentDebtDetails
  font-size:24px;
  padding: 24px 0px 24px 0px;
.myCurrentDebtDetailsMob
  font-size:22px;
  text-align: center;
  padding: 12px 0px 12px 0px;
</style>
