<i18n src='./locales.json'></i18n>
<template>
  <div>
    <div class="font-weight-medium">
      {{ $t('L_contact_i_would_like_to') }}
    </div>
    <div>
      <v-select
        :id="idProp"
        v-model="formInput.$model"
        :disabled="disabled"
        :hint="selectHint"
        :items="items"
        :placeholder="$t('L_contact_placeholder')"
        :error-messages="errorMessages"
        @input="onInput"
        @blur="onBlur"
        :aria-describedby="describers"
      />
    </div>
  </div>
</template>

<script>
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'ReasonInput',
  extends: BaseTextField,
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'reason',
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    modelName: 'reason',
    reason: '',
  }),
  computed: {
    selectHint() {
      return (this.formInput.$model === 'question') ? this.$t('L_contact_policy_question_hint') : undefined;
    },
  },
};
</script>
