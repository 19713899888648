<template>
  <v-layout
    row
    wrap
  >
    <v-flex xs12>
      <h3
        role="presentation"
        v-html="header"
        class="heading_three text-xs-center mb-2 no-wrap"
      />
      <v-flex
        grey
        lighten-4
        style="border-radius: 31px;"
        :class="classString"
      >
        <v-layout
          align-center
          justify-center
          pa-3
          ma-1
          class="pill-body"
        >
          <h3
            role="presentation"
            class="heading_three text-xs-center font-weight-medium mr-1"
          >
            $
          </h3>
          <h3
            role="presentation"
            class="heading_three font-weight-medium"
            style="margin-bottom: -3px;"
          >
            <slot>
              <span style="font-size: 1.4em">{{ amount }}</span>
            </slot>
          </h3>
        </v-layout>
      </v-flex>
      <h3
        role="presentation"
        v-html="footer"
        class="heading_three text-xs-center"
      />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'GuidancePill',
  props: {
    header: {
      type: String,
      required: true,
    },
    footer: {
      type: String,
      required: true,
    },
    amount: {
      type: [String, Number],
      required: true,
    },
    classString: {
      type: String,
      required: false,
      default: undefined,
    },
  },
};
</script>

<style lang="stylus" scoped>
$smAndDown = "only screen and (max-width: 768px)"
h3.heading_three
  @media $smAndDown
    font-size: 16px !important;
    &.font-weight-medium
      font-size: 14px !important;
.pill-body
  @media $smAndDown
    padding: 4px 4px 3px 4px !important;
</style>
