<i18n src='./locales.json'></i18n>
<template>
  <v-container class="backgroundWhite pb-4">
    <div class="fullHeight">
      <v-layout v-if="isLoading">
        loading
      </v-layout>
      <v-layout row wrap mt-4 pb-4 mb-4 v-else>
        <v-flex xs12 class="font-size--18 font-weight-bold text-xs-center">
          {{ $t('L_you_have_successfully_signed_out') }}
        </v-flex>
        <v-flex xs12 ml-4 mr-4 text-xs-center>
          <img class="imageDimension" src="./assets/SignOutPerson.png" alt="Signing out">
        </v-flex>
        <v-flex xs12 class="mb-4 pb-4 putAtBottomCenterHorizontally">
          <v-layout row wrap>
            <v-flex xs12 text-xs-center>
              <v-btn @click="login" color="primary" class="mb-4">
                {{ $t('L_go_back_to_sign_in') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
// TODO: unit test
export default {
  name: 'PostLogoutPorch',
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      await this.$auth
        .login()
        .then(() => (this.$router.push({ name: 'PostLoginLobby' })))
        .catch((err) => {
          try {
            this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PostLogoutPorch - Login failed] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
          } catch (error) { /*  */ }
        });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.backgroundWhite {
  background-color: white;
}
.imageDimension {
  width: 80%;
  max-width: 300px;
}
.fullHeight {
  height: 100vh;
  position: relative;
}
.putAtBottomCenterHorizontally {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

</style>
