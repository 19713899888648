<i18n src="../locales.json"></i18n>

<template>
  <v-flex xs12 sm6 md6 class="mt8 pa8">
    <CardTemplate v-if="$vuetify.breakpoint.smAndUp">
      <img slot="icon" class="account-img" src="../assets/update-info.svg" alt="" role="presentation">
      <span slot="title" v-html="$t('L_mobile_phone_number')" />
      <template v-if="mobileNoExists">
        <v-flex slot="content" sm12 text-sm-center>
          <v-layout row wrap>
            <v-flex sm4 />
            <v-flex sm4>
              <span v-if="!customerProfile">&nbsp;- </span>
              <span v-if="customerProfile">
                {{ formatedContactNumber }}
              </span>
            </v-flex>
            <v-flex v-if="canShowVerifyNow" sm4 text-sm-left>
              <a v-ada-modal role="button" slot="action" href="javascript: void(0)" @click="verifyNow" id="verify_now" :aria-label="$t('L_verify_now')" v-if="!mobileNoVerified">{{
                $t('L_verify_now')
              }}</a>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex slot="content" sm12 text-sm-center class="font-weight-regular font-italic">
          <span v-html="bindVerifiedLabel" />
        </v-flex>
        <a
          v-ada-modal
          role="button"
          slot="action"
          href="javascript: void(0)"
          @click="(showUpdatePhoneDialog = true), (updatedNumber = false)"
          id="update_user_mobile_trigger"
          :aria-label="$t('L_update_mobile_number')"
        >{{ $t('L_edit') }}</a>
      </template>
      <template v-else>
        <v-flex slot="content" sm12 text-sm-center class="hidden-xs-only">
          <v-layout row wrap justify-center>
            <v-flex sm8>
              <span>
                {{ $t('L_to_keep_you_account') }}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
        <a
          v-ada-modal
          role="button"
          slot="action"
          href="javascript: void(0)"
          @click="(showUpdatePhoneDialog = true), (updatedNumber = false)"
          id="add_mobile_phone"
          :aria-label="$t('L_add_your_mobile_phone')"
        >{{ $t('L_add_your_mobile_phone') }}</a>
      </template>
    </CardTemplate>
    <UpdateContactMobile
      @show-modal="showDialog"
      @verify-now="verifyNow"
      :formated-contact-number="formatedContactNumber"
      :mobile-no-exists="mobileNoExists"
      :mobile-no-verified="mobileNoVerified"
      :bind-verified-label="bindVerifiedLabel"
      :customer-profile="customerProfile"
      v-else
    />
    <MyAccountDialog
      :header="$t('L_update_mobile_number')"
      :show="showUpdatePhoneDialog"
      @save="next()"
      @close="(phoneNumber = ''), (showUpdatePhoneDialog = false), (closeIsActive = false), verifyPhone()"
      :close-is-active="closeIsActive"
      :enable-save-button="enableSaveButton"
      :submit-btn-text="$t('L_next')"
    >
      <v-layout row wrap slot="content">
        <v-flex xs12 font-size--16 text-xs-center mb-4>
          {{ $t('L_this_phone_number_may_be_used_for_verification_purposes') }}
        </v-flex>
        <v-flex xs12>
          <label class="mb-0 font-size--12 font-weight-bold" style="display: block;">{{ $t('L_current_mobile_number') }}</label>
          <label v-if="customerProfile" class="mb-5" style="display: block; color: #767575;"> {{ formatedContactNumber }} </label>
        </v-flex>
        <v-flex xs12>
          <PhoneNumberInput
            id="mobile-phone"
            :disabled="updatedNumber"
            id-prop="update_mobile_phone"
            v-model="phoneNumber"
            :form-input="$v.phoneNumber"
            :label="$t('L_step_1_phone')"
            :validation-messages="{
              notCurrentNumber: $t('L_mobile_number_must_be_different'),
              required: $t('L_mobile_number_required'),
            }"
          />
        </v-flex>
      </v-layout>
    </MyAccountDialog>
  </v-flex>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { phoneNumber as phoneNumberFormatter } from '@/shared/util/formatters';
import { PhoneNumberInput, PhoneNumberValidation } from '@/components/Inputs';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';
import bff from '../../../../../shared/api/bff';
import UpdateContactMobile from '../updateContactMobile/UpdateContactMobile.vue';
import { FEATURE_FLAGS } from '../../../../../shared/constants';

export const notCurrentNumberValidator = (value, vm) => value !== vm.customerProfile.mobilePhone?.trim();

export default {
  name: 'UpdateContact',
  components: {
    CardTemplate,
    MyAccountDialog,
    PhoneNumberInput,
    UpdateContactMobile,
  },
  data: () => ({
    showUpdatePhoneDialog: false,
    phoneNumber: '',
    closeIsActive: false,
    updatedNumber: false,
    clickHandler: undefined,
  }),
  validations() {
    return {
      phoneNumber: {
        ...PhoneNumberValidation,
        required,
        notCurrentNumber: notCurrentNumberValidator,
      },
    };
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters({
      containsFeature: 'containsFeature',
    }),
    canShowVerifyNow() {
      return this.containsFeature(FEATURE_FLAGS.CUST_VERIFYPHONE);
    },
    formatedContactNumber() {
      if (this.customerProfile.mobilePhone?.trim()) {
        return phoneNumberFormatter(this.customerProfile.mobilePhone);
      }
      return '-';
    },
    enableSaveButton() {
      return this.$v.$anyDirty && !this.$v.$error;
    },
    mobileNoExists() {
      return !!(this.customerProfile.mobilePhone !== '' && this.customerProfile.mobilePhone !== '1231231234');
    },
    mobileNoVerified() {
      return !!this.customerProfile.phoneVerified;
    },
    bindVerifiedLabel() {
      if (this.customerProfile.phoneVerified) {
        return this.$t('L_phoneno_verified');
      }
      return this.$t('L_phoneno_unverified');
    },
  },
  watch: {
    mobileNoVerified() {
      this.setClickEvent();
    },
  },
  methods: {
    ...mapActions('customer', ['updateCustomerProfile']),
    verifyNow() {
      this.showUpdatePhoneDialog = false;
      this.closeIsActive = true;
      this.updatedNumber = true;
      this.verifyPhone();
    },
    showDialog() {
      this.showUpdatePhoneDialog = true;
    },
    setClickEvent() {
      const clickTarget = this.$el.querySelector('.clickable');
      if (clickTarget && ((this.mobileNoVerified && this.$vuetify.breakpoint.smAndUp) || this.$vuetify.breakpoint.xsOnly)) {
        this.clickHandler = this.showDialog.bind(this);
        clickTarget.addEventListener('click', this.clickHandler);
      } else if (clickTarget) {
        clickTarget.removeEventListener('click', this.clickHandler);
      }
    },
    async next() {
      this.$v.$reset();
      // this.phoneNumber = '';
      this.closeIsActive = true;
      // Call Verify, In the MyAccount passing true flag for :bypass-initial-page-and-send-otp
      await this.$emit('verify-bypass-page-one', this.phoneNumber);
      this.phoneNumber = '';
      this.showUpdatePhoneDialog = false;
    },

    // Actual save of data to backend
    async save() {
      const mobileInfo = {
        mobileNumber: this.phoneNumber,
      };
      try {
        /*
         * Emit a custom action when a user successfully submits an update mobile number request
         * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
         */
        const i = this.$dynatrace.enterAction('account_settings_changes_saved_mobile_number');
        await bff.updateMobileNumber(mobileInfo);
        this.$dynatrace.leaveAction(i);
        this.$store.commit('addAlert', {
          type: 'success',
          details: { message: this.$t('L_MOBILE_VERIFICATION_Mobile_Number_Successfully_Updated') },
          context: 'myaccount',
        });
        this.updateCustomerProfile({
          mobilePhone: this.phoneNumber,
          phoneVerified: false,
        });
        this.$v.$reset();
        this.phoneNumber = '';
        this.closeIsActive = true;
        this.updatedNumber = true;
      } catch (e) {
        this.$store.commit('addError', { error: e, context: 'myaccount' });
      }
    },
    verifyPhone() {
      if (this.updatedNumber) {
        this.$emit('verify-go-to-page-one', this.customerProfile.mobilePhone);
        this.updatedNumber = false;
      }
    },
  },
  mounted() {
    this.setClickEvent();
    this.phoneNumber = '';
  },
};
</script>
