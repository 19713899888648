export class Reinstatement {
    _paymentAsOfReIssueDate = '';
    _paymentAsOfOrgIssueDate = '';
    _termDate = '';
    _pacPremium = '';
    _eligible = null;

    constructor({
      paymentAsOfReIssueDate,
      paymentAsOfOrgIssueDate,
      termDate,
      pacPremium,
      eligible,
    }) {
      this._paymentAsOfReIssueDate = paymentAsOfReIssueDate;
      this._paymentAsOfOrgIssueDate = paymentAsOfOrgIssueDate;
      this._termDate = termDate;
      this._pacPremium = pacPremium;
      this._eligible = eligible;
    }

    get paymentAsOfReIssueDate() {
      return this._paymentAsOfReIssueDate || '';
    }

    get paymentAsOfOrgIssueDate() {
      return this._paymentAsOfOrgIssueDate || '';
    }
    get termDate() {
      return this._termDate || '';
    }
    get pacPremium() {
      return this._pacPremium || '';
    }
    get eligible() {
      return this._eligible || '';
    }
}
