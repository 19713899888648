<i18n src='../locales.json'></i18n>

<template>
  <PrimericaBenefits
    id="learn-more"
    :display-contact-rep="true"
    :footer-content="footerContent"
  >
    <template v-slot:hero>
      <img
        alt="$t('L_with_as_little')"
        src="../../../assets/FamilyHavingFunAtHome1.jpg"
      >
      <h1
        :aria-label="$t('L_with_as_little')"
      >
        {{ $t('L_with_as_little') }}
      </h1>
    </template>
    <template v-slot:content>
      <h2
        class="heading_two"
        :aria-label="$t('L_it_all_adds_up')"
      >
        {{ $t('L_it_all_adds_up') }}
      </h2>
      <div class="text">
        {{ $t('L_no_matter_how_much') }}
      </div>
      <v-tabs
        show-arrows
        v-model="selectedVideo"
      >
        <v-tabs-items>
          <v-tab-item>
            <Video
              :url="$t('L_video_url_high_cost_of_waiting')"
              :render="selectedVideo === 0"
            />
          </v-tab-item>
          <v-tab-item>
            <Video
              :url="$t('L_video_url_pay_yourself_first')"
              :render="selectedVideo === 1"
            />
          </v-tab-item>
          <v-tab-item>
            <Video
              :url="$t('L_video_rule_of_')"
              :render="selectedVideo === 2"
            />
          </v-tab-item>
          <v-tab-item>
            <Video
              :url="$t('L_video_power_of_compound')"
              :render="selectedVideo === 3"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-tab class="youtube-subtitle font-size--12">
          {{ $t('L_video_subtitle_1') }}
        </v-tab>
        <v-tab class="youtube-subtitle font-size--12">
          {{ $t('L_video_subtitle_2') }}
        </v-tab>
        <v-tab class="youtube-subtitle font-size--12">
          {{ $t('L_video_subtitle_3') }}
        </v-tab>
        <v-tab class="youtube-subtitle font-size--12">
          {{ $t('L_video_subtitle_4') }}
        </v-tab>
      </v-tabs>
      <v-layout class="body" row wrap>
        <h2
          class="flex xs12 heading_two"
          :aria-label="$t('L_3_easy_ways')"
        >
          {{ $t('L_3_easy_ways') }}
        </h2>
        <div class="flex xs4 md2 left">
          <div class="icon">
            <img alt="investments icon" src="../../../assets/investments/top-icon1.svg">
          </div>
        </div>
        <div class="flex xs8 md10 right">
          <h3
            class="heading_three"
            :aria-label="$t('L_plan_your_meals')"
          >
            {{ $t('L_plan_your_meals') }}
          </h3>
          <div class="text">
            {{ $t('L_plan_your_meals_desc') }}
          </div>
        </div>
        <div class="flex xs4 md2 left">
          <div class="icon">
            <img alt="investments icon" src="../../../assets/investments/top-icon2.svg">
          </div>
        </div>
        <div class="flex xs8 md10 right">
          <h3
            class="heading_three"
            :aria-label="$t('L_brew_your_own_coffee')"
          >
            {{ $t('L_brew_your_own_coffee') }}
          </h3>
          <div class="text">
            {{ $t('L_brew_your_own_coffee_desc') }}
          </div>
        </div>
        <div class="flex xs4 md2 left">
          <div class="icon">
            <img alt="investment icon" src="../../../assets/investments/top-icon3.svg">
          </div>
        </div>
        <div class="flex xs8 md10 right last">
          <h3
            class="heading_three"
            :aria-label="$t('L_ditch_cable_tv')"
          >
            {{ $t('L_ditch_cable_tv') }}
          </h3>
          <div class="text">
            {{ $t('L_ditch_cable_tv_desc') }}
          </div>
          <div class="sub-text">
            {{ $t('L_20_easy_ways') }}
          </div>
        </div>
      </v-layout>
    </template>
  </PrimericaBenefits>
</template>

<script>
import { COUNTRIES } from '@/shared/constants';
import { mapGetters } from 'vuex';
import Video from '@/components/Video.vue';
import PrimericaBenefits from '@/components/PrimericaBenefits.vue';

export default {
  name: 'LearnMore',
  components: {
    Video,
    PrimericaBenefits,
  },
  data() {
    return {
      selectedVideo: 0,
    };
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    footerContent() {
      return {
        title: this.$t(`L_pfs_investments${this.isCanadian ? '_canada' : ''}`),
        subtext: this.$t('L_securities_by_pfs'),
        subtitle: this.$t('L_helping_families'),
        cards: [
          {
            // eslint-disable-next-line
            icon: require('../../../assets/investments/bottom-icon1.svg'),
            text: this.$t(this.isCanadian ? 'L_committed_to_helping' : 'L_securities_and_exchange'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/investments/bottom-icon2.svg'),
            text: this.$t('L_offers_mutual_funds'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/investments/bottom-icon3.svg'),
            text: this.$t('L_has_approx_client_accounts'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/investments/bottom-icon4.svg'),
            text: this.$t('L_publicly_traded'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/investments/bottom-icon5.svg'),
            text: this.$t('L_primerica_awarded'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/investments/bottom-icon6.svg'),
            text: this.$t('L_investment_clients'),
          },
        ],
      };
    },
    isCanadian() {
      return this.customerProfile.country === COUNTRIES.CANADA;
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables';

#learn-more
  background-color: #fff;
  .v-tabs
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    .v-tabs__bar
      order: 1;
      margin: 5px 0 0;
      div.v-tabs__div > a.v-tabs__item
        color: #4a4a4a !important;
        border: none;
        padding: 0;
        white-space: nowrap;
        letter-spacing: -.2px;
        &.v-tabs__item--active
          margin: 0px 0 0;
      .v-tabs__slider-wrapper
        display: none;
    .v-window
      order: 0;
  @media $display-breakpoints.sm-and-down
    .v-tabs
      margin: 25px 0 10px;
</style>
