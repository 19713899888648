<i18n src='../locales.json'></i18n>
<template>
  <v-content class="pt-0">
    <Alerts context="payment" />
    <v-layout row wrap>
      <v-flex xs12 font-weight-bold font-size--16 mb-4>
        {{ $t('L_please_review_your_payment_information_before') }}
      </v-flex>
    </v-layout>

    <v-layout row wrap class="lightBlue pt-2 pr-2 pb-2 pl-2 mb-4">
      <v-flex :class="{ xs6: hasMultipleOptions, xs12: !hasMultipleOptions }" font-size--14 font-weight-regular mb-2>
        {{ $t('L_payment_amount') }}:
      </v-flex>
      <v-flex v-if="hasMultipleOptions" xs6 text-xs-right font-size--12 font-weight-bold mb-2>
        <v-btn small round @click="goBackToPaymentAmount" color="#e7edf3" :disabled="submitClicked">
          <span class="changeLabel">{{ $t('L_change') }}</span>
        </v-btn>
      </v-flex>
      <v-flex xs12 font-size--16 font-weight-bold mb-2>
        {{ getPaymentAmount }}
      </v-flex>
    </v-layout>

    <v-layout row wrap class="lightBlue pt-2 pr-2 pb-2 pl-2 mt-4">
      <v-flex xs6 text-xs-left font-size--14 font-weight-regular mb-4>
        {{ $t('L_payment_method') }}:
      </v-flex>
      <v-flex xs6 text-xs-right font-size--12 font-weight-bold mb-4>
        <v-btn small round @click="goBackToPaymentMethod" color="#e7edf3" :disabled="submitClicked">
          <span class="changeLabel">{{ $t('L_change') }}</span>
        </v-btn>
      </v-flex>
      <v-flex xs12 font-size--16 font-weight-bold mb-4>
        {{ getPaymentMethod }}
      </v-flex>
      <v-flex xs12 font-size--14 font-weight-regular mb-2>
        {{ getBankName }}
      </v-flex>
    </v-layout>
    <v-card>
      <v-card-text>
        <v-flex pa-3>
          <v-layout>
            <v-flex>
              <LoadingIndicator :loading="submitClicked" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-card class="setMaxHeightCard mb-8">
      <v-card-text>
        <v-layout row wrap mt-4>
          <v-flex xs12 font-weight-bold font-size--16 mb-4>
            <span v-html="getDisclaimerTitle" />
          </v-flex>
          <v-flex xs12 font-weight-regular font-size--14 mb-8>
            <span v-html="getDisclaimerBody" />
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-layout row wrap mt-4 pt-4>
      <v-flex xs12 mb-4>
        {{ $t('L_by_clicking_I_authorize_I_certify_that_I_have_read') }}
      </v-flex>
    </v-layout>
    <v-layout row wrap class="justify-center">
      <v-flex xs0 sm0 md4>
        &nbsp;
      </v-flex>
      <v-flex xs12 sm12 md4 pr-4>
        <v-btn
          block
          color="secondary"
          @click="cancel"
          class="maxwidthButton mr-4"
          :disabled="submitClicked"
        >
          {{ $t('L_cancel') }}
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 md4 pr-4>
        <v-btn
          block
          :disabled="!checked || submitClicked"
          class="white--text mb-3 maxwidthButton"
          @click="callSubmitPayment"
          color="primary"
        >
          {{ $t('L_i_authorize') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <MessageDialog
      :show-dialog="dialog"
      type="error"
      :title="$t('L_submit_ach_error_title')"
      :message="$t('L_submit_ach_error_message')"
      :is-persistent="true"
      @buttonOneAction="dialog=false"
    />
  </v-content>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import MessageDialog from '@/components/MessageDialog.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Alerts from '@/components/Alerts.vue';

export const formattedName = (name) => {
  const fullName = name.split(' ');
  const fname = fullName.slice(fullName.length - 1);
  const sname = fullName.slice(0, fullName.length - 1).join(' ');
  return `${fname}, ${sname}`;
};

export default {
  name: 'PayStepThreeACH',
  components: {
    MessageDialog,
    LoadingIndicator,
    Alerts,
  },
  props: {
    selectedAmount: {
      type: String,
      required: true,
      default: '0',
    },
  },
  data: () => ({
    dialog: false,
    checked: true,
    submitClicked: false,
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', [
      'policies',
      'currentPolicy',
      'routeNameToNavigateBack',
      'selectedPolicyIndex',
      'paymentData',
      'achSubmissionLoading',
    ]),
    ...mapGetters({
      locale: 'getLocale',
    }),
    getPaymentAmount() {
      if (this.paymentData && this.paymentData.amount) {
        return `${this.paymentData.amount}`;
      } return '';
    },
    getPaymentMethod() {
      if (this.paymentData && this.paymentData.accountNumber) {
        return `****${this.paymentData?.accountNumber?.slice(-4)} ${this.paymentData.accountType}`;
      } return '';
    },
    getBankName() {
      if (this.paymentData && this.paymentData.bankName) {
        return this.paymentData.bankName;
      } return '';
    },
    isCanadaPolicy() {
      return !!this.currentPolicy?.isCanadaPolicy;
    },
    hasMultipleOptions() {
      return this.currentPolicy?.paynowInfo?.hasMultipleOptions;
    },
    getDisclaimerTitle() {
      return this.isCanadaPolicy ? this.$t('L_disclaimer_title_ca') : this.$t('L_disclaimer_title_common');
      // return this.$t('L_disclaimer_title_common');
    },
    getDisclaimerBody() {
      return this.isCanadaPolicy ? this.$t('L_disclaimer_body_ca') : this.$t('L_disclaimer_body_us');
    },
    routingNumber() {
      return this?.paymentData?.routingNumber || '';
    },
    accountNumber() {
      return this?.paymentData?.accountNumber || '';
    },
  },
  methods: {
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['submitAch', 'getOnePolicyDocument']),
    ...mapMutations('policy', ['setAchSubmissionLoading', 'setPaymentInfoShowPaymentAmountDateBillingMethod']),
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    goBackToPaymentAmount() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const i = this.$dynatrace.enterAction('OneTimePayment_ChangeAmount_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('goBackToPaymentAmount');
    },
    goBackToPaymentMethod() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const i = this.$dynatrace.enterAction('OneTimePayment_ChangePaymentMethod_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('goBackToPaymentMethod');
    },
    funcChecked() {
      if (this.accepted) {
        this.showSuccessBanner();
      } else {
        this.showErrorBanner('L_you_must_check_box_to_authorize');
      }
    },
    showSuccessBanner() {
      this.$emit('goToStepFour');
    },
    showErrorBanner(error) {
      this.$store.commit('addError', { error: { key: this.$t(error) }, context: 'payment' });
    },
    cancel() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const i = this.$dynatrace.enterAction('PC2_PolicyReview_Cancel');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('cancelDialog');
    },
    async callSubmitPayment() {
      // TODO remove next 2 lines and uncomment after that.
      // This is being done to test ACH step 4
      // this.$emit('goToStepFour');
      this.removeAlerts('payment');
      try {
        this.submitClicked = true;
        this.setAchSubmissionLoading(true);
        const name = formattedName(this.paymentData?.accountHolder);
        const paymentInfo = {
          draftAmount: this?.selectedAmount.replaceAll(',', ''),
          emailAddress: this.customerProfile.email,
          language: this.locale,
          bankAccount: {
            accountName: name,
            routingNumber: this.routingNumber,
            accountNumber: this.accountNumber,
            accountType: this?.paymentData?.accountCode === 1 ? '3' : ' ',
          },
        };
        const response = await this.submitAch({
          paymentInfo,
          policyNumber: this?.currentPolicy?.contractId,
        }).catch((err) => {
          // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
          try {
            const i = this.$dynatrace.enterAction(`OneTimePayment_ACH_PaymentSubmission_Error_${err}`);
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
        });
        if (response) {
          try {
            await this.$store.dispatch('policy/getPolicyOverview');
            const i = this.$dynatrace.enterAction('OneTimePayment_ACH_PaymentSubmission_Succeeded');
            this.$dynatrace.leaveAction(i);
            const j = this.$dynatrace.enterAction('pay_now_succesful_submission');
            this.$dynatrace.leaveAction(j);
          } catch (error) { /*  */ }
          this.$emit('goToStepFour');
        } else {
          try {
            const i = this.$dynatrace.enterAction('OneTimePayment_ACH_PaymentSubmission_Error');
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
          this.showErrorBanner('L_error_occurred_while_processing_ach_payment');
        }
      } finally {
        this.setAchSubmissionLoading(false);
        this.setPaymentInfoShowPaymentAmountDateBillingMethod(true);
        this.submitClicked = false;
      }
    },
  },
  mounted() { },
};
</script>

<style>
.bg-light-grey {
  background-color: #f5f5f5;
}
.lightBlue {
  background-color: #f5f7fa;
}
.changeLabel {
  color: #02579e;
}
.whiteLabel {
  color: #FFFFFF;
}
.setMaxHeightCard {
  display: flex !important;
  flex-direction: column;
  height: 200px;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
