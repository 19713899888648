<i18n src='../../../../locales.json'></i18n>
<template>
  <v-layout
    id="mobile_footer"
    row
    wrap
    class="hidden-sm-and-up footer-wrapper"
  >
    <v-flex
      xs12
      class="text-xs-center pt-1 pb-1"
      :class="{
        'pt-5': detailsExpanded,
        'grey lighten-2': !detailsExpanded,
      }"
    >
      <a
        @click="toggleDetails"
        class="text-uppercase font-weight-regular font-size--12"
        style="margin-top: -6px;color: #333 !important;"
      >
        {{ detailsExpanded? $t('L_hide_details') : $t('L_view_more_details') }}
        <v-icon style="margin-bottom: -4px;">keyboard_arrow_{{ (detailsExpanded) ? 'down' : 'up' }}</v-icon>
      </a>
    </v-flex>
    <v-layout
      v-if="detailsExpanded"
      row
      wrap
      :style="getMobileStyle"
      style="overflow-y: auto;"
    >
      <v-flex
        xs12
      >
        <v-flex>
          <Achievement
            :progress="getProgressProp > 100 ? 100 : getProgressProp"
            :size="10"
          />
        </v-flex>
      </v-flex>
      <v-flex
        v-if="includeSpouseProp"
        xs12
        pt-4
        mt-2
      >
        <v-tabs
          grow
          v-model="selectedTab"
          color="white"
          slider-color="transparent"
        >
          <v-tab
            color="black"
            @click="updateSelectedTabTop(0)"
          >
            {{ $t('L_you') }}
          </v-tab>
          <v-tab
            @click="updateSelectedTabTop(1)"
          >
            {{ $t('L_spouse') }}
          </v-tab>
        </v-tabs>
      </v-flex>
      <v-flex
        pt-2
        pl-4
        pr-4
        pb-2
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
          >
            <GuidancePill
              :header="selectedTab === 0 ? $t('L_pill_you_have') : $t('L_pill_spouse_has')"
              :amount="``"
              :footer="$t('L_pill_in_protection')"
              class-string="offset-xs3 xs6"
            >
              <span>
                <span style="font-size: 1.4em">{{ formattedCurrentCoverageProp }}*</span>
              </span>
            </GuidancePill>
          </v-flex>
          <v-flex
            xs12
            mt-3
          >
            <GuidancePill
              :header="selectedTab === 0 ? $t('L_pill_you_need') : $t('L_pill_spouse_needs')"
              :amount="formattedNeedProp"
              :footer="$t('L_pill_in_life_insurance')"
              class-string="offset-xs3 xs6"
            />
          </v-flex>
          <v-flex
            xs12
            mt-4
          >
            <h4 class="heading_four text-xs-center font-size--12 cursor-pointer">
              <a
                v-ada-modal
                id="how_did_we_calculate_m_trigger"
                href="javascript:void(0)"
                @click="dialogMobile = true"
              >{{ $t('L_how_did_we_calculate') }}</a>
            </h4>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-flex
      grey
      lighten-2
      pa-2
    >
      <h5
        v-if="!detailsExpanded"
        class="heading_five text-xs-center"
      >
        Add <span class="font-weight-medium">${{ formattedAdditionalProp }}</span> to be properly covered.
      </h5>
      <v-layout
        v-if="detailsExpanded && additionalNeedProp > 0"
        row
        wrap
      >
        <v-flex xs12>
          <GuidancePill
            :header="$t('L_we_recommend_adding')"
            :amount="formattedAdditionalProp"
            :footer="$t('L_in_life_coverage')"
            class-string="offset-xs3 xs6"
          />
        </v-flex>
      </v-layout>
      <v-layout
        justify-center
      >
        <v-btn
          v-if="displayContactRep"
          v-ada-modal
          id="contact_your_rep_guidance_trigger"
          block
          color="primary"
          @click="$emit('contact-rep', true)"
        >
          {{ $t('L_contact_your_rep') }}
        </v-btn>
      </v-layout>
    </v-flex>
    <v-dialog
      v-model="dialogMobile"
      max-width="400"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card class="how_did_we_calculate_m_modal">
        <v-card-text
          class="font-weight-regular"
          v-html="$t('L_how_did_we_calculate_text')"
        />

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="lighten-2 how_did_we_calculate_m_close"
            color="primary"
            @click="dialogMobile = false"
          >
            Got It
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Achievement from '@/components/Achievement/Achievement.vue';
import GuidancePill from '../../../GuidancePill.vue';

export default {
  name: 'SidebarMobile',
  components: {
    GuidancePill,
    Achievement,
  },
  props: {
    includeSpouseProp: {
      type: Boolean,
      required: true,
      default: false,
    },
    getProgressProp: {
      type: Number,
      required: true,
      default: null,
    },
    formattedCurrentCoverageProp: {
      type: String,
      required: true,
      default: '',
    },
    formattedNeedProp: {
      type: String,
      required: true,
      default: '',
    },
    additionalNeedProp: {
      type: [Number, String],
      required: true,
      default: null,
    },
    formattedAdditionalProp: {
      type: [Number, String],
      required: true,
      default: null,
    },
    displayContactRep: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    selectedTab: 0,
    dialogMobile: false,
    contactMeMobile: false,
    detailsExpanded: false,
    detailsHeight: 0,
  }),
  computed: {
    getMobileStyle() {
      return {
        'max-height': `${this.detailsHeight}px`,
      };
    },
  },
  methods: {
    toggleDetails() {
      this.detailsHeight = window.innerHeight - 313;
      this.detailsExpanded = !this.detailsExpanded;
    },
    updateSelectedTabTop(value) {
      this.$emit('updateSelectedTab', value);
    },
  },
  watch: {
    includeSpouseProp(newData) {
      if (newData === false) {
        this.updateSelectedTabTop(0);
        this.selectedTab = 0;
      }
    },
  },
};
</script>
