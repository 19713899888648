import bff from '@/shared/api/bff';

export const setIncludeSpouse = ({ commit }, includeSpouse) => {
  commit('setIncludeSpouse', includeSpouse);
};

export const getData = async ({ commit }) => {
  commit('setLoading', true);
  let res;
  try {
    res = await bff.getDimeData();
  } catch (e) {
    commit('setLoading', false);
    return null;
  }
  commit('setDimeData', res.data);
  commit('setLoading', false);
  return res.data;
};

export const calculateYou = async ({ commit }, dimeData) => {
  const dimeResults = await bff.dimeCalc(dimeData);
  commit('setDimeCalculation', {
    you: dimeResults,
  });
};

export const calculateSpouse = async ({ commit }, dimeData) => {
  const dimeResults = await bff.dimeCalc(dimeData);
  commit('setDimeCalculation', {
    spouse: dimeResults,
  });
};
