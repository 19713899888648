<i18n src='../locales.json'></i18n>

<template>
  <div id="notification" pt5 pl5 pr5>
    <!-- Start-->
    <v-alert id="notificationAlertBox"
             :type="type"
             :value="true"
             :color="color"
             :icon="icon"
             :class="{ notification: coloredBorder }"
    >
      <v-layout row wrap>
        <v-flex xs11 sm11 md11 lg11>
          <div class="text-color">
            {{ $t(details.title) }}
          </div>
          <v-layout row wrap class="text-color">
            <v-flex>
              {{ $t(details.body) }}
              <span id="verifyNowBtnSpan">
                <v-btn
                  id="get_guidance_button"
                  role="link"
                  @click="optionOne"
                  class="btn-policy-details"
                  color="secondary"
                >
                  {{ $t(details.buttonLabel) }}
                </v-btn>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="dismissible" xs1 sm1 md1 lg1 text-xs-right>
          <v-icon @click="optionTwo" color="#333333">
            cancel
          </v-icon>
        </v-flex>
      </v-layout>
    </v-alert>
    <!-- End -->
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'Notification',
  props: {
    index: {
      type: Number,
      default: -1,
    },
    type: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      required: false,
      default: () => {},
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'error',
    },
    coloredBorder: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    notification: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    selected: -1,
  }),
  computed: {
    message() {
      if (this.details.key) {
        return this.$t(this.details.key);
      }
      return this.details.title;
    },
  },
  methods: {
    ...mapActions(['snoozeVerifyPhone']),
    ...mapActions(['addNotification', 'updateNotification', 'removeNotification', 'removeNotifications']),
    optionOne() {
      if (this.notificationObj != null && this.notificationObj?.options != null) {
        const options = this.notificationObj?.options;
        options.forEach((option) => {
          if (option?.actionType === 'openVueModal') {
            option.actionData = true;
          }
        });
      }
      this.notificationObj.hideOriginalModalScreen = false;
      const tempObj = Object.assign({}, this.notificationObj);
      this.updateNotification(tempObj);
    },
    optionTwo() {
      if (this.notificationObj != null && this.notificationObj?.options != null) {
        const options = this.notificationObj?.options;
        options.forEach((option) => {
          if (option?.actionType === 'skip') {
            option.actionData = true;
          }
        });
      }
      this.notificationObj.hideOriginalModalScreen = false;
      this.notificationObj.hideBoth = true;
      this.snoozeVerifyPhone();
      this.updateNotification(this.notificationObj);
    },
  },
  mounted() {
    if (this.notification != null) {
      this.notificationObj = JSON.parse(JSON.stringify(this.notification));
    }
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'

#notification
  border-top: 6px solid #2196f3 !important;
  .v-alert__icon.v-icon
    color: #2196f3 !important;
  .v-alert.error
    position: inherit;
  .v-alert.info
    background-color: #e2f0fe !important;
    margin:0px !important;
  .v-expansion-panel
    box-shadow: none;
  .theme--light.v-icon
    color: white;
  .header
    padding-left: 10px;
  .details
    padding-left: 34px;
    overflow-wrap: anywhere;
  .text-color
    color: #333333;
#notificationAlertBox
  zborder-top: 5px solid red;
#verifyNowBtnSpan
  display: block;
  text-align:center;
  @media only screen and (min-width: 1000px)
    zpadding-left: 238px;
    display: inline;
    text-align:left;
</style>
