<i18n src='./locales.json'></i18n>
<template>
  <div>
    <v-text-field
      :id="idProp"
      class="password-input"
      v-model="formInput.$model"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      :color="color"
      :error-messages="errorMessages"
      :label="label || $t && $t('L_password') || 'Create a Password'"
      max-length="118"
      required
      @focus="onFocus"
      @input="mask"
      @blur="onBlur"
      @paste.prevent=""
      @copy.prevent=""
      @cut.prevent=""
      :aria-describedby="describers"
      :type="masked ? 'password': 'text'"
    >
      <span
        slot="append"
      >
        <v-icon
          tabindex="0"
          :aria-label="$t('L_toggle_show_password')"
          class="toggle-masking"
          v-if="enableShowHide"
          @keydown="showPass($event)"
          @keyup="masked = true"
          @mouseenter="masked = false"
          @mouseleave="masked = true"
          @mousedown="masked = false"
          @mouseup="masked = true"
        >
          {{ masked ? 'visibility' : 'visibility_off' }}
        </v-icon>
        <FieldIcon
          :form-field="formInput"
          :icon="validIcon"
        />
      </span>
    </v-text-field>
  </div>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import PasswordInput from '../Password/PasswordInput.vue';

export default {
  name: 'ConfirmPasswordInput',
  components: { FieldIcon },
  extends: PasswordInput,
  props: {
    idProp: {
      type: String,
      default: 'confirm_password',
    },
  },
  data: () => ({
    modelName: 'confirmPassword',
    confirmPassword: '',
  }),
};
</script>
