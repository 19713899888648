<i18n src='../locales.json'></i18n>

<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      xs12
      md8
    >
      <div
        class="heading_four font-weight-medium"
      >
        {{ $t('L_how_many_years') }}
      </div>
    </v-flex>
    <v-flex
      xs12
      sm8
      md7
      mt-3
    >
      <v-slider
        always-dirty
        :max="99"
        v-model="sliderAmount"
        @input="onSliderInput"
        :aria-labelledby="'How many years'"
      />
    </v-flex>
    <v-flex
      xs12
      offset-sm1
      sm3
      offset-md2
    >
      <YearsDisplay
        v-model="amount"
        @input="onTextInput"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Slider from '@/components/Inputs/Slider/Slider.vue';
import YearsDisplay from './YearsDisplay.vue';
import LinkedSlider from '../../LinkedSlider.vue';

export default {
  name: 'HowManyYears',
  extends: LinkedSlider,
  components: {
    YearsDisplay,
    Slider,
  },
};
</script>
