<i18n src='../../locales.json'></i18n>

<template>
  <!-- Payment Information -->
  <v-flex
    mt-2
    xs12
  >
    <v-card>
      <v-card-title mt-4 mb-4 class="marginAuto topBottomSpace">
        <v-flex>
          <span
            role="heading"
            aria-level="2"
            class="card-title"
          >{{ $t('L_policy_payment_information') }}</span>
        </v-flex>
      </v-card-title>

      <Alert v-if="showPayInProgressBanner"
             type="info"
             :details="{ message: $t('L_important_there_is_a_payment_in_process') }"
             :dismissible="false"
             :auto-dismiss="false"
             :expandable="false"
             :colored-border="true"
      />
      <MessageDialog
        :show-dialog="showMessageDialog"
        :has-button-two="false"
        type="warning"
        :title="$t('L_payment_in_process')"
        :message="$t('L_a_payment_is_currently_being_processed')"
        :button-one-label="$t('L_close')"
        :is-persistent="true"
        @buttonOneAction="showMessageDialog = false"
      />
      <v-flex xs12 align-self-center>
        <Message
          v-if="showPayNowMsg"
          :type="paynowType"
          :button-label="$t('L_pay_now')"
          :message="payNowLinkDesc"
          @click="payNow"
        />
      </v-flex>

      <v-divider v-if="!showPayInProgressBanner && !showPayNowInfoCard" />
      <v-card-text v-if="!editPaymentMethod && !updatingBank && getPaymentInfoShowPaymentAmountDateBillingMethod">
        <v-layout
          justify-center
          class="text-xs-center"
          row
          wrap
        >
          <v-flex
            xs12
            sm6
            md3
            mt-4
          >
            <PolicyDetailsInfo>
              <template
                slot="title"
              >
                <div>{{ $t('L_policy_total_premium') }}</div>
              </template>
              <template slot="content">
                <div v-if="hasAnnuity">
                  <span>{{ formattedTotalPremium }}</span>
                  <h4
                    class="heading_four mt-2"
                  >
                    <span
                      class="font-weight-medium font-size--12"
                    >{{ $t('L_life_insurance_premium') }}</span> - <span class="font-size--12">{{ formattedInsurancePremium }}</span>
                  </h4>
                  <h4
                    class="heading_four mt-1"
                  >
                    <span
                      class="font-weight-medium font-size--12"
                    >{{ getPolicyAnnuityLabel }}</span> -  <span class="font-size--12">{{ formattedAnnuityPremium }}</span>
                  </h4>
                </div>
                <div v-else>
                  <span
                    v-if="!hasAnnuity"
                  >{{ formattedInsurancePremium }}</span>
                </div>
              </template>
            </PolicyDetailsInfo>
          </v-flex>

          <v-flex
            v-if="policy.nextDraftDate && policy.billingForm !== 'H1'"
            xs12
            sm3
            md3
            mt-4
          >
            <PolicyDetailsInfo>
              <template slot="title">
                <div> {{ $t(formattedNextDate) }}</div>
              </template>
              <template slot="content">
                <div>
                  {{ formattedDraftDate }}
                  <a
                    v-if="displayEditFeatureFlag"
                    id="edit_billing_method"
                    class="text-decoration-underline ml-2"
                    href="javascript:void(0)"
                    :aria-label="$t('L_edit')+' withdrawal date'"
                    @click="callWithDrawalDateEdit"
                  >{{ $t('L_edit') }}
                  </a>
                </div>
              </template>
            </PolicyDetailsInfo>
          </v-flex>
          <template v-else>
            <v-flex
              v-if="policy.nextPaymentDueDate && policy.billingForm !== 'H1'"
              xs12
              sm3
              md3
              mt-4
            >
              <PolicyDetailsInfo>
                <template slot="title">
                  <div> {{ $t(formattedNextDate) }}</div>
                </template>
                <template slot="content">
                  <div>{{ formattedPaymentDueDate }}</div>
                </template>
              </PolicyDetailsInfo>
            </v-flex>
          </template>
          <v-flex
            v-if="policy.billingForm === 'H1'"
            xs12
            sm3
            md3
            mt-4
          >
            <PolicyDetailsInfo v-if="policy.paymentInfo.paidToDate">
              <template slot="title">
                <div>{{ $t(dateLabel) }}</div>
              </template>
              <template slot="content">
                <div>{{ formattedPaidToDate }}</div>
              </template>
            </PolicyDetailsInfo>
          </v-flex>

          <v-flex
            xs12
            sm3
            md3
            mt-4
          >
            <PolicyDetailsInfo v-if="policy.billingMode">
              <template slot="title">
                <div> {{ $t(paymentLabel) }}</div>
              </template>
              <template slot="content">
                <div>{{ $t(paymentMethod) }}</div>
              </template>
            </PolicyDetailsInfo>
          </v-flex>
        </v-layout>
      </v-card-text>

      <!-- View Payment Details -->
      <ViewPaymentDetails
        v-if="allowExpandDetails || showPayNowInfoCard"
        :bank="policy.paymentInfo.bank || {}"
        :pay-now="showPayNowInfoCard"
        :policy-data="policy"
        @toggle="togglePanel"
        @close="updateshowPayNowInfoCard(false)"
      />
    </v-card>
  </v-flex>
</template>
<script>
import { formatDate, currency } from '@/shared/util/formatters';
import moment from 'moment';
import Alert from '@/components/Alert.vue';
import Message from '@/components/Message.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import stringValues from '@/shared/util/stringValues';
import PolicyDetailsInfo from '../../PolicyDetailsInfo.vue';
// import PolicyBenefits from '../PolicyBenefits/PolicyBenefits.vue';
import PolicyOwner from '../PolicyOwner/PolicyOwner.vue';
import ViewPaymentDetails from './components/ViewPaymentDetails.vue';
import MessageDialog from '../../../../../components/MessageDialog.vue';

export default {
  name: 'PaymentInformationCard',
  components: {
    PolicyOwner,
    // PolicyBenefits,
    PolicyDetailsInfo,
    ViewPaymentDetails,
    Alert,
    Message,
    MessageDialog,
  },
  data: () => ({
    paynowType: 'info',
    showMessageDialog: false,
  }),
  computed: {
    ...mapGetters({
      currentLocale: 'getLocale',
    }),
    ...mapGetters(['containsFeature']),
    ...mapGetters({
      dateFormat: 'getDateFormat',
      policy: 'policy/currentPolicy',
      showPayNowInfoCard: 'policy/getshowPayNowInfoCard',
      showPayNowButton: 'policy/showPayNow',
      editPaymentMethod: 'policy/getEditPaymentMethod',
      updatingBank: 'policy/getUpdatingBank',
      getPaymentInfoShowPaymentAmountDateBillingMethod: 'policy/getPaymentInfoShowPaymentAmountDateBillingMethod',
      policies: 'policy/policies',
      withDrawalDateStep: 'withDrawalDateStep',
    }),
    getPolicyAnnuityLabel() {
      if (this.policy != null) {
        if (this.currentLocale === 'fr_CA') {
          return this.policy?.annuity?.planDescriptionFrench + this.$t('L_payment');
        } return this.policy?.annuity?.planDescription + this.$t('L_payment');
      } return this.$t('L_payment');
    },
    hasAnnuity() {
      return this?.policy?.paynowInfo?.hasAnnuity;
    },
    showPayNowMsg() {
      return (this.showPayNowButton && !this.showPayInProgressBanner);
    },
    formattedDraftDate() {
      // return formatDate(this.policy?.nextDraftDate, this.dateFormat);
      const paidToDate = moment(this.policy?.paymentInfo?.paidToDate).format('YYYY-MM-DD');
      return formatDate(paidToDate, this.dateFormat);
    },
    formattedPaymentDueDate() {
      return formatDate(this.policy?.nextPaymentDueDate, this.dateFormat);
    },
    formattedTotalPremium() {
      return currency(`${(+this.policy?.modePremium || 0) + (+this.policy?.data?.annuityModePrem || 0)}`, 2);
    },
    formattedAnnuityPremium() {
      return this?.policy?.data?.annuityModePrem ? currency(this.policy.data?.annuityModePrem, 2) : '';
    },
    formattedInsurancePremium() {
      if (this.policy?.paynowInfo?.hasAnnuity) {
        return currency(this.policy?.data?.modePremium, 2);
      }
      if (this.policy?.paynowInfo?.totalPaymentAmount) {
        return currency(+this.policy?.paynowInfo?.totalPaymentAmount, 2);
      }
      return '';
    },
    formattedPaidToDate() {
      return formatDate(this.policy?.paymentInfo?.paidToDate, this.dateFormat);
    },
    formattedNextDate() {
      return (this.policy?.billingForm) ? stringValues.nextDate(this.policy) : '';
    },
    dateLabel() {
      if (this.policy?.billingForm === 'H1') {
        return 'L_policy_paid_to_date_H1';
      }
      if (this.policy?.billingMode === '01' && this.policy?.billingForm === 'B2') {
        return 'L_policy_next_auto_draft';
      }
      return 'L_policy_next_premium';
    },
    paymentLabel() {
      switch (this.policy?.billingForm) {
        case 'B2':
        case 'C2':
        case 'D2':
          return 'L_policy_payment_method';
        case 'A1':
        case 'H1':
        default:
          return 'L_policy_billing_method';
      }
    },
    paymentMethod() {
      return stringValues.paymentMethod(this.policy);
    },
    allowExpandDetails() {
      const { billingForm } = this.policy;
      return billingForm !== 'D2' && billingForm !== 'C2' && billingForm !== 'H1';
    },
    payInProgress() {
      return this.policy?._paymentInfo?._payInProcess === '1';
    },
    kubraPaymentInProgress() {
      // return this.policy?.kubraPaymentStatus !== null;
      return !!this.policy?.kubraPaymentStatus;
    },
    showPayInProgressBanner() {
      return this.payInProgress || this.kubraPaymentInProgress;
    },
    isCanadianPolicy() {
      return !!this.policy?.isCanadaPolicy;
    },
    payNowLinkDesc() {
      let payNowLinkDesc = this.policy?.payNowLinkDesc || '';
      const paidToDate = this.policy?.paymentInfo?.paidToDate;
      const billForm = this.policy?.billingForm;
      const DIRECT_BILL = billForm && billForm.toLowerCase() === 'A1'.toLowerCase();
      const isPaidToDateBeforeToday = moment(paidToDate).isBefore(moment(), 'day');
      const isPaidToDateAfterToday = moment(paidToDate).isAfter(moment(), 'day');
      const isPaidToDateToday = moment(paidToDate).isSame(moment(), 'day');
      const paidToDateFormatted = this.formattedPaidToDate;
      const paymentAmt = this.policy?.paynowInfo?.totalPaymentAmount;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.paynowType = 'info';

      if (this.policy.paynowInfo?.inSufficientFunds) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.paynowType = 'warning';
        payNowLinkDesc = this.$t('L_your_automatic_payment_of', { amount: paymentAmt });
      } else if (DIRECT_BILL) {
        if (paidToDate && isPaidToDateBeforeToday) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.paynowType = 'warning';
          payNowLinkDesc = this.$t('L_a_payment_was_due_on', { amount: paymentAmt, date: paidToDateFormatted });
        } else if (paidToDate && isPaidToDateAfterToday) {
          payNowLinkDesc = this.$t('L_a_payment_is_due_on', { amount: paymentAmt, date: paidToDateFormatted });
        } else if (paidToDate && isPaidToDateToday) {
          payNowLinkDesc = this.$t('L_a_payment_is_due_today', { amount: paymentAmt });
        }
        if (this.policy.paynowInfo?.hasMultipleOptions) {
          payNowLinkDesc += ` ${this.$t('L_a_payment_option_minimum', { amount: this.policy.paynowInfo.minimumPaymentAmount })}`;
        }
      } else {
        payNowLinkDesc = '';
      }
      return payNowLinkDesc;
    },
    displayEditFeatureFlag() {
      return this.containsFeature('DRAFT-DATE');
    },
  },
  methods: {
    ...mapMutations({
      updateshowPayNowInfoCard: 'policy/setshowPayNowInfoCard',
      setPaymentInfoShowPaymentAmountDateBillingMethod: 'policy/setPaymentInfoShowPaymentAmountDateBillingMethod',
    }),
    ...mapActions('policy', ['setPaymentStep', 'setWithDrawalDateStep']),
    callWithDrawalDateEdit() {
      try {
        const i = this.$dynatrace.enterAction('ChangeWithdrawalDay_start');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.setWithDrawalDateStep(1);
      try {
        const i = this.$dynatrace.enterAction('SelectWithdrawalDay_PageDisplayed');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    },
    async payNow() {
      await this.$store.dispatch('policy/getPaymentInfo', this.policy.contractId);
      if (this.showPayNowMsg) {
        // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
        try {
          const i = this.$dynatrace.enterAction(`PolicyDetails_payNow_policy_${this.policies[0]?.contractId || this.selectedPolicyIndex}-${this.policies[0]?.insuredName}_Selected`);
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
        try {
          const i = this.$dynatrace.enterAction('OneTimePayment_start');
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
        this.setPaymentStep(1);
        this.$router.push({ name: 'PolicyDetails', params: { contractId: this.selectedPolicyIndex } });
      } else {
        this.showMessageDialog = true;
      }
    },
    togglePanel(currentPanelValue) {
      if (currentPanelValue === 0) {
        this.$store.commit('policy/setPanelValue', -1);
      } else {
        this.updateshowPayNowInfoCard(false);
        this.$store.commit('policy/setPanelValue', 0);
      }
    },
  },
  mounted() {
    this.setPaymentInfoShowPaymentAmountDateBillingMethod(true);
    if (this.policy?.showPayNowLink) {
      this.updateshowPayNowInfoCard(true);
      if (this.$vuetify.breakpoint.lgAndUp) {
        window.scrollTo(0, 650);
      } else if (this.$vuetify.breakpoint.md ||
      this.$vuetify.breakpoint.sm) {
        window.scrollTo(0, 1050);
      } else {
        window.scrollTo(0, 1450);
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
.maxwidthButton {
  width: 300px;
  max-width: 300px !important;
}
.floatright {
  float: right;
}
.infoBox {
  background-color: #e2f0fe;
  border-top: 4px solid #3399ff;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}
.warningBox {
  background-color: #fdf3e2;
  border-top: 4px solid #f39c12;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}
.marginAuto {
  margin: auto;
}
.topBottomSpace {
  padding-top: 16px;
  padding-bottom: 16px;
}
.iconBig {
  font-size: 28px;
}
</style>
