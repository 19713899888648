<i18n src='./locales.json'></i18n>

<template>
  <v-text-field
    max-length="60"
    :id="idProp"
    v-model="formInput.$model"
    :disabled="disabled"
    :label="label || $t && $t('L_contact_message_subject') || 'Subject'"
    :autocomplete="disableAutocomplete ? 'off' : null"
    :color="color"
    :append-icon="icon"
    :error-messages="errorMessages"
    @input="onInput"
    @blur="onBlur"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'SubjectInput',
  components: { FieldIcon },
  extends: BaseTextField,
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'subject',
    },
  },
  data: () => ({
    modelName: 'subject',
    subject: '',
  }),
};
</script>
