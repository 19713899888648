import { required, minLength } from 'vuelidate/lib/validators';

export const policyNumber = value => !!value.match(/^(04|34|05|10|15)\d*/);
export const policyNumberCanada = value => !!value.match(/^(05|10|15)\d*/);
export const policyNumberUS = value => !!value.match(/^(04|34)\d*/);

export default {
  required,
  minLength: minLength(10),
};
