<i18n src="./locales.json"></i18n>
<template>
  <div>
    <MyBeneficiary v-if="beneficiaryStep===1" @set-type="setBeneType" @set-beneficiary="setBeneficiary" :coming-from-edit-beneficiary="getIsComingFromEditBeneficiary" />
    <EditBeneficiaryDetails v-if="beneficiaryStep === 2 || beneficiaryStep === 3" :bene-type="getBeneType" :bene-obj="getBeneficiary" @cancel="isComingFromEditBeneficiary = true;" />
    <ConfirmBeneficiary v-if="beneficiaryStep === 4" />
  </div>
</template>

<script>
// import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';
import MyBeneficiary from './MyBeneficiary.vue';
import EditBeneficiaryDetails from './EditBeneficiaryDetails.vue';
import ConfirmBeneficiary from './ConfirmBeneficiary.vue';

export default {
  name: 'UpdateBeneficiaryLayout',
  components: {
    MyBeneficiary,
    EditBeneficiaryDetails,
    ConfirmBeneficiary,
    /* EmailInput,
    Alert,
    PhoneNumberInput,
    AddressInput,
    PostalCodeInput,
    CityInput,
    StateInput,
    TerritoryInput,
    ZipCodeInput,
    EditContactInfo, */
  },
  data: () => ({
    beneType: '',
    beneficiary: {},
    editing: false,
    isComingFromEditBeneficiary: false,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', {
      beneficiaryStep: 'beneficiaryStep',
    }),
    getBeneType() {
      return this.beneType;
    },
    getBeneficiary() {
      return this.beneficiary;
    },
    getIsComingFromEditBeneficiary() {
      return this.isComingFromEditBeneficiary;
    },
  },
  methods: {
    ...mapMutations('policy', {
      setAddressUpdateSuccess: 'setAddressUpdateSuccess',
    }),
    setBeneType(val) {
      this.beneType = val;
    },
    setBeneficiary(val) {
      this.beneficiary = val;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~vuetify/src/stylus/main'
.move-right
  float: right;
.bkgrnd-color-white
  background-color: #ffffff;
.bkgrnd-color-blue-white
  background-color: #f2f2f2;
.blue-bkgrnd
  background-color: #c9e5f9;
.buttonFormat
  width: 300px;
  height: 44px;
  font-size: 18px;
  font-weight: 600;
.sm-pl-5
  @media $display-breakpoints.sm-and-up
    padding-left: 40px;

@media $display-breakpoints.md-and-up
  .pad-left-md
    padding-left: 12px
  .pad-right-md
    padding-right: 12px

.blue-border {
  border: solid 2px #2196f3;
  cursor pointer;
}

.select-address {
  color: #2196f3 !important;
}

/deep/ label.v-label--active {
  font-weight: 500;
  color: #333;
}

/deep/ div.v-input {
  padding-bottom: 12px;
}

h3.heading_three.font-weight-medium {
  font-weight: 500 !important;
}
.font-size-md {
  font-size: 16px !important;
}
.inlineText {
  display: inline-text;
}
button.short {
  height: 40px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border: 1px #063562 solid;
  width: auto !important;
}
</style>
