<i18n src='../locales.json'></i18n>

<template>
  <v-layout id="retirement-calculator" row wrap>
    <LoadingIndicator :loading="isLoading" />
    <v-flex xs12 sm12 md12 lg8>
      <v-card>
        <v-flex xs12 sm12 md12 titlebox font-weight-bold class="headline retirement-title">
          <v-card-title>{{ $t('L_calculator_retirement') }}</v-card-title>
        </v-flex>
        <v-layout row wrap class="rule-of-thumb-card" align-center justify-center>
          <v-flex xs1 sm1 md1>
            <v-img src="@/assets/investments/calculator/rule-of-thumb-icon.svg" />
          </v-flex>
          <v-flex xs10 sm10 md10>
            <v-card-text>
              <div class="font-weight-medium">
                {{ $t('L_calculator_rule_of_thumb') }}
              </div>
              <div>{{ $t('L_calculator_rule_of_thumb_desc') }}</div>
            </v-card-text>
          </v-flex>
        </v-layout>
        <v-card-text title>
          {{ $t('L_calculator_intro') }}
        </v-card-text>
        <v-card-text>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm8 md8 lg8>
              <div class="subtitle-1 font-weight-medium">
                {{ $t('L_calculator_dob') }}
              </div>
            </v-flex>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs12 sm3 md3 lg3>
              <v-text-field v-model="dateOfBirth"
                            justify-right
                            class="ma-0 pa-0 centered-input text--darken-3 font-size--20"
                            single-line
                            @change="validateDob(); calculate();"
                            placeholder="MM/DD/YYYY"
                            type="text"
                            mask="##/##/####"
                            hide-details
                            :aria-label="'Date of birth'"
              />
              <span v-if="!isDobValid" class="error--text caption ma-0 pa-0">
                {{ dobError }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm8 md8 lg8>
              <div class="subtitle-1 font-weight-medium">
                {{ $t('L_calculator_current_age') }}
              </div>
            </v-flex>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs12 sm3 md3 lg3 text-xs-center class="ma-0 pa-0 centered-input text--darken-3 font-size--24 more-padding">
              {{ currentAge }}
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm8 md8 lg8>
              <div class="subtitle-1 font-weight-medium">
                {{ $t('L_calculator_age') }}
              </div>
            </v-flex>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs12 sm3 md3 lg3>
              <v-text-field v-model="scenario.retirement.age"
                            justify-right
                            class="ma-0 pa-0 centered-input text--darken-3 font-size--24 more-padding"
                            single-line
                            @change="validateAge(); calculate();"
                            type="number"
                            hide-details
                            :aria-label="'Calculated retirement age'"
              />
              <span v-if="!isAgeValid" class="error--text caption ma-0 pa-0">
                {{ ageError }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm8 md8 lg8 subtitle-1 font-weight-medium>
              {{ $t('L_calculator_annual_income') }}
            </v-flex>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs12 sm3 md3 lg3>
              <CurrencyDisplay v-model="scenario.currentIncome"
                               @input="$emit('input', $event)"
                               @change="calculate('goalpct')"
                               label-id="currentIncome" class="more-padding"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm12 md12 lg12>
              <div class="font-weight-medium subtitle-1">
                {{ $t('L_calculator_monthly_income') }}
              </div>
              <div>{{ $t('L_calculator_expect_social_security') }}</div>
              <div class="estimate-social-security">
                <span v-if="isCanada" class="link" @click="openDocument('SSN')">
                  {{ $t('L_calculator_estimate_social_security_ca') }}
                </span>
                <span v-else class="link" @click="openDocument('SSN')">
                  {{ $t('L_calculator_estimate_social_security_us') }}
                </span>
                <v-icon @click="openDocument('SSN')" color="blue darken-2">
                  launch
                </v-icon>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout row wrap align-center class="ma-0 pa-0">
            <v-spacer />
            <v-flex xs12 sm3 md3 lg3 text-xs-center>
              <div>
                <v-text-field v-model="scenario.incomeGoalPct"
                              type="number"
                              class="text-xs-center font-size--24 pb-0 more-padding"
                              @change="validatePct(); calculate('goalpct')"
                              suffix="%"
                              hide-details
                              :aria-label="'Percent of your income'"
                />
              </div>
              <div class="unit">
                {{ $t('L_calculator_of_your_income_unit') }}
              </div>
            </v-flex>
            <v-flex xs12 sm2 md2 lg2 text-xs-center>
              <div>&nbsp;</div>
              <div>{{ $t('L_calculator_or') }}</div>
            </v-flex>
            <v-flex xs12 sm3 md3 lg3 font-size--24 text-xs-center>
              <CurrencyDisplay v-model="scenario.incomeGoal"
                               @input="$emit('input', $event)"
                               @change="validateAmt(); calculate('goal');"
                               class="pa-0 more-padding"
                               label-id="incomeGoal"
                               :hide-details="hideDetails"
              />
              <div class="unit">
                {{ $t('L_calculator_per_month_unit') }}
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="!isPctValid" row wrap align-top text-xs-left>
            <v-spacer />
            <v-flex xs12 sm8 md8 lg8>
              <span class="error--text caption">{{ $t('L_calculator_income_pct_error') }}</span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text class="account-list">
          <v-layout row wrap justify-center>
            <v-flex xs12 sm9 md9 lg9 subtitle-1 font-weight-medium>
              {{ $t('L_calculator_saved_retiremment') }}
            </v-flex>
            <v-spacer />
          </v-layout>
        </v-card-text>
        <v-card-text class="account-list">
          <v-layout row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs2 sm1 md1 lg1>
              <v-img width="28px" height="25px" src="@/assets/investments/calculator/savings-icon.svg" />
            </v-flex>
            <v-flex xs5 sm5 md6 lg6 subtitle-1 font-weight-medium>
              {{ $t('L_calculator_saved_retiremment_primerica') }}
            </v-flex>
            <v-flex xs5 sm3 md3 lg3 headline text-xs-right>
              {{ $n(scenario.retirement.savings,'currencyNoCents','en-US') }}
            </v-flex>
            <v-flex xs0 sm2 md1 lg1 />
          </v-layout>
          <v-layout row wrap justify-center>
            <v-flex xs0 sm2 md2 lg1 />
            <v-flex xs12 sm8 md8 lg7 body-1 v-if="scenario.retirement.accounts.length > 0">
              {{ $t('L_calculator_saved_retiremment_primerica_intro') }}
              <span class="link" @click="viewMyInvestments()">{{ $t('L_calculator_view_my_investments') }}</span>
            </v-flex>
            <v-flex xs0 sm2 md2 lg3 />
          </v-layout>
        </v-card-text>
        <v-card-text class="account-list">
          <v-layout row wrap v-for="account in scenario.retirement.accounts" :key="account.number">
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs8 sm5 md5 lg5 subtitle-1>
              <v-checkbox color="primary"
                          class="ma-0 pa-0"
                          v-model="selectedAccounts"
                          @change="calculate"
                          :value="account.number"
                          :aria-labelledby="'selected account'"
                          title="'selected account'"
              >
                <template v-slot:label>
                  <div class="blackText">
                    {{ account.productType }}
                    {{ account.number }}<br>
                    {{ account.type }}
                  </div>
                </template>
              </v-checkbox>
            </v-flex>
            <v-flex xs1 sm3 md3 lg3 />
            <v-flex xs3 sm2 md2 lg2 subtitle-1 text-xs-right>
              {{ $n(account.balance, 'currencyNoCents', 'en-US') }}
            </v-flex>
            <v-flex xs0 sm1 md1 lg1 />
          </v-layout>
          <v-layout row wrap justify-left>
            <v-flex xs0 sm2 md2 lg1 />
            <v-flex xs12 sm10 md10 lg11 body-1 z-if="scenario.retirement.accounts.length > 0">
              <span v-if="isCanada">{{ $t('L_important_disclosures_ca') }}
                <span class="link" @click="openDocument('Disclosure')">
                  {{ $t('L_here') }}
                </span>
              </span>
              <div v-else>
                <p>
                  <span>{{ $t('L_important_disclosures_us') }}
                    <span class="link" @click="openDocument('Disclosure')">
                      {{ $t('L_important_disclosures') }}
                    </span>
                  </span>
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text class="account-list">
          <v-layout row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs2 sm1 md1 lg1>
              <v-img width="28px" height="25px" src="@/assets/investments/calculator/bank-icon.svg" />
            </v-flex>
            <v-flex xs10 sm6 md6 lg6 subtitle-1 font-weight-medium>
              {{ $t('L_calculator_saved_retiremment_other') }}
            </v-flex>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs12 sm3 md3 lg3 text-xs-right>
              <CurrencyDisplay v-model="scenario.otherSavings"
                               @input="$emit('input', $event)"
                               @change="calculate"
                               label-id="otherSavings" class="more-padding"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text class="account-list">
          <v-layout row wrap justify-center>
            <v-flex xs12 sm8 md8 lg8 subtitle-1 font-weight-medium>
              {{ $t('L_calculator_saving_retirement') }}
            </v-flex>
            <v-spacer />
            <v-flex xs12 sm3 md3 lg3>
              <CurrencyDisplay v-model="scenario.monthlySavings"
                               @input="$emit('input', $event)"
                               @change="calculate"
                               label-id="monthlySavings" class="more-padding"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm12 md12 subtitle-1 font-weight-medium>
              {{ $t('L_calculator_not_saving_enough') }}
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm12 md12 subtitle-1>
              {{ $t('L_calculator_help_options') }}
            </v-flex>
          </v-layout>
          <v-layout>
            <v-spacer />
            <v-flex ms12 sm12 md12 lg12>
              <ul>
                <li>{{ $t('L_calculator_help_options_monthly_saving') }}</li>
                <li>{{ $t('L_calculator_help_options_age') }}</li>
                <li>{{ $t('L_calculator_help_options_income_goal') }}</li>
              </ul>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- Expantion -->
    <v-flex xs12 sm12 md12 lg4 full-length grey lighten-3 v-if="hidePredictionDetails">
      <v-card color="grey lighten-3" flat class="text-xs-center">
        <v-card-text class="px-0 subtitle-1 font-weight-medium view-mode-details">
          <div v-if="!expandedPrediction" @click="expandedPrediction = true">
            {{ $t('L_calculator_view_details') }}
            <v-icon size="large" color="#063562">
              expand_more
            </v-icon>
          </div>
          <div v-else @click="expandedPrediction = false">
            {{ $t('L_calculator_hide_details') }}
            <v-icon size="large" color="#063562">
              expand_less
            </v-icon>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- Right side of page -->
    <v-flex xs12 sm12 md12 lg4 full-length grey lighten-3>
      <v-card v-if="expandedPrediction" color="grey lighten-3" flat class="text-xs-center">
        <v-layout row wrap justify-center>
          <v-flex wrap xs12 sm3 md3 lg12>
            <v-card-text class="title font-weight-medium pt-3 pl-0 pr-0">
              {{ $t('L_calculator_retirement_goal') }}
            </v-card-text>
            <div class="chart">
              <Achievement
                :progress="projection.percentRetirementIncomeGoal > 100 ? 100 : projection.percentRetirementIncomeGoal"
                :size="12"
              />
            </div>
          </v-flex>
          <v-flex xs12 sm9 md9 lg12>
            <v-layout row wrap justify-center>
              <v-flex xs12 sm6 md6 lg12>
                <v-card-text subtitle-1 class="ma-0">
                  {{ $t('L_calculator_projected_retirement_income') }}
                </v-card-text>
              </v-flex>
              <v-flex xs12 sm6 md16 lg12>
                <v-card-text class="ma-0">
                  <v-layout row wrap justify-center>
                    <v-flex xs1 sm1 md1 lg1 />
                    <v-flex xs10 sm10 md10 lg10 font-size--24 font-weight-medium pill-text-field>
                      {{ $n(projection.projectedRetirementIncome, 'currencyNoCents', 'en-US') }}
                      <span class="body-1">{{ $t('L_calculator_per_month') }}</span>
                    </v-flex>
                    <v-flex xs1 sm1 md1 lg1 />
                  </v-layout>
                  <span class="body-1 m-0 p-0">{{ $t('L_calculator_based_on_savings') }}</span>
                </v-card-text>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center>
              <v-flex xs12 sm6 md6 lg12>
                <v-card-text headline class="ma-0">
                  <span v-if="isProjectionIncomeGoalPctUnder100">
                    {{ $t('L_calculator_monthly_income_goal', { pct: projection.percentRetirementIncomeGoal.toFixed(0) }) }}
                  </span>
                  <span v-else>
                    {{ $t('L_calculator_monthly_income_goal_over100' ) }}
                  </span>
                </v-card-text>
              </v-flex>
              <v-flex xs12 sm6 md6 lg12>
                <v-card-text class="ma-0">
                  <v-layout>
                    <v-flex xs1 sm1 md1 lg1 />
                    <v-flex xs10 sm10 md10 lg10 font-size--24 font-weight-medium pill-text-field>
                      {{ $n(scenario.incomeGoal, 'currencyNoCents', 'en-US') }}
                      <span class="body-1">{{ $t('L_calculator_per_month') }}</span>
                    </v-flex>
                    <v-flex xs1 sm1 md1 lg1 />
                  </v-layout>
                </v-card-text>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center>
              <v-flex xs12 sm6 md6 lg12>
                <v-card-text class="font-weight-medium ma-0">
                  {{ $t('L_calculator_financial_independence_number') }}
                  <v-tooltip top max-width="200">
                    <template v-slot:activator="{ on }">
                      <v-icon color="blue darken-2" dark small v-on="on">
                        help
                      </v-icon>
                    </template>
                    <span>{{ $t('L_calculator_fin_tooltip') }}</span>
                  </v-tooltip>
                </v-card-text>
              </v-flex>
              <v-flex xs12 sm6 md6 lg12>
                <v-card-text class="ma-0">
                  <v-layout row wrap justify-center>
                    <v-flex xs1 sm1 md1 lg1 />
                    <v-flex xs10 sm10 md10 lg10 font-size--24 font-weight-medium pill-text-field-red>
                      {{ $n(projection.summary.goal.savings, 'currencyNoCents', 'en-US') }} *
                    </v-flex>
                    <v-flex xs1 sm1 md1 lg1 />
                  </v-layout>
                  <span class="body-1 m-0">
                    {{ $t('L_calculator_total_savings') }}
                  </span>
                </v-card-text>
                <v-card-text justify-center class="body-1 font-weight-medium ma-0">
                  <v-tooltip max-width="200" html="true" open-on-click :open-on-hover="false" top close-delay="2000">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ $t('L_calculator_how_calculate') }} </span>
                      <v-icon color="blue darken-2" dark small v-on="on">
                        help
                      </v-icon>
                    </template>
                    <span @click="openDocument('ImportantNote')">
                      <p v-html="$t('L_calculator_how_calculate_tooltip')" />
                    </span>
                  </v-tooltip>
                  <div v-if="!isTablet && !isMobile">
                    <span class="link" @click="openDocument('ImportantNote')">
                      {{ $t('L_calculator_view_important_notes') }}
                    </span>
                  </div>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
      <v-card color="grey lighten-2" flat class="text-xs-center">
        <v-layout row wrap justify-center>
          <v-flex xs12 sm1 md1 lg12 />
          <v-flex xs12 sm5 md5 lg12>
            <v-card-text v-if="!isTablet && !isMobile" class="px-10 subtitle-1 font-weight-medium">
              <v-layout justify-center>
                <v-spacer />
                <v-flex xs2 sm2 md2 lg2>
                  <v-img v-if="isProjectionIncomeGoalPctUnder100" width="50px" height="50px" src="@/assets/investments/calculator/goal-icon.svg" />
                  <v-img v-else width="50px" height="50px" src="@/assets/investments/calculator/congrats-icon.svg" />
                </v-flex>
                <v-spacer />
              </v-layout>
            </v-card-text>
            <v-card-text v-if="isTablet || isMobile" class="px-0 subtitle-1 font-weight-medium ma-0">
              <span class="link" @click="openDocument('ImportantNote')">
                {{ $t('L_calculator_view_important_notes') }}
              </span>
            </v-card-text>
            <div v-if="isProjectionIncomeGoalPctUnder100">
              <div v-if="expandedPrediction || isTablet">
                <v-card-text class="subtitle-1 font-weight-regular ma-0 pa-0">
                  {{ $t('L_calculator_to_meet_goal') }}
                </v-card-text>
                <v-card-text>
                  <v-layout>
                    <v-spacer />
                    <v-flex xs10 sm10 md10 lg10 font-size--24 font-weight-medium pill-text-field>
                      {{ $n(projection.summary.goal.additionalMonthly, 'currencyNoCents', 'en-US') }}
                      <span class="body-1">{{ $t('L_calculator_per_month') }}</span>
                    </v-flex>
                    <v-spacer />
                  </v-layout>
                </v-card-text>
              </div>
              <div v-else>
                <v-card-text class="subtitle-1 font-weight-regular ma-0 pa-0">
                  <p v-html="$t('L_calculator_to_meet_goal_short', { amt: $n(projection.summary.goal.additionalMonthly, 'currencyNoCents', 'en-US') })" />
                </v-card-text>
              </div>
            </div>
            <div v-else>
              <v-card-text class="subtitle-1 congratulations font-weight-medium ma-0 pa-0">
                {{ $t('L_calculator_congratulations') }}
              </v-card-text>
              <v-card-text class="subtitle-1 font-weight-regular congratulations">
                {{ $t('L_calculator_congratulations_desc') }}
              </v-card-text>
            </div>
          </v-flex>
          <v-flex xs12 sm5 md5 lg12>
            <div v-if="!isMobile || expandedPrediction">
              <div v-if="isProjectionIncomeGoalPctUnder100">
                <v-card-text v-if="isTablet" />
                <v-card-text class="title font-weight-medium ma-0">
                  {{ $t('L_calculator_ask_your_rep') }}
                </v-card-text>
              </div>
              <div v-else>
                <v-card-text class="subtitle-1 congratulations">
                  <div class="font-weight-medium">
                    {{ $t('L_calculator_if_retirement_needs') }}
                  </div>
                </v-card-text>
              </div>
            </div>
            <!-- <v-card-text v-if="hasReps">
              <v-btn @click="contactRep = true" block color="primary" class="no-text-transform ma-0 p-0">
                <span style="white-space: normal;"> {{ $t('L_calculator_help_options_contact_representative') }} </span>
              </v-btn>
            </v-card-text> -->
            <MyRep
              :rep-page="false"
            />
          </v-flex>
          <v-flex xs12 sm1 md1 lg12 />
        </v-layout>
      </v-card>
    </v-flex>
    <EmbeddedViewer
      :title="document.title"
      :loading="document.loading"
      :content-type="document.type"
      :content-url="document.content"
      @close="document.content = null"
    />
  </v-layout>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Achievement from '@/components/Achievement/Achievement.vue';
import { COUNTRIES } from '@/shared/constants';
import Config from '@/shared/util/config';
import { currency } from '@/mixins/numbers';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';
import ContactRep from '../../../components/ContactRep/ContactRep.vue';
import sendRepEmail from '../../../components/ContactRep/sendEmail';
import CurrencyDisplay from '../../policy/guidance/components/CurrencyDisplay.vue';
import MyRep from '../../policy/my_rep/MyRep.vue';

export default {
  name: 'GuidanceCalculator',
  mixins: [currency],
  data() {
    return {
      document: {
        title: '',
        content: '',
        type: '',
      },
      selectedAccounts: [],
      hideDetails: true,
      isAgeValid: true,
      isPctValid: true,
      isDobValid: true,
      expandedPrediction: true,
      contactRep: false,
      sliderKey: 0,
      ageError: '',
      currentAge: '',
      dateOfBirth: '',
    };
  },
  components: {
    Achievement,
    CurrencyDisplay,
    LoadingIndicator,
    EmbeddedViewer,
    ContactRep,
    MyRep,
  },
  methods: {
    ...mapActions('customer', {
      getInvestmentCalculator: 'getInvestmentCalculator',
    }),
    ...mapActions('customer', ['contactRepresentative']),
    calculate(state) {
      if (this.isAgeValid && this.isPctValid && this.isDobValid) {
        if (state === 'init') {
          this.scenario.retirement.accounts.forEach(account => this.selectedAccounts.push(account.number));
        } else {
          const dob = moment(this.dateOfBirth, 'MM/DD/YYYY');
          this.scenario.dateOfBirth = dob.format('YYYY-MM-DD');
          this.scenario.currentAge = moment().diff(dob, 'years');
          this.scenario.retirement.accounts = this.scenario.retirement.accounts.map((account) => {
            account.enabled = this.selectedAccounts.includes(account.number);
            return account;
          });
        }
        if (state === 'goal') this.scenario.incomeGoalPct = 0;
        if (state === 'goalpct') this.scenario.incomeGoal = 0;
        this.scenario.incomeGoal = +this.scenario.incomeGoal;
        this.scenario.incomeGoalPct = +this.scenario.incomeGoalPct;
        this.scenario.otherSavings = +this.scenario.otherSavings;
        this.scenario.monthlySavings = +this.scenario.monthlySavings;
        this.scenario.currentIncome = +this.scenario.currentIncome;
        this.getInvestmentCalculator(this.scenario);
      }
    },
    openDocument(type) {
      this.document.type = 'application/pdf';
      if (type === 'ImportantNote') {
        this.document.title = this.$t('L_calculator_important_notes');
        this.document.content = ((this.scenario.retirement.age - this.scenario.currentAge) <= 5) ?
          `${Config.get('content_stack')}${Config.get('investments.how_do_we_calculate.less5years')}` :
          `${Config.get('content_stack')}${Config.get('investments.how_do_we_calculate.over5years')}`;
      } else if (type === 'SSN') {
        this.document.title = this.$t('L_calculator_social_security_benefit');
        this.document.content = (this.customerProfile.country === COUNTRIES.CANADA) ?
          Config.get('investments.social_security_benefits.ca') :
          Config.get('investments.social_security_benefits.us');
      } else if (type === 'Disclosure') {
        this.document.title = this.$t('L_important_disclosures');
        if (this.customerProfile.country.toLowerCase() === 'ca') {
          this.document.content =
            `${Config.get('content_stack')}${Config.get('investments.important_disclosures.ca')}`;
        } else {
          this.document.content =
            `${Config.get('content_stack')}${Config.get('investments.important_disclosures.us')}`;
        }
      }
    },
    validateAge() {
      if (this.scenario.retirement.age <= this.currentAge) {
        this.isAgeValid = false;
        this.ageError = this.$t('L_calculator_age_less_error');
      } else if (this.scenario.retirement.age >= this.scenario.lifeExpectancy) {
        this.isAgeValid = false;
        this.ageError = this.$t('L_calculator_age_greater_error', { age: this.scenario.lifeExpectancy });
      } else {
        this.isAgeValid = true;
        this.ageError = '';
      }
    },
    validatePct() {
      this.isPctValid = (this.scenario.incomeGoalPct <= 1000);
    },
    validateDob() {
      if (!this.dateOfBirth) {
        this.isDobValid = false;
        this.dobError = this.$t('L_calculator_dob_required');
      } else {
        const dob = moment(this.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD');
        const age = moment().diff(dob, 'years');
        if (age < 18) {
          this.isDobValid = false;
          this.dobError = this.$t('L_calculator_dob_min_error');
          this.currentAge = '';
        } else if (age > 100) {
          this.isDobValid = false;
          this.dobError = this.$t('L_calculator_dob_max_error');
          this.currentAge = '';
        } else {
          this.currentAge = age;
          this.isDobValid = true;
        }
      }
      this.validateAge();
    },
    validateAmt() {
      this.scenario.incomeGoalPct = +(((this.scenario.incomeGoal * this.scenario.incomePeriod) /
      this.scenario.currentIncome) * 100).toFixed(2);
      this.isPctValid = (this.scenario.incomeGoalPct <= 1000);
    },
    sendRepEmail(emailPayload) {
      sendRepEmail.bind(this)(this.contactRepresentative, emailPayload);
    },
    viewMyInvestments() {
      this.$emit('open');
    },
  },
  mounted() {
    if (!this.investments.calculator.projection) {
      this.calculate('init');
    } else {
      this.selectedAccounts = [];
      this.scenario.retirement.accounts.forEach((account) => {
        if (account.enabled) this.selectedAccounts.push(account.number);
      });
    }
    if (this.customerProfile.country === COUNTRIES.CANADA) {
      this.socialSecurityBenefits = Config.get('investments.social_security_benefits.ca');
    }
    this.expandedPrediction = !this.hidePredictionDetails;
  },
  computed: {
    ...mapGetters('policy', [
      'policiesSortedByDate',
    ]),
    ...mapGetters('customer', [
      'loading',
      'customerProfile',
      'investments',
      'investmentsSortedByDate',
    ]),
    ...mapGetters(['getAgent', 'agents']),
    hasReps() {
      return this.agents?.length > 0;
    },
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    isLoading() {
      return this.loading.retirementCalculator;
    },
    isCanada() {
      return this.customerProfile.country === COUNTRIES.CANADA;
    },
    projection() {
      return this.investments.calculator?.projection || {
        projectedRetirementIncome: 0,
        percentRetirementIncomeGoal: 0,
        summary: {
          current: {
            monthly: 0,
          },
          goal: {
            shortfall: 0,
            savings: 0,
            additionalMonthly: 0,
          },
        },
      };
    },
    scenario() {
      if (this.investments.calculator?.scenario) {
        const scenario = JSON.parse(JSON.stringify(this.investments.calculator.scenario));
        const dob = moment(scenario.dateOfBirth, 'YYYY-MM-DD');
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dateOfBirth = dob.format('MM/DD/YYYY');
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentAge = moment().diff(dob, 'years');
        return scenario;
      }
      const accounts = [];
      this.investments.groups.forEach((group) => {
        group.accounts.forEach((account) => {
          accounts.push({
            productType: account.productType,
            number: account.accountNumber,
            enabled: true,
            type: account.accountType,
            balance: account.endingValue,
          });
        });
      });
      return {
        currentIncome: 0,
        incomeGoal: 0,
        incomeGoalPct: 0,
        otherSavings: 0,
        monthlySavings: 0,
        scenarioId: -1,
        retirement: {
          accounts,
          savings: 0,
          age: 0,
        },
      };
    },
    hideEnoughDetails() {
      return this.$vuetify.breakpoint.xs;
    },
    hidePredictionDetails() {
      return this.isTablet || this.isMobile;
    },
    isProjectionIncomeGoalPctUnder100() {
      return (this.projection.percentRetirementIncomeGoal <= 100);
    },
    isTablet() {
      return this.$vuetify.breakpoint.sm;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isNotProd() {
      return Config.get('env') === 'dev' || Config.get('env') === 'test';
    },
  },
  watch: {
    hidePredictionDetails(newData) {
      this.expandedPrediction = !newData;
    },
  },
};

</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'
.blackText
  color: #000000;
#retirement-calculator
  .congratulations
    color: #333333;
  .view-mode-details
    color: #063562;
  .retirement-title
    background-color: #e3e3e3;
  .account-list
    background-color: #f5f5f5;
  .v-label
    display: block !important;
  .v-tooltip__content
    pointer-events: initial;
  .unit
    font-size: 12px;
  .chart
    display: inline-block;
  .pct-of-recommended
    display: none !important;
  .your-coverage
    display: none !important;
  .heading_four
    display: none !important;
  .color-light-black
    display: none !important;
  .pill-text-field
    padding-top: 10px;
    height: 55px;
    border-radius: 39px;
    background-color: #ffffffc0;
  .pill-text-field-red
    padding-top: 10px;
    height: 55px;
    border-radius: 39px;
    background-color: #ffffffc0;
    border: solid 1px #d96154;
  .titlebox
    background-color: #dcdcdc;
  .rule-of-thumb-card
    background-color: #f5f5f5;
  .doughnut-chart
    background-color: red;
  .estimate-social-security
    color: #02579e;
  .link
    font-weight: 600;
    color: #02579e;
    cursor: pointer;
  a.primary--text
    color: #e5e9ec !important;
    caret-color: #f4f4f4 !important;
  ul li
    margin: 10px;
  .v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
  .v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
    -webkit-appearance: none;
    margin: 0;
  .v-text-field__slot input
    text-align: center;

  .progress-circle
    background-color: #FFFFFF;
    &:after
      background-color: #d8d8d8;

    .left-half-clipper
      .value-bar
        border: 0.5em solid #0B8D1F;

      .first50-bar
        background-color: #0B8D1F;

      &:not(.over50)
        .first50-bar
          background-color: #FFFFFF !important;

    >.display
      color: #333333;
      background-image: none;

  .more-padding
    .v-text-field__slot input
      padding: 0px !important;

</style>
