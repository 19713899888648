import { render, staticRenderFns } from "./DebtInput.vue?vue&type=template&id=d9e42d0a&scoped=true"
import script from "./DebtInput.vue?vue&type=script&lang=js"
export * from "./DebtInput.vue?vue&type=script&lang=js"
import style0 from "./DebtInput.vue?vue&type=style&index=0&id=d9e42d0a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9e42d0a",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fdebtsolutions%2Fcomponents%2FMyDebt%2Fcomponents%2FDebtInput.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports