import PolicyBenefits from './PolicyBenefits.vue';

export default {
  name: 'TermLifeBenefits',
  path: '/benefits',
  // path: '/policy/learn-more',
  component: PolicyBenefits,
  params: {
    page: 'benefits',
  },
  meta: {
    pageTitle: 'Term.Life.Benefits',
    indexOfRoute: 2,
  },
};
