export class Bank {
  _name = '';

  _account = '';

  _payorName = '';

  constructor({
    name,
    account,
    payorName,
  }) {
    this._name = name;
    this._account = account;
    this._payorName = payorName;
  }

  get name() {
    return this._name || '';
  }

  get account() {
    return this._account || '';
  }

  get payorName() {
    return this._payorName || '';
  }
}
