<i18n src="../locales.json"></i18n>

<template>
  <v-flex xs12 sm6 md6 class="mt8 pa8">
    <CardTemplate id="update_userid_card" @show-modal="showDialog">
      <img
        v-if="hasTouchId"
        slot="icon"
        class="account-img"
        style="filter: invert(43%) sepia(17%) saturate(1779%) hue-rotate(165deg) brightness(92%) contrast(94%);"
        src="@/assets/TouchID.svg"
        alt="Touch Id"
      >
      <img
        v-else-if="hasFaceId"
        slot="icon"
        class="account-img"
        style="filter: invert(43%) sepia(17%) saturate(1779%) hue-rotate(165deg) brightness(92%) contrast(94%);"
        src="@/assets/FaceID.svg"
        alt="face id"
      >
      <span slot="title">{{ hasTouchId ? $t('L_manage_fingerprint_id') : $t('L_manage_face_id') }}</span>
      <a
        v-ada-modal
        slot="action"
        href="javascript:void(0)"
        role="button"
        @click="showUpdateBiometricsPreferenceDialog = true"
        id="update_userid_trigger"
        :aria-label="$t('L_update_biometrics_preference')"
      >
        {{ $t('L_edit') }}
      </a>
    </CardTemplate>
    <MyAccountDialog
      :header="hasTouchId ? $t('L_manage_fingerprint_id') : $t('L_manage_face_id')"
      :show="showUpdateBiometricsPreferenceDialog"
      :show-save-button="false"
      @close="closeDialog"
      :close-is-active="closeIsActive"
      save-changes-btn-id="save_biometrics_preference_id"
      close-btn-id="close_biometrics_preference_id"
      close-btn-class="update_biometrics_preference_close"
      modal-class="update_biometrics_preference_modal"
      submit-btn-text="L_next"
    >
      <v-layout row wrap slot="content">
        <LoadingIndicator :loading="updateInProgress" />
        <p>{{ hasTouchId ? $t('L_manage_fingerprint_id_prompt') : $t('L_manage_face_id_prompt') }}</p>
        <v-layout row wrap class="mt-3 justify-space-between">
          <v-flex xs6>
            <img
              v-if="hasTouchId"
              height="40"
              width="40"
              src="@/assets/TouchID.svg"
              class="mr-2"
              alt="Touch Id"
            >
            <img
              v-else-if="hasFaceId"
              height="40"
              width="40"
              src="@/assets/FaceID.svg"
              class="mr-2"
              alt="face id"
            >
          </v-flex>
          <v-flex xs6 class="right">
            <v-switch v-model="biometricsPreference" inset :label="biometricsPreferenceSwitchLabel" @change="changeTheBiometricsPreference" style="justify-content: flex-end;" />
          </v-flex>
        </v-layout>
        <v-layout row wrap class="pb-5 mb-5">
          <v-flex xs12>
            <span v-if="hasTouchId" class="font-weight-bold font-size--14">
              {{ $t('L_touch_id_sharing_your_device_with_anyone_who') }}
            </span>
            <span v-else-if="hasFaceId" class="font-weight-bold font-size--14">
              {{ $t('L_face_id_sharing_your_device_with_anyone_who') }}
            </span>
          </v-flex>
        </v-layout>
      </v-layout>
    </MyAccountDialog>
    <!-- TODO: Use Message Dialog -->
    <v-dialog
      v-model="isConfirmationDialogOpen"
      width="500"
    >
      <v-card style="border-top: 7px solid #0B8D1F">
        <v-card-text>
          <v-layout
            wrap
            row
            class="modal-primary-title"
          >
            <span>
              <v-icon
                :size="this.$vuetify.breakpoint.xs ? '22' : '26'"
                @click="closeConfirmationDialog"
                class="btn-close"
                color="#333333"
              >close</v-icon>
            </span>
          </v-layout>
          <h2
            class="heading_two text-xs-left pb-2"
            aria-live="assertive"
          >
            <v-icon
              color="success"
            >
              check_circle
            </v-icon>&nbsp;{{ confirmationMessage }}
          </h2>
        </v-card-text>
        <v-layout>
          <v-flex
            offset-md2
            md8
            offset-xs1
            xs10
            text-xs-left
          >
            <p>{{ biometricsPreference ? $t('L_you_can_now_login_without_using_u_and_p') : $t('L_you_can_now_login_with_using_u_and_p' ) }}</p>
            <v-btn
              block
              color="primary"
              class="mb-3 mt-5 px-2"
              @click="closeConfirmationDialog"
            >
              {{ $t("L_OK") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-flex>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import MessageDialog from '@/components/MessageDialog.vue';
import { hasFaceId, hasTouchId, setUnlockModeToBiometrics, setUnlockModeToSecureStorage,
  isUnlockModePreferenceSetToBiometrics } from '@/plugins/auth/utils/sessionManager';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';

export default {
  name: 'UpdateBiometricsPreference',
  components: {
    CardTemplate,
    MyAccountDialog,
    LoadingIndicator,
    MessageDialog,
  },
  data: () => ({
    showUpdateBiometricsPreferenceDialog: false,
    isConfirmationDialogOpen: false,
    closeIsActive: false,
    biometricsPreference: false,
    updateInProgress: false,
    hasFaceId: false,
    hasTouchId: false,
  }),
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    confirmationMessage() {
      let confirmationMessage = '';
      if (this.biometricsPreference) {
        confirmationMessage = this.hasTouchId ? this.$t('L_fingerprint_id_enabled') : this.$t('L_face_id_enabled');
      } else {
        confirmationMessage = this.hasTouchId ? this.$t('L_fingerprint_id_disabled') : this.$t('L_face_id_disabled');
      }
      return confirmationMessage;
    },
    biometricsPreferenceSwitchLabel() {
      return this.biometricsPreference ? this.$t('L_biometrics_preference_on') : this.$t('L_biometrics_preference_off');
    },
  },
  methods: {
    showDialog() {
      this.showUpdateBiometricsPreferenceDialog = true;
    },
    closeDialog() {
      this.showUpdateBiometricsPreferenceDialog = false;
    },
    async changeTheBiometricsPreference() {
      if (this.updateInProgress) return;
      this.updateInProgress = true;
      this.isConfirmationDialogOpen = true;
      if (this.biometricsPreference) {
        // Set Unlock Mode To Biometrics
        await setUnlockModeToBiometrics().catch(async (e) => {
          try {
            this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[UpdateBiometricsPreference] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
            await this.$store.dispatch('postAuditLog', {
              actionId: 'MobileApp_Device_FailureAction',
              details: `[${Date.now()}] ERROR CODE ${e?.code}`,
              message: e?.message,
            });
          } catch (error) { /*  */ }
        });
      } else {
        // Set Unlock Mode To Secure Storage
        await setUnlockModeToSecureStorage().catch(async (e) => {
          try {
            this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[UpdateBiometricsPreference] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
            await this.$store.dispatch('postAuditLog', {
              actionId: 'MobileApp_Device_FailureAction',
              details: `[${Date.now()}] ERROR CODE ${e?.code}`,
              message: e?.message,
            });
          } catch (error) { /*  */ }
        });
      }
      this.updateInProgress = false;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpen = false;
      this.showUpdateBiometricsPreferenceDialog = false;
    },
  },
  async created() {
    await Promise.all([hasFaceId(), hasTouchId(), isUnlockModePreferenceSetToBiometrics()])
      .then(([_hasFaceId, _hasTouchId, _isUnlockModePreferenceSetToBiometrics]) => {
        this.hasFaceId = _hasFaceId;
        this.hasTouchId = _hasTouchId;
        this.biometricsPreference = _isUnlockModePreferenceSetToBiometrics;
      });
    // TODO: handle error or log in DT
  },
};
</script>
