<i18n src='../../modules/policy/details/locales.json'></i18n>

<template>
  <div>
    <div v-if="mobile">
      <v-card
        v-for="(item, index) in data"
        :key="index+'insured'"
        :color="index % 2 === 0 ? '#fafcfd':'white'"
      >
        <v-card-text class="body-1">
          <v-flex
            xs12
            sm8
            offset-sm2
          >
            <v-layout
              row
              wrap
              v-for="(header, headerIndex) in headers"
              :key="headerIndex+'insured'"
              class="data-row"
            >
              <v-flex xs6
                      class="font-weight-medium"
              >
                <span v-html="transformHeaders(header.text)" />
              </v-flex>
              <v-flex xs6>
                <ResponsiveTableItem
                  :item="item[header.value]"
                  @click="$emit('clicked-item', item)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>
        <v-divider />
      </v-card>
    </div>

    <v-data-table v-if="!mobile"
                  :aria-label="$t('L_policy_coverage_details')"
                  :headers="headers"
                  :items="data"
                  hide-actions
                  class="elevation-0 white"
    >
      <template
        slot="headerCell"
        slot-scope="props"
      >
        <span v-if="displayHeader" v-html="transformHeaders(props.header.text)" />
      </template>
      <template
        slot="items"
        slot-scope="props"
      >
        <tr :class="props.item['background']">
          <td
            v-for="(header, headerIndex) in headers"
            :key="headerIndex+'insured'"
            class="pl-2 pt-2 valign-top"
            :class="props.item[header.value] ? props.item[header.value].itemClass : ''"
          >
            <ResponsiveTableItem
              :item="props.item[header.value]"
              @click="$emit('clicked-item', props.item)"
              @link="$emit('linked-item', props.item)"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ResponsiveTableItem from './ResponsiveTableItem.vue';

export default {
  name: 'ResponsiveTable',
  components: {
    ResponsiveTableItem,
  },
  props: {
    displayHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    transformHeaders: {
      type: Function,
      required: false,
      default: input => input,
    },
    mobile: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters('policy', {
      isPending: 'isPending',
    }),
  },
  methods: {
    getHeaders() {
      const th = [].slice.call(this.$el.querySelectorAll('th.column-header'));
      this.adaTable(th);
    },
    adaTable(th) {
      th.forEach((item) => {
        item.removeAttribute('aria-label');
      });
    },
  },
  mounted() {
    this.getHeaders();
  },
};
</script>

<style lang="stylus" scoped>

@require '~vuetify/src/stylus/settings/_variables'
/deep/ th.column-header {
  vertical-align: top;
  padding-top: 9px !important;
}

/deep/ .valign-top {
  vertical-align: top;
}

.data-row
  padding-top: $spacers.one.y;
  padding-bottom: $spacers.one.y;
  @media $display-breakpoints.sm-and-up
    padding-top: $spacers.two.y;
    padding-bottom: $spacers.two.y;
    &:not(:first-child)
      border-top: solid 1px #cccccc;
    > :first-child
      padding-left: 42px;
</style>
