<i18n src="../locales.json"></i18n>
<template>
  <a
    v-ada-modal
    id="contact_your_rep_sidebar_trigger"
    href="javascript:void(0)"
    role="button"
    aria-label="open contact your rep modal"
    @click="$emit('openModal')"
    class="pa-2 contact-rep-trigger"
  >{{ $t('L_email_me') }}</a>
</template>

<script>
export default {
  name: 'ContactRepTrigger',
};
</script>
