import { render, staticRenderFns } from "./YearsDisplay.vue?vue&type=template&id=554e66bf&scoped=true"
import script from "./YearsDisplay.vue?vue&type=script&lang=js"
export * from "./YearsDisplay.vue?vue&type=script&lang=js"
import style0 from "./YearsDisplay.vue?vue&type=style&index=0&id=554e66bf&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554e66bf",
  null
  
)

export default component.exports