<i18n src='../../../../locales.json'></i18n>

<template>
  <v-flex
    xs12
    class="pt-3 mt-4"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        shrink
      >
        <img src="./assets/house-icon.svg" alt="house">
      </v-flex>
      <v-flex
        role="heading"
        aria-level="2"
      >
        <h2>
          <a
            href="JavaScript:void(0);"
            class="heading_two mt-2"
          >{{ __t('L_term_life_insurance') }}</a>
        </h2>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import __t from '../../../../translate';

export default {
  name: 'TermLifeTitle',
  methods: {
    __t,
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'

a.heading_two
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
  color: #ffffff !important;
  @media $display-breakpoints.sm-only
    font-size: 22px;
  @media $display-breakpoints.xs-only
    font-size: 20px;
</style>
