<template>
  <div>
    <v-text-field
      v-model="formInput.$model"
      id="social-security-number"
      class="ssn-input"
      :mask="!masked && '###-##-####' || '#########'"
      pattern="[0-9]*"
      inputmode="numeric"
      :label="label || $t('L_social_security_number') || 'Social Security Number'"
      :autocomplete="disableAutocomplete ? 'off' : null"
      :hint="hint"
      required
      :color="color"
      :error-messages="errorMessages"
      :maxlength="9"
      @focus="onFocus"
      @input="mask"
      @blur="onBlur"
      :aria-describedby="describers"
      :type="masked ? 'password': 'text'"
    >
      <span
        slot="append"
      >
        <a
          id="ssnIcon"
          @mouseenter="masked = false"
          @mouseleave="masked = true"
          @mousedown="masked = false"
          @mouseup="masked = true"
        >
          <v-icon
            class="toggle-masking"
            :aria-label="masked ? 'show SSN' : 'hide SSN'"
          >
            {{ masked ? 'visibility' : 'visibility_off' }}
          </v-icon>
        </a>
        <FieldIcon
          :form-field="formInput"
          icon="lock"
        />
      </span>
    </v-text-field>
  </div>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export function getForDisplay() {
  const value = this[this.modelName];
  if (this.masked || value.length < 3) {
    return value;
  }
  if (value.length < 6) {
    return value.replace(/([\d•]{3})/, '$1-');
  }
  return value.replace(/([\d•]{3})([\d•]{2})([\d•]{0,4})/, '$1-$2-$3');
}

export default {
  name: 'SSNInput',
  components: { FieldIcon },
  extends: BaseTextField,
  data: () => ({
    modelName: 'socialSecurityNumber',
    socialSecurityNumber: '',
  }),
  computed: {
    getForDisplay,
  },
};


</script>
