<template>
  <v-card
    @click="$emit('show-modal')"
    class="clickable containerBox cursor-pointer"
    height="100%"
  >
    <v-content
      class="pa-3"
      style="height: 100%;"
      role="presentation"
    >
      <v-layout
        column
        justify-space-between
        fill-height
      >
        <v-flex xs12>
          <v-layout align-center>
            <v-flex shrink>
              <slot name="icon" />
            </v-flex>
            <v-flex>
              <div
                class="headerDiv"
              >
                <slot name="title" />
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          sm12
          text-sm-center
          align-center
          class="hidden-xs-only mh80"
        >
          <v-layout
            row
            wrap
            justify-center
            align-center
            fill-height
          >
            <div class="content">
              <slot name="content" />
            </div>
          </v-layout>
        </v-flex>
        <v-flex
          sm12
          text-sm-center
          class="hidden-xs-only"
        >
          <v-layout
            justify-center
            align-end
            fill-height
          >
            <div>
              <slot name="action" />
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
  </v-card>
</template>

<script>
export default {
  name: 'CardTemplate',
};
</script>

<style lang="stylus" scoped>
.mh80
  min-height: 80px
.content
  width: 100%
</style>
