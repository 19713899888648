<i18n src="./locales.json"></i18n>
<template>
  <div>
    <v-text-field
      v-model="formInput.$model"
      :id="bankRoutingid"
      class="bank-routing-number"
      type="tel"
      pattern="\d*"
      :maxlength="maxLengthinput"
      :label="label || $t('L_routing_number') || 'Routing Number'"
      :autocomplete="disableAutocomplete ? 'off' : null"
      required
      :color="color"
      :error-messages="errorMessages"
      @focus="onFocus"
      @blur="onBlur"
      @input="maxLengthCheck($event)"
      @keypress="isNumeric($event)"
      @keydown="noMax($event)"
      :aria-describedby="describers"
    >
      <FieldIcon
        :form-field="formInput"
        icon="lock"
      />
    </v-text-field>
  </div>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import { bankingNumbers } from '@/mixins/bankingNumbers';
import BaseTextField from '../BaseTextField.vue';
import messages from '../validation/messages';

export default {
  name: 'BankRoutingNumber',
  components: { FieldIcon },
  extends: BaseTextField,
  mixins: [bankingNumbers],
  props: {
    maxLengthinput: {
      type: Number,
      required: true,
      default: 9,
    },
    minLengthInput: {
      type: Number,
      required: false,
      default: 8,
    },
    bankRoutingid: {
      type: String,
      required: false,
      default: 'bank_routing_id',
    },
  },
  data: () => ({
    modelName: 'bankRoutingNumber',
    bankRoutingNumber: '',
    maxReached: false,
  }),
  computed: {
    errorMessages() {
      const errors = [];
      const validationMessages = Object.assign({}, messages[this.modelName], this.validationMessages);
      if (typeof (this.formInput) === 'undefined' || !this.formInput.$dirty) {
        return errors;
      }
      if (this.formInput.$error) { // If there is an error on the field
        Object.keys(validationMessages).forEach((type) => {
          if (this.formInput[type] === false && validationMessages && Object.prototype.hasOwnProperty.call(validationMessages, type)) {
            errors.push(validationMessages[type]);
          }
        });
        if (errors.length === 0) { // If no messages have been added for this error
          errors.push(messages.generic.invalid);
        }
      }
      return errors.map(msg => (typeof (this.$t) !== 'undefined' ? this.$t(msg, { length: this.minLengthInput }) : msg));
    },
  },
};
</script>

