<i18n src='../../../locales.json'></i18n>
<template>
  <div>
    <!-- If changing to Direct -->
    <ToDirect
      v-if="!showAchModal"
      :new-billing-mode="newBillingMode"
      @open-contact-details="$emit('open-contact-details')"
      @goToStepThree="$emit('goToStepThree')"
      :policy-data="policyData"
      @cancel="closePanel"
    />
    <!-- If changing to ACH -->
    <UpdateBank
      v-if="showAchModal"
      :is-update-bank="false"
      :show-date-picker="true"
      @cancel="closePanel"
      @done="closePanel"
    />
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import ToDirect from './components/ToDirect.vue';
import UpdateBank from '../../../../ExpandedPaymentCard/components/UpdateBank/UpdateBank.vue';

export default {
  name: 'StepTwo',
  components: {
    ToDirect,
    UpdateBank,
  },
  computed: {
    showAchModal() {
      if (this.newBillingMode === '01') {
        return true;
      }
      return false;
    },
  },
  props: {
    policyData: {
      type: Object,
      required: true,
      default: () => {},
    },
    newBillingMode: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations({
      setDisablePaymentDetailsLink: 'policy/setDisablePaymentDetailsLink',
      setPanelValue: 'policy/setPanelValue',
      setPaymentMethodChangeAlert: 'policy/setPaymentMethodChangeAlert',
      setEditPaymentMethod: 'policy/setEditPaymentMethod',
    }),
    closePanel() {
      this.setDisablePaymentDetailsLink(false);
      this.setPaymentMethodChangeAlert(false);
      this.setEditPaymentMethod(false);
      this.setPanelValue(-1);
    },
  },
};
</script>

