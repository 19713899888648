<i18n src='../locales.json'></i18n>

<template>
  <v-flex
    xs12
    sm6
    md6
    class="mt8 pa8"
    height="100%"
  >
    <CardTemplate
      :class="flagActive ? 'enabled-card':'disabled-card'"
    >
      <img
        slot="icon"
        class="account-img"
        src="../assets/update-info.svg"
        alt=""
        role="presentation"
      >
      <span
        slot="title"
        v-html="$t('L_set_lang')"
      />
      <span slot="content">
        <ul class="language-label">
          <li>
            <span
              class="subheader flag"
              :class="currentLocale"
            />
          </li>
          <li>
            {{ $t(languageLabel) }}
          </li>
        </ul>
      </span>
      <a
        v-ada-modal
        role="button"
        :disabled="!flagActive"
        slot="action"
        href="javascript:void(0)"
        id="update_language_trigger"
        :aria-label="$t('L_set_lang')"
      >{{ $t('L_edit') }}</a>
    </CardTemplate>
    <MyAccountDialog
      :header="$t('L_set_language_for_dialog')"
      :show="showUpdateLanguageDialog"
      @save="updateLanguagePreference"
      @close="closeDialog"
      :close-is-active="closeIsActive"
      :enable-save-button="enableSaveButton"
      save-changes-btn-id="save_user_lang"
      close-btn-id="close_user_lang"
      close-btn-class="update_language_close"
      modal-class="update_language_modal"
    >
      <v-layout
        row
        wrap
        slot="content"
      >
        <v-flex xs12>
          <v-select
            v-model="$v.language.$model"
            :items="languageOptions"
            label="Select"
            class="mb-5"
          />
        </v-flex>
      </v-layout>
    </MyAccountDialog>
  </v-flex>
</template>
<script>
import Config from '@/shared/util/config';
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';

export function showDialog() { this.showUpdateLanguageDialog = true; }
export function onSuccess() {
  this.$v.$reset();
  this.$i18n.locale = this.language;
  /*
  * Emit custom actions when user successfully saves changes to: language preference
  * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
  */
  const i = this.$dynatrace.enterAction('account_settings_changes_saved_language_preference');
  this.$dynatrace.leaveAction(i);
  this.$store.commit('addAlert', {
    type: 'success',
    details: { message: this.$t('L_update_language_preference') },
    context: 'myaccount',
  });
}
export default {
  name: 'UpdateLanguage',
  data() {
    return {
      showUpdateLanguageDialog: false,
      languages: Config.get('languagePreferences'),
      closeIsActive: false,
    };
  },
  validations: {
    language: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      langName: 'langName',
      currentLocale: 'getLocale',
    }),
    flagActive() {
      return true; // substitute for config flag
    },
    enableSaveButton() {
      return this.$v.$anyDirty && !this.$v.$invalid;
    },
    languageLabel() {
      return this.$t(`L_${this.currentLocale}`);
    },
    languageOptions() {
      const options = [];
      // Set default language preferences English if no config
      if (!this.languages) {
        const defaultLangs = ['en_US', 'en_CA'];
        defaultLangs.forEach((lang) => {
          options.push({
            text: this.$t(`L_${lang}`),
            value: lang,
          });
        });
      } else {
        this.languages.forEach((lang) => {
          options.push({
            text: this.$t(`L_${lang}`),
            value: lang,
          });
        });
      }
      return options;
    },
  },
  methods: {
    ...mapActions(['changeLocale']),
    updateLanguagePreference() {
      /* const updateLanguagePreference = {
        preferredLang: this.language,
      };
      */
      // this.$store.dispatch('home/updateLanguagePreference', updateLanguagePreference).then(onSuccess.bind(this));
      this.changeLocale(this.language);
      this.closeIsActive = true;
    },
    closeDialog() {
      this.showUpdateLanguageDialog = false;
      this.$v.$reset();
    },
  },
  components: {
    CardTemplate,
    MyAccountDialog,
  },
  mounted() {
    const element = this.$el;
    if (element && typeof (element.querySelector) === 'function') {
      const target = element.querySelector('.clickable');
      if (target && typeof (target.addEventListener) === 'function') {
        target.addEventListener('click', showDialog.bind(this));
      }
    }
  },
};
</script>
<style lang="stylus" scoped>
  .flag {
    background-image: url('../assets/flags.png');
    width: 35px;
    height: 23px;
    display: block;
    position: relative;
  }
  .en_US,
  .es_US {
    background-position-x: -16px;
    background-position-y: -115px;
  }
  .fr_CA,
  .en_CA {
    background-position-x: -63px;
    background-position-y: -68px;
  }
  span.full-width
  ul.language-label
    padding 0
    display inline-flex
    list-style-type none
    flex-direction row
    align-items center
    li:first-child
      padding-right 1.25rem
  a[disabled="disabled"]
    color: gray !important
    cursor default
  .show-hover
    .layout
      cursor pointer
    &:hover
      background-color #dcdcdc
</style>

