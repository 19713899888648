<i18n src='../locales.json'></i18n>

<template>
  <div class="mt-4 pt-3">
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout row>
          <v-flex
            xs12
            md7
            id="fund_education"
          >
            <div
              class="heading_three"
            >
              {{ $t('L_fund_children_education') }}
            </div>
            <div
              class="heading_four mt-2 font-weight-medium"
            >
              {{ $t('L_provide_for_education') }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        class="shift-up"
      >
        <v-layout
          row
          wrap
        >
          <LinkedSlider
            v-model="amount"
            :form-input="formInput"
            :tooltip-text="$t('L_education_average_cost')"
            max="100000"
            step="1000"
            @input="$emit('input', $event)"
            label-id="fund_education"
          />
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Slider from '@/components/Inputs/Slider/Slider.vue';
import CurrencyDisplay from '../../CurrencyDisplay.vue';
import LinkedSlider from '../../LinkedSlider.vue';

export default {
  name: 'FundEducation',
  data: () => ({
    sliderAmount: 57000,
    amount: 57000,
    manualInput: false,
  }),
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    formInput: {
      type: Object,
      required: true,
    },
  },
  components: {
    LinkedSlider,
    Slider,
    CurrencyDisplay,
  },
  watch: {
    value(value) {
      this.amount = value;
    },
  },
  mounted() {
    this.amount = this.sliderAmount = this.value;
  },
};
</script>
