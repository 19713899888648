<template>
  <v-card
    :id="baseId+'_card'"
    class="cursor-pointer"
    :class="selected ? 'selectedCard': 'unselectedCard'"
  >
    <v-flex
      class="text-xs-center"
      v-show="selected"
    >
      <v-icon class="confirm">
        check
      </v-icon>
    </v-flex>
    <v-card-text
      class="pl-4 pr-4"
      :id="baseId+'_cardtext'"
    >
      <div
        :id="baseId+'_amnt'"
      >
        {{ amount | currency }}
      </div>
      <p
        :id="baseId+'_annual'"
        class="text-wrap mb-0"
      >
        {{ $t(label) }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PaymentOptionCard',
  props: {
    baseId: {
      type: String,
      required: true,
      default: 'edit_paymthd',
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
    amount: {
      type: String,
      required: true,
      default: '',
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
  },
  filters: {
    currency(value) {
      return `$${value}`;
    },
  },
};
</script>

<style scoped>
.selectedCard {
  background-color: #063562 !important;
  color: #ffffff !important;
  border: 1px solid #063562;
}
.unselectedCard {
  background-color: #ffffff !important;
  color: #063562 !important;
  border: 1px solid #063562;
}
.confirm {
  position: absolute;
  margin-left: -15px;
  margin-top: -15px;
  border-radius: 50%;
  border: solid 3px white;
  color: white;
  background-color: #063562;
}
</style>
