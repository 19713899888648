<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout
          pt-2
          pb-4
        >
          <v-flex shrink>
            <span class="pl-3 font-weight-regular font-size--24 white--text"
                  role="heading"
                  aria-level="1"
            >{{ $t('L_help_title') }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex>
        <template>
          <v-card class="mb-4">
            <v-card-text>
              <v-layout class="help-frame mb-4">
                <iframe @load="setRootLoading(false)" id="mainIframe" width="100%" height="580px" frameborder="0"
                        scrolling="auto" align="left" :title="$t('L_help_iframe_title')"
                        marginwidth="0" marginheight="0" seamless="seamless"
                        :src="frameSource"
                />
              </v-layout>
              <v-layout row wrap class="help-footer text-xs-center">
                <v-flex xs12 class="flex-footer">
                  <v-expansion-panel
                    class="pri-center-expansion-panel"
                    role="presentation"
                  >
                    <v-expansion-panel-content
                      role="presentation"
                      tabindex="-1"
                    >
                      <template v-slot:header>
                        <div
                          ref="options1"
                          role="button"
                          :aria-expanded="`${expanded}`"
                          @click="expanded = !expanded"
                          tabindex="0"
                        >
                          {{ $t('L_more_support_options') }}
                        </div>
                      </template>
                      <v-divider />
                      <v-card>
                        <v-layout row wrap justify-space-around class="text-xs-center font-size--14">
                          <v-flex xs12 sm4 md3 pt-2>
                            <v-layout row wrap justify-center pt-4 pb-4>
                              <v-flex xs2>
                                <v-icon color="#02579e">
                                  phone
                                </v-icon>
                              </v-flex>
                              <v-flex shrink class="font-weight-medium">
                                {{ $t('L_call_support') }}
                              </v-flex>
                              <v-flex xs8 pl-4>
                                <a id="help_call_support" :href="'tel:1'+contact.number" class="font-weight-medium cobalt">{{ contact.phone }}</a>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm4 md3 pt-2>
                            <v-layout row wrap justify-center pt-4 pb-4>
                              <v-flex xs2>
                                <v-icon color="#02579e">
                                  mail_outline
                                </v-icon>
                              </v-flex>
                              <v-flex shrink class="font-weight-medium">
                                {{ $t('L_email_support') }}
                              </v-flex>
                              <v-flex xs12>
                                <a id="help_email_support" :href="'mailto:'+contact.email">{{ contact.email }}</a>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm4 md3 pt-2>
                            <v-layout row wrap pt-4 pb-4>
                              <v-flex xs12 mb-2 class="font-weight-medium">
                                {{ $t('L_hours_of_operation') }}
                              </v-flex>
                              <v-flex xs12 class="font-size--12">
                                {{ $t('L_monday_to_friday_hours') }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import Ada from '@/shared/ada/ada';
import Config from '@/shared/util/config';

export const contactInfo = [
  { number: 8002574725, phone: '(800) 257-4725', email: 'plic@primerica.com' },
  { number: 8002574725, phone: '(800) 257-4725', email: 'nblic@primerica.com' },
  { number: 8003877976, phone: '(800) 387-7976', email: 'plicc_cn@primerica.com' },
];
export default {
  name: 'Help',
  data: () => ({
    frameSource: Config.get('help.anonymous'),
    expanded: false,
  }),
  computed: {
    ...mapGetters('policy', ['currentPolicy', 'policies']),
    contact() {
      let contact;
      switch (true) {
        case this.currentPolicy?.isCanadaPolicy:
          contact = contactInfo[2];
          break;
        case this.currentPolicy?.isNewYorkPolicy:
          contact = contactInfo[1];
          break;
        default:
          contact = contactInfo[0];
      }
      return contact;
    },
  },
  methods: {
    ...mapActions('policy', ['getPolicyOverview']),
    ...mapMutations(['setRootLoading']),
  },
  mounted() {
    if (this.$route.params.type) {
      this.frameSource = Config.get(`help.${this.$route.params.type}`);
    }
    this.setRootLoading(true);
    Ada.addKeyClick(this.$refs.options1);
    if (this.policies.length > 0 && this.policies[0].contractId === 0) {
      this.getPolicyOverview();
    }
  },
};
</script>

<style scoped>
#ra_external{
  width: 100%;
}
#mainIframe {
  overflow-y: scroll;
}
</style>
