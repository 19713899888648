<i18n src='./locales.json'></i18n>

<template>
  <v-layout style="height: 100%; align-items: center;">
    <v-flex
      shrink
      style="text-align: center;"
      class="top-row"
    >
      <h3
        role="heading"
        aria-level="2"
        class="heading_three font-weight-medium pl-4 pr-4 color-light-black hidden-sm-and-down your-coverage"
      >
        {{ $t('L_you_have_achieved') }}
      </h3>
      <h4
        role="presentation"
        class="heading_four hidden-sm-and-down pct-of-recommended mb-2 pb-1"
      >
        (% {{ $t('L_of_recommended') }})
      </h4>
      <div
        class="progress-circle"
        :style="{
          'font-size': size + 'px',
        }"
      >
        <span class="display font-weight-medium">{{ progress.toFixed(0) }}%</span>
        <div
          class="left-half-clipper"
          :class="{
            over50: progress > 50
          }"
        >
          <div class="first50-bar" />
          <div
            class="value-bar"
            :style="{
              transform: `rotate(${this.progress * 3.6}deg)`,
            }"
          />
        </div>
      </div>
    </v-flex>
    <v-flex
      grow
      class="visible-sm-only"
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          ml-3
        >
          <h3 class="heading_three font-weight-medium color-light-black">
            {{ $t('L_you_have_achieved') }}
          </h3>
          <h4 class="heading_four">
            (% {{ $t('L_of_recommended') }})
          </h4>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'Achievement',
  props: {
    progress: {
      type: Number,
      required: true,
    },
    progress2: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },
  },
};
</script>

<style lang="stylus" scoped>
$xsOnly = "only screen and (max-width: 599px)"
$smAndDown = "only screen and (max-width: 768px)"
$mdOnly = "only screen and (min-width: 500px) and (max-width: 768px)"
.color-light-black {
  color: #333;
  @media $mdOnly {
    font-size: 16px !important;
  }
}

h4.pct-of-recommended
  @media $xsOnly
    display: block !important;
    font-size: 14px;
    margin-bottom: 16px;

h3.your-coverage
  @media $xsOnly
    display: block !important;
    font-size: 18px !important;
    margin-bottom: 4px;

.top-row
  @media $xsOnly
    width: 100% !important;

.progress-circle
  @media $mdOnly
    span.display
      font-size: 1.7em;
      line-height: 5.25em;
      width: 5.25em;
      background-size: 65%;
  display: inline-block;
  position: relative;
  padding: 0;
  width: 9em;
  height: 9em;
  background-color: #868686;
  border-radius: 50%;
  line-height: 9em;
  &:after
    z-index: 3;
    border: none;
    position: absolute;
    top: .5em;
    left: .5em;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 8em;
    height: 8em;
    background-color: #7a7a7a;
    content: " ";

  .left-half-clipper
    z-index: 1;
    border-radius: 50%;
    width: 9em;
    height: 9em;
    position: absolute;
    clip: rect(0, 9em, 9em, 4.5em);
    .value-bar
      position: absolute;
      clip: rect(0, 4.5em, 9em, 0);
      width: 9em;
      height: 9em;
      border-radius: 50%;
      border: 0.5em solid #56a4dd;
      box-sizing: border-box;

    .first50-bar
      position: absolute;
      clip: rect(0, 9em, 9em, 4.5em);
      background-color: #56a4dd;
      border-radius: 50%;
      width: 9em;
      height: 9em;

    &.over50
      clip: rect(auto,auto,auto,auto) !important;

    &:not(.over50)
      .first50-bar
        background-color: #868686 !important;

  >.display
    position: absolute;
    font-size: 2em;
    line-height: 4.5em;
    width: 4.5em;
    text-align: center;
    display: block;
    color: #FFF;
    z-index: 4;
    background-image: url('./assets/financial-house.svg');
    background-position: center;
    background-size: 3em;

  >.label
    display: inline;
    line-height 1em;
    z-index: 4;
    position: absolute;
    top: 0.1em;
    left: 51%;
    font-size: 10px;
    color: white;

    &.inner
      top: 1.05em;

/deep/ .label
  span
    display: inline-block
    &.char2
      transform: rotate(6deg)
    &.char3
      transform: translateY(.1em) rotate(12deg)
    &.char4
      transform: translateY(.2em) rotate(16deg)
    &.char5
      transform: translateY(.4em) rotate(20deg)
    &.char6
      transform: translateY(.7em) rotate(24deg)

</style>
