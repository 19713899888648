<i18n src='../../../locales.json'></i18n>

<template>
  <div>
    <v-expansion-panel
      :value="0"
      class="payment-details pri-center-expansion-panel"
      role="presentation"
      :disabled="disablePaymentDetailsLink"
    >
      <v-expansion-panel-content
        role="presentation"
        tabindex="-1"
        class="keep-icon-upright"
      >
        <!-- <div
          slot="header"
          ref="payment"
          role="button"
          tabindex="0"
          id="view_payment_details_container"
          :aria-expanded="panelOpen"
          :class="{'disabled-link': disablePaymentDetailsLink}"
          @click.stop.prevent="togglePanel"
        >
          <div
            id="view_payment_details_info"
          >
            {{ $t('L_policy_view_payment_details') }}
          </div>
        </div> -->
        <v-card
          color="lighten-4"
        >
          <v-divider />
          <ExpandedPaymentCard
            v-if="!paymentMethodChangeAlert && !editPaymentMethod"
            :bank="bank"
            :policy-data="policyData"
            @editPayMethod="editPayMethod"
          />

          <AlertChangePayment
            v-if="paymentMethodChangeAlert"
            @cancel="cancelEditPaymentMethod"
            @goToStepOne="goToStepOne"
          />

          <EditPaymentMethod
            v-if="editPaymentMethod"
            @cancel="cancelEditPaymentMethod"
          />
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import ExpandedPaymentCard from './ExpandedPaymentCard/ExpandedPaymentCard.vue';
import AlertChangePayment from './ChangePaymentMethod/AlertChangePayment.vue';
import EditPaymentMethod from './ChangePaymentMethod/components/EditPaymentMethod/EditPaymentMethod.vue';

export default {
  name: 'ViewPaymentDetails',
  computed: {
    ...mapGetters({
      panelValue: 'policy/panelValue',
      paymentMethodChangeAlert: 'policy/getPaymentMethodChangeAlert',
      editPaymentMethod: 'policy/getEditPaymentMethod',
      disablePaymentDetailsLink: 'policy/getDisablePaymentDetailsLink',
    }),
    panelOpen() {
      return this.panelValue === 0 ? 'true' : 'false';
    },
    kubraPaymentInProgress() {
      return this.policyData?.kubraPaymentStatus !== null;
    },
  },
  props: {
    payNow: {
      required: true,
      type: Boolean,
      default: false,
    },
    bank: {
      type: Object,
      default: () => ({}),
    },
    policyData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    ...mapMutations({
      setDisablePaymentDetailsLink: 'policy/setDisablePaymentDetailsLink',
      setPanelValue: 'policy/setPanelValue',
      setPaymentMethodChangeAlert: 'policy/setPaymentMethodChangeAlert',
      setEditPaymentMethod: 'policy/setEditPaymentMethod',
    }),
    editPayMethod() {
      if (this.policyData.isB2BillingForm) {
        this.setPaymentMethodChangeAlert(true);
      } else {
        this.setDisablePaymentDetailsLink(true);
        this.setEditPaymentMethod(true);
      }
    },
    cancelEditPaymentMethod() {
      this.setDisablePaymentDetailsLink(false);
      this.setPaymentMethodChangeAlert(false);
      this.setEditPaymentMethod(false);
    },
    goToStepOne() {
      this.setDisablePaymentDetailsLink(true);
      this.setPaymentMethodChangeAlert(false);
      this.setEditPaymentMethod(true);
    },
    togglePanel() {
      if (!this.disablePaymentDetailsLink) {
        this.setEditPaymentMethod(false);
        this.setPaymentMethodChangeAlert(false);
        this.$emit('toggle', this.panelValue);
      }
    },
  },
  components: {
    ExpandedPaymentCard,
    AlertChangePayment,
    EditPaymentMethod,
  },
};
</script>

<style scoped>
  .disabled-link{
    color: gray;
  }
  /deep/ .disabled-link + div i.v-icon{
    color: gray !important;
  }
</style>
