<i18n src='../../locales.json'></i18n>

<template>
  <v-flex
    xs12
    class="make-right-choice-section text-xs-center containerBox"
  >
    <v-layout
      class="why-primerica-us"
      row
      wrap
    >
      <v-flex
        xs12
        mb-3
        class="title-text font-size--24"
      >
        <h2 class="inherit">
          {{ $t('L_you_made_right_choice') }}
        </h2>
      </v-flex>

      <v-flex
        xs12
        sm4
        md4
        class="text-xs-center"
      >
        <v-layout
          align-start
          justify-center
          row
          fill-height
          class="box"
        >
          <v-flex
            pt-4
            pl-4
            pr-4
          >
            <v-layout
              justify-center
              align-center
            >
              <img
                src="./assets/partnering-icon.svg"
                alt=""
                role="presentation"
                aria-hidden="true"
              >
            </v-layout>
            <div
              role="heading"
              aria-level="3"
              class="font-size--14 mt-3 pt-1 font-weight-bold"
              v-html="$t('L_partner_with_company')"
            />
            <div
              class="font-size--14 mt-3 pt-1"
              v-html="$t(isCanadaPolicy ? 'L_largest_distribution_canada' : 'L_named_one_america_fortune_1000')"
            />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex
        xs12
        sm4
        md4
        class="text-xs-center right-choice-flex-box"
      >
        <v-layout
          align-start
          justify-center
          row
          fill-height
          class="box"
        >
          <v-flex
            pt-4
            pl-4
            pr-4
          >
            <v-layout
              justify-center
              align-center
            >
              <img
                src="./assets/protecting-icon.svg"
                alt=""
                role="presentation"
                aria-hidden="true"
              >
            </v-layout>
            <div
              role="heading"
              aria-level="3"
              class="font-size--14 mt-3 pt-1 font-weight-bold"
              v-html="$t('L_protect_loved_one')"
            />
            <div
              class="font-size--14 mt-3 pt-1"
              v-html="$t(isCanadaPolicy ? 'L_coast_to_coast_canada' : 'L_ninety_four_percent')"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        sm4
        md4
        class="text-xs-center right-choice-flex-box"
      >
        <v-layout
          align-start
          justify-center
          row
          fill-height
          class="box"
        >
          <v-flex
            pt-4
            pl-4
            pr-4
          >
            <v-layout
              justify-center
              align-center
            >
              <img
                src="./assets/investing-icon.svg"
                alt=""
                role="presentation"
                aria-hidden="true"
              >
            </v-layout>
            <div
              role="heading"
              aria-level="3"
              class="font-size--14 mt-3 pt-1 font-weight-bold"
              v-html="$t('L_invest_for_future')"
            />
            <div
              class="font-size--14 mt-3 pt-1"
              v-html="$t(isCanadaPolicy ? 'L_pfsl_investments_canada_ltd' : 'L_more_than_2andhalf_million')"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class="bottom-disclaimer text-xs-left containerBox">
      <v-flex xs12 mb-3 class="title-text font-size--12">
        <div v-html="$t(isCanadaPolicy ? 'L_Life_insurance_offered_by_Primerica_Life_Canada' : 'L_from_fortune_magazine_us1')" />
        <div class="pt-4" v-if="!isCanadaPolicy" v-html="$t('L_numbers_reflect_the_combined_totals_as_of_us2')">
&nbsp;
        </div>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  name: 'WhyPrimerica',
  props: {
    isCanadaPolicy: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus" scoped>
.make-right-choice-section
  margin: 40px 0 0 0;
  color: #fff;
  background: #264568;
  padding: 40px;
.bottom-disclaimer
  margin: 40px -40px -40px -40px;
  color: #fff;
  background: #1A3D5C;
  padding: 40px;
</style>
