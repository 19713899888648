<template>
  <div class="child-rider-conversion">
    <CRCStepAPersonalInfo v-if="step === 1" />
    <CRCStepBSelectionOptions v-if="step === 2" />
    <CRCStepCConfirmPayment v-if="step === 3" />
    <CRCStepDEDelivery v-if="step === 4" />
    <CRCStepEAcknowledge v-if="step === 5" />
    <CRCStepFSuccess v-if="step === 6" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CRCStepAPersonalInfo from '../CRCStepAPersonalInfo/CRCStepAPersonalInfo.vue';
import CRCStepBSelectionOptions from '../CRCStepBSelectionOptions/CRCStepBSelectionOptions.vue';
import CRCStepCConfirmPayment from '../CRCStepCConfirmPayment/CRCStepCConfirmPayment.vue';
import CRCStepDEDelivery from '../CRCStepDEDelivery/CRCStepDEDelivery.vue';
import CRCStepEAcknowledge from '../CRCStepEAcknowledge/CRCStepEAcknowledge.vue';
import CRCStepFSuccess from '../CRCStepFSuccess/CRCStepFSuccess.vue';

export default {
  name: 'ChildRiderSteps',
  components: {
    CRCStepAPersonalInfo,
    CRCStepBSelectionOptions,
    CRCStepCConfirmPayment,
    CRCStepDEDelivery,
    CRCStepEAcknowledge,
    CRCStepFSuccess,
  },
  computed: {
    ...mapGetters({
      step: 'childrider/step',
    }),
  },
  mounted() {
    if (this.step === undefined || this.step < 0) {
      this.$router.push({ name: 'Dashboard' });
    }
  },
};
</script>
