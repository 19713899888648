import { isCanadaPolicyNumber } from '../util/businessRules';
import strings from './strings.json';

// US - Client Communications for Primerica and NBL (NY)
// Telephone: (800) 257-4725
// For Canada English: (800) 387-7876
// For French: (800) 265-4804
export const getClientCommunicationsPhone = (policy) => {
  if (!policy || !policy.contractId) {
    return '';
  } else if (isCanadaPolicyNumber(policy.contractId)) {
    if (policy._owner._address._state === 'PQ') {
      return strings.clientCommunicationsPhone.ca_fr;
    }
    return strings.clientCommunicationsPhone.ca_en;
  }
  return strings.clientCommunicationsPhone.us_en;
};
