<i18n src='./locales.json'></i18n>
<template>
  <v-content class="pt-0">
    <MessageDialog
      :show-dialog="dialog"
      :has-button-two="true"
      type="warning"
      :title="$t('L_cancel_payment_process')"
      :message="$t('L_do_you_want_to_cancel_the_current_payment_process_and_not_submit')"
      :button-two-label="$t('L_yes')"
      :button-one-label="$t('L_no')"
      :is-persistent="true"
      @buttonTwoAction="closeAndGoToPolicyDetails"
      @buttonOneAction="dialog=false"
    />
    <!-- Section Guide
    One Time Payment -    1 Payment Amount  2 Payment Method  3 Welcome Back
    ACH              -    1 Payment Amount  2 Payment Method  3 Review Payment 4 Confirmation
    -->
    <!-- This is for screen larger than xsOnly Horizontal Mode -->
    <v-stepper v-model="step" v-show="!$vuetify.breakpoint.smAndDown">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1" class="stepperTextSize">
          {{ $t('L_payment_amount') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2" class="stepperTextSize">
          {{ $t('L_payment_method') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3" class="stepperTextSize">
          {{ $t(selectedMethod === 2?'L_welcome_back':'L_review_payment') }}
        </v-stepper-step>
        <v-divider v-if="selectedMethod !== 2" />
        <v-stepper-step
          v-if="selectedMethod !== 2" :complete="step > 4" step="4" class="stepperTextSize"
        >
          {{ $t('L_confirmation') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <PayStepOne
            @closeAndGoToPolicyDetails="closeAndGoToPolicyDetails"
            @cancelDialog="dialog=true"
            @minSelected="selectedPaymentId=0"
            @totalSelected="selectedPaymentId=1"
            @goToStepTwo="goToStepTwo"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <PayStepTwo
            :policy-data="currentPolicy"
            :minimum-amount-selected="selectedPaymentId==0"
            :selected-amount="this.selectedAmount"
            @cancelDialog="dialog=true"
            @goToStepThree="goToStepThree"
            @goToStepThreeCC="gotoStepThreeCC"
            @paymentMethodSet="handlePaymentMethodSet"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <div v-if="isKubra">
            <!-- KUBRA -->
            <PayStepThreeKubra
              @cancelDialog="dialog=true"
              @close-external-kubra="closeExternalKubraWindow"
            />
          </div>
          <div v-else>
            <!-- ACH -->
            <PayStepThreeACH
              @goBackToPaymentMethod="step=2"
              @goBackToPaymentAmount="step=1"
              @cancelDialog="dialog=true"
              @goToStepFour="goToStepFour"
              :selected-amount="this.selectedAmount"
            />
          </div>
        </v-stepper-content>
        <v-stepper-content step="4" v-if="isKubra === false">
          <PayStepFour
            @cancelDialog="dialog=true"
            @goToStepFive="goToStepFive"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- This is for xsOnly Vertical Mode-->
    <v-stepper
      v-model="step"
      vertical
      v-show="$vuetify.breakpoint.smAndDown"
    >
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        {{ $t('L_payment_amount') }}
      </v-stepper-step>

      <v-stepper-content step="1">
        <PayStepOne
          @closeAndGoToPolicyDetails="closeAndGoToPolicyDetails"
          @cancelDialog="dialog=true"
          @minSelected="selectedPaymentId=0"
          @totalSelected="selectedPaymentId=1"
          @goToStepTwo="goToStepTwo"
        />
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2">
        {{ $t('L_payment_method') }}
      </v-stepper-step>

      <v-stepper-content step="2">
        <PayStepTwo
          :policy-data="currentPolicy"
          :minimum-amount-selected="selectedPaymentId==0"
          :selected-amount="this.selectedAmount"
          @cancelDialog="dialog=true"
          @goToStepThree="goToStepThree"
          @goToStepThreeCC="gotoStepThreeCC"
          @paymentMethodSet="handlePaymentMethodSet"
        />
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3">
        {{ $t(selectedMethod === 2?'L_welcome_back':'L_review_payment') }}
      </v-stepper-step>
      <v-stepper-content step="3">
        <div v-if="isKubra">
          <!-- KUBRA -->
          <PayStepThreeKubra
            @cancelDialog="dialog=true"
            @close-external-kubra="closeExternalKubraWindow"
          />
        </div>
        <div v-else>
          <!-- ACH -->
          <PayStepThreeACH
            @goBackToPaymentMethod="goBackToStepTwo"
            @goBackToPaymentAmount="goBackToStepOne"
            @cancelDialog="dialog=true"
            @goToStepFour="goToStepFour"
            :selected-amount="this.selectedAmount"
          />
        </div>
      </v-stepper-content>
      <v-stepper-step v-if="selectedMethod !== 2" :complete="step > 4" step="4">
        {{ $t('L_confirmation') }}
      </v-stepper-step>

      <v-stepper-content step="4" v-if="isKubra === false">
        <PayStepFour
          @cancelDialog="dialog=true"
          @goToStepFive="goToStepFive"
        />
      </v-stepper-content>
    </v-stepper>
  </v-content>
</template>
<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import { currency } from '@/shared/util/formatters';
import MessageDialog from '@/components/MessageDialog.vue';
import PayStepOne from './components/StepOne/PayStepOne.vue';
import PayStepTwo from './components/StepTwo/PayStepTwo.vue';
import PayStepThreeACH from './components/StepThree/PayStepThreeACH.vue';
import PayStepThreeKubra from './components/StepThree/PayStepThreeKubra.vue';
import PayStepFour from './components/StepFour/PayStepFour.vue';

export default {
  name: 'PaymentMethodLayout',
  components: {
    PayStepOne,
    PayStepTwo,
    PayStepThreeACH,
    PayStepThreeKubra,
    PayStepFour,
    MessageDialog,
  },
  props: {
    // show: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },
    // alertContext: {
    //   type: String,
    //   required: false,
    //   default: 'go_paperless',
    // },
    // policynumber: {
    //   type: String,
    //   required: false,
    //   default: '',
    // },
  },
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
    persistent: true,
    dialog: false,
    step: 1,
    isKubra: false,
    selectedAmount: '',
    selectedMethod: '',
    accountNumber: '',
    routingNumber: '',
    selectedPaymentId: -1,
    curPaymentData: Object, // routing, bank
    kubraExternalWindow: Object,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', [
      'currentPolicy',
      'selectedPolicyIndex',
      'paymentData',
    ]),
    ...mapGetters({
      locale: 'getLocale',
    }),
    getTotalDue() {
      return currency(+this.currentPolicy?.paynowInfo?.totalPaymentAmount, 2);
    },
    getMinimumDue() {
      return currency(+this.currentPolicy?.paynowInfo?.minimumPaymentAmount, 2);
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument', 'setPaymentStep']),
    handlePaymentMethodSet(method) {
      this.selectedMethod = method;
    },
    goBackToStepOne() {
      this.selectedPaymentId = -1;
      this.selectedAmount = '';
      this.step = 1;
    },
    goBackToStepTwo() {
      this.selectedMethod = '';
      this.accountNumber = '';
      this.routingNumber = '';
      this.step = 2;
    },
    async closeAndGoToPolicyDetails() {
      this.setPaymentStep(-1);
      this.$router.push({ name: 'PolicyDetails', params: { contractId: this.selectedPolicyIndex } });
    },
    goToStepTwo(amount) {
      this.selectedAmount = amount.replaceAll('$', '');
      this.step = 2;
    },
    goToStepThree() {
      // This is called from PayStepTwo
      // this.$store.commit('policy/setPaymentData', paymentData);
      this.curPaymentData = this.paymentData;
      this.isKubra = false;
      this.step = 3;
    },
    gotoStepThreeCC(winObj) {
      this.isKubra = true;
      this.step = 3;
      this.kubraExternalWindow = winObj;
      // Launch the Kubra site on new tab
      // Kubra Done Page can navigate away
      this.setPaymentStep(3);
    },
    goToStepFour() {
      this.step = 4;
      // ACH Done Page can navigate away
      this.setPaymentStep(3);
    },
    goToStepFive() {
      this.step = 5;
      this.closeAndGoToPolicyDetails();
    },
    closeExternalKubraWindow() {
      this.kubraExternalWindow.close();
    },
  },
  watch: {
    step(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.removeAlerts('payment');
      }

      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      const step = newVal;
      if (step === 1) { // Payment Selection page displayed
        try {
          const i = this.$dynatrace.enterAction(`DisplayedPayments: Total $${this.getTotalDue}, Minimum $${this.getMinimumDue})`);
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
        try {
          const i = this.$dynatrace.enterAction('OneTimePayment_PaymentSelection_PageDisplayed');
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
      } else if (step === 2) { // The Payment Method page is displayed.
        try {
          const i = this.$dynatrace.enterAction('OneTimePayment_PaymentMethod_PageDisplayed');
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
      } else if (step === 3) { // The Payment Review page is displayed.
        try {
          const i = this.$dynatrace.enterAction('OneTimePayment_ACH_PaymentAgreement_PageDisplayed');
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
      } else if (step === 4) {
        try { // The Payment Confirmation page is displayed.
          const i = this.$dynatrace.enterAction('OneTimePayment_ACH_PaymentConfirmation_PageDisplayed');
          this.$dynatrace.leaveAction(i);
          const j = this.$dynatrace.enterAction('OneTimePayment_submit');
          this.$dynatrace.leaveAction(j);
        } catch (error) { /*  */ }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
    padding-left: 5px;
  }
}
.stepperTextSize {
  zfont-size: 12px;
}
.v-stepper__header {
  overflow: auto;
  display: flex !important;
  flex-wrap:no-wrap;
  justify-content: left;
}
</style>
