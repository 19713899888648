<i18n src="../locales.json"></i18n>
<template>
  <div
    class="ny-forms"
    row
    wrap
  >
    <v-flex
      xs12
      mt-2
      v-for="(form, index) in formContent"
      :key="index"
    >
      <a
        href="javascript:void(0)"
        :id="'ny_doc_'+form.linkID"
        @click="openDocument(formLinks[form.formName], $t(form.formText))"
        class="subheading font-weight-medium"
      >
        {{ $t(form.formText) }}
      </a>
    </v-flex>
    <EmbeddedViewer
      :title="document.title"
      :loading="document.loading"
      content-type="application/pdf"
      :content-url="document.content"
      @close="document.content = null"
    />
  </div>
</template>

<script>
import Config from '@/shared/util/config';
import { mapGetters } from 'vuex';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';

export default {
  name: 'NYForms',
  components: {
    EmbeddedViewer,
  },
  data: () => ({
    document: {
      title: '',
      content: '',
      loading: false,
    },
    formContent: [
      { formName: 'multiPurposeForm', formText: 'L_multi_purpose_form', linkID: 'multi-purpose' },
      { formName: 'freedomAccumulation', formText: 'L_fab_apda', linkID: 'freedom' },
    ],
  }),
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    formLinks() {
      if (this.locale === 'en_US') {
        return { ...Config.get('policyDocumentForms.en_NY') };
      }
      return { ...Config.get('policyDocumentForms.es_NY') };
    },
  },
  methods: {
    openDocument(content, title) {
      this.document.title = title;
      this.document.content = `${Config.get('content_stack')}${content}`;
    },
  },
};
</script>
