<i18n src="../locales.json"></i18n>

<template>
  <div>
    <v-card @click="$emit('show-modal')" class="clickable containerBox cursor-pointer">
      <v-content class="pa-3" style="height: 100%;" role="presentation">
        <v-layout column justify-space-between fill-height>
          <v-flex xs12>
            <v-layout align-center>
              <v-flex shrink>
                <img class="account-img" src="../assets/update-info.svg" alt="" role="presentation">
              </v-flex>
              <v-flex>
                <v-layout row wrap>
                  <v-flex xs12 class="headerDiv">
                    <span v-html="$t('L_mobile_phone_number')" />
                  </v-flex>
                  <v-flex xs12 v-if="mobileNoExists">
                    <span v-if="!customerProfile">&nbsp;- </span>
                    <span v-if="customerProfile">
                      {{ formatedContactNumber }}
                    </span>
                  </v-flex>
                  <v-flex xs12 class="font-weight-regular font-italic" v-if="mobileNoExists">
                    <span v-html="bindVerifiedLabel" />
                  </v-flex>
                  <v-flex xs12 v-if="!mobileNoExists">
                    <span>
                      {{ $t('L_to_keep_you_account') }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-content>
    </v-card>

    <v-layout class="clickable cursor-pointer py-2 verifynow" v-if="mobileNoExists && !mobileNoVerified && canShowVerifyNow" @click="$emit('verify-now')">
      <v-flex text-xs-center>
        <a v-ada-modal role="button" href="javascript: void(0)" id="verify_now" :aria-label="$t('L_verify_now')">{{ $t('L_verify_now') }}</a>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../../../shared/constants';

export default {
  name: 'UpdateContactMobile',
  props: {
    formatedContactNumber: {
      type: String,
      required: true,
    },
    mobileNoExists: {
      type: Boolean,
      required: true,
      default: false,
    },
    mobileNoVerified: {
      type: Boolean,
      required: true,
      default: false,
    },
    bindVerifiedLabel: {
      type: String,
      required: true,
    },
    customerProfile: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      featureActive: 'containsFeature',
    }),
    canShowVerifyNow() {
      return this.$store.getters.containsFeature(FEATURE_FLAGS.CUST_VERIFYPHONE);
    },
  },
};
</script>
<style scoped>
.verifynow {
  background-color: #eee;
}
</style>
