export const getDefaultState = () => ({
  error: null,
  loading: {
    investments: false,
    investmentStatements: false,
    retirementCalculator: false,
  },
  investments: {
    groups: [],
    summary: {},
    statements: [],
    calculator: {},
    isCompliant: true,
    samlToken: '',
  },
  customerProfile: {
    country: '',
    customerId: null,
    dob: null,
    email: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    phoneVerified: false,
    policyIds: [],
    preferredLang: '',
    ssn: null,
  },
  binaryInvestmentStatement: null,
});

const state = getDefaultState();

export default state;

