<i18n src='./locales.json'></i18n>

<template>
  <div id="my_support">
    <v-flex v-if="!$vuetify.breakpoint.xsOnly" pt-4 class="white--text background hidden-xs-and-down">
      <template v-if="$route.name !== 'Representative'">
        <div v-if="displayAgent" class="text-xs-center mb-4 pb-1 agent-message">
          <div class="imageContainer">
            <img :src="repImageUrl" v-img-fallback="repImageFallback" class="rep-image active imageBox" alt="Representative">
          </div>

          <h3 class="heading_three font-size-lg font-weight-medium mt-3">
            {{ agent.name }}
          </h3>
          <h4 role="presentation" class="heading_four">
            {{ $t('L_term_life_rep') }}
          </h4>
          <v-layout>
            <v-flex xs12 pa-3>
              <v-expansion-panel class="payment-details pri-center-expansion-panel" role="presentation">
                <v-expansion-panel-content role="presentation" tabindex="-1">
                  <div slot="header" class="panelHeader">
                    <v-flex xs12 text-xs-center>
                      <v-card class="cursor-pointer selectedCard">
                        <v-card-text class="pa-1">
                          <div class="font-size--18" ref="contact1" role="button" :aria-expanded="`${expanded}`" @click="expanded = !expanded" tabindex="0">
                            {{ $t('L_contact_details') }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </div>
                  <v-card class="pb-2 light-expanded-card">
                    <v-layout v-if="agent.email" row wrap justify-center>
                      <v-flex xs10 text-xs-center>
                        <h5 class="heading_five pa-2" role="presentation">
                          <img src="./assets/email.svg" alt="" role="presentation">
                          <ContactRepTrigger @openModal="contactRep = true" />
                        </h5>
                      </v-flex>
                    </v-layout>
                    <v-divider />
                    <v-layout row wrap justify-center>
                      <v-flex xs10 text-xs-center>
                        <a>
                          <h5 class="heading_five pa-2">
                            <v-icon size="22px" color="#02579e">phone</v-icon>
                          </h5>
                        </a>
                      </v-flex>
                    </v-layout>
                    <v-flex v-if="agent.useCellPhone && agent.cell" xs12 class="pl-3 pr-0">
                      <v-layout align-left>
                        <a class="font-size--14 mr-1">{{ $t('L_mobile') }}</a>
                      </v-layout>
                      <v-layout align-left>
                        <a :href="'tel:+1' + agent.cell" class="ml-1">{{ agent.cell | phone }}</a>
                      </v-layout>
                    </v-flex>
                    <v-flex v-if="agent.office && agent.office.phone" xs12 class="pl-3 pr-3">
                      <v-layout align-left>
                        <a class="font-size--14 mr-1">{{ $t('L_office') }}</a>
                      </v-layout>
                      <v-layout align-left>
                        <a :href="'tel:+1' + agent.office.phone" class="ml-1">{{ agent.office.phone | phone }}</a>
                      </v-layout>
                    </v-flex>
                    <v-flex v-if="agent.office && agent.office.fax" xs12 class="pl-3 pr-3">
                      <v-layout align-left>
                        <a class="font-size--14 mr-1">{{ $t('L_fax') }}</a>
                      </v-layout>
                      <v-layout align-left>
                        <a :href="'tel:+1' + agent.office.fax" class="ml-1">{{ agent.office.fax | phone }}</a>
                      </v-layout>
                    </v-flex>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-flex>
          </v-layout>
        </div>
        <div v-if="isHomeOffice" class="text-xs-center mb-4 pb-1 agent-message">
          <h3 class="heading_three font-size-lg font-weight-medium mt-3">
            {{ $t('L_home_office') }}
          </h3>
          <h4 role="presentation" class="heading_four">
            {{ $t('L_h_o_questions') }}
          </h4>
          <h4 role="presentation" class="heading_four pb-3">
            {{ $t('L_call_num_below') }}
          </h4>
          <h3 role="presentation" class="heading_threefont-size-md">
            <img class="phone-img pr-1" src="./assets/phone.svg">
            <a class="ml-2 font-weight-medium view-more-link" href="tel:+6785558003">(800) 257-4725</a>
          </h3>
        </div>
      </template>
    </v-flex>
    <v-flex v-if="$vuetify.breakpoint.xsOnly" xs12 class="hidden-md-and-up my-support-flex mt-4 pb-4 mobile-center">
      <template v-if="$route.name !== 'Representative'">
        <v-layout row wrap>
          <v-flex>
            <h2 class="heading_two text-xs-center text-sm-center mt-4 mb-4">
              My Rep
            </h2>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="text-center">
          <v-flex>
            <v-layout justify-center align-center>
              <v-card class="containerBox my-support-mobile-card min-height pa-2">
                <div v-if="isHomeOffice">
                  <v-layout row wrap justify-center align-center pt-2 class="font-weight-regular text-xs-center">
                    <div class="text-xs-center pb-2">
                      <h3 role="heading" aria-level="2" class="font-weight-medium mt-2 pb-2">
                        {{ $t('L_home_office') }}
                      </h3>
                      <h4 role="presentation" class="heading_four">
                        {{ $t('L_h_o_questions') }}
                      </h4>
                      <h4 role="presentation" class="heading_four pb-3">
                        {{ $t('L_call_num_below') }}
                      </h4>
                      <h3 role="presentation" class="heading_threefont-size-md">
                        <img class="phone-img pr-1" src="./assets/phone.svg">
                        <a class="ml-2 font-weight-medium view-more-link" href="tel:+8002574725">{{ $t('L_home_office_contact') }}</a>
                      </h3>
                    </div>
                  </v-layout>
                </div>
                <div v-else>
                  <v-layout row wrap justify-center class="text-xs-center">
                    <v-flex xs12 class="pa-2">
                      <div class="imageContainer">
                        <img :src="repImageUrl" v-img-fallback="repImageFallback" class="rep-image active imageBox" alt="Agent Profile">
                      </div>
                    </v-flex>
                    <v-flex v-if="agent" xs12 class="pa-2">
                      <h3 class="heading_three font-size-lg font-weight-medium">
                        {{ agent.name }}
                      </h3>
                      <h4 class="heading_four mb-3">
                        {{ $t('L_term_life_rep') }}
                      </h4>
                      <v-layout>
                        <v-flex xs12 pa-3>
                          <v-expansion-panel class="payment-details pri-center-expansion-panel" role="presentation">
                            <v-expansion-panel-content role="presentation" tabindex="-1">
                              <div slot="header" class="panelHeader">
                                <v-flex xs12 text-xs-center>
                                  <v-card class="cursor-pointer selectedCard">
                                    <v-card-text class="pa-1">
                                      <div class="font-size--18">
                                        {{ $t('L_contact_details') }}
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-flex>
                              </div>
                              <v-card class="pb-2 light-expanded-card">
                                <v-layout v-if="agent.email" row wrap justify-center>
                                  <v-flex xs10 text-xs-center>
                                    <h5 class="heading_five pa-2">
                                      <img src="./assets/email.svg" alt="" role="presentation">
                                      <ContactRepTrigger @openModal="contactRep = true" />
                                    </h5>
                                  </v-flex>
                                </v-layout>
                                <v-divider />
                                <v-layout row wrap justify-center>
                                  <v-flex xs10 text-xs-center>
                                    <a>
                                      <h5 class="heading_five pa-2">
                                        <v-icon size="22px" color="#02579e">phone</v-icon>
                                      </h5>
                                    </a>
                                  </v-flex>
                                </v-layout>
                                <v-flex v-if="agent.useCellPhone && agent.cell" xs12 class="pl-3 pr-3">
                                  <v-layout align-center>
                                    <v-flex xs3>
                                      <v-layout justify-start>
                                        <a class="font-size--14 mr-1">{{ $t('L_mobile') }}</a>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs9>
                                      <a :href="'tel:+1' + agent.cell" class="ml-1">{{ agent.cell | phone }}</a>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex v-if="agent.office && agent.office.phone" xs12 class="pl-3 pr-3">
                                  <v-layout align-center>
                                    <v-flex xs3>
                                      <v-layout justify-start>
                                        <a class="font-size--14 mr-1">{{ $t('L_office') }}</a>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs9>
                                      <a :href="'tel:+1' + agent.office.phone" class="ml-1">{{ agent.office.phone | phone }}</a>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex v-if="agent.office && agent.office.fax" xs12 class="pl-3 pr-3">
                                  <v-layout align-center>
                                    <v-flex xs3>
                                      <v-layout justify-start>
                                        <a class="font-size--14 mr-1">{{ $t('L_fax') }}</a>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs9>
                                      <a :href="'tel:+1' + agent.office.fax" class="ml-1">{{ agent.office.fax | phone }}</a>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </v-flex>
    <ContactRep
      v-model="contactRep"
      :agents="[agent]"
      @close="contactRep = false"
      @send-message="$emit('contact-rep', $event)"
      close-selector="contact_your_rep_sidebar_close"
      modal-class="contact_your_rep_sidebar_modal"
      :sender="sender"
    />
  </div>
</template>

<script>
import ada from '@/shared/ada/ada';
import ContactRep from '@/components/ContactRep/ContactRep.vue';
import ContactRepTrigger from './components/ContactRepTrigger.vue';

export default {
  name: 'MySupport',
  components: {
    ContactRepTrigger,
    ContactRep,
  },
  data: () => ({
    currentSliderItem: 0,
    mySupportList: [1, 2, 3, 4, 5],
    resizeListener: undefined,
    top: 426,
    contactRep: false,
    expanded: false,
  }),
  props: {
    agent: {
      type: Object,
      required: false,
      default: () => {},
    },
    sender: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    isHomeOffice() {
      return this.agent?.isHomeOfficeAgent;
    },
    displayAgent() {
      return this.agent?.name?.length > 0;
    },
    repImageUrl() {
      return this.agent?.displayPic;
    },
    repImageFallback() {
      // eslint-disable-next-line
      return require('./assets/rep-thumb.svg');
    },
  },
  methods: {
    changeSlide(index) {
      this.currentSliderItem = index;
    },
  },
  mounted() {
    if (this.agent?.name?.length > 0) {
      ada.addKeyClick(this.$refs.contact1);
    }
  },
  filters: {
    phone(number) {
      return number ? number.replace(/(\d{3})(\d{3})(\d{4,})/, '($1) $2-$3') : '';
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables';

#my_support {
  @media only screen and (min-width: 1264px) {
    position: absolute;
    top: 480px;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.phone-img {
  margin-bottom: -4px;
  color: white !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.v-card {
  background-color: #0c2950 !important;
  color: #fff !important;
}

.font-size-md {
  font-size: 16px !important;
}

.rep-image {
  width: 64px;

  &.active {
    width: 80px;
    height: 60px;
  }
}

div.rep-image {
  background-color: #032241;
  background-image: url('./assets/rep-thumb.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

img.divider {
  height: 2px;
  width: 100%;
}

.background {
  background-image: linear-gradient(187deg, #003466, #00284e);
  height: calc(100% - 96px);
}

.my-support-flex {
  background: #294c73;
  margin-left: -16px;
  margin-right: -16px;
  padding: 0 16px;
  box-sizing: content-box;
  overflow: hidden;
}

.min-height {
  min-height: 200px;
}

.slider-container {
  position: relative;

  .v-card {
    display: none;
    border-radius: 12px;
    position: absolute;
    transition: transform 0.5s cubic-bezier;

    &.fl, &.l, &.m, &.r, &.fr {
      display: flex;
    }

    &.fl {
      transform: translate3d(-240%, 0, 0);
    }

    &.l {
      transform: translate3d(-120%, 0, 0);
    }

    &.r {
      transform: translate3d(120%, 0, 0);
    }

    &.fr {
      transform: translate3d(240%, 0, 0);
    }
  }
}

#dots {
  width: 100%;
  text-align: center;
  margin-top: 32px;

  // New style
  .slider-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 1px white;
    margin: 0 4px;
    cursor: pointer;

    &.active {
      background: #ffffff;
    }
  }
}

@media $display-breakpoints.xs-only {
  .v-card {
    background-color: #0c2950 !important;
    color: #ffffff !important;
    width: 100%;
  }

  .my-support-flex {
    background: rgba(0, 0, 0, 0);
    margin-left: 0px;
    margin-right: 0px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .mb24 {
    margin-bottom: 24px;

    .mt8 {
      margin-top: 8px;
    }
  }

  .text-center {
    text-align: center;
  }

  .min-height {
    min-height: 215px;
  }
}

button {
  &.v-btn--outline {
    border-color: #fff;
    height: 40px !important;
    line-height: 26px;
  }
}

>>>.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon {
  display: none;
}

>>>.v-expansion-panel__header {
  padding: 0px !important;
  min-height: 35px;
}

.selectedCard {
  background-color: #063562 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.panelHeader {
  width: 100%;
  border: 1px solid #ffffff;
}

.pb-2.v-card.v-sheet.theme--light.light-expanded-card {
  background: #fff !important;
}
</style>
