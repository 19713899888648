import { render, staticRenderFns } from "./ErrorLayout.vue?vue&type=template&id=e2de4ce0&scoped=true"
import script from "./ErrorLayout.vue?vue&type=script&lang=js"
export * from "./ErrorLayout.vue?vue&type=script&lang=js"
import style0 from "./ErrorLayout.vue?vue&type=style&index=0&id=e2de4ce0&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2de4ce0",
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Ferror%2Flayouts%2FErrorLayout.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports