export default {
  makeButton(el, label) {
    el.setAttribute('role', 'button');
    el.setAttribute('tabindex', 0);
    el.setAttribute('aria-label', label);
  },
  hideFromSR(el) {
    const tabindex = el.getAttribute('tabindex');
    const role = el.getAttribute('role');
    if (role) {
      el.setAttribute('data-ada-role', role);
    }
    if (tabindex === '0') {
      el.setAttribute('data-ada-tabindex', +tabindex);
    }
    el.setAttribute('tabindex', '-1');
    el.setAttribute('aria-hidden', 'true');
    el.setAttribute('role', 'presentation');
  },
  revealToSR(el) {
    const tabindex = el.getAttribute('data-ada-tabindex');
    const role = el.getAttribute('data-ada-role');

    el.removeAttribute('aria-hidden');
    el.removeAttribute('tabindex');
    el.removeAttribute('role');
    if (tabindex === '0') {
      el.setAttribute('tabindex', +tabindex);
      el.removeAttribute('data-ada-tabindex');
    }
    if (role && role !== 'presentation') {
      el.setAttribute('role', role);
      el.removeAttribute('data-ada-role');
    }
  },
  addKeyClick(el) {
    el.addEventListener('keydown', (e) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault();
        e.target.click();
      }
    });
  },
  focusIt(elem) {
    setTimeout(() => {
      elem.focus();
    }, 50);
  },
  createTabLoopSelectors(dialog) {
    if (!dialog) {
      return;
    }
    const focusables = [].slice.call(dialog.querySelectorAll('button, [role="button"], [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'));
    const firstElm = focusables[0];
    const lastElem = focusables.length > 1 ? focusables[focusables.length - 1] : null;
    this.addTabLoop(firstElm, lastElem);
  },
  addTabLoop(firstSel, lastSel) {
    if (!lastSel) {
      firstSel.addEventListener('keydown', (e) => {
        if ((e.keyCode === 9 && !e.shiftKey) || (e.keyCode === 9 && e.shiftKey)) {
          e.preventDefault();
        }
      });
    } else {
      firstSel.addEventListener('keydown', (e) => {
        if (e.keyCode === 9 && e.shiftKey) {
          e.preventDefault();
          lastSel.focus();
        }
      });
      lastSel.addEventListener('keydown', (e) => {
        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          firstSel.focus();
        }
      });
    }
  },
  addKeyLoop(items, trigger) {
    items.forEach((item, index) => {
      this.addKeyClick(item);
      item.addEventListener('keydown', (e) => {
        if ((index === items.length - 1) && e.keyCode === 40) {
          e.preventDefault();
          this.focusIt(items[0]);
        } else if ((index === 0) && e.keyCode === 38) {
          e.preventDefault();
          this.focusIt(items[items.length - 1]);
        } else if (e.keyCode === 38) {
          e.preventDefault();
          this.focusIt(items[index - 1]);
        } else if (e.keyCode === 40) {
          e.preventDefault();
          this.focusIt(items[index + 1]);
        } else if ((e.keyCode === 9 && !e.shiftKey) || (e.keyCode === 9 && e.shiftKey)) {
          e.preventDefault();
          this.focusIt(trigger);
        }
      });
    });
  },
  popupMenu(trigger, itemWrapper) {
    this.addKeyClick(trigger);
    const items = [].slice.call(itemWrapper.querySelectorAll('.v-list__tile__title'));

    trigger.addEventListener('click', () => {
      this.focusIt(items[0]);
    });

    this.addKeyLoop(items, trigger);
  },
  makeTabs(tabWrapper) {
    if (!tabWrapper) {
      return;
    }
    tabWrapper.setAttribute('role', 'tablist');
    const emptyDivs = [].slice.call(tabWrapper.querySelectorAll('div'));
    const tabs = [].slice.call(tabWrapper.querySelectorAll('a.v-tabs__item'));

    emptyDivs.forEach((div) => {
      div.setAttribute('role', 'presentation');
    });
    tabs.forEach((tab) => {
      this.addKeyClick(tab);
    });

    this.setRoles(tabs);
    this.tabsKeyLoop(tabs);

    tabWrapper.addEventListener('click', () => {
      this.setRoles(tabs);
    });
  },
  setRoles(tabs) {
    tabs.forEach((tab) => {
      const active = tab.classList.contains('v-tabs__item--active');
      tab.setAttribute('aria-selected', `${active}`);
      tab.setAttribute('role', 'tab');
      tab.setAttribute('tabindex', active ? 0 : -1);
    });
  },
  tabsKeyLoop(tabs) {
    tabs.forEach((tab, index) => {
      const isFirst = index === 0;
      const isLast = index === tabs.length - 1;
      this.addKeyClick(tab);
      tab.addEventListener('keydown', (e) => {
        const eventData = { key: e.keyCode, isFirst, isLast };
        this.handleTabPress(e, eventData, tabs, index);
      });
    });
  },
  handleTabPress(e, eventData, tabs, index) {
    const combos = [
      { key: 39, isFirst: false, isLast: true },
      { key: 39, isFirst: true, isLast: false },
      { key: 39, isFirst: false, isLast: false },
      { key: 37, isFirst: true, isLast: false },
      { key: 37, isFirst: false, isLast: true },
      { key: 37, isFirst: false, isLast: false },
    ];

    const pos = combos.findIndex(item => JSON.stringify(item) === JSON.stringify(eventData));

    if (pos >= 0) {
      e.preventDefault();
      this.setTabFocus(pos, tabs, index);
    }
  },
  setTabFocus(pos, tabs, index) {
    switch (pos) {
      case 0:
        this.focusIt(tabs[0]);
        break;
      case 1:
      case 2:
        this.focusIt(tabs[index + 1]);
        break;
      case 3:
        this.focusIt(tabs[tabs.length - 1]);
        break;
      case 4:
      case 5:
        this.focusIt(tabs[index - 1]);
        break;
      default:
        break;
    }
  },
};
