<i18n src='./locales.json'></i18n>

<template>
  <div>
    <v-text-field
      :id="idProp"
      class="password-input"
      v-model="formInput.$model"
      autocomplete="new-password"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      :color="color"
      :error-messages="errorMessages"
      :label="label || $t && $t('L_password') || 'Create a Password'"
      max-length="118"
      required
      @focus="onFocus"
      @input="mask"
      @blur="onBlur"
      @paste.prevent=""
      @copy.prevent=""
      @cut.prevent=""
      aria-describedby="password-tooltip password_hint"
      :type="masked ? 'password': 'text'"
    >
      <span
        slot="append"
      >
        <v-icon
          tabindex="0"
          :aria-label="$t('L_toggle_show_password')"
          class="toggle-masking"
          v-if="enableShowHide"
          @keydown="showPass($event)"
          @keyup="masked = true"
          @mouseenter="masked = false"
          @mouseleave="masked = true"
          @mousedown="masked = false"
          @mouseup="masked = true"
        >
          {{ masked ? 'visibility' : 'visibility_off' }}
        </v-icon>
        <FieldIcon
          :form-field="formInput"
          :icon="validIcon"
        />
      </span>
    </v-text-field>
  </div>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField, { maskCallback } from '../BaseTextField.vue';

const maskTimeout = {};

export function setInnerText(container, message) {
  if (container !== null) {
    container.innerText = message;
  }
}

export default {
  name: 'PasswordInput',
  extends: BaseTextField,
  components: { FieldIcon },
  data: () => ({
    modelName: 'password',
    password: '',
  }),
  props: {
    idProp: {
      type: String,
      default: 'password',
    },
    validIcon: {
      type: String,
      default: 'lock',
    },
  },
  methods: {
    mask(input) {
      const value = input;
      if (!this.masked) {
        this.textValue = value;
        this[this.modelName] = value;
        this.onInput(value);
        return;
      }
      const currentValue = this.textValue;
      const caretPosition = this.$el.querySelector('input').selectionStart;
      this[this.modelName] = value.split('').map((char, i) => ((char === currentValue[i] || i >= caretPosition) ? '•' : char)).join('');

      this.textValue = value;
      if (maskTimeout[this._uid]) {
        window.clearTimeout(maskTimeout[this._uid]);
        maskTimeout[this._uid] = null;
      }
      maskTimeout[this._uid] = window.setTimeout(maskCallback.bind(this), 1200);
      this.onInput(this.formInput.$model);
    },
    showPass(e) {
      if (e.keyCode >= 65 && e.keyCode <= 90) {
        this.masked = false;
      }
    },
  },
  mounted() {
    BaseTextField.mounted.bind(this)();
  },
};
</script>
