export const currency = {
  methods: {
    currency(value, locale) {
      if (value || value === 0) {
        return this.$n(value >= 0 ? value : -value, 'currency', locale || 'en-US');
      }
      return null;
    },
  },
};

export const percentage = {
  methods: {
    percentage(value) {
      return `${Math.round((value * 100) + Number.EPSILON) / 100}%`;
    },
  },
};
