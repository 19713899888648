import Dashboard from './Dashboard.vue';

export default {
  name: 'Dashboard',
  path: 'dashboard',
  props: true,
  component: Dashboard,
  meta: {
    pageTitle: 'Landing.Dashboard',
  },
};
