<i18n src='./locales.json'></i18n>
<template>
  <v-layout class="no-investments bottom align-center" row wrap>
    <v-flex xs4 md2 pl-4 class="icon">
      <img
        class="icon"
        role="presentation"
        :alt="$t('L_how_soon_could_you')"
        src="../../../assets/debt-calculator.svg"
      >
    </v-flex>
    <v-flex xs8 md5>
      <h4
        class="heading_four pa-0 font-weight-bold"
        :aria-label="$t('L_how_soon_could_you')"
      >
        {{ $t('L_how_soon_could_you_be_debtfree') }}
      </h4>
      <div class="font-size--14">
        {{ $t('L_use_debt_stacking_to_help') }}
      </div>
    </v-flex>
    <v-flex xs12 md5 class="create-your-plan">
      <v-btn
        block
        outline
        color="#063562"
        :aria-label="$t('L_create_your_plan')"
        @click="updateDebts"
      >
        {{ $t('L_create_your_plan') }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'DebtSolutionsV03BottomSingle',
  methods: {
    updateDebts() {
      if (this.$route.path !== '/debtsolutions/mydebt') {
        this.$router.push({ name: 'MyDebt' });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
  .create-your-plan
    padding: 0 15px;
</style>
