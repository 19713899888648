<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      xs12
      sm8
      md7
    >
      <div
        class="heading_four font-weight-medium"
      >
        {{ heading }}
      </div>
    </v-flex>
    <v-flex
      xs12
      sm3
      offset-sm1
      offset-md2
    >
      <CurrencyDisplay
        v-model="amount"
        :max-length="6"
        @input="$emit('input', $event)"
        :label-id="'Monthly income needed'"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Slider from '@/components/Inputs/Slider/Slider.vue';
import CurrencyDisplay from '../../CurrencyDisplay.vue';

export default {
  name: 'MonthlyIncome',
  data: () => ({
    amount: 0,
  }),
  props: {
    heading: {
      type: String,
      required: true,
      default: '',
    },
    value: {
      type: [Number, String],
      required: true,
    },
    formInput: {
      type: Object,
      required: true,
    },
  },
  components: {
    Slider,
    CurrencyDisplay,
  },
  watch: {
    value(value) {
      this.amount = value;
    },
  },
  mounted() {
    this.amount = this.value;
  },
};
</script>


<style lang="stylus" scoped>

/deep/ .v-text-field input {
  padding: 0px !important;
}
</style>
