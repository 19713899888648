import { App } from '@capacitor/app';
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import globalBeforeEachGuard from './navGuards/globalBeforeEachGuard';
import globalAfterEachHook from './navGuards/globalAfterEachHook';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: document?.getElementById('base_href')?.getAttribute('href'),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

/*
For the full navigation resolution flow
see https://v3.router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
*/

router.beforeEach(globalBeforeEachGuard);

router.afterEach(globalAfterEachHook(router));

// * Listen for url open events for the app and handle deep link
App.addListener('appUrlOpen', (event) => {
  // see https://capacitorjs.com/docs/apis/app#urlopenlistenerevent
  const { url } = event; // The URL the app was opened with.

  // get the url path
  const slug = url.split('com.primerica.myprimerica').pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  } else {
    router.push({ name: 'Dashboard' });
  }
});

export default router;
