import { render, staticRenderFns } from "./PasswordComplexity.vue?vue&type=template&id=00a7d570&scoped=true"
import script from "./PasswordComplexity.vue?vue&type=script&lang=js"
export * from "./PasswordComplexity.vue?vue&type=script&lang=js"
import style0 from "./PasswordComplexity.vue?vue&type=style&index=0&id=00a7d570&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a7d570",
  null
  
)

/* custom blocks */
import block0 from "../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FPasswordForm%2Fcomponents%2FPasswordComplexity%2FPasswordComplexity.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports