<i18n src='../locales.json'></i18n>

<template>
  <v-flex
    xs12
    sm6
    md6
    class="mt8 pa8"
  >
    <CardTemplate
      :class="flagActive ? 'enabled-card':'disabled-card'"
    >
      <img
        slot="icon"
        class="account-img"
        src="../assets/manage-trusted.svg"
        alt=""
        role="presentation"
      >
      <span
        slot="title"
        v-html="$t('L_manage_device')"
      />
      <a
        v-ada-modal
        role="button"
        :disabled="!flagActive"
        href="javascript: void(0)"
        slot="action"
        id="update_device_trigger"
        :aria-label="$t('L_manage_device')"
      >{{ $t('L_edit') }}</a>
    </CardTemplate>
    <MyAccountDialog
      :header="showRemoveDevices ? $t('L_remove_all_trusted_devices') : $t('L_review_trusted_devices')"
      :show="showDevicesDialog"
      :show-save-button="false"
      :show-close-button="false"
      :max-width="520"
      :close-is-active="false"
      save-changes-btn-id="save_updated_device"
      @close="closeDialog"
      close-btn-id="close_save_device"
      close-btn-class="update_device_close"
      modal-class="update_device_modal"
    >
      <v-container
        id="scroll-target"
        slot="content"
        style="max-height: 400px; overflow-y: auto;"
        class="mb-3 clickable"
      >
        <RemoveDevices
          v-if="showRemoveDevices"
          @close="closeDialog"
          @delete="removeAllDevices"
          :devices-deleted="devicesDeleted"
        />
        <v-layout
          v-else
          wrap
        >
          <v-flex xs12>
            <p>
              {{ $t('L_there_are_currently_no_trusted_devices') }}
            </p>
            <p>
              {{ $t('L_if_you_would_like_to_save_device') }}
            </p>
          </v-flex>
          <v-layout
            :class="$vuetify.breakpoint.xsOnly ? 'fixed--bottom': ''"
            wrap
            justify-center
          >
            <v-flex
              sm10
              xs12
              mb-1
              mt-2
            >
              <v-btn
                color="secondary"
                block
                class="mb-1"
                @click="closeDialog"
              >
                {{ $t('L_close') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </MyAccountDialog>
  </v-flex>
</template>

<script>
import bff from '@/shared/api/bff';
import { mapGetters } from 'vuex';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';
import RemoveDevices from './components/RemoveDevices.vue';

export function showDialog() { this.showDevicesDialog = true; }

export default {
  name: 'UpdateDevice',
  data: () => ({
    showDevicesDialog: false,
    closeIsActive: true,
    devices: [],
    devicesDeleted: false,
  }),
  components: {
    RemoveDevices,
    CardTemplate,
    MyAccountDialog,
  },
  computed: {
    ...mapGetters({
      containsFeature: 'containsFeature',
    }),
    flagActive() {
      return this.containsFeature('ACCT-DEVICE');
    },
    showRemoveDevices() {
      return this.devices?.length > 0 || (this.devices?.length === 0 && this.devicesDeleted === true);
    },
  },
  methods: {
    async removeAllDevices() {
      /*
        * Emit a custom action when a user successfully submits an update trusted device request
        * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
      */
      const i = this.$dynatrace.enterAction('account_settings_changes_saved_trusted_devices');
      await bff.deleteTrustedDevices();
      this.$dynatrace.leaveAction(i);
      this.devices = [];
      this.devicesDeleted = true;
    },
    closeDialog() {
      this.showDevicesDialog = false;
      this.closeIsActive = false;
      setTimeout(() => {
        this.devicesDeleted = false;
      }, 500);
    },
  },
  async mounted() {
    const { data } = await bff.getTrustedDevices();
    this.devices = data.devices;
    const clickTarget = this.$el.querySelector('.clickable');
    if (this.flagActive && clickTarget) {
      clickTarget.addEventListener('click', showDialog.bind(this));
    }
  },
};
</script>

<style lang="stylus" scoped>
#scroll-target
    margin-top: -50px;
.fixed--bottom
  position: fixed;
  bottom: 16px;
  width: 75%;
a[disabled="disabled"]
  color: gray !important
  cursor default
.show-hover
  .layout
    cursor pointer
  &:hover
    background-color #dcdcdc
</style>
