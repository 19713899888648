<i18n src="../../locales.json"></i18n>
<template>
  <div>
    <v-divider />
    <v-layout
      class="justify-center"
    >
      <v-flex
        xs11
      >
        <v-card class="card-ach-wrapper">
          <v-card-title
            class="justify-space-around text-xs-center"
          >
            <h2 class="heading_two">
              {{ $t('L_payment_completed') }}
            </h2>
          </v-card-title>
          <v-card-text
            class="background-light-grey pl-4"
          >
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_payment_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ paymentType }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ accountType }}</span>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              mb-4
            >
              <!-- <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_holder_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ accountHolder }}</span>
                </div>
              </v-flex> -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ accountNumber | hideNums }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_routing_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ routingNumber | hideNums }}</span>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              mb-4
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankName }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                mt-3
              >
                <v-flex
                  class="text-sm-right text-xs-center"
                >
                  <v-layout
                    row
                    wrap
                    class="text-xs-center justify-center"
                  >
                    <v-flex
                      xs12
                      class="text-xs-center mb-4"
                    >
                      <span>{{ $t('L_would_you_like_to_setup_monthly_premium_payments') }}</span>
                    </v-flex>
                    <v-flex
                      xs5
                      sm2
                      :class="$vuetify.breakpoint.smAndUp ? 'mr-4':''"
                    >
                      <v-btn
                        id="ACH_monthly_setup_no"
                        block
                        class="mb-3"
                        color="secondary"
                        @click="cancelAll"
                      >
                        {{ $t('L_no') }}
                      </v-btn>
                    </v-flex>
                    <v-flex
                      xs5
                      sm2
                    >
                      <v-btn
                        id="ACH_monthly_setup_yes"
                        block
                        class="white--text mb-3"
                        @click="updateshowPayNowInfoCard"
                        color="primary"
                      >
                        {{ $t('L_yes') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                    class="text-xs-center justify-center"
                    v-if="showBankAccountEdit && diffAccount"
                  >
                    <v-flex
                      xs12
                      class="text-xs-center mb-4"
                    >
                      <span>{{ $t('L_would_you_like_to_update_your_bank_info') }}</span>
                    </v-flex>
                    <v-flex
                      xs5
                      sm2
                      :class="$vuetify.breakpoint.smAndUp ? 'mr-4':''"
                    >
                      <v-btn
                        id="direct_setup_no"
                        block
                        class="mb-3"
                        color="secondary"
                        @click="cancelAll"
                      >
                        {{ $t('L_no') }}
                      </v-btn>
                    </v-flex>
                    <v-flex
                      xs5
                      sm2
                    >
                      <v-btn
                        id="update_bank_info"
                        block
                        class="white--text mb-3"
                        @click="updateshowPayNowInfoCard"
                        color="primary"
                      >
                        {{ $t('L_yes') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                    class="text-xs-center justify-center"
                    v-if="!showBankAccountEdit"
                  >
                    <v-flex
                      xs5
                      sm2
                      :class="$vuetify.breakpoint.smAndUp ? 'mr-4':''"
                    >
                      <v-btn
                        id="btwo_same_account"
                        block
                        class="mb-3"
                        color="secondary"
                        @click="cancelAll"
                      >
                        {{ $t('L_close') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { filters } from '@/mixins/filters';

export const giveDate = (date, isCanada) => {
  if (date.length !== 6) {
    return;
  }
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  const year = `20${date.slice(4, 6)}`;
  // eslint-disable-next-line consistent-return
  return isCanada ? `${year}/${month}/${day}` : `${month}/${day}/${year}`;
};

export default {
  name: 'AchStepThree',
  mixins: [filters],
  props: {
    policyData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['paymentData', 'currentPolicy']),
    isCanadaPolicy() {
      return this.policyData.isCanadaPolicy;
    },
    nextDraftDate() {
      return this.policyData?.paymentInfo?._nextCycleDate ? giveDate(this.policyData?.paymentInfo?._nextCycleDate, false) : '';
    },
    paymentType() {
      return this?.paymentData?.paymentType;
    },
    accountType() {
      return this?.paymentData?.accountType;
    },
    accountHolder() {
      return this?.paymentData?.accountHolder;
    },
    routingNumber() {
      return this?.paymentData?.routingNumber || '';
    },
    accountNumber() {
      return this?.paymentData?.accountNumber || '';
    },
    bankName() {
      return this?.paymentData?.bankName;
    },
    userEmail() {
      return this?.currentPolicy?._owner?._email || this.customerProfile.email;
    },
    showPayMethodEdit() {
      return this?.currentPolicy?.isA1BillingForm && this.$store.getters?.containsFeature('PAY-EDIT');
      // return this?.currentPolicy?.isA1BillingForm && this.$store.state?.features?.has('pc2.pay.edit');
    },
    showBankAccountEdit() {
      return this?.currentPolicy?.isB2BillingForm && this.$store.getters?.containsFeature('BANKACCOUNT-EDIT') && this.paymentData?.hadNSF;
      // return this?.currentPolicy?.isB2BillingForm && this.$store.state?.features?.has('pc2.bankaccount.edit') && this.paymentData?.hadNSF;
    },
    diffAccount() {
      return this.paymentData?.differentAccount;
    },
  },
  methods: {
    ...mapMutations({
      setUpdatingBank: 'policy/setUpdatingBank',
      removeAlert: 'removeAlert',
    }),
    checked() {
      if (this.accepted) {
        this.showSuccessBanner();
      } else {
        this.showErrorBanner();
      }
    },
    showSuccessBanner() {
      this.$store.commit('addAlert', {
        type: 'success',
        details: { message: this.$t('L_ach_payment_success', { email: this.userEmail }) },
        context: 'payment',
        autoDismiss: false,
      });
    },
    cancelAll() {
      // this.removeAlert('payment');
      this.$emit('cancel');
    },
    goToStepOne() {
      this.$emit('goToStepOne');
    },
    updateshowPayNowInfoCard() {
      this.$store.commit('policy/setshowPayNowInfoCard', false);
      this.$store.commit('policy/clearBank');
      this.setUpdatingBank(true);
    },
  },
  mounted() {
    this.showSuccessBanner();
  },
};
</script>

<style scoped lang="stylus">
  .grey-ada-text
    color: #737171
  .disclaimer-wrapper
    overflow-y: scroll;
    max-height: 263px;
    min-height: 250px;
    border-radius: 2px;
    border: solid 1px #979797;
    padding: 20px;
  .card-ach-wrapper
    position: relative;
  .fixed-top-sm-message-banner
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    z-index: 1;
</style>
