<i18n src="./locales.json"></i18n>
<template>
  <v-layout row wrap class="white--text">
    <v-dialog v-model="dialog" max-width="500" :persistent="persistent">
      <v-card class="orangeTop">
        <v-card-title>
          <v-icon class="warning-icon warning--text mr-3">
            warning
          </v-icon>
          <span class="headline fontWeight600">{{ $t('L_remove_beneficiary') }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <i18n path="L_remove_beneficiary_are_you_sure_you_want" tag="p" class="font-weight-regular">
              <span>{{ fullName }}</span>
            </i18n>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-layout>
            <v-flex>
              <v-btn color="#02579e" flat="flat" @click="deleteBeneficiary()">
                {{ $t('L_yes_remove') }}
              </v-btn>
            </v-flex>
            <v-flex>
              <v-btn color="#02579e" flat="flat" @click="$emit('hide-warning')">
                {{ $t('L_cancel') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'WarningDialogBeneficiary',
  data: () => ({
    dialog: true,
    persistent: true,
  }),
  props: {
    deleteBeneficiary: {
      type: Function,
      default: () => undefined,
    },
    fullName: {
      type: String,
      default: () => '',
    },
  },
};
</script>
<style lang="stylus" scoped>
 .close{
  float: right;
}
.orangeTop{
  border-top: 7px solid #ee7026;
}
.fontWeight600{
  font-weight: 600;
}
</style>
