<i18n src="../../locales.json"></i18n>
<i18n src="../../components/UpdateBeneficiaries/locales.json"></i18n>
<template>
  <v-card id="beneficiary-card" class="beneficiary-card">
    <v-card-title>
      <v-layout row wrap>
        <v-flex xs6>
          <span role="heading" aria-level="2" class="card-title">
            {{ $t('L_policy_beneficiaries') }}
          </span>
          <!-- <v-icon tabindex="0" color="#02579E" class="pri-inline-icon pl-1 cursor-pointer" size="x-large" style="margin-bottom: -3px;" aria-label="beneficiary information" @click="helpAction">
            help
          </v-icon> -->
        </v-flex>
        <v-flex xs6 v-if="showUpdateBeneficiary" class="text-xs-right">
          <v-btn color="primary font-size--16" @click="showUpdateBeneficiaries">
            <span v-if="$vuetify.breakpoint.xsOnly">{{ $t('L_update') }}</span>
            <span v-else>{{ $t('L_update_beneficiaries') }}</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-title class="pt-0">
      <v-layout row wrap>
        <v-flex xs12 v-if="dateSigned !== ''" class="cssDateSigned">
          <i18n path="L_last_updated_on" tag="p" class="font-weight-bold font-size--24">
            <span>{{ dateSigned }}</span>
          </i18n>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-divider />
    <v-layout class="font-size--14" row wrap v-if="displayBeneficiaries">
      <v-flex xs12 sm6 md6 lg6>
        <!-- Primary Insured -->
        <v-card-text v-if="pPrimaryBeneficiaries.length > 0">
          <v-layout row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs12 sm11 md11 lg11 text-xs-left>
              <span class="insured-name-label">
                {{ $t('L_policy_insured') }}
              </span>
              <span class="insured-name">
                {{ primaryInsured }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- Primary Primary Beneficiaries -->
        <v-card-text v-if="pPrimaryBeneficiaries.length > 0">
          <v-layout class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="Primary">
                {{ $t('L_policy_beneficiaries_primary') }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="Allocation" v-if="displayPrimaryAllocation">
                {{ $t('L_policy_beneficiaries_allocation') }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout v-for="(beneficiary, index) in pPrimaryBeneficiaries" :key="index" class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="BeneficiaryName">
                {{ getFullNameForDisplayWithLocaleBC(beneficiary.fullName) }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="BeneficiaryName" v-if="beneficiary.origin === 'A' || beneficiary.origin === 'C'"> {{ beneficiary.beneficiaryPercent }} % </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- Primary Contingent Beneficiaries -->
        <v-card-text v-if="pContingentBeneficiaries.length > 0">
          <v-layout class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="Contingent">
                {{ $t('L_policy_beneficiaries_contingent') }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="BeneficiaryName" />
            </v-flex>
          </v-layout>
          <v-layout v-for="(beneficiary, index) in pContingentBeneficiaries" :key="index" class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="BeneficiaryName">
                {{ getFullNameForDisplayWithLocaleBC(beneficiary.fullName) }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="BeneficiaryName" v-if="beneficiary.origin === 'A' || beneficiary.origin === 'C'"> {{ beneficiary.beneficiaryPercent }} % </span>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6>
        <!-- Spouse Insured -->
        <v-card-text v-if="sPrimaryBeneficiaries.length > 0">
          <v-layout row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs12 sm11 md11 lg11 text-xs-left>
              <span class="insured-name-label">
                {{ $t('L_policy_spouse_insured') }}
              </span>
              <span class="insured-name">
                {{ spouse }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- Spouse Primary Beneficiaries -->
        <v-card-text v-if="sPrimaryBeneficiaries.length > 0">
          <v-layout class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="Primary">
                {{ $t('L_policy_beneficiaries_primary') }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="Allocation" v-if="displaySpouseAllocation">
                {{ $t('L_policy_beneficiaries_allocation') }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout v-for="(beneficiary, index) in sPrimaryBeneficiaries" :key="index" class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="BeneficiaryName">
                {{ getFullNameForDisplayWithLocaleBC(beneficiary.fullName) }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="BeneficiaryName" v-if="beneficiary.origin === 'A' || beneficiary.origin === 'C'"> {{ beneficiary.beneficiaryPercent }} % </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- Spouse Contingent Beneficiaries -->
        <v-card-text v-if="sContingentBeneficiaries.length > 0">
          <v-layout class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="Contingent">
                {{ $t('L_policy_beneficiaries_contingent') }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="BeneficiaryName" />
            </v-flex>
          </v-layout>
          <v-layout v-for="(beneficiary, index) in sContingentBeneficiaries" :key="index" class="font-size--14" row wrap>
            <v-flex xs0 sm1 md1 lg1 />
            <v-flex xs6 sm6 md6 lg6 text-xs-left>
              <span class="BeneficiaryName">
                {{ getFullNameForDisplayWithLocaleBC(beneficiary.fullName) }}
              </span>
            </v-flex>
            <v-flex xs6 sm5 md5 lg5 text-xs-center>
              <span class="BeneficiaryName" v-if="beneficiary.origin === 'A' || beneficiary.origin === 'C'"> {{ beneficiary.beneficiaryPercent }} % </span>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-flex>
    </v-layout>
    <!-- When Update Beneficiaries button is clicked and there's beneficiaryHasOriginVorTWithIrrevocable-->
    <v-dialog v-model="showImportantInformationDialog" max-width="700" :persistent="true" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-layout row wrap>
          <v-flex class="bluebar">
            &nbsp;
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 text-xs-right mt-2 mr-2 mb-4 font-size--18>
            <v-icon @click="showImportantInformationDialog = false" color="#000000">
              close
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout row wrap ml-4 pl-2 pr-2 mr-4>
          <v-flex font-size--18 font-weight-bold xs12 text-xs-center mb-4>
            <v-icon color="#3399ff">
              info
            </v-icon>
            {{ $t('L_important_information_about_irrevocable_beneficiary_change') }}
          </v-flex>
          <v-flex xs12 font-size--16 text-xs-left mb-4>
            {{ $t('L_beneficiary_changes_cannot_be_completed_online_because') }}
          </v-flex>
          <v-flex xs12 font-size--16 text-xs-left>
            <i18n path="L_please_request_a_paper_beneficiary_designation_form" tag="p" class="font-weight-regular">
              <span>{{ customerServicePhoneNum }}</span>
            </i18n>
          </v-flex>
          <v-flex xs12 mt-2 mb-4>
            <v-card-actions class="justify-center">
              <v-flex xs8 text-xs-center>
                <v-btn
                  id="closeErrorDialogShownForSubmit"
                  class="pl-4 pr-4 "
                  :class="$vuetify.breakpoint.xsOnly ? 'buttonFormat_mobile' : 'buttonFormat'"
                  color="primary"
                  @click="closeDialogForVorTirrevocable"
                >
                  <h3 class="heading_three font-weight-bold font-size--18">
                    {{ $t('L_ok_got_it') }}
                  </h3>
                </v-btn>
              </v-flex>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { FEATURE_FLAGS } from '@/shared/constants';
import { openInNewWindow } from '@/mixins/openNewWindow';
import bff from '@/shared/api/bff';
import moment from 'moment';

export default {
  name: 'BeneficiaryCard',
  mixins: [openInNewWindow],
  data: () => ({
    showImportantInformationDialog: false,
  }),
  methods: {
    ...mapActions('policy', ['getBeneficiaries', 'setBeneficiaryStep']),
    ...mapMutations('policy', ['setBeneficiaryDataDirty']),
    async helpAction() {
      const helpUrl = await bff.getRightAnswersHelpUrl();
      const url = 'https://primerica.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=210617092059223&';
      // Current data returned as we are grabbing everything after ss? and appending to our url above which should end in '&'
      //  "URL":"https://primericastg.rightanswers.com/portal/ss?companycode=primerica&tabid=2&authID=primericaJwt&group=Client Portal&jwtoken=eyJ0eXAiOiJKV1QiLA0KICJhbGc"
      const finalDestination = encodeURI(url + helpUrl?.data?.URL.split('ss?')[1]);
      // TODO: use openInNewWindow mixin
      const win2 = await window.open(helpUrl?.data?.URL, 'RADashboardWinName');
      win2.location.href = finalDestination;
    },
    getFullNameForDisplayWithLocaleBC(fullName) {
      let retFullName = fullName;
      if (this.locale === 'fr_CA' || this.locale === 'es_US') {
        if (fullName === 'Estate') {
          retFullName = this.$t('L_estate');
        } else if (fullName === 'All Children Equally') {
          retFullName = this.$t('L_all_children_equally');
        } else {
          return retFullName;
        }
      }
      return retFullName;
    },
    showUpdateBeneficiaries() {
      try {
        const i = this.$dynatrace.enterAction('updateBeneficiaries_start');
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }

      if (this.beneficiaryHasOriginVorTWithIrrevocable) {
        this.showImportantInformationDialog = true;
      } else {
        this.setBeneficiaryDataDirty(false);
        this.setBeneficiaryStep(1);
      }
    },
    closeDialogForVorTirrevocable() {
      // If irrevocable they cannot proceed
      if (this.beneficiaryHasOriginVorTWithIrrevocable) {
        this.showImportantInformationDialog = false;
      }
      // else {
      // if no irrevocable then they to can update the beneficiary
      //   this.showImportantInformationDialog = false;
      //   this.setBeneficiaryStep(1);
      // }
    },
  },
  computed: {
    ...mapGetters({ locale: 'getLocale' }),
    ...mapGetters('policy', {
      isPending: 'isPending',
    }),
    ...mapGetters({
      policy: 'policy/currentPolicy',
      beneficiaries: 'policy/beneficiaries',
      beneficiaryHasOriginVorTWithIrrevocable: 'policy/beneficiaryHasOriginVorTWithIrrevocable',
      beneficiaryHasOriginVorT: 'policy/beneficiaryHasOriginVorT',
    }),
    ...mapGetters(['containsFeature', 'features']),
    dateSigned() {
      if (this.beneficiaries.length > 0) {
        const sdate = this.beneficiaries[0].dateSigned;
        if (sdate === null || sdate === '') {
          return '';
        }
        const ddate = moment(sdate, 'YYYY-MM-DD');
        if (this.locale === 'fr_CA') {
          return moment(ddate).format('DD/MM/YYYY');
        }
        return moment(ddate).format('MM/DD/YYYY');
      } return '';
    },
    customerServicePhoneNum() {
      if (this.locale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.locale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.locale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
    showUpdateBeneficiary() {
      return !this.isPending && this.containsFeature(FEATURE_FLAGS.BENEFICIARIES_EDIT) && this.policy.allowEditBeneficiary;
    },
    displayBeneficiaries() {
      return this.containsFeature(FEATURE_FLAGS.BENEFICIARIES) && this.beneficiaries && this.beneficiaries?.length > 0;
    },
    pPrimaryBeneficiaries() {
      return this.beneficiaries?.filter(beneficiary => beneficiary.beneficiaryType === 'P');
    },
    pContingentBeneficiaries() {
      return this.beneficiaries?.filter(beneficiary => beneficiary.beneficiaryType === 'C');
    },
    sPrimaryBeneficiaries() {
      return this.beneficiaries?.filter(beneficiary => beneficiary.beneficiaryType === 'S');
    },
    sContingentBeneficiaries() {
      return this.beneficiaries?.filter(beneficiary => beneficiary.beneficiaryType === 'H');
    },
    primaryInsured() {
      return `${this.policy?.primaryInsured?.firstName} ${this.policy?.primaryInsured?.lastName}` || '';
    },
    spouse() {
      if (this.policy?.data?.spouseLastName) {
        return `${this.policy?.data?.spouseFirstName} ${this.policy?.data?.spouseLastName}`;
      }
      return this.$t('L_policy_beneficiaries_spouse');
    },
    displaySpouseAllocation() {
      let display = false;
      // eslint-disable-next-line
      this.sPrimaryBeneficiaries?.forEach(bene => {
        if (bene.origin === 'A' || bene.origin === 'C') {
          display = true;
        }
      });
      // eslint-disable-next-line
      this.sContingentBeneficiaries?.forEach(bene => {
        if (bene.origin === 'A' || bene.origin === 'C') {
          display = true;
        }
      });
      return display;
    },
    displayPrimaryAllocation() {
      let display = false;
      // eslint-disable-next-line
      this.pPrimaryBeneficiaries?.forEach(bene => {
        if (bene.origin === 'A' || bene.origin === 'C') {
          display = true;
        }
      });
      // eslint-disable-next-line
      this.pContingentBeneficiaries?.forEach(bene => {
        if (bene.origin === 'A' || bene.origin === 'C') {
          display = true;
        }
      });
      return display;
    },
  },
  async mounted() {
    if (this.policy.contractId > 0) {
      await this.getBeneficiaries(this.policy.contractId);
    }
  },
  watch: {
    policy(newData, oldData) {
      if (newData !== oldData && this.policy.contractId > 0) {
        this.getBeneficiaries(this.policy.contractId);
      }
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'
.cssDateSigned
  font-weight: 600;
  zpadding-left: 15px;
.bluebar
  border-top: 8px solid #3399ff;
#beneficiary-card
  .move-right
    float: right;
  .effective-as-of
    width: 160px;
    height: 30px;
    margin: 8px 148px 16px 20px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 2.14;
    letter-spacing: normal;
    color: #424242;
  .insured-name-label
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
  .insured-name
    width: 109px;
    height: 24px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
  .Primary
    width: 62px;
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
  .Contingent
    width: 86px;
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
  .Allocation
    width: 96px;
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
  .BeneficiaryName
    width: 105px;
    height: 30px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #424242;
</style>
