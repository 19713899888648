<i18n src="./locales.json"></i18n>
<template>
  <v-layout
    row
    wrap
    class="white--text"
  >
    <v-dialog v-model="dialog" max-width="500" :persistent="persistent">
      <v-card class="orangeTop">
        <v-card-title>
          <v-icon class="warning-icon warning--text mr-3">
            warning
          </v-icon>
          <span class="font-size--20 fontWeight600">{{ $t('L_are_you_sure_you_want_to_leave_this_page') }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout>
            {{ $t(getNavigationDialogBody) }}
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-layout>
            <v-flex>
              <v-btn color="#02579e" flat="flat" @click="funcRouteAway">
                {{ $t('L_yes_leave') }}
              </v-btn>
            </v-flex>
            <v-flex>
              <v-btn color="#02579e" flat="flat" @click="funcStayOnPage">
                {{ $t('L_stay_on_page') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'NavigationConfirmation',
  data: () => ({
    dialog: true,
    persistent: true,
  }),
  computed: {
    ...mapGetters(['getNavigationDialogBody', 'getNavigationPathOnUserConfirmation']),
    ...mapGetters('policy', ['beneficiaryStep', 'paymentStep', 'withDrawalDateStep']),
  },
  methods: {
    ...mapActions('policy', ['setBeneficiaryStep', 'setPaymentStep', 'setWithDrawalDateStep']),
    ...mapMutations(['updateShowNavigationConfirmation']),

    funcRouteAway() {
      // First we are defaulting state for Beneficiary Navigation to -1 so that it won't go back to
      // confirm dialog again
      // This is a custom one for beneficiary
      if (this.beneficiaryStep !== -1) {
        this.setBeneficiaryStep(-1);
      }
      if (this.paymentStep !== -1) {
        this.$store.dispatch('policy/getPolicyOverview');
        // Setting -2 here and 3 in the BootStrapGuard so that it is not refreshing page to hide Payment Wizard and showing Policy Page as they both are on same page.
        this.setPaymentStep(-2);
      }
      if (this.withDrawalDateStep !== -1) {
        this.setWithDrawalDateStep(-1);
      }
      // This is common code for all
      if (this.getNavigationPathOnUserConfirmation?.name !== '' && this.getNavigationPathOnUserConfirmation?.name !== undefined) {
        this.$router.push({ name: this.getNavigationPathOnUserConfirmation.name });
      } else if (this.getNavigationPathOnUserConfirmation?.path !== '' && this.getNavigationPathOnUserConfirmation?.path !== undefined) {
        this.$router.push({ path: this.getNavigationPathOnUserConfirmation.path });
      }
      this.$store.commit('updateShowNavigationConfirmation', false);
      // This is to not display Pay Now if user goes to Policy Details
      this.setPaymentStep(-1);
    },
    funcStayOnPage() {
      this.$store.commit('updateShowNavigationConfirmation', false);
    },
  },
};
</script>
<style lang="stylus" scoped>
   .close{
    float: right;
  }
  .orangeTop{
    border-top: 7px solid #ee7026;
  }
  .fontWeight600{
    font-weight: 600;
  }
</style>

