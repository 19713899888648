import axios from 'axios';
import Config from '@/shared/util/config';
import { CapacitorFacade } from '@/plugins/capacitorPlugin';

export default {
  // TODO: rename function
  /**
   * The function `streamDocument` asynchronously retrieves a document based on the provided metadata,
   * handling different endpoints for web and non-web environments.
   * @param documentMetadata - The `streamDocument` function is an asynchronous function that takes in
   * a `documentMetadata` object as a parameter. The `documentMetadata` object likely contains
   * information about a document such as its object code, type, and other relevant details needed to
   * retrieve the document.
   * @returns The `streamDocument` function returns the data received from the POST request made using
   * Axios.
   */
  async streamDocument(documentMetadata) {
    const isWeb = CapacitorFacade.isWeb();
    let response;

    if (isWeb) {
      response = await axios.post(
        `${Config.get('endpoints.cm')}/content${documentMetadata.objectCode ? '/documents' : '/policy/documents/all'}`,
        documentMetadata,
        {
          headers: {
            accept: 'application/pdf',
          },
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        },
      );
    } else {
      response = await axios.post(
        `${Config.get('endpoints.bff')}/document-links`,
        documentMetadata,
      );
    }
    return response.data;
  },
};
