// Import external lib here like axios...
import bff from '@/shared/api/bff';
import content from '@/shared/api/content';
import { checkKubraPayment } from '../../../shared/util/kubra';
import customerState from '../../../store/customer/state';

const STEP = {
  READY: 0,
  ADD_A_CHILD: 50,
};

export const setToReady = async ({ commit }) => {
  commit('setStep', STEP.READY);
};

export const startAddAChild = async ({ commit }) => {
  commit('setStep', STEP.ADD_A_CHILD);
};
// Do not call loadPaymentInfo directly.  Instead call getPolicyOverview
export const loadPaymentInfo = async (state, dispatch, commit) => {
  try {
    const startingSelectedPolicyIndex = state?.selectedPolicyIndex || 0;
    commit('setPaymentPanel', state.policies);
    commit('setPaymentInfoLoading', true);
    let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (i; i < state.policies.length; i++) {
      // Following is being done to set the policy so that getPaymentInfo can fetch the data for right one
      commit('setSelectedPolicyIndex', i);
      // eslint-disable-next-line no-await-in-loop
      await dispatch('getPaymentInfo', state.policies[i].contractId);
    }
    // Once everything is done we go back to the policy that was being used.
    await commit('setSelectedPolicyIndex', startingSelectedPolicyIndex);
    // await dispatch('getPaymentInfo', state.policies[state.selectedPolicyIndex].contractId);
    // Object.values(state.policies).forEach(policy => await dispatch('getPaymentInfo', policy.contractId));
    if (window.location.pathname.indexOf('paynow') !== -1) {
      commit('setPanelValue', 0);
    }
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  } finally {
    commit('setPaymentInfoLoading', false);
  }
};

export const getPolicies = async ({ commit, rootGetters }) => {
  try {
    const response = await bff.getPolicyOverview(customerState.customerProfile.policyIds);
    if (response.status === 200) {
      commit('setPolicies', response.data?.policies || []);
      commit('setNavHintDismissed', response.data?.preferences?.navHint);
      commit('setHasTerminatedPolicies', response.data?.hasTerminatedPolicies);
      commit('setTerminatedPolicyAgent', response.data?.terminatedPolicyAgent);
      // Get agents for policies
      if (response.data?.policies?.length > 0) {
        const ids = [];
        response.data.policies.forEach((a) => {
          if (a.agentId && !rootGetters.getAgent(a.agentId) && !ids.includes(a.agentId)) {
            ids.push(a.agentId);
          }
        });
        if (ids.length > 0) {
          const agents = await bff.getAgents(ids);
          commit('addAgents', agents, { root: true });
        }
      }
    }
    return response.status;
  } catch (error) {
    throw error;
  }
};

export async function getPolicyOverview({ commit, state, dispatch }, refresh = false) {
  if (state.policies.length !== 0 && state.policies[0]?.contractId !== 0 && !refresh) {
    await loadPaymentInfo(state, dispatch, commit);
    return;
  }
  try {
    await commit('setPolicyInfoLoading', true);
    const responseStatus = await dispatch('getPolicies');
    if (responseStatus === 200) {
      if (state.policies.length !== 0) {
        await loadPaymentInfo(state, dispatch, commit);
        if (state.policies[state.selectedPolicyIndex]?._kubraPaymentStatus) {
          checkKubraPayment();
        }
      }
    }
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  } finally {
    commit('setPolicyInfoLoading', false);
  }
}

export const changePolicy = ({ commit, state, dispatch }, { selectedIndex }) => {
  if (selectedIndex >= 0 && selectedIndex < state.policies.length) {
    commit('setSelectedPolicyIndex', selectedIndex);
    commit('setDisablePaymentDetailsLink', false);
    if (state.policies[state.selectedPolicyIndex]) {
      dispatch('getPaymentInfo', state.policies[state.selectedPolicyIndex].contractId);
    }
    // Remove the getAlerts for the payment
    commit('removeAlerts', 'payment');
  }
};

export const nextPolicy = ({ state, dispatch }) => {
  const selectedIndex = state.selectedPolicyIndex + 1;
  if (selectedIndex < state.policies.length) {
    dispatch('changePolicy', { selectedIndex });
  }
};

export const prevPolicy = ({ state, dispatch }) => {
  const selectedIndex = state.selectedPolicyIndex - 1;
  if (selectedIndex >= 0) {
    dispatch('changePolicy', { selectedIndex });
  }
};

export const getContactInfo = (policy, contactInfo) => ({
  policyNum: policy.contractId,
  phone: (typeof (contactInfo?.phone) === 'string') ? contactInfo.phone : policy.owner.address.phone,
  email: (typeof (contactInfo?.email) === 'string') ? contactInfo.email : policy.owner.email,
  address: {
    address1: (typeof (contactInfo?.address1) === 'string') ? contactInfo.address1 : policy.owner.address.address1,
    address2: (typeof (contactInfo?.address2) === 'string') ? contactInfo.address2 : policy.owner.address.address2,
    city: (typeof (contactInfo?.city) === 'string') ? contactInfo.city : policy.owner.address.city,
    state: (typeof (contactInfo?.state) === 'string') ? contactInfo.state : policy.owner.address.state,
    zip: (typeof (contactInfo?.zip) === 'string') ? contactInfo.zip : policy.owner.address.zip,
  },
});

export const getEmailInfo = (policy, contactInfo) => ({
  policyNum: policy.contractId,
  email: (typeof (contactInfo?.email) === 'string') ? contactInfo.email : policy.owner.email,
});

export const verifyContactInfo = async ({ commit, getters, dispatch }, address) => {
  const policy = getters.currentPolicy;
  try {
    const resp = await bff.verifyContactInfo(getContactInfo(policy, address));
    if (resp.data && !resp.data?.addressMatched) {
      commit('setAddressVerification', resp.data);
      commit('setAddressUpdateSuccess', false);
      return;
    }
  } catch (e) {
    commit('setAddressVerification', 'error');
    commit('setAddressUpdateSuccess', false);
    return;
  }
  dispatch('updateContactInfo', address);
};

export const paymentSelection = async ({ getters }, type) => {
  const policyNo = getters.currentPolicy.contractId;
  const res = await bff.paymentSelection({ policyNo, type });
  return res;
};

export const updateContactInfo = async ({ getters, commit, dispatch }, contactInfo) => {
  let policy;
  let bffResponse;
  let errorArray;
  if (contactInfo.updateAll) {
    policy = getters.policies;
  } else {
    policy = getters.currentPolicy;
  }
  try {
    // if (Object.prototype.hasOwnProperty.call(contactInfo, 'address1') || Object.prototype.hasOwnProperty.call(contactInfo, 'phone') || Object.prototype.hasOwnProperty.call(contactInfo, 'email')) {
    const contactObj = [];
    commit('setSuccessResponseUpdateContactInfo', []);
    commit('setErrorResponseUpdateContactInfo', []);
    if (contactInfo.updateAll) {
      for (let i = 0; i < policy.length; i++) {
        contactObj.push(getContactInfo(policy[i], contactInfo));
      }
      errorArray = contactObj.map(policyObj => policyObj.policyNum);
      bffResponse = await bff.updateContactInfo(contactObj);
      // contactObj = getContactInfoInArrayWithPolicy(policy, contactInfo);
    } else {
      contactObj.push(getContactInfo(policy, contactInfo));
      errorArray = contactObj.map(policyObj => policyObj.policyNum);
      bffResponse = await bff.updateContactInfo(contactObj);
    }
    // }
    commit('setSuccessResponseUpdateContactInfo', bffResponse.data.success);
    commit('setErrorResponseUpdateContactInfo', bffResponse.data.error);
    commit('setAddressUpdateSuccess', true);
    commit('setAddressVerification', undefined);
    dispatch('setContactInfo', contactInfo);
  } catch (e) {
    commit('setErrorResponseUpdateContactInfo', errorArray);
    commit('addError', { error: e, context: 'app' }, { root: true });
  }
};

export const setContactInfo = ({ commit }, address) => {
  commit('updateAddress1', address.address1);
  commit('updateAddress2', address.address2);
  commit('updateCity', address.city);
  commit('updateState', address.state);
  commit('updateZip', address.zip);
  commit('updatePhone', address.phone);
  commit('updateEmail', address.email);
};

export const setAddressVerification = ({ commit }, data) => {
  commit('setAddressVerification', data);
};

export const addChildRider = async (_state, childRider) => bff.addChildToRider(childRider);

export const getPaymentInfo = async ({ commit }, policyNumber) => {
  try {
    const response = await bff.getPaymentInfo(policyNumber);
    if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
      commit('setPaymentInfo', response.data);
    }
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  }
};

// ***
// TODO: move this to a common store module based on usage
export const getOnePolicyDocument = async ({ commit }, data) => {
  try {
    // TODO: consider renaming since it returns urls for mobile
    const base64String = await content.streamDocument(data); // this has separate implementations for web and mobile
    commit('setBase64String', base64String);
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  }
};

// ***
// TODO: move this to a common store module based on usage
export const getAllPolicyDocuments = async ({ commit }, data) => {
  try {
    // TODO: consider renaming since it returns urls for mobile
    const base64String = await content.streamDocument(data); // this has separate implementations for web and mobile
    commit('setBase64String', base64String);
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  }
};

export const getBankInfo = async ({ commit }, data) => {
  try {
    const bankData = await bff.getBankData(data);
    commit('setBankName', bankData.data);
    return true;
  } catch (e) {
    commit('addError', { error: e, context: 'payment' }, { root: true });
    return false;
  }
};

export const submitAch = async ({ commit, getters }, { paymentInfo, policyNumber }) => {
  try {
    const originalNextCycleDate = getters.currentPolicy?.paymentInfo?.nextCycleDate;
    const response = await bff.submitAchPayment({ paymentInfo, policyNumber });
    if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
      // This is being done because the backend is returning empty nextCycle
      // TODO Provide long term fix.
      const nextCycleInfo = { NextCycleDate: originalNextCycleDate };
      const newPaymentInfo = {
        ...response.data,
        ...nextCycleInfo,
      };
      commit('setPaymentInfo', newPaymentInfo);
    }
    return true;
  } catch (e) {
    // commit('addError', { error: e, context: 'payment' }, { root: true });
    return false;
  }
};

export const updateBillingMode = async ({ commit }, { paymentInfo, policyNumber }) => {
  try {
    const res = await bff.updateBillingMode({ paymentInfo, policyNumber });
    return res.status === 200 || res.status === 201;
  } catch (e) {
    commit('addError', { error: e, context: 'payment' }, { root: true });
    return false;
  }
};

export const getDraftDescription = async ({ commit }, data) => {
  try {
    const res = await bff.getDraftDescription(data);
    return res.data;
  } catch (e) {
    commit('addError', { error: e, context: 'payment' }, { root: true });
    return false;
  }
};
export const setScrollToPolicyDetails = async ({ commit }, scrollToPolicyDetails) => {
  commit('setScrollToPolicyDetails', scrollToPolicyDetails);
};
export const getBeneficiaries = async ({ commit }, policyNumber) => {
  try {
    const response = await bff.getBeneficiaries(policyNumber);
    commit('setBeneficiaries', response?.data);
    const P = response.data.filter(obj => obj.beneficiaryType === 'P' && (obj.origin === 'A' || obj.origin === 'C'));
    commit('setBeneficiaryPrimary', P);
    const C = response.data.filter(obj => obj.beneficiaryType === 'C' && (obj.origin === 'A' || obj.origin === 'C'));
    commit('setBeneficiaryPrimaryContingent', C);
    const S = response.data.filter(obj => obj.beneficiaryType === 'S' && (obj.origin === 'A' || obj.origin === 'C'));
    commit('setBeneficiarySpouse', S);
    const H = response.data.filter(obj => obj.beneficiaryType === 'H' && (obj.origin === 'A' || obj.origin === 'C'));
    commit('setBeneficiarySpouseContingent', H);
  } catch (e) {
    // commit('addError', { error: e, context: 'app' }, { root: true });
  }
};
export const setBeneficiaries = async ({ commit, getters }) => {
  try {
    const finalPayload = [];
    finalPayload.push(...getters.beneficiaryPrimary);
    finalPayload.push(...getters.beneficiaryPrimaryContingent);
    finalPayload.push(...getters.beneficiarySpouse);
    finalPayload.push(...getters.beneficiarySpouseContingent);
    const response = await bff.setBeneficiaries(getters.currentPolicy?.contractId, finalPayload);
    return response;
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
    return false;
  }
};

export const setMonthlyWithDrawalDay = async ({ commit, getters }, day) => {
  try {
    const response = await bff.setMonthlyWithDrawalDay(getters.currentPolicy?.contractId, day);
    return response;
  } catch (e) {
    commit('addError', { error: e, content: 'app' }, { root: true });
    return false;
  }
};

/*
  -1 not set
  1 Show My Benefits page
  2 Show add/edit Beneficiary in Add mode
  3 Show add/edit Beneficiary in Edit mode
*/
export const setBeneficiaryStep = async ({ commit }, beneficiaryStep) => {
  commit('setBeneficiaryStep', beneficiaryStep);
};

export const setBeneficiaryPrimary = async ({ commit }, beneficiaryPrimary) => {
  commit('setBeneficiaryPrimary', beneficiaryPrimary);
};

export const setBeneficiaryPrimaryContingent = async ({ commit }, beneficiaryPrimaryContingent) => {
  commit('setBeneficiaryPrimaryContingent', beneficiaryPrimaryContingent);
};

export const setBeneficiarySpouse = async ({ commit }, beneficiarySpouse) => {
  commit('setBeneficiarySpouse', beneficiarySpouse);
};

export const setBeneficiarySpouseContingent = async ({ commit }, beneficiarySpouseContingent) => {
  commit('setBeneficiarySpouseContingent', beneficiarySpouseContingent);
};

export const setPaymentStep = async ({ commit }, paymentStep) => {
  commit('setPaymentStep', paymentStep);
};

export const setRouteNameToNavigateBack = async ({ commit }, routeNameToNavigateBack) => {
  commit('setRouteNameToNavigateBack', routeNameToNavigateBack);
};

export const setWithDrawalDateStep = async ({ commit }, withDrawalDateStep) => {
  commit('setWithDrawalDateStep', withDrawalDateStep);
};
