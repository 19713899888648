<i18n src="../locales.json"></i18n>
<template>
  <div :class="$vuetify.breakpoint.xsOnly?'ml-4':''">
    <div :class="$vuetify.breakpoint.xsOnly?'ml-4':''">
      <Alert
        :dismissable="false"
        :message="$t('L_please_enter_your_contact_information_this_information_will_be')"
        alert-icon="info"
        type="info"
        :new-alert-type="true"
      />
    </div>
    <v-layout column class="ml-4 mt-4">
      <v-layout row wrap>
        <v-flex xs12 font-size--16 mb-4 font-weight-bold>
          {{ $t('L_enter_the_primary_insured_information') }}
        </v-flex>
        <v-flex xs12 sm6 md4>
          <div class="font-size--12 font-weight-bold">
            <i18n
              path="L_first_name_email_address"
              tag="span"
            >
              <span class="font-weight-bold">{{ primaryName }}</span>
            </i18n>
          </div>
          <div class="font-size--16">
            {{ primaryEmailAddress }}
          </div>
          <div v-if="isPhoneVerified" class="mt-4 mb-4">
            <div class="font-size--12 font-weight-bold">
              <span
                v-html="
                  $t('L_first_name_mobile_phone', {
                    name: primaryName,
                  })
                "
              />
            </div>
            <div class="font-size--16">
              {{ primaryMobilePhone | phone }}
            </div>
          </div>
          <!-- <EmailInput v-model="primaryEmailAddress" :form-input="$v.primaryEmailAddress" :label="$t('L_first_name_email_address', { name: primaryName })" /> -->
          <PhoneNumberInput v-else class="mt-4" v-model="primaryMobilePhone" :form-input="$v.primaryMobilePhone" :label="$t('L_first_name_mobile_phone', { name: primaryName })" />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4 font-size--16 mb-4 v-if="hasSpouse">
          <div class="font-size--16 mb-4 font-weight-bold">
            {{ $t('L_enter_the_spouse_insured_information') }}
          </div>
          <div class="font-size--16 mb-4 font-weight-regular">
            {{ $t('L_since_a_spouse_insured_is_included_on_this_policy_please_provide_their_email_and_mobile_phone_number') }}
          </div>
          <EmailInput v-model="spouseEmailAddress" :form-input="$v.spouseEmailAddress" :label="$t('L_spouse_first_name_email', { name: spouseName })" />
          <EmailInput
            v-model="spouseVerifyEmailAddress"
            :form-input="$v.spouseVerifyEmailAddress"
            :label="$t('L_verify_spouse_first_name_email', { name: spouseName })"
            :validation-messages="{ notMatchingEmail: $t('L_confirm_email_match'), }"
          />
          <PhoneNumberInput v-model="spouseMobilePhone" :form-input="$v.spouseMobilePhone" :label="$t('L_spouse_first_name_mobile_phone', { name: spouseName })" />
        </v-flex>
      </v-layout>
      <v-flex xs12 mt-4>
        <v-layout row wrap class="zoffset-xs3 text-xs-right pl-2 pr-2" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
          <v-flex xs8 sm3 mb-3 :class="$vuetify.breakpoint.xsOnly?'':'mr-3'">
            <v-btn :disabled="disableActions" block color="secondary" class="mr-2" @click="backAction">
              {{ $t('L_back') }}
            </v-btn>
          </v-flex>
          <v-flex xs8 sm3 mb-3>
            <v-btn :disabled="disableActions || disableBtnReinstatementInfo" target="_blank" block color="primary" @click="continueAction">
              {{ $t('L_continue') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import Alert from '@/components/Alert/Alert.vue';
import {
  EmailInput,
  EmailValidation,
  PhoneNumberInput,
  PhoneNumberValidation,
} from '@/components/Inputs';

export const notSameAsEmail = (value, vm) => value.toLowerCase() === vm.spouseEmailAddress.toLowerCase();

export default {
  name: 'ReinstatementProcessInformation',
  components: {
    Alert,
    EmailInput,
    PhoneNumberInput,
  },
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    primaryName: '',
    spouseName: '',
    primaryEmailAddress: '',
    primaryMobilePhone: '',
    spouseEmailAddress: '',
    spouseVerifyEmailAddress: '',
    spouseMobilePhone: '',
  }),
  filters: {
    phone: number => number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
  },
  computed: {
    ...mapGetters('policy', ['currentPolicy']),
    ...mapGetters('customer', ['customerProfile']),
    isPhoneVerified() {
      return this.customerProfile?.phoneVerified;
    },
    disableBtnReinstatementInfo() {
      if (this.hasSpouse) {
        return this.$v.primaryMobilePhone.$invalid ||
               this.$v.spouseEmailAddress.$invalid ||
               this.$v.spouseVerifyEmailAddress.$invalid ||
               this.$v.spouseMobilePhone.$invalid ||
               this.spouseMobilePhone.length === 0 ||
               this.primaryMobilePhone.length === 0;
      } else {
        return this.$v.primaryMobilePhone.$invalid ||
               this.primaryMobilePhone.length === 0;
      }
    },
    hasSpouse() {
      return !!this.spouseName;
    },
  },
  methods: {
    continueAction() {
      const userObj = {
        primaryName: this.primaryName,
        spouseName: this.spouseName,
        primaryEmailAddress: this.primaryEmailAddress,
        primaryMobilePhone: this.primaryMobilePhone,
        spouseEmailAddress: this.spouseEmailAddress,
        spouseVerifyEmailAddress: this.spouseVerifyEmailAddress,
        spouseMobilePhone: this.spouseMobilePhone,
      };
      // eslint-disable-next-line no-alert
      // alert(JSON.stringify(userObj, 1, 3));
      this.$emit('continue-to-the-next-reinstatement-step', userObj);
    },
    backAction() {
      this.$emit('backClicked');
    },
  },
  mounted() {
    this.primaryName = this.customerProfile?.firstName;
    this.primaryEmailAddress = this.customerProfile?.email;
    this.spouseName = this.currentPolicy?.data?.spouseFirstName;
    if (this.customerProfile?.phoneVerified) {
      this.primaryMobilePhone = this.customerProfile?.mobilePhone;
    }
  },
  validations() {
    return {
      primaryEmailAddress: {
        required,
        ...EmailValidation,
        maxLength: maxLength(100),
      },
      primaryMobilePhone: {
        required,
        ...PhoneNumberValidation,
      },
      spouseEmailAddress: {
        ...EmailValidation,
        maxLength: maxLength(100),
      },
      spouseVerifyEmailAddress: {
        ...EmailValidation,
        maxLength: maxLength(100),
        notMatchingEmail: notSameAsEmail,
      },
      spouseMobilePhone: {
        ...PhoneNumberValidation,
      },
    };
  },
};
</script>
<style lang="stylus" scoped>

</style>
