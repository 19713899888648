export const STEP = 'STEP';
export const PACKAGES = 'PACKAGES';
export const PACKAGE = 'PACKAGE'; // updates specific package in packages
export const NEW_POLICY = 'NEW_POLICY';
export const NEW_POLICY_EDELIVERY = 'NEW_POLICY_EDELIVERY';
export const NEW_POLICY_ACKNOWLEDGEMENT = 'NEW_POLICY_ACKNOWLEDGEMENT';

// TODO: cleanup below
export const PACKAGE_PRIMARY = 'PACKAGE_PRIMARY';
export const PERSONALINFO = 'PERSONALINFO';
export const CONVERSIONPROCESS_STEP = 'CONVERSIONPROCESS_STEP';
export const SELECTED_OPTION = 'SELECTED_OPTION';
export const OPTIN = 'OPTIN';
export const SELECTED_PACKAGEINDEX = 'SELECTED_PACKAGEINDEX';
export const NEW_PACKAGE_INFO = 'NEW_PACKAGE_INFO';
export const EDELIVERY = 'EDELIVERY';
export const ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT';
export const LOADING = 'LOADING';
export const LRCIND = 'LRCIND';
export const EXPDATE = 'EXPDATE';
