<i18n src='../locales.json'></i18n>

<template>
  <v-container fluid>
    <v-layout column>
      <p>{{ $t('mal_the_accounts_below_were_found') }}</p>
      <div v-for="account in minorAccounts" :key="account.accountId">
        {{ account.firstName }}&nbsp;{{ account.lastName }}<br>
        {{ `$${account.totalMarketValue}` }}<br>
        {{ account.accountNumber }}<br>
      </div>
      <v-layout mt-4 row wrap :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
        <v-flex xs12 sm4 mb-3 ml-3 offset-md4 offset-lg4>
          <v-btn :disabled="disableActions" block color="secondary" @click="cancelAction">
            Cancel
          </v-btn>
        </v-flex>
        <v-flex xs12 sm4 mb-3 ml-3>
          <v-btn :disabled="disableActions" block color="primary" @click="continueAction">
            Continue
          </v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'MinorAccountLinkProcessInformationReview',
  props: {
    minorAccounts: {
      type: Array,
      default: () => [],
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    continueAction() {
      this.$emit('continueClicked', { });
    },
    cancelAction() {
      this.$emit('cancelClicked');
    },
  },
};
</script>

<style>
</style>
