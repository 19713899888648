/**
 * See http://confluence.primerica.com/display/ADstandard/MyPri+Child+Rider+Conversion+Frontend
 */

//  package to policy relationship notes:
//  happy path (1 child): 1 package (Sagun) -> new policy (Sagun)
//  twins scenario (multiple children): 1 package (numberOfChildren=2) -> new policy (Pinuno), new policy (Eljay)

export const getDefaultState = () => ({
  loading: false,
  step: -1,
  lrcInd: 'N',
  packages: [],
  expirationDate: '',
  newPolicy: {
    packageIndex: -1,
    policyId: '', // parent policy id
    packageId: '',
    newPolicyId: '', // submitted policy id (temporary)
    firstName: '', // retrieved from life-policies-sys primInsFirstName; new policy - primary first name; goes to NB_COSMO_CRC
    lastName: '', // retrieved from life-policies-sys primInsLastName; new policy - primary middle name; goes to NB_COSMO_CRC
    middleName: '', // retrieved from life-policies-sys primInsMiddleName; new policy - primary last name; goes to NB_COSMO_CRC
    DOB: '', // retrieved from life-policies-sys primInsDob; new policy - primary dob; goes to NB_COSMO_CRC
    gender: '', // new policy - gender; goes to NB_COSMO_CRC
    SSN: '', // new policy - SSN; goes to NB_COSMO_CRC
    selectedOption: '',
    selectedFaceAmount: '',
    selectedPremiumAmount: '',
    eDelivery: {
      agreementId: -1,
      agreementLink: '',
      agreementType: 'D',
      createTs: '',
      optIn: '', // goes to NB_COSMO_CRC E_DELIVERY ('Y' or 'N')
    },
    acknowledgement: {
      agreementId: -1,
      agreementLink: '',
      agreementType: 'C',
      createTs: '',
      text: [],
    },
    requestNumber: '',
  },
  // TODO: cleanup/delete below
  agreement: {
    agreementId: 0,
    agreementType: '',
    agreementLink: '',
  },
  acknowledgement: {
    agreementId: 0,
    agreementLink: '',
    agreementTextId: 0,
    agreementType: '',
    createTs: '',
    text: [],
  },
});

const state = getDefaultState();

export default state;
