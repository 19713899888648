<i18n src='./locales.json'></i18n>

<template>
  <v-container>
    <!-- main layout -->
    <v-layout
      row
      wrap
      class="white--text"
    >
      <!-- account Management title -->
      <v-flex
        xs12
      >
        <v-layout
          row
          wrap
          align-center
          class="mb-4"
        >
          <v-flex
            shrink
            class="center"
          >
            <img
              class="account-img"
              src="@/assets/acct-settings-icon.svg"
              alt=""
              role="presentation"
            >
          </v-flex>
          <v-flex>
            <div
              role="heading"
              aria-level="1"
              class="heading_two"
            >
              {{ $t('L_account_settings') }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <div>
          {{ $t('L_account_setting_sub_text') }}
        </div>
      </v-flex>
      <!-- main content flex -->
      <v-flex
        xs12
        id="main-flex"
      >
        <v-layout
          row
          wrap
        >
          <UpdatePassword />
          <UpdateUserID />
          <UpdateBiometricsPreference v-if="mobileMeta.canUseBiometrics && mobileMeta.hasStrongBiometricsStrengthLevel" />
          <UpdateContact
            @verify-go-to-page-one="verifyPhoneModalGoToPageOne"
            @verify-bypass-page-one="verifyPhoneModalBypassPageOne"
            ref="updateContactRef"
          />

          <!-- Removing from UI temporary due to OKTA
          <UpdateDevice />
          -->

          <UpdateCommunication v-if="updateCommEnabled" />
          <VerifyPhoneWrapper
            :allow-edit="false"
            :show="showVerifyPhoneWrapper"
            @show-successful-verify="handleCallsGetAlerts"
            @close-verify-phone-dialog="closeVerifyPhoneDialog"
            @gotoupdatecontact2="goToUpdateContact"
            ref="verifyacc"
          />
          <SuccessVerifyPhone v-if="showSuccessfulVerify" @close-success-verify-phone="closeSuccessVerifyPhone" />
          <!--UpdateLanguage /-->
          <v-layout>
            <v-flex xs12 class="versionDisplay">
              <v-card height="100%" class="pa-2 mt-2">
                <v-flex xs12>
                  <v-icon color="#02579e" @click="toggleMinimizeVersionInfo" v-if="minimizeVersionInfo">
                    expand_more
                  </v-icon>
                  <v-icon color="#02579e" @click="toggleMinimizeVersionInfo" v-else-if="!minimizeVersionInfo">
                    expand_less
                  </v-icon>
                </v-flex>
                <span v-if="!minimizeVersionInfo">
                  <p>{{ $t('L_version') }} <strong>{{ getVersion }}</strong></p>
                  <p>unlockModePreference: <strong>{{ mobileMeta.unlockModePreference }}</strong></p>
                  <p>canUseBiometrics: <strong>{{ mobileMeta.canUseBiometrics }}</strong></p>
                  <p>hasStrongBiometricsStrengthLevel: <strong>{{ mobileMeta.hasStrongBiometricsStrengthLevel }}</strong></p>
                  <p>Vault Config:</p>
                  <ul>
                    <li v-for="(v, k) in mobileMeta.vaultConfig" :key="k">
                      {{ k }}: <strong>{{ v }}</strong>
                    </li>
                  </ul>
                  <br>
                  <p>Device Info:</p>
                  <ul>
                    <li v-for="(v, k) in mobileMeta.deviceInfo" :key="k">
                      {{ k }}: <strong>{{ v }}</strong>
                    </li>
                  </ul>
                </span>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

import { mapActions, mapMutations, mapGetters } from 'vuex';
import { getUnlockModePreference, canUseBiometrics, hasStrongBiometricsStrengthLevel, getVaultConfig } from '@/plugins/auth/utils/sessionManager';
import PasswordForm from '@/components/PasswordForm/PasswordForm.vue';
import { PasswordInput } from '@/components/Inputs';
import { FEATURE_FLAGS } from '@/shared/constants';
import UpdatePassword from './updatePassword/UpdatePassword.vue';
import UpdateUserID from './updateUserId/UpdateUserID.vue';
import UpdateBiometricsPreference from './updateBiometricsPreference/UpdateBiometricsPreference.vue';
import UpdateLanguage from './updateLanguage/UpdateLanguage.vue';
import UpdateContact from './updateContact/UpdateContact.vue';
import UpdateCommunication from './updateCommunication/UpdateCommunication.vue';
import UpdateDevice from './updateDevice/UpdateDevice.vue';
import MyAccountDialog from './MyAccountDialog.vue';
import VerifyPhoneWrapper from '../../../../components/VerifyPhoneWrapper/VerifyPhoneWrapper.vue';
import SuccessVerifyPhone from './successVerifyPhone/successVerifyPhone.vue';
import { version } from '../../../../../package.json';

export default {
  name: 'MyAccount',
  components: {
    VerifyPhoneWrapper,
    MyAccountDialog,
    PasswordForm,
    PasswordInput,
    UpdatePassword,
    UpdateUserID,
    UpdateBiometricsPreference,
    UpdateLanguage,
    UpdateContact,
    UpdateCommunication,
    UpdateDevice,
    SuccessVerifyPhone,
    version,
  },
  data: () => ({
    minimizeVersionInfo: true,
    showSuccessfulVerify: false,
    showVerifyPhoneWrapper: false,
    mobileMeta: {
      unlockModePreference: null,
      canUseBiometrics: false,
      hasStrongBiometricsStrengthLevel: false,
      vaultConfig: null,
      deviceInfo: null,
    },
  }),
  computed: {
    ...mapGetters(['containsFeature', 'features']),
    updateCommEnabled() {
      return this.containsFeature(FEATURE_FLAGS.ECOMM);
    },
    getVersion() {
      return version;
    },
  },
  methods: {
    ...mapActions(['getAlerts', 'dismissAlert', 'completeAlert']),
    ...mapActions({
      getPolicyOverview: 'policy/getPolicyOverview',
    }),
    ...mapMutations(['setVerifyPhonePageToLand']),
    toggleMinimizeVersionInfo() {
      this.minimizeVersionInfo = !this.minimizeVersionInfo;
    },
    handleCallsGetAlerts() {
      this.showVerifyPhoneWrapper = false;
      this.getAlerts();
      this.showSuccessfulVerify = true;
    },
    closeVerifyPhoneDialog() {
      this.showVerifyPhoneWrapper = false;
      this.getAlerts();
      this.showSuccessfulVerify = false;
    },
    goToUpdateContact() {
      this.showVerifyPhoneWrapper = false;
      this.$refs.updateContactRef.showUpdatePhoneDialog = true;
    },
    closeSuccessVerifyPhone() {
      this.showSuccessfulVerify = false;
    },
    verifyPhoneModalGoToPageOne() {
      // this.$refs.verifyacc.closed = false;
      this.setVerifyPhonePageToLand('first');
      this.showVerifyPhoneWrapper = true;
    },
    verifyPhoneModalBypassPageOne(phoneNum) {
      // this.$refs.verifyacc.closed = false;
      this.setVerifyPhonePageToLand('');
      this.$refs.verifyacc.bypassPageOne(phoneNum);
      this.showVerifyPhoneWrapper = true;
    },
  },
  async mounted() {
    this.getPolicyOverview();
    // TODO: handle this onUpdated or even on vault config changes
    await Promise.all([
      getUnlockModePreference(),
      canUseBiometrics(),
      hasStrongBiometricsStrengthLevel(),
      getVaultConfig(),
      this.$pc2capacitor.getDeviceInfo(),
    ])
      .then(([_unlockModePreference, _canUseBiometrics, _hasStrongBiometricsStrengthLevel, _vaultConfig, _deviceInfo]) => {
        this.mobileMeta.unlockModePreference = _unlockModePreference;
        this.mobileMeta.canUseBiometrics = _canUseBiometrics;
        this.mobileMeta.hasStrongBiometricsStrengthLevel = _hasStrongBiometricsStrengthLevel;
        this.mobileMeta.vaultConfig = _vaultConfig;
        this.mobileMeta.deviceInfo = _deviceInfo;
      });
  },
};
</script>
<style lang="stylus" scoped>
  @require '~vuetify/src/stylus/settings/_variables'
  .heading_three
    display inline;
  /deep/ .account-img
    margin-right 16px;
    height 46px;
    display: inline-block
  /deep/ .return-home-link
    color: #fff !important;
    .v-icon
      color: #fff
      font-size: 2em
      line-height: .6em
      margin-left: -8px
  .center
    display: flex;
    justify-content: center;
  #main-flex
    margin-top 40px;
    h3
      color: black;
    img
      display block;
    span
      color: #757576;
  @media $display-breakpoints.sm-and-up
    #main-flex
      /deep/ .layout
        .pa8
          &:nth-child(2n-1)
            padding-right 8px;
          &:nth-child(2n)
            padding-left 8px;
    /deep/ .pa8
      padding: 8px 0;
    /deep/ .pt8
      padding-top 8px;
    /deep/ .pl8
      padding-left 8px;
    /deep/ .mt24
      margin-top 24px;
    /deep/ .mt-48
      margin-top 48px;
  @media $display-breakpoints.sm-only
    /deep/ .lh32
      line-height 32px;
  @media $display-breakpoints.xs-only
    #main-flex
      margin-top 24px
      /deep/ .mt8
        margin-top 8px;
</style>
