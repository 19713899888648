<template>
  <v-layout row justify-center>
    <v-dialog :value="dialogOpen" scrollable persistent :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600px">
      <v-card class="modal-content">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-divider class="hidden-xs-only" />
        <v-card-text class="text-content pa-0" :class="{ 'fade-text': fadeText }" @scroll="onScroll" ref="container">
          <div class="pa-3" ref="content" v-html="content" />
        </v-card-text>
        <transition name="fade">
          <v-flex v-show="showScrollToContinue" grow offset-xs-1 xs-10 class="scroll-to-continue">
            <span>{{ scrollToContinue }}</span>
          </v-flex>
        </transition>
        <v-card-actions>
          <v-flex grow offset-xs2 xs8 offset-md-3 md-6>
            <v-btn block color="primary" @click="$emit('close-dialog')">
              {{ button }}
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
const throttle = require('lodash.throttle');

export default {
  data() {
    return {
      fadeText: true,
      showScrollToContinue: false,
    };
  },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: 'Content',
    },
    title: {
      type: String,
      default: 'Title',
    },
    button: {
      type: String,
      default: 'Button',
    },
    scrollToContinue: {
      type: String,
      default: 'Scroll',
    },
  },
  watch: {
    dialogOpen(newValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.setScrollStyles();
        }, 100);
      }
    },
  },
  methods: {
    setScrollStyles(e) {
      // Adding 32 for padding
      const contentHeight = this.$refs.content.clientHeight;
      const containerHeight = this.$refs.container.clientHeight;
      const heightDiff = contentHeight - containerHeight;
      const containerScrollTop = this.$refs.container.scrollTop;

      if (containerHeight > contentHeight || containerScrollTop >= heightDiff) {
        this.fadeText = false;
        this.showScrollToContinue = false;
      } else {
        this.fadeText = true;
      }

      if (e) {
        this.showScrollToContinue = e.target.scrollTop === 0;
      }
    },
    // eslint-disable-next-line
    onScroll: throttle(function (e) {
      this.setScrollStyles(e);
    }, 100),
  },
};
</script>

<style lang="stylus" scoped>
.v-dialog {
  &:not(.v-dialog--fullscreen) {
    .modal-content .scroll-to-continue {
      display: none;
    }
  }

  .modal-content {
    padding: 20px 20px 30px 20px;

    .v-card__title {
      flex: initial;
      text-align: center;
      font-size: 24px;
      line-height: 36px;
      color: #333333;
      display: block;
      padding: 0;
    }

    .text-content {
      margin: 0 0 10px 0;

      &.v-card__text {
        &:after {
          transition: opacity 0.1s;
          height: 10em;
          left: 0;
          right: 0;
          position: absolute;
          bottom: calc(50px + 2.7em);
          content: '';
          background: rgba(240, 248, 255, 0) linear-gradient(to top, #fff, rgba(255, 255, 255, 0)) repeat scroll 0 0;
        }
      }

      &.v-card__text:not(.fade-text) {
        &:after {
          opacity: 0;
        }
      }
    }
  }
}

.v-dialog--fullscreen {
  >.v-card {
    padding-bottom: 20px !important;
  }

  .modal-content {
    .scroll-to-continue {
      text-align: center;
      position: absolute;
      bottom: 130px;
      width: 100%;

      > span {
        padding: 6px 20px;
        font-size: 16px;
        font-weight: 600;
        background-color: #d8d8d8;
        border-radius: 2px;
      }
    }

    .v-card__title {
      background: var(--v-primary-base);
      font-size: 20px;
      color: #ffffff;
      padding: 11px 0 11px 0;
      font-weight: 600;
    }
  }
}
</style>
