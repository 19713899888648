<template>
  <router-view />
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { getUnlockModePreference, canUseBiometrics, hasStrongBiometricsStrengthLevel, setUnlockMode } from '@/plugins/auth/utils/sessionManager';

export default {
  name: 'PostLoginLobby',
  data() {
    return {
      error: '',
    };
  },
  computed: {
    ...mapGetters({
      hasTermsOfUseTask: 'hasTermsOfUseTask',
      hasPolicyAckTask: 'hasPolicyAckTask',
    }),
  },
  ...mapMutations({
    addError: 'addError',
  }),
  methods: {
    ...mapMutations(['setRootLoading']),
    ...mapActions(['bootstrap']),
    async getAuthTokens() {
      const urlParams = new URLSearchParams(window.location.search);
      const queryEntries = Object.fromEntries(urlParams.entries());
      return this.$pc2capacitor.isNativePlatform() ? this.$auth.getAccessToken() : this.$auth.handleLoginCallback(queryEntries);
    },
    async processPostLoginTasks() {
      const isNativePlatform = await this.$pc2capacitor.isNativePlatform();
      let _canUseBiometricsAndNoPreferenceYet = false;
      if (isNativePlatform) {
        const [_unlockModePreference, _canUseBiometrics, _hasStrongBiometricsStrengthLevel] = await Promise.all([
          getUnlockModePreference(),
          canUseBiometrics(),
          hasStrongBiometricsStrengthLevel(),
        ]);
        _canUseBiometricsAndNoPreferenceYet = !_unlockModePreference && _canUseBiometrics && _hasStrongBiometricsStrengthLevel;

        // If there was [device preference] already set, we don’t show the prompt/splash, but set config to the [device preference]
        if (!!_unlockModePreference && _canUseBiometrics && _hasStrongBiometricsStrengthLevel) {
          await setUnlockMode(_unlockModePreference).catch(async (err) => {
            try {
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PostLoginLobby] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
              await this.$store.dispatch('postAuditLog', {
                actionId: 'MobileApp_Device_FailureAction', // optional
                details: `[${Date.now()}] ERROR CODE ${err?.code}`, // Additional message stored - optional
                message: err?.message, // Error type - optional
              });
            } catch (error) { /*  */ }
          });
        }
      }

      // If no preference set yet and can use biometrics, show the biometrics prompt/splash;
      const hasToShowBiometricsPrompt = isNativePlatform && _canUseBiometricsAndNoPreferenceYet;

      // call bootstrap to initiate post login tasks
      // TODO: look into breaking down this bootstrap call into smaller calls
      try {
        await this.bootstrap();
      } catch (e) {
        // TODO: handle error or log in DT
        this.error = `ERROR: ${e}`;
        this.addError({ e, context: 'Login Callback' });
        return this.$router.push('/error');
      }
      // handle routing based on post login tasks
      // * consider deeplink routes
      const deeplinkroute = window.localStorage.getItem('deeplinkroute'); // ? this is a path, NOT name of a route
      let nextRoute = '';
      if (hasToShowBiometricsPrompt) nextRoute = 'BiometricsSplash';
      else if (this.hasTermsOfUseTask) nextRoute = 'TermsOfUse';
      else if (this.hasPolicyAckTask) nextRoute = 'PolicyAcknowledgement';
      else {
        window.localStorage.removeItem('deeplinkroute'); // remove the deeplink route
        nextRoute = deeplinkroute || 'Dashboard'; // there are no post login tasks
      }
      this.setRootLoading(false);
      const currentRouteIsTheSameAsNextRoute = this.$router.currentRoute.path === nextRoute || this.$router.currentRoute?.name === nextRoute;
      if (!currentRouteIsTheSameAsNextRoute) await this.$router.push((deeplinkroute ? { path: nextRoute } : { name: nextRoute }));
    },
  },
  updated() {
    // TODO: check if authenticated, sometimes redirect happens before the full login process is resolved
    // ! in mobile ios, login callback is handled in the login call (see auth/index.js)
    if (this.$pc2capacitor.isNativePlatform()) this.processPostLoginTasks();
  },
  async created() {
    // TODO: add a loading ui
    // TODO: handle when there are no query params
    // ? validate query params
    // ? if valid, get tokens
    // ? if error, handle, maybe redirect to login, or display error
    // TODO: refacctor error handling

    // in web, postlogin is handled throught redirect
    if (!this.$pc2capacitor.isNativePlatform()) {
      const {
        code, state, error, errorCode,
      } = this.$route.query;
      if (code && state) {
        // TODO: validate if saved state is the same as response state
        try {
          await this.getAuthTokens();
        } catch (e) {
          this.error = `ERROR: ${e}`;
          this.addError({ e, context: 'Login Callback' });
          this.$router.push('/error');
        }
        // process post login task
        await this.processPostLoginTasks();
      } else if (errorCode) {
        this.error = 'Something wrong happened';
        if (error || errorCode) {
          this.error += `${error}:${errorCode}`;
        }
        this.addError({ e: this.error, context: 'Login Callback' });
        this.$router.push('/error');
      }
    } else {
      const isAuthenticated = await this.$auth.isAuthenticated();
      // TODO: handle error or log in DT
      if (isAuthenticated) {
        this.processPostLoginTasks();
      } else {
        // TODO: handle this scenario
      }
    }
  },
};
</script>

<style scoped></style>
