<i18n src='./locales.json'></i18n>
<template>
  <v-layout
    row
    justify-center
  >
    <v-dialog
      id="term_condition"
      :value="termsOfUseModal"
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
    >
      <v-card>
        <v-divider class="under-title-devider" />
        <v-layout class="row wrap justify-center term-condition-content">
          <v-flex xs10>
            <v-card-text
              class="text-content card-bottom-padding"
              id="scroll-target-policy"
              v-scroll:#scroll-target-policy="onScroll"
            >
              <div v-html="termsOfUseContent" />
            </v-card-text>
          </v-flex>
        </v-layout>
        <transition name="fade">
          <v-flex
            v-if="$vuetify.breakpoint.xsOnly"
            v-show="offsetTop === 0"
            grow
            offset-xs-1
            xs-10
            class="scroll-to-continue"
          >
            <span>{{ $t('L_scroll_to_continue') }}</span>
          </v-flex>
        </transition>
        <v-card-actions
          class="text-xs-center justify-center"
        >
          <v-flex
            xs8
            md5
            pa-4
          >
            <v-btn
              block
              color="primary"
              :aria-label="$t('L_close_terms_of_use')"
              @click="$emit('hidetermsOfUseModal')"
            >
              {{ $t('L_close') }}
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
const throttle = require('lodash.throttle');

export default {
  name: 'TermsOfUse',
  data() {
    return {
      offsetTop: 0,
    };
  },
  props: {
    termsOfUseModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    termsOfUseContent: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    // eslint-disable-next-line
    onScroll: throttle(function (e) {
      this.offsetTop = e.target.scrollTop;
    }, 300),
  },
};

</script>

<style lang="stylus" scoped>
.heading
  font-size:20px;
.sub-heading
  color: #02579E !important;
.term-condition-content
  overflow-y:auto;
.fade-enter-active, .fade-leave-active
  transition: opacity .5s
.fade-enter, .fade-leave-to
  opacity: 0

.modal-content
  padding 20px 20px 30px 20px
  .scroll-to-continue
    text-align center
    z-index 1
    position absolute
    bottom 130px
    width 100%
    span
      padding 6px 20px
      font-size 16px
      font-weight 600
      background-color #d8d8d8
      border-radius 2px
  .v-card__title
    text-align center
    font-size 24px
    line-height 36px
    color #333333
    display block
    padding 0
  .under-title-devider
    margin 10px 16px 10px 16px
    border-color #d4d4d4
  .text-content
    margin 0 0 10px 0
    &.v-card__text
      &:after
        height 10em
        width 90%
        position absolute
        bottom calc(50px + 2.7em)
        background rgba(240,248,255,0) linear-gradient(to top,#fff,rgba(255,255,255,0)) repeat scroll 0 0
        content ''
.v-dialog--fullscreen
  >.v-card
    padding-bottom 20px !important
  .modal-content
    .v-card__title
      background #063562
      font-size 20px
      color #ffffff
      padding 11px 0 11px 0
      font-weight 600
      position relative
      .v-icon
        position absolute
        top 15px
        right 15px
        color #ffffff
        cursor pointer
.card-bottom-padding.v-card__text
  padding-bottom: 120px;
</style>
