import { Bank } from './Bank';

export class Payment {
    _accountName = '';
    _accountNumber = '';
    _bankName = '';
    _accountType = '';
    _annualPremium = '';
    _autoDraftPaymentDate = '';
    _billForm = '';
    _billMode = '';
    _billToDate = '';
    _errorMessage = '';
    _modePremiumAmount = '';
    _newPremiumAmount = '';
    _nextCycleDate = '';
    _pACPremium = '';
    _paidToDate = '';
    _payInProcess = '';
    _policyNumber = '';
    _policyRecordID = '';
    _policyStatus = '';
    _quarterlyPremium = '';
    _restrictCode = '';
    _returnCode = '';
    _routingNumber = '';
    _semiAnnualPremium = '';
    _suspendCode = '';
    _zipCode = '';
    _bank = null;

    _setBank(accountNumber, accountName, bankName) {
      if (
        !!accountNumber &&
        !!bankName &&
        !!accountName
      ) {
        this._bank = new Bank({
          account: accountNumber,
          name: bankName,
          payorName: accountName,
        });
      }
    }

    constructor({
      AccountName,
      AccountNumber,
      AccountType,
      AnnualPremium,
      AutoDraftPaymentDate,
      BillForm,
      BillMode,
      BillToDate,
      ErrorMessage,
      ModePremiumAmount,
      NewPremiumAmount,
      NextCycleDate,
      PACPremium,
      PaidToDate,
      PayInProcess,
      PolicyNumber,
      PolicyRecordID,
      PolicyStatus,
      QuarterlyPremium,
      RestrictCode,
      ReturnCode,
      RoutingNumber,
      SemiAnnualPremium,
      SuspendCode,
      ZipCode,
      BankName,
    }) {
      this._accountName = AccountName;
      this._accountNumber = AccountNumber;
      this._accountType = AccountType;
      this._annualPremium = AnnualPremium;
      this._autoDraftPaymentDate = AutoDraftPaymentDate;
      this._billForm = BillForm;
      this._billMode = BillMode;
      this._billToDate = BillToDate;
      this._errorMessage = ErrorMessage;
      this._modePremiumAmount = ModePremiumAmount;
      this._newPremiumAmount = NewPremiumAmount;
      this._nextCycleDate = NextCycleDate;
      this._pACPremium = PACPremium;
      this._paidToDate = PaidToDate;
      this._payInProcess = PayInProcess;
      this._policyNumber = PolicyNumber;
      this._policyRecordID = PolicyRecordID;
      this._policyStatus = PolicyStatus;
      this._quarterlyPremium = QuarterlyPremium;
      this._restrictCode = RestrictCode;
      this._returnCode = ReturnCode;
      this._routingNumber = RoutingNumber;
      this._semiAnnualPremium = SemiAnnualPremium;
      this._suspendCode = SuspendCode;
      this._zipCode = ZipCode;
      this._setBank(AccountNumber, AccountName, BankName);
    }

    get accountName() {
      return this._accountName;
    }

    get routingNumber() {
      return this._routingNumber;
    }

    get accountNumber() {
      return this._accountNumber;
    }

    get modePremiumAmount() {
      return this._modePremiumAmount || '';
    }

    get billForm() {
      return this._billForm || '';
    }

    get billMode() {
      return this._billMode || '';
    }

    get newPremiumAmount() {
      return this._newPremiumAmount || '';
    }

    get paidToDate() {
      return this._paidToDate || '';
    }

    get accountType() {
      return this._accountType || '';
    }

    get isSavingsAccount() {
      return this._accountType === '3';
    }

    get monthlyPremium() {
      return this._pACPremium || '';
    }

    get quarterlyPremium() {
      return this._quarterlyPremium || '';
    }

    get semiAnnualPremium() {
      return this._semiAnnualPremium || '';
    }

    get annualPremium() {
      return this._annualPremium || '';
    }

    get nextCycleDate() {
      return this._nextCycleDate;
    }

    get bank() {
      return this._bank;
    }
}
