import DashboardLayout from '../../layouts/DashboardLayout.vue';
import ReinstatementLayout from './layouts/ReinstatementLayout.vue';

export default {
  path: '/reinstatement',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'PolicyReinstatement',
      component: ReinstatementLayout,
      meta: {
        pageTitle: 'Policy.Reinstatement',
      },
    },
  ],
};
