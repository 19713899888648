export function onImgLoad(el, original) {
  return () => {
    el.src = original;
  };
}

export function onImgError(el, value) {
  return () => {
    el.src = value;
  };
}

export const directive = (el, binding) => {
  const value = binding.value || '';
  const img = new Image();
  const original = el.src;

  img.src = original;

  img.onload = onImgLoad(el, original);

  img.onerror = onImgError(el, value);
};

export default {
  install(Vue) {
    Vue.directive('img-fallback', directive);
  },
};
