import DashboardLayout from '@/layouts/DashboardLayout.vue';
import AlertAndNotification from './AlertAndNotification.vue';

export default {
  path: '/alertAndNotifications',
  component: DashboardLayout,
  children: [
    {
      name: 'AlertAndNotifications',
      path: '',
      component: AlertAndNotification,
    },
  ],
};
