import { render, staticRenderFns } from "./InvestmentsTitle.vue?vue&type=template&id=575e8641&scoped=true"
import script from "./InvestmentsTitle.vue?vue&type=script&lang=js"
export * from "./InvestmentsTitle.vue?vue&type=script&lang=js"
import style0 from "./InvestmentsTitle.vue?vue&type=style&index=0&id=575e8641&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "575e8641",
  null
  
)

/* custom blocks */
import block0 from "../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fhome%2Fdashboard%2Fcomponents%2FInvestments%2FInvestmentsTitle.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports