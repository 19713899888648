<template>
  <v-text-field
    v-model="formInput.$model"
    :error-messages="errorMessages"
    :id="idProp"
    :label="label || $t && $t('L_email') || 'Email Address'"
    max-length="100"
    :color="color"
    :disabled="disabled"
    autocomplete="off"
    :hint="hint"
    @focus="toggleTooltip({field:'emailAddress', show:true})"
    @input="onInput"
    @blur="onBlur(), toggleTooltip({field:'emailAddress', show:false });"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'EmailInput',
  components: { FieldIcon },
  extends: BaseTextField,
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'email_address',
    },
  },
  data: () => ({
    modelName: 'emailAddress',
    emailAddress: '',
  }),
};
</script>
