<i18n src='./locales.json'></i18n>
<template>
  <v-card>
    <v-card-text>
      <v-layout
        justify-center
        row
        wrap
      >
        <v-flex
          xs12
          md10
          px-3
          class="text-xs-center"
        >
          <h2
            aria-live="assertive"
            class="font-weight-regular"
            :class="$vuetify.breakpoint.xsOnly ? 'font-size--16': ''"
          >
            {{ $t('L_start_new_policy') }}
          </h2>
          <h4
            class="mt-3 font-weight-regular"
          >
            {{ $t('L_policy_info') }}
          </h4>
        </v-flex>
        <v-flex xs12 md9 v-if="!hasNoTabacoRate">
          <v-card-actions class="justify-center">
            <RadioButtonGroup
              :horizontal="$vuetify.breakpoint.xsOnly ? false : true"
              v-model="tobaccoSelection"
              :options="tobaccoOptions"
              :form-input="$v.tobaccoSelection"
            />
          </v-card-actions>
        </v-flex>
        <v-flex v-if="!hasNoTabacoRate" xs12 md8 font-size--12 mb-4 class="text-xs-center   justify-center ">
          <span>
            {{ $t('L_nontobacco_is_for_no_tobacco') }}
          </span>
        </v-flex>
      </v-layout>
      <v-divider />
      <v-layout
        justify-center
        row
        wrap
      >
        <v-flex
          xs12
          md9
          mb-4
          mt-4
          class="text-xs-center"
        >
          <span>
            {{ $t('L_choose_the_termnow_35', { insuranceSelected: getInsuranceSelected}) }}
          </span>
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        justify-center
        class="background-light-grey"
      >
        <v-flex xs12 md10 py-4>
          <span>{{ $t('L_option_explanation') }}</span>
          <div class="mt-4 text-xs-center">
            {{ $t('L_contact_your_representative_if') }}
          </div>
        </v-flex>
        <v-flex xs12 md10>
          <!-- This is for NON xs Screen -->
          <v-data-table
            v-if="!$vuetify.breakpoint.xsOnly"
            v-model="selected"
            :items="electionOptions"
            item-key="name"
            class="elevation-1"
            hide-actions
          >
            <template slot="headers">
              <th
                v-for="(item, index) in headers"
                :key="item.value"
                v-show="!(index === 2 && tobaccoSelection === '')"
                :class="index === 0 ? 'text-xs-left' : 'text-xs-right'"
              >
                <span
                  v-html="headers[index].text"
                />
              </th>
            </template>
            <template v-slot:items="props">
              <tr class="valignTextMiddle">
                <td>
                  <v-radio-group v-model="selectedOption" class="font-weight-bold pt-3">
                    <v-radio class="nonxsSelectionBlock" :value="props.item.electionOption" :label="`${$t('L_election_option')} ${props.index+1}:`" />
                  </v-radio-group>
                </td>
                <td class="font-size--14 text-xs-right">
                  {{ props.item.amountOfInsurance }}
                </td>
                <td class="font-size--14 text-xs-right" v-if="tobaccoSelection !== ''">
                  {{ props.item.monthlyBankDraft }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- This is for xs Screen -->
          <v-card
            class="font-size--14"
            v-if="$vuetify.breakpoint.xsOnly"
          >
            <v-card-title>
              <span
                class="font-weight-medium"
                v-html="headers[0].text"
              />
            </v-card-title>
            <v-card-text
              v-for="(item, index) in electionOptions"
              :key="index"
            >
              <v-layout
                row
                wrap
                justify-center
              >
                <v-flex
                  xs10
                  class="text-xs-left"
                >
                  <v-radio-group v-model="selectedOption">
                    <v-radio class="xsSelectionBlock" :value="item.electionOption" :label="`${$t('L_election_option')} ${index+1}:`" />
                  </v-radio-group>
                </v-flex>
                <v-flex xs2 />
              </v-layout>
              <v-layout>
                <v-flex xs1 />
                <v-flex
                  xs8
                >
                  <span
                    class="font-weight-bold"
                    v-html="headers[1].text"
                  />
                </v-flex>
                <v-flex
                  xs3
                  justify-right
                  class="text-xs-right"
                >
                  <span
                    v-html="item.amountOfInsurance"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                py-3
                v-if="tobaccoSelection !== ''"
              >
                <v-flex xs1 />
                <v-flex
                  xs8
                >
                  <span
                    class="font-weight-bold"
                    v-html="headers[2].text"
                  />
                </v-flex>
                <v-flex
                  xs3
                  justify-right
                  class="text-xs-right"
                >
                  <span
                    v-html="item.monthlyBankDraft"
                  />
                </v-flex>
              </v-layout>
              <v-divider />
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 md10 py-4
                class="font-size--12"
                :class="$vuetify.breakpoint.xsOnly ? 'text-xs-center': 'text-xs-right'"
        >
          {{ $t('L_policy_fee_is') }}
        </v-flex>
      </v-layout>
      <v-layout
        justify-center
      >
        <v-flex xs12 md6>
          <v-btn
            block
            role="link"
            color="primary"
            :disabled="!formValid"
            @click="next"
          >
            {{ $t('L_next') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { RadioButtonGroup } from '@/components/Inputs';
import { currency, formatDate } from '@/shared/util/formatters';

export default {
  name: 'CRCStepB',
  data: () => ({
    areAllOptionsSelected: false,
    tobaccoSelection: '',
    selected: [],
  }),
  components: {
    RadioButtonGroup,
  },
  methods: {
    ...mapActions({
      next: 'childrider/crcNext',
      setNPSelectedOption: 'childrider/setNPSelectedOption',
      setNPSelectedFaceAmount: 'childrider/setNPSelectedFaceAmount',
      setNPSelectedPremiumAmount: 'childrider/setNPSelectedPremiumAmount',
    }),
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters({
      dateFormat: 'getDateFormat',
      selectedPackage: 'childrider/NPPackage',
      NPSelectedOption: 'childrider/NPSelectedOption',
      NPPackage: 'childrider/NPPackage',
      insuranceSelected: 'childrider/insuranceSelected',
    }),
    ...mapGetters('policy', {
      policy: 'currentPolicy',
    }),
    selectedOption: {
      get() {
        return this.NPSelectedOption;
      },
      set(value) {
        this.setNPSelectedOption(value);
        const selected = this.selectedPackage.selectionOptions;
        this.setNPSelectedFaceAmount(selected[value].faceAmount);
        this.setNPSelectedPremiumAmount(selected[value].premiumAmount);
      },
    },
    electionOptions() {
      return (this.tobaccoSelection === 1)
        ? this.nonTobaccoOptionsList
        : this.tobaccoOptionsList;
    },
    tobaccoOptions() {
      // Non nextGen
      if (!this.hasNoTabacoRate) {
        return [
          {
            label: this.$t('L_tobacco'),
            value: 0,
          },
          {
            label: this.$t('L_nontobacco'),
            value: 1,
          },
        ];
      }
      // NextGen
      return [
        {
          label: this.$t('L_nontobacco'),
          value: 1,
        },
      ];
    },
    tobaccoOptionsList() {
      return this.selectionOptionsList.filter(option => (option.electionOption.indexOf('T') !== -1 && option.amountOfInsurance));
    },
    nonTobaccoOptionsList() {
      return this.selectionOptionsList.filter(option => (option.electionOption.indexOf('N') !== -1 && option.amountOfInsurance));
    },
    selectionOptionsList() {
      const policyElections = [];
      const objectArray = Object.entries(this.selectedPackage.selectionOptions ? this.selectedPackage.selectionOptions : []);

      objectArray.forEach(([key, value]) => {
        policyElections.push({
          electionOption: key,
          amountOfInsurance: currency(value.faceAmount, 2),
          monthlyBankDraft: currency(value.premiumAmount, 2),
        });
      });
      return policyElections;
    },
    hasNoTabacoRate() {
      /*
      Identifying NextGen CRC packets (see https://pri-jira.atlassian.net/browse/MYP-2896):
      Per LifeOps team (Chris Thompson), there will not be a NextGen indicator added to the current CRC service / api
      to denote which policies are NextGen versus pre-NextGen. Instead, NextGen CRC packets will not have tobacco rates.
      The following CRC_EVENT_LOG table data fields should contain zero (0 or 0.00) values:
      OPT1_T_PREM, OPT2_T_PREM, OPT3_T_PREM, OPT4_T_PREM, OPT5_T_PREM */
      const options = Object.entries(this.selectedPackage.selectionOptions ? this.selectedPackage.selectionOptions : []);
      const tobaccoRates = [];
      if (!options.length) {
        return false;
      }
      // filter out the tobacco rates
      options.forEach(([key, value]) => {
        if (key.indexOf('T') !== -1) {
          tobaccoRates.push(Number(value.premiumAmount));
        }
      });
      // check if packets does not have tobacco rates (premium amounts have 0 or 0.00 values)
      return tobaccoRates.every(e => e <= 0);
    },
    getInsuranceSelected() {
      if (this.hasNoTabacoRate === false) {
        const compCodes = ['04', '05', '10'];
        if (compCodes.some(eachCompanyCode => this.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
          return 'TermNow35';
        }
        // returns if comp code is 34
        return 'TermNow30';
      }
      const compCodes1 = ['04'];
      const compCodes2 = ['34'];
      const compCodes3 = ['05', '10'];
      if (compCodes1.some(eachCompanyCode => this.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
        return 'PowerTerm 35';
      } else if (compCodes2.some(eachCompanyCode => this.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
        return 'PowerTerm 30';
      } else if (compCodes3.some(eachCompanyCode => this.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
        if (this.locale === 'fr_CA') {
          return 'Temporaire Puissance Primerica 35';
        }
        return 'Primerica PowerTerm 35';
      }
      return 'PowerTerm 35';
    },
    headers() {
      return [
        {
          text: `${this.$t('L_new_policy_election')} ${formatDate(this.selectedPackage.newIssueDt, this.dateFormat)}`,
          align: 'left',
          sortable: false,
          value: 'electionOption',
        },
        {
          text: `${this.$t('L_amount_of_insurance')} `,
          align: 'right',
          value: 'amountOfInsurance',
          sortable: false,
        },
        {
          text: this.headerTimeLabelForChart,
          align: 'right',
          value: 'monthlyBankDraft',
          sortable: false,
        },
      ];
    },
    headerTimeLabelForChart() {
      let timeLabel;
      if (this.policy?.billingForm === 'A1') {
        if (this.policy?.billingMode === '01') {
          timeLabel = this.$t('L_quarterly_premium'); // Changed as per Dewayne who mentioned that A1 01 would be coming in as Quarterly
        } else if (this.policy?.billingMode === '03') {
          timeLabel = this.$t('L_quarterly_premium');
        } else if (this.policy?.billingMode === '06') {
          timeLabel = this.$t('L_semi_annual_premium');
        } else if (this.policy?.billingMode === '12') {
          timeLabel = this.$t('L_annual_premium');
        } else {
          timeLabel = this.$t('L_monthly_premium');
        }
      } else if (this.policy?.billingForm === 'C2' || this.policy?.billingForm === 'D2' || this.policy?.billingForm === 'H1') {
        timeLabel = this.$t('L_quarterly_premium');
      } else {
        timeLabel = this.$t('L_monthly_premium');
      }
      return timeLabel;
    },
    formValid() {
      if (this.selectedOption != null && this.tobaccoSelection != null && this.selectedOption !== '' && this.tobaccoSelection !== '') {
        const tobbOption = this.tobaccoSelection === 1 ? 'N' : 'T';
        const covOption = this.selectedOption.substr(0, 1);
        const value = covOption + tobbOption;
        const selected = this.selectedPackage.selectionOptions;
        this.setNPSelectedFaceAmount(selected[value].faceAmount);
        this.setNPSelectedPremiumAmount(selected[value].premiumAmount);
      }
      return this.selectedOption !== '' && this.tobaccoSelection !== '';
    },
  },
  validations() {
    return {
      tobaccoSelection: {
        required,
      },
    };
  },
  created() {
    /*
    * for all NextGen CRC packets
    * Tobacco / Non-Tobacco Question: Default to 'Non-Tobacco'
    * Do not display the Tobacco / Non-Tobacco Question question on the page
     */
    if (this.hasNoTabacoRate) {
      this.tobaccoSelection = 1;
    }
  },
  mounted() {
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      throw e;
    }
  },
};
</script>
<style lang="stylus" scoped>
  .valignTextMiddle
    vertical-align: middle;
  .nonxsSelectionBlock >>> label
    font-size: 14px;
  .xsSelectionBlock >>> label
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  .v-input--selection-controls
    margin-top: 0px;
    padding-top: 0px;
  .v-input
    padding-bottom: 0px;
  .borderbottom
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
</style>

