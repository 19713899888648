/* eslint-disable no-param-reassign */
import { getDefaultState } from './state';

export const setIncludeSpouse = (state, includeSpouse) => {
  if (typeof (includeSpouse) === 'boolean') {
    state.includeSpouse = includeSpouse;
  }
};

export const setDimeData = (state, data) => {
  state.dimeData = data;
  if (data?.primary?.spouseIncluded === 'Y') {
    state.includeSpouse = true;
  }
};

export const setLoading = (state, data) => {
  state.dimeLoading = !!data;
};

export const setDimeCalculation = (state, { you, spouse }) => {
  if (typeof (you) !== 'undefined') {
    state.dimeCalculation.you = you;
  }
  if (typeof (spouse) !== 'undefined') {
    state.dimeCalculation.spouse = spouse;
  }
};

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};
