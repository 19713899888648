// Valdation messages for registration
import i18n from '../../plugins/i18n';

export default {

  firstName: {
    required: i18n.t('L_firstname_required'),
    minLength: i18n.t('L_min_length_msg'),
    maxLength: i18n.t('L_max_length_msg'),
    name: i18n.t('L_firstname_invalid_msg'),
  },
  lastName: {
    required: i18n.t('L_lastname_required'),
    minLength: i18n.t('L_lastname_min_length_msg'),
    maxLength: i18n.t('L_lastname_max_length_msg'),
    name: i18n.t('L_lastname_invalid_msg'),
    accountExists: i18n.t('L_lastname_not_exists'),
  },
  emailAddress: {
    required: i18n.t('L_email_required'),
    email: i18n.t('L_email_valid'),
    maxLength: i18n.t('L_email_max_length'),
    uniqueAccount: i18n.t('L_email_exist'),
  },
  confirmEmailAddress: {
    required: i18n.t('L_confirm_email_required'),
    email: i18n.t('L_email_valid'),
    maxLength: i18n.t('L_email_max_length'),
    sameAs: i18n.t('L_confirm_email_match'),
  },
  phoneNumber: {
    minLength: i18n.t('L_phone_length'),
    uniqueAccount: i18n.t('L_phone_exist'),
  },
  fundAccountNumber: {
    required: i18n.t('L_fund_account_number_required'),
    uniqueAccount: i18n.t('L_fund_account_num_exists'),
  },
  policyNumber: {
    required: i18n.t('L_policy_number_required'),
    minLength: i18n.t('L_policy_num_min_length'),
    uniqueAccount: i18n.t('L_policy_num_exist'),
    policyNumber: '',
  },
  socialSecurityNumber: {
    required: i18n.t('L_ssn_required'),
    uniqueAccount: i18n.t('L_ssn_exist'),
    accountExists: i18n.t('L_ssn_not_exists'),
  },
  otpCode: {
    required: i18n.t('L_otp_required_field'),
    minLength: i18n.t('L_otp_code_min_length'),
    firstAttempt: i18n.t('L_otp_code_first_attempt'),
    secondAttempt: i18n.t('L_otp_code_second_attempt'),
    thirdAttempt: i18n.t('L_otp_code_third_attempt'),
    thirdAttemptStepUp: i18n.t('L_otp_code_third_attempt_step_up'),
    expired: i18n.t('L_otp_code_expired'),
    otpInvalid: i18n.t('L_otp_code_invalid'),
    forgotInvalid: i18n.t('L_otp_forgot_invalid'),
    thirdAttemptForgot: i18n.t('L_otp_forgot_invalid_third_attempt'),
    forgotExpired: i18n.t('L_otp_forgot_expired'),
    otpCodeExceeded: i18n.t('L_otp_code_exceeded'),
    otpGenericInvalid: i18n.t('L_otp_generic_invalid'),
    otpEnterCorrectNo: i18n.t('L_otp_enter_correct_no'),
  },
  dateOfBirth: {
  },
  generic: {
    invalid: i18n.t('L_generic_invalid'),
  },
  password: {
    required: '',
    minLength: '',
    noSpaces: i18n.t('L_your_password_cannot_have_spaces'),
    noInvalidSpecialChars: i18n.t('L_your_password_cannot_contain_certain_special_characters'),
    requireUpperLowerCase: '',
    requireNumber: '',
    requireSymbol: '',
    notContainString: i18n.t('L_your_password_cannot_contain_your_user_id'),
    noThreeCharsInRow: i18n.t('L_your_password_cannot_have_three_of_the_same_characters_in_a_row'),
    passwordReqContent: i18n.t('L_your_password_must_contain_uppder_and_lower_letters_at_least_one_number'),
  },
  confirmPassword: {
    required: i18n.t('L_the_password_field_do_not_match'),
    sameAs: i18n.t('L_the_password_field_do_not_match'),
  },
  accountHolder: {
    required: i18n.t('L_account_holder_name_is_required'),
    name: i18n.t('L_account_holder_name_is_invalid'),
  },
  bankRoutingNumber: {
    required: 'L_bank_routing_number_required',
    notSameDigits: 'L_invalid_format',
    notConsecutive: 'L_invalid_format',
    minLength: 'L_bank_routing_number_min_length',
    routingNumberNotFound: 'L_invalid_routing_transit_number',
  },
};
