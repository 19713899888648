import { helpers } from 'vuelidate/lib/validators';
import messages, { getTypes } from './messages';

export function validateName(value) {
  if (!value) {
    return true;
  }
  return value.match(/^([A-Za-z][A-Za-z \-.]+)$/) !== null;
}
function mod10Check(val) {
  let sum = 0;
  for (let i = 0; i < val.length; i += 1) {
    let intVal = parseInt(val.substr(i, 1), 10);
    if (i % 2 !== 0) {
      intVal *= 2;
      if (intVal > 9) {
        intVal = 1 + (intVal % 10);
      }
    }
    sum += intVal;
  }
  return (sum % 10) === 0;
}

export function validateDateOfBirth(value) {
  let isValid = false;
  if (value === '') {
    return true;
  }
  if (value && value.match(/^(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(19|20)([0-9]{2})$/)) {
    isValid = true;
  }
  return isValid;
}

export function validateSSN({ country, allowTaxId }) {
  return (value) => {
    let isValid = true;
    if (value !== undefined && value.length > 0) {
      if (value.match('(^[0-9]{9}$)') === null || // needs to be 9 digit number
        value.match(/^(1+|2+|3+|4+|5+|6+|7+|8+|9+|0+|123456789|987654321)$/) !== null) {
        isValid = false;
      }
      const regexUsSSNOnly = /^(?!(9|000|666))[0-9]{3}[0-9]{2}(?!(0000))[0-9]{4}$/;
      const regexUsSsnTin = /^(?!(000|666))[0-9]{3}[0-9]{2}(?!(0000))[0-9]{4}$/;
      const regexUS = allowTaxId ? regexUsSsnTin : regexUsSSNOnly;
      if (country === 'US' && value.match(regexUS) === null) {
        isValid = false;
      } else if (country === 'CA' && mod10Check(value) === false) {
        isValid = false;
      }
    }
    return isValid;
  };
}

export function getErrorMessages({ vObject, field }) {
  const errors = [];
  if (typeof (vObject) === 'undefined' || !vObject[field].$dirty) {
    return errors;
  }
  if (vObject[field].$error) { // If there is an error on the field
    getTypes(field).forEach((type) => {
      if (vObject[field][type] === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], type)) {
        errors.push(messages[field][type]);
      }
    });
    if (errors.length === 0) { // If no messages have been added for this error
      errors.push(messages.generic.invalid);
    }
  }
  return errors;
}

export const noSpaces = helpers.regex('noSpaces', /^((?!\s).)*$/);

export const noInvalidSpecialChars = helpers.regex('noInvalidSpecialChars', /^((?![;'<>"&]).)*$/);

export const requireUpperLowerCase = helpers.regex('requireUpperLowerCase', /(?=.*[a-z])(?=.*[A-Z]).*/);

export const requireNumber = helpers.regex('requireNumber', /\d/);

export const requireSymbol = helpers.regex('requireSymbol', /[^a-zA-Z0-9;'<>"&]/);

export const noThreeCharsInRow = helpers.regex('noThreeCharsInRow', /^(?!.*(.)\1\1).*$/);

export function notContainString(value, string) {
  if (!value || value.length === 0) {
    return true;
  }
  const lowerNewPass = value.toLowerCase();
  const lowerEmail = string.toLowerCase();
  return lowerNewPass.indexOf(lowerEmail) === -1;
}
