<i18n src='../../locales.json'></i18n>>
<template>
  <div class="password-hint mb-3">
    <div class="messages" id="password_hint">
      <password-hint :dirty="this.passwordDirty" :valid="this.minLengthValid" v-show="this.show.minLengthValid">
        Use 8 or more characters
      </password-hint>
      <password-hint :dirty="this.passwordDirty" :valid="this.upperLowerValid" v-show="this.show.upperLowerValid">
        Use upper and lower case letters (e.g. Aa)
      </password-hint>
      <password-hint :dirty="this.passwordDirty" :valid="this.numberValid" v-show="this.show.numberValid">
        Use a number (e.g. 1234)
      </password-hint>
      <password-hint :dirty="this.passwordDirty" :valid="this.symbolValid" v-show="this.show.symbolValid">
        Use a symbol (e.g. !@#$)
      </password-hint>
      <div class="error-hint" v-for="(message, index) in this.messagesToShow" :key="index">
        <v-icon size="12" color="error">
          error
        </v-icon>
        <span class="pl-1">{{ $t(message) }}</span>
      </div>
      <div class="success-hint" v-show="this.showSuccessMessage">
        <v-icon size="12" color="success">
          check_circle
        </v-icon>
        <span> STRONG password created! </span>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordHint from './PasswordHint.vue';

export default {
  components: {
    PasswordHint,
  },
  props: {
    passwordDirty: {
      type: Boolean,
      required: true,
      default: false,
    },
    minLengthValid: {
      type: Boolean,
      required: true,
      default: false,
    },
    upperLowerValid: {
      type: Boolean,
      required: true,
      default: false,
    },
    numberValid: {
      type: Boolean,
      required: true,
      default: false,
    },
    symbolValid: {
      type: Boolean,
      required: true,
      default: false,
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      show: {
        minLengthValid: true,
        upperLowerValid: true,
        numberValid: true,
        symbolValid: true,
      },
    };
  },
  computed: {
    messagesToShow() {
      return this.errorMessages.filter(message => message.length > 0);
    },
    showSuccessMessage() {
      return this.errorMessages.length === 0 && !this.show.minLengthValid && !this.show.upperLowerValid && !this.show.numberValid && !this.show.symbolValid;
    },
  },
  watch: {
    minLengthValid(newValue) {
      this.showHideMessage({ valid: newValue, fieldName: 'minLengthValid' });
    },
    upperLowerValid(newValue) {
      this.showHideMessage({ valid: newValue, fieldName: 'upperLowerValid' });
    },
    numberValid(newValue) {
      this.showHideMessage({ valid: newValue, fieldName: 'numberValid' });
    },
    symbolValid(newValue) {
      this.showHideMessage({ valid: newValue, fieldName: 'symbolValid' });
    },
  },
  methods: {
    showHideMessage({ valid, fieldName }) {
      if (valid === true) {
        setTimeout(() => {
          this.show[fieldName] = false;
        }, 1000);
      } else {
        this.show[fieldName] = true;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.password-hint {
  font-size: 12px;
  line-height: 16px;

  .messages {
    border-radius: 2px;
    border: solid 1px rgba(155, 155, 155, 0.2);
    background-color: rgba(204, 204, 204, 0.2);
    padding: 8px 0;
    padding-left: 9px;
    line-height: 24px;
  }

  .error-hint {
    color: var(--v-error-base);
  }

  .success-hint {
    color: var(--v-success-base);

    .v-icon {
      padding-bottom: 2px;
    }
  }
}
</style>
