<i18n src='./locales.json'></i18n>

<template>
  <v-flex
    mt-2
    xs12
  >
    <v-card>
      <v-card-title>
        <v-flex>
          <span class="card-title">{{ getAnnuitiesTitle }}</span>
        </v-flex>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-layout
          class="text-xs-center"
          row
          wrap
        >
          <v-flex
            xs12
            sm4
            mt-4
          >
            <PolicyDetailsInfo
              :title="$t('L_value')"
              :content="'$' + policy.data.totalAnnuityCoverage"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
            mt-4
          >
            <PolicyDetailsInfo
              :title="$t('L_policy_annuities_issue_date')"
              :content="policy.annuity.phIssueDate.slice(0).replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, '$2/$3/$1')"
            />
          </v-flex>
          <v-flex
            xs12
            text-xs-left
            ml-4
            mt-4
            class="font-size--16"
          >
            <span class="font-weight-bold">
              {{ $t('L_For_information_on_withdrawing_or_surrendering_funds') }}
            </span>
            <span @click="openAnnuityWithDrawalLink" class="clickHere">
              {{ $t('L_click_here') }}
            </span>
          </v-flex>
        </v-layout>
        <v-spacer
          class="mt-4"
        />
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import Config from '@/shared/util/config';
import { openInNewWindow } from '@/mixins/openNewWindow';
import { mapGetters } from 'vuex';
import PolicyDetailsInfo from '../../PolicyDetailsInfo.vue';

export function hideTooltip() {
  this.showAnnuityDetail = false;
  document.removeEventListener('click', hideTooltip.bind(this));
}

export default {
  name: 'Annuities',
  data: () => ({
    showAnnuityDetail: false,
    hideTooltipListener: -1,
  }),
  mixins: [openInNewWindow],
  components: {
    PolicyDetailsInfo,
  },
  computed: {
    ...mapGetters({
      currentLocale: 'getLocale',
    }),
    ...mapGetters('policy', {
      policy: 'currentPolicy',
    }),
    annuityText() {
      return this?.policy?.annuity?.planzText?.replace(/#\$#/g, '<br>');
    },
    getAnnuitiesTitle() {
      if (this.policy != null) {
        if (this.currentLocale === 'fr_CA') {
          return this.policy?.annuity?.planDescriptionFrench;
        } return this.policy?.annuity?.planDescription;
      } return '';
    },
  },
  methods: {
    async openAnnuityWithDrawalLink() {
      let annuityUrl = '';
      if (this.currentLocale === 'en_US') {
        annuityUrl = Config.get('life.Annuity_Withdrawal_US_en');
      } else if (this.currentLocale === 'en_CA') {
        annuityUrl = Config.get('life.Annuity_Withdrawal_CA_en');
      } else if (this.currentLocale === 'fr_CA') {
        annuityUrl = Config.get('life.Annuity_Withdrawal_CA_fr');
      }
      await this.openInNewWindow({ url: annuityUrl, target: '_blank' });
    },
    toggleTooltip(e) {
      if (!this?.annuityText?.length) {
        return;
      }
      this.showAnnuityDetail = !this.showAnnuityDetail;
      e.stopImmediatePropagation();
      if (this.showAnnuityDetail) {
        this.hideTooltipListener = document.addEventListener('click', hideTooltip.bind(this));
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
  h3.heading_three {
    a {
      font-weight: inherit !important;
    }
    a[disabled="disabled"] {
      color: #333 !important;
      text-decoration: none !important;
      cursor: default !important;
    }
  }
  .lh1 {
    line-height: 1em;
  }
  .opaque {
    opacity: 1 !important;
  }
  .clickHere {
    cursor: pointer;
    color: #0c6dca;
  }
</style>
