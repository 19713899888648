import { render, staticRenderFns } from "./BeneficiaryCard.vue?vue&type=template&id=433e1e28"
import script from "./BeneficiaryCard.vue?vue&type=script&lang=js"
export * from "./BeneficiaryCard.vue?vue&type=script&lang=js"
import style0 from "./BeneficiaryCard.vue?vue&type=style&index=0&id=433e1e28&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FBeneficiaries%2Fcomponents%2FBeneficiaryCard%2FBeneficiaryCard.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "../../components/UpdateBeneficiaries/locales.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FBeneficiaries%2Fcomponents%2FBeneficiaryCard%2FBeneficiaryCard.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports