import MyAccount from './components/MyAccount.vue';

export default {
  name: 'MyAccount',
  path: 'account',
  component: MyAccount,
  meta: {
    pageTitle: 'Landing.AccountSettings',
  },
};
