<i18n src='../locales.json'></i18n>

<template>
  <div>
    <v-layout
      row
      wrap
      :justify-start="$vuetify.breakpoint.smAndUp"
      :justify-center="$vuetify.breakpoint.xsOnly"
      class="mb-2 pb-1"
    >
      <span
        class="font-size--14 font-weight-medium"
      >{{ $t('L_my_office') }}</span>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex
        v-if="rep.office"
        xs12
      >
        <a
          :href="navigateTo"
          target="_blank"
          :aria-label="$t('L_opens_new_window')+' '+linkAddr"
        >
          <v-layout
            row
            wrap
            :justify-start="$vuetify.breakpoint.mdAndUp"
            :justify-center="$vuetify.breakpoint.smAndDown"
            align-center
            class="pb-4"
          >
            <v-flex xs12>
              <v-layout row wrap justify-start>
                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">location_on</v-icon></span>
                <span xs10 class="addressText">
                  {{ rep.office.address1 }}
                </span>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="addressText iconSpacingLeft">
              <span
                v-if="rep.office.address2"
                class="hidden-sm-and-down"
              >
                {{ rep.office.address2 }}
                <br class="hidden-sm-and-down">
              </span>
              {{ rep.office.city }}, {{ rep.office.state }} {{ rep.office.zip }}
            </v-flex>
          </v-layout>
        </a>
      </v-flex>
      <v-flex
        xs12
        v-if="!rep.isRvp"
      >
        <v-layout
          row
          wrap
          justify-start
          align-center
        >
          <v-flex xs12>
            <span
              class="mt-3 greyText"
              v-html="$t('L_rep_working_out_of', { rvp: rvpInfo } )"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import { openMap } from '@/mixins/openMap';
import { titleCase } from '@/shared/util/formatters';

export default {
  name: 'Address',
  // mixins: [openMap],
  props: {
    rep: {
      type: Object,
      required: true,
      default: () => undefined,
    },
  },
  data: () => ({
    mapLinks: {
      iphone: 'maps://maps.google.com/?address=',
      android: 'http://maps.google.com/?daddr=',
      web: 'http://maps.google.com/?daddr=',
    },
    navigateTo: '',
    linkAddr: '',
  }),
  computed: {
    rvpInfo() {
      if (this.rep?.rvp) {
        return titleCase(`${this.rep?.rvp?.titleDescription} ${this.rep?.rvp?.name}`);
      }
      return '';
    },
  },
  methods: {
    setAddressToNavigate(office) {
      const device = this.getUserAgent();
      const address2 = office.address2 ? ` ${office.address2}` : '';
      this.linkAddr = `${office.address1}${address2} ${office.city} ${office.state} ${office.zip}`;
      this.navigateTo = this.mapLinks[device]
                + this.linkAddr.toLowerCase().replace(/ /g, '+').replace(/,|-|_|\./g, '');
    },
    getUserAgent() {
      const uA = navigator.userAgent;
      if (uA.indexOf('iPhone') >= 0 || uA.indexOf('iPad') >= 0) {
        return 'iphone';
      } if (uA.indexOf('Android') >= 0) {
        return 'android';
      }
      return 'web';
    },
  },
  mounted() {
    this.setAddressToNavigate(this.rep?.office);
  },
};
</script>
<style scoped lang="stylus">
  .addressText
    color: #02579E;
    font-size: 16px;
    font-weight: 600;
  .greyText
    color: #333333;
    font-size: 12px;
  .iconSpacingLeft
    margin-left: 28px;
</style>
