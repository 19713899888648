import { render, staticRenderFns } from "./AlertChangePayment.vue?vue&type=template&id=0bbb03f0&scoped=true"
import script from "./AlertChangePayment.vue?vue&type=script&lang=js"
export * from "./AlertChangePayment.vue?vue&type=script&lang=js"
import style0 from "./AlertChangePayment.vue?vue&type=style&index=0&id=0bbb03f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbb03f0",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FPaymentInformation%2Fcomponents%2FChangePaymentMethod%2FAlertChangePayment.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports