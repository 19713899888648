import moment from 'moment';

const globalAfterEachHook = router => (to) => {
  window.Q_curl = `${window.location.protocol}//${window.location.host}${router.options.base}${to.fullPath.slice(1)}`;
  if (to?.meta?.pageTitle && typeof window.dataLayer === 'object') {
    window.dataLayer.push({
      title: to.meta.pageTitle,
      timestamp: moment()
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
    });
  }
};

export default globalAfterEachHook;
