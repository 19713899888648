<template>
  <v-select
    :id="idProp"
    v-model="formInput.$model"
    :disabled="disabled"
    :label="label || $t && $t('L_state') || 'Province'"
    :items="items"
    :error-messages="errorMessages"
    @input="onInput"
    @blur="onBlur"
    :aria-describedby="describers"
  />
</template>

<script>
import BaseTextField from '../BaseTextField.vue';
import { territories } from './TerritoryValidation';

export default {
  name: 'TerritoryInput',
  extends: BaseTextField,
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'province',
    },
  },
  data: () => ({
    modelName: 'territory',
    territory: '',
  }),
  computed: {
    items() {
      return Object.keys(territories).map(abbr => ({
        value: abbr,
        text: territories[abbr],
      }));
    },
  },
};
</script>
