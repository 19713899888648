import { render, staticRenderFns } from "./ViewPaymentDetails.vue?vue&type=template&id=e42b191e&scoped=true"
import script from "./ViewPaymentDetails.vue?vue&type=script&lang=js"
export * from "./ViewPaymentDetails.vue?vue&type=script&lang=js"
import style0 from "./ViewPaymentDetails.vue?vue&type=style&index=0&id=e42b191e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e42b191e",
  null
  
)

/* custom blocks */
import block0 from "../../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FPaymentInformation%2Fcomponents%2FViewPaymentDetails.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports