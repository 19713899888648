<i18n src='../../../locales.json'></i18n>

<template>
  <v-layout class="investments-summary" row wrap>
    <v-flex>
      <v-layout row wrap justify-center>
        <v-flex xs12 md7 lg7 class="total">
          <v-layout row wrap>
            <v-flex xs5 md6 lg6>
              <div class="label">
                {{ $t('L_total_value') }}
                <v-tooltip v-if="multipleAccounts" top max-width="200">
                  <template v-slot:activator="{ on }">
                    <v-icon color="blue darken-2" dark small v-on="on">
                      help
                    </v-icon>
                  </template>
                  <span>{{ $t('L_total_help_tooltip') }}</span>
                </v-tooltip>
              </div>
              <div v-if="!multipleAccounts" class="as-of">
                {{ $t('L_as_of') }} {{ summary.dataAsOf }}
              </div>
            </v-flex>
            <v-flex xs5 md6 lg6>
              <div class="value">
                {{ currency(summary.totalValue) }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md5 lg5 class="view-details">
          <v-layout row fill-height align-center justify-center>
            <v-btn
              block
              outline
              color="white"
              @click="goToInvestments"
              class="btn_view_details"
              :aria-label="$t('L_view_details')"
            >
              {{ $t('L_view_details') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center class="disclosure">
        <v-flex xs10 sm10 md10 lg10 body-1 class="text-xs-left">
          <span v-if="isCanadian">{{ $t('L_important_disclosures_ca') }}
            <span class="link" @click="openDisclosure()">
              {{ $t('L_here') }}
            </span>
          </span>
          <p v-else>
            <span>{{ $t('L_important_disclosures_us') }}
              <span class="link" @click="openDisclosure()">
                {{ $t('L_important_disclosures') }}
              </span>
            </span>
          </p>
        </v-flex>
      </v-layout>
    </v-flex>
    <EmbeddedViewer
      :title="document.title"
      :loading="document.loading"
      :content-type="document.type"
      :content-url="document.content"
      @close="document.content = null"
    />
  </v-layout>
</template>

<script>
import { COUNTRIES } from '@/shared/constants';
import { mapGetters } from 'vuex';
import { currency, percentage } from '@/mixins/numbers';
import Config from '@/shared/util/config';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';

export default {
  name: 'InvestmentsSummary',
  mixins: [
    currency,
    percentage,
  ],
  data() {
    return {
      document: {
        title: '',
        content: '',
        type: '',
      },
    };
  },
  components: {
    EmbeddedViewer,
  },
  computed: {
    ...mapGetters('customer', ['investments']),
    ...mapGetters('customer', [
      'customerProfile',
    ]),
    multipleAccounts() {
      const totalAccounts =
        this.investments.groups.map(group => group.accounts.length).reduce((total, current) => total + current);
      return totalAccounts > 1;
    },
    summary() {
      return this.investments.summary;
    },
    isCanadian() {
      return this.customerProfile.country === COUNTRIES.CANADA;
    },
  },
  methods: {
    goToInvestments() {
      this.$router.push({ path: '/investments' });
    },
    openDisclosure() {
      this.document.type = 'application/pdf';
      this.document.title = this.$t('L_important_disclosures');
      if (this.customerProfile.country.toLowerCase() === 'ca') {
        this.document.content =
          `${Config.get('content_stack')}${Config.get('investments.important_disclosures.ca')}`;
      } else {
        this.document.content =
          `${Config.get('content_stack')}${Config.get('investments.important_disclosures.us')}`;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables';

.investments-summary
  table
    margin: 0 auto;
  .btn_view_details
    width: 100%;
    max-width: 288px;
    margin-right: 0px;
    margin-left: 0px;
  .disclosure
    padding-top: 10px;
  .link
    font-weight: 600;
    color: #02579e;
    cursor: pointer;
  .total
    padding-top: 15px;
  .as-of, .since
    font-size: 14px;
  .hidden
    visibility: hidden;
  .contribution, .contributions .label
    font-size: 14px;
  .pri-inline-icon.v-icon
    margin: 0 0 -3px;
  .flex
    text-align: center;
  .label
    font-size: 16px;
    font-weight: 600;
  .value
    margin: 0 0 10px;
    font-size: 24px;
    font-weight: 600;
  .negative
    &:after
      content: ')'
    &:before
      content: '('
  .v-btn
    background-color: #063562 !important;
    .v-btn__content
      color: #fff;
  .view-details
    padding: 0 15px;
</style>
