import dashboard from './dashboard/router';
import myAccount from './myaccount/router';
// import help from '../help/router';
import domesticviolence from './domesticviolence/router';
import Help from '../../components/Help/Help.vue';
import DashboardLayout from '../../layouts/DashboardLayout.vue';

export default {
  path: '/home',
  component: DashboardLayout,
  children: [
    {
      path: '',
      beforeEnter(to, from, next) {
        next({ name: 'Dashboard' });
      },
    },
    {
      path: '/help/:type?',
      name: 'HelpAuth',
      component: Help,
    },
    dashboard,
    myAccount,
    // help,
    domesticviolence,
  ],
};
