import BasicLayout from '../../layouts/BasicLayout.vue';
import ErrorLayout from './layouts/ErrorLayout.vue';

export default {
  path: '/error',
  component: BasicLayout,
  children: [
    {
      name: 'Error',
      path: '',
      beforeEnter: (to, from, next) => {
        next({ name: 'ErrorLayout' });
      },
    },
    {
      name: 'ErrorLayout',
      path: '',
      component: ErrorLayout,
      meta: {
        pageTitle: 'Landing.Error',
      },
    },
  ],
};
