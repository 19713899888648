import { CapacitorFacade } from '../plugins/capacitorPlugin';

export const openInNewWindow = {
  methods: {
    // returns a Promise
    // see https://github.com/ionic-team/capacitor/issues/2713
    async openInNewWindow(options) {
      const isWeb = CapacitorFacade.isWeb();
      const {
        inappbrowser, url, target, windowFeatures,
      } = options;
      // default would be to open in to open in (_blank/_system) system browser
      if (isWeb || !inappbrowser) {
        const browserAgent = navigator.userAgent.toLowerCase();
        const isNotSafari = browserAgent.includes('chrome') || !browserAgent.includes('safari');
        let newWin = null;
        if (isNotSafari) {
          newWin = window.open(url, '_blank');
        } else {
          // To make sure you can always open native Safari, use window.location = url
          newWin = window.open('/beta/...');
          newWin.blur();
          newWin.location = url;
          newWin.focus();
        }
        return Promise.resolve(newWin);
      }
      // inappbrowser flag should be set to true
      /* Open in-app browser, returns a Promise */
      return CapacitorFacade.openBrowser({ url, windowName: target, ...windowFeatures });
    },
  },
};
