import details from './details/router';
import guidance from './guidance/router';
import benefits from './benefits/router';
import rep from './my_rep/router';
import PolicyDetails from './details/PolicyDetails.vue';
import DashboardLayout from '../../layouts/DashboardLayout.vue';

export default {
  path: '/policy',
  component: DashboardLayout,
  children: [
    {
      path: '',
      component: PolicyDetails,
      children: [
        details,
        guidance,
        benefits,
      ],
    },
    rep,
  ],
};
