<i18n src='./locales.json'></i18n>

<template>
  <div class="background">
    <v-content
      :style="style"
      id="main_content"
      tabindex="-1"
    >
      <router-view />
    </v-content>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  components: {
  },
  data: () => ({
  }),
  computed: {
    style() {
      const style = {
        paddingTop: 0,
        paddingBottom: '96px',
      };
      if (this.$vuetify.breakpoint.lgAndUp) {
        style.marginLeft = '238px';
        style.paddingTop = '64px';
      }
      return style;
    },
  },
  methods: {
    ...mapMutations(['setRootLoading']),
  },
  mounted() {
    this.setRootLoading(false);
  },
};
</script>

<style lang="stylus" scoped>
  .background {
    min-height: 100%;
    background-image: linear-gradient(to top, #607c97, #063562);
  }
  .return-home-link
    color: #fff !important;
    .v-icon
      color: #fff
      font-size: 2em
      line-height: .6em
      margin-left: -8px
  #alerts {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 1;
  }
</style>
