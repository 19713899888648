<template>
  <div class="house-svg-nav">
    <svg :style="{ opacity: active ? 1 : 0.6 }" width="65px" height="42px" viewBox="0 0 55 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>{{ svgTitle }}</title>
      <defs>
        <radialGradient
          cx="49.9999146%"
          cy="35.0747705%"
          fx="49.9999146%"
          fy="35.0747705%"
          r="90.2110118%"
          gradientTransform="translate(0.499999,0.350748),scale(0.698177,1.000000),rotate(90.000000),
          scale(1.000000,1.063443),translate(-0.499999,-0.350748)"
          :id="`radialGradient-12${navType}`"
        >
          <stop stop-color="#D7D7D7" offset="0%" />
          <stop stop-color="#D8EBF8" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="49.9999146%"
          cy="35.0747705%"
          fx="49.9999146%"
          fy="35.0747705%"
          r="225.372078%"
          gradientTransform="translate(0.499999,0.350748),scale(0.414471,1.000000),rotate(79.173457),
          scale(1.000000,1.063443),translate(-0.499999,-0.350748)"
          :id="`radialGradient-22${navType}`"
        >
          <stop stop-color="#D7D7D7" offset="0%" />
          <stop stop-color="#D8EBF8" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="53.9154455%"
          cy="49.9998822%"
          fx="53.9154455%"
          fy="49.9998822%"
          r="73.4861117%"
          gradientTransform="translate(0.539154,0.499999),scale(0.698177,1.000000),rotate(90.000000),
          scale(1.000000,1.154632),translate(-0.539154,-0.499999)"
          :id="`radialGradient-32${navType}`"
        >
          <stop stop-color="#EE9770" offset="0%" />
          <stop stop-color="#DC3639" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="53.9154455%"
          cy="49.9998822%"
          fx="53.9154455%"
          fy="49.9998822%"
          r="267.901415%"
          gradientTransform="translate(0.539154,0.499999),scale(0.248402,1.000000),rotate(81.985042),
          scale(1.000000,1.154632),translate(-0.539154,-0.499999)"
          :id="`radialGradient-42${navType}`"
        >
          <stop stop-color="#EE9770" offset="0%" />
          <stop stop-color="#DC3639" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="51.4182051%"
          cy="82.466338%"
          fx="51.4182051%"
          fy="82.466338%"
          r="70.4153415%"
          gradientTransform="translate(0.514182,0.824663),scale(0.698177,1.000000),rotate(77.265059),
          scale(1.000000,1.064158),translate(-0.514182,-0.824663)"
          :id="`radialGradient-52${navType}`"
        >
          <stop stop-color="#36A5DD" offset="0%" />
          <stop stop-color="#286EB6" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="51.4182051%"
          cy="82.466338%"
          fx="51.4182051%"
          fy="82.466338%"
          r="303.039601%"
          gradientTransform="translate(0.514182,0.824663),scale(0.234861,1.000000),rotate(45.568421),
          scale(1.000000,1.064158),translate(-0.514182,-0.824663)"
          :id="`radialGradient-62${navType}`"
        >
          <stop stop-color="#36A5DD" offset="0%" />
          <stop stop-color="#286EB6" offset="100%" />
        </radialGradient>
        <path
          d="M7.5,0 C3.375,0 0,3.375 0,7.5 C0,11.625 3.375,15 7.5,15 C11.625,15 15,11.625 15,7.5
          C15,3.375 11.625,0 7.5,0 Z M5.65430198,12 L1.8,8.25121731 L2.8810847,7.19972949 L5.65791764,9.90054103
          L11.5189153,4.2 L12.6,5.25148783 L5.65430198,12 Z"
          id="path-7"
        />
      </defs>
      <g id="Nav-House" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="White-Level-Icon2" opacity="0.4" transform="translate(12.000000, 11.000000)">
          <g>
            <path
              d="M0.0481927711,10.1085184 L4.76706161,10.115802 L4.76706161,27.2296386 L34.4681573,27.2296386
              L34.4681573,10.115802 L39.0481928,10.115802 L31.4197216,6.1471119 L31.4191664,0.143189422
              L26.1423444,0.143189422 L26.1423444,3.40013459 L19.6152327,0.000722891566 L0.0481927711,10.1085184 Z
              M6.39156627,13.0127711 L32.9242094,13.0127711 L32.939759,25.7838554 L6.40716787,25.7838554 L6.39156627,
              13.0127711 Z"
              id="White-Level-Icon"
              :fill="`url(#radialGradient-12${navType})`"
            />
            <polygon id="Rectangle" fill="#18344E" points="6.39435792 13.0127711 32.9435943 13.0127711 32.9591537 25.7838554 6.39435792 25.7838554" />
            <path
              d="M6.89055335,9.3015749 L6.89384557,12.5127711 L32.4587727,12.5127711 L32.4563273,9.30178306
              L19.6766929,2.56589179 L6.89055335,9.3015749 Z"
              id="Rectangle"
              stroke="#18344E"
              :fill="`url(#radialGradient-22${navType})`"
            />
          </g>
        </g>
        <g id="Orange-Level-Icon2" opacity="0.4" transform="translate(12.000000, 11.000000)">
          <g>
            <polygon
              id="Rectangle"
              fill="#18344E"
              points="6.39435792 8.78385542 19.6767558 1.41036145 32.9435943 8.78385542 32.9591537 25.7838554
              6.39435792 25.7838554"
            />
            <path
              d="M0.0481927711,10.1084729 L4.76703321,10.1158544 L4.76703321,27.2296386 L34.4681425,27.2296386
              L34.4681425,10.1158544 L39.0481928,10.1158544 L31.4196594,6.1471682 L31.4191736,0.143180378
              L26.1423879,0.143180378 L26.1423879,3.40008673 L19.6151981,0.000722891566 L0.0481927711,10.1084729 Z
              M6.39156627,13.4606925 L6.39156627,9.27205608 L19.5299903,2.41036145 L32.939759,9.41343531 L32.939759,
              13.4946988 L6.39156627,13.4606925 Z M6.40017731,19.5187952 L32.939759,19.5187952 L32.939759,25.7838554
              L6.39156627,25.7838554 L6.40017731,19.5187952 Z"
              id="Orange-Level-Icon"
              :fill="`url(#radialGradient-32${navType})`"
            />
            <path d="M6.89404634,13.9 L6.89055568,19.499759 L32.456329,19.499759 L32.4589221,13.9 L6.89404634,13.9 Z" id="Rectangle" stroke="#18344E" :fill="`url(#radialGradient-42${navType})`" />
          </g>
        </g>
        <g id="Blue-Level-Icon2" opacity="0.4" transform="translate(12.000000, 11.000000)">
          <g>
            <polygon
              id="Rectangle"
              fill="#18344E"
              points="6.39435792 8.78385542 19.6767558 1.41036145 32.9435943 8.78385542 32.9591537 25.7838554
              6.39435792 25.7838554"
            />
            <path
              d="M0.0481927711,10.1108701 L4.76705398,10.1181643 L4.76705398,27.2296386 L34.468165,27.2296386
              L34.468165,10.1181643 L39.0481928,10.1181643 L31.4197199,6.14855624 L31.4191647,0.143222694 L26.1423416,
              0.143222694 L26.1423416,3.4009463 L19.6152197,0.000722891566 L0.0481927711,10.1108701 Z M6.40023796,
              9.21092306 L19.5343354,2.41036145 L32.939759,9.35099862 L32.939759,19.759759 L6.39156627,19.759759
             L6.40023796,9.21092306 Z"
              id="Blue-Level-Icon"
              :fill="`url(#radialGradient-52${navType})`"
            />
            <path
              d="M6.89402838,19.259759 L6.89057366,24.499759 L32.4563424,24.499759 L32.4589087,19.259759
              L6.89402838,19.259759 Z"
              id="Rectangle"
              stroke="#18344E"
              :fill="`url(#radialGradient-62${navType})`"
            />
          </g>
        </g>
        <g id="White-Level-Icon2" transform="translate(12.000000, 11.000000)">
          <g>
            <path
              d="M0.0481927711,10.1085184 L4.76706161,10.115802 L4.76706161,27.2296386 L34.4681573,27.2296386
              L34.4681573,10.115802 L39.0481928,10.115802 L31.4197216,6.1471119 L31.4191664,0.143189422 L26.1423444,
              0.143189422 L26.1423444,3.40013459 L19.6152327,0.000722891566 L0.0481927711,10.1085184 Z M6.39156627,
              13.0127711 L32.9242094,13.0127711 L32.939759,25.7838554 L6.40716787,25.7838554 L6.39156627,13.0127711 Z"
              id="White-Level-Icon"
              :fill="`url(#radialGradient-12${navType})`"
            />
            <polygon id="Rectangle" fill="#18344E" points="6.39435792 13.0127711 32.9435943 13.0127711 32.9591537 25.7838554 6.39435792 25.7838554" />
            <path
              d="M6.89055335,9.3015749 L6.89384557,12.5127711 L32.4587727,12.5127711 L32.4563273,9.30178306 L19.6766929,
              2.56589179 L6.89055335,9.3015749 Z"
              id="Rectangle"
              stroke="#18344E"
              :fill="`url(#radialGradient-22${navType})`"
            />
          </g>
        </g>
        <g v-if="svgType === 2" id="Orange-Level-Icon2" transform="translate(12.000000, 11.000000)">
          <g>
            <polygon
              id="Rectangle"
              fill="#18344E"
              points="6.39435792 8.78385542 19.6767558 1.41036145 32.9435943 8.78385542 32.9591537 25.7838554
              6.39435792 25.7838554"
            />
            <path
              d="M0.0481927711,10.1084729 L4.76703321,10.1158544 L4.76703321,27.2296386 L34.4681425,27.2296386
              L34.4681425,10.1158544 L39.0481928,10.1158544 L31.4196594,6.1471682 L31.4191736,0.143180378 L26.1423879,
              0.143180378 L26.1423879,3.40008673 L19.6151981,0.000722891566 L0.0481927711,10.1084729 Z M6.39156627,
              13.4606925 L6.39156627,9.27205608 L19.5299903,2.41036145 L32.939759,9.41343531 L32.939759,13.4946988
              L6.39156627,13.4606925 Z M6.40017731,19.5187952 L32.939759,19.5187952 L32.939759,25.7838554 L6.39156627,
              25.7838554 L6.40017731,19.5187952 Z"
              id="Orange-Level-Icon"
              :fill="`url(#radialGradient-32${navType})`"
            />
            <path d="M6.89404634,13.9 L6.89055568,19.499759 L32.456329,19.499759 L32.4589221,13.9 L6.89404634,13.9 Z" id="Rectangle" stroke="#18344E" :fill="`url(#radialGradient-4${navType})`" />
          </g>
        </g>
        <g v-if="svgType === 1" id="Blue-Level-Icon2" transform="translate(12.000000, 11.000000)">
          <g>
            <polygon
              id="Rectangle"
              fill="#18344E"
              points="6.39435792 8.78385542 19.6767558 1.41036145 32.9435943 8.78385542 32.9591537 25.7838554 6.39435792
              25.7838554"
            />
            <path
              d="M0.0481927711,10.1108701 L4.76705398,10.1181643 L4.76705398,27.2296386 L34.468165,27.2296386
              L34.468165,10.1181643 L39.0481928,10.1181643 L31.4197199,6.14855624 L31.4191647,0.143222694 L26.1423416,
              0.143222694 L26.1423416,3.4009463 L19.6152197,0.000722891566 L0.0481927711,10.1108701 Z M6.40023796,
              9.21092306 L19.5343354,2.41036145 L32.939759,9.35099862 L32.939759,19.759759 L6.39156627,19.759759
              L6.40023796,9.21092306 Z"
              id="Blue-Level-Icon"
              :fill="`url(#radialGradient-52${navType})`"
            />
            <path
              d="M6.89402838,19.259759 L6.89057366,24.499759 L32.4563424,24.499759 L32.4589087,19.259759 L6.89402838,
              19.259759 Z"
              id="Rectangle"
              stroke="#18344E"
              :fill="`url(#radialGradient-62${navType})`"
            />
          </g>
        </g>
        <g id="Check" transform="translate(43.000000, 11.000000)" :style="{ display: active ? 'block' : 'none' }">
          <circle id="Oval" fill="#18344E" cx="7.5" cy="7.5" r="7.5" />
          <g id="Mask-3">
            <mask id="mask-8" fill="white">
              <use xlink:href="#path-7" />
            </mask>
            <use id="Mask" fill="#FFFFFF" fill-rule="nonzero" xlink:href="#path-7" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HouseNavSvg',
  props: {
    svgTitle: {
      type: String,
      required: true,
      default: '',
    },
    svgType: {
      type: Number,
      required: true,
      default: 1,
    },
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    navType: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>
