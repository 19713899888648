<i18n src='./locales.json'></i18n>
<template>
  <div class="mainContainerBackground">
    <v-layout :class="$vuetify.breakpoint.xs ? 'pl-4 pr-4' : 'pl-4 pr-4'" row wrap>
      <v-flex xs12 pt-4 font-size--24 class="font-weight-bold" color="#686868">
        {{ $t('L_debt_solution') }}
      </v-flex>
    </v-layout>
    <v-layout :class="$vuetify.breakpoint.xs ? 'pl-4 pr-4' : 'pl-4 pr-4'">
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'font-size--16' : 'font-size--18'">
        {{ $t('L_enter_your_debt_information') }}
      </v-flex>
    </v-layout>
    <v-layout :class="$vuetify.breakpoint.xs ? 'pl-4 pr-4' : 'pl-4 pr-4'">
      <v-flex pt-3 xs12 :class="$vuetify.breakpoint.xs ? 'font-size--16' : 'font-size--18'">
        {{ $t('L_revisit_this_page_to') }}
      </v-flex>
    </v-layout>
    <div v-show="true">
      <v-layout row wrap :class="$vuetify.breakpoint.xs ? 'pl-4 pr-4' : 'pl-4 pr-4'">
        <v-flex pt-3 xs12 :class="$vuetify.breakpoint.xs ? 'font-size--16' : 'font-size--18'">
          {{ $t('L_in_order_to_provide_an_accurate') }}
        </v-flex>
      </v-layout>
      <v-layout row wrap class="dobinput" :class="$vuetify.breakpoint.xs ? 'pl-4 pr-4' : 'pl-4 pr-4'">
        <v-flex xs12 sm8 md5 pt-4>
          <DateInput
            id="persondob"
            v-model="persondob"
            :form-input="$v.persondob"
            :label="$t('L_birth_date')"
            :quebec="isFrenchLocale"
            :validation-messages="{
              dateBetween18And100: $t('L_invalid_date_must_be_for_ages'),
            }"
          />
        </v-flex>
      </v-layout>
    </div>
    <v-layout row wrap>
      <v-flex xs12 sm12 md8>
        <DebtDetails :disablebutton="$v.persondob.$invalid" :tpersondob="persondob" @is-debt-input-hidden="setHideDebtInput" />
      </v-flex>
      <v-flex xs12 sm12 md4>
        <DebtSummary @call-contact-rep="bubbleUpContactRepRequest" zv-if="!$vuetify.breakpoint.xs || ($vuetify.breakpoint.xs && hideDebtInput)" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

import moment from 'moment';
import { DateInput, DateValidation } from '@/components/Inputs';
import { mapActions, mapGetters } from 'vuex';
import { dateBetween18And100 } from '../../../policy/details/components/AddChild/dateValidation';
import Alert from '../../../../components/Alert.vue';
import DebtDetails from './components/DebtDetails.vue';
import DebtSummary from './components/DebtSummary.vue';


export default {
  name: 'MyDebt',
  components: {
    DebtDetails,
    DebtSummary,
    Alert,
    DateInput,
    DateValidation,
  },
  data: () => ({
    persondob: '',
    hideDebtInput: true,
  }),
  validations() {
    return {
      persondob: {
        dateBetween18And100: dateBetween18And100(false, this.isFrenchLocale),
      },
    };
  },
  computed: {
    ...mapGetters({
      paidOffDebts: 'debtsolutions/paidOffDebts',
      unpaidDebts: 'debtsolutions/unpaidDebts',
      step: 'debtsolutions/step',
      scenario: 'debtsolutions/scenario',
      personInfo: 'debtsolutions/personInfo',
      investments: 'customer/investments',
      currentPolicy: 'policy/currentPolicy',
    }),
    isFrenchLocale() {
      if (this.locale?.length > 0) {
        if (this.locale.toLowerCase().indexOf('fr') > -1) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  watch: {
    async persondob(newData, oldData) {
      const value = newData;
      try {
        if (!!newData && oldData !== newData && !this.$v.persondob.$invalid && this.$v.persondob.$dirty) {
          const tDate = moment(value, 'MMDDYYYY');

          // Start of scenario
          // Add logic for grabbing this.scenario
          const tempScenario = JSON.parse(JSON.stringify(this.scenario));
          // set dob and currentage for this.scenario
          // Check for non null, not {}
          if (tempScenario && Object.keys(tempScenario).length !== 0 && tempScenario.constructor === Object &&
              tempScenario.personInfo != null && tempScenario.personInfo.length > 0) {
            tempScenario.personInfo[0].dob = moment(tDate).format('YYYY-MM-DDT00:00:00-00:00');
            tempScenario.personInfo[0].currentAge = moment().diff(tempScenario.personInfo[0].dob, 'years');
            this.setScenario(tempScenario);
          }
          // End of scneario


          // Start of this.personInfo
          let tempPersonInfo = null;
          if (this.personInfo != null) {
            tempPersonInfo = JSON.parse(JSON.stringify(this.personInfo));
          }
          if (tempPersonInfo && Object.keys(tempPersonInfo).length !== 0 && tempPersonInfo.constructor === Object) {
            tempPersonInfo.dob = moment(tDate).format('YYYY-MM-DDT00:00:00-00:00');
            tempPersonInfo.currentAge = moment().diff(tempPersonInfo.dob, 'years');
          } else {
            tempPersonInfo = {
              personType: 'P',
              name: 'Primary Debt',
              currentAge: 0, // 46 Grab from  life or investment
              retirementAge: 0, // 67 Grab from  investment  else use default CA: 65 US: 67
              dob: '', // '1974-06-06T00:00:00' Grab from life or investment
              grossMonthlyIncome: 5000.00,
            };
            tempPersonInfo.dob = moment(tDate).format('YYYY-MM-DDT00:00:00-00:00');
            tempPersonInfo.currentAge = moment().diff(tempPersonInfo.dob, 'years');
          }
          await this.setPersonInfo(tempPersonInfo);
          // End of this.personInfo

          await this.callPushDebts(this.persondob);
        }
      } catch (error) {
        /* TODO: UnhandledPromiseRejection */
      }
    },
  },
  methods: {
    ...mapActions({
      debtInit: 'debtsolutions/debtInit',
      setPersonInfo: 'debtsolutions/setPersonInfo',
      callPushDebts: 'debtsolutions/callPushDebts',
      setScenario: 'debtsolutions/setScenario',
    }),
    bubbleUpContactRepRequest() {
      this.$emit('contact-your-rep', true);
    },
    setHideDebtInput(requestObject) {
      if (requestObject != null) {
        this.hideDebtInput = requestObject;
      }
    },
  },
  async mounted() {
    if (this.step === undefined || this.step === -1) {
      try {
        await this.debtInit();
      // eslint-disable-next-line no-empty
      } catch (e) {}
      // this.$router.push({ name: 'Dashboard' });
    }

    // This is to update MyPlan data using the updated retirementAge
    if (this.investments?.calculator?.scenario?.retirement?.age !== this.personInfo?.retirementAge) {
      this.debtInit();
    }

    // Scenario as first source of truth
    if (this.scenario != null && this.scenario.personInfo != null && this.scenario.personInfo.length > 0) {
      const personInfoDobCopy = JSON.parse(JSON.stringify(this.scenario.personInfo[0]?.dob));
      if (personInfoDobCopy != null) {
        const tdate1 = moment(personInfoDobCopy, 'YYYY-MM-DDTHH:mm:ss-nn:00');
        if (tdate1.isValid()) {
          this.persondob = tdate1.format('MMDDYYYY');
        }
      }
    }

    // Investment as 2nd source of truth for dob
    if (this.investments?.calculator?.scenario?.dateOfBirth != null && (this.persondob == null || this.persondob === '')) {
      const invDobCopy = JSON.parse(JSON.stringify(this.investments?.calculator?.scenario?.dateOfBirth));
      const tdate2 = moment(invDobCopy, 'YYYY-MM-DD');
      if (tdate2.isValid()) {
        this.persondob = tdate2.format('MMDDYYYY');
      }
    }

    // 3rd Life Policy
    // Setting agentId
    if (this.currentPolicy != null) {
      //      this.scenario.agentId = this.currentPolicy?._agentId;

      // Looking at life policy to fill the dob if this.persondob is still empty
      if (this.currentPolicy._phases != null && (this.persondob == null || this.persondob === '')) {
        const phases = this.currentPolicy?._phases;
        phases.forEach((phase) => {
        // Setting the persondob only if it's not null or empty string
          if (phase?.phaseNo === '01' && phase?.personDateOfBirth != null) {
            const lifeDobCopy = JSON.parse(JSON.stringify(phase?.personDateOfBirth));
            const tdate3 = moment(lifeDobCopy, 'MM/DD/YYYY');
            if (tdate3.isValid()) {
              this.persondob = tdate3.format('MMDDYYYY');
            }
          }
        });
      }
    }
    // Other data besides DOB will be handled in pushDebts method in actions.js
    // Resetting the flag for $v for persondob
    try {
      this.$v.persondob.$reset();
    } catch (error) {
      /* TODO: UnhandledPromiseRejection */
    }
  },
};

</script>
<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
.mainContainerBackground
  background-color: #ffffff;
  padding-bottom: 0px;
  padding-left:0px;
  padding-right:0px;
.modal
  position: fixed; /* Stay in place */
  z-index: 11; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
.dobinput
  max-width:300px;
</style>
