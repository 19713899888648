<i18n src='./locales.json'></i18n>

<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-tooltip
        :value="tooltip.password"
        :open-delay="10000000"
        :close-delay="10000000"
        :max-width="this.$vuetify.breakpoint.mdAndUp ? '186' : '162'"
        right
        color="primary"
        :disabled="!showToolTip || this.$vuetify.breakpoint.xsOnly"
      >
        <PasswordInput
          :id-prop="newPassId"
          slot="activator"
          v-model="password"
          :form-input="$v.password"
          :label="$t(passwordLabel)"
          disable-autocomplete
          :messages="$t('L_password_hint')"
          enable-masking
          :enable-show-hide="true"
          @focus="onFocusPassword"
          @input="onInput"
          @blur="toggleTooltip({ field: 'password', show: false })"
          aria-described-by="password-tooltip"
        />
        <span role="tooltip" id="password-tooltip">
          {{ $t('L_password_tooltip') }}
        </span>
      </v-tooltip>
    </v-flex>
    <v-flex xs12 v-show="this.showPasswordHintList">
      <PasswordComplexity
        :password-dirty="$v.password.$dirty"
        :min-length-valid="$v.password.minLength && $v.password.required"
        :upper-lower-valid="$v.password.requireUpperLowerCase && $v.password.required"
        :number-valid="$v.password.requireNumber && $v.password.required"
        :symbol-valid="$v.password.requireSymbol && $v.password.required"
        :error-messages="passwordErrors"
      />
    </v-flex>
    <v-flex xs12 mt-3>
      <v-tooltip
        :value="tooltip.confirmPassword"
        :max-width="this.$vuetify.breakpoint.mdAndUp ? '186' : '162'"
        :open-delay="10000000"
        :close-delay="10000000"
        right
        color="primary"
        :disabled="!showToolTip || !this.$vuetify.breakpoint.smAndUp"
      >
        <ConfirmPasswordInput
          :id-prop="confirmPassId"
          slot="activator"
          v-model="confirmPassword"
          :form-input="$v.confirmPassword"
          :label="$t(confirmPasswordLabel)"
          disable-autocomplete
          enable-masking
          :enable-show-hide="true"
          maxlength="118"
          @focus="toggleTooltip({ field: 'confirmPassword', show: true })"
          @input="onInput"
          @blur="toggleTooltip({ field: 'confirmPassword', show: false })"
          aria-described-by="confirm-password-tooltip "
        />
        <span role="tooltip" id="confirm-password-tooltip">
          {{ $t('L_confirm_password_tooptip') }}
        </span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { PasswordInput, ConfirmPasswordInput } from '@/components/Inputs';
import { noSpaces, noInvalidSpecialChars, requireUpperLowerCase, requireNumber, requireSymbol, noThreeCharsInRow, notContainString, getErrorMessages } from '@/components/Inputs/validation';
import { toggleTooltip } from '@/shared/util/helpers';
import PasswordComplexity from './components/PasswordComplexity/PasswordComplexity.vue';

export default {
  name: 'PasswordForm',
  props: {
    newPassId: {
      type: String,
      default: '',
    },
    confirmPassId: {
      type: String,
      required: true,
      default: '',
    },
    value: {
      type: [String],
      required: false,
      default: undefined,
    },
    email: {
      type: String,
      required: false,
      default: '',
    },
    passwordLabel: {
      type: String,
      required: false,
      default: 'L_password',
    },
    confirmPasswordLabel: {
      type: String,
      required: false,
      default: 'L_confirm_password',
    },
    showToolTip: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    password: '',
    confirmPassword: '',
    tooltip: {
      password: false,
      confirmPassword: false,
    },
    showPasswordHintList: false,
    modelName: 'passwordForm',
  }),
  validations: {
    password: {
      required,
      minLength: minLength(8),
      noSpaces,
      noInvalidSpecialChars,
      requireUpperLowerCase,
      requireNumber,
      requireSymbol,
      noThreeCharsInRow,
      notContainString: (value, vm) => {
        if (vm.email.length === 0) {
          return true;
        }
        const valid = notContainString(value, vm.email);
        return valid;
      },
    },
    confirmPassword: {
      required,
      sameAs: sameAs('password'),
    },
  },
  computed: {
    passwordErrors() {
      return getErrorMessages({
        vObject: this.$v,
        field: 'password',
      });
    },
  },
  methods: {
    onInput() {
      this.$emit('input', {
        password: this.$v.password.$invalid ? '' : this.password,
        confirmPassword: this.$v.confirmPassword.$invalid ? '' : this.confirmPassword,
      });
    },
    toggleTooltip,
    onFocusPassword() {
      this.toggleTooltip({ field: 'password', show: true });
      this.showPasswordHintList = true;
    },
    onMessage(message) {
      if (message.data === 'form-reset') {
        this.$v.$reset();
      }
    },
  },
  components: {
    PasswordComplexity,
    PasswordInput,
    ConfirmPasswordInput,
  },
  watch: {
    value(value) {
      if (typeof value === 'undefined') {
        this.password = '';
        this.confirmPassword = '';
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.onMessage.bind(this));
  },
};
</script>
