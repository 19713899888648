<i18n src='../locales.json'></i18n>

<template>
  <v-container class="containerSize">
    <!-- <LoadingIndicator :loading="asyncProcessInProgress" /> -->
    <v-layout row class="white--text text-xs-left">
      <v-flex class="mb-3">
        <span class="title mb-2">
          <v-btn fab dark small color="#D9D9D9" class="ml-0">
            <v-icon color="#063562">
              add_link
            </v-icon>
          </v-btn>
          {{ $t('mal_link_a_minors_acct') }}
        </span>
      </v-flex>
      <v-spacer />
    </v-layout>
    <MessageDialog
      :show-dialog="showMinorAccountNotFound"
      :has-button-two="false"
      :title="$t('mal_account_not_found')"
      :message="$t('mal_no_minor_accounts_were_found', { customerServicePhone: customerServicePhoneNumber })"
      :button-one-label="$t('L_OK')"
      @buttonOneAction="showMinorAccountNotFound = false"
    />
    <MessageDialog
      :show-dialog="showMinorAccountsNotLinked"
      :has-button-two="false"
      :title="$t('mal_account_not_linked')"
      :button-one-label="$t('L_OK')"
      @buttonOneAction="showMinorAccountsNotLinked = false"
    >
      <template #message>
        <p>{{ $t('mal_no_minor_accounts_were_linked') }}</p>
        <p>{{ $t('mal_please_try_to_relink') }}</p>
      </template>
    </MessageDialog>
    <NavigationConfirmationDialog v-if="showNavigationConfirmationDialog" @confirm-navigation="navigateToRoute" @cancel-navigation="showNavigationConfirmationDialog = false" />

    <v-stepper v-model="minorAccountLinkProcess" :vertical="isMobileView">
      <v-stepper-header v-if="!isMobileView">
        <v-stepper-step :complete="minorAccountLinkProcess > 1" step="1">
          {{ $t('mal_step_enter_information_header') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="minorAccountLinkProcess > 2" step="2">
          {{ $t('mal_step_review_header') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="minorAccountLinkProcess > 3" step="3">
          {{ $t('mal_step_confirmation_header') }}
        </v-stepper-step>
      </v-stepper-header>
      <div v-if="asyncProcessInProgress" class="text-xs-center ma-4">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <v-stepper-items>
        <v-stepper-step :complete="minorAccountLinkProcess > 1" step="1" v-if="isMobileView">
          {{ $t('mal_step_enter_information_header') }}
        </v-stepper-step>
        <v-stepper-content step="1">
          <!-- has async process -->
          <EnterInformation
            :disable-actions="asyncProcessInProgress"
            @continueClicked="searchForMinorAccounts"
            @cancelClicked="cancelMinorAccountLinkStep()"
          />
        </v-stepper-content>

        <v-stepper-step :complete="minorAccountLinkProcess > 2" step="2" v-if="isMobileView">
          {{ $t('mal_step_review_header') }}
        </v-stepper-step>
        <v-stepper-content step="2">
          <!-- has async process -->
          <InformationReview
            :disable-actions="asyncProcessInProgress"
            :minor-accounts="minorAccountsFound"
            @continueClicked="reviewFoundMinorAccountsToBeLinked" @cancelClicked="cancelMinorAccountLinkStep()"
          />
        </v-stepper-content>

        <v-stepper-step :complete="minorAccountLinkProcess > 3" step="3" v-if="isMobileView">
          {{ $t('mal_step_confirmation_header') }}
        </v-stepper-step>
        <v-stepper-content step="3">
          <LinkConfirmation
            :disable-actions="asyncProcessInProgress"
            :minor-accounts-linked="minorAccountsLinked"
            :minor-accounts-not-linked="minorAccountsNotLinked"
            @continueClicked="confirmLinkedMinorAccounts()"
            @cancelClicked="cancelMinorAccountLinkStep()"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EnterInformation from '../components/EnterInformation.vue';
import InformationReview from '../components/InformationReview.vue';
import LinkConfirmation from '../components/LinkConfirmation.vue';
import MessageDialog from '../../../components/MessageDialog.vue';
import NavigationConfirmationDialog from '../../../components/NavigationConfirmation/NavigationConfirmationDialog.vue';
import LoadingIndicator from '../../../components/LoadingIndicator.vue';

export default {
  name: 'ReinstatementLayout',
  components: {
    LoadingIndicator,
    EnterInformation,
    InformationReview,
    LinkConfirmation,
    NavigationConfirmationDialog,
    MessageDialog,
  },
  data() {
    return {
      asyncProcessInProgress: false,
      listener: null,
      minorAccountLinkProcess: 1,
      minorAccountsFound: [],
      minorAccountsLinked: [],
      minorAccountsNotLinked: [],
      mobileView: false,
      showNavigationConfirmationDialog: false,
      navigatingToRoute: '',
      navigationAwayConfirmed: false,
      showMinorAccountNotFound: false,
      showMinorAccountsNotLinked: false,
      customerServicePhoneNumber: '(800) 257-4725',
    };
  },
  computed: {
    ...mapGetters({ currentPolicy: 'policy/currentPolicy' }),
    isMobileView() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    ...mapActions('customer', ['searchMinorAccount', 'linkMinorAccount', 'getInvestments']),
    ...mapActions(['postPolicyReinstatement']),
    formatFromUS(date) {
      const year = date.slice(4);
      const month = date.slice(0, 2);
      const day = date.slice(2, 4);
      return `${year}-${month}-${day}`;
    },
    async searchForMinorAccounts({ dob, taxId }) {
      this.asyncProcessInProgress = true;
      const minorAcctsFound = await this.searchMinorAccount({
        taxId, // '539968784',
        dob: this.formatFromUS(dob), // '1994-01-30',
      })
        .catch(() => {
          this.asyncProcessInProgress = false;
          return [];
        })
        .finally(() => { this.asyncProcessInProgress = false; });

      this.minorAccountsFound = minorAcctsFound;

      if (!minorAcctsFound.length) {
        this.showMinorAccountNotFound = true;
      }

      // go to the next OR stay on the current minor account link process
      this.minorAccountLinkProcess = minorAcctsFound.length ? 2 : 1;
    },
    async reviewFoundMinorAccountsToBeLinked() {
      this.asyncProcessInProgress = true;
      const linkAccountsPromise = this.minorAccountsFound.map((acct) => {
        const linkMinorAccountPromise = this.linkMinorAccount(acct.accountId)
          .then(data => ({ ...{ isLinked: data }, ...acct }))
          .catch(() => ({ ...{ isLinked: false }, ...acct }));
        return linkMinorAccountPromise;
      });

      await Promise.allSettled(linkAccountsPromise)
        .then((results) => {
          const successfullyLinkedRequests = results.filter(({ status, value }) => status === 'fulfilled' && value?.isLinked);
          const failedLinkedRequests = results.filter(({ status, value }) => status === 'rejected' || !value?.isLinked);
          const linkedMinorAccountIds = successfullyLinkedRequests.map(({ value }) => value?.accountId); // array of successfully linked account ids
          const nonlinkedMinorAccountIds = failedLinkedRequests.map(({ value }) => value?.accountId); // array of unsuccessfuly linked account ids
          this.minorAccountsLinked = this.minorAccountsFound.filter(acct => linkedMinorAccountIds.includes(acct?.accountId));
          this.minorAccountsNotLinked = this.minorAccountsFound.filter(acct => nonlinkedMinorAccountIds.includes(acct?.accountId));
        })
        .catch(() => { this.asyncProcessInProgress = false; })
        .finally(() => { this.asyncProcessInProgress = false; });

      // no minor accounts linked
      if (!this.minorAccountsLinked.length) {
        this.showMinorAccountsNotLinked = true;
      }
      // go to the next OR stay on the current minor account link process
      this.minorAccountLinkProcess = this.minorAccountsLinked.length ? 3 : 2;
    },
    async confirmLinkedMinorAccounts() {
      this.asyncProcessInProgress = true;
      await this.getInvestments()
        .finally(() => { this.asyncProcessInProgress = false; });
      await this.$router.push({ path: '/investments' });
    },
    async cancelMinorAccountLinkStep() {
      await this.$router.push({ path: '/investments' }).catch(() => {
        // TODO: use DT and remove console logs
      });
    },
    navigateToRoute() {
      this.navigationAwayConfirmed = true;
      this.$router.push({ name: this.navigatingToRoute }).catch(() => {
        // TODO: use DT and remove console logs
      });
    },
  },
  watch: {
    isMobileView(newVal /* , oldVal */) {
      this.mobileView = !newVal;
    },
  },
  created() {
    this.mobileView = this.$vuetify.breakpoint.xsOnly;
    this.listener = window.addEventListener('beforeunload', (event) => {
      // TODO: do something, or remove this
      // eslint-disable-next-line no-console
      console.info(`beforeunload: ${event}`);
    });
  },
  beforeDestroy() {
    if (this.listener) {
      window.removeEventListener('resize', this.listener);
    }
  },
  beforeRouteLeave(to, from, next) {
    const isMinorAccountLinkProcessDone = this.minorAccountLinkProcess === 3 || this.navigationAwayConfirmed;
    const navigatingOutsideMinorAccountLink = to.name !== 'MinorAccountLinkProcess' && to.path !== '/minor-account-link-process';
    if (navigatingOutsideMinorAccountLink && !isMinorAccountLinkProcessDone) {
      this.showNavigationConfirmationDialog = true; // show NavigationConfirmationDialog
      this.navigatingToRoute = to.name; // save the route name
      next(false); // cancel the navigation
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.containerSize {
  max-width:1137px;
  margin: auto;
}
</style>

