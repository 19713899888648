import MyPlan from './MyPlan.vue';

export default {
  name: 'MyPlan',
  path: 'myplan',
  component: MyPlan,
  params: {
    page: 'myplan',
  },
  meta: {
    pageTitle: 'My.Plan',
    indexOfRoute: 1,
  },
};
