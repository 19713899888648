import DashboardLayout from '../../layouts/DashboardLayout.vue';
import ChildRiderLayout from './layouts/ChildRiderLayout.vue';

export default {
  path: '/childrider',
  component: DashboardLayout,
  children: [
    {
      path: '',
      beforeEnter: (to, from, next) => {
        next({ name: 'ChildRiderLayout' });
      },
    },
    {
      name: 'ChildRiderLayout',
      path: '',
      component: ChildRiderLayout,
      meta: {
        pageTitle: 'Landing.ChildRider',
      },
    },
  ],
};
