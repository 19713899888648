<i18n src='../../locales.json'></i18n>
<template>
  <v-layout
    v-if="!devicesDeleted"
    wrap
  >
    <v-flex xs12>
      <p>
        {{ $t('L_to_sign_in_again_devices') }}
      </p>
    </v-flex>
    <v-layout
      :class="$vuetify.breakpoint.xsOnly ? 'fixed--bottom': ''"
      wrap
      justify-center
    >
      <v-flex
        sm10
        xs12
        mb-1
        mt-2
      >
        <v-btn
          color="primary"
          block
          @click="$emit('delete')"
        >
          {{ $t('L_remove_devices') }}
        </v-btn>
      </v-flex>
      <v-flex
        sm10
        xs12
      >
        <v-btn
          color="secondary"
          block
          @click="$emit('close')"
        >
          {{ $t('L_cancel') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-layout>
  <v-layout
    v-else
    wrap
    class="pt-4 mt-4"
  >
    <Alert
      id="alert_remove"
      :show-alert="true"
      :dismissable="false"
      :auto-dismiss="false"
      alert-icon="check_circle"
      type="success"
      :new-alert-type="true"
    >
      <span slot="message" class="font-weight-medium">
        {{ $t('L_all_devices_successfully_removed') }}
      </span>
    </Alert>
    <v-flex xs12 class="pt-4 mt-4 text-xs-center">
      <p>
        {{ $t('L_there_are_currently_no_trusted_devices') }}
      </p>
    </v-flex>
    <v-layout
      :class="$vuetify.breakpoint.xsOnly ? 'fixed--bottom': ''"
      wrap
      justify-center
    >
      <v-flex
        sm10
        xs12
        mb-1
        mt-2
      >
        <v-btn
          color="primary"
          block
          class="mb-1"
          @click="$emit('close')"
        >
          {{ $t('L_close') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import Alert from '@/components/Alert/Alert.vue';

export default {
  name: 'RemoveDevices',
  components: { Alert },
  props: {
    devicesDeleted: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style>
#alert_remove {
  position: absolute;
  top: 95px;
  left: 0;
  width: 100%;
}
</style>
