<template>
  <v-dialog transition="scale-transition" origin="center center" id="two_factor_action" :value="show" :max-width="554" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <slot v-if="currentStep === 0" :start="requestOTP" />
    <v-card v-else id="otp-card" class="text-xs-center">
      <Alerts class="fixed-top-alert" :context="alertContext" />
      <v-card-text>
        <v-layout justify-center>
          <v-flex xs9 sm10 md9>
            <h2 class="heading_two mt-4 mb-2">
              {{ $t('L_verify_your_mobile_phone_number') }}
            </h2>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="pl-3 pr-3">
          <form @submit.prevent ref="twoFactorForm">
            <v-flex xs2>
              &nbsp;
            </v-flex>
            <v-flex xs10 mb-4 mt-2 font-size--14>
              <i18n path="L_two_step_authentication_helps_keep_your_account_more_secure" tag="p" class="font-weight-regular">
                <span class="font-weight-medium">
                  {{ twoFactorPayload.channel === 'SMS' ? formattedContactNumber(twoFactorPayload.phone) : twoFactorPayload.email }}
                </span>
                <span>{{ twoFactorPayload.channel === 'SMS' ? $t('L_a_text_message') : $t('L_an_email') }}</span>
                <!-- v-if -->
                <span v-if="$route.name === 'MyAccount'">{{ $t('L_if_the_mobile_number_above_is_incorrect_you_may') }}</span>
                <span v-if="$route.name === 'MyAccount'" class="editItHere" @click="goBackToEdit">{{ $t('L_edit_it_here') }}</span>
                <!-- v-else -->
                <span v-if="$route.name === 'Dashboard' || $route.name === 'AlertAndNotifications'"> {{ $t('L_if_the_mobile_number_above_is_incorrect_you_can_change_it') }}</span>
              </i18n>
            </v-flex>
            <v-flex mb-4 pt-2>
              <span class="font-weight-bold font-size--14">{{ $t('L_enter_the_verification_code') }}</span>
            </v-flex>
            <v-layout row wrap class="font-weight-regular">
              <v-flex class="mb-4 mt-2 xs12">
                <v-text-field
                  v-model="otpCode"
                  id="opt-code"
                  label=""
                  required
                  clear-icon="cancel"
                  mask="######"
                  type="tel"
                  style="padding-bottom: 5px"
                  :error-messages="otpErrorMessages"
                  @input="checkOTPErrors"
                  @focus="checkOTPErrors"
                >
                  <template slot="append">
                    <div v-if="!invalidOtp" style="display: inline-block" aria-live="assertive">
                      <span style="position:absolute; left:-2000px;">Input is valid</span>
                      <v-icon class="right-icon" color="success">
                        check_circle
                      </v-icon>
                    </div>
                  </template>
                </v-text-field>
                <v-layout wrap class="font-size--12 text-xs-left mt-3">
                  <v-flex xs12 v-if="!hideResend">
                    <v-layout wrap>
                      <v-flex xs10>
                        {{ $t('L_didnt_receive_one') }}
                        <a href="javascript:void(0)" @click="resendOTP">{{ sending ? $t('L_sending') : $t('L_resend_code') }}</a>
                      </v-flex>
                      <v-flex xs10 mb-4>
                        {{ $t('L_please_wait_25_seconds') }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 v-if="trustDeviceOption">
                    {{ $t('L_for_your_added_security') }}
                  </v-flex>
                  <v-flex>
                    <v-btn v-if="!maxedOut" block color="primary" class="mb-3" id="acceptBtnId" :disabled="invalidOtp" @click="submitAction">
                      {{ $t('L_next') }}
                    </v-btn>
                    <v-btn block color="primary" class="mb-3" id="close_btn_id" :loading="loading" :disabled="false" @click="closeAction">
                      {{ $t('L_close') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </form>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { minLength, required } from 'vuelidate/lib/validators';
import { phoneNumber as phoneNumberFormatter } from '@/shared/util/formatters';
import { CHANNELS } from '../../shared/constants';
import messages from '../../shared/validation/messages';
import Alerts from '../Alerts.vue';

const STEPS = {
  MAIN_ACTION: 0,
  CHANNELS: 1,
  SUBMITOTP: 2,
};

const MAX_RESEND_COUNT = 3;
const MAX_ATTEMPT_COUNT = 3;

export default {
  name: 'TwoFactorWrapper',
  components: { Alerts },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    alertContext: {
      type: String,
      required: true,
      default: 'verify_phone',
    },
    twoFactorPayload: {
      type: Object,
      required: true,
      default: () => ({ channel: CHANNELS.SMS, phone: '', email: '' }),
    },
    submitActionFunc: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    submitPayload: {
      type: Object,
      required: true,
      default: () => {},
    },
    trustDeviceOption: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    currentStep: STEPS.MAIN_ACTION,
    otpCode: '',
    otpErrorMessages: [],
    attemptCount: 0,
    resendCount: 0,
    otpCodeExpired: false,
    maxedOut: false,
    mfaToken: '',
    submitActionPayload: {},
    sending: false,
  }),
  validations() {
    return {
      otpCode: {
        required,
        minLength: minLength(6),
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: 'home/loading',
      token: 'home/mfaToken',
    }),
    invalidOtp() {
      return this.$v.otpCode.$invalid || this.otpCode.length < 1;
    },
    hideResend() {
      return this.resendCount === MAX_RESEND_COUNT && this.maxedOut;
    },
  },
  methods: {
    ...mapActions({
      createChallenge: 'home/createChallenge',
    }),
    ...mapMutations({
      addError: 'addError',
      addAlert: 'addAlert',
      setLoading: 'home/setLoading',
      removeAlerts: 'removeAlerts',
    }),
    goBackToEdit() {
      this.$emit('gotoupdatecontact');
    },
    requestOTP(bypassNum) {
      this.removeAlerts(this.alertContext);
      this.setLoading(true);
      if (bypassNum && bypassNum.length > 0) {
        this.twoFactorPayload.phone = bypassNum;
      }
      this.twoFactorPayload.token = this.token;
      const challengeSpec = {
        viewContext: this.alertContext,
        payload: this.twoFactorPayload,
      };
      this.createChallenge(challengeSpec)
        .then(this.handleOTPSentSuccess)
        .catch((error) => {
          this.handleOTPSentFailure(error);
        })
        .finally(() => this.setLoading(false));
    },
    resendOTP() {
      if (this.sending) return;
      this.maxedOut = false;
      this.otpErrorMessages = [];
      this.sending = true;
      this.attemptCount = 0;
      this.resendCount += 1;
      setTimeout(() => {
        this.sending = false;
      }, 25000);
      this.requestOTP();
    },
    handleOTPSentSuccess() {
      this.currentStep = STEPS.SUBMITOTP;
      this.addAlert({
        type: 'success',
        details: { message: this.$t('L_verification_code_sent_sms_or_email', { type: this.$t(`L_sent_${this.twoFactorPayload.channel}`) }) },
        context: this.alertContext,
      });
    },
    handleOTPSentFailure() {
      this.currentStep = STEPS.SUBMITOTP;
    },
    submitAction() {
      this.setLoading(true);
      this.otpErrorMessages = [];
      const submitActionPayload = {
        ...this.submitPayload,
        token: this.token,
        verified: true,
        otp: this.otpCode,
      };
      this.submitActionFunc(submitActionPayload)
        .then(() => {
          this.$emit('success');
        })
        .catch(this.handleSubmitErrors)
        .finally(() => this.setLoading(false));
    },
    closeAction() {
      // this.$refs.twoFactorForm.reset();
      // this.otpCode = '';
      // this.currentStep = STEPS.MAIN_ACTION;
      // this.otpErrorMessages = [];
      // this.$v.reset();
      this.currentStep = STEPS.MAIN_ACTION;
      this.$emit('closeverifyphonedialog');
    },
    handleSubmitErrors(error) {
      const status = error?.response?.data?.statusCode;
      if (!status || status !== 400) {
        this.$emit('error', error);
        return;
      }
      this.handleOTPErrors(error);
    },
    handleOTPErrors(error) {
      const errorCode = error?.response?.data?.appStatusCode;
      let otpErrorMessage = '';

      if (errorCode === 'dcp-3003' || errorCode === 'dcp-3004') {
        this.attemptCount += 1;
        if (this.resendCount < MAX_RESEND_COUNT || (this.resendCount === MAX_RESEND_COUNT && this.attemptCount < MAX_ATTEMPT_COUNT)) {
          switch (this.attemptCount) {
            case 1:
              otpErrorMessage = messages.otpCode.firstAttempt;
              break;
            case 2:
              otpErrorMessage = messages.otpCode.secondAttempt;
              break;
            case 3:
              otpErrorMessage = messages.otpCode.thirdAttempt;
              this.maxedOut = true;
              break;
            default:
              otpErrorMessage = messages.otpCode.otpGenericInvalid;
          }
        } else if (this.resendCount > MAX_RESEND_COUNT || (this.resendCount === MAX_RESEND_COUNT && this.attemptCount === MAX_ATTEMPT_COUNT)) {
          otpErrorMessage = messages.otpCode.otpEnterCorrectNo;
          this.maxedOut = true;
        }
        this.otpErrorMessages.push(otpErrorMessage);
      }

      if (errorCode === 'dcp-3001') {
        this.maxedOut = true;
        if (this.resendCount < MAX_RESEND_COUNT) {
          this.otpErrorMessages.push(messages.otpCode.otpCodeExceeded);
        } else if (this.resendCount >= MAX_RESEND_COUNT) {
          this.otpErrorMessages.push(messages.otpCode.otpEnterCorrectNo);
        }
      }

      if (errorCode === 'dcp-3002') {
        this.otpCodeExpired = true;
        this.otpErrorMessages.push(messages.otpCode.expired);
      }
    },
    checkOTPErrors() {
      const validationError = [];
      if (!this.$v.otpCode.required) {
        validationError.push(messages.otpCode.required);
      }
      if (!this.$v.otpCode.minLength) {
        validationError.push(messages.otpCode.minLength);
      }
      this.otpErrorMessages = validationError;
    },
    formattedContactNumber(number) {
      if (number) {
        return phoneNumberFormatter(number);
      }
      return '-';
    },
  },
};
</script>

<style>
.editItHere {
  color: #0c6dca;
  font-size: 14px;
  font-weight: normal;
}
.fixed-top-alert {
  position: absolute;
  top: -4px;
  z-index: 1;
  width: 100%;
}
.v-btn.close-otp {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
