export const validCharacter = /^[1-9][0-9]*$/;

// import { required } from 'vuelidate/lib/validators';
export default {

  allocationZeroOrLess(value) {
    return value === '' || !(value <= 0);
  },
  allocationOverHundred(value) {
    return value === '' || !(value > 100);
  },
  invalidCharacter(value) {
    const match = value && value.match(validCharacter);
    return !!(match);
  },
};
