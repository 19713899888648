export default {
  underwritingClass: {
    F: 'L_uw_class_f',
    G: 'L_uw_class_g',
    H: 'L_uw_class_h',
    J: 'L_uw_class_j',
    K: 'L_uw_class_k',
    N: 'L_uw_class_n',
    P: 'L_uw_class_p',
    Q: 'L_uw_class_q',
    R: 'L_uw_class_r',
    S: 'L_uw_class_s',
    T: 'L_uw_class_t',
  },
  billingMode(mode) {
    if (mode === '1') {
      return 'Monthly';
    } else if (mode === '3') {
      return 'Quarterly';
    } else if (mode === '6') {
      return 'Semi-annual';
    }
    return 'Annual';
  },
  paymentMethod({ billingMode, billingForm }) {
    // DATA - Billing Mode
    // '01' = Monthly
    // '03' = Quarterly
    // '06' = Semi-annually
    // '12' = Annually
    // DATA - Billing Form
    // A1 = Direct
    // B2 = PAC
    // C2 = Government allotment
    // D2 = payroll deduction
    if (billingForm === 'H1') {
      return 'L_billing_mode_monthly_direct_bill';
    }
    switch (billingMode) {
      case '01':
        switch (billingForm) {
          case 'B2':
            return 'L_billing_mode_monthly_bank_draft';
          case 'C2':
            return 'L_billing_mode_government_allotment';
          case 'D2':
            return 'L_billing_mode_payroll_deduction';
          default:
            return 'L_billing_mode_monthly_direct_bill';
        }
      case '03':
        return 'L_billing_mode_quarterly_direct_bill';
      case '06':
        return 'L_billing_mode_semi_annual_direct_bill';
      case '12':
        return 'L_billing_mode_annual_direct_bill';
      default:
        return 'L_billing_mode_monthly_direct_bill';
    }
  },
  nextDate({ billingForm }) {
    return (billingForm === 'B2')
      ? 'L_policy_next_auto_draft'
      : 'L_policy_next_premium';
  },
};
