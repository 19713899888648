<template>
  <v-tooltip
    max-width="450px"
    html="true"
    open-on-click
    :open-on-hover="false"
    right
    close-delay="2000"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-model="formInput.$model"
        :label="label || $t('L_allocation_perentage')"
        :autocomplete="disableAutocomplete ? 'off' : null"
        :disabled="disabled"
        :color="color"
        inputmode="numeric"
        pattern="[0-9]*"
        :append-icon="icon"
        :hint="hint"
        maxlength="3"
        :error-messages="errorMessages"
        @focus="onFocusAllocation();"
        @input="onInput()"
        @blur="onBlurAllocation();"
        @keydown="onKeyDown"
        :aria-describedby="describers"
      >
        <FieldIcon
          slot="append"
          :form-field="formInput"
        />
      </v-text-field>
    </template>
    <span class="toolTipBox">
      <p v-html="$t('L_allocation_percentage_tooltip')" />
    </span>
  </v-tooltip>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'AllocationPercentage',
  components: { FieldIcon },
  extends: BaseTextField,
  data: () => ({
    modelName: 'allocationPercentage',
    allocationPercentage: '',
    tooltip: {
      allocation: true,
    },
  }),
  methods: {
    onFocusAllocation() {
      this.tooltip.allocation = true;
    },
    onBlurAllocation() {
      this.tooltip.allocation = false;
    },
    onKeyDown(e) {
      // backspace, tab, arrow keys, insert, delete,
      // number on top of keyboard,  number on numpad
      if ((e.keyCode === 8) || (e.keyCode === 9) || (e.keyCode >= 35 && e.keyCode <= 46) ||
      (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (e.key !== '!' && e.key !== '@' && e.key !== '#' && e.key !== '$'
        && e.key !== '%' && e.key !== '^' && e.key !== '&' && e.key !== '*'
        && e.key !== '(' && e.key !== ')') {
          // Above is to ensure that when space is being pressed it returns same keycode as numbers so we need
          // to look at the key to make sure that the keys are not special characters laid on top of the numbers
          return; // 0-9 only
        }
        e.preventDefault();
      }
      e.preventDefault();
    },
  },
};
</script>
