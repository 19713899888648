<i18n src='../../../locales.json'></i18n>

<template>
  <v-layout class="no-investments top align-center" row wrap>
    <v-flex xs12 md7 class="content">
      <h2>
        <!-- REMOVED TODO: temporary until Tooltip content is finalized by legal
        <v-tooltip max-width=200 html=true open-on-click :open-on-hover="false" top close-delay="2000">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ $t('L_just_25_month') }} </span>
            <span class="tooltip_icon">
              <v-icon color="#02579E" dark small v-on="on">help</v-icon>
            </span>
          </template>
          <span><p v-html="$t('L_just_25_month_tooltip')"/></span>
        </v-tooltip>
        -->
        {{ $t('L_just_25_month') }}
      </h2>
      <h4 class="heading_four">
        {{ $t('L_help_saving_for_retirement') }}
      </h4>
    </v-flex>
    <v-flex xs12 md5 lg5 class="learn-more">
      <v-layout row fill-height align-center justify-center>
        <v-btn
          block
          outline
          color="white"
          @click="goToInvestments"
          :aria-label="$t('L_learn_more')"
        >
          {{ $t('L_learn_more') }}
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'NoInvestmentsTop',
  methods: {
    goToInvestments() {
      this.$router.push({ path: '/investments/learn-more' });
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables';

.investments-card .no-investments.top
  h2, h4
    font-weight: 600;
    text-align: center;
  h4
    margin: 5px 0;
  .learn-more
    padding: 0 15px;
  .v-btn
    background-color: #063562 !important;
    width: 100%;
    max-width: 288px;
    margin-right: 0px;
    margin-left: 0px;
  .tooltip_icon
    position:relative;
    bottom:10px;

</style>
