<i18n src='./locales.json'></i18n>

<template>
  <v-flex mt-2 xs12 class="representative">
    <v-card
      flat
      class="agent_card"
      :color="'#FFFFFF'"
    >
      <v-card-title>
        <v-flex xs12 sm12 md6 lg6
                :class="{'text-xs-center': hide}"
        >
          <span
            role="heading"
            aria-level="2"
            :class="hide ? 'title mb-0 pb-0 font-weight-medium' : 'card-title'"
          >
            {{ title }}
          </span>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 v-if="agents.length > 1 && !hide">
          <v-select
            filled
            :items="agents"
            solo
            single-line
            item-text="account"
            item-value="index"
            v-model="currentAgent"
            color="black"
            hide-details
          />
        </v-flex>
      </v-card-title>
      <v-divider dark v-if="!hide" />

      <v-card-text
        :class="hide ? 'text-xs-center pt-0' : 'pb-4'"
      >
        <template v-if="hide">
          <p>{{ $t('L_call_the_home_office') }}</p>
          <p>
            <v-icon
              size="24"
              class="mr-2"
              color="#ffffff"
            >
              phone
            </v-icon>
            <a
              :href="`tel:+1${getCustServNumWODash(locale)}`"
              class="font-weight-medium font-size--20 white--text"
            >{{ (getCustServNumWDash(locale)) }}</a>
          </p>
        </template>
        <v-layout
          row
          wrap
          align-start
          class="wrap-at-xs"
          :class="{'text-xs-center': $vuetify.breakpoint.xsOnly}"
          v-else
        >
          <v-layout row wrap>
            <!-- <temp>---Agent Photo-----------------------------------------</temp> -->
            <v-flex xs12 sm4 md2 :mb-3="$vuetify.breakpoint.xsOnly">
              <v-layout row wrap>
                <v-flex xs12 pb-2>
                  <v-layout justify-center>
                    <img class="rep-image" style="width: 80px" :src="displayPic" v-img-fallback="repFallbackImage" alt="rep photo">
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- <temp>---Agent Info-----------------------------------------</temp> -->
            <v-flex xs12 sm8 md10>
              <v-layout row wrap justify-center>
                <v-flex xs7 sm6 md4 lg4 pr-4 mb-4 :mb-3="$vuetify.breakpoint.xsOnly">
                  <v-layout row wrap justify-start>
                    <v-flex xs12>
                      <h3 role="heading" aria-level="2" class="heading_three font-weight-medium mb-2" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly, }">
                        {{ agent.name }}
                      </h3>
                      <h5 role="presentation"
                          v-if="agent.isRvp && agent.addresses.filter(address => address.type === 'home' &&
                            !(address.state ==='NF' || address.state === 'PE'|| address.state === 'NS'|| address.state === 'NB'||
                              address.state ==='PQ' || address.state === 'ON'|| address.state === 'MB'|| address.state === 'SK'||
                              address.state ==='AB' || address.state === 'BC'|| address.state === 'YT'|| address.state === 'NT'||
                              address.state === 'NU'
                            )).length > 0"
                          class="heading_five font-weight-medium mb-1 pb-1" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly, }"
                      >
                        {{ $t('L_' + agent.title) }}
                      </h5>
                      <!-- <h5 v-if="!hideExtraInfo" role="presentation" class="heading_five font-weight-medium mb-1 pb-1" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly, }">
                        {{ $t(agent.products.length > 1 ? 'L_rep_servicing_product_plural' : 'L_rep_servicing_product') }}
                      </h5>
                      <span v-if="!hideExtraInfo">
                        <div v-for="(item, index) in agent.products" :key="index" class="font-size--14 mb-1" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly,}">
                          {{ item }}
                        </div>
                      </span> -->
                    </v-flex>
                  </v-layout>
                </v-flex>

                <!-- <temp>---CONTACT------------------------------------------</temp> -->
                <v-flex xs7 sm6 md4 lg4 mb-4 :mb-3="$vuetify.breakpoint.xsOnly" class="text-xs-left">
                  <v-layout zalign-left class="mb-2 pb-1 justify-start" :justify-center="$vuetify.breakpoint.xsOnly">
                    <span class="font-size--14 font-weight-medium">{{ $t('L_contact') }}</span>
                  </v-layout>
                  <v-layout row wrap justify-start>
                    <v-flex v-if="agent.office && agent.office.phone" xs12 pb-2>
                      <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">phone</v-icon></span>
                      <a :href="'tel:+1' + agent.office.phone" class="ml-1">{{ agent.office.phone | phone }}</a>
                    </v-flex>
                    <v-flex v-if="agent.useCellPhone && agent.cell" xs12 pb-2>
                      <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">phone_android</v-icon></span>
                      <a :href="'tel:+1' + agent.cell" class="ml-1">{{ agent.cell | phone }}</a>
                    </v-flex>
                    <v-flex v-if="agent.office && agent.office.fax" xs12 pb-2>
                      <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">print</v-icon></span>
                      <a :href="'tel:+1' + agent.office.fax" class="ml-1">{{ agent.office.fax | phone }}</a>
                    </v-flex>

                    <v-flex v-if="agent.email" xs12 pb-2>
                      <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">email</v-icon></span>
                      <span @click.prevent="emailRep(agent.agentId)">
                        <a :href="'tel:+1' + agent.office.fax" class="ml-1">{{ $t('L_rep_email_me') }}</a>
                      </span>
                    </v-flex>

                    <v-flex v-if="agent.appointmentUrl" xs12 pb-2>
                      <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">event</v-icon></span>
                      <span @click.prevent="openCalendar(agent.appointmentUrl)">
                        <a href="" class="ml-1">{{ $t('L_make_an_appointment') }}</a>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <!-- <temp>-------------------------</temp> -->

                <v-flex xs7 sm12 md4 lg4>
                  <v-layout row wrap justify-start>
                    <v-flex xs12 text-xs-left>
                      <Address :rep="agent" v-if="agents.length > 0 && agent.office !== undefined" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card-text>
      <ContactRep
        v-if="agent"
        v-model="contactMe"
        :agents="[agent]"
        @close="contactMe = false"
        @send-message="send($event)"
        close-selector="contact_your_rep_close"
        modal-class="contact_your_rep_modal"
        :sender="sender"
      />
      <MessageDialog
        :show-dialog="dialog"
        :has-button-two="true"
        :is-persistent="true"
        type="info"
        :title="$t('L_you_are_being_redirected_to_an_appointment_scheduling_website')"
        :message="$t('L_this_link_will_take_you_to_a_third_party_site_that_has_its_own')"
        :button-two-label="$t('L_go_back')"
        :button-one-label="$t('L_continue')"
        @buttonTwoAction="dialog=false"
        @buttonOneAction="closeAndGoToCalendar"
      />
    </v-card>
  </v-flex>
</template>

<script>
import bff from '@/shared/api/bff';
import { mapGetters } from 'vuex';
import { openMap } from '@/mixins/openMap';
import { openInNewWindow } from '@/mixins/openNewWindow';
import ContactRep from '../components/ContactRep/ContactRep.vue';
import sendRepEmail from '../components/ContactRep/sendEmail';
import Address from '../modules/policy/my_rep/components/Address.vue';
import MessageDialog from './MessageDialog.vue';

const repFallbackImage = require('../../src/assets/rep-thumb.svg');

export default {
  name: 'Representative',
  mixins: [openMap, openInNewWindow],
  props: {
    agents: {
      type: Array,
      required: true,
    },
    restricted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ContactRep,
    Address,
    MessageDialog,
  },
  data: () => ({
    dialog: false,
    contactMe: false,
    agent: Object,
    currentAgent: '',
    calendarLink: '',
  }),
  computed: {
    ...mapGetters('customer', {
      getCustServNumWDash: 'getCustServNumWDash',
      getCustServNumWODash: 'getCustServNumWODash',
      customerProfile: 'customerProfile',
    }),
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters(['getAgent']),
    hasRvp() {
      return this.rvp && this.rvp.name;
    },
    rvp() {
      return (this.agent?.rvpId) ? this.agent?.rvp : {};
    },
    title() {
      let header = '';
      if (this.hide) {
        header = this.$t('L_questions_about_policy');
      } else {
        header = (this.agents?.length === 1) ? this.$t('L_your_rep') : this.$t('L_your_rep_account');
      }
      return header;
    },
    displayPic() {
      return this.agent?.displayPic ? this.agent?.displayPic : repFallbackImage;
    },
    office() {
      return (this.agent?.office) ? this.agent.office : {};
    },
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    hide() {
      let agentExists = true;
      if (this.agents === undefined || this.agents === null) {
        agentExists = false;
      } else if (this.agents && this.agents.length > 0 && !!this.agents[0]?.agentId === false) {
        agentExists = false;
      }
      return this.restricted || this.agent?.isHomeOfficeAgent || agentExists === false;
    },
    repFallbackImage() {
      // eslint-disable-next-line
      return require('@/assets/rep-thumb.svg');
    },
  },
  filters: {
    phone: number => number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
  },
  mounted() {
    this.setAgent();
  },
  methods: {
    openCalendar(calendarLink) {
      this.calendarLink = calendarLink;
      this.dialog = true;
    },
    send(payload) {
      sendRepEmail.bind(this)(bff.sendRepEmail, payload);
    },
    setAgent() {
      if (this.agents?.length > 0) {
        this.agent = this.agents[0];
        this.currentAgent = this.agent?.index;
      }
      this.setAddressToNavigate(this.office);
    },
    async closeAndGoToCalendar() {
      this.dialog = false;
      await this.openInNewWindow({ url: this.calendarLink, target: '_blank' });
    },
  },
  watch: {
    currentAgent(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.agent = this.agents.find(a => a.index === newVal);
      }
    },
    agents(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setAgent();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.representative
  background-color: #FFFFFF;
  .agent_card
    color: #333333;
  .rep-image
    width: 80px;
  @media (max-width: 599px)
    .wrap-at-xs
      min-width: 100% !important;
  .width-100
    width: 100% !important;
  .shift-right
    margin-left: 114px;
  .bgc-white
    background: #333;
  .close-dialog
    pull: right;
    font-size: 19px;
  a
    color: #02579E !important;
    font-weight: bold;
    &.map-link
      padding-bottom: 3px

</style>

