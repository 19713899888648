import { getAuthClient } from '@/plugins/auth/index';
import bff from '../../../shared/api/bff';

export const passwordErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 40310:
      return 'L_password_entered_does_not_match_records';
    case 40311:
      return 'L_update_password_error_no_of_attemps';
    default:
      return '';
  }
};

export const userIdErrorMessage = (status) => {
  switch (status) {
    case 200:
      return 'L_update_user_id_success';
    case 403:
      return 'L_update_user_id_password_does_not_match';
    case 409:
      return 'L_update_user_id_error_exists';
    case 423:
      return 'L_update_user_id_account_locked';
    default:
      return '';
  }
};

export const updatePassword = async ({ commit }, request) => {
  try {
    await bff.updatePassword(request);
    commit('addAlert', { type: 'success', details: { key: 'L_update_password_success' }, context: 'myaccount' }, { root: true });
    return true;
  } catch (e) {
    const keyMsg = passwordErrorMessage(e.response?.data?.appStatusCode);
    if (keyMsg) {
      commit('addError', { error: { key: keyMsg }, context: 'myaccount' }, { root: true });
    } else {
      commit('addError', { error: e, context: 'myaccount' }, { root: true });
    }
    if (e.response?.status === 423) {
      getAuthClient().logout();
    }
    throw e;
  }
};

export const requestUserIdChallenge = async ({ commit }, request) => {
  commit('setSubmitLoading', true, { root: true });

  try {
    // const verificationResult =
    await bff.changeUserVerification(request);
    // if (verificationResult?.status === 200) {
    //   // This
    // }
  } catch (e) {
    const statusCode = e.response?.data?.appStatusCode;
    if (e.response.status === 403 && (statusCode === 'dcp-5000' || statusCode === 'dcp-5002')) {
      const statusMessage = statusCode === 'dcp-5000' ? 'L_invalid_password_update_user_id' : 'L_user_already_in_use_update_user_id';
      commit('addError', { error: { key: statusMessage }, context: 'myaccount' }, { root: true });
    } else {
      commit('addError', { error: e, context: 'myaccount' }, { root: true });
    }
    commit('setSubmitLoading', false, { root: true });
    throw e;
  } finally {
    commit('setSubmitLoading', false, { root: true });
  }
};

export const requestChallenge = async ({ commit }, request) => {
  commit('setSubmitLoading', true, { root: true });
  try {
    const { locale } = request;
    delete request.locale;

    // Validating User credential
    const { token } = request;
    delete request.token;
    const challengeResult = await bff.requestUserIdChallenge({ loginId: request.loginId, locale, token });
    if (challengeResult?.status === 200) {
      commit('addAlert', { type: 'success', details: { key: 'L_verification_code_sent' }, context: 'myaccount' }, { root: true });
      return challengeResult.data?.mfaToken;
    }
  } catch (e) {
    commit('addError', { error: e, context: 'myaccount' }, { root: true });
    commit('setSubmitLoading', false, { root: true });
    throw e;
  } finally {
    commit('setSubmitLoading', false, { root: true });
  }
};


export const createChallenge = async ({ commit }, challengeSpec) => {
  try {
    const result = await bff.createChallenge(challengeSpec.payload);
    commit('home/setMfaToken', result.data.mfaToken, { root: true });
  } catch (e) {
    commit('addError', { e, context: challengeSpec.viewContext }, { root: true });
    throw e;
  }
};

export const updateMobileNumber = async ({ commit }, updateSpec) => {
  try {
    await bff.updateMobileNumber(updateSpec.payload);
  } catch (e) {
    commit('addError', { e, context: updateSpec.viewContext }, { root: true });
    throw e;
  }
};

export const updateUserID = async ({ commit }, request) => {
  commit('setSubmitLoading', true, { root: true });
  try {
    await bff.updateUserID(request);
    commit('customer/updateCustomerProfile', { email: request.id }, { root: true });
    return true;
  } catch (e) {
    commit('setSubmitLoading', false, { root: true });
    const status = e.response?.status;
    if (status === 400) {
      throw e;
    }
    if (status === 423) {
      setTimeout(() => {
        getAuthClient().logout();
      }, 2000);
    }
    const keyMsg = userIdErrorMessage(status);
    if (keyMsg) {
      commit('addError', { error: { key: keyMsg }, context: 'myaccount' }, { root: true });
    } else {
      commit('addError', { error: e, context: 'myaccount' }, { root: true });
    }
    return false;
  } finally {
    commit('setSubmitLoading', false, { root: true });
  }
};

export const updateLanguagePreference = async ({ commit }, request) => {
  try {
    // const response = await ca.updateLanguagePreference(request);
    // if (response.status === 200) {
    // eslint-disable-next-line no-console
    commit('changeLocale', request?.preferredLang, { root: true });
    // }
    return true;
  } catch (e) {
    commit('addError', { error: e, context: 'myaccount' }, { root: true });
    throw e;
  }
};

