<template>
  <v-text-field
    id="years_display"
    :aria-label="'years_display'"
    v-model="years"
    mask="##"
    class="text-xs-center font-size--24 color-transparent"
    :class="{
      'pr-0': years.length === 0,
      'pr-50': +years === 1,
      'pr-62': +years === 0 || +years > 1,
    }"
    @input="$emit('input', $event)"
  >
    <span slot="append">{{ displayValue }}</span>
  </v-text-field>
</template>

<script>

export default {
  name: 'YearsDisplay',
  props: {
    value: {
      type: [String, Number],
      required: true,
      default: '0',
    },
  },
  data: () => ({
    years: '0',
  }),
  computed: {
    displayValue() {
      if (this.years.length === 0) {
        return '';
      }
      return `${this.years} year${(this.years === 0 || this.years > 1) ? 's' : ''}`;
    },
  },
  watch: {
    value(value) {
      this.years = value;
    },
  },
  mounted() {
    this.years = this.value;
  },
};
</script>

<style lang="stylus" scoped="true">
/deep/ #years_display {
  color: rgba(0,0,0,0);
}
.pr-50 {
  /deep/ input {
    padding-right: 48px !important;
  }
}
.pr-62 {
  /deep/ input {
    padding-right: 58px !important;
  }
}

/deep/ div.v-text-field__slot {
  z-index: 2;
}

/deep/ div.v-input__append-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &>span {
    width: 100%;
    text-align: center;
  }
}
</style>
