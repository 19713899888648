import LearnMore from './LearnMore.vue';

export default {
  name: 'LearnMoreDebt',
  path: 'learnmoredebt',
  component: LearnMore,
  meta: {
    pageTitle: 'Learn.More',
    indexOfRoute: 2,
  },
};
