<template>
  <v-dialog
    v-model="showViewer"
    :content-class="contentClasses"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-layout justify-center align-center>
      <v-card>
        <v-card-title class="text-xs-center" v-if="!loading">
          <v-flex xs12 class="heading">
            {{ title }}
          </v-flex>
        </v-card-title>
        <v-card-text>
          <LoadingIndicator
            :loading="loading"
            :title="`Downloading ${title}`"
          />
          <iframe
            :src="embedUrl"
            title="embeddedTitle"
            class="embedded-viewer"
            v-if="!loading && isModal"
          />
        </v-card-text>
        <v-card-actions class="justify-center" v-if="!loading">
          <v-flex xs8 md5>
            <v-btn color="primary" @click="showViewer = false">
              Close
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-dialog>
</template>

<script>

import { openInNewWindow } from '@/mixins/openNewWindow';
import LoadingIndicator from './LoadingIndicator.vue';

export default {
  name: 'EmbeddedViewer',
  components: {
    LoadingIndicator,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    binaryData: {
      type: ArrayBuffer,
      default: null,
    },
    contentUrl: {
      type: String,
      default: null,
    },
    contentType: {
      type: String,
      required: true,
    },
  },
  mixins: [openInNewWindow],
  data() {
    return {
      showViewer: false,
      embedUrl: '',
    };
  },
  computed: {
    contentClasses() {
      let classes = 'embedded-viewer';
      if (this.loading) {
        classes += ' loading';
      }
      return classes;
    },
    isModal() {
      return this.contentType !== 'application/pdf';
    },
  },
  methods: {
    async show() {
      this.embedUrl = null;
      if (this.binaryData) {
        this.embedUrl = URL.createObjectURL(new Blob(
          [this.binaryData],
          { type: this.contentType },
        ));
      } else if (this.contentUrl) {
        this.embedUrl = this.contentUrl;
      }
      if (this.embedUrl && !this.isModal) {
        await this.openInNewWindow({ url: this.embedUrl, target: '_blank' });
        this.$emit('close');
        this.showViewer = false;
      } else {
        this.showViewer = true;
      }
    },
  },
  watch: {
    binaryData() {
      this.show();
    },
    contentUrl(newData) {
      if (newData) {
        this.show();
      }
    },
    loading() {
      if (!this.showViewer) {
        this.showViewer = this.loading;
      }
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables';
.embedded-viewer
    height: 100%;
    max-width: 800px;
    &.loading
      height: initial !important;
      position: relative;
      max-width: 300px;
      .v-card__text
        padding: 0;
        min-height: 150px;
    &:not(.v-dialog--fullscreen)
      height: 90%;
    .layout, .embedded-viewer
      height: 100%;
    .v-card
      width: 100%;
    .v-card__title
      height: 60px;
      border-bottom: 1px solid rgba(0,0,0,0.12);
    .v-card__text
      height: calc(100% - 140px);
      padding: 16px 16px 8px;
    .v-card__actions
      height: 80px;
      padding: 0 8px 8px;
    .heading
      font-size: 20px;
    .embedded-viewer
      width: 100%;
      border-radius: 2px;
      border-bottom: 5px solid #525659;
    .v-btn
      width: 100%;
</style>

