import stringValues from '@/shared/util/stringValues';

export const step = state => state.step;

export const packages = state => state.packages;

export const expireDate = state => state?.packages[0] && state.packages[0].crcExpireDt;

export const crcExpireDate = state => state.expirationDate.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');

export const packagesGivenPolicyId = state => policyId => state.packages.filter(item => item.policyId === policyId);

export const packageGivenPackageId = state => packageId => state.packages.filter(item => item.packageId === packageId)[0];

export const hasPackageGivenPolicyId = state => policyId => (state.packages.filter(item => item.policyId === policyId).length > 0);

export const newPolicy = state => state.newPolicy;

export const NPPackage = state => state.packages.filter(item => item.policyId === state.newPolicy.policyId && item.packageId === state.newPolicy.packageId)[0];

export const NPFirstName = state => state.newPolicy.firstName;

export const NPLastName = state => state.newPolicy.lastName;

export const NPFullName = state => `${state.newPolicy.firstName} ${state.newPolicy.lastName}`;

export const NPMiddleName = state => state.newPolicy.middleName;

export const NPGender = state => state.newPolicy.gender;

export const NPDOB = state => state.newPolicy.DOB;

export const NPSSN = state => state.newPolicy.SSN;

export const NPSelectedOption = state => state.newPolicy.selectedOption;

export const NPSelectedFaceAmount = state => state.newPolicy.selectedFaceAmount;

export const NPSelectedPremiumAmount = (state) => {
  let premiumAmount = state.newPolicy.selectedPremiumAmount ? state.newPolicy.selectedPremiumAmount : '';
  const splitVal = premiumAmount.split('.');
  if (splitVal[1] != null) {
    splitVal[1] = (`${splitVal[1]}00`).slice(0, 2);
    premiumAmount = `${splitVal[0]}.${splitVal[1]}`;
  } else {
    premiumAmount = `${splitVal[0]}.00`;
  }
  return premiumAmount;
};

export const NPSelectedOptionDetails = (state) => {
  const _package = state.packages.filter(item => item.policyId === state.newPolicy.policyId && item.packageId === state.newPolicy.packageId)[0];
  return _package.selectionOptions[state.newPolicy.selectedOption];
};

export const NPEDelivery = state => state.newPolicy.eDelivery;

export const insuranceSelected = (state, getters, rootGetters) => {
  const options = Object.entries(getters.NPPackage?.selectionOptions ? getters.NPPackage.selectionOptions : []);
  let hasNoTabacoRate = true;
  if (!options.length) {
    hasNoTabacoRate = false;
  } else {
    // filter out the tobacco rates
    const tobaccoRates = [];
    options.forEach(([key, value]) => {
      if (key.indexOf('T') !== -1) {
        tobaccoRates.push(Number(value.premiumAmount));
      }
    });
    // check if packets does not have tobacco rates (premium amounts have 0 or 0.00 values)
    hasNoTabacoRate = tobaccoRates.every(e => e <= 0);
  }

  if (hasNoTabacoRate === false) {
    const compCodes = ['04', '05', '10'];
    if (compCodes.some(eachCompanyCode => getters.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
      return 'TermNow35';
    }
    // returns if comp code is 34
    return 'TermNow30';
  }
  const compCodes1 = ['04'];
  const compCodes2 = ['34'];
  const compCodes3 = ['05', '10'];
  if (compCodes1.some(eachCompanyCode => getters.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
    return 'PowerTerm 35';
  } else if (compCodes2.some(eachCompanyCode => getters.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
    return 'PowerTerm 30';
  } else if (compCodes3.some(eachCompanyCode => getters.NPPackage?.policyCompNum.includes(eachCompanyCode))) {
    if (rootGetters.locale === 'fr_CA') {
      return 'Temporaire Puissance Primerica 35';
    }
    return 'Primerica PowerTerm 35';
  }
  return 'PowerTerm 35';
};

export const policyOwner = (state, getters, rootState, rootGetters) => `${rootGetters['policy/currentPolicy']?.owner?._firstName} ${rootGetters['policy/currentPolicy']?.owner?._lastName}`;

export const bankName = (state, getters, rootState, rootGetters) => rootGetters['policy/currentPolicy']?._paymentInfo?._bank?._name;

export const NPAcknowledgementHTML = (state, getters, rootState) => {
  const rawText = state.newPolicy.acknowledgement.text;
  const interpolatedHTML = [];
  // {{name}}
  // {{ insuranceSelection }}
  // {{ targetName }}
  // {{ faceAmount }}
  // {{ timePeriod }}
  // {{ premumAmount }}
  // {{ newModePremiumAmountFormatted }}
  // {{ dayOfMonthWith }}
  const targetName = `${state.newPolicy.firstName} ${state.newPolicy.lastName}`;
  const name = getters.policyOwner;
  const insuranceSelection = getters.insuranceSelected;

  let faceAmount = `$${state.newPolicy.selectedFaceAmount}`;
  faceAmount = faceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const billForm = getters.NPPackage?.paymentForm;
  const billMode = getters.NPPackage?.paymentMode;
  let timePeriod = '3';
  if ((billForm === 'A1' && billMode === '1') || billForm === 'C2' || billForm === 'D2' || billForm === 'H1') {
    timePeriod = stringValues.billingMode('3');
  } else {
    timePeriod = stringValues.billingMode(getters.newPolicy?.paymentMode);
  }
  let premumAmount = `$${state.newPolicy.selectedPremiumAmount}`;
  // let newModePremiumAmountFormatted = `$${state.newPolicy.selectedPremiumAmount}`;


  const splitVal = premumAmount.split('.');
  if (splitVal[1] != null) {
    splitVal[1] = (`${splitVal[1]}00`).slice(0, 2);
    premumAmount = `${splitVal[0]}.${splitVal[1]}`;
  } else {
    premumAmount = `${splitVal[0]}.00`;
  }
  // For some reason we are reading same properties for both line # 101
  const newModePremiumAmountFormatted = premumAmount;

  // const dayOfMonthWith = `${getters.newPolicy.pacDraftDay}`;
  // let dayOfMonthWith = (state, getters, rootState, rootGetters) => rootGetters['policy/currentPolicy']?._paymentInfo?._autoDraftPaymentDate;
  let dayOfMonthWith = rootState.policy.policies[rootState.policy.selectedPolicyIndex]._paymentInfo._autoDraftPaymentDate;
  if (dayOfMonthWith !== undefined && dayOfMonthWith.indexOf('0') === 0) {
    dayOfMonthWith = dayOfMonthWith.substring(1);
  }
  if (dayOfMonthWith === '1' || dayOfMonthWith === '21') {
    dayOfMonthWith = `${dayOfMonthWith}st`;
  } else if (dayOfMonthWith === '2' || dayOfMonthWith === '22') {
    dayOfMonthWith = `${dayOfMonthWith}nd`;
  } else if (dayOfMonthWith === '3' || dayOfMonthWith === '23') {
    dayOfMonthWith = `${dayOfMonthWith}rd`;
  } else {
    dayOfMonthWith = `${dayOfMonthWith}th`;
  }

  rawText.forEach((item) => {
    let text = item.textContent.replace('{{ targetName }}', `${targetName}`);
    text = text.replace('{{ name }}', `${name}`);
    text = text.replace('{{ insuranceSelection }}', `${insuranceSelection}`);
    text = text.replace('{{ faceAmount }}', `${faceAmount}`);
    text = text.replace('{{ timePeriod }}', `${timePeriod}`);
    text = text.replace('{{ premumAmount }}', `${premumAmount}`);
    text = text.replace('{{newModePremiumAmountFormatted}}', `${newModePremiumAmountFormatted}`);
    text = text.replace('{{dayOfMonthWith}}', `${dayOfMonthWith}`);

    text = text.replace(/\\"|\/"/g, '');

    let _html = '';
    if (item.textSequence % 2 === 0) {
      _html = `<h3>${text}</h3>`;
    } else {
      _html = `<p>${text}</p>`;
    }

    // If paymentType = B2 (ACH), include sequence content, 6, 7, 8, 9
    const paymentType = getters.NPPackage.paymentForm;
    if (item.textSequence === 6 || item.textSequence === 7 ||
      item.textSequence === 8 || item.textSequence === 9) {
      if (paymentType === 'B2') {
        interpolatedHTML.push(_html);
      }
    } else {
      interpolatedHTML.push(_html);
    }
  });
  return interpolatedHTML;
};

export const NPConfirmation = state => state.newPolicyConfirmation;

export const loading = state => state.loading;

export const isUSPolicy = (state, getters) => getters.NPPackage.policyCompNum === '04';

export const isCanadaPolicy = (state, getters) => getters.NPPackage.policyCompNum === '10' || getters.NPPackage.policyCompNum === '05';

export const isNYPolicy = (state, getters) => getters.NPPackage.policyCompNum === '34';

export const lastChild = (state) => {
  // check lrcInd of the last child being processed for conversion
  // TODO: deal with 60 and 90 day

  // last child alert is false if there is still a valid package to be processed
  if (state.packages.length > 0) {
    return false;
  }

  return state.lrcInd === 'Y';
};
