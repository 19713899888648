import { Session } from '@/shared/util/session';
import moment from 'moment';

export const sendKubraAnalytics = (now) => {
  Session.set('kubra_posted', now, true);

  if (typeof (window?.dataLayer?.push) === 'function') {
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'alert',
      eventLabel: 'success',
      eventValue: '1TP kubra submit confirmation',
      timestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
      userId: Session.get('Q_ci'),
      sessionId: Session.get('X-Correlation-ID'),
    });
  }
};

export const checkKubraPayment = () => {
  const kubraPaymentDate = Session.get('kubra_posted', true);
  const now = new Date().getTime();

  if (!kubraPaymentDate) return sendKubraAnalytics(now);

  let diff = (now - kubraPaymentDate) / 1000;
  diff /= (60 * 60);
  const hoursDifference = Math.abs(Math.round(diff));

  return hoursDifference >= 24 ? sendKubraAnalytics(now) : undefined;
};

