<template>
  <div>
    <v-toolbar
      app
      class="elevation-0"
      aria-label="main"
    >
      <img
        src="@/assets/primerica-logo.svg"
        height="40"
        alt="Primerica Beta"
      >

      <v-spacer />
      <!-- Commenting this just for Alpha relese, this will need this one in future. -->
      <!-- <v-menu
        class="hidden-sm-and-down"
        v-if="$route.name === 'RegistrationStepOne'">
        <v-toolbar-title slot="activator">
          <span>{{ this.langName }}</span>
          <v-icon aria-hidden="true">arrow_drop_down</v-icon>
        </v-toolbar-title>

        <v-list>
          <v-list-tile @click="changeLocale('en_US')">
            <v-list-tile-title>English</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="changeLocale('es_US')">
            <v-list-tile-title>Spanish</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="changeLocale('fr_CA')">
            <v-list-tile-title>French</v-list-tile-title>
          </v-list-tile>
        </v-list>

      </v-menu> -->
      <v-toolbar-items class="hidden-sm-and-down">
        <v-flex
          align-center
          justify-center
          d-flex
          ml-5
          mr-5
          pl-5
          pr-5
        >
          <a
            href="javascript:void(0)"
            class="sign-in"
            @click="goToLogin"
          >
            {{ $t('L_sign_in') }}
          </a>
        </v-flex>
      </v-toolbar-items>

      <!--<v-toolbar-side-icon class="hidden-md-and-up"/>-->
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SectionHeader',
  computed: {
    ...mapGetters({
      langName: 'langName',
    }),
  },
  methods: {
    ...mapActions(['goToLogin']),
    changeLocale(lang) {
      this.$store.commit('changeLocale', lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="stylus" scoped>
.v-toolbar__title {
  font-size: 16px;
  font-weight: normal !important;
}
.sign-in
  color: #333 !important;
  font-weight: normal !important;
</style>
