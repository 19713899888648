import PolicyDetailsCards from './components/PolicyDetailsCards/PolicyDetailsCards.vue';

export default {
  name: 'PolicyDetails',
  alias: ':contractId/details/paynow',
  path: ':contractId/details',
  component: PolicyDetailsCards,
  meta: {
    pageTitle: 'Policy.Details',
    indexOfRoute: 0,
  },
};
