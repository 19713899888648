<i18n src="../locales.json"></i18n>
<template>
  <v-container>
    <v-layout column>
      <v-flex xs12 mt-4>
        <v-layout row wrap justify-center>
          <v-flex xs12 text-xs-center mb-4 font-size--18 font-weight-bold v-if="isNyOrThirdParty">
            {{ $t('L_request_reinstatement_application') }}
          </v-flex>
          <v-flex xs12 text-xs-center mb-4>
            <img alt="overview reinstatement icon" src="../assets/NextStepImageOriginalDate.svg" class="mb-4">
            <v-layout row wrap>
              <v-flex xs0 sm1 md2 lg2>
                &nbsp;
              </v-flex>
              <v-flex xs12 sm10 md8 lg8 font-size-16 font-weight-regular text-xs-left>
                <div class="font-size--16 font-weight-bold">
                  {{ $t('L_your_request_for_a_paper_reinstatement_application_has_been_received') }}
                </div>
                <div class="font-size--16 mt-4">
                  {{ $t('L_please_allow_up_to_a_week_to_receive_the_paper_application_you_must_complete_and_mail_the') }}
                </div>
                <div class="font-size--16 mt-4">
                  {{ $t('L_submission_of_the_applicatioN_deon_not_guarantee_coverage_coverage_will_not_go') }}
                </div>
                <div class="font-size--16 mt-4">
                  <i18n path="L_if_you_have_questions_please_contact_your_representative_or_client_service" tag="p" class="font-weight-regular">
                    <span>{{ customerServicePhoneNum }}</span>
                  </i18n>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="zoffset-xs3 text-xs-right pl-2 pr-2" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
          <!-- <v-flex xs8 sm3 mb-3 :class="$vuetify.breakpoint.xsOnly?'':'mr-3'">
            <v-btn block color="secondary" class="mr-2" @click="cancelAction">
              {{ $t('L_cancel') }}
            </v-btn>
          </v-flex> -->
          <v-flex xs8 sm3 mb-3>
            <v-btn :disabled="disableActions" target="_blank" block color="primary" @click="continueAction">
              {{ $t('L_done') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReinstatementOriginalIssueNextSteps',
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
    isNyOrThirdParty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ getLocale: 'getLocale' }),
    customerServicePhoneNum() {
      if (this.getLocale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.getLocale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.getLocale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
  },
  methods: {
    continueAction() {
      this.$emit('continueClicked');
    },
    // cancelAction() {
    //   this.$emit('cancelClicked');
    // },
  },
};
</script>

<style lang="stylus" scoped>
.bluetext {
  color: #063562;
}
</style>
