<i18n src='../../../../locales.json'></i18n>

<template>
  <v-layout
    justify-center
    pt-4
    pb-4
  >
    <v-flex
      v-if="!kubraPaymentInProgress && !updatingBank"
      md8
      sm10
      xs12
      pl-4
      pr-4
    >
      <v-card-title
        class="pb-0
        justify-center"
      >
        <span
          role="heading"
          aria-level="3"
          class="title font-weight-regular mb-0"
        >{{ $t('L_policy_payment_details') }}</span>
      </v-card-title>
      <v-card-text>
        <v-layout
          text-xs-center
          row
          wrap
          class="justify-center"
        >
          <v-flex
            xs8
            md4
            mt-3
            class="font-weight-medium"
          >
            <v-layout class="justify-space-around">
              <v-flex xs10>
                {{ $t('L_policy_payment_method') }}
              </v-flex>
              <v-flex xs2 v-feature:pc2-pay-edit
                      v-if="!policyData.isB2BillingForm || policyData.billingMode === '01'"
              >
                <a
                  id="edit_billing_method"
                  class="text-decoration-underline ml-2"
                  href="javascript:void(0)"
                  :aria-label="$t('L_payment_edit')+' payment details'"
                  @click="editPaymentMethod"
                >{{ $t('L_payment_edit') }}
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            my-3
          >
            {{ $t(paymentMethod) }}
          </v-flex>
          <v-flex
            xs8
            md4
            mt-3
            class="font-weight-medium"
            v-if="policyData.isB2BillingForm"
          >
            <v-layout class="justify-space-around">
              <v-flex xs10>
                {{ $t(accountType) }}
              </v-flex>
              <v-flex xs2 v-feature:pc2-bankaccount-edit>
                <a
                  id="edit_account_type"
                  role="button"
                  class="text-decoration-underline ml-2"
                  href="javascript:void(0)"
                  @click="startBankChange"
                >{{ $t('L_payment_edit') }}
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text
        v-if="showBankInfo"
      >
        <v-layout
          justify-center
          wrap
        >
          <v-flex
            xs12
            sm4
          >
            <PolicyDetailsInfo>
              <template slot="title">
                {{ $t('L_policy_bank_name') }}
              </template>
              <template slot="content">
                {{ bank.name }}
              </template>
            </PolicyDetailsInfo>
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <PolicyDetailsInfo>
              <template slot="title">
                {{ $t('L_policy_account_number') }}
              </template>
              <template slot="content">
                {{ bank.account | hideNums }}
              </template>
            </PolicyDetailsInfo>
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <PolicyDetailsInfo>
              <template slot="title">
                {{ $t('L_policy_payor_name') }}
              </template>
              <template slot="content">
                {{ bank.payorName }}
              </template>
            </PolicyDetailsInfo>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-flex>
    <KubraPayment
      v-if="kubraPaymentInProgress"
    />
    <UpdateBank
      @cancel="cancelBankUpdate"
      @cancelAll="cancel"
      :is-update-bank="true"
      :show-date-picker="displayDatePicker"
      v-if="updatingBank"
      @done="cancel"
    />
  </v-layout>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { filters } from '@/mixins/filters';
import stringValues from '@/shared/util/stringValues';
import PolicyDetailsInfo from '../../../../PolicyDetailsInfo.vue';
import AchPayment from '../OneTimePayment/components/AchPayment/AchPayment.vue';
import KubraPayment from './components/KubraPayment.vue';
import UpdateBank from './components/UpdateBank/UpdateBank.vue';

export default {
  name: 'ExpandedPaymentCard',
  mixins: [filters],
  components: {
    UpdateBank,
    KubraPayment,
    AchPayment,
    PolicyDetailsInfo,
  },
  props: {
    bank: {
      type: Object,
      default: () => ({}),
    },
    policyData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['containsFeature']),
    ...mapGetters({
      updatingBank: 'policy/getUpdatingBank',
    }),
    displayDatePicker() {
      return !this.containsFeature('DRAFT-DATE');
    },
    accountType() {
      if (this.policyData?.paymentInfo?.isSavingsAccount) {
        return 'L_payment_accnttype_saving';
      }
      return this.isCanadianPolicy ? 'L_payment_accnttype_chequing' : 'L_payment_accnttype_checking';
    },
    showBankInfo() {
      return (Object.keys(this.bank).length > 0) && this.policyData.isB2BillingForm;
    },
    paymentMethod() {
      return stringValues.paymentMethod(this.policyData);
    },
    isCanadianPolicy() {
      return !!this.policyData?.isCanadaPolicy;
    },
    kubraPaymentInProgress() {
      return this.policyData?.kubraPaymentStatus !== null;
    },
  },
  methods: {
    ...mapMutations({
      setUpdatingBank: 'policy/setUpdatingBank',
      setDisablePaymentDetailsLink: 'policy/setDisablePaymentDetailsLink',
      setPanelValue: 'policy/setPanelValue',
      setPreLoadBank: 'policy/setPreLoadBank',
    }),
    editPaymentMethod() {
      try {
        const i = this.$dynatrace.enterAction('PolicyDetails_BillingMethod_Edit_selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      if (this.policyData.isB2BillingForm ||
          this.policyData.isA1BillingForm) {
        this.$emit('editPayMethod');
      }
    },
    startBankChange() {
      if (this.policyData.isB2BillingForm) {
        const paymentData = {
          accountNumber: '',
          bankName: '',
          routingNumber: '',
        };
        this.$store.commit('policy/setPaymentData', paymentData);
        this.setPreLoadBank(true);
        this.setDisablePaymentDetailsLink(true);
        this.setUpdatingBank(true);
      }
    },
    cancel() {
      this.setDisablePaymentDetailsLink(false);
      this.setUpdatingBank(false);
      this.setPanelValue(-1);
    },
    cancelBankUpdate() {
      this.setDisablePaymentDetailsLink(false);
      this.setUpdatingBank(false);
    },
  },
};
</script>
