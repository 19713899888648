<i18n src='../../locales.json'></i18n>
<template>
  <v-card
    class="default-beneficiary-card"
  >
    <v-card-text
      class="text-xs-center"
    >
      <p class="beneficiaries-subtext">
        <span v-if="!displayBeneficiaries">
          {{ $t('L_policy_beneficiaries_subtext_file') }}
        </span>
        <i18n
          path="L_policy_beneficiaries_subtext"
        >
          <a :href="getTelCustServ" class="font-weight-regular">{{ getNumCustServ }} </a>
          <span class="font-weight-medium">{{ $t('L_policy_contact_your_servicing') }}</span>
        </i18n>
      </p>
      <v-layout
        class="font-size--14"
        row
        wrap
        justify-center
      >
        <v-flex xs12>
          <span class="font-weight-medium">{{ $t('L_policy_submit_written_request') }}</span>
        </v-flex>
        <v-flex
          xs12
        >
          <span>
            {{ $t('L_policy_beneficiaries_company_US') }}
          </span>
        </v-flex>
        <v-flex
          xs12
        >
          <span class="beneficiaries-submit-address">
            {{ $t('L_policy_submit_written_request_address') }}
          </span>
        </v-flex>
        <v-flex
          xs-12
          md12
          pb-4
        >
          <span class="beneficiaries-submit-note">
            {{ $t('L_policy_submit_written_request_include_note') }}
          </span>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '@/shared/constants';

export default {
  name: 'BeneficiaryDefault',
  computed: {
    ...mapGetters({
      getCustServNumWDash: 'customer/getCustServNumWDash',
      getCustServNumWODash: 'customer/getCustServNumWODash',
    }),
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters(['containsFeature', 'features']),
    ...mapGetters({
      beneficiaries: 'policy/beneficiaries',
    }),
    displayBeneficiaries() {
      return this.containsFeature(FEATURE_FLAGS.BENEFICIARIES) && (this.beneficiaries && this.beneficiaries?.length > 0);
    },
    getNumCustServ() {
      if (this.locale?.length > 0) {
        return this.getCustServNumWDash(this.locale);
      }
      return '';
    },
    getTelCustServ() {
      if (this.locale?.length > 0) {
        return `tel:${this.getCustServNumWODash(this.locale)}`;
      }
      return '';
    },
  },
};
</script>
