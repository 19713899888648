import { render, staticRenderFns } from "./MyAccount.vue?vue&type=template&id=c33ac09a&scoped=true"
import script from "./MyAccount.vue?vue&type=script&lang=js"
export * from "./MyAccount.vue?vue&type=script&lang=js"
import style0 from "./MyAccount.vue?vue&type=style&index=0&id=c33ac09a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c33ac09a",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fhome%2Fmyaccount%2Fcomponents%2FMyAccount.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports