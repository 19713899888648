import { getDefaultState } from './state';

const setStep = (state, step) => { state.step = step; };
const setDebts = (state, debts) => { state.debts = debts; };
const setPersonInfo = (state, personInfo) => {
  state.personInfo = personInfo;
};
const setScenario = (state, scenarioObj) => {
  state.scenario = scenarioObj;
};
const setDebt = (state, debt) => {
  const foundIndex = state.debts.findIndex(item => item.debtID === debt.debtID);
  if (foundIndex !== -1) {
    state.debts[foundIndex] = { ...state.debts[foundIndex], ...debt };
  } else {
    state.debts.push(debt);
  }
};

const deleteDebt = (state, debt) => {
  const foundIndex = state.debts.findIndex(item => item.debtID === debt.debtID);
  if (foundIndex !== -1) {
    state.debts.splice(foundIndex, 1);
  }
};

const setPlanWOStacking = (state, plan) => { state.planWOStacking = plan; };
const setPlanWStacking = (state, plan) => { state.planWStacking = plan; };

const setAddADebtOpenFlag = (state, isOpen) => { state.addADebtOpen = isOpen; };

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};

export default {
  STEP: setStep,
  DEBTS: setDebts,
  PERSONINFO: setPersonInfo,
  DEBT: setDebt,
  DEBT_REMOVAL: deleteDebt,
  PLAN_WO_STACKING: setPlanWOStacking,
  PLAN_W_STACKING: setPlanWStacking,
  ADDADEBTOPEN: setAddADebtOpenFlag,
  SCENARIO: setScenario,
  resetState,
};
