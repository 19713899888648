<template>
  <v-text-field
    v-model="formInput.$model"
    type="tel"
    :id="idProp"
    :label="label || $t('L_phone') || 'Phone Number'"
    :autocomplete="disableAutocomplete ? 'off' : null"
    :disabled="disabled"
    :color="color"
    :hint="hint"
    mask="(###) ### - ####"
    :placeholder="placeHolder"
    :error-messages="errorMessages"
    @focus="toggleTooltip({field:'phoneNumber', show:true})"
    @input="onInput"
    @blur="onBlur(); toggleTooltip({field:'phoneNumber', show:false})"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  components: { FieldIcon },
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'phone_number',
    },
    placeHolder: {
      type: [Number, String],
      required: false,
      default: '',
    },
  },
  data: () => ({
    modelName: 'phoneNumber',
    phoneNumber: '',
  }),
  name: 'PhoneNumberInput',
  extends: BaseTextField,
};
</script>
