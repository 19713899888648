<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      xs12
      md7
      sm8
      pt-3
    >
      <v-slider
        always-dirty
        v-model="sliderAmount"
        @input="onSliderInput"
        @focus="showToolTip = true"
        @blur="showToolTip = false"
        :aria-labelledby="labelId"
      />
    </v-flex>
    <v-flex
      xs12
      offset-sm1
      sm3
      offset-md2
    >
      <CurrencyDisplay
        v-model="amount"
        :max-length="6"
        @input="onTextInput"
        @focus="showToolTip = true"
        @blur="showToolTip = false"
        :notify="notifyUser"
        :label-id="labelId"
        :tool-tip-id="toolTipId"
      />
    </v-flex>
    <v-tooltip
      v-if="tooltipText && $vuetify.breakpoint.mdAndUp"
      slot="append"
      :right="$vuetify.breakpoint.mdAndUp"
      class="hidden-tablet-and-down"
      :class="{
        'mt-4': $vuetify.breakpoint.smAndDown,
      }"
      :max-width="$vuetify.breakpoint.xsOnly ? '' : '186'"
      v-model="showToolTip"
      color="primary"
    >
      <v-icon slot="activator" />
      <span
        role="tooltip"
        :id="toolTipId"
      >
        {{ tooltipText }}
      </span>
    </v-tooltip>
    <v-flex
      v-if="tooltipText && showToolTip"
      class="hidden-desktop-and-up slider-tooltip"
      xs12
      md7
    >
      {{ tooltipText }}
    </v-flex>
  </v-layout>
</template>

<script>
import Slider from '@/components/Inputs/Slider/Slider.vue';
import CurrencyDisplay from './CurrencyDisplay.vue';

export default {
  name: 'LinkedSlider',
  components: {
    Slider,
    CurrencyDisplay,
  },
  props: {
    value: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    min: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    max: {
      type: [Number, String],
      required: false,
      default: 100,
    },
    step: {
      type: [Number, String],
      required: false,
      default: 1,
    },
    tooltipText: {
      type: String,
      required: false,
      default: undefined,
    },
    labelId: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data: () => ({
    amount: 0,
    sliderAmount: 0,
    showToolTip: false,
    notifyUser: true,
  }),
  computed: {
    toolTipId() {
      return `tool_tip_${this.labelId}`;
    },
  },
  methods: {
    setSliderPosition(value) {
      if (value >= +this.max) {
        this.sliderAmount = 100;
        return;
      }
      if (value <= +this.min) {
        this.sliderAmount = 0;
        return;
      }
      this.sliderAmount = Math.floor(((value - (+this.min)) / (+this.step)));
    },
    onSliderInput(value) {
      this.amount = (+this.min + Math.floor(((value / 100) * (+this.max)) / (+this.step))) * (+this.step);
      this.$emit('input', this.amount);
    },
    onTextInput(value) {
      this.setSliderPosition(value);
      this.$emit('input', value);
    },
  },
  mounted() {
    this.setSliderPosition(this.value);
    this.amount = this.value;
  },
  watch: {
    value(value) {
      this.amount = value;
      this.setSliderPosition(value);
    },
  },
};
</script>

<style lang="stylus" scoped>
$mdAndDown = 'only screen and (max-width: 1023px)';
$lgAndUp = 'only screen and (min-width: 1024px)';
$smAndDown = 'only screen and (max-width: 767px)';

.hidden-tablet-and-down, .hidden-desktop-and-up {
  display: none;
}

.hidden-tablet-and-down {
  @media $lgAndUp {
    display: flex;
  }
}

.hidden-desktop-and-up {
  @media $mdAndDown {
    display: flex;
  }
}

.slider-tooltip {
  @media $mdAndDown {
    margin-top: -48px !important;
  }

  @media $smAndDown {
    margin-top: 0 !important;
  }
}

/deep/ .v-text-field input {
  padding: 0px !important;
}
</style>
