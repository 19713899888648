<template>
  <div :class="this.dirty ? (this.valid ? 'success-hint' : 'error-hint') : ''">
    <v-icon
      size="12"
      :color="this.dirty ? (this.valid ? 'success' : 'error') : 'default'"
    >
      {{ this.valid ? 'check_circle' : 'cancel' }}
    </v-icon>
    <span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    dirty: {
      type: Boolean,
      required: true,
      default: false,
    },
    valid: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/ .v-icon {
  padding-bottom: 2px;
}
</style>
