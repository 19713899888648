import MyDebt from './MyDebt.vue';

export default {
  name: 'MyDebt',
  path: 'mydebt',
  component: MyDebt,
  params: {
    page: 'mydebt',
  },
  meta: {
    pageTitle: 'My.Debt',
    indexOfRoute: 0,
  },
};
