// TODO: the default policies array shouldd be empty
export const getDefaultState = () => ({
  step: -1,
  loading: {
    payment: false,
    policy: false,
    ach: false,
  },
  navHintDismissed: true,
  selectedPolicyIndex: 0,
  paymentPanel: [{
    contractId: 0,
    editPayments: {
      paymentMethodChangeAlert: false,
      editPaymentMethod: false,
      disablePaymentDetailsLink: false,
    },
    panelValue: -1,
  }],
  policies: [{
    contractId: 0,
    policyData: {
      owner: {
        name: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: null,
          city: '',
          state: '',
          zip: '',
        },
      },
      paymentInfo: {
        premium: '',
        paidToDate: '',
        method: '',
        draftDate: '',
      },
      details: {
        primaryInsured: '',
        status: '',
        insured: [{
          dateOfBirth: '',
          issueDate: '',
          sex: '',
          issueAge: '',
          planType: '',
          planDescription: '',
          underwritingClass: '',
          benefitAmount: '',
          name: '',
        }],
        annuity: {
          value: '',
          type: '',
          description: '',
          issueDate: '',
        },
      },
      totalCoverageAmount: 0,
      bank: {
        accountNumber: '',
        payorName: '',
        bankName: '',
      },
    },
    paymentInfo: {
      AccountName: '',
      AccountNumber: '',
      AccountType: '',
      AnnualPremium: '',
      AutoDraftPaymentDate: '',
      BillForm: '',
      BillMode: '',
      BillToDate: '',
      ErrorMessage: '',
      ModePremiumAmount: '',
      NewPremiumAmount: '',
      NextCycleDate: '',
      PACPremium: '',
      PaidToDate: '',
      PayInProcess: '',
      PolicyNumber: '',
      PolicyRecordID: '',
      PolicyStatus: '',
      QuarterlyPremium: '',
      RestrictCode: '',
      ReturnCode: '',
      RoutingNumber: '',
      SemiAnnualPremium: '',
      SuspendCode: '',
      ZipCode: '',
    },
    servicingAgent: {
      id: '',
      name: '',
      phone: '',
      fax: '',
      email: '',
      title: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
    showPayNowLink: false,
    payNowLinkDesc: '',
  }],
  successResponseUpdateContactInfo: [],
  errorResponseUpdateContactInfo: [],
  addressVerification: undefined,
  addressUpdateSuccess: false,
  base64String: null,
  contactDetailsPanel: -1,
  editContactInfo: false,
  bankName: null,
  paymentData: {
    amount: '',
    paymentType: '',
    accountType: '',
    accountCode: '',
    accountHolder: '',
    routingNumber: '',
    accountNumber: '',
    bankName: '',
  },
  restrictedPolicy: false,
  showPayNowInfoCard: false,
  paymentInfoShowPaymentAmountDateBillingMethod: true,
  payNowLinkToggle: true,
  updatingBank: false,
  preLoadBank: null,
  beneficiaries: [],
  beneficiaryStep: -1,
  beneficiaryPrimary: [],
  beneficiaryPrimaryContingent: [],
  beneficiarySpouse: [],
  beneficiarySpouseContingent: [],
  isBeneficiaryDataDirty: false,
  paymentStep: -1,
  routeNameToNavigateBack: '',
  hasTerminatedPolicies: undefined,
  terminatedPolicyAgent: [],
  scrollToPolicyDetails: 'policy-panel',
  withDrawalDateStep: -1,
});

const state = getDefaultState();

export default state;

