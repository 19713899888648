<i18n src='../locales.json'></i18n>

<template>
  <v-card color="grey lighten-4">
    <v-card-title>
      <v-flex>
        <span
          class="card-title"
        >{{ $t('L_new_york_state_domestic_and_sexual_violence') }}</span>
      </v-flex>
    </v-card-title>
    <v-divider />
    <v-layout
      row
      wrap
      pt-2
    >
      <v-flex
        xs10
        offset-xs1
        sm6
        offset-sm0
        md5
        lg4
        pa-3
        pb-2
      >
        <h5
          class="heading_five font-weight-medium"
        >
          {{ $t('L_english') }}
        </h5>
        <a :href="`tel:+`+$t('L_hotline')">
          <h4 class="heading_four">
            <v-icon
              size="22px"
              color="#02579e"
              class="mr-2"
            >phone</v-icon>
            <span>{{ $t('L_hotline') }}</span>
          </h4>
        </a>
      </v-flex>
      <v-flex
        xs10
        offset-xs1
        sm6
        offset-sm0
        md5
        lg4
        pa-3
        pb-0
      >
        <h5 class="heading_five font-weight-medium">
          {{ $t('L_in_nyc') }}
        </h5>
        <h4 class="heading_four">
          <v-icon
            size="22px"
            color="#02579e"
            class="mr-2"
          >
            phone
          </v-icon>
          <a
            :href="`tel:+`+$t('L_hope_no')"
            class="font-weight-regular"
          >
            <span>{{ $t('L_hope') }}</span>
          </a>
          <br>
          <span class="pl-4 ml-2">
            or
            <a
              :href="`tel:+`+$t('L_dial_311_no')"
              class="font-weight-regular"
            >
              <span>{{ $t('L_dial_311') }}</span>
            </a>
          </span>
        </h4>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs10
        offset-xs1
        sm6
        offset-sm0
        md5
        lg4
        pa-3
        pb-0
      >
        <h5 class="heading_five font-weight-medium">
          {{ $t('L_spanish') }}
        </h5>
        <a :href="`tel:+`+$t('L_spanish_no')">
          <h4 class="heading_four">
            <v-icon
              size="22px"
              color="#02579e"
              class="mr-2"
            >phone</v-icon>
            <span>{{ $t('L_spanish_no') }}</span>
          </h4>
        </a>
      </v-flex>
      <v-flex
        xs10
        offset-xs1
        sm6
        offset-sm0
        md5
        lg4
        pa-3
        pb-0
      >
        <h5 class="heading_five font-weight-medium">
          {{ $t('L_tty') }}
        </h5>
        <a :href="`tel:+`+$t('L_in_nyc_no')">
          <h4 class="heading_four">
            <v-icon
              size="22px"
              color="#02579e"
              class="mr-2"
            >phone</v-icon>
            <span>{{ $t('L_in_nyc_no') }}</span>
          </h4>
        </a>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs10
        offset-xs1
        sm6
        offset-sm0
        md5
        lg4
        pa-3
        pb-0
      >
        <h5 class="heading_five font-weight-medium">
          {{ $t('L_deaf_hoh') }}
        </h5>
        <a :href="`tel:+`+$t('L_deaf_no')">
          <h4 class="heading_four">
            <v-icon
              size="22px"
              color="#02579e"
              class="mr-2"
            >phone</v-icon>
            <span>{{ $t('L_deaf_no') }}</span>
          </h4>
        </a>
      </v-flex>
      <v-flex
        xs10
        offset-xs1
        sm6
        offset-sm0
        md5
        lg4
        pa-3
        pb-0
      />
    </v-layout>
  </v-card>
</template>
<script>

export default {
  name: 'ViewContactDetails',
};
</script>

