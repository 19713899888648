<i18n src='./locales.json'></i18n>

<template>
  <v-flex>
    <v-text-field
      v-model="formInput.$model"
      :id="id"
      :label="label || $t && $t('L_date') ||'Date of Birth'"
      required
      :disabled="disabled"
      :mask="(canadian && !quebec) ? '####/##/##' : '##/##/####'"
      :placeholder="fieldPlaceholder"
      :error-messages="errorMessages"
      :validation-messages="validationMessages"
      :autocomplete="disableAutocomplete ? 'off' : null"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      :aria-describedby="describers"
    >
      <FieldIcon
        slot="append"
        :form-field="formInput"
      />
    </v-text-field>
  </v-flex>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'DateInput',
  components: { FieldIcon },
  extends: BaseTextField,
  props: {
    id: {
      type: String,
      required: false,
      default: 'date',
    },
    canadian: {
      type: Boolean,
      required: false,
      default: false,
    },
    quebec: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: String,
      required: false,
      default: undefined,
    },
    max: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    modelName: 'date',
    date: '',
    showPicker: false,
  }),
  computed: {
    /* eslint default-case: "error" */
    ...BaseTextField.computed,
    fieldPlaceholder() {
      switch (true) {
        case this.quebec:
          return 'DD/MM/YYYY';
        case this.canadian:
          return 'YYYY/MM/DD';
        default:
          return 'MM/DD/YYYY';
      }
    },
  },
  methods: {
    onInput(e) {
      this.onChange(e);
      const regex = this.canadian && !this.quebec ? /^(\d{4})(\d{2})(\d{2})$/ : /^(\d{2})(\d{2})(\d{4})$/;
      let replace = '$3-$1-$2';
      if (this.canadian && !this.quebec) {
        replace = '$1-$2-$3';
      }
      if (this.quebec) {
        replace = '$3-$2-$1';
      }
      this.$emit('date', e.replace(regex, replace));
    },
  },
};
</script>
