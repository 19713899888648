import { helpers } from 'vuelidate/lib/validators';
import messages from './messages';

export default {
  // TODO: Better way to manage messages https://itnext.io/vuelidate-validation-messages-7c318322fd87
  getErrorMessages({ vObject, field }) {
    const errors = [];
    if (!vObject[field].$dirty) {
      return errors;
    } else if (vObject[field].$error) {
      // If there is an error on the field
      if (vObject[field].email === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'email')) {
        errors.push(messages[field].email);
      }
      if (vObject[field].required === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'required')) {
        errors.push(messages[field].required);
      }
      if (vObject[field].minLength === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'minLength')) {
        errors.push(messages[field].minLength);
      }
      if (vObject[field].maxLength === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'maxLength')) {
        errors.push(messages[field].maxLength);
      }
      if (vObject[field].sameAs === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'sameAs')) {
        errors.push(messages[field].sameAs);
      }
      if (vObject[field].uniqueAccount === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'uniqueAccount')) {
        errors.push(messages[field].uniqueAccount);
      }
      if (vObject[field].accountExists === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'accountExists')) {
        errors.push(messages[field].accountExists);
      }
      if (vObject[field].name === false && messages[field] && Object.prototype.hasOwnProperty.call(messages[field], 'name')) {
        errors.push(messages[field].name);
      }
      // If no messages have been added for this error
      if (errors.length === 0) {
        errors.push(messages.generic.invalid);
      }
    }
    return errors;
  },
  validatePolicyNumber(value) {
    if (value.match(/^(04|34|05|10)\d+/)) {
      return true;
    }
    return false;
  },
  validateSSN({ country, allowTaxId }) {
    return (value) => {
      let isValid = true;
      if (value !== undefined && value.length > 0) {
        if (value.match('(^[0-9]{9}$)') === null || // needs to be 9 digit number
          value.match(/^(1+|2+|3+|4+|5+|6+|7+|8+|9+|0+|123456789|987654321)$/) !== null) {
          isValid = false;
        }

        // When we need to allow TIN numbers (starting '9'), allow them enter it
        const regexUsSSNOnly = /^(?!(9|000|666))[0-9]{3}[0-9]{2}(?!(0000))[0-9]{4}$/;
        const regexUsSsnTin = /^(?!(000|666))[0-9]{3}[0-9]{2}(?!(0000))[0-9]{4}$/;
        const regexUS = allowTaxId ? regexUsSsnTin : regexUsSSNOnly;
        if (country === 'US' && value.match(regexUS) === null) {
          isValid = false;
        // Do the Mod-10 validation for Canada
        // Note: mod10Check functions won't restrict numbers starting 9
        } else if (country === 'CA' && this.mod10Check(value) === false) {
          isValid = false;
        }
      }
      return isValid;
    };
  },
  mod10Check(val) {
    let sum = 0;
    for (let i = 0; i < val.length; i += 1) {
      let intVal = parseInt(val.substr(i, 1), 10);
      if (i % 2 !== 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return (sum % 10) === 0;
  },
  validateDateOfBirth(value) {
    let isValid = false;
    if (value === '') {
      return true;
    }
    if (value && value.match(/^(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(19|20)([0-9]{2})$/)) {
      isValid = true;
    }
    return isValid;
  },
  validateUniqueAccount(type) {
    return (value) => {
      if (type === 'email') {
        return value !== 'exists@primerica.com';
      }
      if (type === 'phoneNumber') {
        return value !== '4444444444';
      }
      if (type === 'policyNumber') {
        return value !== '0444444444';
      }
      if (type === 'socialSecurityNumber') {
        return value !== '044444444';
      }
      return true;
    };
  },
  noSpaces: helpers.regex('noSpaces', /^((?!\s).)*$/),
  noInvalidSpecialChars: helpers.regex('noInvalidSpecialChars', /^((?![,'<>"&]).)*$/),
  requireUpperLowerCase: helpers.regex('requireUpperLowerCase', /(?=.*[a-z])(?=.*[A-Z]).*/),
  requireNumber: helpers.regex('requireNumber', /(?=\d).*/),
  requireSymbol: helpers.regex('requireSymbol', /(?!\d)(?!\w).+/),
  noThreeCharsInRow: helpers.regex('noThreeCharsInRow', /^(?!.*(.)\1\1).*$/),
  notContainString(value, string) {
    if (!value || value === '') {
      return true;
    }
    return value.indexOf(string) === -1;
  },
};

export function validateName(value) {
  if (!value || value === '') {
    return true;
  }
  return value.match(/^([A-Za-z. ]+)$/) !== null;
}

/** Regexes
 *  Three Characters in a row - /(.)\1{2,}/
 *  Verify that there is only a single "@" - /^[^@]+[@][^@]+$/
 *  Verify that there are not two or more dots in a row - /(\.)\1{1,}/
 *
 */
