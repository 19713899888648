import Vue from 'vue';
import Vuetify from 'vuetify';
import '@/assets/main.styl';

Vue.use(Vuetify, {
  theme: {
    primary: '#063562',
    secondary: '#424242',
    primarylight: '#e2f0fe',
    primarylight2: '#f5f7fa',
    accent: '#82B1FF',
    error: '#DD3333',
    info: '#2196F3',
    success: '#0B8D1F',
    warning: '#EE7026',
  },
  options: {
    customProperties: true,
  },
});
