import { BrowserVault, Vault } from '@ionic-enterprise/identity-vault';
import { CapacitorFacade } from '../../capacitorPlugin';
import { VAULT_TYPE, DEVICE_SECURITY_TYPE, ANDROID_BIOMETRIC_CRYPTO_PREFERENCE } from './definitions';
import { DynatracePlugin } from '../../dynatracePlugin';

const generateDefaulltAndroidVaultConfig = () => {
  // ! `key` and `type` is required, everything else have default values
  const c = {
    /*
     * For Android, specifies Strong Cryptographic Vault ("StrongVault")
     * or the System Passcode fallback ("SystemPasscode") when they both aren't available
     */
    androidBiometricsPreferStrongVaultOrSystemPasscode: ANDROID_BIOMETRIC_CRYPTO_PREFERENCE.STRONG_VAULT, // ? default StrongVault
    /* For Android, text of the button to dismiss the biometric prompt */
    androidBiometricsNegativeButtonText: 'Cancel', // ? default "Cancel"
    /* For Android, the title text for the authentication prompt */
    androidBiometricsPromptTitle: 'Please Authenticate', // ? default "Please Authenticate"
    /* For Android, the subtitle text for the authentication prompt */
    androidBiometricsPromptSubtitle: '', // ? default null
    /* For Android, the description text for the Android authentication prompt */
    androidBiometricsPromptDescription: '', // ? default null
  };
  return c;
};

const generateDefaulltIosVaultConfig = () => {
  const c = {
    /* For iOS, the localized title for the cancel button in the dialog during authentication */
    iosBiometricsLocalizedCancelTitle: 'Cancel', // ? If null, iOS will use default value, like "Cancel".
    /* For iOS, the localized title for the fallback button presented to the user during authentication */
    iosBiometricsLocalizedFallbackTitle: 'Use Passcode', // ? If null, iOS will use default value, like "Use Passcode”.
    /* For iOS, the localized explanation for authentication shown in the dialog presented to the user */
    iosBiometricsLocalizedReason: '',
  };
  return c;
};

const generateDefaultIdentityVaultConfig = () => {
  // ! `key` and `type` is required, everything else have default values
  const c = {
    /* The key that will be used to uniquely identify the vault in the app */
    key: 'com.primerica.myprimerica',
    /* When true, vault will attempt to unlock when app launches and resumes from the background */
    unlockVaultOnLoad: false, // ? default false
    /* Lock the vault when app is resumed if it has been in the background for this number of milliseconds */
    lockAfterBackgrounded: null, // ? default null
    /* If the app should clear the vault if too many invalid unlock attempts. Only supported on `CustomPasscode` vaults. */
    shouldClearVaultAfterTooManyFailedAttempts: false, // ? default false
    /* controls how many failed unlock attempts are allowed if `shouldClearVaultAfterTooManyFailedAttempts` is enabled */
    customPasscodeInvalidUnlockAttempts: 5, // ? default 5
    /* The type of identity vault */
    type: VAULT_TYPE.DEVICE_SECURITY, // ? "SecureStorage" | "DeviceSecurity" | "CustomPasscode" | "InMemory"
    /* When vault type is `DeviceSecurity`, determines how to secure the vault; Default is `Both` */
    deviceSecurityType: DEVICE_SECURITY_TYPE.BOTH, // ? "SystemPasscode" | "Biometrics" | "Both" | "None"
  };
  return c;
};

/*
* Creates a secure vault on devices, or a fallback vault on browsers
* // ? [Identity Vault](https://ionic.io/docs/identity-vault/)
* // ? see https://ionic.io/docs/identity-vault/classes/vault
* // ? see https://ionic.io/docs/identity-vault/classes/browservault
*/
export const createVault = (config) => {
  // ? see https://ionic.io/docs/identity-vault/interfaces/identityvaultconfig
  const isNative = CapacitorFacade.isNativePlatform();
  const isIos = CapacitorFacade.isIos();
  const deviceConfig = isIos ? generateDefaulltIosVaultConfig() : generateDefaulltAndroidVaultConfig();
  let defaultConfig = generateDefaultIdentityVaultConfig();
  defaultConfig = isNative ? { ...defaultConfig, ...deviceConfig } : defaultConfig;

  if (isNative) {
    const v = new Vault({ ...defaultConfig, ...config });
    // TODO: use DT and remove console logs
    v.onConfigChanged((c) => {
      // eslint-disable-next-line
      console.info(`Updated Vault Config: ${JSON.stringify(c, null, 2)}`);
    });
    v.onLock((lockEvent) => {
      try {
        const i = DynatracePlugin.getInstance().enterAction(`Vault Locked${lockEvent?.timeout ? 'due to background timeout' : ''}`);
        DynatracePlugin.getInstance().leaveAction(i);
      } catch (error) { /*  */ }
    });
    v.onUnlock(() => {
      try {
        const i = DynatracePlugin.getInstance().enterAction('Vault Unlocked');
        DynatracePlugin.getInstance().leaveAction(i);
      } catch (error) { /*  */ }
    });
    return v;
  }
  return new BrowserVault({ ...defaultConfig, ...config });
};

export default {
  createVault,
};
