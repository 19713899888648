<i18n src='./locales.json'></i18n>

<template>
  <v-flex
    class="term-life-insurance-card"
    xs12
  >
    <v-card-text>
      <v-layout
        row
        wrap
        pl-3
        pr-3
      >
        <v-flex
          xs12
          md6
          class="text-xs-center"
        >
          <!-- first row -->

          <v-layout
            row
            wrap
            class="mt-3"
          >
            <v-flex
              xs12
              class="text-xs-center"
            >
              <v-spacer class="hidden-sm-and-up mt-4" />
              <div
                class="mb-1 font-weight-medium font-size--24"
              >
                {{ $t('L_protect_what_matters') }}
              </div>
              <div
                class="mb-1 font-weight-medium font-size--16"
              >
                {{ $t('L_get_the_protection') }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          md6
          class="text-xs-center"
        >
          <v-spacer class="hidden-md-and-up mt-3" />
          <v-layout
            align-center
            justify-center
            row
            fill-height
          >
            <v-flex
              class="font-size--14"
            >
              <v-btn
                role="link"
                class="btn-learn-more mx-0"
                color="primary"
                @click="$router.push({ path: '/benefits' });"
              >
                {{ $t('L_learn_more') }}
              </v-btn>
              <v-spacer class="hidden-sm-and-up mt-2" />
            </v-flex>
          </v-layout>
          <v-spacer class="hidden-md-and-up mb-3" />
        </v-flex>
      </v-layout>
      <!-- second row -->
      <v-layout
        row
        wrap
        class="protected-box pa-3 mt-4"
      >
        <v-flex
          xs12
          md6
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md2
              class="text-xs-center"
            >
              <img
                src="../../assets/money-shield-icon.svg"
                alt=""
                role="presentation"
              >
            </v-flex>
            <v-flex
              xs12
              md10
              pl-3
              text-center
            >
              <h1
                class="font-size--16 font-weight-medium mb-1"
                role="heading"
                aria-level="3"
              >
                {{ $t('L_how_much_is_enough') }}
              </h1>
              <span
                class="font-size--14"
                v-html="$t('L_determining_how_much_protection')"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          md6
        >
          <v-spacer class="hidden-md-and-up mt-3" />
          <v-layout
            row
            fill-height
            align-center
            justify-center
            v-show="showGuidanceCalculatorTab"
          >
            <v-btn
              id="get_guidance_button"
              role="link"
              @click="$router.push({ path: '/policy/guidance' })"
              class="btn-learn-more"
              color="secondary"
            >
              {{ $t('L_get_guidance') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import Ada from '@/shared/ada/ada';

export default {
  name: 'PrePurchaseLifeCard',
  mounted() {
    if (this.$refs.items !== undefined) {
      Ada.popupMenu(this.$refs.policies, this.$refs.items.$el);
    }
  },
  computed: {
    ...mapGetters({
      isMobileApp: 'isMobileApp',
    }),
    showGuidanceCalculatorTab() {
      return !this.isMobileApp;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'

.term-life-insurance-card
  .protected-box
    background-color: #ebebeb;

.btn-learn-more
  width: 100%;
  max-width: 288px;

@media $display-breakpoints.xs-only
  .term-life-insurance-card
    .protected-box
      h1
        margin-top: 16px;
</style>
