export const getDefaultState = () => ({
  step: -1, // -1 not started, 1 started
  debts: [],
  planWOStacking: {},
  planWStacking: {},
  fna: {}, // placeholder; this may not be needed
  addADebtOpen: false,
  scenario: {},
  personInfo: null,
});

const state = getDefaultState();

export default state;
