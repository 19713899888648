export const bankingNumbers = {
  methods: {
    maxLengthCheck(value) {
      if (value.length > this.maxLengthinput + 1) {
        this.formInput.$model = value.slice(0, this.maxLengthinput);
        this.maxReached = true;
      } else {
        this.maxReached = false;
      }
    },
    isNumeric($event) {
      const { keyCode } = $event;
      const key = String.fromCharCode(keyCode);
      const regex = /[0-9]/;
      if (!regex.test(key)) {
        $event.returnValue = false;
        $event.preventDefault();
      }
    },
    noMax($event) {
      if (this.maxReached && !($event.keyCode === 46 || $event.keyCode === 8)) {
        $event.preventDefault();
      }
    },
  },
};
