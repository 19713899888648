<i18n src='./locales.json'></i18n>
<template>
  <v-layout class="no-investments bottom align-center" row wrap>
    <v-flex xs4 md2 pl-4 class="icon ">
      <img
        :class="$vuetify.breakpoint.xsOnly ? 'icon-left': 'icon'"
        role="presentation"
        :alt="$t('L_how_soon_could_you')"
        src="../../../assets/debt-calculator.svg"
      >
    </v-flex>
    <v-flex xs8 md5>
      <h4
        class="heading_four pa-0 font-weight-bold"
        :aria-label="$t('L_how_soon_could_you')"
      >
        {{ $t('L_how_soon_could_you') }}
      </h4>
      <div
        class="text"
        :aria-label="$t('L_debt_stacking_can_lead')"
      >
        {{ $t('L_debt_stacking_can_lead') }}
      </div>
    </v-flex>
    <v-flex xs12 md5 class="create-your-plan">
      <v-layout row fill-height align-center justify-center>
        <v-btn
          block
          outline
          color="#063562"
          class="btn_create_plan"
          :aria-label="$t('L_create_your_plan')"
          @click="GoToDebtSolutionsLandingPage()"
        >
          {{ $t('L_create_your_plan') }}
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DebtSolutionsNoPlanBottom',
  methods: {
    ...mapActions({
      setAddADebtOpen: 'debtsolutions/setAddADebtOpen',
    }),
    GoToDebtSolutionsLandingPage() {
      this.setAddADebtOpen(false).then(() => {
        if (this.$route.path !== '/debtsolutions/mydebt') {
          this.$router.push({ name: 'MyDebt' });
        }
      }).catch((error) => { this.addError({ error, context: 'learn_more' }); });
    },
  },
};
</script>

<style lang="stylus" scoped>
  .create-your-plan
    padding: 0 15px;
  .btn_create_plan
    width: 100%;
    max-width: 288px;
    margin-right: 0px;
    margin-left: 0px;
  .icon-left
    margin-left:-12px;
</style>
