export const debts = state => state.debts;

export const step = state => state.step;

export const addADebtOpen = state => state.addADebtOpen;

export const personInfo = state => state.personInfo;

export const scenario = state => state.scenario;

// Grab a particular debt using its debt Id
export const debtGivenDebtId = state => debtID => state.debts.filter(item => item.debtID === debtID)[0];

export const projectedPayoffDateGivenDebtId = state => debtID => state.planWOStacking.planItemCollection.filter(item => item.debtID === debtID)[0].projectedPayoffDate;

export const debtBalanceForDebtId = state => debtID => state.debts.filter(item => item.debtID === debtID)[0].balance;

export const debtMonthlyPaymentForDebtId = state => debtID => state.debts.filter(item => item.debtID === debtID)[0].minPayment;

// Unpaid debts
export const unpaidDebts = state => state.debts.filter(item => item.paidOffInd === 0);

// Paid off debts
export const paidOffDebts = state => state.debts.filter(item => item.paidOffInd === 1 || item.balance === 0);

export const getTotalMinPaymentUsinggPaidOffDebts = (state) => {
  let totalMinPayment = 0;
  const tPaidOffDebts = state.debts.filter(item => item.paidOffInd === 1 || item.balance === 0);
  if (tPaidOffDebts != null && tPaidOffDebts.length) {
    tPaidOffDebts.forEach((element) => {
      totalMinPayment += element.minPayment;
    });
    return totalMinPayment;
  }
  return totalMinPayment;
};

export const planStatus = (state) => {
  // possible values: `-1` no plan, `0` active plan, `1` completed plan
  if (state.debts != null && state.debts.length) {
    // if all debts are paid off, return 1;
    // otherwise, return 0
    return unpaidDebts(state).length === 0 ? 1 : 0;
  }
  // return -1 if there are no debts
  return -1;
};

export const twoOrMoreDebtsAndMinOneUnpaidDebt = (state) => {
  if (state.debts != null && state.debts.length) {
    const unpaidDebtCount = unpaidDebts(state).length;
    const totalDebt = unpaidDebtCount + paidOffDebts(state).length;
    if (totalDebt >= 2 && unpaidDebtCount >= 1) {
      return true;
    }
    return false;
  }
  return false;
};

export const minimumTwoUnpaidDebtsStatus = (state) => {
  // possible values: `-1` no plan, `0` active plan, `1` completed plan
  if (state.debts != null && state.debts.length) {
    // if all debts are paid off, return 1;
    // otherwise, return 0
    return unpaidDebts(state).length > 1 ? 1 : 0;
  }
  // return -1 if there are no debts
  return -1;
};

export const hasNoPlan = state => planStatus(state) === -1;
export const hasActivePlan = state => planStatus(state) === 0;
export const hasCompletedPlan = state => planStatus(state) === 1;

export const planWOStacking = state => state.planWOStacking;
export const planWStacking = state => state.planWStacking;

