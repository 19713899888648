<script>
import DateInput from '../Date/DateInput.vue';

export default {
  name: 'DOBInput',
  extends: DateInput,
  data: () => ({
    modelName: 'dateOfBirth',
    dateOfBirth: '',
  }),
};
</script>
