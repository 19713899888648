import { Policy } from './Policy';

export class Phase {
  phaseNo;
  phaseSequenceNo;
  personFirstName;
  personLastName;
  personTaxId;
  personSex;
  personDateOfBirth;
  personIssueAge;
  phPlanzPerson;
  phaseIsIBR;
  phaseIsAnnuity;
  phPlan;
  planDescription;
  planzText;
  phIssueDate;
  phaseEOTDate;
  phInforceCode;
  phIssueState;
  coverageAmount;
  phPremium;
  phWPPrem;
  phWPPremSpouse;
  phOtherPrem;
  phUndwPremClass;
  personIssueAge;

  constructor(phaseData) {
    Object.assign(this, phaseData);
  }

  get coverageAmount() {
    return Phase.parseNum(this.coverageAmount);
  }

  get inForceCode() {
    return this.phInforceCode;
  }

  get isIbr() {
    return this.phaseIsIBR === true || this.phaseIsIBR === 'true';
  }

  get isAnnuity() {
    return this.phaseIsAnnuity === true || this.phaseIsAnnuity === 'true';
  }

  get issueAge() {
    return Phase.parseNum(this.personIssueAge);
  }

  get issueDate() {
    return Policy.parseDate(this.phIssueDate, 'MM/DD/YYYY');
  }

  get issueState() {
    return this.phIssueState;
  }

  get dateOfBirth() {
    return Policy.parseDate(this.personDateOfBirth, 'MM/DD/YYYY');
  }

  get eotDate() {
    return Policy.parseDate(this.phaseEOTDate);
  }

  get premium() {
    return Phase.parseNum(this.phPremium);
  }

  get insuredName() {
    return `${this.personFirstName} ${this.personLastName}`;
  }

  get isChild() {
    return this.person.length === 2 && this.person.slice(0, 1) === '2';
  }

  get person() {
    return this?.phPlanzPerson;
  }

  get planDescription() {
    return this.planDescription || '';
  }

  set planDescription(description) {
    this.planDescription = description || '';
    return this.planDescription;
  }

  get planText() {
    return this.planzText || '';
  }

  get sequenceNumber() {
    return this.phaseSequenceNo;
  }

  get sex() {
    return this.personSex;
  }

  get underwritingClass() {
    return this.phUndwPremClass || '';
  }

  get wpPrem() {
    return Phase.parseNum(this.phWPPrem);
  }

  get wpPremSpouse() {
    return Phase.parseNum(this.phWPPremSpouse);
  }

  static parseNum(number) {
    const num = +number;
    return !Number.isNaN(num) ? num : 0.0;
  }
}
