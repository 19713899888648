<i18n src='./locales.json'></i18n>
<template>
  <div class="cssBottom">
    <slot name="stickycontent" />
  </div>
</template>

<script>
export default {
  name: 'StickyContainer',
};
</script>

<style lang="stylus" scoped>
.cssBottom {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white;
}

.cssLeft {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

.cssTop {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.cssRight {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: white;
}


</style>
