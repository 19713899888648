<template>
  <div class="edit-payment-methods-wrapper">
    <StepOne
      v-if="step === 0"
      :policy-data="policy"
      @goToStepTwo="goToStepTwo"
      @cancel="$emit('cancel')"
    />
    <StepTwo
      v-if="step === 1"
      :policy-data="policy"
      :new-billing-mode="newBillingMode"
      @open-contact-details="openContactDetailsPanel"
      @goToStepThree="goToStepThree"
    />
    <StepThree
      v-if="step === 2"
      :policy-data="policy"
      @open-contact-details="openContactDetailsPanel"
      @done="$emit('cancel')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StepOne from './StepOne/StepOne.vue';
import StepTwo from './StepTwo/StepTwo.vue';
import StepThree from './StepThree/StepThree.vue';

export default {
  name: 'EditPaymentMethod',
  components: {
    StepOne,
    StepTwo,
    StepThree,
  },
  data: () => ({
    step: 0,
    newBillingMode: '',
  }),
  computed: {
    ...mapGetters('policy', {
      policy: 'currentPolicy',
    }),
  },
  methods: {
    goToStepTwo(newBillingMode) {
      this.newBillingMode = newBillingMode;
      this.step = 1;
    },
    goToStepThree() {
      this.step = 2;
    },
    openContactDetailsPanel() {
      this.$emit('cancel');
      this.$store.commit('policy/setPanelValue', -1);
      this.$store.commit('policy/setContactDetailsPanel', 0);
      this.$store.commit('policy/setContactInfoEdit', true);
    },
  },
};
</script>

