<i18n src="./locales.json"></i18n>
<template>
  <v-container style="max-width:1024px; margin:auto;">
    <v-layout row wrap class="main-body" justify-center>
      <Alerts class="fixed-top-alert" :context="'documents'" />
      <v-flex xs12>
        <v-layout pt-2 pb-4>
          <div>
            <v-icon large color="#b4c9db">
              folder_open
            </v-icon>
          </div>
          <v-flex shrink>
            <span class="pl-3 font-weight-regular font-size--24 white--text" role="heading" aria-level="1">{{ $t('L_documents') }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- Desktop -->
    <v-card class="mb-4" v-if="!isMobile">
      <v-layout row>
        <!-- Left side content -->
        <v-flex xs3 class="menu_divider">
          <v-list v-model="activeTile" class="tile_list" dense>
            <template class="background-color: red">
              <v-list-tile v-for="(item, i) in documentCategories" :key="i" @click="navigateToDocumentList(item.routeName)" :class="activeTile === i ? 'tile_active' : 'tile_inactive'">
                <v-list-tile-content :class="activeTile === i ? 'tile_text_active' : 'tile_text_inactive'" id="categoryTitle">
                  {{ item.title }}
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-flex>
        <!-- Right side content -->
        <v-flex xs9 class="right_container">
          <router-view />
        </v-flex>
      </v-layout>
      <v-card-text v-if="loading">
        <v-flex pa-3>
          <v-layout>
            <v-flex>
              <LoadingIndicator :loading="!documentsLoaded" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-card class="mb-4" v-if="isMobile">
      <v-layout row>
        <v-flex xs12>
          <v-card>
            <v-list two-line>
              <template v-for="(item, index) in documentCategories">
                <v-list-tile :key="item.title" avatar ripple @click="navigateToDocumentList(item.routeName)">
                  <v-list-tile-content>
                    <v-list-tile-title id="category-title-mobile">
                      {{ item.title }}
                    </v-list-tile-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <!-- <v-list-tile-action-text>{{ item.action }}</v-list-tile-action-text> -->
                    <v-icon color="#02579e" large>
                      keyboard_arrow_right
                    </v-icon>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider v-if="index + 1 < documentCategories.length" :key="index" />
              </template>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { COUNTRIES } from './../../shared/constants';
import Alerts from '../../components/Alerts.vue';

export default {
  name: 'Documents',
  components: {
    LoadingIndicator,
    Alerts,
  },
  data: () => ({
    documentsLoaded: true,
    loading: true,
    // activeTile: 0,
  }),
  computed: {
    ...mapGetters(['getLocale']),
    ...mapGetters(['documents', 'policyDocuments', 'notices', 'investmentStatements']),
    ...mapGetters('policy', ['policies']),
    ...mapGetters('customer', ['customerProfile', 'investments', 'countryOfOrigin']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isCanadian() {
      return this.countryOfOrigin === COUNTRIES.CANADA || this.getLocale?.indexOf('_CA') > 0;
    },
    hasPolicyDocuments() {
      return this.policies.filter(p => p.active || p.pending).length > 0;
      // return this.policyDocuments && this.policyDocuments.length > 0;
    },
    hasInvestments() {
      return this.investments?.summary?.totalValue > 0;
    },
    documentCategoriesTitles() {
      return {
        PolicyDocuments: this.$t('L_policy_documents'),
        NoticesAndConfirmations: this.$t('L_notices_and_confirmations'),
        InvestmentStatements: this.$t('L_investment_statements'),
      };
    },
    documentCategories() {
      // see http://confluence.primerica.com/display/PD/Documents++-+Consolidated+View+of+All+Documents#DocumentsConsolidatedViewofAllDocuments-DocumentPage-Categories
      const c = [
        {
          title: this.documentCategoriesTitles.NoticesAndConfirmations, // 'Notices and Confirmations',
          routeName: 'NoticesAndConfirmations',
        },
        {
          title: this.documentCategoriesTitles.PolicyDocuments, // 'Policy Documents',
          routeName: 'PolicyDocuments',
        },
      ];
      if (this.isCanadian) {
        c.push({
          title: this.documentCategoriesTitles.InvestmentStatements, // 'Investment Statements',
          routeName: 'InvestmentStatements',
        });
      }
      return c;
    },
    activeTile() {
      return this.selectedDocumentCategoryIndex(this.$route.name) || 0;
    },
  },
  async created() {
    await this.fetchDocuments();
    this.loading = false;
    this.documentsLoaded = true;

    // TODO: refactor
    if (this.isMobile) {
      if (['NoticesAndConfirmations', 'PolicyDocuments', 'InvestmentStatements'].includes(this.$route.name)) {
        // redirect to mobile view;
        this.$router.push({ name: 'DocumentListMobile', params: { routeName: this.$route.name } });
      }
    } else if (!this.isMobile && this.$route.name === 'DocumentListMobile') {
      // redirect to mobile view;
      this.$router.push({ name: this.$route.params.routeName || 'NoticesAndConfirmations' });
    }
  },
  watch: {
    isMobile(newVal, oldVal) {
      if (newVal !== oldVal) {
        // TODO: refactor
        if (this.isMobile) {
          if (['NoticesAndConfirmations', 'PolicyDocuments', 'InvestmentStatements'].includes(this.$route.name)) {
            // redirect to mobile view;
            this.$router.push({ name: 'DocumentListMobile', params: { routeName: this.$route.name } });
          }
        }
      }
    },
  },
  methods: {
    ...mapActions(['getDocuments']),
    ...mapActions('customer', ['getInvestments']),
    async fetchDocuments() {
      await this.getDocuments().catch(() => {
        this.$store.commit('addError', {
          error: {
            key: 'L_documents_not_available_error',
            message: this.$t('L_documents_not_available_error'),
          },
          context: 'documents',
        });
      });
    },
    navigateToDocumentList(routeName) {
      if (this.isMobile) {
        if (routeName === 'Documents' && this.route.name !== 'DocumentListMobile') {
          this.$router.push({ name: 'DocumentListMobile', params: { routeName: 'NoticesAndConfirmations' } }); // default
        }
        this.$router.push({ name: 'DocumentListMobile', params: { routeName } });
      } else if (this.$route.name !== routeName) {
        // if not already in the page
        if (routeName === 'Documents') {
          this.$router.push({ name: 'NoticesAndConfirmations' }); // default
        }
        this.$router.push({ name: routeName });
      }
    },
    selectedDocumentCategoryIndex(routeName) {
      return this.documentCategories.findIndex(val => val.routeName === routeName) || 0;
    },
  },
};
</script>

<style scoped lang="stylus">
.v-list.v-list--two-line.theme--light {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.link
  font-weight: 600;
  color: #0c6dca;
  cursor: pointer;
.myNotifications
  padding-left: 16px;
  padding-right: 16px;
.bgAllOfYourAvailable
  background: #f9f9f9;
.contactYourRepButtonWidth
  width: 264px;
  max-width: 265px;
  min-width: 264px;
.picture
  .agentImage
  .icon
    width: 55px;
    height: 55px;
    margin: 0 auto;
    border-radius: 50%;
.rep
    padding: 15px 0;
    background: #eee;
    .button-wrapper .v-btn
      width: 85%
      margin: 0 auto;
      background-color: #063562 !important;
.backgroundColorBlue
  background-color: #f5f7fa;
.backgroundColorWhite
  background-clor: #ffffff;
.borderLeftWall
  border-left: 1px solid #ccc;
.paddingForNoDismiss
  padding-right: 32px;
#you-have-no-available
  line-height: 1.5;
  text-align: center;
  color: #333;
#documents-folder-img
  margin-top:18px;
  margin-bottom:18px

.right_container {
  min-height: 800px;
}

.tile_list {
  padding: 0px;
}

.menu_divider {
  border-right: 1px solid #ccc !important
}

.tile_active {
  background-color: #e7edf3;
  border-right: 5px solid #3399ff !important
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}

.tile_active:hover {
  background-color: #e7edf3;
  border-right: 5px solid #3399ff !important
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}

.tile_inactive:hover {
  background-color: #e7edf3;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}

.tile_inactive {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}

.tile_text_active {
  font-weight: bold;
  white-space: normal;
  font-size: 14px;
}

.tile_text_inactive {
  white-space: normal;
  font-size: 14px;
}

#category-title-mobile {
  font-size: 16px;
}
</style>
