<i18n src='../locales.json'></i18n>

<template>
  <v-dialog
    max-width="554px"
    :value="show"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :persistent="persistent"
  >
    <v-card
      @click="$emit('show-modal')"
      class="clickable containerBox cursor-pointer"
    >
      <v-layout row-wrap class="alertBox">
        <v-flex xs12 color="#d8f8dd">
          <span>
            <v-icon class="right-icon" color="success">
              check_circle
            </v-icon>
          </span>
          <span class="blackText">
            {{ $t('L_phone_number_has_been_updated_successfully') }}
          </span>
        </v-flex>
      </v-layout>
      <v-content
        class="pa-3"
        style="height: 100%;"
        role="presentation"
      >
        <v-layout
          column
          justify-space-between
          fill-height
          text-xs-center
        >
          <v-flex xs12 font-size--24 class="successLabel">
            {{ $t('L_success') }}
          </v-flex>
          <v-flex xs12 font-size--16 text-xs-center class="bodyMargin">
            {{ $t('L_your_mobile_phone_has_been_successfully_updated_for_your') }}
          </v-flex>
          <v-flex xs12>
            <v-btn color="primary" block @click="closeSuccessVerifyPhone" class="btnWidth okButton">
              {{ $t('L_OK') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-content>
    </v-card>
  </v-dialog>
</template>
<script>

import {
  ALERT_VIEW_CONTEXT,
} from '../../../../../shared/constants';

export default {
  name: 'SuccessVerifyPhone',
  data: () => ({
    show: true,
    alertContext: ALERT_VIEW_CONTEXT.VERIFY_PHONE,
  }),
  methods: {
    closeSuccessVerifyPhone() {
      this.$emit('close-success-verify-phone');
    },
  },
};
</script>
<style scoped>
  .blackText {
    color: black !important;
  }
  .btnWidth {
    max-width: 288px;
    margin: auto;
  }
  .verifynow {
    background-color:#eee;
  }
  .bodyMargin {
    margin-top:45px;
    margin-bottom:64px;
  }
  .successLabel {
    margin-top: 20px;
  }
  .okButton {
    margin-bottom: 40px;
  }
  .alertBox {
    background-color: #d8f8dd;
    height: 48px;
    border-top: 3px solid #0b8d1f;
    padding-top: 8px;
    padding-left: 18px;
    font-weight: 600;
    color: #333333;
  }
</style>
