export class Address {
  _address1;

  _address2;

  _city;

  _state;

  _zip;

  _phone;

  _fax;

  _type;

  constructor({
    address1, address2, city, state, zip, phone, fax,
  }) {
    this._address1 = address1 || '';
    this._address2 = address2 || '';
    this._city = city || '';
    this._state = state || '';
    this._zip = zip || '';
    this._phone = phone || undefined;
    this._fax = fax || undefined;
  }

  get address1() {
    return this._address1;
  }

  set address1(value) {
    this._address1 = value;
  }

  get address2() {
    return this._address2;
  }

  set address2(value) {
    this._address2 = value;
  }

  get city() {
    return this._city;
  }

  set city(value) {
    this._city = value;
  }

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }

  get zip() {
    return this._zip;
  }

  set zip(value) {
    this._zip = value;
  }

  get phone() {
    return this._phone;
  }

  set phone(value) {
    this._phone = value;
  }

  get fax() {
    return this._fax;
  }
}
