<i18n src='../locales.json'></i18n>

<template>
  <div>
    <v-layout
      row
      wrap
      mt-3
    >
      <v-flex
        xs12
        sm8
        md7
        id="pay_off_mortgage"
      >
        <div
          class="heading_three mt-3"
        >
          {{ $t('L_pay_off_mortgage') }}
        </div>
        <div
          class="heading_four mt-2 font-weight-medium"
        >
          {{ $t('L_currently_owe') }}
        </div>
      </v-flex>
      <v-flex
        xs12
        offset-sm1
        sm3
        offset-md2
        md3
        mt-4
      >
        <CurrencyDisplay
          v-model="amount"
          @input="$emit('input', $event)"
          label-id="pay_off_mortgage"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Slider from '@/components/Inputs/Slider/Slider.vue';
import CurrencyDisplay from '../../CurrencyDisplay.vue';

export default {
  name: 'PayOffMortgage',
  data: () => ({
    amount: 0,
  }),
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    formInput: {
      type: Object,
      required: true,
    },
  },
  components: {
    Slider,
    CurrencyDisplay,
  },
  watch: {
    value(value) {
      this.amount = value;
    },
  },
  mounted() {
    this.amount = this.value;
  },
};
</script>

<style lang="stylus" scoped>

/deep/ .v-text-field input {
  padding: 0px !important;
}
</style>
