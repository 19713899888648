<template>
  <div>
    <div class="info-title">
      <span
        v-if="!hasSlot('title')"
      >{{ title }}</span>
      <slot name="title" />
    </div>
    <div class="info-content">
      <span
        v-if="!hasSlot('content')"
      >{{ content }}</span>
      <slot name="content" />
      <div>
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>

<style lang="stylus" scoped>

@require '~vuetify/src/stylus/settings/_variables'

.info-title
  font-size: $headings.subheading.size;
  margin-bottom: $spacers.one.y;
  font-weight: $font-weights.medium;
  text-align: center;
  @media $display-breakpoints.xs-only
    font-size: 14px;

.info-content {
  font-size: $headings.h6.size;
  font-weight: $font-weights.regular;
  text-align: center;
}

</style>
