<i18n src='../../locales.json'></i18n>

<template>
  <v-flex
    class="restricted-policy-code-box"
    xs12
  >
    <v-layout justify-end>
      <v-flex
        shrink
        class="view-policy-section policy-number"
      >
        <v-subheader class="white--text">
          <div>
            {{ $t('L_policy_policy_number') }}:
            <span class="font-weight-regular">{{ policyList[selectedPolicyIndex] && policyList[selectedPolicyIndex].contractId }}</span>
          </div>
        </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      class=""
    >
      <v-flex
        xs12
        class="restricted-policy-message-box mt-3 pb-5 pt-5"
      >
        <h3
          class="heading_three text-xs-center mb-3"
        >
          {{ $t('L_restricted_policy_code_message') }}
        </h3>
        <h3
          v-html="$t('L_restricted_policy_code_number', {phoneWODash:getCustServNumWODash(locale),phoneWDash:getCustServNumWDash(locale)})"
          class="heading_three text-xs-center"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RestrictedPolicy',
  computed: {
    ...mapGetters('policy', {
      policies: 'policies',
      selectedPolicyIndex: 'selectedPolicyIndex',
    }),
    ...mapGetters('customer', {
      getCustServNumWDash: 'getCustServNumWDash',
      getCustServNumWODash: 'getCustServNumWODash',
    }),
    ...mapGetters({
      locale: 'getLocale',
    }),
    policyList() {
      return this.policies.map(p => ({ contractId: p.contractId }));
    },
  },
  methods: {
    ...mapActions('policy', {
      changePolicy: 'changePolicy',
    }),
  },
};
</script>

<style lang="stylus" scoped>
  .policy-items-dropdown
    position: relative;
    border-radius: 13.5px;
    width: 135px;
    height: 27px;
    background: #ebebeb;
    margin: 0;
    padding: 0px 35px 0 10px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.1;
    letter-spacing: normal;
    color: #000;
    span
      font-weight 600
    .v-icon
      position: absolute;
      right: 10px;
      top: 2px;
      color: #2196f3;
  .policy-number-text
    font-size: 12px;
    font-weight: 600;
    padding: 0 10px 0 0;
  .restricted-policy-message-box
    background-color: rgba(216, 216, 216, 0.95);
    box-shadow: 0px 0px 1px rgba(0,0,0,0.5) inset;
</style>
