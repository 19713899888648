<i18n src='./locales.json'></i18n>
<template>
  <v-layout class="no-investments bottom align-center" row wrap>
    <v-flex xs4 md2 pl-4 class="icon">
      <img
        class="icon"
        role="presentation"
        :alt="$t('L_how_soon_could_you')"
        src="../../../assets/debt-success.svg"
      >
    </v-flex>
    <v-flex xs8 md5>
      <h4
        class="heading_four pa-0"
        :aria-label="$t('L_congratulations')"
      >
        <h2 class="text-xs-center font-weight-medium">
          {{ $t('L_congratulations') }}
        </h2>
      </h4>
      <div
        class="text font-weight-medium"
        :aria-label="$t('L_you_have_achieved_your_goal_of_debt_freedom')"
      >
        {{ $t('L_you_have_achieved_your_goal_of_debt_freedom') }}
      </div>
    </v-flex>
    <v-flex xs12 md5 class="create-your-plan">
      <v-btn
        block
        outline
        color="#063562"
        @click="contactRep=true"
        :aria-label="$t('L_contact_your_representative')"
      >
        {{ $t('L_contact_your_representative') }}
      </v-btn>
    </v-flex>
    <ContactRep
      v-model="contactRep"
      :agents="agents"
      @close="contactRep = false"
      @send-message="sendRepEmail($event)"
      v-if="hasReps"
      :sender="sender"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ContactRep from '../../../../../components/ContactRep/ContactRep.vue';
import sendRepEmail from '../../../../../components/ContactRep/sendEmail';

export default {
  name: 'DebtSolutionsCongratsBottom',
  components: {
    ContactRep,
    sendRepEmail,
  },
  data: () => ({
    contactRep: false,
  }),
  methods: {
    ...mapActions('customer', ['contactRepresentative']),
    sendRepEmail(emailPayload) {
      sendRepEmail.bind(this)(this.contactRepresentative, emailPayload);
    },
  },
  computed: {
    ...mapGetters('policy', [
      'policiesSortedByDate',
    ]),
    ...mapGetters('customer', [
      'investmentsSortedByDate',
      'customerProfile',
    ]),
    ...mapGetters(['getAgent', 'agents']),
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    hasReps() {
      return this.agents?.length > 0;
    },
    agent() {
      return (this.agents?.length > 0) ? this.agents[0] : {};
    },
  },
};
</script>

<style lang="stylus" scoped>
  .create-your-plan
    padding: 0 15px;
  .font-weight-medium
    font-weight: 500;
</style>
