<i18n src="./locales.json"></i18n>
<template>
  <MessageDialog
    :show-dialog="true"
    :has-button-two="true"
    :is-persistent="true"
    type="warning"
    :title="$t('L_cancel_changes')"
    :message="$t('L_by_cancelling_no_change')"
    :button-two-label="$t('L_yes_cancel')"
    :button-one-label="$t('L_no_continue')"
    @buttonTwoAction="cancelButtonClicked"
    @buttonOneAction="confirmationButtonClicked"
  />
  <!-- confirmation button text is 'No, Continue' -->
  <!-- cancel button text is 'Yes, Cancel' -->
</template>

<script>
import MessageDialog from '@/components/MessageDialog.vue';

export default {
  name: 'NavigationConfirmationDialog',
  components: {
    MessageDialog,
  },
  data: () => ({
    dialog: true,
  }),
  methods: {
    confirmationButtonClicked() {
      // confirmation button text is 'No, Continue'
      // meaning: continue current process
      // meaning: cancel navigating away from current page
      this.$emit('cancel-navigation');
    },
    cancelButtonClicked() {
      // cancel button text is 'Yes, Cancel'
      // meaning cancel out of current process
      // meaning: confirm navigating away from current page
      this.$emit('confirm-navigation');
    },
  },
};
</script>
<style>
</style>

