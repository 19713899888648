export const getDefaultState = () => ({
  includeSpouse: false,
  dimeLoading: false,
  dimeCalculation: {
    you: {
      totalIncomeNeeded: 0,
      totalCashNeeded: 0,
      totalProtectionNeeded: 0,
    },
    spouse: {
      totalIncomeNeeded: 0,
      totalCashNeeded: 0,
      totalProtectionNeeded: 0,
    },
  },
});

const state = getDefaultState();

export default state;

