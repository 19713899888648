<i18n src='./locales.json'></i18n>
<template>
  <footer
    class="pb-3 pt-3"
    :class="isMobile?'cssfooterMobileApp': 'cssfooter'"
  >
    <v-toolbar
      class="elevation-0"
      aria-label="footer"
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          class="mb-3"
        >
          <a
            v-for="(link, index) in footerLinks"
            :key="index"
            :href="link.linkUrl ? link.linkUrl : 'javascript:void(0)'"
            :target="link.linkUrl ? '_blank' : ''"
            :class="index === 0 ? 'font-weight-bold no-underline':''"
            :aria-label="link.linkUrl ? $t('L_opens_new_window') + $t(link.linkName) : $t(link.linkName)"
            @click="callMethod(index)"
          >
            {{ $t(link.linkName) }}
          </a>
        </v-flex>
        <v-flex
          xs12
          class="center copyright"
        >
          &#x24B8;{{ new Date().getFullYear() }} {{ $t('primericaweburl') }}
        </v-flex>
        <TermsOfUse
          v-if="showTermsOfUseModal"
          :terms-of-use-modal="showTermsOfUseModal"
          :terms-of-use-content="termsOfUseContent"
          @hidetermsOfUseModal="showTermsOfUseModal=false"
        />
      </v-layout>
    </v-toolbar>
  </footer>
</template>
<script>
import axios from 'axios';
import TermsOfUse from '../TermsOfUse/TermsOfUse.vue';

export const footerLinks = {
  enUS: [
    { linkName: 'L_primerica', linkUrl: 'https://www.primerica.com/public/' },
    { linkName: 'L_privacy', linkUrl: 'http://www.primerica.com/public/privacy/privacy_notices.html' },
    { linkName: 'L_importantdisclosures', linkUrl: 'http://www.primerica.com/public/primerica_disclosures.html' },
    { linkName: 'L_termsofuse', linkUrl: 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt19dba62bbf957b2c/MISC-011_Sep2021.html' },
    { linkName: 'L_contact', linkUrl: 'http://www.primerica.com/public/contact.html' },
    { linkName: 'L_adastatement', linkUrl: 'http://www.primerica.com/public/ADA_Policy_Statement.html' },
    { linkName: 'L_domestic_violence_confidentiality', linkUrl: '' },
    { linkName: 'L_pfsidisclosures', linkUrl: 'https://www.primerica.com/public/pfsi-disclosures.html' },
    { linkName: 'L_formcrs', linkUrl: 'https://portfolio.primerica.com/public/pss/Form-CRS.pdf' },
  ],
  enCA: [
    { linkName: 'L_primerica', linkUrl: 'https://www.primericacanada.ca/' },
    { linkName: 'L_privacy', linkUrl: 'http://www.primericacanada.ca/public/canada/canada_privacy.html' },
    { linkName: 'L_importantdisclosures', linkUrl: 'http://www.primerica.com/public/primerica_disclosures.html' },
    { linkName: 'L_termsofuse', linkUrl: 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt19dba62bbf957b2c/MISC-011_Sep2021.html' },
    { linkName: 'L_contact', linkUrl: 'http://www.primericacanada.ca/public/canada/contact.html' },
    { linkName: 'L_accessibility', linkUrl: 'http://www.primericacanada.ca/public/canada/primerica_disclosures.html#accessibility' },
    { linkName: 'L_fairtreatmentofcustomers', linkUrl: 'http://www.primericacanada.ca/public/canada/fair-treatment-of-customers.html' },
  ],
  frCA: [
    { linkName: 'L_primerica', linkUrl: 'https://www.primericacanada.ca/public/canada/french/index.html' },
    { linkName: 'L_privacy', linkUrl: 'http://www.primericacanada.ca/public/canada/french/canada_privacy.html' },
    { linkName: 'L_importantdisclosures', linkUrl: 'http://www.primericacanada.ca/public/canada/french/primerica_disclosures.html' },
    { linkName: 'L_termsofuse', linkUrl: 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/bltd2ddced11e64590f/MISC-012_September2021.html' },
    { linkName: 'L_contact', linkUrl: 'http://www.primericacanada.ca/public/canada/french/contact.html' },
    { linkName: 'L_accessibility', linkUrl: 'http://www.primericacanada.ca/public/canada/french/primerica_disclosures.html#accessibility' },
    { linkName: 'L_fairtreatmentofcustomers', linkUrl: 'http://www.primericacanada.ca/public/canada/french/fair-treatment-of-customers-fr.html' },
  ],
};

export const termsOfUseContentUrl = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt19dba62bbf957b2c/MISC-011_Sep2021.html';
export const termsOfUseContentUrlFR = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/bltd2ddced11e64590f/MISC-012_September2021.html';
export default {
  name: 'Footer',
  components: {
    TermsOfUse,
  },
  data: () => ({
    showTermsOfUseModal: false,
    termsOfUseContent: '',
  }),
  props: {
    showDomesticViolenceCard: {
      type: Boolean,
      default: false,
    },
    currentLocale: {
      type: String,
      required: true,
      default: () => 'en_US',
    },
    isMobile: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    footerLinks() {
      if (this.currentLocale === 'en_CA') {
        return footerLinks.enCA;
      } else if (this.currentLocale === 'fr_CA') {
        return footerLinks.frCA;
      }
      return footerLinks.enUS;
    },
    termsOfUseContentUrl() {
      if (this.currentLocale.indexOf('fr') !== -1) {
        return termsOfUseContentUrlFR;
      }
      return termsOfUseContentUrl;
    },
  },
  methods: {
    async openTermsOfUseModal() {
      this.showTermsOfUseModal = true;
      const client = axios.create();
      // eslint-disable-next-line max-len
      // TODO: wrap this in store or a service
      const res = await client.get(this.termsOfUseContentUrl);
      this.termsOfUseContent = res.data;
    },
    goToDomesticViolenceCard() {
      this.$router.push({ name: 'DomesticViolence' });
    },
    async callMethod(index) {
      if (index === 6 && this.currentLocale === 'en_US') {
        this.goToDomesticViolenceCard();
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~vuetify/src/stylus/main'
.cssfooter
  text-align center;
  font-size 14px;
  background-color #ffffff;
  height 88px;
  position absolute;
  bottom 0;
  left 0;
  right 0;
  .theme--light.v-toolbar
    background-color #ffffff;
  @media $display-breakpoints.xs-only
    text-align center;
    font-size 10px;
  a
    color #666666 !important;
    text-decoration underline;
    padding-right: 8px;
    font-weight: 400;
.cssfooterMobileApp
  text-align center;
  font-size 14px;
  background-color #ffffff;
  height 180px;
  position absolute;
  bottom -93px;
  left 0;
  right 0;
  .theme--light.v-toolbar
    background-color #ffffff;
  @media $display-breakpoints.xs-only
    text-align center;
    font-size 10px;
  a
    color #666666 !important;
    text-decoration underline;
    padding-right: 8px;
    font-weight: 400;
.copyright
  font-size 12px;
  @media $display-breakpoints.xs-only
    font-size 11px;
  color #666666 !important;
</style>

