<template>
  <v-expansion-panel-content>
    <h2
      slot="header"
      class="heading_two"
    >
      <img
        alt="question icon"
        class="heading-icon"
        src="../../assets/question.svg"
      >Other Existing Coverage
    </h2>
    <v-icon slot="actions">
      {{ $vuetify.icons.expand }}
    </v-icon>
    <v-flex
      xs12
      pl-3
      pr-3
      mt-4
    >
      <h3 class="heading_three pl-3 pr-3 font-weight-medium">
        Do you or your spouse  have any additional life insurance from other sources?
      </h3>
    </v-flex>
    <v-flex
      xs12
      pl-4
      pr-4
    >
      <v-layout
        mt-3
        justify-space-around
      >
        <v-flex
          xs12
          md5
        >
          <v-btn
            block
            color="secondary"
          >
            Yes
          </v-btn>
        </v-flex>
        <v-flex
          xs12
          md5
        >
          <v-btn
            block
            color="primary"
          >
            No
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-expansion-panel-content>
</template>

<script>
export default {
  name: 'OtherCoverage',
};
</script>

<style scoped>

</style>
