<template>
  <div>
    <v-text-field
      v-model="formInput.$model"
      id="social-insurance-number"
      class="sin-number"
      :mask="!masked && '###-###-###' || '#########'"
      pattern="[0-9]*"
      inputmode="numeric"
      :label="label || $t('L_social_insurance_number') ||'Social Insurance Number'"
      :autocomplete="disableAutocomplete ? 'off' : null"
      :hint="hint"
      :color="color"
      :error-messages="errorMessages"
      :maxlength="9"
      @input="mask"
      @focus="onFocus($event); toggleTooltip({field:'socialInsuranceNumber', show:true})"
      @blur="onBlur(); toggleTooltip({field:'socialInsuranceNumber', show:false})"
      :aria-describedby="describers"
      :type="masked ? 'password': 'text'"
    >
      <span slot="append">
        <v-icon
          class="toggle-masking"
          @mouseenter="masked = false"
          @mouseleave="masked = true"
          @mousedown="masked = false"
          @mouseup="masked = true"
        >
          {{ masked ? 'visibility' : 'visibility_off' }}
        </v-icon>
        <FieldIcon
          slot="append"
          :form-field="formInput"
        />
      </span>
    </v-text-field>
  </div>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export function getForDisplay() {
  const value = this[this.modelName];
  if (this.masked || value.length < 3) {
    return value;
  }
  if (value.length < 7) {
    return value.replace(/([\d•]{3})/, '$1-');
  }
  return value.replace(/([\d•]{3})([\d•]{3})([\d•]{0,3})/, '$1-$2-$3');
}
export default {
  name: 'SINInput',
  components: { FieldIcon },
  extends: BaseTextField,
  data: () => ({
    modelName: 'socialInsuranceNumber',
    socialInsuranceNumber: '',
  }),
  computed: {
    getForDisplay,
  },
};
</script>
