<i18n src='./locales.json'></i18n>

<template>
  <v-content :class="leftSideBar?'myrep moveUp':''" role="presentation" style="max-width:1024px;margin:auto;margin-top:5px;">
    <!-- <temp>---Modify this section for Rep Page-----------------------------------------</temp> -->
    <v-layout row wrap class="main-body" justify-center v-if="repPage">
      <v-flex xs12>
        <v-layout pt-2 pb-4 mt-4 ml-2>
          <img src="./assets/group.svg" height="30px" role="presentation" alt="">
          <v-flex shrink>
            <span class="pl-3 font-weight-regular font-size--24 white--text" role="heading" aria-level="1">{{ $t(reps
              && reps.length > 1 ? 'L_my_rep_title_plural' : 'L_my_rep_title') }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex>
        <template v-if="reps && reps.length > 0">
          <v-card v-for="rep in reps" :key="rep.agentId" class="mb-4">
            <v-card-text>
              <v-flex pa-3>
                <v-layout row align-start>
                  <v-flex>
                    <v-layout row wrap>
                      <!-- <temp>---Agent Photo-----------------------------------------</temp> -->
                      <v-flex xs12 sm4 md2 :mb-3="$vuetify.breakpoint.xsOnly">
                        <v-layout row wrap>
                          <v-flex xs12 pb-2>
                            <v-layout justify-center>
                              <img class="rep-image" style="width: 80px" :src="rep.displayPic" v-img-fallback="repFallbackImage" alt="rep photo">
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <!-- <temp>---Agent Info-----------------------------------------</temp> -->
                      <v-flex xs12 sm8 md10>
                        <v-layout row wrap justify-center>
                          <v-flex xs7 sm6 md4 lg4 mb-4 :mb-3="$vuetify.breakpoint.xsOnly">
                            <v-layout row wrap justify-start>
                              <v-flex xs12>
                                <h3 role="heading" aria-level="2" class="heading_three font-weight-medium mb-2" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly, }">
                                  {{ rep.displayName }}
                                </h3>
                                <h5 role="presentation"
                                    v-if="rep.isRvp && rep.addresses.filter(address => address.type === 'home' &&
                                      !(address.state ==='NF' || address.state === 'PE'|| address.state === 'NS'|| address.state === 'NB'||
                                        address.state ==='PQ' || address.state === 'ON'|| address.state === 'MB'|| address.state === 'SK'||
                                        address.state ==='AB' || address.state === 'BC'|| address.state === 'YT'|| address.state === 'NT'||
                                        address.state === 'NU'
                                      )).length > 0"
                                    class="heading_five font-weight-medium mb-1 pb-1" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly, }"
                                >
                                  {{ $t('L_' + rep.title) }}
                                </h5>
                                <h5 role="presentation" class="heading_five font-weight-medium mb-1 pb-1" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly, }">
                                  {{ $t(rep.products.length > 1 ? 'L_rep_servicing_product_plural' : 'L_rep_servicing_product') }}
                                </h5>
                                <div v-for="(item, index) in rep.products" :key="index" class="font-size--14 mb-1" :class="{ 'text-xs-center': $vuetify.breakpoint.xsOnly,}">
                                  {{ item }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <!-- <temp>---CONTACT------------------------------------------</temp> -->
                          <v-flex xs7 sm6 md4 lg4 mb-4 :mb-3="$vuetify.breakpoint.xsOnly">
                            <v-layout zalign-left class="mb-2 pb-1 justify-start" :justify-center="$vuetify.breakpoint.xsOnly">
                              <span class="font-size--14 font-weight-medium">{{ $t('L_contact') }}</span>
                            </v-layout>
                            <v-layout row wrap justify-start>
                              <v-flex v-if="rep.office && rep.office.phone" xs12 pb-2>
                                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">phone</v-icon></span>
                                <a :href="'tel:+1' + rep.office.phone" class="ml-1">{{ rep.office.phone | phone }}</a>
                              </v-flex>
                              <v-flex v-if="rep.useCellPhone && rep.cell" xs12 pb-2>
                                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">phone_android</v-icon></span>
                                <a :href="'tel:+1' + rep.cell" class="ml-1">{{ rep.cell | phone }}</a>
                              </v-flex>
                              <v-flex v-if="rep.office && rep.office.fax" xs12 pb-2>
                                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">print</v-icon></span>
                                <a :href="'tel:+1' + rep.office.fax" class="ml-1">{{ rep.office.fax | phone }}</a>
                              </v-flex>

                              <v-flex v-if="rep.email" xs12 pb-2>
                                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">email</v-icon></span>
                                <span @click.prevent="emailRep(rep.agentId)">
                                  <a :href="'tel:+1' + rep.office.fax" class="ml-1">{{ $t('L_rep_email_me') }}</a>
                                </span>
                              </v-flex>

                              <v-flex v-if="rep.appointmentUrl" xs12 pb-2>
                                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24">event</v-icon></span>
                                <span @click.prevent="openCalendar(rep.appointmentUrl)">
                                  <a href="" class="ml-1">{{ $t('L_make_an_appointment') }}</a>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <!-- <temp>-------------------------</temp> -->

                          <v-flex xs7 sm12 md4 lg4>
                            <v-layout row wrap justify-start>
                              <v-flex xs12>
                                <Address :rep="rep" />
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-divider class="mt-4 mb-3 hidden-sm-and-down" />
                      </v-flex>
                    </v-layout>

                    <v-divider class="mt-4 mb-3 hidden-md-and-up" />
                    <v-layout align-start justify-start>
                      <v-flex v-if="$vuetify.breakpoint.mdAndUp" xs2 />
                      <v-flex xs12 md4>
                        <div class="font-size--14 font-weight-medium mb-2">
                          {{ $t('L_rep_help_with') }}:
                        </div>
                        <v-layout v-for="(product, i) in rep.licensedProducts" :key="i" align-start>
                          <div v-if="filterProducts(product)" class="pb-2">
                            <v-icon color="primary">
                              check_circle
                            </v-icon>
                            <span class="ml-2 font-size--16">{{ translatedProductName(product) }}</span>
                          </div>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-card-text>
          </v-card>
        </template>
      </v-flex>
    </v-layout>
    <!-- <temp>---Modify this section for Left Bar on Wide Desktop | Guidance Calc for Life or Investment Rep Section------------</temp> -->
    <v-layout row wrap v-else-if="reps && reps.length === 1" :class="{'backgroundBlueGradient': leftSideBar}">
      <v-flex xs12 v-for="rep in reps" :key="rep.agentId">
        <v-layout row wrap justify-center>
          <v-flex xs12 text-xs-center :mb-3="$vuetify.breakpoint.xsOnly">
            <div class="mb-4 mt-4">
              <img class="rep-image" style="width: 80px" :src="rep.displayPic" v-img-fallback="repFallbackImage" alt="rep photo">
            </div>
            <h3 role="heading" aria-level="2" class="heading_three font-weight-bold font-size--20 mb-2"
                :class="leftSideBar?'whitetext':''"
            >
              {{ rep.displayName }}
            </h3>
            <h5 role="presentation"
                v-if="rep.isRvp && rep.addresses.filter(address => address.type === 'home' &&
                  !(address.state ==='NF' || address.state === 'PE'|| address.state === 'NS'|| address.state === 'NB'||
                    address.state ==='PQ' || address.state === 'ON'|| address.state === 'MB'|| address.state === 'SK'||
                    address.state ==='AB' || address.state === 'BC'|| address.state === 'YT'|| address.state === 'NT'||
                    address.state === 'NU'
                  )).length > 0"
                class="heading_five font-weight-medium mb-4 font-size--14 mb-1 pb-1"
                :class="{'whitetext': leftSideBar }"
            >
              {{ $t('L_' + rep.title) }}
            </h5>
          </v-flex>
          <!-- <temp>---------------------------------------------</temp> -->

          <v-flex xs12 mb-4 :mb-3="$vuetify.breakpoint.xsOnly">
            <v-layout row wrap :class="leftSideBar?'justify-left ml-2':'justify-center'">
              <v-flex xs12 class="font-size--14 font-weight-bold text-xs-center" :class="leftSideBar?'whitetext':''">
                {{ $t('L_contact_me') }}
              </v-flex>
              <v-flex v-if="rep.office && rep.office.phone" xs7 lg12 pt-1 pb-2 text-xs-left :class="$vuetify.breakpoint.lgAndUp?'ml-3':''">
                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24" :class="leftSideBar?'whiteicon':''">phone</v-icon></span>
                <a :href="'tel:+1' + rep.office.phone" class="ml-1" :class="leftSideBar?'whitetext':''">{{ rep.office.phone | phone }}</a>
              </v-flex>
              <v-flex v-if="rep.useCellPhone && rep.cell" xs7 lg12 pt-1 pb-2 text-xs-left :class="$vuetify.breakpoint.lgAndUp?'ml-3':''">
                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24" :class="leftSideBar?'whiteicon':''">phone_android</v-icon></span>
                <a :href="'tel:+1' + rep.cell" class="ml-1" :class="leftSideBar?'whitetext':''">{{ rep.cell | phone }}</a>
              </v-flex>
              <v-flex v-if="rep.office && rep.office.fax" xs7 lg12 pt-1 pb-2 text-xs-left :class="$vuetify.breakpoint.lgAndUp?'ml-3':''">
                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24" :class="leftSideBar?'whiteicon':''">print</v-icon></span>
                <a :href="'tel:+1' + rep.office.fax" class="ml-1" :class="leftSideBar?'whitetext':''">{{ rep.office.fax | phone }}</a>
              </v-flex>

              <v-flex v-if="rep.email" xs7 lg12 pt-1 pb-2 @click.prevent="emailRep(rep.agentId)" text-xs-left :class="$vuetify.breakpoint.lgAndUp?'ml-3':''">
                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24" :class="leftSideBar?'whiteicon':''">email</v-icon></span>
                <a :href="'tel:+1' + rep.office.fax" class="ml-1" :class="leftSideBar?'whitetext':''">{{ $t('L_rep_email_me') }}</a>
              </v-flex>

              <v-flex v-if="rep.appointmentUrl" xs7 lg12 pt-1 pb-2 @click.prevent="openCalendar(rep.appointmentUrl)" text-xs-left :class="$vuetify.breakpoint.lgAndUp?'ml-3':''">
                <span class="font-size--14 font-weight-medium mr-1"><v-icon color="#02579E" class="font-size--24" :class="leftSideBar?'whiteicon':''">event</v-icon></span>
                <a href="" class="ml-1" :class="leftSideBar?'whitetext':''">{{ $t('L_make_an_appointment') }}</a>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <ContactRep v-if="contactAgent" v-model="contactMe" :agents="[contactAgent]" @close="contactMe = false"
                @send-message="send($event)" close-selector="contact_your_rep_myrep_close"
                modal-class="contact_your_rep_myrep_modal" :sender="sender"
    />
    <MessageDialog
      :show-dialog="dialog"
      :has-button-two="true"
      :is-persistent="true"
      type="info"
      :title="$t('L_you_are_being_redirected_to_an_appointment_scheduling_website')"
      :message="$t('L_this_link_will_take_you_to_a_third_party_site_that_has_its_own')"
      :button-two-label="$t('L_go_back')"
      :button-one-label="$t('L_continue')"
      @buttonTwoAction="dialog=false"
      @buttonOneAction="closeAndGoToCalendar"
    />
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MessageDialog from '@/components/MessageDialog.vue';
import Config from '@/shared/util/config';
import { phoneNumber } from '@/shared/util/formatters';
import { openInNewWindow } from '@/mixins/openNewWindow';
import ContactRep from '../../../components/ContactRep/ContactRep.vue';
import sendRepEmail from '../../../components/ContactRep/sendEmail';
import Address from './components/Address.vue';
import bff from '../../../shared/api/bff';

const repFallbackImage = require('./assets/rep-thumb.svg');

export default {
  name: 'MyRep',
  components: {
    Address,
    ContactRep,
    MessageDialog,
  },
  mixins: [openInNewWindow],
  props: {
    repPage: {
      type: Boolean,
      required: false,
      default: true,
    },
    leftSideBar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    contactMe: false,
    contactAgent: {},
    calendarLink: '',
  }),
  computed: {
    ...mapGetters('policy', {
      policiesSortedByDate: 'policiesSortedByDate',
    }),
    ...mapGetters('customer', [
      'investmentsSortedByDate',
      'customerProfile',
    ]),
    ...mapGetters(['agents', 'getAgent']),
    envUrl() {
      const env = Config.get('env');
      if (env === 'dev' || env === 'test') {
        return env;
      }
      return 'w';
    },
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    reps() {
      const accounts = [];
      this.policiesSortedByDate.forEach((pol) => {
        if (pol.contractId?.length > 0) {
          accounts.push({
            product: `${this.$t('L_term_life_policy')} ${pol.contractId}`,
            agentId: pol.agentId,
          });
        }
      });
      this.investmentsSortedByDate.forEach((acct) => {
        if (acct.accountNumber?.length > 0) {
          accounts.push({
            product: `${acct.productType} ${acct.accountNumber}`,
            agentId: acct.agentId,
          });
        }
      });
      let reps = [];
      if (accounts?.length > 0) {
        accounts.forEach((acct) => {
          const found = reps.find(a => a.agentId === acct.agentId);
          if (!found) {
            const agent = this.getAgent(acct.agentId);
            if (agent && agent.agentId) {
              agent.products = [acct.product];
              agent.displayName = agent.name;
              reps.push(agent);
            }
          } else {
            reps = reps.map((rep) => {
              if (rep.agentId === acct.agentId) {
                rep.products.push(acct.product);
              }
              return rep;
            });
          }
        });
      }
      return reps;
    },
    repFallbackImage() {
      return repFallbackImage;
    },
  },
  methods: {
    ...mapActions('policy', {
      getPolicyOverview: 'getPolicyOverview',
    }),
    rvp(id) {
      return this.getAgent(id);
    },
    emailRep(agentId) {
      const agent = this.reps.find(a => a.agentId === agentId);
      this.contactAgent = agent;
      this.contactMe = true;
    },
    openCalendar(calendarLink) {
      this.calendarLink = calendarLink;
      this.dialog = true;
    },
    async closeAndGoToCalendar() {
      this.dialog = false;
      await this.openInNewWindow({ url: this.calendarLink, target: '_blank' });
    },
    send(payload) {
      sendRepEmail.bind(this)(bff.sendRepEmail, payload);
    },
    filterProducts(product) {
      return /(Term Life Insurance|Securities|Annuities)/.test(product);
    },
    translatedProductName(product) {
      let resDisplayLabel;
      switch (product) {
        case 'Term Life Insurance':
          resDisplayLabel = this.$t('L_term_life_insurance');
          break;
        case 'Securities':
          resDisplayLabel = this.$t('L_securities');
          break;
        case 'Fixed Annuities':
          resDisplayLabel = this.$t('L_fixed_annuities');
          break;
        case 'Indexed Annuities':
          resDisplayLabel = this.$t('L_indexed_annuities');
          break;
        case 'Variable Annuities':
          resDisplayLabel = this.$t('L_variable_annuities');
          break;
        default:
          resDisplayLabel = product;
      }
      return resDisplayLabel;
    },
  },
  filters: {
    phone(number) {
      return phoneNumber(number);
    },
    camelCaseName: name => ((name && name.length > 0) ? name.split(' ').map(word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''),
  },
};
</script>

<style scoped lang="stylus">
.myrep
  padding: 8px 0 0 !important;
  min-height: 1000px;
.emailButtonCss
  zwidth: 85px;
  text-overflow: initial;
  white-space: normal;
  font-size: 13px;
.emailBtnWidth
  width: 115px;
.setHeightAuto
  height:auto !important;
.contactElement
  color: #02579e !important;
  font-size: 16px;
  text-align: center;
.navyColorIcon
  color: #02579e !important;
.whitetext
  color: #FFFFFF !important;
.whiteicon
  color: #FFFFFF !important;
.backgroundBlueGradient
  background-image: linear-gradient(to top, #012f60, #013b71);
.moveUp
  margin-top:-3px;
.main-body
  margin-top:3px;
  max-width:1137px;
  margin: auto;
.rep-image
  height: 60px !important;
  width: 80px !important;
.parent
  text-align: center;
.listItems
  display: inline-block;
  text-align: left;
</style>
