<i18n src='./locales.json'></i18n>
<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-layout row wrap pt-2 pb-0 class="backgroundWhite">
        <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'currentDebtSummaryMob' : 'currentDebtSummary'">
          {{ $t('L_current_debt_summary') }}
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="rightSideGrey">
      <v-layout row wrap pt-2 pb-2 v-if="$vuetify.breakpoint.xs">
        <v-flex text-xs-center font-size--14 v-if="selectedexpand !== index">
          {{ $t('L_view_more_details') }}
          <v-icon @click="selectedexpand = index" color="#333333">
            expand_more
          </v-icon>
        </v-flex>
        <v-flex text-xs-center font-size--14 v-else>
          {{ $t('L_hide_details') }}
          <v-icon @click="selectedexpand = -1" color="#333333">
            expand_less
          </v-icon>
        </v-flex>
      </v-layout>
      <!-- If debt data exists -->
      <v-layout text-xs-center pl-2 pr-2 row wrap v-if="unpaidDebts.length !== 0 && (!$vuetify.breakpoint.xs || this.selectedexpand !== -1)" class="grayBorderBottom">
        <v-layout row wrap pt-0 pb-4>
          <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'labelForRoundedBubbleMob': 'labelForRoundedBubble'">
            {{ $t('L_your_total_current_debt') }}
          </v-flex>
          <v-flex xs12 text-xs-center :class="$vuetify.breakpoint.xs ? 'roundedBubbleMob': 'roundedBubble'">
            {{ getBalance }}
          </v-flex>
          <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'labelForRoundedBubbleMob': 'labelForRoundedBubble'">
            {{ $t('L_you_will_be_in_debt_for') }}
          </v-flex>
          <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'roundedBubbleMob': 'roundedBubble'">
            {{ getWillBeInDebtFor }}
          </v-flex>
          <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'labelForRoundedBubbleMob': 'labelForRoundedBubble'">
            {{ $t('L_your_total_interest_paid') }}
          </v-flex>
          <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'roundedBubbleMob': 'roundedBubble'">
            {{ getTotalInterestPaid }}
          </v-flex>
          <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'labelForRoundedBubbleMob': 'labelForRoundedBubble'">
            {{ $t('L_your_toal_monthly_payment') }}
          </v-flex>
          <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'roundedBubbleMob': 'roundedBubble'">
            {{ getMonthlyPayment }}
          </v-flex>
        </v-layout>
      </v-layout>

      <!-- Enter Your Info -->
      <v-layout class="rightSideGrey" pt-2 text-xs-center row wrap v-if="unpaidDebts.length <= 1 && (!$vuetify.breakpoint.xs || this.selectedexpand !== -1)">
        <v-flex xs12 md12 pt-4 :class="$vuetify.breakpoint.xs ? 'font-size--16': 'font-size--18'">
          {{ $t('L_enter_your_information_to_see_how') }}
        </v-flex>
      </v-layout>

      <!-- View My Plan Btn -->
      <v-layout row wrap pt-4 text-xs-center v-if="(!$vuetify.breakpoint.xs || this.selectedexpand !== -1)">
        <v-flex pl-2 pr-2 xs12 :class="$vuetify.breakpoint.xs ? 'font-size--16': 'font-size--18'"
                v-if="!twoOrMoreDebtsAndMinOneUnpaidDebt"
        >
          {{ $t('L_you_need_a_minimum') }}
        </v-flex>
        <v-flex xs12 v-else>
          <v-btn name="viewMyPlanButton" @click="ViewMyPlan()" role="link" :class="unpaidDebts.length === 1?'disabledViewMyPlanBtn':'enabledViewMyPlanBtn'"
                 :disabled="unpaidDebts.length === 0" class="widthViewMyPlanBtn"
          >
            {{ $t('L_view_my_plan') }}
          </v-btn>
        </v-flex>
      </v-layout>

      <!-- Time to Pay Off -->
      <v-layout row wrap text-xs-center v-if="unpaidDebts.length > 1">
        <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'get_out_of_debtMob' : 'get_out_of_debt'">
          {{ $t('L_get_out_of_debt') }}
        </v-flex>
        <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'biggerBlueMob' : 'biggerBlue'">
          {{ getTimeForFasterPaymentDate }}{{ $t('L_sooner') }}
        </v-flex>
        <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'font-size--18' : 'font-size--18'" v-if="(!$vuetify.breakpoint.xs || this.selectedexpand !== -1)">
          {{ $t('L_making_the_same') }}
        </v-flex>
      </v-layout>

      <!-- View Important Assumption -->
      <v-layout row wrap text-xs-center class="font-weight-bold cursorPointer" @click="openImportantDisc">
        <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'view_important_assumptionsMob' : 'view_important_assumptions'">
          {{ $t('L_view_important_assumptions') }}
        </v-flex>
      </v-layout>

      <!-- If you have questions Always Show-->
      <v-layout row wrap class="font-weight-bold" text-xs-center zv-if="hasReps">
        <v-flex xs-12 md12 sm12 pt-2 class="font-size--18 font-weight-bold" pb-4 v-if="(!$vuetify.breakpoint.xs || this.selectedexpand !== -1)">
          {{ $t('L_if_you_have_questions_about_debt') }}
        </v-flex>
        <!-- Contact your rep button Always Show-->
        <v-flex text-xs-center xs12 pb-4>
          <v-btn class=" borderColorMarine contactYourRepButtonText" @click="callContactYourRep">
            {{ $t('L_contact_your_representative') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <ContactRep
      v-if="hasReps"
      v-model="contactYourRep"
      :agents="agents"
      @close="contactYourRep = false"
      @send-message="sendRepEmail($event)"
      close-selector="contact_your_rep_close"
      modal-class="contact_your_rep_modal"
      :sender="sender"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Config from '@/shared/util/config';
import { openInNewWindow } from '@/mixins/openNewWindow';
import ContactRep from '../../../../../components/ContactRep/ContactRep.vue';
import sendRepEmail from '../../../../../components/ContactRep/sendEmail';

export default {
  name: 'DebtSummary',
  data: () => ({
    valCurrentDebt: 0,
    valInDebtFor: 0,
    valTotIntPaid: 0,
    valTotalMonthlyPay: 0,
    debtDataArray: [],
    document: {
      title: '',
      content: '',
      type: '',
      loading: false,
    },
    contactYourRep: false,
    policy: {},
    selectedexpand: -1,
    index: 0,
  }),
  mixins: [openInNewWindow],
  components: {
    ContactRep,
  },
  methods: {
    ...mapActions('customer', ['contactRepresentative']),
    sendRepEmail(emailPayload) {
      sendRepEmail.bind(this)(this.contactRepresentative, emailPayload);
    },
    contact_your_rep_close() {
      this.contactYourRep = false;
    },
    callContactYourRep() {
      this.contactYourRep = true;
    },
    ViewMyPlan() {
      if (this.$route.path !== '/debtsolutions/myplan') {
        // this.$router.replace({ name: 'MyPlan' });
        // this.$router.push({ name: 'MyPlan' });
        this.$router.push({ name: 'TermLifeBenefits' }).then(() => {
          this.$router.push({ name: 'MyPlan' });
        });
      }
    },
    async openImportantDisc() {
      await this.openInNewWindow({ url: `${Config.get('content_stack')}/blt676ecb292039a611/MISC-006.pdf`, target: '_blank' });
    },
  },
  computed: {
    ...mapGetters('policy', [
      'policiesSortedByDate',
    ]),
    ...mapGetters('customer', [
      'investmentsSortedByDate',
      'customerProfile',
    ]),
    ...mapGetters(['getAgent', 'agents']),
    ...mapGetters({
      debts: 'debtsolutions/debts',
      unpaidDebts: 'debtsolutions/unpaidDebts',
      paidOffDebts: 'debtsolutions/paidOffDebts',
      planWOStacking: 'debtsolutions/planWOStacking',
      planWStacking: 'debtsolutions/planWStacking',
      twoOrMoreDebtsAndMinOneUnpaidDebt: 'debtsolutions/twoOrMoreDebtsAndMinOneUnpaidDebt',
    }),
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    hasReps() {
      return this.agents?.length > 0;
    },
    getBalance() {
      const balance = this.planWOStacking?.planTotals?.balance;
      if (Number.isNaN(balance)) {
        return '';
      }
      return this.$n(balance, 'currencyNoCents', 'en-US');
    },
    getWillBeInDebtFor() {
      if (this.planWOStacking.planTotals === undefined) {
        return '';
      }
      return `${this.planWOStacking?.planTotals?.debtPayoffYears} ${this.$t('L_yrs_dot_slash')} ${this.planWOStacking?.planTotals?.debtPayoffMonths} ${this.$t('L_mos_dot_slash')}`;
    },
    getTotalInterestPaid() {
      const prjIntPaid = this.planWOStacking?.planTotals?.projectedInterestPaid;
      if (Number.isNaN(prjIntPaid)) {
        return '';
      }
      return this.$n(prjIntPaid, 'currencyNoCents', 'en-US');
    },
    getMonthlyPayment() {
      const minPayment = this.planWOStacking?.planTotals?.minPayment;
      if (Number.isNaN(minPayment)) {
        return '';
      }
      return this.$n(minPayment, 'currencyNoCents', 'en-US');
    },
    getTimeForFasterPaymentDate() {
      if (this.planWStacking === undefined) {
        return '';
      }
      let totalMonthsWStacking = (parseInt(this.planWStacking?.planTotals?.debtPayoffYears, 10) || 0) * 12;
      totalMonthsWStacking += (parseInt(this.planWStacking?.planTotals?.debtPayoffMonths, 10) || 0);
      let totalMonthsWOStacking = (parseInt(this.planWOStacking?.planTotals?.debtPayoffYears, 10) || 0) * 12;
      totalMonthsWOStacking += (parseInt(this.planWOStacking?.planTotals?.debtPayoffMonths, 10) || 0);

      if (totalMonthsWStacking > 0 && totalMonthsWOStacking > 0) {
        const diffYears = Math.floor((totalMonthsWOStacking - totalMonthsWStacking) / 12);
        const diffMonths = (totalMonthsWOStacking - totalMonthsWStacking) % 12;
        return `${diffYears} ${this.$t('L_years_and')} ${diffMonths} ${this.$t('L_months')}`;
      }
      return '';
    },
  },
};

</script>
<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
.rightSideGrey
  background-color: #eeeeee;
  padding: 0 5px 0 5px;
.rightSideGreyMob
  background-color: #eeeeee;
  padding: 0 5px 0 5px;
.max-btn-width
  max-width: 260px;
.biggerBlue
  font-size:24px;
  color:#063562;
  font-weight: bold;
  padding-bottom:0px;
.biggerBlueMob
  font-size:18px;
  color:#063562;
  font-weight: bold;
  padding-bottom:5px;
.roundedBubble
  border-radius: 16px;
  background-color: white;
  padding: 2px 2px 2px 2px;
  font-size: 28px;
  font-weight:600;
  height: 50px;
  line-height: 50px;
  width:252px;
  max-width:252px;
  margin: auto;
.roundedBubbleMob
  border-radius: 16px;
  background-color: white;
  padding: 0 10px 10px 10px;
  font-size: 18px;
  font-weight:600;
  height: 32px;
  line-height: 32px;
  width:180px;
  max-width:180px;
  margin: auto;
.labelForRoundedBubble
  font-weight:400;
  padding: 20px 0 2px 0;
  font-size: 18px;
.labelForRoundedBubbleMob
  font-weight:400;
  padding: 20px 0 2px 0;
  font-size: 16px;
.widthViewMyPlanBtn
  width: 220px;
.view_important_assumptions
  color: #02579e;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
.view_important_assumptionsMob
  color: #02579e;
  font-size: 16px;
  padding: 2px 3px 10px 3px;
.get_out_of_debt
  font-size: 18px;
  color: #333333;
  padding-top:16px;
.get_out_of_debtMob
  font-size: 18px;
  color: #333333;
  padding-top:10px;
.if_you_have_questions_about_debt
  font-size: 18px;
  color: #333333;
  font-weight: 600;
.Contact_Your_Representative
  background-color: #002c56;
  font-size: 18px;
.moveleftByTen
  margin-left:-10px;
.borderColorMarine
  border: 1px solid #063562;
.backgroundWhite
  background-color: white;
.noPaddingBottom
  padding-bottom: 0px !important;
.grayBorderBottom
  border-bottom: 1px solid #cccccc;
.disabledViewMyPlanBtn
  background-color: #eeeeee !important;
  color: #adadad !important;
.enabledViewMyPlanBtn
  background-color: #063562 !important;
  color: white !important;
.cursorPointer
  cursor:pointer;
.size22font
  font-size: 22px;
.currentDebtSummary
  font-size:24px;
  padding: 24px 0px 24px 12px;
.currentDebtSummaryMob
  font-size:24px;
  text-align: center;
  padding: 12px 0px 12px 12px;
.contactYourRepButtonText
  color: #002c56;
  width: 220px;
  font-size: 16px;
  @media only screen and (min-width: 800px)
    width: 235px;
    font-size:17px;
</style>
