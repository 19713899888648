
const setupCollection = () => new Promise((resolve, reject) => {
  try {
    let client;
    if (window.ca && window.ca.rm) {
      client = new window.ca.rm.Client();
    }
    if (client && typeof (client.loadFlash) === 'function') {
      client.loadFlash(() => {
        client.setProperty('format', 'json');
        client.setProperty('didname', 'RISKFORT_COOKIE');
        client.processDNA();
        resolve(client.getDNA());
      });
    } else {
      resolve(null);
    }
  } catch (e) {
    reject(e);
  }
});

export default setupCollection;
