export default class Config {
  static get(config) {
    if (!config) {
      throw new Error('Config.get requires a key to be passed as a string.');
    }
    return config.split('.').reduce(Config.objectPropertyReducer, Object.assign({}, window.config));
  }

  static objectPropertyReducer(aggregator, currentProperty) {
    return (typeof (aggregator) === 'undefined' || typeof (aggregator[currentProperty]) === 'undefined')
      ? undefined
      : aggregator[currentProperty];
  }
}
