/* eslint-disable no-param-reassign */
import { PolicyModel as Policy } from '../../../shared/data/models';
import { getDefaultState } from './state';

export const setStep = (state, step) => {
  state.step = step;
};

export const setScrollToPolicyDetails = (state, scrollToPolicyDetails) => {
  state.scrollToPolicyDetails = scrollToPolicyDetails;
};

export const setPolicies = (state, policies) => {
  state.policies = policies
    .map(policy => new Policy(policy));
};

export const setSelectedPolicyIndex = (state, selectedPolicyIndex) => {
  state.selectedPolicyIndex = selectedPolicyIndex;
};

export const setPanelValue = (state, panelValue) => {
  const paymentPanel = state.paymentPanel[state.selectedPolicyIndex];
  paymentPanel.panelValue = panelValue;
};

export const setContactDetailsPanel = (state, panelValue) => {
  state.contactDetailsPanel = panelValue;
};

export const setContactInfoEdit = (state, editContactInfo) => {
  state.editContactInfo = editContactInfo;
};

export const updateAddress1 = (state, value) => {
  if (typeof (value) !== 'string') {
    return;
  }
  const policy = state.policies[state.selectedPolicyIndex];
  policy.owner.address.address1 = value;
};

export const updateAddress2 = (state, value) => {
  if (typeof (value) !== 'string') {
    return;
  }
  const policy = state.policies[state.selectedPolicyIndex];
  policy.owner.address.address2 = value;
};

export const updateCity = (state, value) => {
  if (typeof (value) !== 'string') {
    return;
  }
  const policy = state.policies[state.selectedPolicyIndex];
  policy.owner.address.city = value;
};

export const updateState = (state, value) => {
  if (typeof (value) !== 'string') {
    return;
  }
  const policy = state.policies[state.selectedPolicyIndex];
  policy.owner.address.state = value;
};

export const updateZip = (state, value) => {
  if (typeof (value) !== 'string') {
    return;
  }
  const policy = state.policies[state.selectedPolicyIndex];
  policy.owner.address.zip = value;
};

export const updatePhone = (state, value) => {
  if (typeof (value) !== 'string') {
    return;
  }
  const policy = state.policies[state.selectedPolicyIndex];
  policy.owner.address.phone = value;
};

export const updateEmail = (state, value) => {
  if (typeof (value) !== 'string') {
    return;
  }
  const policy = state.policies[state.selectedPolicyIndex];
  policy.owner.email = value;
};

export const setSuccessResponseUpdateContactInfo = (state, value) => {
  state.successResponseUpdateContactInfo = value;
};

export const setErrorResponseUpdateContactInfo = (state, value) => {
  state.errorResponseUpdateContactInfo = value;
};


export const setAddressVerification = (state, value) => {
  state.addressVerification = value;
};

export const setAddressUpdateSuccess = (state, value) => {
  state.addressUpdateSuccess = value;
};

export const setNavHintDismissed = (state, value) => {
  state.navHintDismissed = value;
};

export const setHasTerminatedPolicies = (state, value) => {
  state.hasTerminatedPolicies = value;
};

export const setTerminatedPolicyAgent = (state, agent) => {
  state.terminatedPolicyAgent = agent;
};

export const setPaymentInfoLoading = (state, loading) => {
  state.loading.payment = loading;
};

export const setAchSubmissionLoading = (state, loading) => {
  state.loading.ach = loading;
};

export const setPolicyInfoLoading = (state, loading) => {
  state.loading.policy = loading;
};

export const setPaymentInfo = (state, paymentInfo) => {
  const policy = state.policies[state.selectedPolicyIndex];
  policy.paymentInfo = paymentInfo;
  policy.paynowInfo = {
    totalPaymentAmount: paymentInfo.TotalPaymentAmount,
    minimumPaymentAmount: paymentInfo.MinimumPaymentAmount,
    inSufficientFunds: paymentInfo.InSufficientFunds,
    hasAnnuity: paymentInfo.DisplayAnnuity,
    hasMultipleOptions: paymentInfo.HasMultipleOptions,
  };
};

export const setBase64String = (state, base64String) => {
  state.base64String = base64String;
};

export const setBankName = (state, bankName) => {
  state.bankName = bankName;
};

export const clearBank = (state) => {
  state.bankName = null;
};

export const setPaymentData = (state, paymentData) => {
  state.paymentData = paymentData;
};

/* Edit payment details */

export const setPaymentPanel = (state, policies) => {
  state.paymentPanel = [];
  policies.forEach((policy) => {
    const eachPanel = {
      contractId: policy.contractId,
      editPayments: {
        paymentMethodChangeAlert: false,
        editPaymentMethod: false,
        disablePaymentDetailsLink: false,
      },
      panelValue: -1,
    };
    state.paymentPanel.push(eachPanel);
  });
};
export const setPaymentMethodChangeAlert = (state, paymentMethodChangeAlert) => {
  const paymentPanel = state.paymentPanel[state.selectedPolicyIndex];
  paymentPanel.editPayments.paymentMethodChangeAlert = paymentMethodChangeAlert;
};
export const setEditPaymentMethod = (state, editPaymentMethod) => {
  const paymentPanel = state.paymentPanel[state.selectedPolicyIndex];
  paymentPanel.editPayments.editPaymentMethod = editPaymentMethod;
};
export const setDisablePaymentDetailsLink = (state, disablePaymentDetailsLink) => {
  const paymentPanel = state.paymentPanel[state.selectedPolicyIndex];
  paymentPanel.editPayments.disablePaymentDetailsLink = disablePaymentDetailsLink;
};
export const setshowPayNowInfoCard = (state, value) => {
  state.showPayNowInfoCard = value;
};
export const setPaymentInfoShowPaymentAmountDateBillingMethod = (state, value) => {
  state.paymentInfoShowPaymentAmountDateBillingMethod = value;
};
export const setUpdatingBank = (state, value) => {
  state.updatingBank = value;
};
export const setpayNowLinkToggle = (state, value) => {
  state.payNowLinkToggle = value;
};
export const setPreLoadBank = (state, value) => {
  state.preLoadBank = value;
};

export const setBeneficiaries = (state, value) => {
  state.beneficiaries = value;
};

export const setBeneficiaryStep = (state, value) => {
  state.beneficiaryStep = value;
};

export const setBeneficiaryPrimary = (state, value) => {
  state.beneficiaryPrimary = value;
};

export const setBeneficiaryPrimaryContingent = (state, value) => {
  state.beneficiaryPrimaryContingent = value;
};

export const setBeneficiarySpouse = (state, value) => {
  state.beneficiarySpouse = value;
};

export const setBeneficiarySpouseContingent = (state, value) => {
  state.beneficiarySpouseContingent = value;
};

export const setBeneficiaryDataDirty = (state, value) => {
  state.isBeneficiaryDataDirty = value;
};

export const setPaymentStep = (state, value) => {
  state.paymentStep = value;
};

export const setRouteNameToNavigateBack = (state, value) => {
  state.routeNameToNavigateBack = value;
};

export const setWithDrawalDateStep = (state, value) => {
  state.withDrawalDateStep = value;
};

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};
