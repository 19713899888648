<i18n src="../locales.json"></i18n>
<template>
  <v-card
    class="white-background"
    :class="$vuetify.breakpoint.lgAndUp ? 'mt-4' : 'mt-0'"
  >
    <v-card-text
      class="each-row-height top-bottom-blue font-size--18 font-weight-bold"
    >
      <v-layout row wrap>
        <v-flex color="#233b52" xs11>
          <span role="heading" aria-level="2">
            <v-icon color="#233b52">
              notifications
            </v-icon>
            <sup
              class="redBadgeCircleLarge"
            >
              <span class="warningTextInCircleLarge">!</span>
            </sup>
            {{ $t('L_my_notifications') }}
          </span>
        </v-flex>
        <v-flex color="#063562" xs1 text-xs-right>
          <span role="heading" aria-level="2">
            <v-icon size="24" right @click="isShowExpandedFlag = !isShowExpandedFlag">{{
              showExpandedFlag ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
            }}</v-icon>
          </span>
        </v-flex>
      </v-layout>
    </v-card-text>
    <div v-for="(item, index) in getFirstThreeAlertsAndNotifications"
         :key="index + 'alertAndNotify'"
         font-size--14
         :class="index %2 === 0 ? 'light-blue-background': 'white-background'"
    >
      <v-card-text class="each-row-height top-bottom-blue font-size--14" v-if="showExpandedFlag">
        <v-layout row wrap p0 m0>
          <v-flex xs2 text-xs-left>
            <v-icon v-if="item.alertIcon && item.alertIcon.toLowerCase() === 'warning'" color="orange">
              warning
            </v-icon>
            <v-icon v-else-if="item.alertIcon && item.alertIcon.toLowerCase() === 'info'" color="blue">
              info
            </v-icon>
            <v-icon v-else-if="item.alertIcon && item.alertIcon.toLowerCase() === 'error'" color="red">
              error
            </v-icon>
          </v-flex>
          <v-flex xs10 text-xs-right>
            <v-icon v-show="item.allowDismiss" color="#333333" class="pl-2 pushXUp font-size--20" @click="dismissThenGetAlerts(item.alertId)">
              close
            </v-icon>
            <span v-show="!item.allowDismiss" color="#e7edf3" class="pl-2 spacing-right-missing-x">
              &nbsp;
            </span>
          </v-flex>
          <v-spacer />
          <v-flex xs12 sm12 md12 lg12 mb-6 ml-6 mr-6>
            <span color="#333333">
              {{ $t(item.messages.filter(message => message.language === locale)[0].message) }}
            </span>
            <span class="colorBlue pr-2 font-weight-bold clickable" @click="viewNotification(item)">
              {{ item.messages.filter((message) => message.language === locale)[0].actionText }}
            </span>
          </v-flex>
        </v-layout>
      </v-card-text>
    </div>
    <v-card-text class="each-row-height top-bottom-blue font-size--14" v-if="showExpandedFlag && alertsAndNotifications && alertsAndNotifications.length > 3">
      <v-layout row wrap>
        <v-flex grow font-size--14 font-weight-bold class="colorBlue" @click="$router.push({ name: 'AlertAndNotifications' });">
          {{ $t('L_view_all') }}
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Notification from './Notification.vue';

export default {
  // TODO: rename to two word component
  name: 'Notifications',
  data: () => ({
    showMoreDiv: false,
    showAll: false,
    isShowExpandedFlag: false,
  }),
  props: {
    context: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    Notification,
  },
  computed: {
    ...mapGetters({
      isMobileApp: 'isMobileApp',
      locale: 'getLocale',
      alertsAndNotifications: 'alertsAndNotifications',
    }),
    getFirstThreeAlertsAndNotifications() {
      return this.alertsAndNotifications.filter((item, index) => index < 3); // TODO get $Config for count
    },
    showExpandedFlag() {
      return this.isShowExpandedFlag;
    },
  },
  methods: {
    ...mapActions(['getAlerts', 'dismissAlert', 'completeAlert']),
    ...mapMutations(['setShowVerifyPhoneWrapper', 'setShowGoPaperlessWrapper']),
    async dismissThenGetAlerts(alertId) {
      await this.dismissAlert(alertId);
      this.getAlerts();
    },
    viewNotification(itemObj) {
      if (itemObj.actionType === 'PHONE_VERIFICATION') {
        this.$emit('call-verify-phone-wrapper');
      } else if (itemObj.actionType === 'PAPERLESS_COMM') {
        this.setShowGoPaperlessWrapper(true);
      } else if (itemObj.actionType === 'ACKNOWLEDGEMENT') {
        this.$emit('view-notification', itemObj.alertId);
      } else {
        // TODO for view later
      }
    },
  },
  created() {
    if (this.$vuetify.breakpoint.smAndDown || this.isMobileApp) {
      this.isShowExpandedFlag = false;
    } else {
      this.isShowExpandedFlag = true;
    }
  },
  mounted() {
    this.getAlerts();
    if (this.notifications?.length > 3) {
      this.showMoreDiv = true;
    }
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'
.clickable
  cursor: pointer;
.icon_box
  max-width: 10px;
.redBadgeCircleLarge
  width: 15px;
  background: #f00;
  position: relative;
  top: -26px;
  height: 15px;
  border-radius: 15px;
  left: -11px;
  display: inline-block;
.warningTextInCircleLarge
  display: inline-block;
  position: relative;
  left: 6px;
  top: 7px;
  font-size: 10px;
  color: #fff;
  font-weight: 800;
.borderLeftWall
  border-left: 1px solid #ccc;
.light-blue-background
  background-color: #f5f7fa;
.white-background
  background-color: #ffffff !important;
.blue-background
  background-color: #e7edf3 !important;
.each-row-height
  zheight: 50px;
.top-bottom-blue
  border-bottom: 1px solid #b4c9db;
.colorBlue
  color: #0c6dca;
.pushXUp
  margin-top: -2px;
.ViewAndVerifyTop
  padding-top: 4px;
.spacing-right-missing-x
  padding-right: 23px;
#notifications
  position: relative;
  margin-top: 0px;
  padding-left: 0px;
  background-color: black;
  color: white;
  .v-notification.error
    position: inherit;
  .v-expansion-panel
    box-shadow: none;
  .theme--light.v-icon
    color: white;
  .header
    padding-left: 10px;
  .details
    padding-left: 34px;
  .title
    font-size:40px;
  .circledX
    float:right;
    color: white;
  @media only screen and (min-width: 1264px)
    zpadding-left: 238px;
    margin-top:18px;
</style>

