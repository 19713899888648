<i18n src="./locales.json"></i18n>
<template>
  <div class="date-picker-wrapper mb-2">
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <span
          class="font-weight-medium"
          id="select_payment_date"
        >
          {{ $t('L_select_monthly_payment_day') }}
        </span>
      </v-flex>
      <v-flex shrink>
        <v-text-field
          aria-labelledby="select_payment_date"
          class="date-picker-date"
          v-model="formInput.$model"
          :error-messages="errorMessages"
          label=""
          mask="##"
          max-length="2"
          @blur="$emit('formatInput')"
        />
      </v-flex>
      <v-flex
        shrink
        mr-1
      >
        <v-menu
          tabindex="-1"
          aria-hidden="true"
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
            >
              <v-icon color="primary">
                calendar_today
              </v-icon>
            </v-btn>
          </template>
          <div class="v-picker v-card v-picker--date theme--light">
            <div class="v-picker__title primary">
              <div class="v-date-picker-title text-xs-center">
                <div class="v-picker__title__btn v-date-picker-title__date v-picker__title__btn--active text-xs-left text-md-center">
                  <span
                    :class="$vuetify.breakpoint.xsOnly ? 'font-size--14' : 'font-size--16'"
                  >
                    {{ $t('L_select_your_monthly_payment_day') }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="v-picker__body theme--light"
              style="width: 350px;"
            >
              <div class="v-date-picker-table v-date-picker-table--date theme--light custom-date-picker">
                <table>
                  <tbody>
                    <tr
                      v-for="(tr, i) in table"
                      :key="i"
                      :class="{'small': $vuetify.breakpoint.xsOnly}"
                    >
                      <td
                        v-for="(td, idx) in tr"
                        :key="idx"
                        :class="{'small': $vuetify.breakpoint.xsOnly}"
                      >
                        <button
                          type="button"
                          class="v-btn v-btn--flat v-btn--floating theme--light"
                          :class="getBtnClass(td)"
                          @click="$emit('updateDate', td)"
                        >
                          <div class="v-btn__content">
                            {{ td }}
                          </div>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="cancel-section">
                  <v-btn
                    flat
                    color="primary"
                  >
                    <h3>{{ $t('L_cancel') }}</h3>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-menu>
      </v-flex>
      <v-flex
        class="text-xs-center text-sm-left sm6"
        :class="{'mt-4': $vuetify.breakpoint.smAndUp}"
      >
        <span class="font-size--12">{{ $t('L_select_payment_date_between') }}</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'Datepicker',
  extends: BaseTextField,
  props: {
    formInput: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    table: [
      ['01', '02', '03', '04', '05', '06', '07'],
      ['08', '09', '10', '11', '12', '13', '14'],
      ['15', '16', '17', '18', '19', '20', '21'],
      ['22', '23', '24', '25', '26', '27', '28'],
      ['29', '30', '31'],
    ],
    modelName: 'datepicker',
  }),
  methods: {
    updateDate(val) {
      this.$emit('updateDate', val);
    },
    getBtnClass(val) {
      return {
        'v-btn--active primary v-btn--icon': val < 29 && val === this.formInput.$model,
        'v-btn--disabled': val > 28,
      };
    },
  },
  filters: {
    addZero: date => (date.length > 1 ? date : `0${date}`),
  },
};
</script>

<style scoped>
.custom-date-picker table tr.small,
.custom-date-picker table td.small {
  border: 0;
}
.custom-date-picker table td.small {
  display: inline;
}
.large-date-picker {
  width: 350px;
}
.v-input.date-picker-date {
  max-width: 45px;
}
button.v-btn.v-btn--flat.v-btn--floating.theme--light{
  font-size: 14px;
}
.v-date-picker-table.custom-date-picker{
  padding: 12px;
}
.cancel-section {
  position: absolute;
  bottom: 16px;
  right: 8px;
}
/deep/ #datepicker_error_message > .v-messages__message {
  position: fixed !important;
}
</style>
