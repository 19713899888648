<i18n src='./locales.json'></i18n>
<template>
  <v-container>
    <!-- main layout -->
    <v-layout
      row
      wrap
      class="white--text"
    >
      <!-- domestic violence card title -->
      <v-flex
        xs12
      >
        <v-layout
          row
          wrap
          align-center
          class="mb-4"
        >
          <v-flex>
            <div
              role="heading"
              aria-level="1"
              class="heading_two"
            >
              {{ $t('L_new_york_domestic_violence_notice') }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- main content flex -->
      <v-flex
        xs12
        id="main-flex"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            mt-2
            xs12
          >
            <v-card>
              <v-card-title>
                <v-flex>
                  <span
                    class="card-title"
                  >{{ $t('L_confidentiality_information') }}</span>
                </v-flex>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-layout
                  row
                  wrap
                  pt-2
                >
                  <v-flex
                    xs12
                    mb-2
                  >
                    <h5 class="heading_five font-weight-medium">
                      {{ $t('L_know_your_rights') }}
                    </h5>
                  </v-flex>
                  <v-flex
                    xs12
                    mb-2
                  >
                    <a href="javascript:void(0)" @click="openDocument('insurance')">
                      <h4 class="heading_four font-weight-medium">{{ $t('L_ny_ins_law') }}</h4>
                    </a>
                  </v-flex>
                  <v-flex xs12 mb-2>
                    <a href="javascript:void(0)" @click="openDocument('confidential')">
                      <h4 class="heading_four font-weight-medium">{{ $t('L_confidential_comm_req') }}</h4>
                    </a>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <ViewContactDetails />
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <EmbeddedViewer
        :title="document.title"
        :loading="document.loading"
        content-type="application/pdf"
        :content-url="document.content"
        @close="document.content = null"
      />
    </v-layout>
  </v-container>
</template>
<script>
import Config from '@/shared/util/config';
import { mapGetters } from 'vuex';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';
import Representative from '@/components/Representative.vue';
import ViewContactDetails from './components/ViewContactDetails.vue';

export default {
  name: 'DomesticViolence',
  components: {
    EmbeddedViewer,
    Representative,
    ViewContactDetails,
  },
  data: () => ({
    showModal: false,
    document: {
      title: '',
      content: '',
      loading: false,
    },
    url: `${Config.get('content_stack')}${Config.get('policyDocumentForms.en_NY.nyInsuranceLaw')}`,
    confidentialCommunication: {
      url: `${Config.get('content_stack')}${Config.get('policyDocumentForms.en_NY.confidentialCommunication')}`,
      name: 'L_confidential_communication_card',
    },
  }),
  computed: {
    ...mapGetters(['getAgent']),
    ...mapGetters('policy', {
      policyData: 'currentPolicy',
    }),
    isRestricted() {
      return this?.policyData?.restricted;
    },
    agents() {
      return [this.getAgent(this?.policyData?.agentId)];
    },
  },
  methods: {
    openDocument(type) {
      if (type === 'confidential') {
        this.document.content = this.confidentialCommunication.url;
        this.document.title = this.$t(this.confidentialCommunication.name);
      } else if (type === 'insurance') {
        this.document.content = this.url;
        this.document.title = this.$t('L_insurance_law_title');
      }
    },
  },
};
</script>
