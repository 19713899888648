<i18n src="./locales.json"></i18n>
<template>
  <v-layout row wrap class="mainContainerBackground">
    <v-layout row wrap>
      <v-flex
        xs12
        align-center
      >
        <img alt="family having fun" style="width:100%" src="../../assets/Familyhavingfunathome.jpg">
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'faster-simpler-textMob' : 'faster-simpler-text'">
        {{ $t('L_a_faster_simpler_path') }}
      </v-flex>
    </v-layout>
    <v-layout row wrap :class="$vuetify.breakpoint.xs ? 'pt-2 pl-3 pr-3' : 'pt-3 pl-5 pr-5'">
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'pay-off_your_debt_cssMob' : 'pay-off_your_debt_css'">
        {{ $t('L_pay_off_your_debt') }}
      </v-flex>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'excess_debt_can_be_a_threat_to_your_financial_security_cssMob' : 'excess_debt_can_be_a_threat_to_your_financial_security_css'">
        {{ $t('L_excess_debt_can_be_a_threat_to_your_financial_security') }}
      </v-flex>
    </v-layout>
    <v-layout row wrap :class="$vuetify.breakpoint.xs ? 'pt-2 pl-3 pr-3 pb-2' : 'pl-5 pr-5 pt-3 pb-3'">
      <v-flex text-xs-center xs12 class="view_your_plan_css">
        <v-btn
          role="link"
          color="primary"
          class="setPlanBtnWidth"
          @click="goToNavigate"
        >
          {{ twoOrMoreDebtsAndMinOneUnpaidDebt ? $t('L_view_your_plan') : $t('L_create_your_plan') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap :class="$vuetify.breakpoint.xs ? 'pl-3 pr-3' : 'pl-5 pr-5'">
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'debt_Stacking_can_help_cssMob' : 'debt_Stacking_can_help_css'">
        {{ $t('L_debt_Stacking_can_help') }}
      </v-flex>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'how_soon_could_you_be_debt_free_cssMob' : 'how_soon_could_you_be_debt_free_css'" class="if_the_idea_of_paying_off_your_css">
        {{ $t('L_if_the_idea_of_paying_off_your') }}
      </v-flex>
    </v-layout>
    <v-layout :class="$vuetify.breakpoint.xs ? 'ml-3 mr-3 pl-3 pr-3' : 'ml-3 mr-3 pl-3 pr-3'">
      <v-flex class="video">
        <iframe
          :src="$t('L_video_url_debt_stacking')"
          title="video"
          frameborder="0"
          allowfullscreen
          allow="accelerometer; encrypted-media; gyroscope"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap :class="$vuetify.breakpoint.xs ? 'pl-3 pr-3' : 'pl-5 pr-5'">
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'by_taking_into_account_cssMob' : 'by_taking_into_account_css'">
        {{ $t('L_by_taking_into_account') }}
      </v-flex>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'begin_by_making_consistent_payments_cssMob' : 'begin_by_making_consistent_payments_css'">
        {{ $t('L_begin_by_making_consistent_payments') }}
      </v-flex>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'when_you_pay_off_the_target_cssMob' : 'when_you_pay_off_the_target_css'">
        {{ $t('L_when_you_pay_off_the_target') }}
      </v-flex>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'debt_stacking_works_best_when_cssMob' : 'debt_stacking_works_best_when_css'">
        {{ $t('L_debt_stacking_works_best_when') }}
      </v-flex>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'how_soon_could_you_be_debt_free_cssMob' : 'how_soon_could_you_be_debt_free_css'">
        {{ $t('L_how_soon_could_you_be_debt_free') }}
      </v-flex>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'revisit_the_my_plan_tab_cssMob' : 'revisit_the_my_plan_tab_css'">
        {{ $t('L_revisit_the_my_plan_tab') }}
      </v-flex>
    </v-layout>
    <v-layout row wrap class="fullWidth">
      <v-flex xs12>
        <ContactRepFooter v-if="agent" />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex>&nbsp;</v-flex>
    </v-layout>
  </v-layout>
</template>
<script>
// TODO: map 'debt/planStatus' getter and show correct correct label - Create vs View Your Plan
import { mapActions, mapGetters } from 'vuex';
import Alert from '@/components/Alert.vue';
import ContactRepFooter from '@/components/ContactRep/ContactRepFooter.vue';

export default {
  name: 'LearnMore',
  components: {
    Alert,
    ContactRepFooter,
  },
  data: () => ({
    debtCount: 2, // TODO: remove; use 'debt/planStatus' getter instead
  }),
  computed: {
    ...mapGetters(['agents', 'getAgent']),
    ...mapGetters({
      hasNoPlan: 'debtsolutions/hasNoPlan',
      twoOrMoreDebtsAndMinOneUnpaidDebt: 'debtsolutions/twoOrMoreDebtsAndMinOneUnpaidDebt',
      unpaidDebts: 'debtsolutions/unpaidDebts',
      paidOffDebts: 'debtsolutions/paidOffDebts',
    }),
    agent() {
      return (this.agents?.length === 1) ? this.agents[0] : null;
    },
  },
  methods: {
    ...mapActions({
      setAddADebtOpen: 'debtsolutions/setAddADebtOpen',
    }),
    goToNavigate() {
      // -1 = No plan so this is for create your plan i.e. MyDebt to enter info
      // 0 means less than 2 items in unpaid debt
      // else User has at least one plan either paid or unpaid so we take them to MyPlan View
      if (!this.twoOrMoreDebtsAndMinOneUnpaidDebt) {
        this.setAddADebtOpen(true).then(() => {
          this.$router.push({ name: 'TermLifeBenefits' }).then(() => {
            this.$router.push({ name: 'MyDebt' });
          });
        }).catch((error) => { this.addError({ error, context: 'learn_more' }); });
      } else if (this.$route.path !== '/debtsolutions/myplan') {
        this.$router.push({ name: 'TermLifeBenefits' }).then(() => {
          this.$router.push({ name: 'MyPlan' });
        }).catch((error) => { this.addError({ error, context: 'learn_more' }); });
      }
    },
  },
};

</script>

<style  lang="stylus" scoped>
.mainContainerBackground
  background-color: #ffffff;
.faster-simpler-textMob
  margin-top: -77px;
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
  padding-left: 10px;
.faster-simpler-text
  margin-top: -77px;
  font-size: 26px;
  font-weight: 700;
  color: #FFFFFF;
  padding-left: 45px;
.pay-off_your_debt_css, .debt_Stacking_can_help_css, .how_soon_could_you_be_debt_free_css
  color: #333333;
  font-size: 32px;
  font-weight:600;
  margin-top: 50px;
.pay-off_your_debt_cssMob, .debt_Stacking_can_help_cssMob, .how_soon_could_you_be_debt_free_cssMob
  color: #333333;
  font-size: 24px;
  font-weight:600;
  margin-top: 10px;
.excess_debt_can_be_a_threat_to_your_financial_security_css, .if_the_idea_of_paying_off_your_css,
.by_taking_into_account_css, .begin_by_making_consistent_payments_css, .when_you_pay_off_the_target_css,
.debt_stacking_works_best_when_css, .revisit_the_my_plan_tab_css
  color: #4a4a4a;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  padding: 8px 0 8px 0;
.excess_debt_can_be_a_threat_to_your_financial_security_cssMob, .if_the_idea_of_paying_off_your_cssMob,
.by_taking_into_account_cssMob, .begin_by_making_consistent_payments_cssMob, .when_you_pay_off_the_target_cssMob,
.debt_stacking_works_best_when_cssMob, .revisit_the_my_plan_tab_cssMob
  color: #4a4a4a;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  padding: 8px 0 8px 0;
.your_representative_can_help_css
  color: #4a4a4a;
  font-size: 24px;
  line-height: 1.5;
.Zv-btn
    background-color: #063562 !important
    border: 2px solid #063562;
    padding-right:15px !important;
    color: white;
    border-color: white;
.Zv-btn__content
  color: #063562;
.agentImage
  height: 80px;
  vertical-align: middle;
.backgroundGray
  background-color: #eeeeee;
.video
  margin: 40px 0
  position: relative;
  padding-top: 56.25%;  /* 16:9 Aspect Ratio */
  iframe
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
.valignMiddle
  zvertical-align: middle;
  margin: auto !important;
.setPlanBtnWidth
  width: 200px;
.modal
  position: fixed; /* Stay in place */
  z-index: 11; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
.fullWidth
  width: 100%;
</style>
