<i18n src="../../locales.json"></i18n>
<template>
  <v-layout
    row
    wrap
    class="white--text"
  >
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="orangeTop">
        <v-card-title>
          <v-icon class="warning-icon warning--text mr-3">
            warning
          </v-icon>
          <span class="headline fontWeight600">{{ $t('L_are_you_sure') }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <span aria-live="assertive">
              {{ $t('L_do_you_really_want_to_cancel') }} <br>
              {{ $t('L_all_previously_entered_informatoin_will') }}
            </span>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-layout>
            <v-flex>
              <v-btn color="#02579e" flat="flat" @click="cancelThenGoToDashboard()">
                {{ $t('L_cancel_transaction') }}
              </v-btn>
            </v-flex>
            <v-flex>
              <v-btn color="#02579e" flat="flat" @click="$emit('hide-warning')">
                {{ $t('L_stay_on_this_screen') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'WarningDialog',
  data: () => ({
    dialog: true,
  }),
  methods: {
    cancelThenGoToDashboard() {
      this.$emit('go-to-dashboard');
    },
  },
};
</script>
<style lang="stylus" scoped>
   .close{
    float: right;
  }
  .orangeTop{
    border-top: 7px solid #ee7026;
  }
  .fontWeight600{
    font-weight: 600;
  }
</style>

