import bff from '@/shared/api/bff';
import moment from 'moment';

// MAIN ACTIONS - changes steps or application state
export const setStepToStart = async ({ commit }) => {
  commit('STEP', 1);
};

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const debtInit = async ({ dispatch, commit }) => {
  // pull debts
  const responseStatus1 = await dispatch('pullDebts');
  if (responseStatus1) {
    // calculate
    const responseStatus2 = await dispatch('pushDebts');
    if (responseStatus2) {
      // If true it will have Add a Debt open when going into the MyDebt Tab
      commit('ADDADEBTOPEN', false);
    }
  }
};

export const callPushDebts = async ({ dispatch }, persondob) => {
  await dispatch('pushDebts', persondob);
};

export const setAddADebtOpen = ({ commit }, openFlag) => {
  commit('ADDADEBTOPEN', openFlag);
};


export const setPersonInfo = ({ commit }, personInfo) => {
  commit('PERSONINFO', personInfo);
};

export const setScenario = ({ commit }, scenarioObj) => {
  commit('SCENARIO', scenarioObj);
};


export const createDebtItem = async ({ dispatch, getters, commit }, debtItem, persondob) => {
  // setup ID
  let max = 0;
  let id = 0;
  const tDebts = getters.debts;
  tDebts.forEach((element) => {
    id = Number.parseInt(element.debtID, 10) || 0;
    if (id > max) {
      max = id;
    }
  });
  const setNewId = (parseInt(max, 10) || 0) + 1;
  debtItem.debtID = `${setNewId}`;
  // 30 max validation
  if (max !== 31) {
    // update DEBTS
    commit('DEBT', debtItem);

    // sync to server and calculate
    await dispatch('pushDebts', persondob);
    return true;
  }
  return false;
};

export const updateDebtItem = async ({ dispatch, commit }, debtItem, persondob) => {
  // update DEBTS
  commit('DEBT', debtItem);

  // sync to server and calculate
  await dispatch('pushDebts', persondob);
  return true;
};

export const deleteDebtItem = async ({ dispatch, commit }, debtItem, persondob) => {
  // update DEBTS
  commit('DEBT_REMOVAL', debtItem);

  // push to server and calculate
  await dispatch('pushDebts', persondob);
  return true;
};

// END MAIN ACTIONS - changes steps or application state

// HELPER ACTIONS - BFF or other API Integration
function getInvestment(investments) {
  if (investments?.calculator?.scenario) {
    return JSON.parse(JSON.stringify(investments.calculator.scenario));
  }
  const accounts = [];
  investments.groups.forEach((group) => {
    group.accounts.forEach((account) => {
      accounts.push({
        productType: account?.productType,
        number: account?.accountNumber,
        enabled: true,
        type: account?.accountType,
        balance: account?.endingValue,
      });
    });
  });
  return {
    currentIncome: 0, incomeGoal: 0, incomeGoalPct: 0, otherSavings: 0, monthlySavings: 0, scenarioId: -1, retirement: { accounts, savings: 0, age: 0 },
  };
}

export const pullDebts = async ({ commit, rootGetters }) => {
  // {"scenarios":[{"agentId":"","state":"","country":"","includeSpouse":0,"retirementROR":0.08,"debtInfo":[],"personInfo":[]}]}
  const response = await bff.getDebt();
  // throw error in case of non-200 response
  if (Math.floor(response.status / 100) !== 2) {
    // throw new Error('Pulling Debts failed.');
    return false;
  }

  const scenario = response.data.scenarios[0];
  commit('SCENARIO', scenario);
  if (scenario?.debtInfo) {
    const debt = scenario.debtInfo;
    debt.scenarioId = scenario.scenarioId;

    // cleaning up the scenario.debtname to only allow a-zA-Z0-9 and space
    scenario.debtInfo.forEach((debtObj1) => {
      debtObj1.debtName = debtObj1.debtName.replace(/[^A-Za-z0-9 ]/g, '');
    });
    commit('DEBTS', debt);
  }
  let personInfo = response.data.scenarios[0].personInfo[0];
  if (personInfo === undefined || personInfo === null) {
    personInfo = {
      personType: 'P',
      name: 'Primary Debt',
      currentAge: 0, // 46 Grab from  life or investment
      retirementAge: 0, // 67 Grab from  investment  else use default CA: 65 US: 67
      dob: '', // '1974-06-06T00:00:00' Grab from life or investment
      grossMonthlyIncome: 5000.00,
    };
  }

  const investments = rootGetters['customer/investments'];
  const investScenario = getInvestment(investments);
  // Data coming in from Investment side
  if (investScenario != null && investScenario?.retirement?.age > 0) {
    personInfo.retirementAge = investScenario?.retirement?.age;
  }
  if (personInfo?.currentAge) {
    commit('PERSONINFO', personInfo);
  }
  return true;
};


export const pushDebts = async ({ commit, getters, rootGetters }, persondob) => {
  // let lifeIssueDate = ''; // 06/28/2014
  // const investIssueDate = ''; // Currently can't find the date that the dob was registered or investments created

  // eslint-disable-next-line prefer-destructuring
  let scenario = JSON.parse(JSON.stringify(getters.scenario));


  // Check for
  if (scenario == null || scenario.personInfo == null || scenario.personInfo.length === 0) {
    scenario = {
      scenarioId: -1,
      agentId: '', // 00028', This is not being looked at in Back End
      state: '', // 'GA
      country: '', // Need to do this 'US', 'CA'
      includeSpouse: 0,
      retirementROR: 0.08, // Need to do this Investment Guidance Calc
      personInfo: [
        {
          personType: 'P',
          name: 'Primary Debt',
          currentAge: 0, // 46 Grab from  life or investment
          retirementAge: 0, // 67 Grab from  investment  else use default CA: 65 US: 67
          dob: '', // '1974-06-06T00:00:00' Grab from life or investment
          grossMonthlyIncome: 5000.00,
        },
      ],
      debtInfo: null,
    };
  }
  // This is to grab the user updated DOB from MyDebt
  let tempPersonDob = '';
  if (!!(persondob) === true) {
    const tpersondob = moment(persondob, 'MMDDYYYY');
    tempPersonDob = moment(tpersondob).format('YYYY-MM-DDTHH:mm:ss-00:00');
  } else if (getters.personInfo !== undefined && getters.personInfo?.dob != null && getters.personInfo?.dob !== '') {
    tempPersonDob = JSON.parse(JSON.stringify(getters.personInfo?.dob));
  }
  // If not null, empty string for tempPersonDob and scenario.personInfo[0].dob is empty String
  if (tempPersonDob !== undefined && tempPersonDob != null && tempPersonDob !== '' && (scenario.personInfo[0]?.dob == null || scenario.personInfo[0]?.dob === '')) {
    scenario.personInfo[0].dob = tempPersonDob;
    const tDobDate = moment(tempPersonDob, 'YYYY-MM-DDT00:00:00-00:00');
    // Only set the dob && currentAge if dob is null or empty string
    if (tDobDate.isValid()) {
      scenario.personInfo[0].currentAge = moment().diff(tDobDate, 'years');
    }
  }

  if (scenario.personInfo[0].dob === '' || scenario.personInfo[0].retirementAge === 0) {
  // Start of setting DOB from investment and life
    let investments = rootGetters['customer/investments'];
    let investScenario = getInvestment(investments);
    let currentPolicy = rootGetters['policy/currentPolicy'];
    // Start a while loop that puts data from investment and life, keep running
    // until necessary data is set or for maxIteration.
    const maxIteration = 10; // 5 secs max
    const iTimeOutInMilliseconds = 500;
    let iCount = 0;
    while (iCount < maxIteration && (scenario.personInfo[0].dob === '' || scenario.personInfo[0].retirementAge === 0)) {
      investments = rootGetters['customer/investments'];
      // eslint-disable-next-line no-await-in-loop
      investScenario = getInvestment(investments);
      // Data coming in from Investment side
      if (investScenario != null) {
        scenario.personInfo[0].retirementAge = investScenario?.retirement?.age;
        const tDate1 = moment(investScenario?.dateOfBirth, 'YYYY-MM-DD');
        // Only set the dob && currentAge if dob is null or empty string
        if ((scenario.personInfo[0].dob == null || scenario.personInfo[0].dob === '') && tDate1.isValid()) {
        // Date needs to be in this format '1974-06-06T00:00:00'
          scenario.personInfo[0].dob = moment(tDate1).format('YYYY-MM-DDTHH:mm:ss-00:00');
          scenario.personInfo[0].currentAge = moment().diff(scenario?.personInfo[0].dob, 'years');
        }
      }
      // Life Policy
      currentPolicy = rootGetters['policy/currentPolicy'];

      // Setting agentId
      if (currentPolicy != null && currentPolicy?._agentId != null) {
        scenario.agentId = currentPolicy?._agentId;
      }
      // Looking at life policy to fill the dob if not empty
      if (currentPolicy?._phases != null) {
        const phases = currentPolicy?._phases;
        phases.forEach((phase) => {
          if (phase?.phaseNo === '01') {
            // Only set the dob && currentAge if dob is null or empty string
            if (scenario.personInfo[0].dob == null || scenario.personInfo[0].dob === '') {
              const tDate2 = moment(phase?.personDateOfBirth, 'MM/DD/YYYY');
              if (tDate2.isValid()) {
              // Date needs to be in this format '1974-06-06T00:00:00'
                scenario.personInfo[0].dob = moment(tDate2).format('YYYY-MM-DDTHH:mm:ss-00:00');
                scenario.personInfo[0].currentAge = moment().diff(scenario?.personInfo[0].dob, 'years');
              }
            }
            scenario.state = phase?.phIssueState;
          // This is the issue date for life
          // lifeIssueDate = moment(phase?.phIssueDate, 'MM/DD/YYYY');
          }
        });
      }
      iCount++;
      // await timeout(iTimeOutInMilliseconds);
      // eslint-disable-next-line no-await-in-loop
      await timeout(iTimeOutInMilliseconds);
    }
    // End a while loop

    // Catching any missing data and putting default
    if (scenario.agentId === '') {
      scenario.agentId = '00028';
    }

    // This is to put the country and state from customerProfile
    const customerProfileObj = rootGetters['customer/customerProfile'];
    if (customerProfileObj != null) {
      if (customerProfileObj?.country === 'US') {
        scenario.country = 'US';
        if (scenario.state === '') {
          scenario.state = 'GA';
        }
      } else {
        scenario.country = 'CA';
        if (scenario.state === '') {
          scenario.state = 'ON';
        }
      }
    }

    // This is to handle cases where for some reason no data in country and state
    if (scenario.country === '') {
      scenario.country = 'US';
    }
    if (scenario.state === '') {
      scenario.state = 'GA';
    }

    if (scenario.personInfo[0].retirementAge === 0) {
      scenario.personInfo[0].retirementAge = 67;
    }
    if (scenario.personInfo[0].dob === '') {
      scenario.personInfo[0].dob = '1974-06-06T00:00:00';
    }

    if (scenario.personInfo[0].currentAge === 0) {
      const tCurrentAge = moment().diff(scenario.personInfo[0].dob, 'years');
      scenario.personInfo[0].currentAge = tCurrentAge;
    }
    // This is to save the mocked up scenario obj
    // setScenario(scenario);
  // End of setting DOB from investment and life
  }

  // Setting the ROR depending on the age till retirement, if more than 5 years 8% (default value),  else 6%
  const timeTillRetirement = scenario.personInfo[0].retirementAge - moment().diff(scenario?.personInfo[0].dob, 'years');
  if (timeTillRetirement < 5) {
    scenario.retirementROR = 0.06;
  } else {
    scenario.retirementROR = 0.08;
  }

  // Saving personInfo
  commit('PERSONINFO', scenario.personInfo[0]);

  scenario.debtInfo = getters.debts;
  if (getters.debts && getters.debts.scenarioId) {
    scenario.scenarioId = getters.debts.scenarioId;
  } else {
    scenario.scenarioId = -1;
  }

  // Saving scenario
  // commit('SCENARIO', scenario);

  const response = await bff.postDebtAndCalculate(scenario);
  // throw error in case of non-200 response
  if (Math.floor(response.status / 100) !== 2) {
    // throw new Error('Pushing Debts failed.');
    return false;
  }

  // const plans = response.data.plans.planCollection;
  /*
  commit('PLAN_WO_STACKING', response.data.plans[0].planItemCollection);
  commit('PLAN_W_STACKING', response.data.plans[1].planItemCollection);
  */
  commit('PLAN_WO_STACKING', response.data.plans[0]);
  commit('PLAN_W_STACKING', response.data.plans[1]);
  return true;
};

