import axios from 'axios';
import Config from '@/shared/util/config';

/*
Core Okta API
OpenID Connect & OAuth 2.0 API
see https://developer.okta.com/docs/reference/api/oidc/
*/

const { issuerUrl, clientId } = Config.get('auth.providers.okta'); // 'https://${yourOktaDomain}/oauth2/${authorizationServerId}/v1',

/*
The API takes an access or refresh token and revokes it.
Revoked tokens are considered inactive at the introspection endpoint.
A client may only revoke its own tokens.
https://developer.okta.com/docs/reference/api/oidc/#revoke
*/
export const revokeToken = async (token, tokenTypeHint) => {
  // Paameters
  // token: An access or refresh token. String
  // token_type_hint: A hint of the type of token. Valid values: access_token, refresh_token, and device_secret
  const p = new URLSearchParams();
  p.append('token', token);
  p.append('token_type_hint', tokenTypeHint || 'refresh_token');
  p.append('client_id', clientId);

  const response = await axios.post(`${issuerUrl}/revoke`, p, {
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  });
  return response.data;
};
