import { render, staticRenderFns } from "./PayOffMortgage.vue?vue&type=template&id=237901a1&scoped=true"
import script from "./PayOffMortgage.vue?vue&type=script&lang=js"
export * from "./PayOffMortgage.vue?vue&type=script&lang=js"
import style0 from "./PayOffMortgage.vue?vue&type=style&index=0&id=237901a1&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237901a1",
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fguidance%2Fcomponents%2FImmediateCashNeeds%2Fcomponents%2FPayOffMortgage.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports