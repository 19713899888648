<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="500"
      id="messageDialog"
      :persistent="isPersistent"
    >
      <v-card :class="border">
        <v-card-title>
          <v-layout>
            <v-flex xs1 sm1 md1 lg1>
              <v-icon v-if="type === 'warning'" class="mr-3" :color="iconColor">
                warning
              </v-icon>
              <v-icon v-if="type === 'info'" class="mr-3" :color="iconColor">
                info
              </v-icon>
              <v-icon v-if="type === 'error'" class="mr-3" :color="iconColor">
                error
              </v-icon>
              <v-icon v-if="type === 'success'" class="mr-3" :color="iconColor">
                check_circle
              </v-icon>
            </v-flex>
            <v-flex xs11 sm11 md11 lg11>
              <span class="headline fontWeight600" v-html="title" />
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs12 sm12 md12 lg12 :class="messageAlign === 'center' ? 'text-xs-center' : ''">
              <slot name="message" />
              <span v-if="!hasSlot('message')" v-html="message" />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-layout>
            <v-flex v-if="hasButtonTwo">
              <v-btn
                color="primary"
                text
                @click="buttonTwoAction"
                flat="flat"
              >
                {{ buttonTwoLabel }}
              </v-btn>
            </v-flex>
            <v-flex>
              <v-btn
                color="primary"
                text
                @click="buttonOneAction"
                flat="flat"
              >
                {{ buttonOneLabel }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MessageDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'error',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    messageAlign: {
      type: String,
      required: false,
      default: '',
    },
    hasButtonTwo: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonOneLabel: {
      type: String,
      required: false,
      default: 'OK',
    },
    buttonTwoLabel: {
      type: String,
      required: false,
      default: '',
    },
    isPersistent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    buttonTwoAction() {
      this.$emit('buttonTwoAction');
    },
    buttonOneAction() {
      this.$emit('buttonOneAction');
    },
    hasSlot(name = 'message') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
  computed: {
    border() {
      // eslint-disable-next-line
      if (this.type === 'error') {
        return 'errorBorder';
      } else if (this.type === 'warning') {
        return 'warningBorder';
      } else if (this.type === 'success') {
        return 'successBorder';
      } else if (this.type === 'info') {
        return 'infoBorder';
      }
      return '';
    },
    iconColor() {
      if (this.type === 'error') {
        return 'error';
      } else if (this.type === 'warning') {
        return 'warning';
      } else if (this.type === 'success') {
        return 'success';
      } else if (this.type === 'info') {
        return 'info';
      }
      return '';
    },
  },
};
</script>

<style lang="stylus">
  .leftalignment {
    border-top: 7px solid #EE7026 !important;
  }
  .warningBorder {
    border-top: 7px solid #EE7026 !important;
  }
  .infoBorder {
    border-top: 7px solid #2196F3 !important;
  }
  .successBorder {
    border-top: 7px solid #0B8D1F !important;
  }
  .errorBorder {
    border-top: 7px solid #DD3333 !important;
  }
</style>
