<i18n src='./locales.json'></i18n>

<template>
  <div
    class="locale-selection"
    :class="display === true ? '' : 'hide'"
  >
    <div
      class="submenu"
    >
      <v-menu
        relative
        :content-class="submenuLocation"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            class="menu-position blue_label ml-0 mr-0"
            :class="isWideEnoughForFullMenu? 'font-size--16': 'font-size--14'"
          >
            <v-icon
              color="#02579e"
              class="language-icon"
            >
              language
            </v-icon>
            {{ $t(`L_${radioLang}`) }}
            <v-icon v-if="displayIcon">
              arrow_drop_down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile
            v-for="(language, index) in languages"
            :key="index"
            @click="updateLocale(language)"
          >
            <ul
              class="language-list"
              :class="isWideEnoughForFullMenu? 'font-size--16': 'font-size--14'"
            >
              <li class="language-item">
                <div class="language-label">
                  {{ $t(`L_${language}`) }}
                  <v-icon
                    class="check-mark"
                    :class="radioLang === language ? 'active' : ''"
                  >
                    done
                  </v-icon>
                </div>
              </li>
            </ul>
          </v-list-tile>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocaleToggle',
  props: {
    currentLocale: {
      type: String,
      required: true,
      default: () => 'en_US',
    },
    languages: {
      type: Array,
      required: true,
      default: () => [],
    },
    display: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    displayIcon: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    submenuLocation: {
      type: String,
      required: false,
      default: () => 'left',
    },
  },
  data: () => ({
    radioLang: '',
    toggleArrowState: true,
  }),
  watch: {
    currentLocale(value) {
      this.radioLang = value;
    },
  },
  computed: {
    isWideEnoughForFullMenu() {
      return this.$vuetify.breakpoint.width > 1284;
    },
  },
  mounted() {
    this.radioLang = this.currentLocale;
  },
  methods: {
    updateLocale(language) {
      this.$emit('update-locale', language);
    },
  },
};
</script>

<style scoped>
  .arrow-position {
    position: absolute !important;
    margin: 18px 0 0 -35px !important;
  }
  .menu-position {
    width: auto;
    height: 74px !important;
    /* margin: 5px 27px 0 0px !important; */
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: #000 !important;
    background-color: transparent;
  }
  .menu-position:hover {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .menu-position:focus {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
  }
  .menu-position:hover:before,
  .menu-position:focus:before {
    background-color: transparent !important;
  }
  .menu-position .v-btn__content {
    justify-content: left !important;
  }
  .v-messages {
    display: none;
    min-height: 0 !important;
    height: 0;
  }
  .language-icon {
    position: relative;
  }
  .language-list {
    padding-left: 0;
    margin: 0 0 0 0;
    position: relative;
  }
  .language-item {
    list-style-type: none;
    padding: 0;
  }
  .language-item i {
    visibility: hidden;
    color: #333;
  }
  .language-item i.active {
    visibility: visible;
  }
  .language-label {
    color: #333;
  }
  .hide {
    display: none;
  }
  div.locale-selection {
    margin-top: -18px;
    margin-right: 0px;
    /* zposition: absolute; */
  }
  div.locale-selection .submenu {
      margin-right: 0px;
  }
  div.locale-selection .submenu .menu-position {
    height: 53px !important;
  }
  .v-menu__content.left {
    margin-left: -164px;
    margin-top: 2px;
  }
  .v-menu__content.bottom {
    margin-left: 0px;
    margin-top: 68px;
  }
  .v-ripple__container {
    display: none !important;
  }
  .v-btn--active:before,
  .v-btn:hover:before,
  .v-btn:focus:before {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .blue_label {
    color:#02579e;
  }

</style>
