<template>
  <div
    style="display: inline-block"
    aria-live="assertive"
  >
    <span
      v-if="showSuccess"
      style="position:absolute; left:-2000px;"
    >
      Input is valid
    </span>
    <v-icon
      class="right-icon"
      v-show="showSuccess"
      color="success"
    >
      {{ icon }}
    </v-icon>
    <span
      v-if="showError"
      style="position:absolute; left:-2000px;"
    >Input is invalid</span>
    <v-icon
      class="right-icon"
      v-show="showError"
      color="error"
    >
      error
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    formField: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'check_circle',
    },
  },
  computed: {
    showSuccess() {
      return this.formField.$dirty && !this.formField.$invalid && this.formField.$model.length > 0;
    },
    showError() {
      return this.formField.$error;
    },
  },
  name: 'FieldIcon',
};
</script>
