<i18n src='../../locales.json'></i18n>

<template>
  <v-layout
    row
    wrap
    class="investments-title mb-2"
    :class="{'is-dashbaord': isDashboard}"
  >
    <v-flex
      shrink
    />
    <svg id="investments-dashboard" width="55px" height="40px" viewBox="15 10 42 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Investments</title>
      <defs>
        <radialGradient cx="53.9154455%" cy="49.9998822%" fx="53.9154455%" fy="49.9998822%" r="73.4861117%"
                        gradientTransform="translate(0.539154,0.499999),scale(0.698177,1.000000),rotate(90.000000),scale(1.000000,1.154632),translate(-0.539154,-0.499999)"
                        id="radialGradient-311"
        >
          <stop stop-color="#EE9770" offset="0%" />
          <stop stop-color="#DC3639" offset="100%" />
        </radialGradient>
        <radialGradient cx="53.9154455%" cy="49.9998822%" fx="53.9154455%" fy="49.9998822%" r="267.901415%"
                        gradientTransform="translate(0.539154,0.499999),scale(0.248402,1.000000),rotate(81.985042),scale(1.000000,1.154632),translate(-0.539154,-0.499999)"
                        id="radialGradient-411"
        >
          <stop stop-color="#EE9770" offset="0%" />
          <stop stop-color="#DC3639" offset="100%" />
        </radialGradient>
      </defs>
      <g id="Nav-House" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Orange-Level-Icon2" transform="translate(12.000000, 11.000000)">
          <g>
            <polygon id="Rectangle" fill="#18344E" points="6.39435792 8.78385542 19.6767558 1.41036145 32.9435943 8.78385542 32.9591537 25.7838554 6.39435792 25.7838554" />
            <!-- eslint-disable-next-line vue/max-attributes-per-line -->
            <path
              d="M0.0481927711,10.1084729 L4.76703321,10.1158544 L4.76703321,27.2296386 L34.4681425,27.2296386 L34.4681425,10.1158544 L39.0481928,10.1158544 L31.4196594,6.1471682
                L31.4191736,0.143180378 L26.1423879,0.143180378 L26.1423879,3.40008673 L19.6151981,0.000722891566 L0.0481927711,10.1084729 Z M6.39156627,13.4606925 L6.39156627,9.27205608
                L19.5299903,2.41036145 L32.939759,9.41343531 L32.939759,13.4946988 L6.39156627,13.4606925 Z M6.40017731,19.5187952 L32.939759,19.5187952 L32.939759,25.7838554
                L6.39156627,25.7838554 L6.40017731,19.5187952 Z"
              id="Orange-Level-Icon" fill="url(#radialGradient-311)"
            />
            <path d="M6.89404634,13.9 L6.89055568,19.499759 L32.456329,19.499759 L32.4589221,13.9 L6.89404634,13.9 Z" id="Rectangle" stroke="#18344E" fill="url(#radialGradient-411)" />
          </g>
        </g>
      </g>
    </svg>
    <v-flex
      role="heading"
      aria-level="2"
    >
      <h2 class="heading_two">
        <a
          class="my-primerica-header"
          :aria-label="$t('L_saving_investment')"
        >
          {{ $t('L_saving_investment') }}
        </a>
      </h2>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'InvestmentsTitle',
  props: {
    isDashboard: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'

.investments-title
  .heading_two
    color: #fff;
    a
      font-size: 24px;
      font-weight: normal;
      line-height: 36px;
      color: #fff !important;
      @media $display-breakpoints.sm-only
        font-size: 22px;
      @media $display-breakpoints.xs-only
        font-size: 20px;
  &:not(.is-dashbaord) .heading_two a
    cursor: initial;
</style>
