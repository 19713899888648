<i18n src="./locales.json"></i18n>
<template>
  <v-content role="presentation" class="white_background">
    <v-container text-xs-center>
      <v-layout class="widthForContent">
        <v-flex text-xs-center mt-4 mb-4>
          <v-layout row wrap>
            <v-flex xs12 class="font-size--20 font-weight-bold">
              <div>
                {{ $t('L_enable_face_id_or_fingerprint_id_later') }}
              </div>
            </v-flex>
            <v-flex xs12 mt-4 pt-4>
              <img
                height="257"
                width="236"
                src="@/assets/ErrorBiometrics.svg"
                class="mr-2"
                alt="Mobile phone biometrics enable later via account settings"
              >
            </v-flex>
          </v-layout>
          <div class="mx-auto mb-xs-12 mt-4 " :class="$vuetify.breakpoint.xsOnly ? 'overall-div-card-smaller' : 'overall-div-card-larger'">
            <v-layout row wrap>
              <v-flex xs12 text-xs-center font-weight-regular font-size--20 ml-4 mr-4 class="regular_body_text">
                <span>{{ $t('L_you_can_enable_faceid_or_fingerprint_id_later') }}</span>
              </v-flex>
            </v-layout>
          </div>
          <v-layout row wrap text-xs-center class="outerlayout">
            <div class="innerLayout">
              <v-flex xs12 mt-4 mb-4>
                <v-btn font-size--20 block ml-4 mr-4 pl-4 pr-4 class="btn_default primary" @click="goToNextFlow">
                  {{ $t('L_ok') }}
                </v-btn>
              </v-flex>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COUNTRIES } from '@/shared/constants';
import Config from '@/shared/util/config';
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';


export default {
  name: 'ErrorBiometrics',
  components: {
    LocaleToggle,
  },
  props: {
    color: {
      type: String,
      default: '#063562',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 70,
    },
    width: {
      type: Number,
      default: 7,
    },
  },
  data: () => ({
    languagesPrefs: Config.get('languagePreferences'),
    languages: [],
    isFaceId: false,
    isTouchId: false,
  }),
  computed: {
    ...mapGetters({
      currentLocale: 'getLocale',
      hasTermsOfUseTask: 'hasTermsOfUseTask',
      hasPolicyAckTask: 'hasPolicyAckTask',
    }),
    ...mapGetters('customer', ['customerProfile']),
  },
  methods: {
    ...mapActions(['changeLocale']),
    async goToNextFlow() {
      const deeplinkroute = window.localStorage.getItem('deeplinkroute'); // ? this is a path, NOT name of a route
      let nextRoute = '';
      if (this.hasTermsOfUseTask) nextRoute = 'TermsOfUse';
      else if (this.hasPolicyAckTask) nextRoute = 'PolicyAcknowledgement';
      else {
        window.localStorage.removeItem('deeplinkroute'); // remove the deeplink route
        nextRoute = deeplinkroute || 'Dashboard'; // there are no post login tasks
      }
      this.$router.push((deeplinkroute ? { path: nextRoute } : { name: nextRoute }));
    },
    updateLocale(language) {
      this.changeLocale(language);
    },
    validateLanguagePreference() {
      if (!this.languagesPrefs || this.languagesPrefs.length === 0) {
        this.languagePrefs = ['en_US', 'en_CA'];
      }

      if (this.customerProfile.country === COUNTRIES.CANADA) {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_CA') > 0);
        if (this.currentLocale?.indexOf('_CA') === -1) {
          this.changeLocale('en_CA');
        }
      } else {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_US') > 0);
        if (this.currentLocale?.indexOf('_US') === -1) {
          this.changeLocale('en_US');
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.background {
  min-height: 100%;
  background-image: linear-gradient(to top, #607c97, #063562);
}
.white_background {
  min-height: 100%;
  background-color: white;
}
.css_locale {
  background-color: white;
}
.css_app_update {
  font-size: 20px;
  font-weight: bold;
}
.regular_body_text {
  font-size: 20px;
}
.language-icon {
  color: white !imporant;
}
.container {
  max-width: 960px !important;
}
.widthForContent {
  max-width: 600px !important;
  margin:auto;
}
.btn_default {
  min-width: 250px;
  max-width: 350px;
  margin: auto;
  height: 48px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.outerlayout {
  padding-bottom: 50px;
}
.innerLayout {
  zwidth: 50% !important;
  margin: auto !important;
  padding-bottom:300px;
}
.btn_white {
  background-color: white !important;
}
.centerProgress {
  left: calc(50% + -38px);
  top: 50vh;
  position: absolute;
}
</style>
