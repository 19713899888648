<template>
  <v-text-field
    v-model="formInput.$model"
    :id="inputId"
    :label="label || $t && $t('L_lastname') || 'Last Name'"
    :autocomplete="disableAutocomplete ? 'off' : null"
    required
    :color="color"
    :append-icon="icon"
    :error-messages="errorMessages"
    :hint="hint"
    @focus="toggleTooltip({field:'lastName', show:true})"
    :maxlength="maxlen"
    @input="onInput()"
    @blur="onBlur(); toggleTooltip({field:'lastName', show:false});"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'LastNameInput',
  components: { FieldIcon },
  extends: BaseTextField,
  props: {
    inputId: {
      type: String,
      required: false,
      default: 'first_name',
    },
    maxlen: {
      type: Number,
      required: false,
      default: 35,
    },
  },
  data: () => ({
    modelName: 'lastName',
    lastName: '',
  }),
  methods: {
    onBlur(event) {
      this.formInput.$model = this.formInput.$model.trim().replace(/\s+/g, ' ').replace(/[0-9]/g, '');
      BaseTextField.methods.onBlur.bind(this)(event);
    },
  },
};
</script>
