<i18n src='./locales.json'></i18n>

<template>
  <v-content
    class="investments"
    role="presentation"
    style="max-width:1024px; margin:auto;"
  >
    <v-container>
      <v-layout fill-height row wrap>
        <v-flex xs12>
          <InvestmentsTitle :is-dashboard="false" />
          <v-tabs
            v-model="selectedTab"
            :class="{'calculator': calculatorEnabled, 'has-investments': hasInvestments}"
          >
            <v-tab key="my-investments" href="#my-investments" v-if="hasInvestments" id="my_investment" :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">
              <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_my_investments') }}</span>
            </v-tab>
            <v-tab key="retirement-calculator" href="#retirement-calculator" v-if="calculatorEnabled" id="retirement_calculator">
              <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_guidance_calculator') }}</span>
            </v-tab>
            <v-tab key="learn-more" href="#learn-more" id="learn_more" :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">
              <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_learn_more') }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab" touchless>
            <LoadingIndicator :loading="investmentsLoading" />
            <v-tab-item v-if="hasInvestments" key="my-investments" value="my-investments">
              <MyInvestments />
            </v-tab-item>
            <v-tab-item v-if="calculatorEnabled" key="retirement-calculator" value="retirement-calculator">
              <GuidanceCalculator @open="viewMyInvestments" />
            </v-tab-item>
            <v-tab-item key="learn-more" value="learn-more">
              <LearnMore />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { FEATURE_FLAGS } from '@/shared/constants';
import { mapActions, mapGetters } from 'vuex';
import InvestmentsTitle from '@/modules/home/dashboard/components/Investments/InvestmentsTitle.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import LearnMore from './components/LearnMore.vue';
import MyInvestments from './components/MyInvestments.vue';
import GuidanceCalculator from './components/GuidanceCalculator.vue';

export default {
  name: 'InvestmentsCard',
  components: {
    LearnMore,
    MyInvestments,
    InvestmentsTitle,
    LoadingIndicator,
    GuidanceCalculator,
  },
  data() {
    return {
      selectedTab: 'my-investments',
    };
  },
  computed: {
    ...mapGetters(['containsFeature', 'features']),
    ...mapGetters('customer', ['investments', 'loading']),
    calculatorEnabled() {
      return this.containsFeature(FEATURE_FLAGS.RETIREMENT_CALCULATOR);
    },
    hasInvestments() {
      return this.investments.summary.totalValue > 0;
    },
    investmentsLoading() {
      return this.loading.investments;
    },
  },
  created() {
    if (!this.hasInvestments) {
      this.getInvestments();
    }
  },
  methods: {
    ...mapActions('customer', ['getInvestments']),
    viewMyInvestments() {
      this.selectedTab = 'my-investments';
    },
  },
  watch: {
    investmentsLoading(newData) {
      if (!newData) {
        if (this.hasInvestments) {
          this.selectedTab = 'my-investments';
        } else {
          this.selectedTab = 'retirement-calculator';
        }
      }
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'

.investments
  padding: 24px 0 0 !important;
  .v-tabs
    &.calculator, &.has-investments
      .v-tabs__div, .v-tabs__slider-wrapper
        width: 50% !important;
    &.calculator.has-investments
      .v-tabs__div, .v-tabs__slider-wrapper
        width: 33.333% !important;
    .v-tabs__div, .v-tabs__slider-wrapper
      width: 100% !important;
      max-width: inherit;
    .v-tabs__slider-wrapper .accent
      height: 4px;
      background-color: #063562 !important;
</style>
