/**
 * The type of vault
 * ? https://ionic.io/docs/identity-vault/getting-started-vue#using-different-vault-types
 */
export const VAULT_TYPE = Object.freeze({
  /**
   * No additional security is required in the app as long as the device was unlocked with a secure method.
   * This vault type cannot be locked/unlock so methods like `lock`, `unlock`, `isLocked`, and `lockAfterbackgrounded` have no effect.
   * On iOS, this vault is synchronized via iCloud or new device setup.
   * */
  SECURE_STORAGE: 'SecureStorage',
  /**
   * Uses additional device features to add an additional layer of security while the user is in the app.
   * On iOS, this vault is NOT synchronized via iCloud or new device setup.
   * */
  DEVICE_SECURITY: 'DeviceSecurity',
  /**
   * User will set a custom passcode that will be used to access the vault.
   * On iOS, this vault is NOT synchronized via iCloud or new device setup.
   * */
  CUSTOM_PASSCODE: 'CustomPasscode',
  /** Data will persist only while the application is in memory.
   * This vault type cannot be locked/unlock so methods like `lock`, `unlock`, `isLocked`, and `lockAfterbackgrounded` have no effect.
   * On iOS, this vault is NOT synchronized via iCloud or new device setup.
   */
  IN_MEMORY: 'InMemory',
});

/**
 * The meaning of the error code in the thrown [VaultError] see https://ionic.io/docs/identity-vault/interfaces/vaulterror.
 */
export const VAULT_ERROR_CODES = Object.freeze({
  /**
   * An unknown error happened.
   */
  Unknown: 0,
  /**
   * The operation failed because the vault was locked.
   */
  VaultLocked: 1,
  /**
   * The operation failed because the vault was unavailable.
   * The most likely cause of this error is that a vault has not been configured.
   */
  VaultUnavailable: 2,
  /**
   * The operation failed because the some of the vault provided arguments were invalid.
   */
  InvalidArguments: 3,
  /**
   * The credentials were invalidated. This can happen when a user changes biometrics or passcode.
   */
  InvalidatedCredential: 4,
  /**
   * Biometric security is unavailable due to a passcode not being set up at the system level.
   * In order to use biometric identification on the device a system level passcode must be set up by the user.
   */
  SecurityNotAvailable: 5,
  /**
   * User authentication failed. This can occur when the user has denied use of biometrics or force closed the app during a biometric prompt.
   * You can call the Device.isBiometricsAllowed method to determine if the user has denied access to Biometrics.
   */
  AuthFailed: 6,
  /**
   * Too many failed authentication attempts made against the custom passcode vault, so the vault was cleared and user will need to login again.
   */
  TooManyFailedAttempts: 7,
  /**
   * The user cancelled the native authentication dialog.
   *
   * **Note**: On iOS, this error code will also be thrown in the event that the native authentication dialog fails as a result of too many failed attempts.
   * The user will be forced to cancel the dialog, triggering this error code.
   *
   * On Android, this error also will be thrown if using {DeviceSecurityType.SystemPasscode} or {DeviceSecurityType.Both} in the event of too many failed attempts.
   * Its currently not possible to get the nature of failures from the native authentication dialog, and similar to iOS, the user will be forced to cancel the dialog, triggering this error code.
   */
  UserCanceledInteraction: 8,
  /**
   * The user provided mismatched passcodes.
   */
  MismatchedPasscode: 9,
  /**
   * The operation requires passcode to be setup but it isn't set yet. Call {Vault.setCustomPasscode} to set it.
   */
  MissingPasscode: 10,
  /**
   * The operation failed because the application tried to unlock the vault with passcode authentication,
   * but the vault is not configured to allow passcode authentication.
   */
  PasscodeNotEnabled: 11,
  /**
   * The key was not found. This can happen when a user changes biometrics or passcode.
   */
  KeyNotFound: 12,
  /**
   * The operation failed because biometric authentication is not enabled.
   * This can occur when biometrics is not supported by the device
   * or when biometrics has not been configured for the device or vault.
   */
  BiometricsNotEnabled: 13,
  /**
   * ignore
   */
  InvalidAuthMode: 14,
  /**
   * Biometrics have not been authed yet (Android only)
   */
  MissingBiometrics: 15,
  /**
   * {DeviceSecurityType.SystemPasscode} for {DeviceSecurityType} not available on this Android device.
   */
  AndroidSystemPasscodeUnavailable: 16,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   * **Note:** This error will never be thrown if using {DeviceSecurityType.SystemPasscode} or {DeviceSecurityType.Both}.
   * Its currently not possible to get the nature of failures from the native authentication dialog, so the only error that would be thrown is {VaultErrorCodes.UserCanceledInteraction} as the user
   * is forced to cancel the prompt.
   *
   */
  AndroidBiometricsLockedOut: 17,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   */
  iOSBiometricsLockedOut: 18,
  /**
   * An unexpected error has occurred in the Android keystore.
   * Some android devices (such as the Samsung S10) will return this error code when
   * a maximum number of failed attempts is reached and the device must be restarted.
   * See: [Max Uses Per Boot](https://source.android.com/security/keystore/tags#max_uses_per_boot)
   *
   */
  AndroidUnexpectedKeystoreError: 19,
});

/**
 * When type is set to 'DeviceSecurity', determines which aspects of the device to use to secure the vault.
 * Note: Android only supports `SystemPasscode` on Android 11 and greater.
 * default `Both`
 */
export const DEVICE_SECURITY_TYPE = Object.freeze({
  /** Will allow a system passcode to secure the vault. On Android, only supported on Android 11 and greater. */
  SYSTEM_PASSCODE: 'SystemPasscode',
  /** WIll allow biometric hardware to secure the vault. */
  BIOMETRICS: 'Biometrics',
  /** WIll allow both SystemPasscode or Biometrics as an option to secure the vault. */
  BOTH: 'Both',
  /** No biometric security option will be used. */
  NONE: 'None',
});

/**
 * For Android, when [type] is `VAULT_TYPE.DEVICE_SECURITY` and [deviceSecurityType] is `DEVICE_SECURITY_TYPE.BOTH`, this options specifies if you want to
 * prefer a Strong Cryptographic Vault or the System Passcode fallback when they both aren't available.
 * default `StrongVault`
 */
export const ANDROID_BIOMETRIC_CRYPTO_PREFERENCE = Object.freeze({
  /** Prefer to use a strong cryptographic vault. */
  STRONG_VAULT: 'StrongVault',
  /** Prefer the system passcode fallback. */
  SYSTEM_PASSCODE: 'SystemPasscode',
});

/**
 * Possible app biometric permissions states on iOS (always `granted` on Android and on iOS with TouchID).
 */
export const BIOMETRIC_PERMISSION_STATE = Object.freeze({
  /** App has been granted permission to use FaceID */
  GRANTED: 'granted',
  /** App has been denied permission to use FaceID */
  DENIED: 'denied',
  /** User has not yet been prompted to allow FaceID */
  PROMPT: 'prompt',
});

export const UNLOCK_MODE = Object.freeze({
  BIOMETRICS: 'Biometrics',
  BIOMETRICS_WITH_PASSCODE: 'BiometricsWithPasscode',
  SYSTEM_PASSCODE: 'SystemPasscode',
  SECURE_STORAGE: 'SecureStorage',
});

export const DEVICE_ERROR_CODES = Object.freeze({
  /**
   * An unknown error happened.
   */
  Unknown: 0,
  /**
   * The operation failed because some of the vault provided arguments were invalid.
   */
  InvalidArguments: 1,
  /**
   * The user cancelled the native authentication dialog.
   */
  UserCanceledInteraction: 2,
  /**
   * Biometric security is unavailable due to a passcode not being set up at the system level.In order to use biometric identification on the device a system level passcode must be set up by the user.
   */
  SecurityNotAvailable: 3,
  /**
   * User Authentication failed,
   */
  AuthFailed: 4,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
  */
  BiometricsLockedOut: 5,
  /**
   * The operation failed because biometric authentication is not enabled.This can occur when biometrics is not supported by the device or when biometrics has not been configured
   * for the device or vault.
  */
  BiometricsNotEnabled: 6,
});
