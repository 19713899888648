
<i18n src='../../../locales.json'></i18n>
<template>
  <div class="paynow-section">
    <v-flex
      text-xs-center
      pb-2
      id="edit_paymthd_stp1_panel"
    >
      <v-divider />
      <v-flex
        mt-4
        xs12
      >
        <span
          role="heading"
          aria-level="2"
          class="card-title link-"
        >{{ $t('L_select_your_new_payment') }}</span>
      </v-flex>
      <v-layout
        justify-center
      >
        <v-flex
          md9
          sm12
          xs12
          pl-4
          pr-4
          pb-4
        >
          <v-card-text>
            <v-layout
              justify-center
              wrap
              xs12
            >
              <template
                v-for="(item, index) in paymentMethods"
              >
                <v-flex
                  :key="index"
                  xs12
                  :class="(paymentMethods.length===3 && index === 0) ? 'md12': 'md4'"
                  sm5
                  mt-3
                  mx-4
                  mt-4
                >
                  <v-layout
                    class="row wrap justify-space-around"
                  >
                    <v-flex
                      :class="(paymentMethods.length===3 && index === 0) ? 'md5': ''"
                    >
                      <PaymentOptionCard
                        @click.native="newBillingMode = item.billMode"
                        :base-id="'edit_paymethod_'+item.billForm+'_'+item.billMode"
                        :selected="newBillingMode === item.billMode"
                        :amount="item.amount"
                        :label="$t(item.name)"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
          </v-card-text>
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <v-btn
          id="edit_paymthd_btn_cancel"
          class="pl-4 pr-4"
          color="secondary"
          @click="$emit('cancel')"
        >
          <h3 class="heading_three font-weight-medium font-size-md">
            {{ $t('L_cancel') }}
          </h3>
        </v-btn>
        <v-btn
          id="edit_paymthd_btn_continue"
          class="pl-4 pr-4"
          :class="bindClass"
          color="primary"
          :disabled="badData"
          @click="goToStepTwo"
        >
          <h3
            class="heading_three font-weight-medium font-size-md"
            :class="bindClass"
          >
            {{ $t('L_edit_paymethod_continue') }}
          </h3>
        </v-btn>
      </v-flex>
    </v-flex>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PaymentOptionCard from '../components/PaymentOptionCard.vue';

export default {
  name: 'StepOne',
  components: {
    PaymentOptionCard,
  },
  data: () => ({
    newBillingMode: '',
  }),
  computed: {
    ...mapGetters('policy', {
      policy: 'currentPolicy',
    }),
    badData() {
      return this.newBillingMode === '';
    },
    isQuarterly() {
      return this.newBillingMode === '03';
    },
    isSemiAnnual() {
      return this.newBillingMode === '06';
    },
    isAnnual() {
      return this.newBillingMode === '12';
    },
    bindClass() {
      return `payment-method-${this.isQuarterly ? 'quarterly' :
        `${this.isSemiAnnual ? 'semi-annual' :
          `${this.isAnnual ? 'annual' : 'monthly-ach'}`}`}`;
    },
    paymentMethods() {
      const paymentMethodsList = [
        {
          billForm: 'B2', billMode: '01', name: 'L_monthly_bank_draft', amount: this.policy?.paymentInfo?.monthlyPremium,
        },
        {
          billForm: 'A1', billMode: '03', name: 'L_quarterly_bill', amount: this.policy?.paymentInfo?.quarterlyPremium,
        },
        {
          billForm: 'A1', billMode: '06', name: 'L_semiannual_bill', amount: this.policy?.paymentInfo?.semiAnnualPremium,
        },
        {
          billForm: 'A1', billMode: '12', name: 'L_annual_bill', amount: this.policy?.paymentInfo?.annualPremium,
        },
      ];
      if (this.policy.billingForm === 'A1' && this.policy.billingMode === '01') {
        return paymentMethodsList;
      }
      return paymentMethodsList.filter(eachMethod => !(eachMethod.billForm === this.policy.billingForm && eachMethod.billMode === this.policy.billingMode));
    },
  },
  mounted() {
    this.setAsDefaultSelectedBillingMode();
  },
  methods: {
    goToStepTwo() {
      try {
        const m = this.$t(this.paymentMethods?.find(e => (e.billMode === this.newBillingMode))?.name)?.replaceAll(' ', '-');
        const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${m || this.newBillingMode}_selected`);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('goToStepTwo', this.newBillingMode);
    },
    setAsDefaultSelectedBillingMode() {
      if (!(this.policy.billingForm === 'B2' && this.policy.billingMode === '01')) {
        this.newBillingMode = '01';
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
