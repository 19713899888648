import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';

let _Vue;

if (!Capacitor) {
  // see https://capacitorjs.com/docs
  throw new Error('[capacitor-plugin] cannot locate Capacitor');
}

// Get the name of the Platform the app is currently running on.
// Return a value of "web", "ios", or "android".
const getPlatform = () => Capacitor.getPlatform();

// Check whether the currently running platform is native.
// This function returns a value of "true" if the app is running as a native, installed Capacitor app,
// or false if it is served via a browser or installed as a PWA.
const isNativePlatform = () => Capacitor.isNativePlatform();

// Return whether app is running on iOS
const isIos = () => Capacitor.getPlatform() === 'ios';

// Return whether app is running on Android
const isAndroid = () => Capacitor.getPlatform() === 'android';

// Return whether app is running on Web
const isWeb = () => Capacitor.getPlatform() === 'web';

// see https://capacitorjs.com/docs/apis/browser#openoptions
const openBrowser = async options => Browser.open(options);

// Return information about the app. see https://capacitorjs.com/docs/apis/app
const getInfo = () => App.getInfo();

// TODO: crete a unit test
const getDeviceInfo = () => Device.getInfo();

// TODO: crete a unit test
const getDeviceId = () => Device.getId();

// Exit App
const exitOutOfApp = async () => App.exitApp();

// Return app version. On iOS:CFBundleShortVersionString. On Android: versionName.
const getAppVersion = () => App.getInfo().then(i => i.version);

// see https://capacitorjs.com/docs/apis/preferences
const getPreference = async (key) => {
  const { value } = await Preferences.get({ key });
  return value; // ? If a value was not previously set or was removed, value will be null.
};

// see https://capacitorjs.com/docs/apis/preferences
const setPreference = async ({ key, value }) => (Preferences.set({ key, value }));

// see https://capacitorjs.com/docs/apis/preferences
const removePreference = async key => (Preferences.remove({ key }));

// TODO add unit test
const addListenerOnAppPause = async cb => App.addListener('pause', () => {
  cb();
});

// TODO add unit test
const addListenerOnAppResume = async cb => App.addListener('resume', () => {
  cb();
});

// see https://capacitorjs.com/docs/apis/browser#open
const _wrapper = {
  Capacitor,
  getPlatform,
  isNativePlatform,
  isIos,
  isAndroid,
  isWeb,
  openBrowser,
  getDeviceId,
  getInfo,
  getDeviceInfo,
  exitOutOfApp,
  getAppVersion,
  getPreference,
  setPreference,
  removePreference,
  addListenerOnAppPause,
  addListenerOnAppResume,
};

export const CapacitorFacade = _wrapper;

export default function install(Vue) {
  if (install.installed && _Vue === Vue) return;
  install.installed = true;

  _Vue = Vue;

  if (Vue.prototype.$pc2capacitor !== _wrapper) {
    Vue.prototype.$pc2capacitor = _wrapper;
  }
}
