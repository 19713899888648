import { render, staticRenderFns } from "./InvestmentsCard.vue?vue&type=template&id=52c78eaa"
import script from "./InvestmentsCard.vue?vue&type=script&lang=js"
export * from "./InvestmentsCard.vue?vue&type=script&lang=js"
import style0 from "./InvestmentsCard.vue?vue&type=style&index=0&id=52c78eaa&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fhome%2Fdashboard%2Fcomponents%2FInvestments%2FInvestmentsCard.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports