<i18n src="../locales.json"></i18n>
<template>
  <v-content class="pt-0">
    <v-layout row wrap>
      <v-flex xs12 mb-4>
        <Message
          v-if="responseError"
          :show-msg="responseError"
          :type="'error'"
          :link-label="'.'"
          :message="$t('L_error_occured_while_saving')"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap mb-4>
      <v-flex xs12 font-weight-bold>
        <div class="firstLabel">
          {{ $t('L_confirm_your_new_payment_withdrawal_day') }}
        </div>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="blue-background pl-4 pr-4 pt-4 pb-4 mb-4">
      <v-flex xs12 mb-4 font-weight-bold font-size--16>
        {{ $t('L_of_each_month', { date: getSelectedDate }) }}
        <span>
          <v-btn color="secondary" class="change_button" @click="goToWDStepOne">
            {{ $t('L_change') }}
          </v-btn>
        </span>
      </v-flex>
      <v-flex xs12 font-size--14 mb-4 v-if="dateIssueNextPayDateEarly === 1">
        {{ $t('L_because_your_current_draft_day_is_within_the_next') }}
      </v-flex>
      <v-flex xs12 font-size--14 mb-4 v-if="dateIssueNextPayDateEarly === 1 || dateIssueNextPayDateEarly === 2">
        {{ $t('L_going_forward_your_next_payment_will_be_withdrawn_on_the', { dayth: getSelectedDate }) }}
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="dateIssueNextPayDateEarly === 1" class="warning-box mt-4 mb-4 pt-4 pb-4">
      <v-flex xs12>
        <span class="pr-2 mb-4">
          <v-icon :color="'#f39c12'" large class="font-size--20">
            warning
          </v-icon>
        </span>
        <span class="font-size--14" v-html="getConfirmationData" />
      </v-flex>
    </v-layout>
    <!-- Button -->
    <v-layout row wrap class="justify-end mt-4 pt-4">
      <v-flex xs0 sm0 md4>
        &nbsp;
      </v-flex>
      <v-flex xs12 sm12 md4 pr-4>
        <v-btn
          block
          color="secondary"
          @click="cancel"
          class="maxwidthButton"
        >
          {{ $t('L_cancel') }}
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 md4 pr-4>
        <v-btn
          block
          class="white--text mb-3 maxwidthButton"
          @click="goToWDStepThree"
          :disabled="confirmDisabled"
          color="primary"
        >
          {{ $t('L_confirm') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { currency, formatDate } from '@/shared/util/formatters';
import moment from 'moment';
import Message from '../../../../components/Message.vue';

export const notSameDigits = (value) => {
  const regexp = /(\d)\1{7}/;
  return !regexp.test(value);
};

export const letterSpaceLetter = (value) => {
  const regexp = /^[a-zA-Z]+[\sa-zA-Z-.]{0,}[a-zA-Z]+$/;
  return regexp.test(value);
};

export default {
  name: 'WDStepTwo',
  components: { Message },
  props: {
    daySelected: {
      type: String,
      required: true,
      default: '1',
    },
    dateIssueNextPayDateEarly: {
      type: Number,
      required: true,
      default: -1,
    },
    dateForNextPayment: {
      type: String,
      required: true,
      default: '1',
    },
  },
  data: () => ({
    persistent: true,
    dialog: false,
    confirmDisabled: false,
    responseError: false,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['policies', 'currentPolicy', 'routeNameToNavigateBack', 'selectedPolicyIndex', 'bankNameResponse', 'paymentData']),
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    getConfirmationData() {
      let x = this.$t('L_by_clicking_confirm_you_are_agreeing_to_have_the_current_amount_due_of', { amount: this.formattedTotalPremium, account_num: this.getMaskedAccountNum });
      x += ' ';
      return x;
    },
    getSelectedDate() {
      let x = '';
      x = this.daySelected;
      if (this.daySelected === '1' || this.daySelected === '21') {
        x += 'st ';
      } else if (this.daySelected === '2' || this.daySelected === '22') {
        x += 'nd ';
      } else if (this.daySelected === '3' || this.daySelected === '23') {
        x += 'rd ';
      } else {
        x += 'th ';
      }
      return x;
    },
    formattedTotalPremium() {
      return currency(`${(+this.currentPolicy?.modePremium || 0) + (+this.currentPolicy?.data?.annuityModePrem || 0)}`, 2);
    },
    getMaskedAccountNum() {
      let x = this.currentPolicy?.paymentInfo?._accountNumber || '';
      x = x.replaceAll('*', '');
      return x;
    },
    isDateWithinThreeDaysOfDraftDate() {
      // TODO check if this is right place to see withdrawal date
      const currentWDDate = formatDate(this.currentPolicy?.nextDraftDate, this.dateFormat);
      let day = 0;
      if (currentWDDate.length > 6) {
        // For MM/DD/YYYY format
        day = parseInt(currentWDDate.substr(3, 2), 10);
        // If we have DD/MM/YYYY use substr(0,2)
      }

      let iDaySelected = 0;
      if (this.daySelected !== null && this.daySelected !== '') {
        iDaySelected = parseInt(this.daySelected, 10);
      }
      return iDaySelected - day <= 3;
    },
    getNextPaymentDate() {
      // Returns the month day not week day
      const y = moment().date();
      return y;
    },
    isCanadaPolicy() {
      return this.policyData?.isCanadaPolicy;
    },
  },
  methods: {
    ...mapActions('policy', ['setMonthlyWithDrawalDay']),
    ...mapActions('policy', ['getPolicyOverview']),
    goToWDStepOne() {
      this.$emit('goBackToWDStepOne');
      try {
        const i = this.$dynatrace.enterAction('ReviewWithdrawalDay_ChangeDay_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    },
    async goToWDStepThree() {
      if (this.confirmDisabled) {
      } else {
        try {
          const i = this.$dynatrace.enterAction('ReviewWithdrawalDay_Confirm_Selected');
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
        this.confirmDisabled = true;
        let tDaySelected = this.daySelected;
        if (tDaySelected.length === 1) {
          tDaySelected = `0${tDaySelected}`;
        }
        const response = await this.setMonthlyWithDrawalDay(tDaySelected);
        if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
          this.responseError = false;
          await this.$store.dispatch('policy/getPolicies');
          await this.$store.dispatch('policy/getPolicyOverview');
          this.$emit('goToWDStepThree');
          this.confirmDisabled = false;
          // WD Confirmation Page Displayed
          try {
            const i = this.$dynatrace.enterAction('WithdrawalDayConfirmation_PageDisplayed');
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
          // Successfully done
          try {
            const i = this.$dynatrace.enterAction('ChangeWithdrawalDay_stop');
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
        } else {
          this.responseError = true;
          this.confirmDisabled = false;
          try {
            const errorCodeDescription = `${response?.status}_${response?.ReturnMessage}`;
            const i = this.$dynatrace.enterAction(`WithdrawalDayConfirmation_Failure_${errorCodeDescription}`);
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
        }
      }
    },
    cancel() {
      this.$emit('cancelDialog');
      try {
        const i = this.$dynatrace.enterAction('ReviewWithdrawalDay_Cancel_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    },
  },
};
</script>

<style>
.warning-box {
  background-color : #fdf3e2 !important;
  border-top: 4px solid #f39c12;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  padding: 2px 10px 4px 10px;
  a {
    font-size: 16px;
  }
}
.bg-light-grey {
  background-color: #f5f5f5;
}
.change_button {
  height: 24px;
  background-color: #b4c9db !important;
  color: #02579e !important;
  font-size: 12px;
}
.blueBox {
  margin-left: 10px;
  padding: 16px 24px 24px 24px;
  border-top: 8px solid #3399ff;
  border-left: 2px solid #3399ff;
  border-right: 2px solid #3399ff;
  border-bottom: 2px solid #3399ff;
  background-color: #e2f0fe;
}
.gray-border-box {
  border: 2px solid #e3e3e3;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  background-color: #ffffff;
}
.green-border-box {
  border: 2px solid #39ac4b;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  background-color: #d8f8dd;
}
.greenCheckRight {
  float: right;
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.blueText {
  color: #0c6dca;
}
.LRBottom {
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}
.bottomBorder {
  border-bottom: 2px solid black;
}
.emptyLine {
  height: 50px;
}
.emptyLine2{
  height: 30px;
}

.checkBlueText {
  color: #385d81;
}
.pLpR50 {
  padding-left: 50px;
  padding-right: 50px;
}
.spaceBelow10 {
  margin-bottom: 10px;
}
.spaceToRight {
  margin-right: 15px !important;
}
.maxwidthButton {
  max-width: 300px;
}

.maxwidthButton2 {
  max-width: 225px;
}
.centerText {
  margin: auto;
}
#bank_name {
  color: #000000 !important;
}
label.v-label.v-label--active.v-label--is-disabled.theme--light {
  color: #000000 !important;
}
.mouseCursor {
  cursor: pointer;
}
.blackText{
  color: #000000 !important;
}
.checkImg {
  width: 500px;
  margin: auto;
}
.mobileCheckImg {
  width: 224px;
  margin: auto;
}
.centerButton {
  margin: auto;
}
.labelBankName {
  color: rgba(0,0,0,0.54);
  font-size: 12px;
}
.valueBankName {
  font-size: 16px;
  height: 24px;
  margin-bottom: 64px;
}
.clickable {
  cursor: pointer;
}
.blue_label{
  color: #02579e;
}
.gray-box {
  background-color: darkgray !important;
  cursor: default !important;
}
</style>
