<i18n src='./locales.json'></i18n>

<template>
  <v-layout
    row
    wrap
    class="mb-2"
  >
    <v-flex
      shrink
    >
      <img alt="Debt Solutions Icon" src="../../../assets/debt-solutions-icon.svg">
    </v-flex>
    <v-flex>
      <h2 class="inherit">
        <a
          href="JavaScript:void(0);"
          :aria-label="$t('L_debt_solutions') + '. Currently unavailable option'"
          class="my-primerica-header heading_two disabled not-live-link"
        >{{ $t('L_debt_solutions') }}</a>
      </h2>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'DebtSolutionsTitleSection',
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'

a.heading_two
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
  color: #ffffff !important;
  @media $display-breakpoints.sm-only
    font-size: 22px;
  @media $display-breakpoints.xs-only
    font-size: 20px;
</style>
