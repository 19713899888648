<template>
  <div class="house-svg-main">
    <svg :width="`${styles.width[navType]}`" :height="`${styles.height[navType]}`" viewBox="5 0 176 113" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>My Financial House</title>
      <defs>
        <radialGradient
          cx="53.9153768%"
          cy="50%"
          fx="53.9153768%"
          fy="50%"
          r="104.923692%"
          gradientTransform="translate(0.539154,0.500000),scale(0.195489,1.000000),rotate(84.886701),scale(1.000000,3.283291),translate(-0.539154,-0.500000)"
          :id="`radialGradient-1${navType}`"
        >
          <stop stop-color="#32A7E2" offset="0%" />
          <stop stop-color="#176FC2" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="53.9153768%"
          cy="50%"
          fx="53.9153768%"
          fy="50%"
          r="104.923692%"
          gradientTransform="translate(0.539154,0.500000),scale(0.195489,1.000000),rotate(84.886701),scale(1.000000,3.283291),translate(-0.539154,-0.500000)"
          :id="`radialGradient-2${navType}`"
        >
          <stop stop-color="#EF9870" offset="0%" />
          <stop stop-color="#DC363A" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="42.4572173%"
          fx="50%"
          fy="42.4572173%"
          r="328.895338%"
          gradientTransform="translate(0.500000,0.424572),scale(0.195489,1.000000),rotate(74.894768),scale(1.000000,1.064158),translate(-0.500000,-0.424572)"
          :id="`radialGradient-3${navType}`"
        >
          <stop stop-color="#32A7E2" offset="0%" />
          <stop stop-color="#176FC2" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="53.9153768%"
          cy="50%"
          fx="53.9153768%"
          fy="50%"
          r="298.344494%"
          gradientTransform="translate(0.539154,0.500000),scale(0.195489,1.000000),rotate(90.000000),scale(1.000000,1.154632),translate(-0.539154,-0.500000)"
          :id="`radialGradient-4${navType}`"
        >
          <stop stop-color="#EF9870" offset="0%" />
          <stop stop-color="#DC363A" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="201.991679%"
          gradientTransform="translate(0.500000,0.500000),scale(0.345865,1.000000),
          rotate(88.517779),scale(1.000000,1.063443),translate(-0.500000,-0.500000)"
          :id="`radialGradient-5${navType}`"
        >
          <stop stop-color="#D8D8D8" offset="0%" />
          <stop stop-color="#D8EDFF" offset="100%" />
        </radialGradient>
        <path
          d="M8.5,57 C4.375,57 1,60.375 1,64.5 C1,68.625 4.375,72 8.5,72 C12.625,72 16,68.625 16,64.5 C16,60.375
          12.625,57 8.5,57 Z M6.65430198,69 L2.8,65.2512173 L3.8810847,64.1997295 L6.65791764,66.900541 L12.5189153,61.2
          L13.6,62.2514878 L6.65430198,69 Z"
          id="path-1"
        />
        <path
          d="M8.5,1 C4.375,1 1,4.375 1,8.5 C1,12.625 4.375,16 8.5,16 C12.625,16 16,12.625 16,8.5 C16,4.375 12.625,
          1 8.5,1 Z M6.65430198,13 L2.8,9.25121731 L3.8810847,8.19972949 L6.65791764,10.900541 L12.5189153,5.2 L13.6,
          6.25148783 L6.65430198,13 Z"
          id="path-3"
        />
        <path
          d="M8.5,29.5 C4.375,29.5 1,32.875 1,37 C1,41.125 4.375,44.5 8.5,44.5 C12.625,44.5 16,41.125 16,37 C16,32.875
          12.625,29.5 8.5,29.5 Z M6.65430198,41.5 L2.8,37.7512173 L3.8810847,36.6997295 L6.65791764,39.400541
          L12.5189153,33.7 L13.6,34.7514878 L6.65430198,41.5 Z"
          id="path-5"
        />
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Empty-House">
          <g id="Floor-1" transform="translate(22.000000, 87.000000)">
            <polygon id="Rectangle-Copy-2" fill="#1C5687" points="0 5.91748872e-14 133 0 133 26 0 26" />
            <path
              d="M131.141824,-1.42108547e-14 L131.141,0.006 L1.907,0.006 L1.90741068,24.1519337 L131.141824,24.1519337
              L131.141,0.006 L133,0.00649716693 L133,26 L0,26 L0,0.00649716693 L1.907,0.006 L1.90741068,-1.42108547e-14
              L131.141824,-1.42108547e-14 Z"
              id="Combined-Shape"
              :fill="`url(#radialGradient-1${navType})`"
            />
          </g>
          <g id="Floor-2" transform="translate(22.000000, 59.000000)">
            <rect id="Rectangle-Copy-2" fill="#553B44" x="0" y="0" width="133" height="26" />
            <path
              d="M133,0.00649501545 L133,25.9913903 L131.141,25.991 L131.141824,26 L1.90741068,26 L1.907,25.991
              L131.141,25.991 L131.141,0.006 L133,0.00649501545 Z M131.141824,0 L131.141,0.006 L1.907,0.006
              L1.907,25.991 L0,25.9913903 L0,0.00649501545 L1.907,0.006 L1.90741068,0 L131.141824,0 Z"
              id="Combined-Shape"
              :fill="`url(#radialGradient-2${navType})`"
            />
          </g>
          <g id="Floor-3" transform="translate(22.000000, 11.000000)">
            <polygon id="Fill-1" fill="#4C6883" points="133 34.1891892 66.5 2.04281037e-14 0 33.5675676 0 46 133 46" />
            <path
              d="M131.135514,34.8108108 L66.5,2.48648649 L1.86448598,34.8108108 L1.86448598,46 L0,46 L0,33.5675676
              L66.5,0 L133,33.5675676 L133,46 L131.135514,46 L131.135514,34.8108108 Z"
              id="Combined-Shape"
              fill="#EEEEEE"
            />
          </g>
          <polygon
            id="Roof-stroke"
            fill="#5680AF"
            points="176 44 141.573828 26.7363778 141.571641 0.61971831 117.758317 0.61971831 117.758317 14.7870895
            88.3022912 2.53666415e-14 0 43.9680038 13.5659178 44 88.3022912 6.81690141 162.891049 44"
          />
          <polygon
            id="Roof"
            fill="#002C56"
            points="172 45 139.524164 28.7295157 139.524164 3.61764706 119.539033 3.61764706 119.539033 18.6958514
            88.3122677 3 4 45 17.739777 45 88.3122677 9.79411765 158.884758 45"
          />
          <g v-if="termLifeActive" id="term_life">
            <rect id="Floor-1" :fill="`url(#radialGradient-3${navType})`" x="22" y="87" width="133" height="26" />
          </g>
          <g v-if="investmentsActive" id="investments">
            <rect id="Floor-2" :fill="`url(#radialGradient-4${navType})`" x="22" y="59" width="133" height="26" />
          </g>
          <g v-if="debtSolutionsActive" id="debt_solutions">
            <polygon id="Floor-3" :fill="`url(#radialGradient-5${navType})`" points="155 44.5675676 88.5 11 22 44.5675676 22 57 155 57" />
          </g>
        </g>
        <g v-if="navType === 0" id="house_active_checks" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(164.000000, 38.000000)">
          <g id="Group-2">
            <circle id="Oval" fill="#18344E" cx="8.5" cy="64.5" r="8.5" />
            <circle id="Oval" fill="#18344E" cx="8.5" cy="37" r="8.5" />
            <circle id="Oval" fill="#18344E" cx="8.5" cy="8.5" r="8.5" />
            <g id="term_wrap">
              <mask id="term_check" fill="white">
                <use xlink:href="#path-1" />
              </mask>
              <use id="term_circ" :fill="termLifeActive ? '#FFFFFF' : 'gray'" fill-rule="nonzero" xlink:href="#path-1" />
            </g>
            <g id="invest_wrap">
              <mask id="invest_check" fill="white">
                <use xlink:href="#path-5" />
              </mask>
              <use id="invest_circ" :fill="investmentsActive ? '#FFFFFF' : 'gray'" fill-rule="nonzero" opacity="0.997419085" xlink:href="#path-5" />
            </g>
            <g id="debt_wrap">
              <mask id="debt_term" fill="white">
                <use xlink:href="#path-3" />
              </mask>
              <use id="debt_circ" :fill="debtSolutionsActive ? '#FFFFFF' : 'gray'" fill-rule="nonzero" xlink:href="#path-3" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HouseMainSVG',
  props: {
    debtSolutionsActive: {
      type: Boolean,
      default: false,
      required: true,
    },
    investmentsActive: {
      type: Boolean,
      default: false,
      required: true,
    },
    termLifeActive: {
      type: Boolean,
      default: false,
      required: true,
    },
    navType: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    styles: {
      width: ['176px', '65px'],
      height: ['113px', '60px'],
    },
  }),
};
</script>

<style scoped>
</style>
