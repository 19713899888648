import DomesticViolenceCard from './DomesticViolenceCard.vue';

export default {
  name: 'DomesticViolence',
  path: 'domesticviolence',
  props: true,
  component: DomesticViolenceCard,
  meta: {
    pageTitle: 'Landing.DomesticViolence',
  },
};
