import { render, staticRenderFns } from "./AddressInput.vue?vue&type=template&id=ccf2e540"
import script from "./AddressInput.vue?vue&type=script&lang=js"
export * from "./AddressInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FInputs%2FAddress%2FAddressInput.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports