<i18n src='./locales.json'></i18n>

<template>
  <div :class="$vuetify.breakpoint.width<1264? 'bannerDivLeftSideBar':'bannerDiv'">
    <v-flex xs12 class="orangeBar shiftDown">
      &nbsp;
    </v-flex>
    <v-alert
      border="top"
      colored-border
      id="spanishBanner"
      type="error"
      elevation="2"
      color="orange"
      :value="true"
    >
      <v-layout row wrap>
        <v-flex xs11 sm11 md11 lg11>
          <div class="text-color">
            {{ $t('L_the_language_displayed') }}
            <span @click="openModalSpanishBanner" class="moreInfoLink">{{ $t('L_more_information') }}</span>
          </div>
        </v-flex>
        <v-flex xs1 sm1 md1 lg1 text-xs-right mt-2>
          <span class="text-xs-right"><v-icon @click="cancelIcon" color="#333333">cancel</v-icon></span>
        </v-flex>
      </v-layout>
    </v-alert>

    <v-layout class="modalPopUp" v-if="showModalPopup">
      <div>
        <v-flex xs12 class="orangeBar2" mt-1>
          &nbsp;
        </v-flex>
        <v-layout class="largeBodyWhite">
          <v-flex xs11>
            &nbsp;
          </v-flex>
          <v-flex xs1 text-xs-right>
            <span class="text-xs-right" mr-2><v-icon @click="showModalPopup=false" class="cancelIcon">cancel</v-icon></span>
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-space-around class="largeBodyWhite">
          <v-flex xs12 pl-3 pr-3 font-weight-bold>
            {{ $t('L_en_policy_and_account_documents_disclosures_and_certain') }}
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-space-around class="largeBodyWhite" pt-4 pb-5>
          <v-flex xs12 pl-3 pr-3 font-weight-bold>
            {{ $t('L_es_policy_and_account_documents_disclosures_and_certain') }}
          </v-flex>
        </v-layout>
        <div class="largeBodyWhite borderAboveButtonSection" mt-2 mb-2>
          <v-layout text-xs-center>
            <v-flex xs12>
              <v-btn class="btnOkGotIt" @click="cancelIcon">
                {{ $t('L_en_ok_got_it') }}
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout text-xs-center pt-2 pb-4>
            <v-flex xs12>
              <span class="moreInfoLink" @click="cancelIcon" font-weight-bold>{{ $t('L_ok_understood') }}</span>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </v-layout>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'SpanishBanner',
  data: () => ({
    showModalPopup: false,
  }),
  methods: {
    ...mapActions(['updateSpanishBanner']),
    openModalSpanishBanner() {
      this.showModalPopup = true;
    },
    cancelIcon() {
      this.updateSpanishBanner(false);
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'

#spanishBanner
  zheight: 42px;
  background: #ffffff !important;
  color: black !important;
  .v-icon
    background-color: #ffffff !important;
    color: orange !important;
    zmargin-top: -12px;
.cancelIcon
  background-color: #ffffff !important;
  color: orange !important;
.moreInfoLink
  color: #063562;
.topBarForAlert
  height:5px;
  background-color: orange;
  margin-bottom:-5px;
  position:fixed;
.modalPopUp
  padding-top:50px;
  zposition: fixed;
  z-index: 2;
  width: 90%;
  margin: auto;
  background-color: #063562;
  top:35%;
  left:5%;
  bottom:5%;
  zborder: 2px solid #333333;
  height: auto;
.orangeBar
  background-color:orange;
  height:10px;
.orangeBar2
  background-color:orange;
  height:7px;
.largeBodyWhite
  background-color: #ffffff;
.bannerDiv
  position: sticky;
  z-index:1;
  top: 4rem;
  left: 238px;
  right: 0;
  overflow-y:scroll;
  overflow-x:hidden;
.bannerDivLeftSideBar
  position: sticky;
  z-index:1;
  top:4rem;
  left: 0;
  right: 0;
  overflow-y:scroll;
  overflow-x:hidden;
.cancelIcon
  color:#333333;
  background-color:#ffffff;
.borderAboveButtonSection
  border-top:1px solid #cbcbcb;
  padding-top:20px;
.btnOkGotIt
  width: 280px;
  background-color: #063562 !important;
  color: white !important;
.shiftDown
  margin-top:16px;
  margin-bottom: -7px;

</style>
