<i18n src="./locales.json"></i18n>
<template>
  <v-layout row wrap class="background-white">
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs12 class="debtStackingPlanText">
          {{ $t('L_my_debt_stacking_plan') }}
        </v-flex>
        <v-flex xs12 class="importantAssumptionText" @click="openImportantDisclosures">
          {{ $t('L_important_assumption_and_disclosure') }}
        </v-flex>
      </v-layout>
      <!--Start Top Row with Date and # of debts remaining -->
      <v-layout row wrap text-xs-center class="lightGrayBorderBottom lightGrayBorderTop">
        <v-flex v-if="slideIndex > 0" @click="goLeft" class="font-text-bold SpacingForColumnHeader  leftArrow">
          <img alt="active left arrow" class="leftArrowImage" src="../../../assets/LeftActive.svg">
        </v-flex>
        <v-flex v-else @click="goLeft" class="font-text-bold SpacingForColumnHeader  leftArrow">
          <img alt="inactive left arrow" class="leftArrowImage" src="../../../assets/LeftInactive.svg">
        </v-flex>
        <v-flex class="marginAuto bottomLayer">
          <div>
            <div v-if="showColumn1" class="SpacingForColumnHeader   lightGrayBorderLeftRight higherLayerThanLeftRightArrow dataColumnMaxWidth"
                 :class="firstDataColumn?'haveBlueBackground':'showWhiteBackground'"
            >
              <div> {{ addAMonth(slideIndex) }}</div>
              <div class="debtsRemaining">
                {{ numOfDebtsRemaining(slideIndex) }}
              </div>
            </div>

            <div v-if="showColumn2" class="SpacingForColumnHeader   lightGrayBorderLeftRight dataColumnMaxWidth"
                 :class="secondDataColumn?'haveBlueBackground':'showWhiteBackground'"
            >
              <div> {{ addAMonth(slideIndex+1) }}</div>
              <div class="debtsRemaining">
                {{ numOfDebtsRemaining(slideIndex+1) }}
              </div>
            </div>

            <div v-if="showColumn3" class="SpacingForColumnHeader   lightGrayBorderLeftRight dataColumnMaxWidth"
                 :class="thirdDataColumn?'haveBlueBackground':'showWhiteBackground'"
            >
              <div> {{ addAMonth(slideIndex+2) }}</div>
              <div class="debtsRemaining">
                <span>  <!-- v-if="getAge(getMonthYearForTodayAgeCalculation(slideIndex+2)) !== ''" -->
                  {{ numOfDebtsRemaining(slideIndex+2) }}
                </span>
              </div>
            </div>
          </div>
        </v-flex>


        <v-flex v-if="canGoRight" @click="goRight" class="font-text-bold SpacingForColumnHeader  rightArrow">
          <img alt="active right arrow" class="rightArrow" src="../../../assets/RightActive.svg">
        </v-flex>
        <v-flex v-else class="font-text-bold SpacingForColumnHeader  rightArrow">
          <img alt="inactive right arrow" class="rightArrow" src="../../../assets/RightInactive.svg">
        </v-flex>
        <div>
          <div :class="canGoRight?'seeMorePayOff':'seeMorePayOffHidden'">
            <span v-if="canGoRight" class="seeMorePayOffDates">{{ $t('L_see_more_pay_off_dates') }}</span>
          </div>
        </div>
      </v-layout>
      <!--End Top Row with Date and # of debts remaining -->


      <!-- Displays the Years Old. 2nd Row for Each Column -->
      <!--       <v-layout row wrap text-xs-center :class="checkIfDebtPaidOffOnScreen?'spaceBelowYearsOldText':''" >
        <v-flex xs1>&nbsp;</v-flex>
        <v-flex xs3 class="yearsOldVal"
                :class="(planWStacking.planItemCollection.length >= 1 && paidOffDebts.length > 0 && slideIndex == this.planWStacking.planItemCollection.length - 1) ||
                  (planWStacking.planItemCollection.length >= 1 && paidOffDebts.length === 0 && slideIndex == this.planWStacking.planItemCollection.length - 0)
                ?'haveBlueBackground':'showWhiteBackground'">
          {{ getAge(getMonthYearForTodayAgeCalculation(slideIndex)) }} <div class="yearsOld" v-if="getAge(getMonthYearForTodayAgeCalculation(slideIndex)) !== ''">Years Old</div></v-flex>
        <v-flex xs3 class="yearsOldVal"
                :class="(planWStacking.planItemCollection.length >= 1 && paidOffDebts.length > 0 && slideIndex == this.planWStacking.planItemCollection.length - 2) ||
                  (planWStacking.planItemCollection.length >= 1 && paidOffDebts.length === 0 && slideIndex == this.planWStacking.planItemCollection.length - 1)
                ?'haveBlueBackground':'showWhiteBackground'">
          {{ getAge(getMonthYearForTodayAgeCalculation(slideIndex+1)) }} <div class="yearsOld" v-if="getAge(getMonthYearForTodayAgeCalculation(slideIndex+1)) !== ''">Years Old</div></v-flex>
        <v-flex xs3 class="yearsOldVal"
                :class="(planWStacking.planItemCollection.length >= 1 && paidOffDebts.length > 0 && slideIndex == this.planWStacking.planItemCollection.length - 3) ||
                  (planWStacking.planItemCollection.length >= 1 && paidOffDebts.length === 0 && slideIndex == this.planWStacking.planItemCollection.length - 2)
                ?'haveBlueBackground':'showWhiteBackground'">
          {{ getAge(getMonthYearForTodayAgeCalculation(slideIndex+2)) }}
        <div class="yearsOld" v-if="getAge(getMonthYearForTodayAgeCalculation(slideIndex+2)) !== ''">Years Old  </div> </v-flex>
        <v-flex xs1>&nbsp;</v-flex>
        <v-flex xs1>&nbsp;</v-flex>
      </v-layout> -->
      <!--Column for each Debt List-->
      <v-layout row wrap zstyle="padding-top:40px;" class="marginAuto" :class="(showColumn4 && !canGoRight)?'columnFourMargin':'columnFourMarginTwo'">
        <div class="marginAuto" :class="!showColumn4 ? 'columnFourHideMargin' : ''">
          <v-flex v-if="showColumn1" class="dataColumnMaxWidth">
            <DebtStackColumn :debt-data="hideDataBeforeIndex(slideIndex)" />
          </v-flex>
          <v-flex v-if="showColumn2" class="dataColumnMaxWidth">
            <DebtStackColumn :debt-data="hideDataBeforeIndex(slideIndex+1)" />
          </v-flex>
          <v-flex v-if="showColumn3" class="dataColumnMaxWidth" :class="canGoRight ? '' : 'zpositionAbsolute'">
            <DebtStackColumn :debt-data="hideDataBeforeIndex(slideIndex+2)" />
          </v-flex>
          <v-flex v-if="showColumn4" class="hiddenData dataColumnMinWidth">
            <DebtStackColumn :debt-data="hideDataBeforeIndex(slideIndex+3)" :right-most-col="true" />
          </v-flex>
        </div>
      </v-layout>

      <v-layout row wrap class="spacingAroundMonthlyPayment">
        <v-flex xs12 text-xs-center>
          <div class="monthlyPayment">
            {{ $t('L_monthly_payment') }}
          </div>
          <div class="pt-4 pb-4">
            <span class="pillButtonTotalDebtPayment">{{ totalDebtPayment }}</span>
          </div>
          <div class="thisAmountDoesNotChange">
            {{ $t('L_this_amount_doesnot_change') }}
          </div>
        </v-flex>
      </v-layout>

      <v-layout row wrap v-if="paidOffDebts.length > 0">
        <v-flex xs12 text-xs-center font-size-twelve line-on-top>
          <div class="max-width-assume-section">
            {{ $t('L_assumes_eight_percent_rate_of_return_this_illustration') }}
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>

import { mapGetters } from 'vuex';
import Config from '@/shared/util/config';
import { openInNewWindow } from '@/mixins/openNewWindow';
import moment from 'moment';
import DebtStackColumn from './DebtStackColumn.vue';

export default {
  name: 'DebtStackingPlan',
  components: {
    DebtStackColumn,
  },
  data: () => ({
    // TODO replace this will the age of the person
    dob: '4/1/1990',
    slideIndex: 0,
    debtData: [],
  }),
  mixins: [openInNewWindow],
  computed: {
    ...mapGetters({
      hasActivePlan: 'debtsolutions/hasActivePlan',
      hasNoPlan: 'debtsolutions/hasNoPlan',
      debts: 'debtsolutions/debts',
      unpaidDebts: 'debtsolutions/unpaidDebts',
      paidOffDebts: 'debtsolutions/paidOffDebts',
      getTotalMinPaymentUsinggPaidOffDebts: 'debtsolutions/getTotalMinPaymentUsinggPaidOffDebts',
      debtGivenDebtId: 'debtsolutions/debtGivenDebtId',
      planWStacking: 'debtsolutions/planWStacking',
      twoOrMoreDebtsAndMinOneUnpaidDebt: 'debtsolutions/twoOrMoreDebtsAndMinOneUnpaidDebt',
      personInfo: 'debtsolutions/personInfo',
    }),
    firstDataColumn() {
      return (this.planWStacking?.planItemCollection?.length >= 1 && this.paidOffDebts?.length > 0 && this.slideIndex === this.planWStacking?.planItemCollection?.length - 1) ||
                  (this.planWStacking?.planItemCollection?.length >= 1 && this.paidOffDebts?.length === 0 && this.slideIndex === this.planWStacking?.planItemCollection?.length - 0);
    },
    secondDataColumn() {
      return (this.planWStacking?.planItemCollection?.length >= 1 && this.paidOffDebts?.length > 0 && this.slideIndex === this.planWStacking?.planItemCollection?.length - 2) ||
                  (this.planWStacking?.planItemCollection?.length >= 1 && this.paidOffDebts?.length === 0 && this.slideIndex === this.planWStacking?.planItemCollection?.length - 1);
    },
    thirdDataColumn() {
      return (this.planWStacking?.planItemCollection?.length >= 1 && this.paidOffDebts?.length > 0 && this.slideIndex === this.planWStacking?.planItemCollection?.length - 3) ||
                  (this.planWStacking?.planItemCollection?.length >= 1 && this.paidOffDebts?.length === 0 && this.slideIndex === this.planWStacking?.planItemCollection?.length - 2);
    },
    getColumnWidth() {
      if (this.$vuetify.breakpoint.width > 780) {
        return 'xs-3';
      } else if (this.$vuetify.breakpoint.width > 550) {
        return 'xs-5';
      }
      return 'xs-10';
    },
    showColumn1() {
      return true;
    },
    showColumn2() {
      if ((this.$vuetify.breakpoint.width >= 660)) {
        return true;
      } return false;
    },
    showColumn3() {
      if (
        /* (this.$vuetify.breakpoint.width >= 925 && this.$vuetify.breakpoint.width < 960) || */
        (this.$vuetify.breakpoint.width >= 1024)) {
        return true;
      } return false;
    },
    showColumn4() {
      if ((this.$vuetify.breakpoint.width >= 1024)) {
        return true;
      } return false;
    },
    canGoRight() {
      if (this.planWStacking?.planItemCollection === undefined && this.paidOffDebts === undefined) {
        return false;
      } else if (this.planWStacking?.planItemCollection?.length >= 1 && this.paidOffDebts?.length > 0) {
        return this.slideIndex < this.planWStacking?.planItemCollection?.length - 3; // Need to change to -1?
      } else if ((this.showColumn3 && this.slideIndex < this.planWStacking?.planItemCollection?.length - 2) ||
          (this.showColumn2 && !this.showColumn3 && this.slideIndex < this.planWStacking?.planItemCollection?.length - 1) ||
          (this.showColumn1 && !this.showColumn2 && !this.showColumn3 && this.slideIndex < this.planWStacking?.planItemCollection?.length)) {
        return true;
      }
      return false;
    },
    checkIfDebtPaidOffOnScreen() {
      if (this.planWStacking?.planItemCollection === undefined) {
        return false;
      } else if (this.slideIndex > this.planWStacking?.planItemCollection?.length - 2) {
        return true;
      }
      return false;
    },
    totalDebtPayment() {
      return this.$n(this.planWStacking?.planTotals?.newPayment, 'currencyNoCents', 'en-US');
    },
  },
  mounted() {
    let rDate = null;
    const tDate = moment(this?.personInfo?.dob, 'YYYY-MM-DDTHH:mm:ss');
    rDate = moment(tDate).format('MM/DD/YYYY');
    this.dob = rDate;
  },
  methods: {
    hideDataBeforeIndex(idx) {
      let tDebtData = this.planWStacking.planItemCollection;

      if (tDebtData !== undefined) {
        tDebtData = JSON.parse(JSON.stringify(tDebtData));

        // isAcceleratedDebt set if at least one paidOffDebts and is the 1st item
        if (tDebtData.length >= 2 && this.paidOffDebts.length > 0 && idx === 0) {
          tDebtData[0].isBlueBoxTop = true;
          tDebtData[1].isBlueBoxBottom = true;
        } else if (tDebtData.length >= 2 && idx === 0) {
          tDebtData[0].isBlueBoxTop = true;
          tDebtData[1].isBlueBoxBottom = false;
        } else if (this.paidOffDebts.length > 0) {
          tDebtData[0].isBlueBoxTop = false;
        }
        if (tDebtData.length >= 1 && this.paidOffDebts.length > 0) {
          tDebtData[0].isAcceleratedDebt = true;
        } else if (tDebtData.length >= 1 && this.paidOffDebts.length === 0) {
          tDebtData[0].isAcceleratedDebt = false;
        }

        // Only start hiding starting from index = 1 i.e 2 column since we start at 0
        // Setting the hideData
        for (let i = 0; i < tDebtData.length; i++) {
          // Accelerated Debt
          if (this.paidOffDebts.length > 0) {
            // Showing dashes with number at end
            if (idx === i) {
              tDebtData[i].showOnlyMonthlyAmount = true;
            } else {
              tDebtData[i].showOnlyMonthlyAmount = false;
            }
            // Show the Pay Off Date
            if (idx + 1 === i) {
              tDebtData[i].showPayOffDate = true;
            } else {
              tDebtData[i].showPayOffDate = false;
            }
            // Hiding the entire data
            if (i < idx) {
              tDebtData[i].hideData = true;
            } else {
              tDebtData[i].hideData = false;
            }
          } else { // First Debt
            // Showing dashes with number at end
            if (idx > 0 && idx === i + 1) {
              tDebtData[i].showOnlyMonthlyAmount = true;
            } else {
              tDebtData[i].showOnlyMonthlyAmount = false;
            }
            // Show the Pay Off Date
            if (idx === i) {
              tDebtData[i].showPayOffDate = true;
            } else {
              tDebtData[i].showPayOffDate = false;
            }
            // Hiding the entire data
            if (i + 1 < idx) {
              tDebtData[i].hideData = true;
            } else {
              tDebtData[i].hideData = false;
            }
          }
          if (i === tDebtData.length - 1) {
            tDebtData[i].islastDebt = true;
          }
        }

        // Adding data for items not in planItemCollection
        tDebtData.dontStopThereIndividualAmount = this.planWStacking.planTotals.dontStopThereIndividualAmount;
        return tDebtData;
      }
      return tDebtData;
    },
    async openImportantDisclosures() {
      await this.openInNewWindow({ url: `${Config.get('content_stack')}/blt676ecb292039a611/MISC-006.pdf`, target: '_blank' });
    },
    numOfDebtsRemaining(idx) {
      let debtRemaining = -1;
      if (this.planWStacking.planItemCollection === undefined) {
        debtRemaining = '';
      } else if (this.paidOffDebts.length > 0) {
        debtRemaining = this.planWStacking.planItemCollection.length - idx - 1;
      } else if (this.planWStacking.planItemCollection.length - idx > 0) {
        debtRemaining = this.planWStacking.planItemCollection.length - idx;
      }
      if (debtRemaining <= 0 && (idx <= (this.unpaidDebts.length + this.paidOffDebts.length))) {
        return `${this.$t('L_wow_zero_debts_remaining')}`;
      } else if (debtRemaining <= 0) {
        return '';
      } else if (debtRemaining === 1) {
        return `${this.$t('L_one_debt_remaining')}`;
      }
      return `${debtRemaining} ${this.$t('L_debts_remaining')}`;
    },
    addAMonth(idx) {
      let rDate = null;
      const tDebtData = this.planWStacking.planItemCollection;
      if (tDebtData === undefined) {
        return '';
      } else if (idx === 0) {
        return this.$t('L_today');
      } else if (idx <= tDebtData.length - 1 && this.paidOffDebts.length > 0) {
        const tObj = tDebtData[idx].projectedPayoffDate;
        // We need to add a month to the pay off date
        const tDate = moment(tObj, 'YYYY-MM-DDTHH:mm:ss-nn:00').add(1, 'M');
        rDate = moment(tDate).format('MM/YYYY');
        return rDate;
      } else if (idx <= tDebtData.length && this.paidOffDebts.length === 0) {
        const tObj = tDebtData[idx - 1].projectedPayoffDate;
        // We need to add a month to the pay off date
        const tDate = moment(tObj, 'YYYY-MM-DDTHH:mm:ss-nn:00').add(1, 'M');
        rDate = moment(tDate).format('MM/YYYY');
        return rDate;
      }
      return rDate;
    },
    getMonthYearForTodayAgeCalculation(idx) {
      let rDate = null;
      const tDebtData = this.planWStacking.planItemCollection;
      if (tDebtData === undefined) {
        return '';
      } else if (idx === 0) {
        return -1; /*
        const tObj = this.personInfo.dob;
        // We need to add a month to the pay off date
        const tDate = moment(tObj, 'YYYY-MM-DDTHH:mm:ss-nn:00');
        rDate = moment(tDate).format('MM/YYYY');
        return rDate; */
      } else if (idx <= tDebtData.length - 1) {
        const tObj = tDebtData[idx - 1].projectedPayoffDate;
        // We need to add a month to the pay off date
        const tDate = moment(tObj, 'YYYY-MM-DDTHH:mm:ss-nn:00').add(1, 'M');
        rDate = moment(tDate).format('MM/YYYY');
        return rDate;
      } else if (idx <= tDebtData.length && this.paidOffDebts.length === 0) {
        // This is to handle all debts w/o any being paid off
        const tObj = tDebtData[idx - 1].projectedPayoffDate;
        // We need to add a month to the pay off date
        const tDate = moment(tObj, 'YYYY-MM-DDTHH:mm:ss-nn:00').add(1, 'M');
        rDate = moment(tDate).format('MM/YYYY');
        return rDate;
      }
      return rDate;
    },
    goRight() {
      if (this.planWStacking.planItemCollection !== undefined && this.slideIndex < this.planWStacking.planItemCollection.length) { // - 2
        this.slideIndex += 1;
      }
    },
    goLeft() {
      if (this.slideIndex > 0) {
        this.slideIndex -= 1;
      }
    },
    // returns data starting from the given index to end
    getDataStartingFromGiven(index) {
      return this.planWStacking.planItemCollection.slice(index);
    },
    getAge(dateString) {
      if (dateString === null || dateString === undefined) {
        return '';
      } else if (dateString === -1) {
        const tDob = this.personInfo.dob;
        return moment().diff(tDob, 'years');
      }
      const tDob = this.personInfo.dob;
      if (tDob != null || tDob !== undefined) {
        const dateObj = moment(dateString, 'MM/YYYY');
        const bornObj = moment(tDob);
        const diff = dateObj.diff(bornObj, 'years');
        return diff;
      }

      return '';
    },
  },
};
</script>

<style lang="stylus" scoped>
.background-white
  background-color: #FFFFFF;
.font-text-bold
  font-weight: 600;
.hiddenData
  opacity:0.3;
.debtStackingPlanText
  font-size: 24px;
  font-weight: 600;
  padding-left: 50px;
  padding-top: 20px;
.importantAssumptionText
  padding-left: 50px;
  padding-top: 0px;
  color: #02579e;
  font-size: 14px;
  font-weight: 600;
.yearsOld
  font-size:12px;
  color:#333333;
  margin-top:-6px;
.lightGrayBorderLeftRight
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
.SpacingForColumnHeader
  height:50px;
  background-color: white;
  z-index: 1;
.lightGrayBorderBottom
  border-bottom: 1px solid #d4d4d4;
.lightGrayBorderTop
  border-top: 1px solid #d4d4d4;
.debtsRemaining
  font-size:10px;
  color: #3333333;
.yearsOldVal
  font-size: 24px;
.todayColor
  color:#333333;
.higherLayerThanLeftRightArrow
  z-index: 1;
  background-color:white;
.spaceBelowYearsOldText
  margin-bottom:40px;
.monthlyPayment
  font-size: 16px;
  color: #333333;
.thisAmountDoesNotChange
  font-size: 12px;
  color: #333333;
.pillButtonTotalDebtPayment
  border-radius: 16px;
  border: 1px solid #063562;
  color: #333333;
  font-size: #18px;
  padding: 5px 10px 5px 10px;
.showWhiteBackground
  background-color: white;
.haveBlueBackground
  background-color: #f5faff;
.spacingAroundMonthlyPayment
  margin-top:20px;
  margin-bottom:30px;
.line-on-top
  border-top: 1px solid #e7edf4;
  padding-top: 25px;
.font-size-twelve
  font-size: 12px;
.max-width-assume-section
  max-width:84%;
  margin: auto;
  padding-bottom:25px;
.dataColumnMaxWidth
  max-width:280px;
  width:280px;
  display: inline-block;
.dataColumnMinWidth
  max-width:24px;
  width: 24px;
  display: inline-block;
.arrowWidth
  width: 45px;
  max-width: 45px;
.marginAuto
  margin:auto;
.leftArrow
  height: 55px;
  margin: -1px 0 0 0;
  position: absolute;
  left: 0;
  z-index:2;
  width: 85px;
.leftArrowImage
  margin-top: -12px;
  height:86px;
  width:112px;
.rightArrow
  height: 64px;
  zmargin: 0px 0 0 -62px;
  z-index: 0;
  position: absolute;
  right: 0;
  z-index:2;
  margin-top:-1px;
.positionAbsolute
  position: absolute;
.bottomLayer
  z-index:1;
.seeMorePayOff
  font-size: 10px;
  text-align: center;
  width: 44px;
  position: absolute;
  margin-left: -84px;
  z-index: 22;
  color: #063562;
  margin-top: 0px;
  background-color: #fff;
  height: 50px;
.seeMorePayOffHidden
  font-size: 10px;
  text-align: center;
  width: 44px;
  position: absolute;
  margin-left: -84px;
  z-index: 22;
  color: #063562;
  margin-top: 0px;
  background-color: #f5faff;;
  height: 50px;
.columnFourYouDidIt
  margin-left:-255px;
.columnFourMargin
  margin-left:25px;
.columnFourMarginTwo
  margin-left:25px;
.columnFourHideMargin
  padding-right:25px;
.seeMorePayOffDates
  font-size: 8px;
  font-weight: 600;

</style>
