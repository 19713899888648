<i18n src='../locales.json'></i18n>

<template>
  <v-container fluid class="containerSize">
    <NavigationConfirmationDialog
      v-if="showNavigationConfirmationDialog"
      @confirm-navigation="navigateToRoute"
      @cancel-navigation="showNavigationConfirmationDialog = false"
    />
    <!-- My Primerica Header -->
    <v-layout row wrap mt-4 mb-4>
      <v-flex text-xs-left shrink>
        <!-- My Primerica Header -->
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="28" cy="28" r="28" fill="#D2DEE9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M32.125 10H17.625C15.6313 10 14 11.62 14 13.6V42.4C14 44.38 15.6131 46 17.6069 46H39.375C41.3687 46 43 44.38 43
          42.4V20.8L32.125 10ZM35.75 28H21.25V31.6H35.75V28ZM35.75 35.2H21.25V38.8H35.75V35.2ZM17.625 42.4H39.375V22.6H30.3125V13.6H17.625V42.4Z" fill="black"
          />
          <mask id="mask0_1135_1943" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="14" y="10" width="29" height="36">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.125 10H17.625C15.6313 10 14 11.62 14 13.6V42.4C14 44.38 15.6131 46 17.6069 46H39.375C41.3687 46 43 44.38 43
             42.4V20.8L32.125 10ZM35.75 28H21.25V31.6H35.75V28ZM35.75 35.2H21.25V38.8H35.75V35.2ZM17.625 42.4H39.375V22.6H30.3125V13.6H17.625V42.4Z" fill="white"
            />
          </mask>
          <g mask="url(#mask0_1135_1943)">
            <rect x="3" y="3" width="50" height="50" fill="#063562" />
            <mask id="mask1_1135_1943" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="3" width="50" height="50">
              <rect x="3" y="3" width="50" height="50" fill="white" />
            </mask>
            <g mask="url(#mask1_1135_1943)" />
          </g>
        </svg>
      </v-flex>
      <v-flex text-xs-left stretch :class="$vuetify.breakpoint.xsOnly ? 'font-size--16' : 'font-size--20'">
        <div class="white--text ml-4 mt-3">
          {{ $t('L_policy_reinstatement_request') }}
        </div>
      </v-flex>
      <!-- Policy Number -->
      <v-flex shrink text-xs-right v-if="!$vuetify.breakpoint.xsOnly">
        <div class="white--text font-size--14 mt-4">
          {{ $t('L_policy_number') }}:
          <span class="font-weight-regular">{{ getContractId }}</span>
        </div>
      </v-flex>
    </v-layout>
    <!-- New York and 3rd Party Policies-->
    <v-layout row wrap v-if="goToAOFlow">
      <v-card class="fullWidth pt-4 pb-4">
        <!-- NY and 3rd First Step -->
        <ReinstatementNYStepThree
          v-if="nyPolicyReinstatementStep === 1"
          :disable-actions="asyncProcessInProgress"
          @continue-to-the-next-reinstatement-step="continueToNYFinalStep"
          @cancelClicked="cancelFromReinstatementStep()"
        />
        <!-- Last step for NY and 3rd step -->
        <ReinstatementOriginalIssueNextSteps
          v-if="nyPolicyReinstatementStep === 2"
          :disable-actions="asyncProcessInProgress"
          :is-ny-or-third-party="goToAOFlow"
          @continueClicked="goToDashboard()"
        />
      </v-card>
    </v-layout>
    <!-- Non New York and Non 3rd Party Policies -->
    <v-stepper v-else v-model="policyReinstatementStep" :vertical="isMobileView">
      <v-stepper-header v-if="!isMobileView">
        <v-stepper-step :complete="policyReinstatementStep > 1" step="1">
          {{ $t('reinstatement_process_step_overview_header') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="policyReinstatementStep > 2" step="2">
          {{ $t('reinstatement_process_step_options_header') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="policyReinstatementStep > 3" step="3">
          {{ $t('reinstatement_process_step_information_header') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="policyReinstatementStep > 4" step="4">
          {{ $t('reinstatement_process_step_next_steps_header') }}
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <div v-if="asyncProcessInProgress" class="text-xs-center ma-4">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <v-stepper-items>
        <v-stepper-step :complete="policyReinstatementStep > 1" step="1" v-if="isMobileView">
          {{ $t('reinstatement_process_step_overview_header') }}
        </v-stepper-step>
        <v-stepper-content step="1">
          <ReinstatementProcessOverview
            :disable-actions="asyncProcessInProgress"
            @continueClicked="continueToTheNextReinstatementStep()"
            @cancelClicked="cancelFromReinstatementStep()"
          />
        </v-stepper-content>

        <v-stepper-step :complete="policyReinstatementStep > 2" step="2" v-if="isMobileView">
          {{ $t('reinstatement_process_step_options_header') }}
        </v-stepper-step>
        <v-stepper-content step="2">
          <ReinstatementProcessOptions
            :disable-actions="asyncProcessInProgress"
            @userOption="setReinstatementOption"
            @continueClicked="continueToTheNextReinstatementStep()"
            @cancelClicked="cancelFromReinstatementStep()"
          />
        </v-stepper-content>

        <v-stepper-step :complete="policyReinstatementStep > 3" step="3" v-if="isMobileView">
          {{ $t('reinstatement_process_step_information_header') }}
        </v-stepper-step>
        <v-stepper-content step="3" class="moveUpAndLeftToCounterMarginForStepperContent">
          <ReinstatementProcessInformation
            v-if="userSelectedReinstatmentOption === 1"
            :disable-actions="asyncProcessInProgress"
            @continue-to-the-next-reinstatement-step="continueToTheNextReinstatementStep"
            @backClicked="goToPreviousPage()"
          />
          <ReinstatementOriginalIssueDate
            v-else-if="userSelectedReinstatmentOption === 0"
            :disable-actions="asyncProcessInProgress"
            @continue-to-the-next-reinstatement-step="continueToTheNextReinstatementStep"
            @backClicked="goToPreviousPage()"
          />
        </v-stepper-content>

        <v-stepper-step :complete="policyReinstatementStep > 4" step="4" v-if="isMobileView">
          {{ $t('reinstatement_process_step_next_steps_header') }}
        </v-stepper-step>
        <v-stepper-content step="4">
          <ReinstatementProcessNextSteps
            v-if="userSelectedReinstatmentOption === 1"
            :disable-actions="asyncProcessInProgress"
            @continueClicked="continueToTheNextReinstatementStep()"
            @cancelClicked="cancelFromReinstatementStep()"
          />
          <div v-else-if="userSelectedReinstatmentOption === 0">
            <ReinstatementOriginalIssueNextSteps
              :disable-actions="asyncProcessInProgress"
              :is-ny-or-third-party="goToAOFlow"
              @continueClicked="goToDashboard()"
            />
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { openInNewWindow } from '@/mixins/openNewWindow';
import { mapActions, mapGetters } from 'vuex';
import Config from '@/shared/util/config';
import ReinstatementProcessOverview from '../components/ReinstatementProcessOverview.vue';
import ReinstatementProcessOptions from '../components/ReinstatementProcessOptions.vue';
import ReinstatementProcessInformation from '../components/ReinstatementProcessInformation.vue';
import ReinstatementProcessNextSteps from '../components/ReinstatementProcessNextSteps.vue';
import NavigationConfirmationDialog from '../../../components/NavigationConfirmation/NavigationConfirmationDialog.vue';
import ReinstatementOriginalIssueDate from '../components/ReinstatementOriginalIssueDate.vue';
import ReinstatementOriginalIssueNextSteps from '../components/ReinstatementOriginalIssueNextSteps.vue';
import ReinstatementNYStepThree from '../components/ReinstatementNYStepThree.vue';

export default {
  name: 'ReinstatementLayout',
  mixins: [openInNewWindow],
  components: {
    ReinstatementProcessOverview,
    ReinstatementProcessOptions,
    ReinstatementProcessInformation,
    ReinstatementProcessNextSteps,
    NavigationConfirmationDialog,
    ReinstatementOriginalIssueDate,
    ReinstatementOriginalIssueNextSteps,
    ReinstatementNYStepThree,
  },
  data() {
    return {
      asyncProcessInProgress: false,
      policyReinstatementStep: 1,
      nyPolicyReinstatementStep: 1,
      mobileView: false,
      showNavigationConfirmationDialog: false,
      navigatingToRoute: '',
      navigationAwayConfirmed: false,
      isReinstatementProcessDone: false,
      userSelectedReinstatmentOption: -1,
      userInformation: {
        primaryName: '',
        spouseName: '',
        primaryEmailAddress: '',
        primaryMobilePhone: '',
        spouseEmailAddress: '',
        spouseVerifyEmailAddress: '',
        spouseMobilePhone: '',
      },
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentLocale: 'getLocale',
    }),
    ...mapGetters({
      currentPolicy: 'policy/currentPolicy',
    }),
    ...mapGetters('customer', ['customerProfile']),
    isMobileView() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    getContractId() {
      return this.currentPolicy?._contractId || '';
    },
    newYorkPolicy() {
      return !!this.currentPolicy?.data?.newYorkPolicy;
    },
    thirdPartyPolicy() {
      return !!this.currentPolicy?.data?.thirdPartyPolicy;
    },
    goToAOFlow() {
      // redirect user to AO note flow
      // if resintatement.override config = true
      // or newYorkPolicy = true or thirdPartyPolicy = true
      if (Config.get('reinstatement.override')) {
        return true;
      } else {
        return this.newYorkPolicy || this.thirdPartyPolicy;
      }
    },
    isCurrentPolicyCountryUS() {
      return this.currentPolicy._contractId?.substr(0, 2) === '04' || this.currentPolicy._contractId.substr(0, 2) === '34';
    },
    isCurrentPolicyCountryCAN() {
      return this.currentPolicy._contractId?.substr(0, 2) !== '04' && this.currentPolicy._contractId.substr(0, 2) !== '34';
    },
  },
  methods: {
    ...mapActions('reinstatement', ['postPolicyReinstatement']),
    ...mapActions('policy', ['getPolicyOverview']),
    setReinstatementOption(option) {
      this.userSelectedReinstatmentOption = option;
    },
    async goToDashboard() {
      this.asyncProcessInProgress = true;
      await this.$store.dispatch('policy/getPolicyOverview', this.currentPolicy?.contractId);
      this.asyncProcessInProgress = false;
      this.isReinstatementProcessDone = true;
      this.$router.push({ name: 'Dashboard' });
    },
    async continueToLastPageForOriginalDate(addressObj) {
      // start
      // eslint-disable-next-line no-console
      // console.log('reInstatementPayloadObj', JSON.stringify(reInstatementPayloadObj, 1, 3));
      try {
        const redirectUrl = await this.postPolicyReinstatement({
          addressObj,
        });

        // eslint-disable-next-line no-console
        // console.log(JSON.stringify(this.redirectUrl, 1, 3));
        await this.openInNewWindow({ url: redirectUrl, target: '_blank' });
        this.isReinstatementProcessDone = true;
        await this.$router.push({ name: 'Dashboard' });
      } catch (e) {}
    },
    async continueToTheNextReinstatementStep(userOrAddressObj) {
      // TODO: maybe use a more descriptive step string instead of arbitrary step int
      // Overview, Options, Information, Next Steps
      switch (this.policyReinstatementStep) {
        case 1: // Overview
          this.policyReinstatementStep = 2;
          break;
        case 2: // Options
          this.policyReinstatementStep = 3;
          break;
        case 3:
        // TODO: refactor reusable logic see case 4
          // eslint-disable-next-line no-alert
          // alert(JSON.stringify(userObj, 1, 3));
          // TODO this is setting undefined instead of data right now
          if (this.userSelectedReinstatmentOption === 1) {
            this.userInformation = userOrAddressObj;
            this.policyReinstatementStep = 4;
          } else if (this.userSelectedReinstatmentOption === 0) {
            // this.address1 = userOrAddressObj.streetaddress1;
            // this.address2 = userOrAddressObj.streetaddress2;
            // this.city = userOrAddressObj.city;
            // this.state = userOrAddressObj.state;
            // this.zip = userOrAddressObj.zip;
            // TODO: use isCurrentPolicyCountryUS || isCurrentPolicyCountryCAN
            if (this.currentPolicy._contractId?.substr(0, 2) === '04' || this.currentPolicy._contractId.substr(0, 2) === '34') {
              this.country = 'US';
            } else {
              this.country = 'CA';
            }
            // start
            const reInstatementPayloadObj = {
              type: 'ORG', // NEW (New Date) / ORG (Original Date)
              state: this.currentPolicy?._owner?._address?._state || '', // Client life policy address state
              locale: this.currentLocale,
              // Address exists only if type = ORG
              address: {
                address1: userOrAddressObj?.streetaddress1 || '',
                address2: userOrAddressObj?.streetaddress2 || '',
                city: userOrAddressObj?.city || '',
                state: userOrAddressObj?.state || '',
                country: this.country,
                zip: userOrAddressObj?.zip,
              },
            };

            const signers = [];

            const ownerObj = // Owner is required
            {
              name: `${this.customerProfile?.firstName} ${this.customerProfile?.lastName}`,
              role: 'Owner',
              emailAddress: this.customerProfile?.email,
              mobilePhone: this.userInformation.primaryMobilePhone,
            };
            signers.push(ownerObj);

            this.spouseFirstName = this.currentPolicy?.data?.spouseFirstName;
            this.spouseLastName = this.currentPolicy?.data?.spouseLastName;
            const spouseObj = {
              name: `${this.spouseFirstName} ${this.spouseLastName}`,
              role: 'Spouse',
              emailAddress: '', // TODO since this is not being passed to us
              mobilePhone: '', // What should we do for Original date?
            };
            if (this.spouseFirstName !== undefined && this.spouseFirstName.length > 0) {
            // ONLY if there is Spouse
              signers.push(spouseObj);
            }
            reInstatementPayloadObj.signers = signers;

            try {
              this.asyncProcessInProgress = true;
              await this.postPolicyReinstatement({
                policyNumber: this.currentPolicy._contractId,
                reinstatementPayload: reInstatementPayloadObj,
              });

              // eslint-disable-next-line no-console
              // this.isReinstatementProcessDone = true;
              // await this.$router.push({ name: 'Dashboard' });
              await this.$store.dispatch('policy/getPolicyOverview', this.currentPolicy?.contractId);
              this.policyReinstatementStep = 4;
            } catch (e) {} finally {
              this.asyncProcessInProgress = false;
            }
            // end
          }
          // eslint-disable-next-line no-alert
          // alert(JSON.stringify(this.userInformation, 1, 3));
          // Information
          break;
        case 4: // Next Steps
        // TODO: refactor reusable logic see case 3
        {
          if (this.userSelectedReinstatmentOption === 1) {
            let tcountry = '';
            if (this.currentPolicy._contractId?.substr(0, 2) === '04' || this.currentPolicy._contractId.substr(0, 2) === '34') {
              tcountry = 'US';
            } else {
              tcountry = 'CA';
            }
            // call postPolicyReinstatement
            // eslint-disable-next-line no-alert
            // alert(JSON.stringify(this.userInformation, 1, 3));
            // const owner = this.userInformation;
            const reInstatementPayloadObj = {
              type: 'NEW', // NEW (New Date) / ORG (Original Date)
              state: this.currentPolicy?._owner?._address?._state || '', // Client life policy address state
              locale: this.currentLocale,
              // Address exists only if type = ORG
              address: {
                address1: this.currentPolicy?._owner?._address?._address1 || '',
                address2: this.currentPolicy?._owner?._address?._address2 || '',
                city: this.currentPolicy?._owner?._address?._city || '',
                state: this.currentPolicy?._owner?._address?._state || '',
                country: tcountry,
              },
            };

            const signers = [];

            const ownerObj = // Owner is required
            {
              name: `${this.customerProfile?.firstName} ${this.customerProfile?.lastName}`,
              role: 'Owner',
              emailAddress: this.customerProfile?.email,
              mobilePhone: this.userInformation.primaryMobilePhone,
            };
            signers.push(ownerObj);

            this.spouseFirstName = this.currentPolicy?.data?.spouseFirstName;
            this.spouseLastName = this.currentPolicy?.data?.spouseLastName;
            const spouseObj = {
              name: `${this.spouseFirstName} ${this.spouseLastName}`,
              role: 'Spouse',
              emailAddress: this.userInformation.spouseEmailAddress,
              mobilePhone: this.userInformation.spouseMobilePhone,
            };
            if (this.spouseFirstName !== undefined && this.spouseFirstName.length > 0) {
            // ONLY if there is Spouse
              signers.push(spouseObj);
            }
            reInstatementPayloadObj.signers = signers;

            // eslint-disable-next-line no-console
            // console.log('reInstatementPayloadObj', JSON.stringify(reInstatementPayloadObj, 1, 3));
            try {
              this.asyncProcessInProgress = true;
              const redirectUrl = await this.postPolicyReinstatement({
                policyNumber: this.currentPolicy.contractId,
                reinstatementPayload: reInstatementPayloadObj,
              });

              await this.$store.dispatch('policy/getPolicyOverview', this.currentPolicy?.contractId);
              // eslint-disable-next-line no-console
              // console.log(JSON.stringify(this.redirectUrl, 1, 3));
              await this.openInNewWindow({ url: redirectUrl, target: '_blank' });
              this.isReinstatementProcessDone = true;
              await this.$router.push({ name: 'Dashboard' });
            } catch (e) {} finally {
              this.asyncProcessInProgress = false;
            }
          } else {
            await this.$store.dispatch('policy/getPolicyOverview', this.currentPolicy?.contractId);
          }
          break;
        }
        default:
          // TODO: implement default
          this.asyncProcessInProgress = true;
          await this.$store.dispatch('policy/getPolicyOverview', this.currentPolicy?.contractId);
          this.asyncProcessInProgress = false;
          await this.$router.push({ name: 'Dashboard' });
          break;
      }
    },
    async continueToNYFinalStep(addressObj) {
      const reInstatementPayloadObj = {
        type: 'ORG', // NEW (New Date) / ORG (Original Date)
        state: this.currentPolicy?._owner?._address?._state || '', // Client life policy address state
        locale: this.currentLocale,
        address: { // Address exists only if type = ORG
          address1: addressObj?.streetaddress1 || '',
          address2: addressObj?.streetaddress2 || '',
          city: addressObj?.city || '',
          state: addressObj?.state || '',
          country: this.isCurrentPolicyCountryUS ? 'US' : 'CA',
          zip: addressObj?.zip,
        },
      };

      const signers = [];
      const ownerObj = { // Owner is required
        name: `${this.customerProfile?.firstName} ${this.customerProfile?.lastName}`,
        role: 'Owner',
        emailAddress: this.customerProfile?.email,
        mobilePhone: this.userInformation.primaryMobilePhone,
      };
      signers.push(ownerObj);

      const isThereASpouse = this.spouseFirstName && this.spouseFirstName.length;
      if (isThereASpouse) { // ONLY if there is Spouse
        this.spouseFirstName = this.currentPolicy?.data?.spouseFirstName;
        this.spouseLastName = this.currentPolicy?.data?.spouseLastName;
        const spouseObj = {
          name: `${this.spouseFirstName} ${this.spouseLastName}`,
          role: 'Spouse',
          emailAddress: '', // TODO since this is not being passed to us
          mobilePhone: '', // What should we do for Original date?
        };
        signers.push(spouseObj);
      }
      reInstatementPayloadObj.signers = signers;

      try {
        this.asyncProcessInProgress = true;
        await this.postPolicyReinstatement({
          policyNumber: this.currentPolicy._contractId,
          reinstatementPayload: reInstatementPayloadObj,
        });
        await this.$store.dispatch('policy/getPolicyOverview', this.currentPolicy?.contractId);
        this.nyPolicyReinstatementStep = 2;
      } catch (e) {} finally {
        this.asyncProcessInProgress = false;
      }
    },
    async cancelFromReinstatementStep() {
      await this.$router.push({ name: 'Dashboard' });
    },
    goToPreviousPage() {
      this.policyReinstatementStep = 2;
    },
    navigateToRoute() {
      this.navigationAwayConfirmed = true;
      this.$router.push({ name: this.navigatingToRoute }).catch((e) => {
        // TODO: use DT and remove console logs
        // eslint-disable-next-line no-console
        console.error(`err ${e}`);
      });
    },
  },
  watch: {
    isMobileView(newVal /* , oldVal */) {
      this.mobileView = !newVal;
    },
  },
  created() {
    this.mobileView = this.$vuetify.breakpoint.xsOnly;
  },
  beforeRouteLeave(to, from, next) {
    const isReinstatementDone = this.isReinstatementProcessDone || this.navigationAwayConfirmed;
    const navigatingOutsidePolicyReinstatement = to.name !== 'PolicyReinstatement' && to.path !== '/reinstatement';
    if (navigatingOutsidePolicyReinstatement && !isReinstatementDone) {
      this.showNavigationConfirmationDialog = true; // show NavigationConfirmationDialog
      this.navigatingToRoute = to.name; // save the route name
      next(false); // cancel the navigation
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.containerSize {
  max-width:1024px;
  margin: auto;
}
.moveUpAndLeftToCounterMarginForStepperContent {
  margin-top: -29px;
  margin-left: -24px;
  width: calc(100% + 50px);
}
.moveOnTopOfCircle {
  margin-left: -40px;
}
.fullWidth {
  width: 100%;
}
</style>

