import { render, staticRenderFns } from "./NYForms.vue?vue&type=template&id=156a7f47"
import script from "./NYForms.vue?vue&type=script&lang=js"
export * from "./NYForms.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FPolicyOverview%2Fcomponents%2FPolicyDetailsPanel%2Fcomponents%2FNYForms%2FNYForms.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports