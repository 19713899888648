<i18n src="./locales.json"></i18n>
<template>
  <v-container class="mainContainerBackgroundDebtInput" :class="$vuetify.breakpoint.xs ? '' : 'mainContainerBorder'">
    <v-layout row wrap>
      <v-flex xs11>
        &nbsp;
      </v-flex>
      <v-flex xs1 class="xForClose" @click="closeThisPage">
        X
      </v-flex>
    </v-layout>
    <v-layout row wrap text-xs-center>
      <v-flex xs12 :class="$vuetify.breakpoint.xs ? 'enterDetailsOfThisDebtMob' : 'enterDetailsOfThisDebt'">
        {{ $t('L_enter_details_of_this_debt') }}
      </v-flex>
      <v-flex xs12 pb-4 :class="$vuetify.breakpoint.xs ? 'allFieldsAreRequiredMob' : 'allFieldsAreRequired'">
        {{ $t('L_all_fields_are_required') }}
      </v-flex>

      <v-flex xs12 text-xs-left class="inputLabel">
        {{ $t('L_debt_type') }}
      </v-flex>
      <v-flex xs12>
        <v-select :items="debtCategoryForSelect" solo single-line :placeholder="getLabelDebtCategory" v-model="debtData.debtCategory" @change="isValidInterestRate()" :label="$t('L_debt_type')" />
      </v-flex>

      <v-flex xs12 text-xs-left class="inputLabel">
        {{ $t('L_debt_name') }}
      </v-flex>
      <v-flex xs12>
        <v-text-field label="" required :placeholder="$t('L_debt_name')" v-model="debtData.debtName" @keyup="isValidDebtName()" @change="isValidDebtName()" />
        <span v-if="showInvalidDebtName" class="error--text caption ma-0 pa-0">
          {{ $t('L_invalid_debt_name_error') }}
        </span>
      </v-flex>

      <v-flex xs12 text-xs-left class="inputLabel">
        {{ $t('L_current_balance') }}
      </v-flex>
      <v-flex xs12 class="pb-4">
        <v-text-field
          style="font-size:16px !important"
          id="current_balance"
          label=""
          required
          :placeholder="$t('L_current_balance')"
          v-model="debtData.balance"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          prefix="$"
          @keyup="
            validateMonthlyPayment();
            isValidCurrentBalance();
          "
          @change="
            validateMonthlyPayment();
            isValidCurrentBalance();
          "
          @keydown.69.capture.prevent.stop
        />
        <span v-if="showInvalidCurrentBalance" class="error--text caption ma-0 pa-0">
          <!-- L_must_be_greater_than_zero -->
          {{ $t('L_balance_needs_to_be_between_one_and_million_dollars') }}
        </span>
      </v-flex>

      <v-flex xs12 text-xs-left class="inputLabel">
        {{ getLabelMonthlyPayment }}
      </v-flex>
      <v-flex xs12>
        <v-text-field
          id="monthly_payment"
          label=""
          required
          :placeholder="$t('L_monthly_payment')"
          v-model="debtData.minPayment"
          prefix="$"
          type="number"
          @keyup="
            validateMonthlyPayment();
            isValidMonthlyIncome();
          "
          @change="
            validateMonthlyPayment();
            isValidMonthlyIncome();
          "
          @keydown.69.capture.prevent.stop
        />
        <div v-if="minPaymentError" class="error--text caption ma-0 pa-0">
          <i18n path="L_payment_must_be_greater" tag="span" class="font-weight-regular">
            <span class="font-weight-medium">
              {{ minPaymentErrorMsg }}
            </span>
          </i18n>
          <span class="detailsText" @click="detailsMessage">
            {{ $t('L_Details') }}
          </span>
        </div>
        <div v-if="showInvalidMinPayment" class="error--text caption ma-0 pa-0">
          {{ $t('L_payment_cannot_exceed') }}
        </div>
      </v-flex>

      <v-flex xs12 text-xs-left class="inputLabel">
        {{ $t('L_interest_rate') }}
      </v-flex>
      <v-flex xs12>
        <v-text-field
          id="interest_rate"
          label=""
          required
          :placeholder="$t('L_interest_rate')"
          v-model="debtData.interestRate"
          type="number"
          suffix="%"
          @keyup="
            validateMonthlyPayment();
            isValidInterestRate();
          "
          @change="
            validateMonthlyPayment();
            isValidInterestRate();
          "
          @keydown.69.capture.prevent.stop
        />
        <span v-if="showInvalidInterestRate" class="error--text caption ma-0 pa-0">
          <span v-if="this.debtData.debtCategory === 1">
            {{ $t('L_valid_range_is_zero_to_fifty') }}
          </span>
          <span v-else>
            {{ $t('L_valid_range_is_zero_to_hundred') }}
          </span>
        </span>
      </v-flex>
    </v-layout>
    <!-- <v-layout v-if="!newDebt" row wrap :class="debtData.paidOffInd === 1|| debtData.paidOffInd === true || debtData.balance === 0 || debtData.balance === '' ?'greenBackground':'whiteBackground'">
      <v-flex xs2 style="text-align:left;">
        <v-switch
          color="#0b8d1f"
          caret-color="#0b8d1f"
          v-model="debtData.paidOffInd"
          class="mt-0 pb-0"
          style="width: 156px !important"
        />
      </v-flex>
      <v-flex xs10 text-xs-left>
        <span class="paidoff">  {{ $t('L_paid_off') }}</span><span class="trophyFormatting">
          <img v-if="debtData.paidOffInd===1 || debtData.paidOffInd===true || debtData.balance === 0 " src="../../../assets/trophy.svg" alt="trophy image green">
          <img v-else src="../../../assets/trophyblue.svg" alt="trophy image blue">
        </span>
      </v-flex>
      <v-flex xs2>&nbsp;</v-flex>
      <v-flex xs10 class="markthisdebt"> {{ $t('L_mark_this_debt_as_paid_off') }}</v-flex>
    </v-layout> -->
    <v-layout row wrap text-xs-center v-if="!newDebt" pt-4>
      <v-flex xs12>
        <v-btn :class="formValid ? 'primary' : 'graybutton'" class="btnFormat maxButtonWidth" @click="sendDebtSolutionDataToParent" :disabled="!formValid">
          {{ $t('L_save_changes') }}
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <v-btn @click="showDebtAlert" class="btnFormat removeDebtBtn maxButtonWidth" :disabled="debtData.debtID === undefined">
          {{ $t('L_remove_debt') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap text-xs-center v-if="newDebt">
      <v-flex xs12>
        <v-btn :class="formValid ? 'primary' : 'graybutton'" class="btnFormat maxButtonWidth" @click="sendDebtSolutionDataToParent" :disabled="!formValid">
          {{ $t('L_add_to_summary') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <div v-if="showDetailsMessage || showDebtDeleteAlert" class="modal">
      <MessageDialog
        :show-dialog="showDetailsMessage"
        :has-button-two="true"
        type="warning"
        :title="$t('L_payment_amount_too_low')"
        :message="$t('L_payment_amt_verbiage', { amount: minPaymentErrorMsg })"
        :button-one-label="$t('L_got_it')"
        :is-persistent="true"
        @buttonOneAction="closeTheDetailsInfo"
      />
      <MessageDialog
        :show-dialog="showDebtDeleteAlert"
        :has-button-two="true"
        type="warning"
        :title="$t('L_remove_debt_ques')"
        :message="$t('L_once_you_remove_this_debt')"
        :button-two-label="$t('L_cancel')"
        :button-one-label="$t('L_remove_debt')"
        :is-persistent="true"
        @buttonTwoAction="closeTheDebtDeleteAlert"
        @buttonOneAction="closeTheDebtDeleteAlertAndDeleteData"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import MessageDialog from '@/components/MessageDialog.vue';

export default {
  name: 'DebtInput',
  props: {
    tempDebtData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  components: {
    MessageDialog,
  },
  data: () => ({
    newDebt: true,
    minPaymentError: false,
    minPaymentErrorMsg: 0,
    showDetailsMessage: false,
    showDebtDeleteAlert: false,
    isDisabledSendDebtSolutionRemoval: false,
    isDisabledCloseTheDebt: false,
    debtData: {},
    showInvalidDebtName: false,
    showInvalidCurrentBalance: false,
    showInvalidMinPayment: false,
    showInvalidInterestRate: false,
  }),
  mounted() {
    if (this.tempDebtData != null) {
      this.debtData = JSON.parse(JSON.stringify(this.tempDebtData));
      this.debtData.interestRate = (this.debtData.interestRate * 100).toFixed(3);
      this.newDebt = false;
    }
  },
  beforeDestroy() {
    if (this.tempDebtData != null) {
      this.debtData = null;
    }
  },
  watch: {
    tempDebtData(val) {
      this.debtData = val;
    },
  },
  methods: {
    ...mapActions('debtsolutions', {
      updateDebtItem: 'updateDebtItem',
    }),
    validateMonthlyPayment() {
      this.minPaymentError = false;
      if (this.debtData.balance !== undefined && this.debtData.minPayment !== undefined && this.debtData.interestRate !== undefined) {
        // Adding an extra penny because for cases where the third number after decimal is less than 5 it would not be rounded up
        const minAllowed = (((this.debtData.balance * this.debtData.interestRate) / 1200) + 2.01).toFixed(2);
        const minPayment = Number.parseFloat(this.debtData.minPayment) || 0;
        // TODO Travis will give formula for this
        if (minPayment < minAllowed) {
          this.minPaymentErrorMsg = minAllowed;
          this.minPaymentError = true;
        } else {
          this.minPaymentError = false;
        }
      } else {
        this.minPaymentError = false;
      }
    },
    detailsMessage() {
      this.showDetailsMessage = true;
    },
    /*
      getRandomInt(min, max) {
      const tmin = Math.ceil(min);
      const tmax = Math.floor(max);
      // eslint-disable-next-line no-mixed-operators
      return Math.floor(Math.random() * (tmax - tmin + 1)) + tmin;
    },
    */
    isValidDebtName() {
      if (this.debtData.debtName == null || this.debtData.debtName === '') {
        this.showInvalidDebtName = true;
      } else {
        this.debtData.debtName = this.debtData.debtName.replace(/[^A-Za-z0-9 ]/g, '');
        this.debtData.debtName = this.debtData.debtName.substring(0, 50);
        this.showInvalidDebtName = false;
      }
    },
    isValidCurrentBalance() {
      if (this.debtData.balance == null || this.debtData.balance === '') {
        this.showInvalidCurrentBalance = true;
      } else if (!this.debtData.paidOffInd && (this.debtData.balance < 2 || this.debtData.balance > 9999999)) {
        // Max allowed is less than $1 million and more than $1
        this.showInvalidCurrentBalance = true;
      } else if (this.debtData.balance === 0 && (this.debtData.paidOffInd !== 1 || this.debtData.paidOffInd !== true)) {
        // balance cannot be 0 unless the paid off indicator is true
        this.showInvalidCurrentBalance = true;
      } else {
        this.showInvalidCurrentBalance = false;
      }
    },
    isValidMonthlyIncome() {
      if (this.debtData.minPayment == null || this.debtData.minPayment === '') {
        this.showInvalidMinPayment = true;
      } else if (this.debtData.minPayment > 999999) {
        this.showInvalidMinPayment = true;
      } else {
        this.showInvalidMinPayment = false;
      }
    },
    isValidInterestRate() {
      if (this.debtData.debtCategory === 1) {
        // Mortgage needs to be between 0% and 50%
        if (this.debtData.interestRate < 0 || this.debtData.interestRate > 50) {
          this.showInvalidInterestRate = true;
        } else {
          this.showInvalidInterestRate = false;
        }
      } else if (this.debtData.interestRate < 0 || this.debtData.interestRate >= 100) {
        // Everything else is between 0% and 99.999%
        this.showInvalidInterestRate = true;
      } else {
        this.showInvalidInterestRate = false;
      }
    },
    setTypeForCategory(category) {
      switch (category) {
        case 1:
          this.debtData.mortgageInd = 1;
          this.debtData.debtType = 0;
          break;
        case 2:
        case 5:
          this.debtData.mortgageInd = 0;
          this.debtData.debtType = 1;
          break;
        case 3:
        case 4:
          this.debtData.mortgageInd = 0;
          this.debtData.debtType = 0;
          break;
        default:
          break;
      }
    },
    sendDebtSolutionDataToParent() {
      this.debtData.debtCategory = Number.parseInt(this.debtData.debtCategory, 10) || 0;
      this.debtData.debtName = this.debtData.debtName.substring(0, 50);

      const tempBalance = Number.parseInt(this.debtData.balance, 10) || 0;
      this.debtData.balance = Number.parseInt(tempBalance.toFixed(0), 10) || 0;

      const tempMinPayment = Number.parseFloat(this.debtData.minPayment, 10) || 0;
      this.debtData.minPayment = this.debtData.actualPayment = Number.parseFloat(tempMinPayment.toFixed(2), 10) || 0;

      this.debtData.interestRate = (Number.parseFloat(this.debtData.interestRate) || 0) / 100;
      this.debtData.paidOffInd = this.debtData?.paidOffInd === true ? 1 : 0;

      if (this.debtData.paidOffInd === 1) {
        this.debtData.balance = 0;
      }

      // Currently there is no option on Front end to accelerate debt so we are having to put in here.
      this.debtData.accelerateDebt = 1;

      // Need to set debtType to Revolving or 1 for Credit Card and HELOC
      // For Home Equity Loan, Loan (auto, student, personal) and Mortgage will be Fixed or 0.
      // This is being set only for mortgage
      this.setTypeForCategory(this.debtData.debtCategory);
      // eslint-disable-next-line no-mixed-operators
      // const tTotalDuration = (tCurrentBalance * ((tInterestRate + 100) / 100) / tMonthlyPayment).toFixed(0);

      // this.debtData.totalDuration = tTotalDuration;

      // this.debtData.totalInterestPaid = tTotalDuration * tInterestRate;
      // Will use the one in DebtDetails
      // this.updateDebtItem(this.debtData);

      // This will pass data in this component to parent
      this.$emit('close-debt-input', this.debtData);
    },
    sendDebtSolutionRemovalInstructionToParent() {
      if (this.isDisabledSendDebtSolutionRemoval) {
        return;
      }
      this.isDisabledSendDebtSolutionRemoval = true;
      this.debtData.remove = true;
      this.$emit('close-debt-input', this.debtData);
      setTimeout(() => {
        this.isDisabledSendDebtSolutionRemoval = false;
      }, 500);
    },
    closeThisPage() {
      this.$emit('close-debt-input');
    },
    closeTheDetailsInfo() {
      this.showDetailsMessage = false;
    },
    closeTheDebtDeleteAlert() {
      this.showDebtDeleteAlert = false;
    },
    closeTheDebtDeleteAlertAndDeleteData() {
      if (this.isDisabledCloseTheDebt) {
        return;
      }
      this.isDisabledCloseTheDebt = true;
      this.showDebtDeleteAlert = false;
      this.sendDebtSolutionRemovalInstructionToParent();
      setTimeout(() => {
        this.isDisabledCloseTheDebt = false;
      }, 500);
    },
    showDebtAlert() {
      this.showDebtDeleteAlert = true;
    },
    removeDebtAlert() {
      this.showDebtDeleteAlert = false;
    },
  },
  computed: {
    debtCategoryForSelect() {
      return [
        {
          value: 2,
          text: this.$t('L_credit_card'),
        },
        {
          value: 4,
          text: this.$t('L_home_equity_loan'),
        },
        {
          value: 5,
          text: this.$t('L_home_equity_line_of_credit'),
        },
        {
          value: 3,
          text: this.$t('L_loan_auto_student_personal'),
        },
        {
          value: 1,
          text: this.$t('L_mortgage'),
        },
      ];
    },
    getLabelDebtCategory() {
      return this.$t('L_debt_type');
    },
    getLabelDebtName() {
      return this.$t('L_debt_name');
    },
    getLabelCurrentBalance() {
      return this.$t('L_current_balance');
    },
    getLabelMonthlyPayment() {
      if (this.debtData.debtCategory === 1) {
        return this.$t('L_principal_and_interest');
      }
      return this.$t('L_monthly_payment');
    },
    getLabelInterestRate() {
      return this.$t('L_interest_rate');
    },
    formValid() {
      return !(
        this.debtData.debtCategory == null ||
        this.debtData.debtCategory === '' ||
        this.debtData.debtName == null ||
        this.debtData.debtName === '' ||
        this.debtData.balance == null ||
        this.debtData.balance === '' ||
        this.debtData.minPayment == null ||
        this.debtData.minPayment === '' ||
        this.debtData.interestRate == null ||
        this.debtData.interestRate === '' ||
        this.minPaymentError === true ||
        this.showInvalidDebtName === true ||
        this.showInvalidCurrentBalance === true ||
        this.showInvalidMinPayment === true ||
        this.showInvalidInterestRate === true
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
.mainContainerBackgroundDebtInput
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
.mainContainerBorder
  border:1px solid black;
.max-btn-width
  max-width: 260px;
  width: 260px;
.graybutton
  color: #adadad;
  background-color: #eeeeee;
.xForClose
  color: #005de3;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
.trophyFormatting
  padding-left:5px;
  padding-top:5px;
.markthisdebt
  font-size:12px;
  text-align:left;
  font-weight: 600;
.paidoff
  font-size: 20px;
  font-weight: 600;
.whiteBackground
  background-color: white;
  color:#063562;
  margin: 0 -40px 0 -40px;
  padding: 20px 40px 20px 40px;
.greenBackground
  background-color: #eafbec;
  border: 1px solid #098d1f;
  color: #098d1f;
  margin: 0 -40px 0 -40px;
  padding: 20px 40px 20px 40px;
.btnFormat
  width: 260px;
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined)
  color:#adadad !important;
  background-color: #eeeeee !important;
.removeDebtBtn
  color:#002c56;
.inputLabel
  color: #666666;
  font-size: 12px;
  font-weight: 600;
.errorColor
  color: red;
.defaultColor
  color: #666666;
.detailsText
  text-decoration: underline;
.infoTitle
  font-size: 24px;
  font-weight: 600;
  color: #333333;
.moveXToRight
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  right: 15px;
  font-size: 24px;
.verbiagePara
  padding-bottom: 20px;
  font-size: 16px;
  coor: #333333;
.gotItText
  font-size: 16px;
  font-weight: 600;
  color: #02579e;
  position: absolute;
  right: 15px;
  bottom: 15px;
.alertModal
  position: fixed;
  height: 500px;
  width: 590px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
.alertModalMobile
  position: fixed;
  width: 320px;
  height: 700px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
.modal
  position: fixed; /* Stay in place */
  z-index: 11; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
.cancelText
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  position: absolute;
  right: 200px;
  bottom: 15px;
.v-input
  padding-bottom: 5px;
.enterDetailsOfThisDebt
  font-size:24px;
.enterDetailsOfThisDebtMob
  font-size:20px;
.allFieldsAreRequired
  font-size:20px;
.allFieldsAreRequiredMob
  font-size:16px;
.maxButtonWidth
  max-width: 275px;
  width: 275px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
</style>
