<template>
  <div class="ach-payments-wrapper">
    <AchStepOne
      v-if="step === 0"
      :policy-data="policy"
      :minimum-amount-selected="minimumAmountSelected"
      @goToStepTwo="goToStepTwo"
      @cancel="$emit('cancel')"
    />
    <AchStepTwo
      v-if="step === 1"
      :policy-data="policy"
      :payment-amount="paymentAmount"
      @goToStepOne="goToStepOne"
      @goToStepThree="goToStepThree"
    />
    <AchStepThree
      v-if="step === 2"
      :policy-data="policy"
      @goToStepTwo="goToStepTwo"
      @goToStepOne="goToStepOne"
      @cancel="cancelAll"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AchStepOne from './components/AchStepOne/AchStepOne.vue';
import AchStepTwo from './components/AchStepTwo/AchStepTwo.vue';
import AchStepThree from './components/AchStepThree/AchStepThree.vue';

export default {
  name: 'AchPayment',
  components: {
    AchStepOne,
    AchStepTwo,
    AchStepThree,
  },
  props: {
    paymentAmount: {
      type: String,
      required: true,
      default: '0',
    },
    minimumAmountSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    step: 0,
  }),
  computed: {
    ...mapGetters('policy', {
      policy: 'currentPolicy',
    }),
  },
  methods: {
    goToStepOne() {
      this.step = 0;
    },
    goToStepTwo() {
      this.step = 1;
    },
    goToStepThree() {
      this.step = 2;
    },
    cancelAll() {
      this.$emit('cancelAll');
    },
  },
};
</script>

