<i18n src='../../locales.json'></i18n>

<template>
  <div class="investmentContainer" style="max-width: 1024px; margin: auto;">
    <v-flex
      xs12
      class="investments-card"
    >
      <InvestmentsTitle :is-dashboard="true" />
      <v-card>
        <Alert v-if="!investments.isCompliant"
               type="error"
               :dismissible="false"
               :details="{ message: $t('L_investments_accounts_not_available') }"
               icon="info"
               color="error"
               :expandable="false"
               :colored-border="true"
        />
        <LoadingIndicator :loading="investmentsLoading" />
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 class="top">
              <InvestmentsSummary v-if="hasInvestments" />
              <NoInvestmentsTop v-else />
            </v-flex>
            <v-flex xs12 class="bottom">
              <RetirementTracker v-if="hasCalculator" />
              <NoInvestmentsBottom v-else />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Alert from '@/components/Alert.vue';
import InvestmentsTitle from './InvestmentsTitle.vue';
import InvestmentsSummary from './components/InvestmentsSummary.vue';
import NoInvestmentsTop from './components/NoInvestmentsTop.vue';
import NoInvestmentsBottom from './components/NoInvestmentsBottom.vue';
import RetirementTracker from './components/RetirementTracker.vue';

export default {
  name: 'InvestmentsCard',
  components: {
    InvestmentsTitle,
    InvestmentsSummary,
    NoInvestmentsTop,
    NoInvestmentsBottom,
    RetirementTracker,
    LoadingIndicator,
    Alert,
  },
  computed: {
    ...mapGetters('customer', ['investments', 'loading']),
    hasInvestments() {
      return this.investments.summary.totalValue > 0;
    },
    investmentsLoading() {
      return this.loading.investments;
    },
    hasCalculator() {
      return (this.investments.calculator.projection?.percentRetirementIncomeGoal >= 0);
    },
  },
  created() {
    if (!this.hasInvestments) {
      this.getInvestments();
    }
  },
  methods: {
    ...mapActions('customer', ['getInvestments']),
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'
.investmentContainer
  max-width: 1024px;
  margin: auto;
.investments-card
  margin: 35px 0 0;
  .top
    padding: 5px 0;
  .bottom
    padding: 15px 0;
    background: #d6d6d6;
    .flex
      padding: 0 15px;
    .text
      font-size: 14px;
    .text-big
      font-size: 18px;
      font-weight: 600;
    .v-btn
      border: 2px solid #063562;
      .v-btn__content
        color: #063562;
</style>
