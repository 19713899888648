import { render, staticRenderFns } from "./PolicyOwner.vue?vue&type=template&id=7b02270f&scoped=true"
import script from "./PolicyOwner.vue?vue&type=script&lang=js"
export * from "./PolicyOwner.vue?vue&type=script&lang=js"
import style0 from "./PolicyOwner.vue?vue&type=style&index=0&id=7b02270f&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b02270f",
  null
  
)

/* custom blocks */
import block0 from "../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FPolicyOwner%2FPolicyOwner.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports