<template>
  <v-container
    fluid
    class="secure-header"
  >
    <v-layout
      row
      justify-center
      align-center
    >
      <v-flex
        d-flex
        class="secure-header-connection"
      >
        <img src="@/assets/shield.svg">
        <p>Secure Connection</p>
      </v-flex>
      <v-flex class="caption">
        Primerica cares about your security
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>
