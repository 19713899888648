<template>
  <v-text-field
    v-model="formInput.$model"
    id="opt-code"
    label=""
    :autocomplete="disableAutocomplete ? 'off' : null"
    required
    clear-icon="cancel"
    mask="######"
    :color="color"
    style="padding-bottom: 5px"
    :hint="isMobile
      ? 'For your added security, you should only trust devices that are not shared or accessible to the public. This helps keep your information secure.'
      : ''"
    :error-messages="errorMessageValue"
    @focus="onFocus(); toggleTooltip({field:'otpCode', show:true})"
    @input="onInput"
    @blur="onBlur"
    aria-describedby="otp_device_tooltip otpCode_error_message"
  >
    <template slot="append">
      <FieldIcon
        slot="append"
        :form-field="formInput"
      />
      <v-tooltip
        v-if="showToolTip && !isMobile"
        style="background-color:#DD3333;"
        slot="append"
        right
        v-model="otpFocus"
        :max-width="this.$vuetify.breakpoint.mdAndUp ? '186' : '162'"
        color="primary"
      >
        <v-icon slot="activator" />
        <span
          role="tooltip"
          id="otp_device_tooltip"
        >
          For your added security, you should only trust devices that are not shared or accessible to the public. This helps keep your information secure.
        </span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'OtpCodeInput',
  extends: BaseTextField,
  components: { FieldIcon },
  props: {
    showToolTip: {
      type: Boolean,
      required: false,
      default: true,
    },
    otpValErrors: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    modelName: 'otpCode',
    otpCode: '',
    otpFocus: false,
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    errorMessageValue() {
      if (this.errorMessages[0] && this.errorMessages[0].length > 0) {
        return this.errorMessages[0];
      }
      if (this.otpValErrors) {
        return this.otpValErrors;
      }
      return this.label;
    },
  },
  methods: {
    onFocus(e) {
      this.otpFocus = true;
      BaseTextField.methods.onFocus.bind(this)(e);
    },
    onBlur(e) {
      this.otpFocus = false;
      BaseTextField.methods.onBlur.bind(this)(e);
    },
    onInput() {
      this.$emit('clearOTPError');
    },
  },
};
</script>
