<template>
  <router-view />
</template>

<script>
import { mapMutations } from 'vuex';
import { CapacitorFacade } from '@/plugins/capacitorPlugin';
import { unsupportedBrowser } from '@/shared/util/helpers';
import store from '@/store/index';

export default {
  name: 'PreLoginLobby',
  data() {
    return {
      error: '',
    };
  },
  methods: {
    ...mapMutations({
      addError: 'addError',
    }),
  },
  created() { },
  async beforeRouteEnter(to, from, next) {
    try {
      const srcId = to?.query?.source || window?.localStorage?.getItem('sourceId');
      if (srcId) {
        window.localStorage.setItem('sourceId', srcId); // set sourceId
      }
    } catch (error) {}

    // TODO: handle when user is logged in already
    if (to.name === 'PreLoginLobby') {
      await store.dispatch('getAppInfo');
    }
    if (CapacitorFacade.isNativePlatform() && (store.getters.isOptionalUpdateAvailable || store.getters.isCriticalUpdateAvailable)) {
      return ((to.name === 'AppUpdate') ? next() : next({ name: 'AppUpdate' }));
    } else if (CapacitorFacade.isWeb() && unsupportedBrowser()) {
      return ((to.name === 'UnsupportedBrowser') ? next() : next({ name: 'UnsupportedBrowser' }));
    } else if (store.getters.isMaintenanceMode) {
      return ((to.name === 'MaintenanceMode') ? next() : next({ name: 'MaintenanceMode' }));
    } else {
      return (next({ name: 'Dashboard' }));
    }
  },
};
</script>

<style scoped></style>
