<i18n src='./locales.json'></i18n>

<template>
  <v-layout>
    <v-flex
      xs12
      class="debt-solutions-section"
      v-if="debtSolutionsEnabled"
    >
      <DebtSolutionsTitleSection />
      <v-card>
        <v-card-text>
          <v-layout row wrap>
            <LoadingIndicator class="setupLeft"
                              :loading="step===-1"
            />
            <v-flex
              xs12
              pt-4
              pb-4
              class="top"
            >
              <!-- Top portion -->
              <DebtSolutionsNoPlanTop v-if="hasNoPlan" />
              <!-- 2 or more debts -->
              <DebtSolutionsV03Top v-if="hasActivePlan && twoOrMoreDebtsAndMinOneUnpaidDebt" />
              <!-- Only 1 debt -->
              <DebtSolutionsV03TopSingle v-if="hasActivePlan && !twoOrMoreDebtsAndMinOneUnpaidDebt" />
              <DebtSolutionsCongratsTop v-if="hasCompletedPlan" />
            </v-flex>
            <v-flex
              xs12
              pt-4
              pb-4
              class="bottom"
            >
              <!-- Bottom portion -->
              <DebtSolutionsNoPlanBottom v-if="hasNoPlan" />
              <!-- 2 or more debts -->
              <DebtSolutionsV03Bottom v-if="hasActivePlan && twoOrMoreDebtsAndMinOneUnpaidDebt" />
              <!-- Only 1 debt -->
              <DebtSolutionsV03BottomSingle v-if="hasActivePlan && !twoOrMoreDebtsAndMinOneUnpaidDebt" />
              <DebtSolutionsCongratsBottom v-if="hasCompletedPlan" />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>


    <v-flex
      xs12
      class="right pt-3 mt-4"
      v-else
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          shrink
        >
          <svg width="55px" height="40px" viewBox="15 10 42 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Debt Solutions</title>
            <defs>
              <radialGradient cx="49.9999146%" cy="35.0747705%" fx="49.9999146%" fy="35.0747705%" r="90.2110118%"
                              gradientTransform="translate(0.499999,0.350748),scale(0.698177,1.000000),rotate(90.000000),scale(1.000000,1.063443),translate(-0.499999,-0.350748)"
                              id="radialGradient-111"
              >
                <stop stop-color="#D7D7D7" offset="0%" />
                <stop stop-color="#D8EBF8" offset="100%" />
              </radialGradient>
              <radialGradient cx="49.9999146%" cy="35.0747705%" fx="49.9999146%" fy="35.0747705%" r="225.372078%"
                              gradientTransform="translate(0.499999,0.350748),scale(0.414471,1.000000),rotate(79.173457),scale(1.000000,1.063443),translate(-0.499999,-0.350748)"
                              id="radialGradient-211"
              >
                <stop stop-color="#D7D7D7" offset="0%" />
                <stop stop-color="#D8EBF8" offset="100%" />
              </radialGradient>
            </defs>
            <g id="Nav-House" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="White-Level-Icon2" transform="translate(12.000000, 11.000000)">
                <g>
                  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                  <path d="M0.0481927711,10.1085184 L4.76706161,10.115802 L4.76706161,27.2296386 L34.4681573,27.2296386 L34.4681573,10.115802 L39.0481928,10.115802
                    L31.4197216,6.1471119 L31.4191664,0.143189422 L26.1423444,0.143189422 L26.1423444,3.40013459 L19.6152327,0.000722891566 L0.0481927711,10.1085184 Z
                    M6.39156627,13.0127711 L32.9242094,13.0127711 L32.939759,25.7838554 L6.40716787,25.7838554 L6.39156627,13.0127711 Z"
                        id="White-Level-Icon" fill="url(#radialGradient-111)"
                  />
                  <polygon id="Rectangle" fill="#18344E" points="6.39435792 13.0127711 32.9435943 13.0127711 32.9591537 25.7838554 6.39435792 25.7838554" />
                  <path d="M6.89055335,9.3015749 L6.89384557,12.5127711 L32.4587727,12.5127711 L32.4563273,9.30178306 L19.6766929,2.56589179 L6.89055335,9.3015749 Z"
                        id="Rectangle" stroke="#18344E" fill="url(#radialGradient-211)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </v-flex>
        <v-flex>
          <h2 class="inherit">
            <a
              href="JavaScript:void(0);"
              :aria-label="$t('L_debt_freedom') + '. Currently unavailable option'"
              class="my-primerica-header heading_two disabled not-live-link"
            >{{ $t('L_debt_freedom') }}</a>
          </h2>
        </v-flex>
      </v-layout>


      <v-layout class="imageContainer">
        <v-flex xs12
                row
                class="box imageBox overlayed"
        />
        <v-layout
          row
          class="box imageBox" style="width:100%;"
        >
          <v-flex
            offset-xs1
            xs10
            pt-4
          >
            <div class="textContainer">
              <v-flex class="mh-120">
                <div
                  class="text-medium"
                  v-html="$t('L_do_you_know_your_debt')"
                  id="know_Debt"
                  aria-labelledby="know_Debt"
                />
              </v-flex>
              <v-flex>
                <v-btn
                  disabled
                  class="box-btn not-live-link"
                  outline
                  block
                  color="white"
                  aria-label="Contact your rep for debt solution"
                >
                  {{ $t('L_contact_your_rep') }}
                </v-btn>
              </v-flex>
            </div>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FEATURE_FLAGS } from '@/shared/constants';
import DebtSolutionsTitleSection from './components/DebtSolutionsTitleSection.vue';
import DebtSolutionsNoPlanTop from './components/DebtSolutionsNoPlanTop.vue';
import DebtSolutionsNoPlanBottom from './components/DebtSolutionsNoPlanBottom.vue';
import DebtSolutionsV03Top from './components/DebtSolutionsV03Top.vue';
import DebtSolutionsV03Bottom from './components/DebtSolutionsV03Bottom.vue';
import DebtSolutionsCongratsTop from './components/DebtSolutionsCongratsTop.vue';
import DebtSolutionsCongratsBottom from './components/DebtSolutionsCongratsBottom.vue';
import DebtSolutionsV03TopSingle from './components/DebtSolutionsV03TopSingle.vue';
import DebtSolutionsV03BottomSingle from './components/DebtSolutionsV03BottomSingle.vue';
import LoadingIndicator from '../../../../components/LoadingIndicator.vue';

export default {
  name: 'DebtSolutionsCard',
  components: {
    DebtSolutionsTitleSection,
    DebtSolutionsNoPlanTop,
    DebtSolutionsNoPlanBottom,
    DebtSolutionsV03Top,
    DebtSolutionsV03Bottom,
    DebtSolutionsCongratsTop,
    DebtSolutionsCongratsBottom,
    DebtSolutionsV03TopSingle,
    DebtSolutionsV03BottomSingle,
    LoadingIndicator,
  },
  computed: {
    ...mapGetters(['containsFeature']),
    ...mapGetters({
      hasNoPlan: 'debtsolutions/hasNoPlan',
      hasActivePlan: 'debtsolutions/hasActivePlan',
      hasCompletedPlan: 'debtsolutions/hasCompletedPlan',
      step: 'debtsolutions/step',
      twoOrMoreDebtsAndMinOneUnpaidDebt: 'debtsolutions/twoOrMoreDebtsAndMinOneUnpaidDebt',
      investments: 'customer/investments',
      personInfo: 'debtsolutions/personInfo',
    }),
    debtSolutionsEnabled() {
      return this.containsFeature(FEATURE_FLAGS.DEBT_SOLUTIONS);
    },
  },
  methods: {
    ...mapActions({
      debtInit: 'debtsolutions/debtInit',
      setStepToStart: 'debtsolutions/setStepToStart',

    }),
  },
  async mounted() {
    try {
      if ((this.step === undefined || this.step === -1)) {
        await this.debtInit();
        this.setStepToStart();
      } else if (this.investments?.calculator?.scenario?.retirement?.age !== this.personInfo?.retirementAge) {
        this.debtInit();
      }
    } catch (e) {
      this.setStepToStart();
    }
  },
  /* created() {
    if (this.investments?.calculator?.scenario?.retirement?.age !== this.personInfo?.retirementAge) {
      this.debtInit();
    }
  }, */
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'

.debt-solutions-section
  margin: 35px 0 0;
  .top
    padding: 20px 0;
  .bottom
    padding: 15px 0;
    background: #d6d6d6;
  .flex
    padding: 0 15px;
  .text
    font-size: 14px;
  .text-big
    font-size: 18px;
    font-weight: 600;
a.heading_two
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
  color: #ffffff !important;
  @media $display-breakpoints.sm-only
    font-size: 22px;
  @media $display-breakpoints.xs-only
    font-size: 20px;
.right
  padding: 0 0 0 11px;
  @media only screen and (max-width: 768px)
    padding: 0 16px 0 16px;
  .box
    background-image: url("../../assets/debt-freedom-image.png");
    background-position-x: center;
.box
  background-size: cover;
  text-align: center;
  padding: 0 0 30px 0;
  .text-big
    font-size: 32px;
    font-weight: 600;
  .text-medium
    font-size: 24px;
    font-weight: 500;
    padding: 10px 0;
  .text
    font-size: 16px;
    font-weight: 500;
  .box-btn
    margin: 15px 0 0 0;
.not-live
  cursor: text;
a.not-live-link
  cursor text;
  color #cccccc !important
button.theme--light.v-btn.v-btn--disabled.not-live-link
  cursor: text !important;
  color: #cccccc !important
.imageContainer
  position: relative;
.box.imageBox.overlayed
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: .7;
  background: #d6d6d6;
  cursor: text !important;
.setupLeft
  margin-left:-16px;
  margin-top:-16px;
</style>
