<i18n src='../../locales.json'></i18n>
<template>
  <Message
    :show-msg="crcEnabled"
    type="warning"
    :link-label="$t('L_start_a_new_policy_for_adult_child')"
    :message="$t('L_your_adult_child_coverage_expires')"
    @click="startNewPolicy"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '@/shared/constants';
import Message from '@/components/Message.vue';

export default {
  name: 'ChildRiderAlert',
  components: {
    Message,
  },
  props: {
    indexPolicy: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    ...mapGetters(['containsFeature']),
    crcEnabled() {
      return this.containsFeature(FEATURE_FLAGS.POL_CHILDCONVERT);
    },
  },
  methods: {
    ...mapActions('childrider', {
      crcStart: 'crcStart',
    }),
    async startNewPolicy() {
      if (this.indexPolicy !== -1) {
        // TODO do this only on OR link click for "Policy Details, Go click"
        await this.$store.dispatch('policy/changePolicy', { selectedIndex: this.indexPolicy });
      }
      // TODO: maybe show a loading animation
      this.crcStart()
        .then(() => {
          this.$router.push({ name: 'ChildRiderLayout' });
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
  .font-size--13
    font-size: 13px !important;
  .full_width
    width: 100% !important;
</style>
