<i18n src='./locales.json'></i18n>
<template>
  <v-container style="max-width:1024px; margin:auto; margin-top:16px;">
    <!-- My Primerica Header -->
    <v-layout fill-height row wrap>
      <v-flex align-center xs12>
        <v-layout row wrap
                  class="my-primerica-header-layout white--text column-at-xs"
        >
          <v-flex
            d-flex
          >
            <v-layout
              mb-3
            >
              <!-- My Primerica Header -->
              <v-flex
                shrink
                mr-3
                d-flex
                align-center
              >
                <img alt="Debt Solutions Icon" src="../../assets/debt-solutions-icon.svg">
              </v-flex>
              <v-flex
                d-flex
                align-center
              >
                <h2
                  role="heading"
                  aria-level="1"
                  class="heading_two"
                >
                  {{ $t('L_my_primerica_debt_solution') }}
                </h2>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout>
      <!-- Policy Overview -->
      <v-flex xs12>
        <v-tabs
          grow
          color="white"
          class="pri-tabs"
          slider-color="transparent"
          aria-orientation="horizontal"
          fixed-tabs
          v-model="activeTab"
        >
          <v-tab ref="mydebt" @click="$router.replace({name: 'MyDebt'})">
            <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_my_debt') }}</span>
          </v-tab>
          <v-tab ref="myplan" @click="$router.replace({name: 'MyPlan'})">
            <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_my_plan') }}</span>
          </v-tab>
          <v-tab ref="learnmoredebt" @click="$router.replace({name: 'LearnMoreDebt'})">
            <span :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--16'">{{ $t('L_learn_more') }}</span>
          </v-tab>

          <v-tab-item>
            <MyDebt @contact-your-rep="callContactRep" />
          </v-tab-item>
          <v-tab-item>
            <MyPlan />
          </v-tab-item>
          <v-tab-item>
            <LearnMore @contact-your-rep="callContactRep" />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import sendRepEmail from '@/components/ContactRep/sendEmail';
import MyDebt from '../MyDebt/MyDebt.vue';
import MyPlan from '../MyPlan/MyPlan.vue';
import LearnMore from '../LearnMore/LearnMore.vue';

export default {
  name: 'DebtSolutionsPage',
  data: () => ({
    activeTab: 0,
  }),
  mounted() {
    this.activeTab = this.getActiveTab(this.$route.name);
  },
  methods: {
    ...mapActions('customer', ['contactRepresentative']),
    sendRepEmail(emailPayload) {
      sendRepEmail.bind(this)(this.contactRepresentative, emailPayload);
    },
    contact_your_rep_close() {
      this.contactYourRep = false;
    },
    callContactRep() {
      this.contactYourRep = true;
    },
    getActiveTab(routeName) {
      const tabs = {
        MyDebt: 0,
        MyPlan: 1,
        LearnMoreDebt: 2,
      };
      return tabs[routeName] || 0;
    },
  },
  components: {
    MyDebt,
    MyPlan,
    LearnMore,
  },
  computed: {
    ...mapGetters('policy', ['currentPolicy']),
  },
};
</script>


<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
</style>

