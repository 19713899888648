<i18n src='./locales.json'></i18n>

<template>
  <v-layout class="top debt-solutions-no-plan-top align-center text-xs-center" row wrap>
    <v-flex xs12 md7 class="content">
      <h2 class="heading_two font-weight-medium">
        {{ $t('L_a_faster_simpler_path_to') }}
      </h2>
      <h4 class="heading_four font-weight-medium">
        {{ $t('L_donot_let_debt_keep') }}
      </h4>
    </v-flex>
    <v-flex xs12 md5 class="learn-more">
      <v-layout row fill-height align-center justify-center>
        <v-btn
          block
          outline
          color="secondary"
          class="btn_learn_more"
          @click="goToLearnMoreDS"
          :aria-label="$t('L_learn_more')"
        >
          {{ $t('L_learn_more') }}
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>

export default {
  name: 'DebtSolutionsNoPlanTop',
  methods: {
    goToLearnMoreDS() {
      if (this.$route.path !== '/debtsolutions/learnmoredebt') {
        this.$router.push({ name: 'LearnMoreDebt' });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables';
.debt-solutions-no-plan-top
  .v-btn
    background-color: #063562 !important;
    color: #FFFFFF !important;
    width: 100%;
    max-width: 288px;
    margin-right: 0px;
    margin-left: 0px;
  .v-btn__content
    color: #FFFFFF !important;

  .textBlue
    color: #063562;
  .learn-more
    padding: 0 15px;
  .font-weight-medium
    font-weight: 500;
  .btn_learn_more
    width: 100%;
    max-width: 288px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 18px;
    background-color: #063562 !important;
    color: #FFFFFF !important;

</style>
