<i18n src="./locales.json"></i18n>

<template>
  <v-content role="presentation" id="maintenance">
    <v-container text-xs-center class="maintenance-content">
      <v-layout row wrap>
        <v-flex xs12 text-xs-right>
          <locale-toggle
            :current-locale="currentLocale"
            :languages="languages"
            :display="languageFlag"
            :display-icon="true"
            @update-locale="updateLocale"
            submenu-location=""
          />
        </v-flex>
      </v-layout>
      <v-layout class="widthForContent">
        <v-flex text-xs-center mt-4 mb-4>
          <v-layout row wrap />
          <v-layout row wrap>
            <v-flex xs12 mb-4 pb-4>
              <img
                height="82"
                width="82"
                src="@/assets/info-icon.svg"
                class="mr-2"
                alt="info icon"
              >
            </v-flex>
            <v-flex xs12 class="maintenance_title mb-4 pb-4">
              {{ $t('L_maintenance_title') }}
            </v-flex>
            <v-flex xs12 class="maintenance_prompt">
              {{ $t('L_maintenance_desc') }}
            </v-flex>
            <v-flex xs12 class="maintenance_prompt">
              {{ $t('L_maintenance_footer') }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COUNTRIES } from '@/shared/constants';
import Config from '@/shared/util/config';
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';

export default {
  name: 'Maintenance',
  components: {
    LocaleToggle,
  },
  data: () => ({
    deviceType: null,
    languagesPrefs: Config.get('languagePreferences'),
    languages: [],
  }),
  computed: {
    ...mapGetters({
      currentLocale: 'getLocale',
      containsFeature: 'containsFeature',
    }),
    ...mapGetters('customer', ['customerProfile']),
    languageFlag() {
      if (this.containsFeature('LANGUAGE-TOGGLE')) {
        if (this.languages?.length > 1) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['changeLocale']),
    updateLocale(language) {
      this.changeLocale(language);
    },
    validateLanguagePreference() {
      if (!this.languagesPrefs || this.languagesPrefs.length === 0) {
        this.languagePrefs = ['en_US', 'en_CA'];
      }

      if (this.customerProfile.country === COUNTRIES.CANADA) {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_CA') > 0);
        if (this.currentLocale?.indexOf('_CA') === -1) {
          this.changeLocale('en_CA');
        }
      } else {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_US') > 0);
        if (this.currentLocale?.indexOf('_US') === -1) {
          this.changeLocale('en_US');
        }
      }
    },
  },
  async mounted() {
    this.validateLanguagePreference();
  },
};
</script>

<style lang="stylus" scoped>
#maintenance {
  background-color: #FFFFFF;
  height: 100%;
}
.maintenance-canvas {
  max-width: 100% !important;
  height: 100%;
}
.maintenance-content {
  max-width: 100% !important;
  height: 80%;
  @media only screen and (min-width: 760px) {
    max-width: 75% !important;
    height: 80%;
  }
}
.maintenance_title {
  font-size: 24px !important;
}
.maintenance_prompt {
  font-size: 18px !important;
}
#maintenance p {
  color: #333 !important;
}
#maintenance a.text-decoration-none {
  text-decoration: none !important;
}
a.underline-link {
  text-decoration:underline !important;
}
.pad-bottom {
  padding-bottom: 100px;
}
.info-img{
  height: 104px;
  width: 104px;
}
.btn_white {
  background-color: white !important;
}
</style>
