<i18n src="../../locales.json"></i18n>
<template>
  <v-dialog
    :value="showModal"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="800px"
  >
    <v-card class="pb-4">
      <v-card-title class="justify-center">
        <h3 role="heading" aria-level="2" class="card-title pt-1 font-weight-regular">
          {{ $t('L_child_rider_exchange_package') }}
        </h3>
      </v-card-title>
      <v-spacer class="pt-2" />
      <v-card-text
        class="text-content mb-4"
      >
        <v-card
          class="scroll-y"
        >
          <v-card-text
            id="scroll-target"
            v-scroll:#scroll-target="onScroll"
            column
            align-center
            justify-center
            style="max-height: 400px;"
          />
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-layout row wrap justify-center>
          <v-flex xs8 md6>
            <v-btn
              id="btn_close"
              block
              color="primary"
              @click="close"
            >
              {{ $t('L_close') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ChildRiderExchangePackage',
  data() {
    return {
      offsetTop: 0,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },
};
</script>
