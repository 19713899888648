import Config from './config';

const WARNING_TIMEOUT = 270; // seconds 270
const SESSION_TIMEOUT = 300; // seconds 300
const NAMESPACE = `pri.pc2.${Config.get('env')}.`;

export class Session {
  interval;

  static get(key, isLocal) {
    return this.getStorage(isLocal).getItem(`${NAMESPACE}${key}`);
  }

  static set(key, value, isLocal) {
    this.getStorage(isLocal).setItem(`${NAMESPACE}${key}`, value);
  }

  static remove(key, isLocal) {
    this.getStorage(isLocal).removeItem(`${NAMESPACE}${key}`);
  }

  static clear(isLocal) {
    this.getStorage(isLocal).clear();
  }

  static getStorage(isLocal) {
    return isLocal ? window.localStorage : window.sessionStorage;
  }

  static now() {
    return Math.floor(Date.now() / 1000);
  }

  static getLastActive() {
    return Number(this.get('last_active'));
  }

  static setLastActive(lastActive) {
    this.set('last_active', lastActive);
  }

  static resetExpiration() {
    this.setLastActive(this.now());
  }

  static timer(continueCallback, expiredCallback) {
    return () => {
      const lastActive = this.getLastActive();
      if ((this.now() - lastActive) > SESSION_TIMEOUT) {
        expiredCallback();
        return false;
      }
      if ((this.now() - lastActive) > (WARNING_TIMEOUT)) {
        continueCallback();
        return false;
      }
      return true;
    };
  }
}
