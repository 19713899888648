import { render, staticRenderFns } from "./ConfirmBeneficiary.vue?vue&type=template&id=39e6151d&scoped=true"
import script from "./ConfirmBeneficiary.vue?vue&type=script&lang=js"
export * from "./ConfirmBeneficiary.vue?vue&type=script&lang=js"
import style0 from "./ConfirmBeneficiary.vue?vue&type=style&index=0&id=39e6151d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e6151d",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FBeneficiaries%2Fcomponents%2FUpdateBeneficiaries%2FConfirmBeneficiary.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports