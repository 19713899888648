<i18n src='./locales.json'></i18n>

<template>
  <div id="alerts">
    <Alert v-for="(alert, index) in filteredAlerts"
           :key="index"
           :type="alert.type"
           :details="alert.details"
           :index="alert.index"
           :dismissible="true"
           :color="alert.type"
           :icon="alert.icon ? alert.icon : ''"
           :new-alert-type="true"
           :auto-dismiss="alert.autoDismiss"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Alert from '@/components/Alert.vue';

export default {
  name: 'Alerts',
  props: {
    context: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    Alert,
  },
  computed: {
    ...mapGetters(['alerts']),
    filteredAlerts() {
      return (this.context) ? this.alerts.filter(alert => alert.context === this.context) : this.alerts;
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'

#alerts
  .v-alert.error
    position: inherit;
  .v-expansion-panel
    box-shadow: none;
  .theme--light.v-icon
    color: white;
  .header
    padding-left: 10px;
  .details
    padding-left: 34px;

</style>
