<template>
  <div>
    <v-radio-group
      :row="horizontal ? true : false"
      v-model="formInput.$model"
      class="pb-0"
      :label="label"
      :hint="hint"
      :color="color"
      :error-messages="errorMessages"
      @input="onInput"
      @blur="onBlur"
      role="group"
    >
      <v-radio
        v-for="(option , i) in options"
        :id="option.id || 'radio_input_'+i"
        class="pb-2"
        :key="option.name"
        :label="option.label"
        :value="option.value"
      />
    </v-radio-group>
  </div>
</template>

<script>
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'RadioButtonGroup',
  extends: BaseTextField,
  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    modelName: 'selectedOption',
    selectedOption: '',
  }),
};
</script>
