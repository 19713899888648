import MyRep from './MyRep.vue';

export default {
  name: 'Representative',
  path: 'rep',
  component: MyRep,
  meta: {
    pageTitle: 'Policy.Rep',
    indexOfRoute: 0,
  },
};
