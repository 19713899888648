import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enUS from '../locales/en_US.json';
import esUs from '../locales/es_US.json';
import frCa from '../locales/fr_CA.json';
import enCa from '../locales/en_CA.json';

Vue.use(VueI18n);
const defaultLocale = 'en_US';
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLocale,
  messages: {
    en_US: enUS,
    es_US: esUs,
    fr_CA: frCa,
    en_CA: enCa,
  },
  silentTranslationWarn: true,
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
        maximumFractionDigits: 0,
      },
    },
  },
});

const localesLoaded = [i18n.locale];

export const loadLocale = async (locale) => {
  if (i18n.locale !== locale) {
    if (localesLoaded.includes(locale)) {
      // if the language was already loaded, simply update the locale on the i18n object
      i18n.locale = locale;
    } else {
      // otherwise, load the locale and set it...
      const c = await import(`@/locales/${locale}.json`);
      const localeContent = c;
      if (localeContent) {
        i18n.setLocaleMessage(locale, localeContent.default);
        i18n.locale = locale;
        localesLoaded.push(locale);
      }
    }
  }
};

export default i18n;
