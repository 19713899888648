<i18n src='../locales.json'></i18n>

<template>
  <v-flex
    xs12
    sm6
    md6
    class="pa8"
  >
    <MessageDialog
      :show-dialog="showPasswordHasChangedDialog"
      :has-button-two="false"
      type="success"
      :title="$t('L_password_has_been_updated')"
      :message="$t('L_will_now_be_logged_out_and_you_will_have_to_log_back_in_using_your_new_password')"
      :button-one-label="$t('L_OK')"
      :is-persistent="true"
      @buttonOneAction="startLogoff"
    />

    <CardTemplate
      id="update_password_card"
    >
      <img
        slot="icon"
        class="account-img"
        src="../assets/update-password.svg"
        alt=""
        role="presentation"
      >
      <span
        slot="title"
        v-html="$t('L_update_password')"
      />
      <span slot="content">***********</span>
      <a
        v-ada-modal
        class="5"
        slot="action"
        href="javascript:void(0)"
        role="button"
        @click="showUpdatePasswordDialog = true"
        id="update_password_trigger"
        :aria-label="$t('L_update_password')"
      >{{ $t('L_edit') }}</a>
    </CardTemplate>
    <MyAccountDialog
      :header="$t('L_update_password')"
      :show="showUpdatePasswordDialog"
      @save="updatePassword"
      @close="closeDialog"
      :close-is-active="closeIsActive"
      :enable-save-button="enableSaveButton"
      save-changes-btn-id="save_user_password"
      close-btn-id="close_user_password"
      close-btn-class="update_password_close"
      modal-class="update_password_modal"
    >
      <v-layout
        row
        wrap
        slot="content"
      >
        <form @submit.prevent>
          <v-flex xs12>
            <PasswordInput
              v-model="currentPassword"
              :form-input="$v.currentPassword"
              :label="$t('L_enter_current_password')"
              id-prop="enter_current_password"
              enable-show-hide
              valid-icon="check_circle"
              enable-masking
            />
          </v-flex>
          <v-flex xs12>
            <PasswordForm
              v-model="password"
              :password-label="$t('L_enter_new_password')"
              :confirm-password-label="$t('L_confrim_new_password')"
              :email="customerProfile.email"
              new-pass-id="enter_new_password"
              confirm-pass-id="confirm_new_password"
              @input="onPasswordInput"
              :show-tool-tip="false"
            />
          </v-flex>
        </form>
      </v-layout>
    </MyAccountDialog>
  </v-flex>
</template>
<script>
import PasswordForm from '@/components/PasswordForm/PasswordForm.vue';
import { PasswordInput } from '@/components/Inputs';
import MessageDialog from '@/components/MessageDialog.vue';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';

export function setCloseIsActive() {
  this.closeIsActive = true;
}

export function enableSaveButton(newValue) {
  this.closeIsActive = !newValue;
}

export function onResponse() {
  /*
    * Emit custom actions when user successfully saves changes to: Password
    * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
    */
  const i = this.$dynatrace.enterAction('account_settings_changes_saved_password');
  this.$dynatrace.leaveAction(i);
  try {
    this.clearForm();
    setTimeout(setCloseIsActive.bind(this), 500);
  } catch (error) {
    /* TODO: UnhandledPromiseRejection */
  }
}
export function showDialog() { this.showUpdatePasswordDialog = true; }

export default {
  name: 'UpdatePassword',
  data() {
    return {
      password: '',
      confirmPassword: '',
      currentPassword: '',
      showUpdatePasswordDialog: false,
      closeIsActive: false,
      showPasswordHasChangedDialog: false,
    };
  },
  components: {
    CardTemplate,
    MyAccountDialog,
    PasswordForm,
    PasswordInput,
    MessageDialog,
  },
  validations: {
    password: {
      required,
    },
    currentPassword: {
      required,
    },
    confirmPassword: {
      required,
    },
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters({
      locale: 'getLocale',
      isMobileApp: 'isMobileApp',
    }),
    enableSaveButton() {
      return this.$v.$anyDirty && !this.$v.$invalid;
    },
  },
  methods: {
    onPasswordInput({ password, confirmPassword }) {
      this.$v.password.$model = password;
      this.$v.confirmPassword.$model = confirmPassword;
    },
    closeDialog() {
      this.showUpdatePasswordDialog = false;
      this.clearForm();
    },
    clearForm() {
      this.password = undefined;
      this.currentPassword = '';
      this.confirmPassword = '';
      window.postMessage('form-reset', `${window.location.protocol}//${window.location.hostname}`);
      this.$v.$reset();
    },
    async updatePassword() {
      const updatePassword = {
        username: this.customerProfile.email,
        oldPassword: this.currentPassword,
        newPassword: this.password,
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
        locale: this.locale,
      };
      if (updatePassword.username) {
        this.$store.dispatch('home/updatePassword', updatePassword).then(onResponse.bind(this))
          .then(() => {
            this.showPasswordHasChangedDialog = true;
          })
          .catch((e) => { throw e; });
      }
    },
    async startLogoff() {
      this.showPasswordHasChangedDialog = false;
      await this.$store.dispatch('resetStore');
      if (this.isMobileApp) {
        await this.$auth.logout(); // ! clear browser session and revoke tokens
        await this.$router.push({ name: 'PostLogoutPorch' });
      } else {
        this.$auth.logout(); // ! clear browser session and revoke tokens
      }
    },

  },
  watch: {
    enableSaveButton,
  },
  async mounted() {
    const clickTarget = this.$el.querySelector('.clickable');
    if (clickTarget) {
      clickTarget.addEventListener('click', showDialog.bind(this));
    }
  },
};
</script>

