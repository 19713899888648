import GuidanceCalculator from './GuidanceCalculator.vue';

export default {
  name: 'GuidanceCalculator',
  path: 'guidance',
  component: GuidanceCalculator,
  params: {
    page: 'guidance',
  },
  meta: {
    pageTitle: 'Guidance.Calculator',
    indexOfRoute: 1,
  },
};
