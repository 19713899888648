<i18n src='../locales.json'></i18n>
<template>
  <v-content class="pt-0" id="PaymentPrintable">
    <div row wrap style="margin-bottom:5px;">
      <div style="margin-top:20px; margin-bottom:80px;" class="showOnlyForPrint">
        <img
          src="https://www.primerica.com/public/images/primerica-logo.png"
          alt="PrimericaLogo"
        >
      </div>
    </div>
    <div row wrap style="margin-bottom:80px; font-size:16px; font-weight: bold;" class="showOnlyForPrint">
      <div style="display:inline;">
        {{ $t('L_payment_authorization') }}
      </div>
      <div style="display:inline;">
        {{ todayDate }}
      </div>
    </div>
    <div row wrap style="margin-bottom:20px;" class="addSpaceBelow">
      <div style="font-size:16px; font-weight:bold; display: inline;">
        <span>{{ $t('L_your_payment_authorization_has_been_received') }}</span>
      </div>
    </div>

    <div row wrap style="margin-bottom:20px;" class="addSpaceBelow">
      <div xs12 style="font-size:16px;">
        <i18n
          path="L_we_have_received_your_payment_authorization_for_your_life"
          tag="span"
        >
          <span class="font-weight-regular">{{ getPolicyNumber }}</span>
        </i18n>
      </div>
    </div>

    <div row wrap style="margin-bottom:20px;">
      <div xs12 style="font-size:14px;">
        <span style="font-weight:regular;">{{ $t('L_your_payment_is_being_processed') }}</span>
      </div>
    </div>


    <div row wrap style="margin-bottom:8px;">
      <div xs12 style="font-size:16px; font-weight:bold">
        <span>{{ $t('L_here_are_the_details_of_your_authorizations') }}</span>
      </div>
    </div>

    <v-divider class="customDividier addSpaceBelow" />

    <div row wrap style="margin-bottom:20px;margin-top:10px;">
      <div xs12 class="addHalfSpaceBelow">
        <span style="font-size:12px;">{{ $t('L_payment_amount') }}</span>
      </div>
      <div xs12 style="font-size:16px; font-weight:bold">
        $ {{ getPaymentAmount }}
      </div>
    </div>

    <div row wrap style="margin-bottom:10px;">
      <div style="font-size:12px;" class="addHalfSpaceBelow">
        {{ $t('L_bank_account') }}
      </div>
      <div xs12 style="font-size:16px; font-weight:bold">
        {{ getPaymentMethod }}
      </div>
      <div xs12 style="font-size:14px;font-weight: bold;">
        {{ getBankName }}
      </div>
    </div>

    <v-divider class="customDividier addSpaceBelow" />

    <div row wrap style="margin-bottom:20px; margin-top:10px;">
      <div xs12 style="font-size:16px">
        <i18n
          path="L_an_email_confirmation_has_also_been_sent_to"
          tag="span"
          class="font-weight-regular"
          style="font-weight:regular"
        >
          <span class="font-weight-bold" style="font-weight:bold;">
            {{ userEmail }}
          </span>
        </i18n>
      </div>
    </div>

    <div>
      <v-layout row wrap class="hideForPrint" v-if="!isMobile">
        <v-flex @click="printDoc" font-size--18 text-xs-left font-weight-bold xs12 mb-4 class="blueText">
          <v-icon color="#0c6dca">
            print
          </v-icon>
          {{ $t('L_print_payment_confirmation') }}
        </v-flex>
      </v-layout>

      <div row wrap text-xs-right style="margin-bottom:20px;" class="hideForPrint">
        <div xs12 style="font-size:16px; font-weight:bold">
          <v-btn color="primary" sm-2 @click="done" :class="$vuetify.breakpoint.xsOnly ?'buttonCSSMobile': 'buttonCSS'">
            {{ $t('L_done') }}
          </v-btn>
        </div>
      </div>
    </div>
    <div style="position:relative; text-align: center;" class="showOnlyForPrint">
      <p style="position:fixed; bottom: 0; width:100%;">
        &#169; {{ getCurrentYear }} Primerica www.myprimerica.com
      </p>
    </div>
  </v-content>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapMutations } from 'vuex';


export const giveDate = (date, isCanada) => {
  if (date.length !== 6) {
    return;
  }
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  const year = `20${date.slice(4, 6)}`;
  // eslint-disable-next-line consistent-return
  return isCanada ? `${year}/${month}/${day}` : `${month}/${day}/${year}`;
};


export default {
  name: 'PayStepFour',
  components: {
  },
  props: {
    // show: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },
    // alertContext: {
    //   type: String,
    //   required: false,
    //   default: 'go_paperless',
    // },
    // policynumber: {
    //   type: String,
    //   required: false,
    //   default: '',
    // },
  },
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', [
      'policies',
      'currentPolicy',
      'routeNameToNavigateBack',
      'selectedPolicyIndex',
      'paymentData',
    ]),
    ...mapGetters({
      locale: 'getLocale',
    }),
    todayDate() {
      return moment().format('MMMM DD, YYYY');
    },
    getCurrentYear() {
      return moment().format('YYYY');
    },
    nextDraftDate() {
      return this.currentPolicy?.paymentInfo?._nextCycleDate ? giveDate(this.currentPolicy?.paymentInfo?._nextCycleDate, false) : '';
    },
    getPolicyNumber() {
      if (this.currentPolicy) {
        return this.currentPolicy.contractId;
      } return '';
    },
    getPaymentAmount() {
      if (this.paymentData && this.paymentData.amount) {
        return `${this.paymentData.amount}`;
      } return '';
    },
    getPaymentMethod() {
      if (this.paymentData && this.paymentData.accountNumber) {
        return `****${this.paymentData?.accountNumber?.slice(-4)} ${this.paymentData.accountType}`;
      } return '';
    },
    getBankName() {
      if (this.paymentData && this.paymentData.bankName) {
        return this.paymentData.bankName;
      } return '';
    },
    isCanadaPolicy() {
      return !!this.currentPolicy?.isCanadaPolicy;
    },
    userEmail() {
      return this.customerProfile.email || this?.currentPolicy?._owner?._email;
    },
    isMobile() {
      const userAgent = navigator?.userAgent;
      let retVal = false;
      retVal = userAgent.indexOf('Android') !== -1 ||
      userAgent.indexOf('webOS') !== -1 ||
      userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1 || userAgent.indexOf('iPod') !== -1 ||
      userAgent.indexOf('IEMobile') !== -1 || userAgent.indexOf('Opera Mini') !== -1 || userAgent.indexOf('BlackBerry') !== -1;
      return retVal;
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument', 'setPaymentStep']),
    printDoc() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const i = this.$dynatrace.enterAction('OneTimePayment_PrintConfirmation_PageDisplayed');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      const contents = document.getElementById('PaymentPrintable').innerHTML;
      const frame1 = document.createElement('iframe');
      frame1.name = 'frame1';
      frame1.style.position = 'absolute';
      frame1.style.top = '-1000000px';
      document.body.appendChild(frame1);
      // eslint-disable-next-line no-nested-ternary
      const frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
      frameDoc.document.open();
      if (this.locale === 'fr_CA') {
        frameDoc.document.write('<html lang="fr"><head><title>MyPrimerica</title>');
      } else {
        frameDoc.document.write('<html lang="en"><head><title>MyPrimerica</title>');
      }
      // frameDoc.document.write('<link rel="stylesheet" type="text/css" href="/css/print.css"/>');
      frameDoc.document.write('<style>');
      frameDoc.document.write('.hideForPrint {');
      frameDoc.document.write('  display: none;');
      frameDoc.document.write('}');
      frameDoc.document.write('.showOnlyForPrint {');
      frameDoc.document.write('display: block;');
      frameDoc.document.write('}');
      frameDoc.document.write('.hideinlineForPrint {');
      frameDoc.document.write('display: none;');
      frameDoc.document.write('}');
      frameDoc.document.write('.addSpaceBelow {');
      frameDoc.document.write('margin-bottom: 25px;');
      frameDoc.document.write('}');
      frameDoc.document.write('.addHalfSpaceBelow {');
      frameDoc.document.write('margin-bottom: 15px;');
      frameDoc.document.write('}');
      frameDoc.document.write('</style>');
      frameDoc.document.write('</head><body>');
      frameDoc.document.write(contents);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();
      setTimeout(() => {
        window.frames.frame1.focus();
        window.frames.frame1.print();
        document.body.removeChild(frame1);
      }, 500);
      return false;
    },
    done() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const i = this.$dynatrace.enterAction('OneTimePayment_Done_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('goToStepFive');
    },
  },
  mounted() {
  },
};
</script>

<style>
.bg-light-grey {
  background-color: #f5f5f5;
}
.blueText {
  color: #0c6dca;
}
.marginbottomline {
  margin-bottom: 15px;
}
.marginbottomhalfline {
  margin-bottom: 8px;
}
.customDividier {
  border-top: 4px solid rgba(6, 53, 98, 0.3);
}
.showOnlyForPrint {
  display: none;
}
.hideinlineForPrint {
  display: inline;
}
.buttonCSS {
  float: right;
  max-width: 300px;
  width: 300px;
  margin: auto;
}
.buttonCSSMobile {
  float: left;
  max-width: 226px;
  width: 226px;
}
</style>
