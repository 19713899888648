<i18n src="./locales.json"></i18n>

<template>
  <v-content role="presentation" class="white_background">
    <v-container text-xs-center>
      <v-layout row wrap>
        <v-flex xs12 text-xs-right>
          <locale-toggle
            :current-locale="currentLocale"
            :languages="languages"
            :display="languageFlag"
            :display-icon="true"
            @update-locale="updateLocale"
            submenu-location=""
          />
        </v-flex>
      </v-layout>
      <v-layout class="widthForContent">
        <v-flex text-xs-center mt-4 mb-4>
          <v-layout row wrap>
            <v-flex xs12 text-xs-center mt-4 mb-4>
              <img src="@/assets/primerica-logo.svg" height="70" alt="Primerica Beta">
            </v-flex>
          </v-layout>
          <div class="mx-auto mb-xs-12" :class="$vuetify.breakpoint.xsOnly ? 'overall-div-card-smaller' : 'overall-div-card-larger'">
            <v-layout row wrap ml-4 mr-4>
              <v-flex text-xs-center xs12 mt-4>
                <span mb-4 class="css_app_update">
                  {{ $t('L_app_update') }}
                </span>
              </v-flex>
              <v-flex xs12 text-xs-left mt-4 mb-4 ml-4 mr-4 class="regular_body_text">
                {{ $t('L_we_have_recently_added_some_updates') }}
              </v-flex>
              <v-flex xs12 text-xs-left mb-4 ml-4 mr-4 pb-4 class="regular_body_text">
                {{ $t('L_it_looks_like_your_current_version_is_out_of_date') }}
              </v-flex>
            </v-layout>
          </div>
          <v-layout row wrap text-xs-center class="outerlayout">
            <div class="innerLayout">
              <v-flex xs12 mb-4>
                <v-btn block ml-4 mr-4 pl-4 pr-4 color="primary" class="btn_default" @click="goToUpdate" target="_blank">
                  {{ $t('L_update_now') }}
                </v-btn>
              </v-flex>
              <v-flex xs12 v-if="enableSkipButton">
                <v-btn block ml-4 mr-4 pl-4 pr-4 class="btn_default btn_white" @click="skip">
                  {{ $t('L_later') }}
                </v-btn>
              </v-flex>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { COUNTRIES } from '@/shared/constants';
import Config from '@/shared/util/config';
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';
import { openInNewWindow } from '@/mixins/openNewWindow';

export default {
  name: 'AppUpdate',
  components: {
    LocaleToggle,
  },
  data: () => ({
    deviceType: null,
    languagesPrefs: Config.get('languagePreferences'),
    languages: ['en_US', 'fr_CA'],
  }),
  mixins: [openInNewWindow],
  computed: {
    ...mapGetters({
      currentLocale: 'getLocale',
      isMaintenanceMode: 'isMaintenanceMode',
      recommendedAppVersion: 'recommendedAppVersion',
      isCriticalUpdateAvailable: 'isCriticalUpdateAvailable',
      isOptionalUpdateAvailable: 'isOptionalUpdateAvailable',
      isIos: 'isIos',
      isAndroid: 'isAndroid',
      getAndroidPlayStoreLink: 'getAndroidPlayStoreLink',
      getiOSStoreLink: 'getiOSStoreLink',
    }),
    ...mapGetters('customer', ['customerProfile']),
    languageFlag() {
      return true;
    },
    enableSkipButton() {
      return !this.isCriticalUpdateAvailable;
    },
  },
  methods: {
    ...mapActions(['changeLocalePreLogin']),
    async goToUpdate() {
      let embedUrl = '';
      if (this.isAndroid) {
        embedUrl = this.getAndroidPlayStoreLink;
      } else if (this.isIos) {
        embedUrl = this.getiOSStoreLink;
      }
      await this.openInNewWindow({ url: embedUrl, target: '_blank' });
    },
    skip() {
      if (this.isMaintenanceMode) {
        this.$router.push({ name: 'MaintenanceMode' });
      } else {
        this.$router.push({ name: 'Dashboard' });
      }
    },
    updateLocale(language) {
      this.changeLocalePreLogin(language);
    },
    validateLanguagePreference() {
      this.changeLocalePreLogin('en_US');
    },
  },
  async mounted() {
    // this.deviceType = navigator?.userAgent;
    // await this.$store.dispatch('policy/getPolicyOverview');
    this.validateLanguagePreference();
    // const ua = navigator?.userAgent;
    // // const isChrome = ua.indexOf('Chrome/') !== -1 && ua.indexOf('Chromium/') === -1;
    // // const isSafari = ua.indexOf('Safari/') !== -1 && ua.indexOf('Chrome/') === -1 && ua.indexOf('Chromium/') === -1;
    // // const isEdge = ua.indexOf('Edg') !== -1;
    // const isFirefox = ua.indexOf('Firefox/xyz') !== -1;
    // const isInternetExplorer = ua.indexOf('Trident/') !== -1 && (ua.indexOf('rv:') !== -1 || ua.indexOf('MSIE') !== -1);
    // const exclusionList = isFirefox || isInternetExplore || this.deviceType === null;
    // if (exclusionList) {
    //   this.relatedToUnsupportedBrowser = false;
    //   // this.relatedToUnsupportedBrowser = true;
    // }
  },
};
</script>

<style lang="stylus" scoped>
.background {
  min-height: 100%;
  background-image: linear-gradient(to top, #607c97, #063562);
}
.white_background {
  min-height: 100%;
  background-color: white;
}
.css_locale {
  background-color: white;
}
.css_app_update {
  font-size: 20px;
  font-weight: bold;
}
.regular_body_text {
  font-size: 20px;
}
.language-icon {
  color: white !imporant;
}
.container {
  max-width: 960px !important;
}
.widthForContent {
  max-width: 600px !important;
  margin:auto;
}
.btn_default {
  min-width: 250px;
  max-width: 350px;
  margin: auto;
  height: 48px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: white !important;
}
.outerlayout {
  padding-bottom: 50px;
}
.innerLayout {
  zwidth: 50% !important;
  margin: auto !important;
}
.btn_white {
  background-color: white !important;
  color: black !important;
}
</style>
