<i18n src='./locales.json'></i18n>

<template>
  <div id="full_screen_loading">
    <v-toolbar :height="$vuetify.breakpoint.smAndDown ? '64px' : '80px'" class="elevation-0">
      <img src="../../assets/primerica-color-logo.svg" alt="" role="presentation">
    </v-toolbar>

    <div id="building_house">
      <div class="card">
        <div class="child">
          <svg width="133px" height="180px" viewBox="0 0 133 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Loading</title>
            <defs>
              <filter id="a" width="124.1%" height="138.1%" x="-12%" y="-19%" filterUnits="objectBoundingBox">
                <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.784901495 0 0 0 0 0.784901495 0 0 0 0 0.784901495 0 0 0 0.5 0" />
                <feMerge>
                  <feMergeNode in="shadowMatrixOuter1" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <filter x="-19.8%" y="-33.5%" width="139.7%" height="167.0%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="0" dy="7" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
                <feMerge>
                  <feMergeNode in="shadowMatrixOuter1" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <polygon
                id="path-2"
                points="17 42.4727273 31.4886364 42.4727273 31.4886364 104.434659 153.363636
              104.434659 153.363636 42.4727273 166.999148 42.4727273 137.665625 27.7752841 137.657102 5.52840909
              117.362784 5.52840909 117.362784 17.6025568 92.2573864 5"
              />
              <filter x="-2.0%" y="-3.0%" width="104.0%" height="106.0%" filterUnits="objectBoundingBox" id="filter-3">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix values="0 0 0 0 0.968627451   0 0 0 0 0.984313725   0 0 0 0 1  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" />
              </filter>
            </defs>
            <g fill="none" fill-rule="evenodd" filter="url(#a)">
              <rect width="132" height="83" x=".5" y=".5" fill="#F2F2F2" stroke="#6C97B7" stroke-opacity=".313" rx="3" />
              <rect width="87" height="23" x="36" y="10" fill="#D8D8D8" rx="3" />
              <circle cx="20" cy="20" r="10" fill="#D8D8D8" />
              <rect width="21" height="39" x="10" y="35" fill="#D8D8D8" rx="3" />
              <rect width="84" height="35" x="39" y="39" fill="#D8D8D8" rx="3" />
            </g>
            <g id="Loading-Screen" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Loading-Screen-Refresh" transform="translate(-421.000000, -155.000000)">
                <g id="Group-5" filter="url(#filter-1)" transform="translate(415.000000, 171.000000) scale(.78)">
                  <path
                    d="M17.0294956,46.4841309 L27.4993896,46.4841309 L27.4993896,46.4841309 L27.4993896,107.407776
                  C27.4993896,107.960061 27.9471049,108.407776 28.4993896,108.407776 L156.347107,108.407776 C156.899392,
                  108.407776 157.347107,107.960061 157.347107,107.407776 L157.347107,46.4841309 L157.347107,46.4841309
                  L167.014816,46.4841309 C169.223955,46.4841309 171.014816,44.6932699 171.014816,42.4841309 C171.014816,
                  40.971932 170.162035,39.589004 168.810842,38.9100169 L142.725339,25.8018057 C142.049743,25.4623122
                  141.623352,24.7708482 141.623352,24.0147488 L141.623352,3.55023193 C141.623352,2.44566243 140.727922,
                  1.55023193 139.623352,1.55023193 L115.342651,1.55023193 C114.238082,1.55023193 113.342651,2.44566243
                  113.342651,3.55023193 L113.342651,7.86231195 C113.342651,8.96688145 112.447221,9.86231195 111.342651,
                  9.86231195 C111.030714,9.86231195 110.723102,9.7893467 110.444396,9.64924713 L92.7295613,0.744366163
                  C92.4481006,0.6028817 92.1164254,0.602295598 91.8344664,0.742784446 L15.2456269,38.9039336
                  C13.2683385,39.8891372 12.4640949,42.2907113 13.4492984,44.2679996 C14.1258733,45.6258751 15.5123999,
                  46.4841309 17.0294956,46.4841309 Z"
                    id="Fill-1"
                    fill="#6C97B7"
                  />
                  <g id="Fill-1-Copy">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2" />
                    <polygon
                      fill="#FFFFFF"
                      fill-rule="evenodd"
                      points="17 42.4727273 31.4886364 42.4727273 31.4886364
                    104.434659 153.363636 104.434659 153.363636 42.4727273 166.999148 42.4727273 137.665625 27.7752841
                    137.657102 5.52840909 117.362784 5.52840909 117.362784 17.6025568 92.2573864 5"
                    />
                  </g>
                  <polygon
                    id="Rectangle"
                    fill="#FFFFFF"
                    points="35.7496591 41.2792045 149.101932 41.8093182 149.102273
                  99.603125 35.75 99.603125"
                  />
                  <polygon
                    id="chevron_3"
                    fill="#D5E5F0"
                    points="35.75 99.603125 149.102273 99.603125 149.102273
                  77.4440341 35.75 77.4440341"
                  />
                  <polygon
                    id="chevron_2"
                    fill="#E3EEF5"
                    points="35.75 75.7386364 149.102273 75.7386364 149.102273
                  53.5803977 35.75 53.5803977"
                  />
                  <polygon
                    id="chevron_1"
                    fill="#EBF3F8"
                    points="35.7496591 41.2792045 35.7496591 51.8755114 149.101932
                  51.8755114 149.101932 41.8093182 92.4257955 12.6709659"
                  />
                </g>
              </g>
            </g>
          </svg>
          <v-layout>
            <v-flex id="full_loading">
              <h2 class="heading_two text-xs-center font-weight-medium mb-2">
                {{ $t('L_were_building_your_financial_house') }}
              </h2>
              <h3 class="heading_three text-xs-center">
                {{ $t('L_thanks_for_your_patience') }}
              </h3>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullScreenLoading',
};
</script>
<style lang="stylus" scoped>
@import '~vuetify/src/stylus/main';

@keyframes fill_house {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

#full_screen_loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-image: linear-gradient(to top, rgb(96, 124, 151), rgb(6, 53, 98));

  .card {
    position: absolute;
    width: 100%;
    height: 300px;
    top: 50%;
    margin-top: -100px;
    text-align: center;

    .child {
      box-shadow: 0 3px 4px 1px #00000022;
      position: relative;
      display: inline-block;
      padding: 112px 32px 32px 32px;
      background-image: url('./assets/chevron_bg.svg'), linear-gradient(to bottom, #ffffff, #cfe4ff);
      background-position: bottom left;
      border-radius: 4px;
      width: 522px;

      @media $display-breakpoints.sm-only {
        width: 340px;
      }

      @media $display-breakpoints.xs-only {
        width: 300px;
      }

      svg {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -85px;
        margin-top: -72px;
        width: 170px;

        #bg {
          #Rectangle {
            box-shadow: 2px 0 0 rgba(0, 0, 0, 0.5);
          }
        }

        #chevron_1 {
          opacity: 0.5;
          animation: fill_house;
          animation-duration: 1.5s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-delay: 0.6s;
        }

        #chevron_2 {
          opacity: 0.5;
          animation: fill_house;
          animation-duration: 1.5s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-delay: 0.3s;
        }

        #chevron_3 {
          opacity: 0.5;
          animation: fill_house;
          animation-duration: 1.5s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
        }
      }
    }
  }
}
</style>

