import moment from 'moment';

const error = state => state.error;
const loading = state => state.loading;
const investments = state => state.investments;
const customerProfile = state => state.customerProfile;
const countryOfOrigin = state => state.customerProfile.country;
const binaryInvestmentStatement = state => state.binaryInvestmentStatement;
const agents = state => state.agents;

export {
  error,
  loading,
  investments,
  customerProfile,
  countryOfOrigin,
  binaryInvestmentStatement,
  agents,
};

const getCustServNumForCA = (locale) => {
  if (locale?.indexOf('en_CA') !== -1) {
    return '18003877876';
  } else if (locale?.indexOf('fr_CA') !== -1) {
    return '18002654804';
  }
  return '18003877876';
};

const getCustServNum = (country, locale) => {
  if (country?.toUpperCase() === 'CA') {
    return getCustServNumForCA(locale);
  }
  return '18002574725';
};

export const getCANCustomerNumber = () => locale => getCustServNumForCA(locale);

export const getCustServNumWODash = state => locale => getCustServNum(state.customerProfile.country, locale);

export const getCustServNumWDash = state => (locale) => {
  const customerNumber = getCustServNum(state.customerProfile.country, locale);
  return customerNumber?.length > 0 ?
    customerNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2) $3-$4') : '';
};

export const investmentsSortedByDate = ((state) => {
  const accts = [];
  state.investments.groups.forEach((group) => {
    group.accounts.forEach((account) => {
      accts.push(account);
    });
  });
  accts.sort((a, b) => {
    const aDate = moment(a.inceptionDate, 'MM/DD/YYYY');
    const bDate = moment(b.inceptionDate, 'MM/DD/YYYY');
    return bDate - aDate;
  });
  return accts;
});
