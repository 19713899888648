<i18n src="./locales.json"></i18n>
<template>
  <TwoFactorAction
    v-slot="twofactor"
    :show="canShow"
    :alert-context="alertContext"
    :two-factor-payload="twoFactorPayload"
    :submit-action-func="submitAction"
    :submit-payload="submitActionPayload"
    @error="handleActionFailure"
    @success="handleActionSuccess"
    @close="handleActionClose"
    @gotoupdatecontact="goToUpdateContact"
    @closeverifyphonedialog="handleActionVerifyPhoneClose"
    ref="twofactoryactionacc"
  >
    <v-card id="verify_phone_card" v-if="verifyPhonePageToLand === 'first'" class="text-xs-center">
      <Alerts class="fixed-top-alert" :context="alertContext" />
      <v-card-text>
        <v-layout justify-center>
          <v-flex xs11>
            <h2 class="heading_two mt-4 mb-2">
              {{ $t('L_get_quick_access_to_your_account_with_mobile_verification') }}
            </h2>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-layout>
        <v-flex xs12>
          <v-layout row wrap justify-center>
            <v-flex xs11 mb-4>
              <span class="font-weight-regular">{{ $t(modalContent.message) }}</span>
            </v-flex>
            <v-flex mb-4>
              <span class="font-weight-regular font-size--12">{{ $t('L_message_and_data_rates_may_apply') }}</span>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex offset-xs1 xs10 class="mb-2 mt-2 edit-wrapper">
              <v-btn v-if="!editDisabled" class="pencil-edit-phone" :ripple="false" icon flat color="#063562" aria-label="Edit Phone" @click="editIsDisabled = false">
                <v-icon v-if="editIsDisabled">
                  edit
                </v-icon>
              </v-btn>
              <PhoneNumberInput
                id="verify_phone"
                v-model="phoneNumber"
                :form-input="$v.phoneNumber"
                :place-holder="phoneNumber"
                :label="$t('L_mobile_phone_number')"
                :disabled="editIsDisabled"
                :validation-messages="{
                  notCurrentNumber: $t('L_mobile_number_must_be_different'),
                  required: $t('L_mobile_number_required'),
                }"
              />
            </v-flex>
            <v-flex />
          </v-layout>
          <v-layout justify-center>
            <v-flex xs10>
              <v-btn block :loading="loading" color="primary" class="mb-3" @click="twofactor.start">
                {{ $t(modalContent.buttonText) }}
              </v-btn>
              <v-btn block color="secondary" class="mb-5" id="sms_skip" @click="closeVerify">
                {{ $t('L_skip_for_now') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex />
      </v-layout>
    </v-card>
  </TwoFactorAction>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { PhoneNumberInput, PhoneNumberValidation } from '@/components/Inputs';
import bff from '@/shared/api/bff';
import TwoFactorAction from '../TwoFactorAction/TwoFactorAction.vue';
import Alerts from '../Alerts.vue';
import { ALERT_VIEW_CONTEXT, CHANNELS, FEATURE_FLAGS } from '../../shared/constants';

export default {
  name: 'VerifyPhoneWrapper',
  components: {
    TwoFactorAction,
    PhoneNumberInput,
    Alerts,
  },
  props: {
    allowEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    editIsDisabled: true,
    phoneNumber: '',
    alertContext: ALERT_VIEW_CONTEXT.VERIFY_PHONE,
    submitAction: bff.updateMobileNumber, // TODO: consider go through store
    showSuccessMessage: false,
  }),
  computed: {
    ...mapGetters(['notifications', 'verifyPhonePageToLand']),
    ...mapGetters({
      loading: 'home/loading',
      customerProfile: 'customer/customerProfile',
      featureActive: 'containsFeature',
      locale: 'getLocale',
    }),
    twoFactorPayload() {
      return {
        channel: CHANNELS.SMS,
        phone: this.phoneNumber,
        locale: this.locale,
      };
    },
    mobileNumber() {
      return this.customerProfile.mobilePhone ? this.customerProfile.mobilePhone.trim() : this.phoneNumber;
    },
    canShow() {
      return this.show && this.$store.getters.containsFeature(FEATURE_FLAGS.CUST_VERIFYPHONE);
    },
    isVerifyModal() {
      return !!this.mobileNumber && this.mobileNumber.trim().length > 0;
    },
    modalContent() {
      return {
        message: this.isVerifyModal ? 'L_we_noticed_you_havenot_verified_your_mobile_number_yet' : 'L_no_mobile_number',
        buttonText: this.isVerifyModal ? 'L_yes_use_sms_verification' : 'L_verify_with_text_message',
      };
    },
    editDisabled() {
      return this.isVerifyModal && this.editIsDisabled && !this.allowEdit;
    },
    submitActionPayload() {
      return { mobileNumber: this.phoneNumber };
    },
  },
  methods: {
    ...mapActions(['snoozeVerifyPhone', 'removeNotification', 'updateNotification']),
    ...mapMutations({
      addError: 'addError',
      addAlert: 'addAlert',
    }),
    ...mapActions({
      updateCustomerProfile: 'customer/updateCustomerProfile',
    }),
    async handleActionSuccess() {
      await this.updateCustomerProfile({
        mobilePhone: this.phoneNumber,
        phoneVerified: true,
      });
      // Since the phone is verified we will remove it from sessionStorage
      window.sessionStorage.removeItem('hideVerifyPhone');
      this.addAlert({
        type: 'success',
        details: { message: this.$t('L_phone_has_been_verified') },
        context: ALERT_VIEW_CONTEXT.VERIFY_PHONE,
      });
      this.$emit('show-successful-verify');
      // TODO do we want to close this after success
      /*
      setTimeout(() => {
        this.$emit('handle-action-verify-snooze');
      }, 2500);
      */
    },
    handleActionFailure(error) {
      this.addError({ error, context: this.alertContext });
    },
    handleActionClose() {
      this.$emit('close-verify-phone-dialog');
    },
    handleActionVerifyPhoneClose() {
      this.$emit('close-verify-phone-dialog');
    },
    goToUpdateContact() {
      this.$emit('gotoupdatecontact2');
    },
    async closeVerify() {
      await this.snoozeVerifyPhone();
      this.$emit('close-verify-phone-dialog');
    },
    async bypassPageOne(num) {
      this.phoneNumber = num;
      await this.$refs.twofactoryactionacc.requestOTP(num); // TODO: minimize usage of $refs
    },
    reset() {
      this.phoneNumber = this.mobileNumber.trim();
      this.$v.$reset();
    },
  },
  validations: {
    phoneNumber: {
      ...PhoneNumberValidation,
      required,
    },
  },
  watch: {
    mobileNumber() {
      this.phoneNumber = this.mobileNumber.trim();
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style lang="stylus" scoped>
.edit-wrapper{
  position: relative;
}
button.pencil-edit-phone.v-btn.v-btn--flat.v-btn--icon.theme--light {
  position: absolute !important;
  top: 0 !important;
  right: -20px !important;
}
.fixed-top-alert {
  position: absolute;
  top: -4px;
  z-index: 1;
}
</style>
