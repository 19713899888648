<i18n src='../locales.json'></i18n>

<template>
  <v-content
    class="policy-overview-content pt-0"
    role="presentation"
    :class="$vuetify.breakpoint.lgAndUp ? 'removeLeftMargin' : ''"
  >
    <Alerts context="app" v-if="showAlertsBasedOnEnv" />
    <v-container>
      <v-card
        v-if="relatedToUnsupportedBrowser"
        class="mx-auto mb-xs-12"
        :class="$vuetify.breakpoint.xsOnly ? 'overall-div-card-smaller' : 'overall-div-card-larger'"
      >
        <v-layout row>
          <v-flex text-xs-center>
            <div id="unsupported_browser_title" class="display-2 font-weight-regular mt-sm-12">
              <span font-size--48>
                {{ $t('L_unsupported_browser_title') }}
              </span>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row class="">
          <v-flex text-xs-center>
            <div id="unsupported_browser_prompt" class="title font-weight-regular ">
              <span font-size--16>
                {{ $t('L_unsupported_browser_prompt') }}
              </span>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="pt-4 mb-xs-12">
          <v-flex xs4 text-xs-center pr-2>
            <div class="title font-weight-regular">
              <v-img
                contain
                alt="Primerica"
                class="mx-auto mb-2 maxHeightWidth"
                src="./assets/browser-chrome.png"
              />
              <p>
                <a class="font-weight-regular"
                   :class="$vuetify.breakpoint.mdAndUp ? 'largerFontSizeBrowserName' : 'smallerFontSizeBrowserName'"
                   href="https://www.google.com/chrome/" target="_blank"
                >
                  {{ $t('L_unsupported_browser_google_chrome') }}
                </a>
              </p>
            </div>
          </v-flex>
          <v-flex xs4 text-xs-center pr-2>
            <div class="title font-weight-regular text-center">
              <v-img
                contain
                alt="Primerica"
                class="mx-auto mb-2 maxHeightWidth"
                src="./assets/browser-edge.png"
              />
              <p>
                <a class="font-weight-regular"
                   :class="$vuetify.breakpoint.mdAndUp ? 'largerFontSizeBrowserName' : 'smallerFontSizeBrowserName'"
                   href="https://www.microsoft.com/en-us/edge" target="_blank"
                >{{ $t('L_unsupported_browser_microsoft_edge') }}</a>
              </p>
            </div>
          </v-flex>
          <v-flex xs4 text-xs-center pr-2>
            <div class="title font-weight-regular text-center">
              <v-img
                contain
                alt="Primerica"
                class="mx-auto mb-2 maxHeightWidth"
                src="./assets/browser-safari.png"
              />
              <p>
                <a class="font-weight-regular"
                   :class="$vuetify.breakpoint.mdAndUp ? 'largerFontSizeBrowserName' : 'smallerFontSizeBrowserName'"
                   href="https://www.apple.com/safari/" target="_blank"
                >
                  {{ $t('L_unsupported_browser_apple_safari') }}
                </a>
              </p>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout
        v-if="!relatedToUnsupportedBrowser"
        row
        wrap
        fill-height
        class="white--text"
        :class="{ 'mb-4': $vuetify.breakpoint.mdAndUp }"
      >
        <v-flex
          class="view-policy-section policy-number"
          d-flex
        >
          {{ $t('L_init_error_msg') }}
        </v-flex>
        <v-flex
          xs12
          mt-4
        >
          <a
            class="white--text"
            @click="goToLogin"
            href="javascript:void(0)"
          >
            {{ $t('L_go_login') }}
          </a>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions } from 'vuex';
import Config from '@/shared/util/config';
import Alerts from '@/components/Alerts.vue';

export default {
  name: 'ErrorLayout',
  components: {
    Alerts,
  },
  data: () => ({
    relatedToUnsupportedBrowser: false,
  }),
  computed: {
    showAlertsBasedOnEnv() {
      return (Config.get('env') !== 'prod');
    },
  },
  methods: {
    ...mapActions(['goToLogin']),
  },
  mounted() {
    const ua = navigator?.userAgent;
    // const isChrome = ua.indexOf('Chrome/') !== -1 && ua.indexOf('Chromium/') === -1;
    // const isSafari = ua.indexOf('Safari/') !== -1 && ua.indexOf('Chrome/') === -1 && ua.indexOf('Chromium/') === -1;
    // const isEdge = ua.indexOf('Edg') !== -1;
    // const isFirefox = ua.indexOf('Firefox/xyz') !== -1;
    const isInternetExplorer = ua.indexOf('Trident/') !== -1 && (ua.indexOf('rv:') !== -1 || ua.indexOf('MSIE') !== -1);
    const exclusionList = isInternetExplorer;
    if (exclusionList) {
      this.relatedToUnsupportedBrowser = false;
      // this.relatedToUnsupportedBrowser = true;
    }
  },
};
</script>


<style lang="stylus" scoped>
  .overall-div-card-larger {
    position: relative !important;
    width: 100%;
    max-width: 1120px;
    padding: 40px;
  }
  .overall-div-card-smaller {
    position: relative !important;
    width: 100%;
    max-width: 1120px;
    padding: 8px;
  }
  #supportedBrowsers {
    background-image: linear-gradient(#063562, #607c97);
    height: 100%;
    position: absolute;
    margin: auto;
  }
  .supported-browsers-content {
    max-width: 100% !important;
    @media only screen and (min-width: 760px) {
      max-width: 75% !important;
    }
  }
  #unsupported_browser_title {
    @media only screen and (max-width: 760px) {
      font-size: 32px !important;
    }
    margin-top: 44px;
    margin-bottom: 44px;
  }
  #unsupported_browser_prompt {
    @media only screen and (max-width: 760px) {
      font-size: 16px !important;
    }
  }
  #supportedBrowsers p {
    color: #333 !important;
  }
  #supportedBrowsers a.text-decoration-none {
    text-decoration: none !important;
  }
  a.underline-link {
    text-decoration:underline !important;
  }
  .pad-bottom {
    padding-bottom: 100px;
  }
  .maxHeightWidth {
    max-height: 200px;
    max-width: 200px;
  }
  .removeLeftMargin {
    margin-left: -119px;
  }
  .largerFontSizeBrowserName {
    font-size: 20px;
  }
  .smallerFontSizeBrowserName {
    font-size: 12px;
  }
  </style>
