import DashboardLayout from '../../layouts/DashboardLayout.vue';
import Investments from './Investments.vue';

export default {
  path: '/investments/:tab?',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'Investments',
      component: Investments,
    },
  ],
};
