<i18n src="./locales.json"></i18n>
<template>
  <div>
    <!--  <v-layout row wrap>
      <v-flex style="font-size:11px;">
         {{ isLastColumn }}
        {{ debtData }}
      </v-flex>
    </v-layout> -->
    <v-layout font-size--12 row wrap v-for="(debtobj, index) in debtData" :key="index" text-xs-center :class="!isLastColumn ? 'whiteBackground' : 'blueBackground zlastColumn' ">
      <!-- 1 Top of the Accelerated Debt box -->
      <v-flex xs12 v-if="debtobj.isBlueBoxTop === true && debtData[0].isAcceleratedDebt === true">
        <v-layout row wrap>
          <v-flex xs12 class="blueBackgroundWhiteText">
            {{ $t('L_Accelerated_Debt') }}
          </v-flex>
        </v-layout>
        <v-layout row wrap class="blueBorderTop blueBorderLeft blueBorderRight">
          <v-flex xs7 text-xs-left pl-4 class="accelerationAmount">
            <div>{{ $t('L_acceleration_amount') }}</div>
            <v-tooltip max-width="200" html="true" open-on-click :open-on-hover="false" bottom close-delay="2000">
              <template v-slot:activator="{ on }">
                <v-icon color="blue darken-2" dark small v-on="on" class="moveiconup">
                  help
                </v-icon>
              </template>
              <div class="">
                <p v-html="$t('L_this_is_the_total')" />
              </div>
            </v-tooltip>
          </v-flex>
          <v-flex xs5 class="grayDownArrowPillButton">
            <div class="spanMoveBubbleLeftInAcceleratedDebt">
              <img alt="arrow bubble" class="arrowBubble" src="../../../assets/6digit.png">
            </div>
            <div class="monthlyAmountInBubbleAcceleratedDebt">
              {{ formattedMoney(debtobj.newPayment) }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- 2 Bottom of the Accelerated Debt box -->
      <v-flex xs12 v-else-if="debtobj.isBlueBoxBottom === true && debtData[0].isAcceleratedDebt === true">
        <v-layout row wrap class="blueBorderLeft blueBorderRight blueBorderBottom">
          <v-flex>
            <v-layout row wrap>
              <v-flex xs7 text-xs-left pl-4 class="accelerationAmount">
                {{ debtobj.debtName }}
              </v-flex>
              <v-flex xs5 class="SecondDollarAmount">
                <span class="payThisAmountVal">{{ formattedMoneyWSign(debtobj.newPayment - debtobj.acceleratedAmount) }} </span>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs7 text-xs-left pl-4 class="payOffDate">
                {{ $t('L_pay_off_date') }} {{ getMonth(debtobj.projectedPayoffDate) }}
              </v-flex>
              <v-flex xs5>
&nbsp;
              </v-flex>
            </v-layout>
            <v-layout row wrap class="" mb-1>
              <v-flex xs7 class="payThisAmount">
                {{ $t('L_Pay_this_amount') }}
              </v-flex>
              <v-flex xs5 class="payThisAmountVal">
                <div class="pillButtonPayThisAmount">
                  {{ formattedMoney(debtobj.newPayment) }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- 7 Hidden Top Most data for Accelerated Debt This will put space on top to account for blue bar for Accelerated Debt"  -->
      <v-flex pl-3 xs12 v-else-if="debtobj.isBlueBoxTop === false && debtobj.isAcceleratedDebt === true"
              class="eachCellSpacingPlusBlueBarHeight grayRight" zstyle="border: 2px solid red; color: red;"
      >
        <!-- <span> Hidden Data: {{ debtobj.debtName }} </span> <span class="text-align-right"> {{ debtobj.newPayment }}</span>  -->
      </v-flex>

      <!-- 5 First Debt to Pay Off  with Blue Bar                                                                    -->
      <v-flex xs12 v-else-if="debtobj.isBlueBoxTop ===true && debtobj.isAcceleratedDebt ===false" style="margin-top:4px;" zstyle="border: 2px solid pink; color:pink;">
        <v-layout row wrap>
          <v-flex xs12 class="blueBackgroundWhiteText">
            {{ $t('L_first_debt_to_pay_off') }}
          </v-flex>
        </v-layout>
        <div class="blueBorderTop blueBorderLeft blueBorderRight blueBorderBottom">
          <v-layout row wrap>
            <v-flex xs7 pl-3 text-xs-left font-size--16 class="hideOverflowText">
              {{ debtobj.debtName }}
            </v-flex>
            <v-flex xs5 class="firstDebtToPayOffBubbleSpacing" font-size--16 text-xs-right>
              <span class="pillButtonPayThisAmountTwo"> {{ formattedMoney(debtobj.newPayment) }} </span>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 class="payOffDatevtwo" text-xs-left pl-3>
              {{ $t('L_pay_off_date') }} {{ getMonth(debtobj.projectedPayoffDate) }}
            </v-flex>
          </v-layout>
        </div>
      </v-flex>

      <!-- 11 This is it since All Debt is paid -->
      <v-flex mb-5 xs12 v-else-if="debtobj.showOnlyMonthlyAmount===true && debtobj.islastDebt===true"
              class="heightToEncompassYouDidItImgAndDontStopNowTxt dottedAggregateAmount " zstyle="border:2px solid gold; color:gold;"
      >
        <div class="raiseTheDotsOne">
          . . . . . . . . . . . . . . . . . .
        </div>
        <div class="dottedBubbleInMiddle">
          <img alt="amount per month" class="arrowBubbleBlueBorder" src="../../../assets/white-bubble-arrow.svg">
        </div>
        <div class="dottedMonthlyAmountInBubbleMiddle text-xs-center">
          {{ formattedMoney(debtobj.newPayment) }}
        </div>


        <div xs12 class="imageOfYouDidIt">
          <span><img alt="Debt paid off successfully" src="../../../assets/debt-success.svg"></span>
        </div>

        <div xs12 class="youDidItText">
          {{ $t('L_you_did_it') }}
        </div>
        <div xs12 class="This_is_a_huge_step">
          {{ $t('L_this_is_a_huge_step_toward') }}
        </div>
        <div class="blueBackground">
          <v-layout ml-2 mr-2 pt-2 pb-2 pl-2 pr-2 row wrap class=" moveBelowTotalInvestmentAmountWithBlueBox whiteBackground">
            <div v-if="debtData.dontStopThereIndividualAmount > 0">
              <v-flex xs12 pt-3 class="dontStopNow">
                {{ $t('L_donot_stop_now') }}
              </v-flex>
              <v-flex xs12 pt-3 class="startSaving">
                {{ $t('L_start_saving') }}
              </v-flex>
              <v-flex xs12 class="startSavingAmt">
                {{ formattedMoney(debtobj.newPayment) }}
              </v-flex>
              <v-flex xs12 pl-3 pr-3 class="eachMonthAndAccumulate">
                {{ $t('L_each_month_and_accumulate') }}
              </v-flex>
              <v-flex xs12 class="totalSavedAmount">
                {{ formattedMoney(debtData.dontStopThereIndividualAmount) }}
              </v-flex>
              <v-flex xs12 class="byRetirementAge">
                {{ $t('L_by_retirement_age') }} {{ getRetirementAge }}
              </v-flex>
            </div>
            <v-flex xs12 pt-3 class="contactYourRepresentativeToday">
              {{ $t('L_contact_your_representative_today') }}
            </v-flex>
            <div v-if="debtData.dontStopThereIndividualAmount > 0">
              <v-flex xs12 pb-4 class="youCanUpdateTheRetirement">
                <div v-html="$t('L_you_can_update_the_retirement')" />
              </v-flex>
            </div>
          </v-layout>
        </div>
      </v-flex>

      <!-- 9 This is the TopMost row, dashed with Money in Bubble for Unpaid/First Debt 2nd column (index ==1) only occurs in 1 case.  Need to account for blue bar height of first debt-->
      <v-flex pl-3 xs12 v-else-if="debtobj.isBlueBoxTop === false && debtobj.isAcceleratedDebt === false && debtobj.showOnlyMonthlyAmount === true"
              class="eachCellSpacingPlusBlueBarHeightForFirstDebt grayBottomAndRight" zstyle="border: 2px solid orange; color: orange;"
      >
        <span class="raiseTheDotsTwo">. . . . . . . . . . . . . . . . . . . . . . . . . . . . .</span>
        <span :class="index==0?'dottedBubbleBigBox':'dottedBubble'">
          <img alt="arrow bubble" class="arrowBubble" src="../../../assets/6digit.png">
        </span>
        <span class="dottedMonthlyAmountInBubbleOne">{{ formattedMoneyWSign(debtobj.newPayment) }} </span>
      </v-flex>

      <!-- 12 This is the TopMost row, dashed with Money in Bubble for Unpaid/First Debt 2nd column (index ==1) only occurs in 1 case.  Need to account for blue bar height of first debt-->
      <v-flex pl-3 xs12 v-else-if="debtobj.showOnlyMonthlyAmount === true"
              :class="index==0?'eachCellSpacingPlusFirstDebtBlueBarHeight paddingTopByHeightOfBlueBar':'paddingTopNone eachCellSpacing'"
              class="grayLeft grayBottomAndRight" zstyle="border: 2px dashed purple; color: purple;"
      >
        <div class="raiseTheDotsThree">
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
        </div>
        <div v-if="index==0" :class="debtobj.newPayment < 100000?'dottedBubbleBigBoxTwo':'dottedBubbleBigBox'">
          <img alt="arrow bubble" :class="debtobj.newPayment < 100000?'arrowBubbleSmall':'arrowBubble'" src="../../../assets/6digit.png">
        </div>
        <div v-else :class="debtobj.newPayment < 100000?'dottedBubbleTwo':'dottedBubble'">
          <img alt="arrow bubble" :class="debtobj.newPayment < 100000?'arrowBubbleSmall':'arrowBubble'" src="../../../assets/6digit.png">
        </div>
        <div class="dottedMonthlyAmountInBubbleTwo text-xs-right">
          {{ formattedMoneyWSign(debtobj.newPayment) }}
        </div>
      </v-flex>

      <!-- 10 Topmost Hidden cell for Unpaid. Need to add extra spacing to account for blue bar for Accelerated Debt-->
      <v-flex pl-3 xs12 v-else-if="debtobj.isBlueBoxTop === false && debtobj.isAcceleratedDebt === false && debtobj.hideData === true"
              class="grayRight"
              :class="index==0?'eachCellSpacingPlusFirstDebtBlueBarHeight':'eachCellSpacing'" zstyle="border: 2px dashed green; color: green;"
      >
        <!-- <span> Hidden Data: {{ debtobj.debtName }} </span> <span class="text-align-right"> {{ debtobj.newPayment }}</span>  -->
      </v-flex>

      <!-- 8 Hidden Data Cell Logic for 2nd column onwards for both Paid and Unpaid                                                                     -->
      <v-flex pl-3 xs12 v-else-if="debtobj.hideData===true && debtobj.showPayOffDate === false && debtobj.showOnlyMonthlyAmount === false"
              class="grayLeft grayRight"
              :class="index==0?'eachCellSpacingPlusFirstDebtBlueBarHeight':'eachCellSpacing'" zstyle="border:1px dashed blue; color:blue;"
      >
        <!-- <span> Hidden Data: {{ debtobj.debtName }} </span> <span class="text-align-right"> {{ debtobj.newPayment }}</span>  -->
      </v-flex>

      <!-- 4 This shows the payoff Date -->
      <v-flex pl-3 xs12 v-else-if="debtobj.showPayOffDate === true" class=" eachCellSpacing defaultDebtLeft grayLeft grayBottomAndRight" text-xs-left zstyle="border:2px solid green; color:green;">
        <v-layout row wrap>
          <v-flex xs7 class="hideOverflowText">
            {{ debtobj.debtName }}
          </v-flex>
          <v-flex xs5 text-xs-right class="numberFourPadding">
            <span class="bluePillButton">{{ formattedMoney(debtobj.newPayment) }}</span>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 class="payoffDateTwo">
            {{ $t('L_pay_off_date') }} {{ getMonth(debtobj.projectedPayoffDate) }}
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- 6 This is the default view with debtName and newPayment -->
      <v-flex pl-3 xs12 v-else class="hideOverflowText eachCellSpacing defaultDebtLeft grayLeft grayBottomAndRight" text-xs-left zstyle="border:2px solid gray; color:gray;">
        <v-layout row wrap>
          <v-flex xs7 class="hideOverflowText">
            {{ debtobj.debtName }}
          </v-flex>
          <v-flex xs5 text-xs-right pr-4>
            {{ formattedMoney(debtobj.newPayment - debtobj.acceleratedAmount) }}
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'DebtStackColumn',
  props: {
    debtData: {
      type: Array,
      required: false,
      default() { return []; },
    },
    rightMostCol: {
      type: Boolean,
      required: false,
      default() { return false; },
    },
  },
  methods: {
    GoToRetirementGuidance() {
      // Go to Retirment Guidance;
    },
    getMonth(tObj) {
      let rDate = null;
      const tDate = moment(tObj, 'YYYY-MM-DDTHH:mm:ss-nn:00');
      rDate = moment(tDate).format('MM/YYYY');
      return rDate;
    },
    formattedMoney(strMoney) {
      return this.$n(strMoney, 'currencyNoCents', 'en-US');
    },
    formattedMoneyWSign(strMoney) {
      if (strMoney === undefined) {
        return '';
      } else if (strMoney.toString().indexOf('-') > -1) {
        return this.$n(strMoney, 'currencyNoCents', 'en-US');
      }
      return `+${this.$n(strMoney, 'currencyNoCents', 'en-US')}`;
    },
  },
  computed: {
    ...mapGetters({
      personInfo: 'debtsolutions/personInfo',
      planWStacking: 'debtsolutions/planWStacking',
    }),

    isLastColumn() {
      if (this.debtData[this.debtData?.length - 1].islastDebt === true
      && this.debtData[this.debtData?.length - 1]?.showOnlyMonthlyAmount === true
      ) {
        return true;
      }
      return false;
    },
    getRetirementAge() {
      const retAge = this.planWStacking?.planTotals?.dontStopThereIndividualAge > 0 ?
                     this.planWStacking?.planTotals?.dontStopThereIndividualAge :
        this.personInfo.retirementAge;
      return `${retAge}*`;
    },
  },
};
</script>

<style lang="stylus" scoped>
.whiteBackground
  background-color: #FFFFFF;
.text-align-right
  text-align: right;
.hideOverflowText
  white-space: nowrap;
  overflow: hidden;
.eachCellSpacing
  height: 50px;
  line-height: 50px;
.blueBackgroundWhiteText
  background-color: #063562;
  color: #ffffff;
  border-bottom:1px solid #ffffff;
.moveiconup
  position:absolute;
  margin-top: -20px;
  padding-left:32px;
.accelerationAmount
  color: #063562;
  font-size: 14px;
.blueBorderBottom
  border-bottom: 1px solid #063562;
.grayLeft
  border-left: 1px solid rgba(212,212,212,0.5);
.grayRight
  border-right: 1px solid rgba(212,212,212,0.5);
.grayBottomAndRight
  border-right:1px solid rgba(212,212,212,.5);
  border-bottom: 1px solid rgba(212, 212, 212,.5);
.blueBorderTop
  border-top: 1px solid #063562;
.blueBorderLeft
  border-left: 1px solid #063562;
.blueBorderRight
  border-right: 1px solid #063562;
.monthlyAmountInBubble
  position: absolute;
  margin-top:-50px;
  font-size:16px;
  color: #063562;
  margin-left:-35px;
  font-weight:600;
.monthlyAmountInBubbleAcceleratedDebt
  position: absolute;
  margin-top:5px;
  font-size:16px;
  color: #063562;
  margin-left:-25px;
  font-weight:600;
.secondText
  font-size:16px;
.payOffDate
  font-size:11px;
  color:#02579e;
  position: relative;
  margin-top: -5px;
.payThisAmount
  font-size:12px;
  color:#63562;
  color:#063562;
.payThisAmountVal
  font-size: 16px;
  color:#063562;
  font-weight: 600;
.pillButtonPayThisAmount
  border: 1px solid #063562;
  border-radius: 16px;
  position: relative;
  padding: 5px;
.defaultDebtLeft
  font-size:16px;
  color: #333333;
.dottedAggregateAmount
  font-size:16px;
.raiseTheDotsTwo
  position:absolute;
  margin-top:2px;
  margin-left:-130px;
.raiseTheDotsThree
  position:absolute;
  margin-top:-8px;
  margin-left:-24px;
.raiseTheDotsFour
  position:absolute;
  margin-top:2px;
  margin-left:-130px;
.dottedBubbleBigBox
  position: absolute;
  margin-left:135px;
  margin-top:-10px;
  zheight: auto;
  zwidth: 110px !important;
.dottedBubbleBigBoxTwo
  position: absolute;
  margin-left:160px;
  margin-top:-10px;
  zheight: auto;
  zwidth: 110px !important;
.dottedBubble
  position: absolute;
  margin-left:138px;
  margin-top:5px;
.dottedBubbleTwo
  position: absolute;
  margin-left:160px;
  margin-top:5px;
.raiseTheDotsOne
  position:absolute;
  margin-top:0px;
  margin-left:-10px;
.dottedBubbleInMiddle
  position: absolute;
  margin-left:56px;
  margin-top:-28px;
.dottedMonthlyAmountInBubbleMiddle
  position: relative;
  margin-top: 12px;
  margin-left: 0px;
  font-weight: 600;
  font-size: 16px;
  color: #063562;
  padding-bottom:55px;
.arrowBubbleBlueBorder
  width:159px;
  height: 122px;
.dottedMonthlyAmountInBubbleOne, .dottedMonthlyAmountInBubbleTwo, .dottedMonthlyAmountInBubbleThree
  font-weight: 600;
  font-size: 16px;
  color: #063562;
.dottedMonthlyAmountInBubbleOne
  position: absolute;
  margin-top: -5px;
  margin-left:0px;
.dottedMonthlyAmountInBubbleTwo
  position: relative;
  margin-top: -4px;
  padding-right:20px;
.dottedMonthlyAmountInBubbleThree
  position: absolute;
  margin-top: -5px;
  margin-left:0px;
.payoffDateTwo
  font-size:11px;
  color: #02579e;
  position: relative;
  margin-top:-35px;
.eachCellSpacingPlusBlueBarHeight
  height: 95px;
.eachCellSpacingPlusFirstDebtBlueBarHeight
  height: 65px;
.eachCellSpacingPlusBlueBarHeightForFirstDebt
  height: 85px;
  padding-top:23px;
.imageOfYouDidIt
  padding-bottom:0px;
.youDidItText
  font-size: 18px;
  font-weight: 600;
  color: #333333;
.This_is_a_huge_step
  font-size: 18px;
  color: #333333;
  line-height: 1.43;
.overlayOnTopOfData
  position: relative;
  padding-top: -44px;
.heightToEncompassYouDidItImgAndDontStopNowTxt
  height: 570px;
.moveBelowTotalInvestmentAmountWithBlueBox
  padding-top:75px;
  border: 1px solid #f5faff;
  border-radius: 16px;
  zheight: 390px;
.eachMonthAndAccumulate
  line-height: 1.25;
.byRetirementAge
  line-height:1;
.dontStopNow, .startSaving, .eachMonthAndAccumulate, .byRetirementAge, .contactYourRepresentativeToday
  font-size:16px;
  color:#333333;
.startSavingAmt, .totalSavedAmount
  font-size: 18px;
  color: #063562;
  font-weight: 600;
.youCanUpdateTheRetirement
  font-size: 10px;
  color: #333333;
.linkText
  color: #063562;
  font-weight: 600;
.background-lightBlue
  background-color: #f5faff;
.payOffDatevtwo
  font-size:11px;
  color:#02579e;
.pillButtonPayThisAmountTwo
  zpadding-top: 4px;
  zposition: absolute;
  zmargin-top: 4px;
  zmargin-left: -30px;
  zheight: 30px;
  zwidth: 70px;
  border: 1px solid #063562;
  border-radius: 16px;
  padding: 0px 10px 0px 10px;
.heightForBlueBarAndRegularCell
  margin-top:18px;
  height: 45px;
  line-height: 45px;
.bluePillButton
  border: 1px solid #063562;
  border-radius: 16px;
  position: relative;
  padding: 5px;
.paddingTopByHeightOfBlueBar
  padding-top:35px;
.paddingTopNone
  padding-top:5px;
.arrowBubble
  width:116px;
  height: 48px;
.arrowBubbleSmall
  width:94px;
  height: 48px;
.zarrowBubbleForAcceleratedDebt
  width:100px;
  height: 48px;
.spanMoveBubbleLeftInAcceleratedDebt
  position: absolute;
  margin-left: -47px;
.blueBackground
  background-color: #f5faff;
.lastColumn
  position: absolute;
  margin-top: -100px;
  width: 230px;
.firstDebtToPayOffBubbleSpacing
  padding-right:14px;
.numberFourPadding
  padding-right:18px;
</style>
