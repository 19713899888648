import * as MUTATION from './mutation-types';
import { getDefaultState } from './state';
// TODO: cleanup

const setStep = (state, step) => { state.step = step; };

const setPackages = (state, packages) => {
  // TODO: Sort the packages by oldest dob
  state.packages = packages;
};

const setPackage = (state, _package) => {
  const foundIndex = state.packages.findIndex(pkge => pkge.policyId === _package.policyId && pkge.packageId === _package.packageId);
  if (foundIndex !== -1) {
    state.packages[foundIndex] = { ...state.packages[foundIndex], ..._package };
  } else {
    state.packages.push(_package);
  }
};

const setLrcInd = (state, _lrcInd) => {
  state.lrcInd = _lrcInd;
};

const setNewPolicy = (state, newPolicy) => { state.newPolicy = { ...state.newPolicy, ...newPolicy }; };

const setNewPolicyEDelivery = (state, eDelivery) => { state.newPolicy.eDelivery = { ...state.newPolicy.eDelivery, ...eDelivery }; };

const setNewPolicyAcknowledgement = (state, acknowledgement) => { state.newPolicy.acknowledgement = { ...state.newPolicy.acknowledgement, ...acknowledgement }; };

/**
 * set selected package index if package info is provided, clears package index otherwise
 */
const setSelectedPackageIndex = (state, packageInfo) => {
  if (packageInfo) {
    const foundIndex = state.packages.findIndex(pkge => pkge.policyId === packageInfo.policyId && pkge.packageId === packageInfo.packageId);
    state.newPackageInfo.packageIndex = foundIndex;
  } else {
    state.newPackageInfo.packageIndex = -1;
  }
};

/**
 * set new package info packageId, policyId, and primary if package info is provided, clears package index otherwise
 */
const setNewPackageInfo = (state, packageInfo) => {
  if (packageInfo) {
    state.newPackageInfo.packageId = packageInfo.packageId;
    state.newPackageInfo.policyId = packageInfo.policyId;
    state.newPackageInfo.primary = packageInfo.primary;
  } else {
    state.newPackageInfo.packageId = '';
    state.newPackageInfo.policyId = '';
    state.newPackageInfo.primary = {
      firstName: '',
      lastName: '',
      middleName: '',
      DOB: '',
      gender: '',
      SSN: '',
    };
  }
};

/**
 * setPersonalInfo
 *  - set the new package info from the user
 */
const setPersonalInfo = (state, personalInfo) => {
  state.newPackageInfo.primary = personalInfo;
};

/**
 * setSelectedOption
 *  - set the selected option from the user
 */
const setSelectedOption = (state, selectedOption) => {
  state.newPackageInfo.selectedOption = selectedOption;
};

/**
 * setOptIn
 *  - set the selected option from the user
 */
const setOPTIN = (state, { optIn, agreement }) => {
  state.newPackageInfo.optIn = optIn;
  if (optIn === 'Y') {
    state.newPackageInfo.eDelivery.agreementId = agreement.agreementId;
    state.newPackageInfo.eDelivery.agreementType = agreement.agreementType;
  }
};

/**
 * setPersonalInfo
 *  - set step of the conversion process
 */
const setConversionProcessStep = (state, step) => {
  state.conversionProcess.step = step;
};

/**
 * set Agreements
 */
const setEDelivery = (state, eDeliveryData) => {
  state.agreement = eDeliveryData;
};

/**
 * set Acknowledgements
 */
const setAcknowledgement = (state, acknowledgementData) => {
  state.acknowledgement = acknowledgementData;
  state.newPackageInfo.acknowledgement.agreementId = acknowledgementData.agreementId;
  state.newPackageInfo.acknowledgement.agreementType = acknowledgementData.agreementType;
};

/**
 * set loading
 */
const setLoading = (state, loading) => {
  state.loading = loading;
};

const setExpirationDate = (state, expirationDate) => {
  state.expirationDate = expirationDate;
};

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};

export default {
  [MUTATION.STEP]: setStep,
  [MUTATION.PACKAGES]: setPackages,
  [MUTATION.PACKAGE]: setPackage,
  [MUTATION.NEW_POLICY]: setNewPolicy,
  [MUTATION.NEW_POLICY_EDELIVERY]: setNewPolicyEDelivery,
  [MUTATION.NEW_POLICY_ACKNOWLEDGEMENT]: setNewPolicyAcknowledgement,
  // TODO: cleanup below
  [MUTATION.PERSONALINFO]: setPersonalInfo,
  [MUTATION.CONVERSIONPROCESS_STEP]: setConversionProcessStep,
  [MUTATION.SELECTED_OPTION]: setSelectedOption,
  [MUTATION.OPTIN]: setOPTIN,
  [MUTATION.SELECTED_PACKAGEINDEX]: setSelectedPackageIndex,
  [MUTATION.NEW_PACKAGE_INFO]: setNewPackageInfo,
  [MUTATION.EDELIVERY]: setEDelivery,
  [MUTATION.ACKNOWLEDGEMENT]: setAcknowledgement,
  [MUTATION.LOADING]: setLoading,
  [MUTATION.LRCIND]: setLrcInd,
  [MUTATION.EXPDATE]: setExpirationDate,
  resetState,
};
