<i18n src='../locales.json'></i18n>
<template>
  <v-content class="pt-0 text-left">
    <v-layout row wrap>
      <v-flex xs12 my-2 font-size--16 font-weight-bold class="greenIconText">
        <v-icon color="success">
          check_circle
        </v-icon>
        <p class="your_withdrawal">
          {{ $t('L_your_widthdrawal_day_was_successfully_updated') }}
        </p>
      </v-flex>
      <v-flex xs12>
        <div class="mb-4">
          {{ $t('L_your_new_monthly_withdrawal_day_has_been_updated', { date: getSelectedDate, policy_num: getPolicyNumber }) }}
        </div>
        <div v-if="dateIssueNextPayDateEarly === 1">
          {{ $t('L_based_on_your_change_your_current_amount_due_will_be') }}
        </div>
      </v-flex>
      <v-flex xs12 offset-md8 offset-lg8 mt-4 pt-4>
        <v-btn id="one_tp_kubra_done" block class="white--text float-right" color="primary" @click="refreshAndClose">
          {{ $t('L_done') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';


export default {
  name: 'WDStepThree',
  components: {},
  props: {
    daySelected: {
      type: String,
      required: true,
      default: '1',
    },
    dateIssueNextPayDateEarly: {
      type: Number,
      required: true,
      default: -1,
    },
    dateForNextPayment: {
      type: String,
      required: true,
      default: '1',
    },
  },
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['policies', 'currentPolicy', 'routeNameToNavigateBack', 'selectedPolicyIndex']),
    ...mapGetters({
      locale: 'getLocale',
    }),
    getSelectedDate() {
      let x = '';
      x = this.daySelected;
      if (this.daySelected === '1' || this.daySelected === '21') {
        x += 'st ';
      } else if (this.daySelected === '2' || this.daySelected === '22') {
        x += 'nd ';
      } else if (this.daySelected === '3' || this.daySelected === '23') {
        x += 'rd ';
      } else {
        x += 'th ';
      }
      return x;
    },
    getPolicyNumber() {
      return this.currentPolicy?.contractId;
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument', 'getPolicyOverview', 'setPaymentInfoShowPaymentAmountDateBillingMethod', 'setWithDrawalDateStep']),
    refreshAndClose() {
      try {
        const i = this.$dynatrace.enterAction('WithdrawalDayConfirmation_Done_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.setWithDrawalDateStep(-1);
      this.$router.push({ name: 'PolicyDetails', params: { contractId: this.selectedPolicyIndex } });
    },
  },
};
</script>

<style>
.greenIconText {
  display: inline-flex;
}
.bg-light-grey {
  background-color: #f5f5f5;
}
.clickable {
  cursor: pointer;
}
.blue_label{
  color: #02579e;
}
.your_withdrawal {
  margin-top: 17px !important;
  padding-left: 10px !important;
}
</style>
