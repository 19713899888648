import { loadLocale } from '../plugins/i18n';
import { getDefaultState } from './state';

export const setRootLoading = (state, rootLoading) => {
  state.rootLoading = rootLoading;
};

export const changeLocale = (state, locale) => {
  state.locale = locale;
  if (locale.indexOf('_') === -1) {
    switch (locale.toLowerCase()) {
      case 'en':
        state.locale = 'en_US';
        break;
      case 'es':
        state.locale = 'es_US';
        break;
      case 'fr':
        state.locale = 'fr_CA';
        break;
      default:
        state.locale = 'en_US';
    }
  }
  loadLocale(state.locale);
};

export const deviceDNA = (state, deviceDna) => {
  state.deviceDNA = deviceDna;
};

export const addAlert = (state, value) => {
  const containAlert = state.alerts.find((a) => {
    const doesContatinAlert =
      a.type === value.type &&
      a.context === value.context &&
      ((a.details.message && value.details.message && a.details.message === value.details.message) || (a.details.key && value.details.key && a.details.key === value.details.key));
    return doesContatinAlert;
  });
  if (!containAlert) {
    value.index = state.alerts.length;
    state.alerts.push(value);
  }
};

export const addError = (state, value) => {
  const details = value?.error?.response ? value?.error?.response?.data : value?.error || {};
  if (!details?.key) {
    details.key = 'L_generic_error_message';
  }
  const alert = {
    type: 'error',
    details,
    context: value?.context,
    icon: value?.icon,
  };
  addAlert(state, alert);
};

export const removeAlert = (state, index) => {
  state.alerts = state.alerts.filter(alert => alert.index !== index);
};

export const removeAlerts = (state, context) => {
  if (context) {
    state.alerts = state.alerts.filter(alert => alert.context !== context);
  } else {
    state.alerts = [];
  }
};

export const setInitialized = (state, initialized) => {
  state.initialized = initialized;
};

export const setFeatures = (state, features) => {
  state.features = features;
};

export const user = (state, userData) => {
  state.user = userData;
};

export const setTasks = (state, tasks) => {
  state.tasks = tasks;
};

export const showTermConditionModal = (state, value) => {
  state.showTermConditionModal = value;
};

export const termConditionContent = (state, value) => {
  state.termConditionContent = value;
};

export const addAgents = (state, agents) => {
  agents.forEach((agent) => {
    // Add agent only if agent does not exist in the store.agents
    const contain = state.agents.find(a => a.agentId === agent.agentId);
    if (!contain) {
      state.agents.push(agent);
    }
  });
};

export const setSubmitLoading = (state, value) => {
  state.submitLoading = value;
};

export const addNotification = (state, value) => {
  const containDuplicateNotification = state.notifications.find((notification) => {
    const duplicateNotification = notification.context === value.context &&
      notification.component === value.component &&
      notification.details.title === value.details.title;
    return duplicateNotification;
  });
  if (!containDuplicateNotification) {
    value.index = state.notifications.length;
    state.notifications.push(value);
  }
};

export const removeNotification = (state, index) => {
  state.notifications = state.notifications.filter(notification => notification.index !== index);
};

export const removeNotifications = (state, context) => {
  if (context) {
    state.notifications = state.notifications.filter(notification => notification.context !== context);
  } else {
    state.notifications = [];
  }
};

export const updateNotification = (state, notifobj) => {
  state.notifications = state.notifications.filter(notification => notification.index !== notifobj.index);
  state.notifications.push(notifobj);
};

export const updateSpanishBanner = (state, showSpanishBanner) => {
  state.spanishBanner = showSpanishBanner;
};

export const updateAlertsAndNotifications = (state, alertsAndNotifications) => {
  state.alertsAndNotifications = [...alertsAndNotifications];
};

export const setShowVerifyPhoneWrapper = (state, value) => {
  state.showVerifyPhoneWrapper = value;
};

export const setVerifyPhonePageToLand = (state, value) => {
  state.verifyPhonePageToLand = value;
};


export const updateDocuments = (state, documents) => {
  state.documents = { ...documents };
};

export const updateShowNavigationConfirmation = (state, showFlag) => {
  state.showNavigationConfirmation = showFlag;
};

export const updateNavigationPathOnUserConfirmation = (state, pathIfUserSelectsToNavigateOut) => {
  state.navigationPathOnUserConfirmation = pathIfUserSelectsToNavigateOut;
};

export const updateNavigationDialogBody = (state, localeKey) => {
  state.navigationDialogBody = localeKey;
};

export const setShowGoPaperlessWrapper = (state, value) => {
  state.showGoPaperlessWrapper = value;
};

export const setMaintenanceMode = (state, value) => {
  state.meta.isMaintenanceMode = value;
};

export const setRecommendedAppVersion = (state, value) => {
  state.meta.recommendedAppVersion = value;
};

export const setUserSessionMeta = (state, value) => {
  /*
  x-pri-device='iPhone 14'
  x-pri-os='17.3'
  x-pri-platform='Android'
  x-pri-app='0.2.50'
  */
  state.userSessionMeta = { ...value }; // TODO: add unit test
};

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};
