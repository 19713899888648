<template>
  <div ref="baseText" />
</template>
<script>
import moment from 'moment';
import messages from './validation/messages';
import { Session } from '../../shared/util/session';

const maskTimeout = {};

export function maskCallback() {
  this[this.modelName] = this[this.modelName].replace(/./g, '•');
}

export default {
  data: () => ({
    masked: false,
    textValue: '',
    describers: '',
  }),
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    hint: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    formInput: {
      type: Object,
      required: true,
    },
    enableMasking: {
      type: Boolean,
      default: false,
    },
    enableShowHide: {
      type: Boolean,
      default: false,
    },
    autoCapitalize: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    validationMessages: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    formatters: {
      type: [Array, Function],
      required: false,
      default: null,
    },
    messages: {
      type: [String, Array],
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaDescribedBy: {
      type: String,
      required: false,
      default: '',
    },
    disableAutocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onInput() {
      this.onChange(this.formInput.$model);
    },
    onBlur(event) {
      this.formInput.$model = this.formInput.$model.trim();
      if (this.autoCapitalize) {
        const words = this.formInput.$model.split(' ');
        words.forEach((word, index) => {
          words[index] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        this.formInput.$model = words.join(' ');
      }
      this.onChange(this.formInput.$model);
      this.$emit('blur', event);
      if (this.formInput.$error) {
        this.sendGTMValidationError();
      }
    },
    onChange(event) {
      this.$emit('input', event);
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    toggleTooltip(event) {
      this.$emit('toggle-tooltip', event);
    },
    toggleMasked() {
      this.masked = !this.masked;
      this[this.modelName] = !this.masked ? this.formInput.$model : this.formInput.$model.replace(/./g, '•');
    },
    mask(input) {
      let value = input;
      if (value.length > 9) {
        value = value.slice(0, 9);
      }
      if (!this.masked) {
        this.textValue = value;
        this[this.modelName] = value;
        this.onInput(value);
        return;
      }

      const chars = value.split('');
      const currentValue = this.textValue;
      const caretPosition = this.$el.querySelector('input').selectionStart;
      this[this.modelName] = chars.map((char, i) => ((char === currentValue[i] || i >= caretPosition) ? '•' : char)).join('').slice(0, 9);

      this.textValue = value.slice(0, 9);
      if (maskTimeout[this._uid]) {
        window.clearTimeout(maskTimeout[this._uid]);
        maskTimeout[this._uid] = null;
      }
      maskTimeout[this._uid] = window.setTimeout(maskCallback.bind(this), 1200);
      this.onInput(this.formInput.$model);
    },
    format(input) {
      if (typeof (this.formatters) === 'function') {
        return this.formatters(input);
      }
      let output = input;
      this.formatters.forEach((formatter) => {
        output = formatter(output);
      });
      return output;
    },
    onFormReset(message) {
      if (message.data === 'form-reset') {
        this.mask('');
      }
    },
    sendGTMValidationError() {
      this.$eventBus.$emit('gtm-data-layer', {
        event: 'event',
        eventCategory: 'form validation',
        eventLabel: 'error',
        eventValue: this.errorMessageValue,
        timestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
        sessionId: Session.get('X-Correlation-ID'),
      });
    },
  },
  computed: {
    icon() {
      if (!this.formInput.$dirty) {
        return '';
      }
      return this.formInput.$invalid ? 'error' : 'check_circle';
    },
    getClass() {
      return {
        'success--text': !this.formInput.$invalid,
      };
    },
    color() {
      return this.formInput.$invalid || this.formInput.$model?.length === 0 ? 'primary' : 'success';
    },
    errorMessages() {
      const errors = [];
      const validationMessages = Object.assign({}, messages[this.modelName], this.validationMessages);
      if (typeof (this.formInput) === 'undefined' || !this.formInput.$dirty) {
        return errors;
      }
      if (this.formInput.$error) { // If there is an error on the field
        Object.keys(validationMessages).forEach((type) => {
          if (this.formInput[type] === false && validationMessages && Object.prototype.hasOwnProperty.call(validationMessages, type)) {
            errors.push(validationMessages[type]);
          }
        });
        if (errors.length === 0) { // If no messages have been added for this error
          errors.push(messages.generic.invalid);
        }
      }
      return errors.map(msg => (typeof (this.$t) !== 'undefined' ? this.$t(msg) : msg));
    },
    errorMessageValue() {
      if (this.messages) {
        return this.messages;
      }
      return this.errorMessages[0].length >= 1 ? this.errorMessages[0] : this.label;
    },
    maskStyle() {
      const style = {};
      if (this.masked) {
        style['font-family'] = 'monospace';
      }
      return style;
    },
  },
  mounted() {
    window.addEventListener('message', this.onFormReset);
    this.textValue = this.formInput.$model;
    this.masked = this.enableMasking;
    const message = this.$el.querySelector('.v-messages__wrapper');
    if (message) {
      const describerId = `${this.modelName}_error_message`;
      message.setAttribute('id', describerId);
      this.describers = `${this.ariaDescribedBy} ${describerId}`;
    }
  },
  updated() {
    if (this.enableMasking && this[this.modelName].length === 0 && this.formInput.$model.length > 0) {
      this[this.modelName] = (this.masked) ? this.formInput.$model.replace(/./g, '•') : this.formInput.$model;
    }
  },
};
</script>

<style lang="stylus" scoped>
  /deep/ .v-input.masked input {
    z-index: 1;
    color: transparent !important;
    &::selection {
      color: transparent !important;
    }
  }
  /deep/ .v-input.masked.mask-active input {
    font-family: monospace;
  }

  /deep/ i.toggle-masking {
    color: #02579E !important;
  }
  span.mask-text.absolute {
    position: absolute;
    padding-top: 4px;
    z-index: 0;
  }
</style>
