<i18n src="./locales.json"></i18n>
<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3 role="heading" aria-level="2" class="card-title pt-1 font-weight-regular">
        {{ $t('L_edelivery') }}
      </h3>
    </v-card-title>
    <v-divider class="under-title-divider" />
    <v-card-text
      class="text-content frame-parent mb-4"
    >
      <v-card
        class="scroll-y"
      >
        <v-card-text
          id="scroll-target"
          v-scroll:#scroll-target="onScroll"
          column
          align-center
          justify-center
          style="max-height: 400px;"
        >
          <div
            class="my-2"
            v-html="$t('L_disclaimer_title')"
          />
          <div
            v-html="disclaimerBody"
          />
        </v-card-text>
      </v-card>
      <v-layout
        row
        wrap
        class="justify-center"
      >
        <v-flex
          xs11
          px-3
          class="text-xs-center"
        >
          <h4
            class="mt-3 font-weight-regular"
          >
            {{ $t('L_if_you_decline') }}
          </h4>
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        class="pt-4 text-xs-center font-weight-medium"
      >
        <v-flex
          xs12
        >
          {{ selectedPackage.addrLine1 }}
        </v-flex>
        <v-flex
          xs12
        >
          {{ selectedPackage.addrLine2 }}
        </v-flex>
        <v-flex
          xs12
        >
          {{ selectedPackage.addrCity }}, {{ selectedPackage.addrState }} {{ selectedPackage.addrZip }}
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        class="justify-center"
      >
        <v-flex
          xs6
          px-3
          class="text-xs-center"
        >
          <h4
            class="mt-3 font-weight-regular"
            v-html="$t('L_you_can_update_this_address')"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-layout row wrap justify-center>
        <v-flex
          xs8
          md6
          class="text-xs-center"
        >
          <v-btn
            block
            color="primary"
            @click="optIn"
          >
            {{ $t('L_i_agree') }}
          </v-btn>
          <br>
          <a
            @click="decline"
          >
            {{ $t('L_no_please_my_documents') }}
          </a>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Config from '@/shared/util/config';

export default {
  name: 'CRCStepD',
  data: () => ({
    offsetTop: 0,
  }),
  computed: {
    ...mapGetters({
      selectedPackage: 'childrider/NPPackage',
      isUSPolicy: 'childrider/isUSPolicy',
      isCanadaPolicy: 'childrider/isCanadaPolicy',
      isNYPolicy: 'childrider/isNYPolicy',
    }),
    disclaimerBody() {
      let body = 'L_disclaimer_body_us_ny';
      if (this.isUSPolicy) {
        body = 'L_disclaimer_body_us';
      }
      if (this.isCanadaPolicy) {
        body = 'L_disclaimer_body_ca';
      }
      return this.$t(body, { phone: Config.get('techSupportPhone') });
    },
  },
  methods: {
    ...mapActions({
      next: 'childrider/crcNext',
      pullAgreement: 'childrider/pullAgreement',
      setOptIn: 'childrider/setNPEDeliveryOptIn',
    }),
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    optIn() {
      this.setOptIn('Y');
      this.next();
    },
    decline() {
      this.setOptIn('N');
      this.next();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

