<template>
  <v-slider
    v-model="formInput.$model"
    :always-dirty="true"
    :min="min"
    :max="maxVal"
    :step="step"
    :ticks="ticks"
    :tick-labels="tickLabels"
    @input="onInput"
    @change="$emit('change', $event)"
  />
</template>

<script>
import BaseTextField from '../BaseTextField.vue';

export function tickLabelMapper(option) {
  if (typeof (option) === 'object') {
    return option.label || option.value;
  }
  return option;
}

export function optionValueMapper(option) {
  return (typeof (option) === 'number') ? option : option.value;
}

export default {
  name: 'Slider',
  extends: BaseTextField,
  props: {
    max: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    modelName: 'sliderValue',
    sliderValue: 0,
  }),
  computed: {
    maxVal() {
      return this.options ? this.options.length - 1 : this.max;
    },
    tickLabels() {
      return (this.options) ? this.options.map(tickLabelMapper) : undefined;
    },
    ticks() {
      return (this.options) ? 'always' : undefined;
    },
  },
  methods: {
    onChange(event) {
      const value = (this.options && this.options[event]) ? this.options[event] : event;
      BaseTextField.methods.onChange.bind(this)(typeof (value) === 'object' ? value.value : value);
    },
  },
};
</script>
