import { render, staticRenderFns } from "./ContactRepFooter.vue?vue&type=template&id=be08bd6a&scoped=true"
import script from "./ContactRepFooter.vue?vue&type=script&lang=js"
export * from "./ContactRepFooter.vue?vue&type=script&lang=js"
import style0 from "./ContactRepFooter.vue?vue&type=style&index=0&id=be08bd6a&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be08bd6a",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FContactRep%2FContactRepFooter.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports