<i18n src='../../../../locales.json'></i18n>
<template>
  <v-layout
    id="tablet_footer"
    row
    wrap
    class="visible-md-and-down hidden-lg-and-up hidden-xs-only tablet footer-wrapper"
  >
    <v-flex
      xs12
      class="text-xs-center pt-2 pb-2"
      :class="{
        'grey lighten-2': !detailsExpanded,
      }"
    >
      <a
        @click="detailsExpanded = !detailsExpanded"
        class="text-uppercase font-weight-regular font-size--12"
        style="margin-top: -6px;color: #333 !important;"
      >
        {{ detailsExpanded? $t('L_hide_details') : $t('L_view_more_details') }}
        <v-icon style="margin-bottom: -4px;">keyboard_arrow_{{ (detailsExpanded) ? 'down' : 'up' }}</v-icon>
      </a>
    </v-flex>
    <v-flex
      v-show="detailsExpanded"
      xs12
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          v-if="includeSpouseProp"
        >
          <v-tabs
            grow
            v-model="selectedTab"
            color="white"
            slider-color="transparent"
          >
            <v-tab
              color="black"
              @click="updateSelectedTabTop(0)"
            >
              {{ $t('L_you') }}
            </v-tab>
            <v-tab
              @click="updateSelectedTabTop(1)"
            >
              {{ $t('L_spouse') }}
            </v-tab>
          </v-tabs>
        </v-flex>
        <v-flex
          pt-2
          pl-4
          pr-4
          pb-2
          style="background-color: #eeeeee;"
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs6>
              <Achievement
                :progress="getProgressProp > 100 ? 100 : getProgressProp"
                :size="10"
              />
            </v-flex>
            <v-flex xs6>
              <v-layout
                row
                pl-4
                align-center
              >
                <v-flex
                  xs6
                  pr-3
                >
                  <GuidancePill
                    :header="selectedTab === 0 ? $t('L_pill_you_have') : $t('L_pill_spouse_has')"
                    :amount="``"
                    :footer="$t('L_pill_in_protection')"
                  >
                    <span>
                      <span style="font-size: 1.4em">{{ formattedCurrentCoverageProp }}*</span>
                    </span>
                  </GuidancePill>
                </v-flex>
                <v-flex
                  xs6
                  pl-3
                >
                  <GuidancePill
                    :header="selectedTab === 0 ? $t('L_pill_you_need') : $t('L_pill_spouse_needs')"
                    :amount="formattedNeedProp"
                    :footer="$t('L_pill_in_life_insurance')"
                  />
                </v-flex>
              </v-layout>
              <v-flex
                xs12
              >
                <h4 class="heading_four text-xs-center font-size--12">
                  <a
                    v-ada-modal
                    id="how_did_we_calculate_t_trigger"
                    href="javascript:void(0)"
                    @click="dialogTablet = true"
                  >{{ $t('L_how_did_we_calculate') }}</a>
                </h4>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      grey
      lighten-2
      pl-4
      pt-2
      pr-4
      pb-2
      xs12
    >
      <v-layout
        row
        wrap
        align-center
        justify-center
      >
        <v-flex
          xs6
          v-if="additionalNeedProp > 0"
        >
          <GuidancePill
            :header="$t('L_we_recommend_adding')"
            :amount="formattedAdditionalProp"
            :footer="$t('L_in_life_coverage')"
            class-string="sm6 offset-sm3"
          />
        </v-flex>
        <v-flex
          xs6
          pl-4
          class="text-xs-center"
          :class="{
            'offset-xs-3': additionalNeedProp > 0,
          }"
        >
          <v-btn
            v-if="displayContactRep"
            v-ada-modal
            id="contact_your_rep_guidance_trigger"
            block
            color="primary"
            @click="$emit('contact-rep', true)"
          >
            {{ $t('L_contact_your_rep') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-dialog
      v-model="dialogTablet"
      max-width="400"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-text
          class="font-weight-regular"
          v-html="$t('L_how_did_we_calculate_text')"
        />

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="lighten-2 how_did_we_calculate_t_close"
            color="primary"
            @click="dialogTablet = false"
          >
            Got It
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Achievement from '@/components/Achievement/Achievement.vue';
import GuidancePill from '../../../GuidancePill.vue';

export default {
  name: 'SidebarTablet',
  components: {
    GuidancePill,
    Achievement,
  },
  props: {
    includeSpouseProp: {
      type: Boolean,
      required: true,
      default: false,
    },
    getProgressProp: {
      type: Number,
      required: true,
      default: null,
    },
    formattedCurrentCoverageProp: {
      type: String,
      required: true,
      default: '',
    },
    formattedNeedProp: {
      type: String,
      required: true,
      default: '',
    },
    additionalNeedProp: {
      type: [Number, String],
      required: true,
      default: null,
    },
    formattedAdditionalProp: {
      type: [Number, String],
      required: true,
      default: null,
    },
    displayContactRep: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    updateSelectedTabTop(value) {
      this.$emit('updateSelectedTab', value);
    },
  },
  data: () => ({
    selectedTab: 0,
    dialogTablet: false,
    contactMeTablet: false,
    detailsExpanded: false,
    detailsHeight: 0,
  }),
  watch: {
    includeSpouseProp(newData) {
      if (newData === false) {
        this.updateSelectedTabTop(0);
        this.selectedTab = 0;
      }
    },
  },
};
</script>
