
<template>
  <div class="pagination-dots">
    <span
      v-for="(item, index) in numItems"
      :key="index"
      class="slider-circle"
      :class="{ 'active': index === currentIndex}"
      @click="$emit('clicked-dot', index)"
    />
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      required: true,
    },
    numItems: {
      type: Number,
      required: true,
    },
  },
};
</script>


<style lang="stylus" scoped>
  .pagination-dots
    width 100%
    text-align center
    .slider-circle
      display inline-block
      width 12px
      height 12px
      border-radius 50%
      border solid 1px white
      margin 0 4px;
      cursor: pointer;
      &.active
        background #ffffff
</style>
