import { getDefaultState } from './state';

export const setMfaToken = (state, value) => {
  state.mfaToken = value;
};

export const setLoading = (state, value) => {
  state.loading = value;
};

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};
