<i18n src="../locales.json"></i18n>
<template>
  <v-container>
    <v-layout column>
      <v-flex xs12 mt-4>
        <v-layout row wrap justify-center>
          <v-flex xs12 text-xs-center mb-4>
            <img alt="overview reinstatement icon" src="../assets/NextStepImageRedate.svg">
            <v-layout row wrap>
              <v-flex xs0 sm1 md2 lg2>
                &nbsp;
              </v-flex>
              <v-flex xs12 sm10 md8 lg8 font-size-16 font-weight-regular text-xs-left>
                <div class="font-size--16">
                  {{ $t('L_thank_you_for_providing_and_confirming') }}
                </div>
                <div class="font-size--16 mt-4">
                  {{ $t('L_you_will_now_complete_the_reinstatement_application_using_docusign_we_recommend_completing') }}
                </div>
                <div class="font-size--16 mt-4">
                  {{ $t('L_remember_you_will_need_to_provide_the_following_information') }}
                </div>
                <div class="font-size--16 mt-4 bluetext font-weight-bold">
                  <ul>
                    <li>{{ $t('L_medical_history') }}</li>
                    <li>{{ $t('L_bank_information_routing_transite_and_account_numbers') }}</li>
                  </ul>
                </div>
                <div class="font-size--16 mt-4">
                  <i18n path="L_if_you_have_question_or_prefer_to_recieve_a_paper_reinstament_applicatin" tag="p" class="font-weight-regular">
                    <span>{{ customerServicePhoneNum }}</span>
                  </i18n>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="zoffset-xs3 text-xs-right pl-2 pr-2" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
          <v-flex xs8 sm3 mb-3 :class="$vuetify.breakpoint.xsOnly?'':'mr-3'">
            <v-btn :disabled="disableActions" block color="secondary" class="mr-2" @click="cancelAction">
              {{ $t('L_cancel') }}
            </v-btn>
          </v-flex>
          <v-flex xs8 sm3 mb-3>
            <v-btn :disabled="disableActions" target="_blank" block color="primary" @click="continueAction">
              {{ $t('L_continue') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReinstatementProcessNextSteps',
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ getLocale: 'getLocale' }),
    customerServicePhoneNum() {
      if (this.getLocale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.getLocale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.getLocale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
  },
  methods: {
    continueAction() {
      this.$emit('continueClicked');
    },
    cancelAction() {
      this.$emit('cancelClicked');
    },
  },
};
</script>

<style lang="stylus" scoped>
.bluetext {
  color: #063562;
}
</style>
