<i18n src='./locales.json'></i18n>

<template>
  <div class="financial-house" :class="isMobile ? 'mobile' : 'pb-3'">
    <div class="text-xs-center white--text" :class="{ ' pt-3 pb-4 mb-5': !isMobile }">
      <v-layout row wrap align-center justify-center class="header" :class="{ 'mb-4': !isMobile }">
        <!-- have to use "isMobile" computed prop because grid breakpoints (overwritten in SASS) do not match Vuetify configuration -->
        <template v-if="isMobile">
          <HouseMainSVG :nav-type="1" :debt-solutions-active="debtSolActive" :investments-active="investmentsActive" :term-life-active="termLifeActive" @click="$router.push({ name: 'Dashboard' })" />
          <h2 role="heading" aria-level="2" class="heading_two ml-2 pl-2 font-weight-medium">
            {{ $t('L_my_financial_house') }}
          </h2>
          <a @click.prevent="show = !show">
            <v-icon class="font-size-48">{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
          </a>
        </template>
        <template v-else>
          <v-flex xs12 class="mb-3">
            <h2 role="heading" aria-level="2" class="heading_two font-weight-medium">
              {{ $t('L_my_financial_house') }}
            </h2>
          </v-flex>
          <v-flex xs12>
            <HouseMainSVG :nav-type="0" :debt-solutions-active="debtSolActive" :investments-active="investmentsActive" :term-life-active="termLifeActive" />
          </v-flex>
        </template>
      </v-layout>
      <v-layout row wrap class="body" v-if="show">
        <v-flex xs12 sm9 md4>
          <v-layout :class="{ 'cursor-pointer': debtSolActive }" @click="$emit('debt-freedom-action', $event)">
            <HouseNavSvg :svg-type="3" :active="debtSolActive" svg-title="Debt Solutions" />
            <v-flex class="text-xs-left pt-1 pl-2" id="debtcursor">
              <v-layout wrap>
                <v-flex xs12>
                  <h4 role="presentation" class="heading_four font-weight-medium active">
                    {{ $t('L_debt_freedom') }}
                  </h4>
                </v-flex>
                <v-flex xs12>
                  <h5 role="presentation" class="heading_five active">
                    <span>{{ debtSolActive ? $t('L_active') : $t('L_learn_more') }}</span>
                  </h5>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm9 md4>
          <v-layout :class="{ 'cursor-pointer': investmentsActive }" @click="$emit('investments-action', $event)">
            <HouseNavSvg :svg-type="2" :active="investmentsActive" svg-title="Investments" />
            <v-flex class="text-xs-left pt-1 pl-2" id="investmentcursor">
              <v-layout wrap>
                <v-flex xs12>
                  <h4 role="presentation" class="heading_four font-weight-medium active">
                    {{ $t('L_savings_investments') }}
                  </h4>
                </v-flex>
                <v-flex xs12>
                  <h5 role="presentation" class="heading_five active">
                    {{ investmentsActive ? $t('L_active') : $t('L_learn_more') }}
                  </h5>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm9 md4>
          <v-layout class="last" :class="{ 'cursor-pointer': termLifeActive }" @click="$emit('term-life-action', $event)">
            <HouseNavSvg :svg-type="1" :active="termLifeActive" svg-title="Life Insurance" />
            <v-flex class="text-xs-left pt-1 pl-2" id="termcursor">
              <v-layout wrap>
                <v-flex xs12>
                  <h4 role="presentation" class="heading_four font-weight-medium active">
                    {{ $t('L_term_life') }}
                  </h4>
                </v-flex>
                <v-flex xs12>
                  <h5 role="presentation" class="heading_five active">
                    {{ termLifeActive ? $t('L_active') : $t('L_learn_more') }}
                  </h5>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import HouseMainSVG from '@/components/HouseMainSVG/HouseMainSVG.vue';
import HouseNavSvg from '@/components/HouseNavSVG/HouseNavSVG.vue';

export default {
  name: 'FinancialHouse',
  components: { HouseMainSVG, HouseNavSvg },
  props: {
    debtSolActive: {
      type: Boolean,
      default: false,
    },
    debtSolCompleted: {
      type: Boolean,
      default: false,
      required: false,
    },
    investmentsActive: {
      type: Boolean,
      default: false,
    },
    termLifeActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    isMobile() {
      if (!this.isMobile) {
        this.show = true;
      }
    },
    $route() {
      if (this.isMobile) {
        this.show = false;
      }
    },
  },
  mounted() {
    if (!this.isMobile) {
      this.show = true;
    }
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables';

.financial-house {
  width: 100%;
  background-image: linear-gradient(to top, #012f60, #013b71);

  &.mobile {
    background: inherit;

    .header {
      margin: 0 auto;
      max-width: 75%;
    }

    .body {
      margin: 25px auto;
      max-width: 75%;
    }

    .heading_two {
      font-size: 14px !important;
      white-space: nowrap;
    }
  }

  &:not(.mobile) {
    .body > .flex {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .body {
    > .flex {
      margin: 0 auto;
    }

    .layout:not(.last):not(.wrap) {
      margin: 0 0 15px;
      padding: 0 0 15px;
      border-bottom: 2px solid #165390;

      @media $display-breakpoints.md-only {
        border-bottom: 0;
      }
    }
  }

  .heading_two {
    font-size: 16px !important;
  }

  .font-weight-medium {
    font-weight: 500 !important;
  }

  .inactive {
    opacity: 0.4;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .active-icon {
    position: absolute;
    left: 40px;
    color: #ffffff !important;
  }

  .theme--light.v-icon {
    color: #fff;
  }

  #termcursor, #investmentcursor, #debtcursor {
    cursor: pointer;
  }
}
</style>
