<i18n src="./locales.json"></i18n>
<template>
  <v-container>
    <v-layout row wrap justify-center>
      <v-flex xs12>
        <v-layout row wrap justify-center>
          <v-flex xs12 text-xs-center mb-4>
            <img alt="overview reinstatement icon" src="../assets/OverviewImage.svg">
          </v-flex>
          <v-flex xs12 sm8 md10 text-xs-left font-size--16 font-weight-regular>
            {{ $t('L_this_process_will_guide_you_through_the_steps_to_reinstate_your_primerica_life_insurance') }}
          </v-flex>
          <v-flex xs12 sm8 md10 text-xs-left font-size--16 font-weight-regular mt-4>
            {{ $t('L_the_following_information_will_be_needed_complete_the_application') }}
          </v-flex>
          <v-flex xs12 sm8 md10 mb-4 mt-4 text-xs-left font-size--16 font-weight-bold class="blueText">
            <ul>
              <li>{{ $t('L_medical_information_for_all') }}</li>
              <li>{{ $t('L_doctors_contact_information') }}</li>
              <li>{{ $t('L_the_bank_routing_and_account_number') }}</li>
            </ul>
          </v-flex>
          <v-flex xs12 sm8 md10 text-xs-left font-size--16 font-weight-regular>
            <i18n path="L_if_you_have_questions_or_prefer_to_receive_a_paper_reinstatement_application" tag="p" class="font-weight-regular">
              <span>{{ customerServicePhoneNum }}</span>
            </i18n>
          </v-flex>
          <v-flex xs12 mt-4>
            <v-layout row wrap class="zoffset-xs3 text-xs-right pl-2 pr-2" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
              <v-flex xs8 sm3 mb-3 :class="$vuetify.breakpoint.xsOnly ? '':'mr-3'">
                <v-btn :disabled="disableActions" block color="secondary" class="mr-2" @click="cancelAction">
                  {{ $t('L_cancel') }}
                </v-btn>
              </v-flex>
              <v-flex xs8 sm3 mb-3>
                <v-btn :disabled="disableActions" target="_blank" block color="primary" @click="continueAction">
                  {{ $t('L_continue') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReinstatementProcessOverview',
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ getLocale: 'getLocale' }),
    customerServicePhoneNum() {
      if (this.getLocale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.getLocale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.getLocale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
  },
  methods: {
    continueAction() {
      this.$emit('continueClicked');
    },
    cancelAction() {
      this.$emit('cancelClicked');
    },
  },
};
</script>

<style lang="stylus" scoped>
.blueText {
  color: #063562;
}
</style>
