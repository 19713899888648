<i18n src='./locales.json'></i18n>

<template>
  <v-flex
    xs12
    pt-3
  >
    <v-layout>
      <v-flex
        xs12
        pl-4
        pr-4
      >
        <h3
          class="heading_three"
        >
          {{ $t('L_assuming_above') }}
        </h3>
        <v-spacer class="mt-5" />
        <MonthlyIncome
          :heading="$t('L_monthly_survivors')"
          :form-input="$v.yourMonthly"
          v-model="yourMonthly"
          @input="onInput"
        />
        <v-spacer class="mt-5" />
        <HowManyYears
          :min="0"
          :max="100"
          :step="1"
          v-model="yourYears"
          @input="onInput"
        />
      </v-flex>
    </v-layout>
    <v-layout
      grey
      lighten-2
      v-show="includeSpouse"
    >
      <v-flex
        xs12
        pl-4
        pr-4
      >
        <v-spacer class="mt-5" />
        <MonthlyIncome
          :heading="$t('L_monthly_self')"
          :form-input="$v.monthly"
          v-model="monthly"
          @input="onInput"
        />
        <v-spacer class="mt-5" />
        <HowManyYears
          :min="0"
          :max="100"
          :step="1"
          v-model="years"
          @input="onInput"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import SurvivorMonthlyIncome from './components/SurvivorMonthlyIncome.vue';
import HowManyYears from './components/HowManyYears.vue';
import MonthlyIncome from './components/MonthlyIncome.vue';

export default {
  name: 'OngoingExpenses',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dimeData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => ({
    monthly: 0,
    years: 0,
    yourMonthly: 0,
    yourYears: 0,
  }),
  components: {
    MonthlyIncome,
    HowManyYears,
    SurvivorMonthlyIncome,
  },
  validations: {
    monthly: {},
    years: {},
    yourMonthly: {},
    yourYears: {},
  },
  computed: {
    ...mapGetters('dime', {
      includeSpouse: 'includeSpouse',
    }),
  },
  methods: {
    onInput() {
      this.$emit('updated', {
        you: {
          need: +this.yourMonthly,
          years: +this.yourYears,
        },
        spouse: {
          need: +this.monthly,
          years: +this.years,
        },
      });
    },
  },
  mounted() {
    this.yourMonthly = this.$v.yourMonthly.$model = this.dimeData.you.need;
    this.yourYears = this.$v.yourYears.$model = this.dimeData.you.years;
    this.monthly = this.$v.monthly.$model = this.dimeData.spouse.need;
    this.years = this.$v.years.$model = this.dimeData.spouse.years;
  },
};
</script>

<style lang="stylus" scoped>
.heading-icon
  margin: 0 16px -12px 0
</style>
