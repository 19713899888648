<i18n src='./locales.json'></i18n>

<template>
  <v-layout class="top align-center text-xs-center" row wrap>
    <v-flex xs12 md7 class="content">
      <h2 class="heading_two font-weight-medium">
        {{ $t('L_has_your_debt_situation_changed') }}
      </h2>
      <h4 class="heading_four font-weight-medium">
        {{ $t('L_if_so_update_your_debt_plan') }}
      </h4>
    </v-flex>
    <v-flex xs12 md5 class="learn-more">
      <v-btn
        block
        outline
        color="white"
        @click="updateMyPlan"
        :aria-label="$t('L_update_my_plan')"
      >
        {{ $t('L_update_my_plan') }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>

export default {
  name: 'DebtSolutionsCongratsTop',
  methods: {
    updateMyPlan() {
      if (this.$route.path !== '/debtsolutions/mydebt') {
        this.$router.push({ name: 'MyDebt' });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
  .textBlue
    color: #063562;
  .learn-more
    padding: 0 15px;
  .v-btn
    background-color: #063562 !important
    border: 2px solid #063562;
    padding-right:15px !important;
    color: white;
    border-color: white;
  .v-btn__content
    color: #063562;
  .font-weight-medium
    font-weight: 500;

</style>
