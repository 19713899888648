import AuthClient, { hybridAuthClient } from './clients/hybridAuthClient';

/*
* both mobile/web auth plugin should expose the auth client
* this getter returns the installed authentication client
*/
export const getAuthClient = () => hybridAuthClient;

/*
* authentication navguard to be used by the global nav guard in the vue route
*/
export const authenticationGuard = async (to, from, next) => {
  const toRouteRequiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (toRouteRequiresAuth) {
    const authClient = getAuthClient(); // TODO: this can be async;
    if (!authClient) {
      throw new Error('no auth client found');
    }
    const isAuthenticated = await authClient.isAuthenticated();
    if (!isAuthenticated) {
      try {
        const originalUriTracker = to.fullPath; // TODO save this in a store to be retrieved later on login callback
        window.localStorage.setItem('deeplinkroute', originalUriTracker);

        // set sourceId
        try {
          const srcId = to?.query?.source || window?.localStorage?.getItem('sourceId');
          if (srcId) {
            window.localStorage.setItem('sourceId', srcId); // set sourceId
          }
        } catch (error) {}

        await authClient.login().then(() => {
          // this only runs on mobile
          // this handles post login, instead of the redirect page
          next({ name: 'PostLoginLobby' });
        });
      } catch (error) {
        // TODO: handle error or log in DT
        await authClient.logout();
        await authClient.login().then(() => {
          next({ name: 'PostLoginLobby' });
        });
        if (error?.errorMessage === 'user canceled auth') {
          // TODO In Android device this can be recreated by ending session then killing app and relaunch app.
          return next(false);
        } else {
          next(new Error('Authentication Error')); // * authentication error
        }
      }
    } else {
      next(); // * user authenticated; move on to the next hook in the navigation pipeline
    }
  } else {
    next(); // * route does not need authentication
  }
};

export default {
  install: (Vue) => {
    const authClient = getAuthClient();
    if (Vue.prototype.$auth !== authClient) {
      Vue.prototype.$auth = authClient;
    }
  },
  initialize: () => AuthClient.initialize(),
  config: {
    setVaultErrorHandler: hybridAuthClient.setVaultErrorHandler,
  },
};
