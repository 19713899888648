<template>
  <v-layout class="loading-indicator" row justify-center align-center v-if="loading">
    <v-flex>
      <v-layout class="loading-wrapper" row wrap justify-center align-center>
        <v-flex class="title" v-if="title">
          {{ title }}
        </v-flex>
        <v-flex class="loader">
          <v-progress-circular indeterminate :size="size" :width="width" :color="color" />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    color: {
      type: String,
      default: '#063562',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 70,
    },
    title: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 7,
    },
  },
};
</script>

<style lang="stylus">
.loading-indicator
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(7000px);
  .loading-wrapper
    text-align: center;
  .title
    width: 100%;
    font-size: 16px !important;
</style>
