import DashboardLayout from '../../layouts/DashboardLayout.vue';
import MinorAccountLinkProcess from './layouts/MinorAccountLinkProcess.vue';

export default {
  path: '/minor-account-link-process',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'MinorAccountLinkProcess',
      component: MinorAccountLinkProcess,
      meta: {
        pageTitle: 'Investements.MinorAccountLinkProcess',
      },
    },
  ],
};
