<i18n src='./locales.json'></i18n>

<template>
  <div>
    <div class="font-weight-medium">
      {{ $t('L_contact_message') }}
    </div>
    <div class="message-box">
      <v-textarea
        :id="idProp"
        v-model="formInput.$model"
        :autocomplete="disableAutocomplete ? 'off' : null"
        :color="color"
        :append-icon="icon"
        :error-messages="errorMessages"
        @input="onInput"
        @blur="onBlur"
        :aria-describedby="describers"
        :placeholder="$t('L_type_your_message_here')"
        maxlength="500"
        rows="7"
      />
    </div>
  </div>
</template>

<script>
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'MessageInput',
  extends: BaseTextField,
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'message',
    },
  },
  data: () => ({
    modelName: 'message',
    message: '',
  }),
};
</script>
