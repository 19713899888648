<i18n src='../locales.json'></i18n>

<template>
  <v-container fluid>
    <v-layout column>
      <h1>Enter Information</h1>
      <p>{{ $t('mal_use_this_process_to_link_a_minors_account') }}</p>
      <p>
        {{ $t('mal_please_enter_the_minors_info') }}
        <v-icon
          color="#02579e"
          class="cursor-pointer"
          @click.stop="minorInformationTooltip=!minorInformationTooltip"
          @blur="minorInformationTooltip = !minorInformationTooltip"
          @focus="minorInformationTooltip = !minorInformationTooltip"
          size="20"
        >
          help
        </v-icon>
        <v-tooltip max-width="200" bottom v-model="minorInformationTooltip">
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on" />
          </template>
          <span>
            <p>{{ $t('mal_you_can_only_search_for_minor_account_as_custodian') }}</p>
          </span>
        </v-tooltip>
      </p>
      <v-layout row class="justify-start">
        <v-flex xs12 sm4 md4 lg4>
          <DateInput
            id="birth_date"
            v-model="birthDate"
            :form-input="$v.birthDate"
            :label="$t('mal_minors_date_of_birth')"
            :validation-messages="{
              date: $t('L_invalid_date')
            }"
          />
        </v-flex>
      </v-layout>
      <v-layout row class="justify-start">
        <v-flex xs12 sm4 md4 lg4>
          <SSNInput
            id="ssn"
            v-model="ssn"
            enable-masking
            :form-input="$v.ssn"
            :label="$t('mal_minors_ssn')"
            @focus="showTooltip = false"
            @blur="showTooltip = false"
          />
        </v-flex>
      </v-layout>
      <v-layout mt-4 row wrap :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
        <v-flex xs12 sm4 mb-3 ml-3 offset-md4 offset-lg4>
          <v-btn :disabled="disableActions" block color="secondary" @click="cancelAction">
            Cancel
          </v-btn>
        </v-flex>
        <v-flex xs12 sm4 mb-3 ml-3>
          <v-btn :disabled="disableActions" block color="primary" @click="continueAction">
            Continue
          </v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  DateInput,
  DateValidation,
  SSNInput,
  SSNValidation,
} from '@/components/Inputs';

export default {
  name: 'MinorAccountLinkProcessEnterInformation',
  components: {
    DateInput,
    SSNInput,
  },
  data: () => ({
    birthDate: '',
    ssn: '',
    minorInformationTooltip: false,
  }),
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // TODO: confirm with BA or remove
    // ...mapGetters({
    //   dateFormat: 'getDateFormat',
    // }),
    disableAccountHolderNameEdit() { return 0; },
  },
  methods: {
    continueAction() {
      this.$emit('continueClicked', { dob: this.birthDate, taxId: this.ssn });
    },
    cancelAction() {
      this.$emit('cancelClicked');
    },
  },
  validations() {
    return {
      birthDate: {
        // TODO: confirm with BA or remove
        /* date: DateValidation.date(false, this.isFrenchLocale),
        // We are using MM/DD/YYYY for Canada and US except for Quebec DD/MM/YYYY
        // so we can pass false flag for canada
        minDate: this.currentPolicy?.nextGenPolicy ? minDateNextGen(false, this.isFrenchLocale) : minDateNonNextGen(false, this.isFrenchLocale),
        // minDate: minDate(false, this.isFrenchLocale),
        // maxDate: maxDate(false, this.isFrenchLocale),
        dateLessThan15DayOldOrInFuture: dateLessThan15DayOldOrInFuture(false, this.isFrenchLocale),
        bornOnOrAfterIssue: bornOnOrAfterIssue(this.issueDateForCurrentPolicy, false, this.isFrenchLocale), */
        required,
        ...DateValidation,
      },
      ssn: {
        required,
        ...SSNValidation,
      },
    };
  },
};
</script>

<style>
</style>
