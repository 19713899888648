<i18n src="./locales.json"></i18n>

<template>
  <v-content
    id="supportedBrowsers"
    tabindex="-1"
    style="padding: 0 !important"
    aria-label="Unsupported Browser"
  >
    <v-container fluid class="supported-browsers-content mx-auto mt-4 mt-md-6 mb-4 pb-4">
      <v-card class="mx-auto mb-xs-12 mb-4 pb-4">
        <v-container pb-12 mb-12>
          <v-layout row wrap text-xs-center pt-4 mt-4 mb-4>
            <v-flex xs12>
              <div id="unsupported_browser_title" class="display-2 text-md-display-3 font-weight-regular text-center mt-sm-12">
                <span class="font-size--48">
                  {{ $t('L_unsupported_browser_title') }}
                </span>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap text-xs-center pb-4 mb-4>
            <v-flex xs12>
              <div id="unsupported_browser_prompt" class="title font-weight-regular text-center">
                <span>
                  {{ $t('L_unsupported_browser_prompt') }}
                </span>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap text-xs-center>
            <v-flex xs12 md4 pb-4>
              <div class="title font-weight-regular text-center">
                <v-img
                  contain
                  height="200"
                  width="200"
                  alt="Primerica"
                  class="mx-auto mb-2"
                  src="@/assets/browser-chrome.png"
                />
                <p>
                  <a class="title font-weight-regular" href="https://www.google.com/chrome/" target="_blank">{{ $t('L_unsupported_browser_google_chrome') }}</a>
                </p>
              </div>
            </v-flex>
            <v-flex xs12 md4 pb-4>
              <div class="title font-weight-regular text-center">
                <v-img
                  contain
                  height="200"
                  width="200"
                  alt="Primerica"
                  class="mx-auto mb-2"
                  src="@/assets/browser-edge.png"
                />
                <p>
                  <a class="title font-weight-regular" href="https://www.microsoft.com/en-us/edge" target="_blank">{{ $t('L_unsupported_browser_microsoft_edge') }}</a>
                </p>
              </div>
            </v-flex>
            <v-flex xs12 md4 pb-4>
              <div class="title font-weight-regular text-center">
                <v-img
                  contain
                  height="200"
                  width="200"
                  alt="Primerica"
                  class="mx-auto mb-2"
                  src="@/assets/browser-safari.png"
                />
                <p>
                  <a class="title font-weight-regular" href="https://www.apple.com/safari/" target="_blank">{{ $t('L_unsupported_browser_apple_safari') }}</a>
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-container>
  </v-content>
</template>

<script>
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';

export default {
  name: 'UnsupportedBrowser',
  components: {
    LocaleToggle,
  },
};
</script>

<style lang="stylus" scoped>
.supported-browsers-content {
  max-width: 100% !important;
  @media only screen and (min-width: 760px) {
    max-width: 75% !important;
  }
}
#unsupported_browser_title {
  @media only screen and (max-width: 760px) {
    font-size: 32px !important;
  }
}
#unsupported_browser_prompt {
  @media only screen and (max-width: 760px) {
    font-size: 16px !important;
  }
}
#supportedBrowsers p {
  color: #333 !important;
}
#supportedBrowsers a.text-decoration-none {
  text-decoration: none !important;
}
a.underline-link {
  text-decoration:underline !important;
}
.pad-bottom {
  padding-bottom: 100px;
}
</style>
