<i18n src='../locales.json'></i18n>

<template>
  <v-tooltip
    activator="#ssn"
    v-model="value"
    :nudge-top="$vuetify.breakpoint.smAndDown ? 25 : 0"
    :max-width="$vuetify.breakpoint.mdAndUp ? 184 : 800"
    :right="$vuetify.breakpoint.mdAndUp"
    :bottom="$vuetify.breakpoint.smAndDown"
  >
    <span v-if="tooltext != ''">{{ tooltext }}</span>
    <span v-if="tooltext == ''">{{ $t('L_child_rider_ssn_tooltip') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'SSNTooltip',
  props: {
    activator: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    tooltext: {
      type: String,
      default: '',
    },
  },
};
</script>
