<i18n src="./locales.json"></i18n>
<template>
  <v-layout row wrap>
    <v-flex class="rep" xs12 v-if="hasReps">
      <v-layout class="text-xs-left" row wrap>
        <v-flex xs12 sm12 text-xs-center :class="$vuetify.breakpoint.xsOnly?'':'centerVertically'">
          <span class="picture">
            <img :src="agentImageUrl" class="agentImage " alt="Rep" v-img-fallback="repFallbackImage">
            <span class="font-weight-bold ml-4 greyText" :class="$vuetify.breakpoint.xsOnly?'displayBlock font-size--14':'centerVerticallySpan font-size--18'">
              {{ $t('L_your_representative', { agent: agentName }) }}
            </span>
          </span>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="reps && reps.length > 0" class="mt-4 mb-4  justify-center">
        <v-flex xs12 v-for="rep in reps" :key="rep.agentId">
          <v-layout row wrap ml-4 mr-4 class="font-size--16 zjustify-left " :class="$vuetify.breakpoint.xsOnly ? 'text-xs-left': 'text-xs-left' ">
            <v-flex xs12 sm4 md3 v-if="rep.office && rep.office.phone">
              <span class="font-size--14 font-weight-medium pr-2"><v-icon color="#02579E" class="font-size--24">phone</v-icon></span>
              <a :href="'tel:+1' + rep.office.phone" class="">{{ rep.office.phone | phone }}</a>
            </v-flex>
            <v-flex xs12 sm4 md3 v-if="rep.useCellPhone && rep.cell">
              <span class="font-size--14 font-weight-medium pr-2"><v-icon color="#02579E" class="font-size--24">phone_android</v-icon></span>
              <a :href="'tel:+1' + rep.cell" class="">{{ rep.cell | phone }}</a>
            </v-flex>
            <v-flex xs12 sm4 md3 v-if="rep.office && rep.office.fax">
              <span class="font-size--14 font-weight-medium pr-2"><v-icon color="#02579E" class="font-size--24">print</v-icon></span>
              <a :href="'tel:+1' + rep.office.fax" class="">{{ rep.office.fax | phone }}</a>
            </v-flex>
            <v-flex xs12 sm4 md3 v-if="rep.email">
              <span class="font-size--14 font-weight-medium pr-2"><v-icon color="#02579E" class="font-size--24">email</v-icon></span>
              <span xs10 @click.prevent="emailRep(rep.agentId)">
                <a href="$t('L_rep_email_me')" class="">{{ $t('L_rep_email_me') }}</a>
              </span>
            </v-flex>
            <v-flex xs12 sm4 md3 v-if="rep.appointmentUrl">
              <span class="font-size--14 font-weight-medium pr-2"><v-icon color="#02579E" class="font-size--24">event</v-icon></span>
              <span xs10 @click.prevent="openCalendar(rep.appointmentUrl)">
                <a href="$t('L_make_an_appointment')" class="">{{ $t('L_make_an_appointment') }}</a>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
        <ContactRep v-if="contactAgent" v-model="contactMe" :agents="[contactAgent]" @close="contactMe = false"
                    @send-message="send($event)" close-selector="contact_your_rep_myrep_close"
                    modal-class="contact_your_rep_myrep_modal" :sender="sender"
        />
        <MessageDialog
          :show-dialog="dialog"
          :has-button-two="true"
          :is-persistent="true"
          type="info"
          :title="$t('L_you_are_being_redirected_to_an_appointment_scheduling_website')"
          :message="$t('L_this_link_will_take_you_to_a_third_party_site_that_has_its_own')"
          :button-two-label="$t('L_go_back')"
          :button-one-label="$t('L_continue')"
          @buttonTwoAction="dialog=false"
          @buttonOneAction="closeAndGoToCalendar"
        />
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MessageDialog from '@/components/MessageDialog.vue';
import { openInNewWindow } from '@/mixins/openNewWindow';
import { phoneNumber } from '@/shared/util/formatters';
import ContactRep from '../ContactRep/ContactRep.vue';
import sendRepEmail from '../ContactRep/sendEmail';

export default {
  name: 'ContactRepFooter',
  components: {
    ContactRep,
    MessageDialog,
  },
  mixins: [openInNewWindow],
  data() {
    return {
      dialog: false,
      // contactRep: false,
      contactMe: false,
      contactAgent: {},
    };
  },
  filters: {
    phone(number) {
      return phoneNumber(number);
    },
  },
  computed: {
    ...mapGetters('policy', ['currentPolicy']),
    ...mapGetters('policy', [
      'policiesSortedByDate',
    ]),
    ...mapGetters('customer', [
      'investmentsSortedByDate',
      'customerProfile',
    ]),
    ...mapGetters(['getAgent', 'agents']),
    hasReps() {
      return this.agents?.length > 0;
    },
    agentName() {
      return this.agent?.name ? this.agent.name : '';
    },
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    agent() {
      return (this.agents?.length > 0) ? this.agents[0] : {};
    },
    agentImageUrl() {
      return this.agent?.displayPic;
    },
    altAgentImageUrl() {
      return `Rep pic ${this.agent?.displayPic}`;
    },
    repFallbackImage() {
      // eslint-disable-next-line
      return require('@/assets/rep-thumb.svg');
    },
    reps() {
      const accounts = [];
      this.policiesSortedByDate.forEach((pol) => {
        if (pol.contractId?.length > 0) {
          accounts.push({
            product: `${this.$t('L_term_life_policy')} ${pol.contractId}`,
            agentId: pol.agentId,
          });
        }
      });
      this.investmentsSortedByDate.forEach((acct) => {
        if (acct.accountNumber?.length > 0) {
          accounts.push({
            product: `${acct.productType} ${acct.accountNumber}`,
            agentId: acct.agentId,
          });
        }
      });
      let reps = [];
      if (accounts?.length > 0) {
        accounts.forEach((acct) => {
          const found = reps.find(a => a.agentId === acct.agentId);
          if (!found) {
            const agent = this.getAgent(acct.agentId);
            if (agent && agent.agentId) {
              agent.products = [acct.product];
              agent.displayName = agent.name;
              reps.push(agent);
            }
          } else {
            reps = reps.map((rep) => {
              if (rep.agentId === acct.agentId) {
                rep.products.push(acct.product);
              }
              return rep;
            });
          }
        });
      }
      return reps;
    },
  },
  methods: {
    ...mapActions('customer', ['contactRepresentative']),
    sendRepEmail(emailPayload) {
      sendRepEmail.bind(this)(this.contactRepresentative, emailPayload);
    },
    emailRep(agentId) {
      const agent = this.reps.find(a => a.agentId === agentId);
      this.contactAgent = agent;
      this.contactMe = true;
    },
    openCalendar(calendarLink) {
      this.calendarLink = calendarLink;
      this.dialog = true;
    },
    async closeAndGoToCalendar() {
      this.dialog = false;
      await this.openInNewWindow({ url: this.calendarLink, target: '_blank' });
    },
  },
};
</script>

<style scoped lang="stylus">
.contactYourRepButtonWidth
  width: 264px;
  max-width: 265px;
  min-width: 264px;
.picture
  .agentImage
  .icon
    width: 80px;
    height: 60px;
    zmargin: 0 auto;
    zborder-radius: 50%;
.rep
    padding: 15px 0;
    background: #eee;
    .button-wrapper .v-btn
      width: 85%
      margin: 0 auto;
      background-color: #063562 !important;
.border
  border: 1px solid black;
.blueText
  color:#02579e;
  font-weight: bold;
.heading_three
  zalign-items: center;
  zdisplay: inline-flex;
  ztext-align: center;
  zmargin: auto;
  zwidth: 50%;
.centerVertically
  line-height: 60px;
  height: 60px;
  text-align: center;
.centerVerticallySpan
  display: inline-block;
  vertical-align: text-bottom;
.displayBlock
  display: block;
.greyText
  color: #333333;
  font-weight: 600;
</style>
