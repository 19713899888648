<template>
  <div class="update-bank-wrapper">
    <UpdateBankOne
      v-if="step === 0"
      :show-date-picker="showDatePicker"
      @goToStepTwo="goToStepTwo"
      @cancel="$emit('cancel')"
    />
    <UpdateBankTwo
      v-if="step === 1"
      :is-update-bank="isUpdateBank"
      :bank-info="bankInfo"
      @goToStepOne="goToStepOne"
      @goToStepThree="goToStepThree"
    />
    <UpdateBankThree
      v-if="step === 2"
      :bank-info="bankInfo"
      @goToStepTwo="goToStepTwo"
      @goToStepOne="goToStepOne"
      @done="$emit('done')"
    />
  </div>
</template>

<script>
import UpdateBankOne from './components/UpdateBankOne/UpdateBankOne.vue';
import UpdateBankTwo from './components/UpdateBankTwo/UpdateBankTwo.vue';
import UpdateBankThree from './components/UpdateBankThree/UpdateBankThree.vue';

export default {
  name: 'UpdateBank',
  components: {
    UpdateBankOne,
    UpdateBankTwo,
    UpdateBankThree,
  },
  props: {
    isUpdateBank: {
      type: Boolean,
      default: false,
    },
    showDatePicker: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    step: 0,
    bankInfo: {},
  }),
  methods: {
    goToStepOne() {
      this.step = 0;
    },
    goToStepTwo(bankInfo) {
      this.step = 1;
      this.bankInfo = bankInfo;
    },
    goToStepThree() {
      this.step = 2;
    },
    cancelAll() {
      this.$emit('cancelAll');
    },
  },
};
</script>

<style scoped>
.update-bank-wrapper {
  min-width: 90%;
}
</style>
