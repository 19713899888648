<template>
  <v-app>
    <SkipToMain />
    <FullScreenLoading v-if="rootLoading && $route.name !== 'PageNotFound'" aria-live="assertive" />
    <router-view />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import FullScreenLoading from '@/components/FullScreenLoading/FullScreenLoading.vue';
import Ada from '@/shared/ada/ada';
import SkipToMain from '@/components/SkipToMain/SkipToMain.vue';

export default {
  name: 'App',
  components: {
    FullScreenLoading,
    SkipToMain,
  },
  computed: {
    ...mapGetters({
      rootLoading: 'getRootLoading',
    }),
  },
  watch: {
    rootLoading(val) {
      const dialog = document.querySelector('.v-dialog--active:not([style*="300px"])');
      if (!val && dialog) {
        Ada.createTabLoopSelectors(dialog);
      }
    },
  },
  updated() {
    document.title = this.$route.name;
  },
};
</script>
<style lang="stylus">
@import url('https://fonts.googleapis.com/css?family=Roboto:100:300,400,500,700,900|Material+Icons&subset=greek,latin-ext');
  .application {
    font-family: 'Roboto';
  }
</style>
