<i18n src='../../locales.json'></i18n>

<template>
  <v-card>
    <v-layout
      class="justify-center"
    >
      <v-flex
        xs11
      >
        <v-card>
          <v-card-title
            class="justify-space-around text-xs-center"
          >
            <v-layout
              wrap
              row
              justify-center
            >
              <v-flex
                xs12
              >
                <h3 class="heading_three">
                  {{ $t('L_confirm_your_payment_option') }}
                </h3>
                <h4 class="mt-3 font-weight-regular" v-html="$t('L_primerica_life_will_immediately', { date: getWithDrawDate })" />
              </v-flex>
            </v-layout>
          </v-card-title>
          <!-- This is for mobile screen only -->
          <v-card-text v-if="$vuetify.breakpoint.xsOnly" class=" justify-center background-light-grey pl-4 textCenter">
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_premium_amount') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text"> {{ initialPremium }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Two -->
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ selpackage.pacAcctNum | hideNums }} </span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Three -->
            <v-layout
              row
              wrap
              mb-4
            >
              <v-flex
                xs12
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_routing_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ selpackage.pacBankNum | hideNums }}</span>
                </div>
              </v-flex>
            </v-layout>


            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_account_holder') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ selpackage.pacSignature }} </span>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankName }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <!-- This is for all screen sizes Except mobile -->
          <v-card-text v-if="!$vuetify.breakpoint.xsOnly"
                       class="background-light-grey pl-4"
          >
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_premium_amount') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text"> {{ initialPremium }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_account_holder') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ selpackage.pacSignature }} </span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Two -->
            <v-layout
              row
              wrap
              mb-4
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ selpackage.pacAcctNum | hideNums }} </span>
                </div>
              </v-flex>
              <!-- Second part in step one -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankName }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Three -->
            <v-layout
              row
              wrap
              mb-4
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_routing_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ selpackage.pacBankNum | hideNums }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Close rows -->
            <!-- Disclaimer -->
          </v-card-text>
          <v-layout
            row
            wrap
            mt-4
            pt2
          >
            <v-flex xs12>
              <div class="disclaimer-wrapper">
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    class="context"
                    v-html="$t(disclaimerBody)"
                  />
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                mt-3
                mb-4
                class="text-xs-left"
              >
                {{ $t('L_by_clicking_I_authorize_I_certify_that_I_have_read') }}
              </v-flex>
              <v-flex
                class="text-sm-right text-xs-center"
              >
                <v-layout
                  justify-center
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    md6
                  >
                    <v-btn
                      block
                      class="white--text mb-3 "
                      :disabled="!accepted"
                      @click="$emit('open-edelivery')"
                      color="primary"
                    >
                      {{ $t('L_i_authorize') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout
                  justify-center
                  xs12
                  md6
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    md6
                  >
                    <v-btn
                      block
                      class="mb-3  "
                      color="secondary"
                      @click="$emit('display-warning')"
                    >
                      {{ $t('L_cancel') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { filters } from '@/mixins/filters';

export default {
  name: 'ConfirmACHPayment',
  mixins: [filters],
  data: () => ({
    accepted: true,
  }),
  computed: {
    ...mapGetters({
      bankName: 'childrider/bankName',
      selpackage: 'childrider/NPPackage',
      selectedPremiumAmount: 'childrider/NPSelectedPremiumAmount',
      isCanadaPolicy: 'childrider/isCanadaPolicy',
      currentPolicy: 'policy/currentPolicy',
    }),
    getWithDrawDate() {
      const y = this.currentPolicy._paymentInfo._autoDraftPaymentDate;
      const x = parseInt(y, 10);
      if (y !== null && y !== undefined && x !== undefined && x !== null) {
        if (x === 1 || x === 21) {
          return `${x}st`;
        } else if (x === 2 || x === 22) {
          return `${x}nd`;
        } else if (x === 3 || x === 23) {
          return `${x}rd`;
        }
        return `${x}th`;
      } return '';
    },
    disclaimerTitle() {
      return `L_disclaimer_title_${this.isCanadaPolicy ? 'ca' : 'us'}`;
    },
    disclaimerBody() {
      return `L_disclaimer_body_${this.isCanadaPolicy ? 'ca' : 'us'}`;
    },
    initialPremium() {
      let premiumAmount = this.selectedPremiumAmount;
      const splitVal = premiumAmount.split('.');
      if (splitVal[1] != null) {
        splitVal[1] = (`${splitVal[1]}00`).slice(0, 2);
        premiumAmount = `${splitVal[0]}.${splitVal[1]}`;
      } else {
        premiumAmount = `${splitVal[0]}.00`;
      }
      return `$${premiumAmount}`;
    },
  },
};
</script>
<style scoped lang="stylus">
  .grey-ada-text
    color: #737171
  .disclaimer-wrapper
    overflow-y: scroll;
    max-height: 263px;
    min-height: 250px;
    border-radius: 2px;
    border: solid 1px #979797;
    padding: 20px;
  .show-hide
    color: #2196f3
  .textCenter
    text-align: center;
  .btnMaxWidth
    max-width : 444px;
</style>

