<i18n src='../../../locales.json'></i18n>

<template>
  <v-layout class="no-investments bottom align-center" row wrap justify-center>
    <v-flex xs3 md2 class="icon">
      <img
        class="icon"
        role="presentation"
        :alt="$t('L_pay_yourself_first')"
        src="@/assets/investments/calculator/savings-icon.svg"
      >
    </v-flex>
    <v-flex xs9 md5>
      <h4
        class="heading_four pa-0"
        :aria-label="$t('L_pay_yourself_first')"
      >
        {{ $t('L_pay_yourself_first') }}
      </h4>
      <div
        class="text"
        :aria-label="$t('L_not_what_you_earn')"
      >
        {{ $t('L_not_what_you_earn') }}
      </div>
    </v-flex>
    <v-flex xs12 md5 class="see_where">
      <v-layout row fill-height align-center justify-center>
        <v-btn
          block
          outline
          color="white"
          class="btn_see_where"
          @click="goToCalculator"
          :aria-label="$t('L_see_where_you_stand')"
          v-if="calculatorEnabled"
        >
          {{ $t('L_see_where_you_stand') }}
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '@/shared/constants';

export default {
  name: 'NoInvestmentsBottom',
  computed: {
    ...mapGetters(['containsFeature']),
    calculatorEnabled() {
      return this.containsFeature(FEATURE_FLAGS.RETIREMENT_CALCULATOR);
    },
  },
  methods: {
    goToCalculator() {
      this.$router.push({
        path: '/investments/calculator',
      });
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables';

.investments-card .no-investments.bottom
  .see_where
    padding-top: 10px;
  .btn_see_where
    width: 100%;
    max-width: 288px;
    margin-right: 0px;
    margin-left: 0px;
  .heading_four
    margin: 5px 0 3px;
    font-weight: 600;
  .icon
    text-align: center;
    width: 65px;
    height: 65px;
    object-fit: contain;

</style>
