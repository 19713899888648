<i18n src='./locales.json'></i18n>
<template>
  <v-text-field
    v-model="formInput.$model"
    :max-length="6"
    type="text"
    :id="idProp"
    :label="label || $t('L_postal_code') || 'Postal Code'"
    :autocomplete="disableAutocomplete ? 'off' : null"
    :color="color"
    :hint="hint"
    :disabled="disabled"
    mask="A#A #A#"
    :error-messages="errorMessages"
    @keydown="onKeyDown"
    @input="onInput"
    @blur="onBlur"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  data: () => ({
    modelName: 'postalCode',
    postalCode: '',
  }),
  components: { FieldIcon },
  name: 'PostalCodeInput',
  extends: BaseTextField,
  props: {
    territory: {
      type: String,
      required: false,
      default: undefined,
    },
    idProp: {
      type: String,
      required: false,
      default: 'postal_code',
    },
  },
  methods: {
    onKeyDown(e) {
      const keyCode = e.code.slice(0, -1);
      if ((keyCode !== 'Key' && keyCode !== 'Digit') || e.key.match(/[0-9A-CEGHJ-NPRSTV-Za-ceghj-nprstv-z]/)) {
        return;
      }
      e.preventDefault();
    },
  },
};
</script>
