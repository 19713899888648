export const types = [
  'email',
  'required',
  'minLength',
  'maxLength',
  'sameAs',
  'policyNumber',
  'uniqueAccount',
  'dateOfBirth',
  'accountExists',
  'name',
  'validForTerritory',
  'bankAcctNumber',
  'verifyBankAccountNumber',
  'bankRoutingNumber',
];


const messages = {
  firstName: {
    required: 'L_firstname_required',
    minLength: 'L_min_length_msg',
    maxLength: 'L_max_length_msg',
    name: 'L_firstname_invalid_msg',
  },
  lastName: {
    required: 'L_lastname_required',
    minLength: 'L_lastname_min_length_msg',
    maxLength: 'L_lastname_max_length_msg',
    name: 'L_lastname_invalid_msg',
    accountExists: 'L_lastname_not_exists',
  },
  emailAddress: {
    required: 'L_email_required',
    email: 'L_email_valid',
    noSingleQuotes: 'L_email_valid',
    maxLength: 'L_email_max_length',
    uniqueAccount: 'L_email_exist',
  },
  selectedOption: {
    required: 'L_must_selection_option',
  },
  confirmEmailAddress: {
    required: 'L_confirm_email_required',
    email: 'L_email_valid',
    maxLength: 'L_email_max_length',
    sameAs: 'L_confirm_email_match',
  },
  phoneNumber: {
    minLength: 'L_phone_length',
    uniqueAccount: 'L_phone_exist',
  },
  fundAccountNumber: {
    required: 'L_fund_account_number_required',
    uniqueAccount: 'L_fund_account_num_exists',
  },
  policyNumber: {
    required: 'L_policy_number_required',
    policyNumber: 'L_policy_num_rule',
    policyNumberCanada: 'L_policy_num_canada_rule',
    policyNumberUS: 'L_policy_num_us_rule',
    minLength: 'L_policy_num_min_length',
    uniqueAccount: 'L_policy_num_exist',
    validPolicy: 'L_policy_num_invalid',
  },
  socialSecurityNumber: {
    required: 'L_ssn_required',
    uniqueAccount: 'L_ssn_exist',
    accountExists: 'L_ssn_not_exists',
    minLength: 'L_ssn_min_length',
  },
  socialInsuranceNumber: {
    required: 'L_sin_required',
    minLength: 'L_sin_min_length',
  },
  otpCode: {
    required: 'L_otp_required_field',
    minLength: 'L_otp_code_min_length',
  },
  dateOfBirth: {
    required: 'L_date_of_birth_is_required',
    date: 'L_invalid_date',
    noUnder18: 'L_policy_owner_must_be_over_18',
    noPre1900Date: 'L_invalid_date',
  },
  generic: {
    invalid: 'L_generic_invalid',
  },
  password: {
    required: 'L_password_is_required',
    minLength: '',
    noSpaces: 'L_your_password_cannot_have_spaces',
    noInvalidSpecialChars: 'L_your_password_cannot_contain_certain_special_characters',
    requireUpperLowerCase: '',
    requireNumber: '',
    requireSymbol: '',
    notContainString: 'L_your_password_cannot_contain_your_user_id',
    noThreeCharsInRow: 'L_your_password_cannot_have_three_of_the_same_characters_in_a_row',
    passwordReqContent: 'L_your_password_must_contain_uppder_and_lower_letters_at_least_one_number',
  },
  confirmPassword: {
    required: 'L_confirm_password_required',
    sameAs: 'L_confirm_password_match',
  },
  territory: {
    required: 'L_territory_required',
    validSelection: 'L_territory_please_select_from_list',
  },
  postalCode: {
    required: 'L_postal_code_required',
    minLength: 'L_postal_code_min_length',
    validForTerritory: 'L_postal_code_invalid_for_territory',
  },
  zipCode: {
    required: 'L_zip_code_required',
    minLength: 'L_zip_code_min_length',
  },
  bankRoutingNumber: {
    required: 'L_bank_routing_number_required',
    notSameDigits: 'L_invalid_format',
    notConsecutive: 'L_invalid_format',
    minLength: 'L_bank_routing_number_min_length',
    routingNumberNotFound: 'L_invalid_routing_transit_number',
  },
  bankAccountNumber: {
    required: 'L_bank_account_number_required',
    notRouting: 'L_bank_number_same_as_routing',
    notSameDigits: 'L_invalid_format',
    notConsecutive: 'L_invalid_format',
    minLength: 'L_bank_account_number_min_length',
  },
  verifyBankAccountNumber: {
    required: 'L_verify_bank_account_number_required',
    sameAs: 'L_verify_bank_account_number_must_be_same',
    notRouting: 'L_bank_number_same_as_routing',
    notSameDigits: 'L_invalid_format',
    notConsecutive: 'L_invalid_format',
    minLength: 'L_bank_account_number_min_length',
  },
  datepicker: {
    required: 'L_datepicker_required',
    exceeded: 'L_datepicker_value_exceeded',
  },
  reason: {
    required: 'L_reason_required',
  },
  subject: {
    required: 'L_subject_required',
  },
  message: {
    required: 'L_message_required',
  },
  allocationPercentage: {
    required: 'L_allocation_percentage_required',
    allocationZeroOrLess: 'L_allocation_percentage_zero_or_less',
    allocationOverHundred: 'L_allocation_percentage_over_hundred',
    invalidCharacter: 'L_allocation_percentage_invalid_character',
  },
};

export function getTypes(field) {
  if (typeof (messages[field]) !== 'undefined') {
    return Object.keys(messages[field]);
  }
  return [];
}

export default messages;
