<i18n src='./locales.json'></i18n>

<template>
  <div id="alert">
    <v-alert
      :type="type"
      :value="true"
      :color="type"
      :class="{ alert: coloredBorder }"
    >
      <v-layout row>
        <slot name="message" />
        <v-flex v-if="!hasSlot('message')" xs11 sm11 md11 lg11>
          <div v-if="type === 'error' && expandable && details.detail">
            <v-icon v-if="selected !== index" @click="selected = index" color="#FFFFFF">
              expand_more
            </v-icon>
            <v-icon v-else @click="selected = -1" color="#FFFFFF">
              expand_less
            </v-icon>
            <span class="header">{{ message }}</span>
            <div v-if="selected === index" class="details">
              CorrelationId: {{ $t(details.correlationId) }} <br>
              TraceId: {{ $t(details.traceId) }} <br>
              Details: {{ $t(details.detail) }}
            </div>
          </div>
          <div v-else class="cssMessageBlack">
            {{ message }}
          </div>
        </v-flex>
        <v-flex v-if="dismissible" xs1 sm1 md1 lg1 text-xs-right>
          <v-icon @click="removeAlert(index)" color="#FFFFFF" class="font-size--20">
            close
          </v-icon>
        </v-flex>
      </v-layout>
    </v-alert>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      required: true,
    },
    details: {
      type: [Object, Error],
      required: false,
      default: () => {},
    },
    displayIcon: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    coloredBorder: {
      type: Boolean,
      default: true,
    },
    autoDismiss: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selected: -1,
  }),
  computed: {
    message() {
      if (this.details.key) {
        return this.$t(this.details.key);
      }
      return this.details.message;
    },
  },
  methods: {
    ...mapMutations({
      removeAlert: 'removeAlert',
    }),
    hasSlot(name = 'message') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
  mounted() {
    if (this.type !== 'error' && this.autoDismiss !== false) {
      window.setTimeout(() => {
        this.removeAlert(this.index);
      }, 5000);
    }
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'

#alerts
  .v-alert.error
    position: inherit;
  .v-expansion-panel
    box-shadow: none;
  .theme--light.v-icon
    color: white;
  .header
    padding-left: 10px;
  .details
    padding-left: 34px;
    overflow-wrap: anywhere;

.cssMessageBlack {
  color: black !important;
}
.alert{
  color: #333333;
}
.alert.success{
  border-top-color: #0b8d1f !important;
  i.v-alert__icon.v-icon{
    color: #0b8d1f !important;
  }
  background-color : #d8f8dd !important;
  border-bottom: 1px solid black;
}
.alert.warning{
  border-top-color: #f39c12 !important;
  i.v-alert__icon.v-icon{
    color: #f39c12 !important;
  }
  background-color : #fdf3e2 !important;
  border-bottom: 1px solid black;
}
.alert.error{
  border-top-color: #dd3333 !important;
  i.v-alert__icon.v-icon{
    color: #FFFFFF !important;
  }
  background-color : #eb0606 !important;
  border-bottom: 1px solid black;
}
.alert.info {
  border-top-color: #3399ff !important;
  i.v-alert__icon.v-icon{
    color: #3399ff !important;
  }
  background-color : #e2f0fe !important;
  border-bottom: 1px solid black;
}
div.v-alert a {
  color:#02579e !important;
}

</style>
