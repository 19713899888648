import { Policy } from './Policy';

export class Person {
  _dob;

  _firstName = '';

  _lastName = '';

  _middleName = '';

  _number = '';

  _sex = '';

  _taxId = '';

  constructor({
    PersonDOB,
    PersonFirstName,
    PersonLastName,
    PersonMiddleName,
    PersonNumber,
    PersonSex,
    PersonTaxID,
  }) {
    this._dob = PersonDOB;
    this._firstName = PersonFirstName;
    this._lastName = PersonLastName;
    this._middleName = PersonMiddleName;
    this._number = PersonNumber;
    this._sex = PersonSex;
    this._taxId = PersonTaxID;
  }

  get name() {
    return `${this._firstName} ${this._lastName}`;
  }

  get firstName() {
    return this._firstName || '';
  }

  get middleName() {
    return this._firstName || '';
  }

  get lastName() {
    return this._lastName || '';
  }

  get number() {
    return this._number || '';
  }

  get dob() {
    return Policy.parseDate(this._dob);
  }

  get sex() {
    return this._sex || '';
  }

  get taxId() {
    return this._taxId || '';
  }
}
