<i18n src="../locales.json"></i18n>
<template>
  <v-content class="pt-0">
    <v-layout row wrap mb-4>
      <v-flex xs12>
        <div class="firstTextContent pb-4">
          {{ textContentForPayment.first }}
        </div>
        <div class="secondTextContent pb-4">
          {{ textContentForPayment.second }}
        </div>
        <div class="thirdTextContent pb-4">
          {{ textContentForPayment.third }}
        </div>
        <!-- <span>{{ currentPolicy }}</span> -->
        <v-layout row wrap>
          <v-flex xs12 md6 mb-4>
            <v-card @click="selectedAmount = 0" :class="selectedAmount === 0 ? 'cardSelected' : 'cardNotSelected'" class="clickable">
              <v-layout row wrap>
                <v-flex xs6>
                  <div class="cardTitle">
                    {{ $t('L_total_due') }}
                  </div>
                  <div class="cardAmount">
                    {{ getTotalDue }}
                  </div>
                </v-flex>
                <v-flex xs6 class="vertCenterCircleHoriRight">
                  <div v-if="selectedAmount === 0">
                    <v-icon color="green">
                      check_circle
                    </v-icon>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
            <div class="cardFooter" v-if="ifNotInsufficientFund">
              <i18n
                path="L_this_amount_pays_your_premium_through"
              >
                <span>{{ getTotalPayDate }}</span>
              </i18n>
            </div>
          </v-flex>
          <v-flex xs12 md6 v-if="!hideMinimum">
            <v-card @click="selectedAmount = 1" :class="selectedAmount === 1 ? 'cardSelected' : 'cardNotSelected'" class="clickable">
              <v-layout row wrap>
                <v-flex xs6>
                  <div class="cardTitle">
                    {{ $t('L_minimum_due') }}
                  </div>
                  <div class="cardAmount">
                    {{ getMinimumDue }}
                  </div>
                </v-flex>
                <v-flex xs6 class="vertCenterCircleHoriRight">
                  <div v-if="selectedAmount === 1">
                    <v-icon color="green">
                      check_circle
                    </v-icon>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
            <div class="cardFooter">
              {{ $t('L_this_is_the_minimum_amount_to_pay_to_keep_') }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <div class="text-xs-center justify-center">
      <v-layout row wrap>
        <v-flex xs0 sm0 md4>
          &nbsp;
        </v-flex>
        <v-flex xs12 sm12 md4 pr-4>
          <v-btn
            block
            color="secondary"
            @click="cancelPayment"
            class="maxwidthButton"
          >
            {{ $t('L_cancel') }}
          </v-btn>
        </v-flex>
        <v-flex xs12 sm12 md4 pr-4>
          <v-btn
            block
            class="white--text mb-3 maxwidthButton"
            @click="goToNextStep"
            :disabled="selectedAmount === -1"
            color="primary"
          >
            {{ $t('L_continue') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-content>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { formatDate, currency } from '@/shared/util/formatters';

export default {
  name: 'PayStepOne',
  components: {},
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
    selectedAmount: -1,
    persistent: true,
    hideMinimum: false,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', [
      'policies',
      'currentPolicy',
      'routeNameToNavigateBack',
      'selectedPolicyIndex',
    ]),
    ...mapGetters({
      locale: 'getLocale',
      dateFormat: 'getDateFormat',
    }),
    textContentForPayment() {
      const contentToDisplay = { first: '', second: '', third: '' };
      // Returned amount due
      if (!this.currentPolicy?.paynowInfo.inSufficientFunds) {
        // only 1 payment
        if (!this.currentPolicy?.paynowInfo.hasMultipleOptions) {
          contentToDisplay.first = this.$t('L_your_amount_due_appears_below');
        } else {
          // more than 1 payment
          contentToDisplay.first = this.$t('L_The_amounts_below_represent_the_total_amount_due_and_minimum_amount_due');
          contentToDisplay.second = this.$t('L_You_can_pay_either_the_Total_or_Minimum_amount_to_keep_your_policy_coverage_active');
          contentToDisplay.third = this.$t('L_choose_your_payment_amount');
        }
      } else {
        contentToDisplay.first = this.$t('L_the_amount_below_represents_the_amount_due_based_on_a_returned_transaction');
        contentToDisplay.second = this.$t('L_this_amount_must_be_paid_before_you_can_make_additional_payments_for_this_policy_including_any_current_due_premiums');
      }
      return contentToDisplay;
    },
    getTotalDue() {
      return currency(+this.currentPolicy?.paynowInfo?.totalPaymentAmount, 2);
    },
    getMinimumDue() {
      return currency(+this.currentPolicy?.paynowInfo?.minimumPaymentAmount, 2);
    },
    getTotalPayDate() {
      return formatDate(this.currentPolicy?.paymentInfo?._billToDate, this.dateFormat);
    },
    ifNotInsufficientFund() {
      let x = true;
      if (this.currentPolicy?.paynowInfo?.inSufficientFunds) {
        x = false;
      }
      return x;
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    ...mapActions({
      paymentSelection: 'policy/paymentSelection',
    }),
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument']),
    async goToNextStep() {
      let amount = '';
      try {
        const type = (this.selectedAmount === 0) ? 'TOTAL' : 'MINIMUM';
        const res = await this.paymentSelection(type);
        if (res.status === 200 || res.status === 201) {
          if (this.selectedAmount === 0) {
            amount = this.getTotalDue;
            this.$emit('totalSelected');
          } else {
            this.$emit('minSelected');
            amount = this.getMinimumDue;
          }

          // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
          try {
            const i = this.$dynatrace.enterAction(`Payment_Selected: ${type} ${amount}`);
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }

          this.$emit('goToStepTwo', amount);
          // Setting data back to default;
          this.selectedAmount = -1;
        }
      } catch (error) {
        this.$store.commit('addError', { error, context: 'payment' }, { root: true });
      }
    },
    cancelPayment() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags
      try {
        const i = this.$dynatrace.enterAction('PC2_PolicySelection_Cancel');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('cancelDialog');
    },
  },
  mounted() {
    if (!this.currentPolicy?.paynowInfo?.hasMultipleOptions) {
      this.selectedAmount = 0;
      this.hideMinimum = true;
    }
  },
};
</script>

<style lang="stylus" scoped>
.bg-light-grey {
  background-color: #f5f5f5;
}
.cardNotSelected {
  max-width: 295px;
  border: 2px solid #cccccc;
  background-color: #ffffff;
  height: 85px;
}

.cardSelected {
  max-width: 295px;
  border: 2px solid #6cd07b;
  background-color: #d8f8dd;
  height: 85px;
}

.cardTitle {
  padding-top: 16px;
  padding-left: 16px;
  font-size: 12px;
  color: #333333;
}
.cardAmount {
  padding-top: 0px;
  padding-left: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.cardFooter {
  padding-top:8px;
  font-size: 12px;
  color: #333333;
}
.vertCenterCircleHoriRight {
  margin: auto;
  text-align: right;
  padding-right: 10px;
}
.firstTextContent {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.secondTextContent {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.thirdTextContent {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.maxwidthButton {
  max-width: 300px;
}
.moveRight {
  text-align: right;
}
.clickable {
  cursor: pointer;
}
</style>
