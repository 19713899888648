import { Address } from './Address';
import { Policy } from './Policy';

export class Owner {
  _firstName;

  _lastName;

  _dob;

  _ssn;

  _address;

  _email;

  constructor({
    firstName,
    lastName,
    dob,
    ssn,
    address,
    email,
  }) {
    this._firstName = firstName;
    this._lastName = lastName;
    this._dob = Policy.parseDate(dob, 'MM/DD/YYYY');
    this._ssn = ssn;
    this._address = new Address(address);
    this._email = email;
  }

  get name() {
    return (!!this._firstName && !!this._lastName) ? `${this._firstName} ${this._lastName}` : '';
  }

  get dob() {
    return this._dob;
  }

  get email() {
    return this._email;
  }

  set email(email) {
    if (!email) {
      return;
    }
    this._email = email;
  }

  get ssn() {
    return this._ssn;
  }

  get address() {
    return this._address;
  }
}
