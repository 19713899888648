<i18n src='./locales.json'></i18n>

<template>
  <PrimericaBenefits
    id="policy-benefits"
    :footer-content="footerContent"
  >
    <template v-slot:hero>
      <img
        alt="$t('L_policy_protect_what_matters')"
        src="../../../assets/FamilyHavingFunAtHome1.jpg"
      >
      <h1
        :aria-label="$t('L_policy_protect_what_matters')"
      >
        {{ $t('L_policy_protect_what_matters') }}
      </h1>
    </template>
    <template v-slot:content>
      <h2
        class="header heading_two"
        :aria-label="$t('L_policy_buy_term')"
      >
        {{ $t('L_policy_buy_term') }}
      </h2>
      <div class="text">
        {{ $t('L_policy_primericas_philosophy') }}
      </div>
      <Video :url="$t('L_video_url_theory_of_decreasing_responsibility')" />
      <v-layout class="body" row wrap>
        <h2
          class="flex xs12 heading_two"
          :aria-label="$t('L_policy_theory')"
        >
          {{ $t('L_policy_theory') }}
        </h2>
        <v-flex
          xs12
          class="text"
          v-html="$t('L_policy_according_to_theory')"
        />
      </v-layout>
    </template>
  </PrimericaBenefits>
</template>

<script>
import { mapGetters } from 'vuex';
import Video from '@/components/Video.vue';
import PrimericaBenefits from '@/components/PrimericaBenefits.vue';

export default {
  name: 'PolicyBenefits',
  components: {
    Video,
    PrimericaBenefits,
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    footerContent() {
      return {
        title: this.$t('L_policy_benefits_heading'),
        subtext: this.$t('L_disclaimer1_numbers_reflect_the_combined'),
        subtext2: this.$t('L_disclaimer2_the_following_companies_have'),
        subtext3: this.$t('L_disclaimer3_without_waiver_of_premium_terminal'),
        subtitle: this.$t('L_policy_benefits_subheading'),
        cards: [
          {
            // eslint-disable-next-line
            icon: require('../../../assets/policies/icon4.svg'),
            text: this.$t('L_policy_benefits_card_4'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/policies/icon5.svg'),
            text: this.$t('L_policy_benefits_card_5'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/policies/icon6.svg'),
            text: this.$t('L_policy_benefits_card_6'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/policies/icon1.svg'),
            text: this.$t('L_policy_benefits_card_1'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/policies/icon2.svg'),
            text: this.$t('L_policy_benefits_card_2'),
          },
          {
            // eslint-disable-next-line
            icon: require('../../../assets/policies/icon3.svg'),
            text: this.$t('L_policy_benefits_card_3'),
          },
        ],
      };
    },
  },
};
</script>

<style lang="stylus">
#policy-benefits
  .content
    background: #fff;
    .video
      margin: 40px 0;
</style>
