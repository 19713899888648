<i18n src="../../locales.json"></i18n>
<template>
  <v-card>
    <v-layout class="justify-center">
      <v-flex xs11>
        <v-card>
          <v-card-title class="justify-space-around text-xs-center">
            <v-layout wrap row justify-center>
              <v-flex xs9>
                <h3 class="heading_three">
                  {{ $t('L_confirm_your_payment_option') }}
                </h3>
                <h4 class="mt-3 font-weight-regular" v-html="$t('L_primerica_life_will_send_you_a_bill_for')" />
              </v-flex>
            </v-layout>
          </v-card-title>
          <DirectPaymentPlaceHolder>
            <template slot="paymentType">
              {{ billMode }}
            </template>
            <template slot="premiumAmountPanel">
              <v-layout row wrap justify-center class="mx-4">
                <v-flex xs12 sm4>
                  <v-layout class="justify-center row wrap">
                    <v-flex class="font-weight-600 xs12 text-xs-center">
                      {{ $t('L_premium_amount') }}
                    </v-flex>
                  </v-layout>
                  <v-layout class="justify-center mt-2">
                    <v-flex class="xs12 text-xs-center">
                      {{ selectedPremiumAmount }}
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </template>
            <!-- <template
              slot="paymentAmountLabel"
            >
              {{ $t('L_premium_amount') }}
            </template>
            <template
              slot="paymentAmount"
            >
              {{ selectedPremiumAmount }}
            </template>
            <template
              slot="nextPaymentDateLabel"
            >
              {{ $t('L_due_date') }}
            </template>
            <template
              slot="nextPaymentDate"
            >
              06/01/2020
            </template> -->
            <template slot="billingInformationLabel">
              {{ $t('L_billing_information') }}
            </template>
            <template slot="description">
              <i18n path="L_edit_this_info" tag="span" class="font-weight-regular">
                <a href="javascript:void(0)" @click="$emit('open-contact-details')" class="text-decoration-underline">{{ $t('L_contact_details') }}</a>
              </i18n>
            </template>
            <template slot="mailingAddressLabel">
              {{ $t('L_mailing_address') }}
            </template>
            <template slot="address">
              <v-flex xs12>
                {{ selpackage.addrLine1 }}
              </v-flex>
              <v-flex xs12>
                {{ selpackage.addrLine2 }}
              </v-flex>
              <v-flex xs12>
                {{ selpackage.addrCity }}, {{ selpackage.addrState }} {{ selpackage.addrZip }}
              </v-flex>
            </template>
          </DirectPaymentPlaceHolder>
          <v-card-actions>
            <v-layout row wrap justify-center class="py-4">
              <v-flex xs7 class="my-2">
                <v-btn block color="primary" @click="$emit('open-edelivery')">
                  {{ $t('L_i_authorize') }}
                </v-btn>
              </v-flex>
              <v-flex xs7 class="my-2">
                <v-btn id="direct_payment_cancel_trigger" block color="default" aria-label="Close" @click="$emit('display-warning')">
                  {{ $t('L_cancel') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import DirectPaymentPlaceHolder from '@/components/DirectPayment/DirectPaymentPlaceHolder.vue';
import stringValues from '@/shared/util/stringValues';

export default {
  name: 'ConfirmDirectBillPayment',
  components: {
    DirectPaymentPlaceHolder,
  },
  computed: {
    ...mapGetters({
      selpackage: 'childrider/NPPackage',
      NPSelectedPremiumAmount: 'childrider/NPSelectedPremiumAmount',
    }),
    selectedPremiumAmount() {
      return `$${this.NPSelectedPremiumAmount}`;
    },
    billMode() {
      // Adding Quarterly here for C2, D2, H1
      if (this.selpackage?.paymentForm === 'C2' || this.selpackage?.paymentForm === 'D2' ||
       this.selpackage?.paymentForm === 'H1' || (this.selpackage?.paymentForm === 'A1' && this.selpackage?.paymentMode === '1')) {
        return stringValues.billingMode('3');
      } return stringValues.billingMode(this.selpackage?.paymentMode);
    },
  },
};
</script>
