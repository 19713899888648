<i18n src='./locales.json'></i18n>

<template>
  <div
    id="header"
    role="banner"
  >
    <div v-if="showSignOutConfirmationMessage" class="modal">
      <MessageDialog
        :show-dialog="showSignOutConfirmationMessage"
        :has-button-two="true"
        type="warning"
        :title="$t('L_sign_out_confirmation')"
        :message="$t('L_confirmation_message')"
        :button-two-label="$t('L_cancel')"
        :button-one-label="$t('L_sign_out')"
        :is-persistent="true"
        @buttonTwoAction="closeTheSignOutConfirmationAlert"
        @buttonOneAction="closeTheSignOutConfirmationAndLogout"
      />
    </div>
    <NavigationDrawer
      v-if="drawer"
      :drawer-prop="drawer"
      :toggle-drawer-prop="toggleDrawer"
      :selected-item="selectedItem"
      :focus-menu-close-prop="focusMenuClose"
      :home-action-prop="homeAction"
      :investments-action-prop="investmentsAction"
      :investments-active-prop="investmentsActive"
      :life-action-prop="termLifeAction"
      :rep-action-prop="repAction"
      :help-active-prop="helpActive"
      :language-active-prop="languageActive"
      :help-action-prop="helpAction"
      :documents-action-prop="documentsAction"
      :debt-active-prop="debtSolActive"
      :debt-action-prop="debtSolAction"
      :show-rep-link="showRepLink"
      :show-doc-link="showDocLink"
      :account-action-prop="accountAction"
      :hide-all-but-locale="hideAllButLocale"
      :logout-prop="logout"
      :languages="languages"
      :current-locale="currentLocale"
      @reupdate-locale="updateLocale"
      :mobile="mobile"
      :click-to-close-the-drawer="clickToCloseTheDrawer"
    />
    <v-toolbar
      id="main_toolbar"
      aria-label="main"
      fixed
      class="z11 elevation-0"
      :class="{
        'collapse': toolbarHidden,
      }"
    >
      <v-layout>
        <v-flex
          :class="$vuetify.breakpoint.lgAndUp?'mt-3':'mt-1'"
        >
          <v-layout>
            <img
              class="ml-2 cursor-pointer"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-4':'mt-3'"
              src="../../assets/primerica-color-logo.svg"
              :height="isMobile ? '34' : '40'"
              alt="Primerica Beta"
              @click="homeDashboard"
              v-if="!hideAllButLocale"
            >
            <v-flex
              grow
              ml-2
              pl-3
              pt-4
              class="hidden-md-and-down"
            >
              <v-layout
                row
                wrap
                pb-2
              >
                <v-flex
                  ml-2
                  shrink
                  text-xs-left
                >
                  <a
                    v-if="!hideAllButLocale"
                    href="javascript:void(0)"
                    class="pl-2 pr-2 heading_three"
                    :class="[selectedItem==='Home'?'selectedMenuItemClass':'nonSelectedMenuItemClass', isWideEnoughForFullMenu ? 'font-size--16': 'font-size--14' ]"
                    :aria-label="$t('L_home')"
                    @click="homeAction"
                  >
                    {{ $t('L_home') }}
                  </a>
                  <a
                    v-if="!hideAllButLocale"
                    href="javascript:void(0)"
                    class="pl-2 pr-2 heading_three"
                    :class="[selectedItem==='Life Insurance'?'selectedMenuItemClass':'nonSelectedMenuItemClass', isWideEnoughForFullMenu ? 'font-size--16': 'font-size--14' ]"
                    :aria-label="$t('L_life_insurance')"
                    @click="termLifeAction"
                  >
                    {{ $t('L_life_insurance') }}
                  </a>

                  <a
                    v-if="!hideAllButLocale"
                    href="javascript:void(0)"
                    class="pl-2 pr-2 heading_three"
                    :class="[selectedItem==='Investments'?'selectedMenuItemClass':'nonSelectedMenuItemClass', isWideEnoughForFullMenu ? 'font-size--16': 'font-size--14' ]"
                    :aria-label="$t('L_investment')"
                    @click="investmentsAction"
                  >
                    {{ $t('L_investment') }}
                  </a>

                  <a
                    v-if="!hideAllButLocale"
                    href="javascript:void(0)"
                    class="pl-2 pr-2 heading_three"
                    :class="[selectedItem==='Debt Solutions'?'selectedMenuItemClass':'nonSelectedMenuItemClass', isWideEnoughForFullMenu? 'font-size--16': 'font-size--14' ]"
                    :aria-label="$t('L_debt')"
                    @click.prevent="debtSolAction"
                  >
                    {{ $t('L_debt') }}
                  </a>
                  <a
                    v-if="showRepLink && !hideAllButLocale"
                    href="javascript:void(0)"
                    class="pl-2 pr-2 heading_three"
                    :class="[selectedItem==='My Rep'?'selectedMenuItemClass':'nonSelectedMenuItemClass', isWideEnoughForFullMenu? 'font-size--16': 'font-size--14' ]"
                    @click.prevent="repAction"
                  >
                    {{ $t('L_my_rep') }}
                  </a>
                  <a
                    v-if="showDocLink && !hideAllButLocale"
                    href="javascript:void(0)"
                    class="pl-2 pr-2 heading_three"
                    :class="[selectedItem==='Documents'?'selectedMenuItemClass':'nonSelectedMenuItemClass', isWideEnoughForFullMenu? 'font-size--16': 'font-size--14' ]"
                    @click.prevent="documentsAction"
                    :aria-label="$t('L_my_document')"
                  >
                    {{ $t('L_my_document') }}
                  </a>
                  <!-- <a
                    v-if="!hideAllButLocale && helpEnabled"
                    id="topnav_help"
                    href="javascript:void(0)"
                    class="pl-2 pr-2 heading_three labelBlack font-weight-regular"
                    :class="[!helpActive?'not-live':'', isWideEnoughForFullMenu? 'font-size--16': 'font-size--14' ]"
                    @click.prevent="helpAction"
                    :aria-label="$t('L_help')"
                  >
                    {{ $t('L_help') }}
                  </a> -->
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              v-if="!hideAllButLocale"
              ml-0
              shrink
              pt-4
              class="hidden-md-and-down menuGroupTwo font-size--16 pr-2"
              :class="[selectedItem==='Notifications'?'selectedMenuItemClass':'nonSelectedMenuItemClass']"
            >
              <v-icon
                @click.prevent="alertAndNotificationAction"
                color="#02579e"
              >
                notifications
              </v-icon>
              <sup
                class="redBadgeCircleSmall"
                v-if="notificationwarn"
              >
                <span class="warningTextInCircleSmall">!</span>
              </sup>
            </v-flex>
            <v-flex
              hidden-md-and-down
              shrink
              text-xs-right
              pt-4
            >
              <locale-toggle
                :current-locale="currentLocale"
                :languages="languages"
                :display="languageActive"
                :display-icon="true"
                @update-locale="updateLocale"
                submenu-location=""
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          class="pt-2"
          :class="$vuetify.breakpoint.lgAndUp?'shrink':''"
        >
          <v-layout
            row
            align-center
            justify-end
            :class="$vuetify.breakpoint.lgAndUp?'pt-4':'pt-2'"
          >
            <v-menu
              transition="slide-y-transition"
              class="hidden-md-and-down ml-2"
            >
              <a
                ref="menu"
                slot="activator"
                href="javascript:void(0)"
                role="button"
                aria-label="Main Menu"
                aria-haspopup="true"
                class="circle_blue"
              >
                {{ userInitial }}
              </a>
              <v-list
                class="hidden-md-and-down"
                ref="items"
              >
                <v-list-tile>
                  <v-list-tile-title
                    tabindex="-1"
                    role="button"
                    v-if="!hideAllButLocale"
                    @click.prevent="accountAction"
                  >
                    {{ $t('L_account_setting') }}
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-title
                    role="button"
                    tabindex="-1"
                    @click="logout"
                  >
                    {{ $t('L_sign_out') }}
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-layout>
          <v-layout
            row
            justify-end
            class="pt-2"
          >
            <div
              v-if="!hideAllButLocale"
              class="hidden-lg-and-up"
              :class="[selectedItem==='Notifications'?'selectedMenuItemClass':'nonSelectedMenuItemClass']"
              @click.prevent="alertAndNotificationAction"
            >
              <v-icon
                large
                mr-4
                color="#233b52"
              >
                notifications
              </v-icon>
              <sup
                class="redBadgeCircleLarge"
                v-if="notificationwarn"
              >
                <span class="warningTextInCircleLarge">!</span>
              </sup>
            </div>
            <a
              id="drawer_menu_open"
              href="javascript:void(0)"
              tabindex="0"
              role="button"
              aria-label="Mobile Navigation"
              class="hidden-lg-and-up ml-4"
              v-if="!mobile"
              :disabled="menuClickProcessing"
            >
              <v-icon
                v-if="drawer"
                @click.stop="clickToCloseTheDrawer"
                class="hamburger-menu"
              >
                close
              </v-icon>
              <v-icon
                v-else
                @click.stop="clickToOpenTheDrawer"
                class="hamburger-menu"
              >
                menu
              </v-icon>
            </a>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-toolbar>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Ada from '@/shared/ada/ada';
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer.vue';
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';
import MessageDialog from '@/components/MessageDialog.vue';
import Config from '@/shared/util/config';

export function defaultPolicyAction() {
  this.$router.push({ name: 'PolicyDetails' });
}

export function defaultAccountAction() {
  this.$router.push({ name: 'MyAccount' });
}

export function defaultRepAction() {
  this.$router.push({ name: 'Representative' });
}

export function defaultDebtSolutions() {
  this.$router.push({ name: 'DebtSolutions' });
}

export function defaultAlertAndNotificationAction() {
  this.$router.push({ name: 'AlertAndNotification' });
}

export function defaultDocumentsAction() {
  this.$router.push({ name: 'Documents' });
}


export default {
  name: 'Header',
  components: {
    LocaleToggle,
    NavigationDrawer,
    MessageDialog,
  },
  props: {
    homeAction: {
      type: Function,
      default: () => undefined,
    },
    investmentsAction: {
      type: Function,
      default: () => undefined,
    },
    lifeAction: {
      type: Function,
      default: () => undefined,
    },
    helpAction: {
      type: Function,
      default: () => undefined,
    },
    policyAction: {
      type: Function,
      default: defaultPolicyAction,
    },
    accountAction: {
      type: Function,
      default: defaultAccountAction,
    },
    repAction: {
      type: Function,
      default: defaultRepAction,
    },
    debtSolAction: {
      type: Function,
      default: defaultDebtSolutions,
    },
    termLifeAction: {
      type: Function,
      default: () => undefined,
    },
    alertAndNotificationAction: {
      type: Function,
      default: defaultAlertAndNotificationAction,
    },
    documentsAction: {
      type: Function,
      default: defaultDocumentsAction,
    },
    showRepLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDocLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    debtSolActive: {
      type: Boolean,
      default: false,
    },
    debtSolCompleted: {
      type: Boolean,
      required: false,
      default: false,
    },
    investmentsActive: {
      type: Boolean,
      default: false,
    },
    termLifeActive: {
      type: Boolean,
      default: false,
    },
    helpActive: {
      type: Boolean,
      default: false,
    },
    hideAllButLocale: {
      type: Boolean,
      default: false,
      required: false,
    },
    languageActive: {
      type: Boolean,
      default: false,
    },
    currentLocale: {
      type: String,
      required: true,
      default: () => 'en_US',
    },
    userInitial: {
      type: String,
      required: true,
      default: () => '',
    },
    selectedItem: {
      type: String,
      required: true,
      default: () => '',
    },
    languages: {
      type: Array,
      required: true,
      default: () => [],
    },
    notificationwarn: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    drawer: true,
    drawerWasClosed: false,
    scrollPosition: 0,
    toolbarHidden: false,
    menuClickProcessing: false,
    showSignOutConfirmationMessage: false,
  }),
  watch: {
    drawer(val) {
      if (!val) {
        this.drawerWasClosed = true;
        setTimeout(() => {
          this.drawerWasClosed = false;
        }, 850);
      }
    },
  },
  computed: {
    ...mapGetters({
      isMobileApp: 'isMobileApp',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isWideEnoughForFullMenu() {
      return this.$vuetify.breakpoint.width > 1284;
    },
    helpEnabled() {
      return Config.get('help.enabled');
    },
  },
  methods: {
    detailsMessage() {
      this.showDetailsMessage = true;
    },
    closeTheSignOutConfirmationAlert() {
      this.showSignOutConfirmationMessage = false;
    },
    async closeTheSignOutConfirmationAndLogout() {
      if (this.isMobileApp) {
        // ! clear browser session and revoke tokens
        await this.$auth.logout().catch(() => {
          // TODO: handle error or log in DT
        });
        this.showSignOutConfirmationMessage = false;
        await this.$store.dispatch('resetStore');
        await this.$router.push({ name: 'PostLogoutPorch' });
      } else {
        // ! clear browser session and revoke tokens
        await this.$auth.logout().catch(() => {
          // TODO: handle error or log in DT
        });
        this.showSignOutConfirmationMessage = false;
        await this.$store.dispatch('resetStore');
      }
    },
    async logout() {
      if (this.isMobileApp) {
        this.showSignOutConfirmationMessage = true;
      } else {
        // ! clear browser session and revoke tokens
        this.$auth.logout().catch(() => {
          // TODO: handle error or log in DT
        });
      }
    },
    homeDashboard() {
      this.$router.push({ name: 'Dashboard' });
    },
    toggleDrawer() {
      this.drawer = !this.drawerWasClosed;
    },
    focusMenuClose() {
      setTimeout(() => {
        document.getElementById('drawer_menu_close').focus();
      }, 250);
    },
    focusMenuOpen() {
      document.getElementById('drawer_menu_open').focus();
    },
    updateLocale(language) {
      this.$emit('reupdate-locale', language);
    },
    setDrawerFalse() {
      this.drawer = false;
    },
    clickToCloseTheDrawer() {
      this.menuClickProcessing = true;
      const tFlag = false;
      // Close the menu only if it is a hamburger menu option
      // on mobile browser.  We don't want to close the bottom navigation
      if (!this.mobile) {
        this.drawer = tFlag;
      }
      setTimeout(() => {
      // this.drawer = tFlag;
        this.menuClickProcessing = false;
      }, 150);
    },
    clickToOpenTheDrawer() {
      this.menuClickProcessing = true;
      const tFlag = true;
      this.drawer = tFlag;
      setTimeout(() => {
      // this.drawer = tFlag;
        this.menuClickProcessing = false;
      }, 150);
    },
  },
  mounted() {
    Ada.popupMenu(this.$refs.menu, this.$refs.items.$el);
    if (!this.mobile) {
      this.drawer = false;
    }
  },
};
</script>

<style lang="stylus">
@import '~vuetify/src/stylus/main'
.posrelative
  position: relative !important;
.menuGroupTwo
  margin-top: 2px;
.redBadgeCircleSmall
  width: 16px;
  background: red;
  position: relative;
  top: -20px;
  height: 15px;
  border-radius: 15px;
  left: -9px;
  display: inline-block;
.warningTextInCircleSmall
  display: inline-block;
  position: relative;
  left: 6px;
  top: 7px;
  font-size: 14px;
  color: #fff;
  font-weight: 800;
.redBadgeCircleLarge
  width: 15px;
  background: #f00;
  position: relative;
  top: -26px;
  height: 15px;
  border-radius: 15px;
  left: -11px;
  display: inline-block;
.warningTextInCircleLarge
  display: inline-block;
  position: relative;
  left: 8px;
  top: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 800;
#header
  h3.heading_three
    font-size: 20px !important;
    a
      font-weight: inherit;
      color: inherit !important;
  h4.heading_four
    &.inactive
      opacity: .4;
    a
      font-weight: inherit !important;
      color: inherit !important;
  .hamburger-menu
    font-size: 34px;
    color: #003261 !important;
  nav
    &#main_toolbar
      transition: all 300ms ease-in-out;
      background-color: #FFFFFF !important;
      height: 130px;
      margin-top: -50px !important;
      padding-top: 54px;
      &.collapse
        height: 0;
        margin-top: -56px !important;
    &.v-toolbar.mobile
      background-image: linear-gradient(to bottom, #003466, #00284e);
      .v-toolbar__content
        height: inherit !important;
        margin: 80px auto 0;
        padding: 0;
  a.heading_three
    font-size: 20px ;
    zfont-weight: normal;
    line-height: 30px;
    zcolor: black !important;
    @media $display-breakpoints.xs-only
      font-size: 16px;
  .layout > a.pl-4.heading_three.not-live
    color: #656565 !important;
    cursor: text;
  a.heading_four
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: black !important;
    @media $display-breakpoints.xs-only
      font-size: 14px;
  .layout > a.heading_four.not-live
    color: #656565 !important;
    cursor: text;
  .z11
    z-index: 11;
  .v-menu__content
    position: fixed !important;
    right 0 !important;
    left auto !important;
    top 80px !important;
    min-width 200px !important;
  .v-list
    >div:hover
      background-color #f0f0f0;
      cursor: pointer;
  /deep/ .v-overlay--active
    z-index 100;
  .cursor-pointer
    cursor: pointer;
  .circle_blue
    font-weight: 600;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 13px 0 0 1px;
    background: #02579e;
    /* border: 3px solid #000; */
    color: #FFFFFF !important;
    text-align: center;
    font: 18px Arial, sans-serif;
  .selectedMenuItemClass
    background-color: #f5f7fa;
    color: #02579e !important;
    font-weight: bold;
  .nonSelectedMenuItemClass
    background-color: #ffffff;
    color: #000000 !important;
    font-weight: normal;
  .labelBlack
    color: #000000 !important;
  .heading_three:hover
    background-color: #f5f7fa;
</style>
