<i18n src='../locales.json'></i18n>
<template>
  <v-flex
    xs12
    class="slider-box"
  >
    <!-- Slider text -->
    <div id="slider-wrapper">
      <v-layout
        id="slider"
        align-center
        column
        fill-height
      >
        <transition-group
          name="card"
        >
          <div
            role="heading"
            aria-level="1"
            class="card card-text"
            v-for="(item) in currentSliderItem"
            v-touch="{
              left: () => changeSlide(currentSliderIndex + 1),
              right: () => changeSlide(currentSliderIndex - 1),
            }"
            :key="item.id"
          >
            <div class="greeting font-size-mobile">
              {{ $t('L_welcome_sentence') }} {{ customerProfile.firstName }}
            </div>
          </div>
        </transition-group>
      </v-layout>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardMessageSlider',
  data: () => ({
    currentSliderIndex: 0,
    sliderItems: [
      { id: Math.random() },
      { id: Math.random() },
      { id: Math.random() },
    ],
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    currentSliderItem() {
      return [this.sliderItems[this.currentSliderIndex]];
    },
  },
  methods: {
    changeSlide(index) {
      switch (true) {
        case (index < 0):
          this.currentSliderIndex = this.sliderItems.length - 1;
          break;
        case (index >= this.sliderItems.length):
          this.currentSliderIndex = 0;
          break;
        default:
          this.currentSliderIndex = index;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>

#slider-wrapper
  width 100%
  padding-top 20px;
  padding-bottom 20px;
  // overflow hidden
  position relative
  #slider
    .card
      width 100%
      height 100%
  #dots
    width 100%
    text-align center
    // New style
    .slider-circle
      display inline-block
      width 12px
      height 12px
      border-radius 50%
      border solid 1px white
      margin 0 4px;
      cursor: pointer;
      &.active
        background #ffffff
  .card-text
    font-size 24px
    font-weight normal
    font-style normal
    font-stretch normal
    line-height normal
    letter-spacing normal
    text-align center
    color #ffffff
    .greeting
      font-weight 600

.card
  transition all 0.5s
.card-enter, .card-leave-to
  transform scale(0)
  opacity 0;
.card-leave-to
  transform: translateX(-100%);
  opacity: 0;
.card-enter-to
  opacity 1
  transform scale(1)
.card-leave-active
  position absolute
.card-move
  opacity 1
  transition all 0.5s
.slider-box
  background-color: #184976;
  border-redius: 4px;
  @media only screen and (max-width: 768px) and (min-width: 600px)
    margin-top: 24px;

</style>
