<template>
  <div>
    <ConfirmACHPayment
      @open-edelivery="next"
      @display-warning="showWarning = true"
      v-if="isB2BillingForm"
    />
    <ConfirmDirectBillPayment
      @open-edelivery="next"
      @display-warning="showWarning = true"
      v-if="isA1orC2orD2BillingForm"
    />
    <WarningDialog
      v-if="showWarning"
      @hide-warning="showWarning = false"
      @go-to-dashboard="goToDashboard()"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmACHPayment from './components/ConfirmACHPayment/ConfirmACHPayment.vue';
import ConfirmDirectBillPayment from './components/ConfirmDirectBillPayment/ConfirmDirectBillPayment.vue';
import WarningDialog from './components/WarningDialog/WarningDialog.vue';

export default {
  name: 'CRCStepC',
  data: () => ({
    showWarning: false,
  }),
  components: {
    ConfirmACHPayment,
    ConfirmDirectBillPayment,
    WarningDialog,
  },
  methods: {
    ...mapActions({
      crcCancel: 'childrider/crcCancel',
      crcNext: 'childrider/crcNext',
    }),
    setDisplayWarning() {
      this.showWarning = true;
    },
    next() {
      this.crcNext({ paymentInfo: 'paymentInfo' });
    },
    goToDashboard() {
      this.crcCancel()
        .then(() => {
          this.$router.push({ name: 'Dashboard' });
        });
    },
  },
  computed: {
    ...mapGetters({
      selectedPackage: 'childrider/NPPackage',
    }),
    isB2BillingForm() {
      return this.selectedPackage.paymentForm === 'B2';
    },
    isA1orC2orD2BillingForm() {
      return this.selectedPackage.paymentForm === 'A1' || this.selectedPackage.paymentForm === 'C2' || this.selectedPackage.paymentForm === 'D2'
      || this.selectedPackage.paymentForm === 'H1';
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
