<i18n src='./locales.json'></i18n>

<template>
  <v-container>
    <v-layout
      align-center
      justify-center
      row
      fill-height
    >
      <v-flex class="main-flex text-xs-center">
        <img
          class="pb-4 mb-2"
          src="../../assets/404.svg"
        >
        <h1>{{ $t('L_404_text') }}</h1>
        <h3
          class="heading_three font-size--18"
          v-html="$t('L_unfortunately_text', { home: home })"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: 'NotFoundPage',
  data: () => ({
    listener: undefined,
  }),
  computed: {
    home() {
      return document.getElementById('base_href').dataset.href;
    },
  },
  methods: {
    position() {
      const top = (document.documentElement.offsetHeight / 2) - (this.$el.offsetHeight / 2);
      this.$el.style.paddingTop = `${top}px`;
    },
  },
  mounted() {
    this.position();
    this.listener = window.addEventListener('resize', this.position.bind(this));
  },
  beforeDestroy() {
    if (this.listener) {
      window.removeEventListener('resize', this.listener);
    }
  },
};
</script>
<style lang="stylus" scoped>
.home-page-text
  color #02579e;
</style>

