import mydebt from './components/MyDebt/router';
import myplan from './components/MyPlan/router';
import learnmoredebt from './components/LearnMore/router';
import DashboardLayout from '../../layouts/DashboardLayout.vue';
import DebtSolutionsPage from './components/DebtSolutionsPage/DebtSolutionsPage.vue';

export default {
  path: '/debtsolutions',
  component: DashboardLayout,
  children: [
    {
      name: 'DebtSolutions',
      path: '',
      component: DebtSolutionsPage,
      children: [
        mydebt,
        myplan,
        learnmoredebt,
      ],
    },
  ],
};
