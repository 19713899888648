<template>
  <v-text-field
    v-model="formInput.$model"
    id="bank_name"
    :label="label || $t && $t('L_bank_name') || 'Bank Name'"
    :autocomplete="disableAutocomplete ? 'off' : null"
    disabled
    placeholder="Bank Name"
    :aria-describedby="describers"
  />
</template>
<script>
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'BankNameInput',
  extends: BaseTextField,
  data: () => ({
    modelName: 'bankName',
    bankName: '',
  }),
};
</script>
