export const openMap = {
  data: () => ({
    mapLinks: {
      iphone: 'maps://maps.google.com/?address=',
      android: 'http://maps.google.com/?daddr=',
      web: 'http://maps.google.com/?daddr=',
    },
    navigateTo: '',
    linkAddr: '',
  }),
  methods: {
    setAddressToNavigate(office) {
      const device = this.getUserAgent();
      const address2 = office.address2 ? ` ${office.address2}` : '';
      this.linkAddr = `${office.address1}${address2} ${office.city} ${office.state} ${office.zip}`;
      this.navigateTo = this.mapLinks[device]
                + this.linkAddr.toLowerCase().replace(/ /g, '+').replace(/,|-|_|\./g, '');
    },
    getUserAgent() {
      const uA = navigator.userAgent;
      if (uA.indexOf('iPhone') >= 0 || uA.indexOf('iPad') >= 0) {
        return 'iphone';
      } if (uA.indexOf('Android') >= 0) {
        return 'android';
      }
      return 'web';
    },
  },
};

