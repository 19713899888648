<i18n src='./locales.json'></i18n>
<template>
  <v-layout row wrap>
    <v-flex>
      <!-- Current Debt Info -->
      <v-layout row wrap text-xs-center pt-3 pb-3 class="graybar">
        <v-flex :class="$vuetify.breakpoint.xs ? 'currentdebtinfoMob xs12 pb-4' : 'currentdebtinfo pl-1 xs3'">
          {{ $t('L_current_debt_info') }}
        </v-flex>
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12' : 'xs3'" text-xs-center>
          <div :class="$vuetify.breakpoint.xs ? 'titleforpillMob pb-1': 'titleforpill'">
            {{ $t('L_your_total_current_debt') }}
          </div>
          <div :class="$vuetify.breakpoint.xs ? 'whitepillMob pb-3': 'whitepill ml-1 mr-1 '">
            {{ totalCurrentDebt }}
          </div>
        </v-flex>
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12 pt-4' : 'xs3'" text-xs-center>
          <div :class="$vuetify.breakpoint.xs ? 'titleforpillMob pb-1': 'titleforpill'">
            {{ $t('L_your_total_monthly_payment') }}
          </div>
          <div :class="$vuetify.breakpoint.xs ? 'whitepillMob pb-3': 'whitepill ml-1 mr-1 '">
            {{ totalDebtPayment }}
          </div>
        </v-flex>
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12 pt-4' : 'xs3 pr-1'">
          <div :class="$vuetify.breakpoint.xs ? 'updateyourdebtbuttonMob updateYourDebtLabelMobile' : 'updateyourdebtbutton'" @click="updateYourDebt">
            <img alt="Update Your Debt info image" class="positionIcon" src="../../../assets/update-24px.svg">&nbsp;<span class="text-decoration-underline">{{ $t('L_update_your_debt_info') }} </span>
          </div>
        </v-flex>
      </v-layout>
      <!-- With vs Without -->
      <v-layout row wrap text-xs-center class="blackborder">
        <!-- With Debt Stacking -->
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'">
          <v-layout row wrap>
            <v-flex xs12 class="debtstacking textcolorblue">
              {{ $t('L_with_debt_stacking') }}
              <v-tooltip max-width="200" html="true" open-on-click :open-on-hover="false" bottom close-delay="2000">
                <template v-slot:activator="{ on }">
                  <v-icon color="blue darken-2" dark small v-on="on" class="moveiconup">
                    help
                  </v-icon>
                </template>
                <span class="moveTextBelowIcon">
                  <p v-html="$t('L_with_debt_stacking_this_illustration_assumes_that')" />
                </span>
              </v-tooltip>
            </v-flex>
            <v-flex xs12 class="debtfreedomage textcolorblue">
              {{ $t('L_debt_freedom_age') }}
            </v-flex>
            <v-flex xs12 class="age textcolorblue">
              {{ primaryDatePayOffAgeWStacking }}
            </v-flex>
            <v-flex xs12 class="yearsold textcolorblue">
              {{ $t('L_years_old') }}
            </v-flex>
            <v-flex xs12 class="interestpaid textcolorblue">
              {{ $t('L_interest_paid') }}
            </v-flex>
            <v-flex xs12 class="interestpaidamount textcolorblue">
              {{ interestPaidWStacking }}
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- Without Debt Stacking -->
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'">
          <v-layout>
            <v-flex>
              <v-layout row wrap>
                <v-flex xs12 class="debtstacking blacktext">
                  {{ $t('L_without_debt_stacking') }}
                  <v-tooltip max-width="200" html="true" open-on-click :open-on-hover="false" bottom close-delay="2000">
                    <template v-slot:activator="{ on }">
                      <v-icon color="blue darken-2" dark small v-on="on" class="moveiconup">
                        help
                      </v-icon>
                    </template>
                    <span class="moveTextBelowIcon">
                      <p v-html="$t('L_without_debt_stacking_this_illustration_assumes_that')" />
                    </span>
                  </v-tooltip>
                </v-flex>
                <v-flex xs12 class="debtfreedomage blacktext">
                  {{ $t('L_debt_freedom_age') }}
                </v-flex>
                <v-flex xs12 class="age blacktext">
                  {{ debtFreeAgeWOStacking }}
                </v-flex>
                <v-flex xs12 class="yearsold blacktext">
                  {{ $t('L_years_old') }}
                </v-flex>
                <v-flex xs12 class="interestpaid blacktext">
                  {{ $t('L_interest_paid') }}
                </v-flex>
                <v-flex xs12 class="interestpaidamount blacktext">
                  {{ interestPaidWOStacking }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <div :class="$vuetify.breakpoint.xs ? 'debtStackingTopBottomSeparator' : 'debtStackingLeftRightSeparator'">
&nbsp;
        </div>
      </v-layout>
      <!-- 3-column marketing  -->
      <v-layout row wrap class="blueBackground">
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12 blueBackgroundMob blueBackgroundPaddingMob marginAuto' : 'xs4 blueBackground blueBackgroundPadding marginAuto'">
          <span v-html="$t('L_get_out_of_debt', {year:yearsSooner,month:monthsSooner})">&nbsp;</span>
        </v-flex>
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12 blueBackgroundMob marginAuto' : 'xs4 blueBackground centerDivUsingMargin marginAuto'">
          <div :class="$vuetify.breakpoint.xs ? 'whitetopbottomborder blueBackgroundPaddingMob':'whiteleftrightborder blueBackgroundPadding'" v-html="totalDebtPaymentBlueBar">
&nbsp;
          </div>
        </v-flex>
        <v-flex :class="$vuetify.breakpoint.xs ? 'xs12 blueBackgroundMob blueBackgroundPaddingMob marginAuto' : 'xs4 blueBackground blueBackgroundPadding marginAuto'">
          <span v-html="$t('L_avoid_paying_in_interest_charges', {interest:$n(interestSaved, 'currencyNoCents', 'en-US') })">&nbsp;</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="graybar" text-xs-center>
        <v-flex xs12 class="textcolorblue" font-size--12
                :class="$vuetify.breakpoint.xsOnly?'lineheightSmall pt-2 pb-2 pl-2 pr-2':'lineheightBig'"
        >
          {{ $t('L_still_have_questions_about_how_debt') }}
          <span @click="showDebtVideo" class="cursorPointer">&nbsp;<img alt="Debt stacking in action image" class="positionIcon" src="../../../assets/youtube.svg">&nbsp;</span>
          <span @click="showDebtVideo" class="font-weight-bold cursorPointer">{{ $t('L_see_debt_stacking_in_action' ) }} </span>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'DebtInfo',
  computed: {
    ...mapGetters({
      planWOStacking: 'debtsolutions/planWOStacking',
      planWStacking: 'debtsolutions/planWStacking',
    }),
    WOStackingMonth() {
      return (12 * this.planWOStacking?.planTotals?.debtPayoffYears) + this.planWOStacking?.planTotals?.debtPayoffMonths;
    },
    WStackingMonth() {
      return (12 * this.planWStacking?.planTotals?.debtPayoffYears) + this.planWStacking?.planTotals?.debtPayoffMonths;
    },
    yearsSooner() {
      return Math.floor((this.WOStackingMonth - this.WStackingMonth) / 12);
    },
    monthsSooner() {
      return (this.WOStackingMonth - this.WStackingMonth) % 12;
    },
    interestSaved() {
      return this.planWOStacking?.planTotals?.projectedInterestPaid - this.planWStacking?.planTotals?.projectedInterestPaid;
    },
    totalCurrentDebt() {
      const balance = this.planWOStacking?.planTotals?.balance;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(balance)) {
        return '';
      } return this.$n(balance, 'currencyNoCents', 'en-US');
    },
    totalDebtPayment() {
      const totalDebt = this.planWOStacking?.planTotals?.totalDebtPmt;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(totalDebt)) {
        return '';
      } return this.$n(totalDebt, 'currencyNoCents', 'en-US');
    },
    primaryDatePayOffAgeWStacking() {
      return this.planWStacking?.planTotals?.primaryDebtPayoffAge;
    },
    interestPaidWStacking() {
      return this.$n(this.planWStacking?.planTotals?.projectedInterestPaid, 'currencyNoCents', 'en-US');
    },
    interestPaidWOStacking() {
      return this.$n(this.planWOStacking?.planTotals?.projectedInterestPaid, 'currencyNoCents', 'en-US');
    },
    debtFreeAgeWOStacking() {
      return this.planWOStacking?.planTotals?.primaryDebtPayoffAge;
    },
    totalDebtPaymentBlueBar() {
      return this.$t('L_the_same_monthly_payment', { payment: this.$n(this.planWOStacking?.planTotals?.totalDebtPmt, 'currencyNoCents', 'en-US') });
    },
  },
  methods: {
    updateYourDebt() {
      if (this.$route.path !== '/debtsolutions/mydebt') {
        this.$router.push({ name: 'TermLifeBenefits' }).then(() => {
          this.$router.push({ name: 'MyDebt' });
        });
      }
    },
    showDebtVideo() {
      this.$emit('show-debt-video');
    },
  },
};
</script>

<style scoped lang="stylus">
.graybar
  background-color: #eeeeee;
.currentdebtinfo
  font-size:24px;
  color:#333333;
.currentdebtinfoMob
  font-size:24px;
  color:#333333;
.titleforpill
  font-size:14px;
  font-weight: 600;
.titleforpillMob
  font-size:14px;
  font-weight: 600;
  margin-top: -10px;
.whitepill
  background-color: white;
  border-radius: 16px;
  font-size:18px;
  font-weight:600;
  height:35px;
  line-height:35px;
  width: 135px;
  margin:auto;
  display:inline-block;
.whitepillMob
  background-color: white;
  border-radius: 16px;
  font-size:18px;
  font-weight:600;
  height:35px;
  line-height:35px;
  width: 150px;
  margin:auto;
.updateyourdebtbutton
  border: 1px solid #063562;
  font-size: 12px;
  color: #063562;
  padding: 0px 10px 10px 10px;
  margin-left:10px;
  margin-right:10px;
  background-color: #ffffff;
.updateyourdebtbuttonMob
  border: 1px solid #063562;
  font-size: 12px;
  color: #063562;
  padding: 0px 10px 0 10px;
  margin-left:10px;
  margin-right:10px;
  background-color: #ffffff;
  height:45px;
  line-height:45px;
  margin-left: 20px;
  margin-right: 20px;
.updateyourdebtbutton:hover
  background-color: #eeeeee;
  cursor: pointer;
.debtstacking
  font-size: 20px;
  margin-top:20px;
.debtfreedomage
  font-size:16px;
  font-weight:600;
  margin-top:20px;
.age
  font-size:42px;
  line-height:42px;
.yearsold
  font-size:12px;
.interestpaid
  font-size:12px;
  font-weight:600;
  margin-top:15px;
.interestpaidamount
  font-size:18px;
  margin-bottom:40px;
.blacktext
  color:#333333;
.lineargradient
  background-image: linear-gradient(to right, #000000, rgba(254, 254, 254, 0));
  height: 100%;
  width:16px;
  opacity: 0.26;
.blackborder
  border-top: 1px solid black;
  border-bottom:1px solid black;
.blueBackground
  background-color:#063562;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
.blueBackgroundMob
  background-color:#063562;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
.blueBackgroundPadding
  padding: 2px 40px 2px 40px;
.blueBackgroundPaddingMob
  padding: 12px 10px 12px 10px;
.textcolorblue
  color:#063562
.whiteleftrightborder
  border-left:1px solid #ffffff;
  border-right:1px solid #ffffff;
.whitetopbottomborder
  border-top:1px solid #ffffff;
  border-bottom:1px solid #ffffff;
  margin-left:20px;
  margin-right:20px;
.centerDivUsingMargin
  margin:auto;
.lineheightBig
  line-height:40px;
.lineheightSmall
  line-height:30px;
.moveiconup
  position:absolute;
  margin-top: -3px;
.positionIcon
  top: 5px;
  position: relative;
.updateYourDebtLabelMobile
  line-height: 15px;
  width: 180px;
  max-width: 180px;
  margin: auto;
.cursorPointer
  cursor: pointer;
.debtStackingTopBottomSeparator
  position: relative;
  background: linear-gradient(0, #ffffff, #607697);
  height:5px;
  width: 100%;
  margin-top: -258px;
.debtStackingLeftRightSeparator
  position: relative;
  left: 50%;
  background: linear-gradient(90deg, black, transparent);
  height: 254px;
  width: 5px;
  text-align: center;
  margin-top: -254px;
.marginAuto
  margin:auto;
</style>
