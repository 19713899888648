<template>
  <v-flex
    class="left-sidebar"
    v-show="$vuetify.breakpoint.lgAndUp"
  >
    <FinancialHouse
      @debt-freedom-action="debtSolAction"
      @investments-action="investmentsAction"
      @term-life-action="termLifeAction"
      :investments-active="investmentsActive"
      :term-life-active="termLifeActive"
      :debt-sol-active="debtSolActive"
      :debt-sol-completed="debtSolCompleted"
    />
    <v-flex column class="backgroundBlueGradient">
      <!-- <MySupport
        :agent="agent"
        @contact-rep="$emit('contact-rep', $event)"
        v-if="agent"
        :sender="sender"
      /> -->
      <MyRep
        :rep-page="false"
        :left-side-bar="true"
      />
    </v-flex>
  </v-flex>
</template>

<script>
import FinancialHouse from './components/FinancialHouse/FinancialHouse.vue';
import MySupport from './components/MySupport/MySupport.vue';
import MyRep from '../../modules/policy/my_rep/MyRep.vue';

export default {
  name: 'LeftSidebar',
  props: {
    investmentsActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    investmentsAction: {
      type: Function,
      required: true,
      default() {
        window.location.assign('/investments');
      },
    },
    termLifeActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    termLifeAction: {
      type: Function,
      required: false,
      default() {
        window.location.assign('/policy/0/details');
      },
    },
    debtSolActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    debtSolCompleted: {
      type: Boolean,
      required: false,
      default: false,
    },
    debtSolAction: {
      type: Function,
      required: false,
      default() {
        window.location.assign('/debtsolutions/mydebt');
      },
    },
    redirectToDashboard: {
      type: Function,
      default: undefined,
    },
    customer: {
      type: Object,
      required: false,
      default: () => {},
    },
    agent: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    sender() {
      return {
        email: this.customer?.email ? this.customer?.email : '',
        phone: this.customer?.email ? this.customer?.mobilePhone : '',
        firstName: this.customer?.firstName ? this.customer?.firstName : '',
        lastName: this.customer?.lastName ? this.customer?.lastName : '',
      };
    },
  },
  components: {
    MySupport,
    FinancialHouse,
    MyRep,
  },
};
</script>

<style lang="stylus" scoped>
.left-sidebar {
  z-index: 10;
  position: absolute;
  display block;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 238px;
  overflow: hidden;
}
.backgroundBlueGradient
  background-image: linear-gradient(to top, #012f60, #013b71);
  position: relative;
  left: 0;
  bottom: 0;
  height: calc(100% - 603px);

</style>
