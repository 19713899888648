import { Policy } from './Policy';

export class Beneficiary {
  _name;

  _firstName;

  _lastName;

  _personCode;

  _relation;

  _ssn;

  _effectiveDate;

  constructor({
    BenePersonCode,
    BeneEffectiveDate,
    BeneRelation,
    BeneSSN,
    BeneName,
    BeneFirstName,
    BeneLastName,
  }) {
    this._name = BeneName;
    this._firstName = BeneFirstName;
    this._lastName = BeneLastName;
    this._personCode = BenePersonCode;
    this._relation = BeneRelation;
    this._ssn = BeneSSN;
    this._effectiveDate = BeneEffectiveDate;
  }

  get name() {
    return this._name || '';
  }

  get personCode() {
    return this._personCode || '';
  }

  get relation() {
    return this._relation || '';
  }

  get ssn() {
    return this._ssn || '';
  }

  get effectiveDate() {
    return Policy.parseDate(this._effectiveDate);
  }
}
